import React, { Fragment } from "react";
import withRoot from "./Component/HOC/withRoot";
import "./App.css";
import { withRouter } from "react-router";

import { useTranslation } from "react-i18next";

// *****Component*****

import CustomeRoute from "./Component/HOC/Routes";
import withErrorBoundarys from "./Error/withErrorBoubdary";
import moment from "moment";
import "moment/locale/ar";
import withApolloProvider from "./Component/HOC/withApolloProvider";

function App(props) {
  const { i18n } = useTranslation();

  document.getElementsByTagName("html")[0].setAttribute("dir", i18n.dir());

  moment.locale(localStorage.getItem("i18nextLng"));

  return (
    <Fragment>
      <CustomeRoute {...props} />
    </Fragment>
  );
}

export default withErrorBoundarys(
  withRoot(withApolloProvider(withRouter(App)))
);
