import * as gqlb from "gql-query-builder";
export const SAVE_BRANCH = gqlb.mutation({
  operation: "saveBranch",
  fields: ["id", "code", "active", { country: ["name"] }],
  variables: {
    input: {
      type: "BranchInput",
      required: true,
    },
  },
});

export const LIST_ITEMS = gqlb.query({
  operation: "listItems",
  fields: [
    {
      operation: "paginatorInfo",
      fields: ["total"],
      variables: {},
    },
    {
      operation: "data",
      fields: [
        "id",
        "code",
        "type",
        "name",
        "active",
        "description",
        "activeFrom",
        "activeTo",
        "statusCode",
        {
          categorization: [{ level4: ["name"] }],
        },
      ],
      variables: {},
    },
  ],
  variables: {
    page: {
      type: "Int",
    },
    first: {
      type: "Int",
    },
    input: {
      type: "ListItemsInputFilter",
      required: true,
    },
  },
});

const GPCFields = [
  "id",
  "code",
  "name",
  "definition",
  "definitionExcludes",
  { parent: ["id"] },
];

// export const LIST_GPC_ITEMS = gqlb.query([
//   {
//     operation: "level1:listGPCItems(input:{level:1})",
//     fields: [
//       "id",
//       "code",
//       "name",
//       "definition",
//       "definitionExcludes",
//       "active",
//     ],
//   },
//   {
//     operation: "level2:listGPCItems(input:{level:2})",
//     fields: GPCFields,
//   },
//   {
//     operation: "level3:listGPCItems(input:{level:3})",
//     fields: GPCFields,
//   },
//   {
//     operation: "level4:listGPCItems(input:{level:4})",
//     fields: GPCFields,
//   },
// ]);

export const LIST_GPC_ITEMS = gqlb.query([
  {
    operation: "listGPCItems",
    fields: [
      "id",
      "code",
      "name",
      "definition",
      "definitionExcludes",
      {
        parent: ["id"],
      },
    ],
    variables: {
      input: {
        type: "ListGPCItemsInputFilter",
        required: true,
      },
    },
  },
]);
