import * as React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { Box, Tab, Tabs, useTheme } from "@mui/material";
import { windowUrl } from "../CustomFunctions/pushUrl";
import { useHistory } from "react-router";

const StyledBox = styled(Box)(({ theme }) => ({
  borderBottom: "1px solid " + theme.palette.divider,
  backgroundColor: "white",
  display: "flex",
  justifyContent: "center",
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function CustomTab(props) {
  const { tapDetails, hashKeys } = props;

  const [value, setValue] = React.useState(0);
  const history = useHistory();
  const href = document.location.href.split("#");

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (hashKeys) {
      const url = history.createHref({
        pathname: href[0],
        hash: hashKeys[newValue],
      });
      windowUrl(url);
    }
  };

  const ref = React.useRef();

  React.useEffect(() => {
    if (href[1] && hashKeys) {
      const tabId = Math.max(hashKeys.indexOf(href[1]), 0);
      setValue(tabId);
    } else if (hashKeys) {
      const url = history.createHref({
        pathname: href[0],
        hash: hashKeys[0],
      });
      windowUrl(url);
    }
    if (ref.current) {
      setTimeout(() => {
        ref.current.updateIndicator();
      }, 400);
    }
  }, []);

  return (
    <Box sx={{ width: "100%" }}>
      <StyledBox>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          // centered
          indicatorColor="primary"
          textColor="primary"
          action={ref}
          variant="scrollable"
          scrollButtons="auto"
        >
          {tapDetails.map((i, index) => (
            <Tab key={index} label={i.tabHead} {...a11yProps(index)} />
          ))}
        </Tabs>
      </StyledBox>
      {tapDetails.map((i, index) => (
        <TabPanel key={index} value={value} index={index}>
          {i.panel}
        </TabPanel>
      ))}
    </Box>
  );
}
