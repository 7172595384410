// @flow
import {
  Box,
  Button,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  SwipeableDrawer,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import { styled } from '@mui/material/styles';
import * as React from "react";
import PropTypes from "prop-types";
import { gql, useQuery } from "@apollo/client";
import clsx from "clsx";
import { CheckCircleOutline, ErrorOutline } from "@mui/icons-material";
import { Fragment } from "react";
import { useEffect } from "react";
import emptyList from "../../assets/Image/received.png";
import FullScreenLoading from "../../Component/HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { useTranslation } from "react-i18next";
import { Globals } from "../../Component/HOC/Classes/Globals";
import { withRouter } from "react-router";
import { pushUrl } from "../../Component/HOC/CustomFunctions/pushUrl";
import { LIST_NOTIFICATIONS } from "./Graphql";
import { useRef } from "react";

const PREFIX = 'NotificationBody';

const classes = {
  listItemIcon: `${PREFIX}-listItemIcon`,
  listItemText: `${PREFIX}-listItemText`,
  success: `${PREFIX}-success`,
  error: `${PREFIX}-error`,
  newNotifi: `${PREFIX}-newNotifi`,
  notificationList: `${PREFIX}-notificationList`,
  loading: `${PREFIX}-loading`,
  bottomDrawer: `${PREFIX}-bottomDrawer`,
  notifiDrawer: `${PREFIX}-notifiDrawer`,
  notifiDrawerPaper: `${PREFIX}-notifiDrawerPaper`
};

const StyledSwipeableDrawer = styled(SwipeableDrawer)((
  {
    theme
  }
) => ({
  [`& .${classes.listItemIcon}`]: {
    minWidth: 48,
  },

  [`& .${classes.listItemText}`]: {
    paddingRight: 48,
  },

  [`& .${classes.success}`]: {
    color: theme.palette.success.main,
  },

  [`& .${classes.error}`]: {
    color: theme.palette.error.main,
  },

  [`& .${classes.newNotifi}`]: {
    "&::after": {
      backgroundColor: theme.palette.primary.main,
      position: "absolute",
      opacity: " 5%",
      width: "100%",
      content: "''",
      height: "100%",
      top: 0,
      left: 0,
    },
  },

  [`& .${classes.notificationList}`]: {
    padding: 0,
  },

  [`& .${classes.loading}`]: {
    paddingTop: theme.spacing(8),
  },

  [`&.${classes.bottomDrawer}`]: {
    [theme.breakpoints.down("sm")]: {
      width: "auto !important",
      height: "100%",
    },
  },

  [`&.${classes.notifiDrawer}`]: {
    [theme.breakpoints.up("sm")]: {
      width: 360,
      flexShrink: 0,
    },
  },

  [`& .${classes.notifiDrawerPaper}`]: {
    zIndex: 1090,
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.up("sm")]: {
      width: 360,
    },
    // overflow: "hidden"
  }
}));

const NotificationsList = (props) => {

  const { t } = useTranslation();

  const notificationDate = useRef(localStorage.getItem("notifications"));
  const firstOpen = localStorage.getItem("firstOpen");

  const {
    data: notifications,
    fetchMore,
    loading: notifiLoad,
  } = useQuery(
    gql`
      ${LIST_NOTIFICATIONS.query}
    `,
    {
      variables: {
        first: 10,
        page: 1,
      },
      // notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-only",
      onCompleted: () => {
        localStorage.setItem("notifications", new Date());
      },
    }
  );

  return (
    <Fragment>
      {notifiLoad ? (
        <Grid container justifyContent="center" className={classes.loading}>
          <FullScreenLoading />
        </Grid>
      ) : notifications?.listNotifications?.data.length === 0 ? (
        <Grid
          container
          item
          justifyContent="center"
          alignItems="center"
          sx={{ margin: "10% 0" }}
        >
          <Box textAlign="center">
            <img width="50%" src={emptyList} alt={"delivery box"} />
            <Box
              component={Typography}
              variant="h6"
              color="text.secondary"
              marginTop="0"
            >
              {t("noNotification")}
            </Box>
          </Box>
        </Grid>
      ) : (
        <List aria-label="contacts" className={clsx(classes.notificationList)}>
          {notifications?.listNotifications?.data.map((item, index) => {
            const delivered = item.shipment.status.code === "DTR";

            const localStorageDate = notificationDate.current;

            const newNotifi =
              new Date(localStorageDate) < new Date(item.createdAt) &&
              new Date(firstOpen) < new Date(item.createdAt);
            return (
              <ListItem
                key={index}
                button
                className={clsx({ [classes.newNotifi]: newNotifi })}
                onClick={() => {
                  pushUrl(props, `/admin/shipments/${item.shipment.id}`);
                }}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  {delivered ? (
                    <CheckCircleOutline className={classes.success} />
                  ) : (
                    <ErrorOutline className={classes.error} />
                  )}
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ variant: "body2" }}
                  className={classes.listItemText}
                  primary={item.description}
                />
                <ListItemSecondaryAction>
                  <ListItemText secondary={item.createdAt.split(" ")[0]} />
                  <ListItemText secondary={item.createdAt.split(" ")[1]} />
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
      )}
      {notifications?.listNotifications?.paginatorInfo.hasMorePages && (
        <Button
          color="primary"
          variant="text"
          onClick={() =>
            fetchMore({
              variables: {
                page:
                  notifications?.listNotifications?.paginatorInfo.currentPage +
                  1,
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                fetchMoreResult.listNotifications["data"] = [
                  ...prev.listNotifications.data,
                  ...fetchMoreResult.listNotifications.data,
                ];
                return fetchMoreResult;
              },
            })
          }
        >
          {t("more")}
        </Button>
      )}
    </Fragment>
  );
};

const NotificationBody = (props) => {
  const theme = useTheme();

  const { t } = useTranslation();

  const { notifiAnchor, notifidrawerState, closeNotifi, notifiDrawer } = props;

  return (
    <StyledSwipeableDrawer
      disableDiscovery={true}
      disableSwipeToOpen={true}
      anchor={notifiAnchor}
      open={notifidrawerState[notifiAnchor]}
      onClose={closeNotifi}
      onOpen={() => notifiDrawer(notifiAnchor, true)}
      dir={theme.direction}
      className={clsx(classes.notifiDrawer, {
        [classes.bottomDrawer]: notifidrawerState[notifiAnchor],
      })}
      classes={{
        paper: clsx(classes.notifiDrawerPaper, {
          [classes.bottomDrawer]: notifidrawerState[notifiAnchor],
        }),
      }}
    >
      <Toolbar variant="dense">
        <Typography color="inherit" variant="h6">
          {t("notifications")}
        </Typography>
      </Toolbar>

      {notifidrawerState[notifiAnchor] && (
        <NotificationsList {...props} onSelect={closeNotifi} />
      )}
    </StyledSwipeableDrawer>
  );
};

NotificationBody.propTypes = {
  notifiAnchor: PropTypes.any,
  notifidrawerState: PropTypes.any,
  closeNotifi: PropTypes.any,
  notifiDrawer: PropTypes.any,
};

export default withRouter(NotificationBody);
