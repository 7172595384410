import {
  Box,
  Icon,
  IconButton,
  Paper,
  Toolbar,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { Fragment, useState } from "react";

import { gql, useQuery } from "@apollo/client";
import { DeleteOutline, Edit, Print } from "@mui/icons-material";
import clsx from "clsx";
import emptyList from "../../assets/Image/received.png";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";

import { useTranslation } from "react-i18next";
import NotFound from "../../Error/NotFound";
import { Globals } from "../HOC/Classes/Globals";
import { KeyValuePair } from "../HOC/CustomComponents/KeyValuePair";
import { Can, SecuredNavLink } from "../HOC/CustomComponents/Secured";
import ShipmentsTable from "../HOC/CustomComponents/ShipmentsTable";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import useInvoicesDetails from "./InvoicesDetails";
import { DELETE_INVOICES_MUTATION, INVOICES_VIEW } from "./InvoicesListQuary";

import Grid from "@mui/material/Unstable_Grid2";
import MutationWithDialog from "../HOC/CustomComponents/MutationWithDialog";
import SpanLink from "../HOC/CustomComponents/SpanLink";
import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import ExportFinanceShipments from "../Payment/ExportFinanceShipments";
import PrintIcon from "../Shipments/PrintIcon";
import PrintComponent from "../Shipments/PrintComponent";

const PREFIX = "InvoicesView";

const classes = {
  mainTracking: `${PREFIX}-mainTracking`,
  paper: `${PREFIX}-paper`,
  viewPaper: `${PREFIX}-viewPaper`,
  title: `${PREFIX}-title`,
  textAlign: `${PREFIX}-textAlign`,
  divider: `${PREFIX}-divider`,
  table: `${PREFIX}-table`,
  filterAnchor: `${PREFIX}-filterAnchor`,
  picker: `${PREFIX}-picker`,
  button: `${PREFIX}-button`,
  toolbar: `${PREFIX}-toolbar`,
  selectPadding: `${PREFIX}-selectPadding`,
  margin: `${PREFIX}-margin`,
  overlay: `${PREFIX}-overlay`,
  field: `${PREFIX}-field`,
  toolbarIcons: `${PREFIX}-toolbarIcons`,
  iconColor: `${PREFIX}-iconColor`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.mainTracking}`]: {
    margin: theme.spacing(1, 0),
    width: "100%",
  },

  [`& .${classes.paper}`]: {
    marginBottom: theme.spacing(2),
    position: "relative",
    minHeight: "327px",
    gridAutoRows: "max-content",
  },

  [`& .${classes.viewPaper}`]: {
    padding: theme.spacing(3),
  },

  [`& .${classes.title}`]: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },

  [`& .${classes.textAlign}`]: {
    textAlign: "justify",
    padding: theme.spacing(1),
  },

  [`& .${classes.divider}`]: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
      height: "1px",
    },
  },

  [`& .${classes.table}`]: {
    display: "grid",
    width: "100%",
  },

  [`& .${classes.filterAnchor}`]: {
    [theme.breakpoints.down("md")]: {
      width: "auto",
    },
    // overflow: "scroll",
    "& .MuiDrawer-paper": {
      [theme.breakpoints.up("sm")]: {
        // width: "50%"
      },
      top: "auto",
      width: "25%",
      border: "1px solid #0000001f",
      height: "100%",
      overflow: "hidden",
    },
  },

  [`& .${classes.picker}`]: {
    [theme.breakpoints.down("md")]: {
      width: "auto",
    },
    // overflow: "scroll",
    "& .MuiDrawer-paper": {
      [theme.breakpoints.up("sm")]: {
        width: "50%",
      },

      height: "100%",
      overflow: "hidden",
    },
  },

  [`& .${classes.button}`]: {
    margin: theme.spacing(1),
  },

  [`& .${classes.toolbar}`]: {
    borderBottom: "1px #ccd1d6 solid",
    backgroundColor: "#f5f7f9",
    width: "100%",
  },

  [`& .${classes.selectPadding}`]: {
    paddingTop: "6px",
    paddingBottom: "5px",
  },

  [`& .${classes.margin}`]: {
    margin: theme.spacing(1, 2),
  },

  [`& .${classes.overlay}`]: {
    backgroundColor: "#ffffffad",
    position: "absolute",
    zIndex: 2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    top: 0,
    right: 0,
  },

  [`& .${classes.field}`]: {
    width: 260,
    [theme.breakpoints.down("sm")]: {
      width: 170,
    },
  },

  [`& .${classes.toolbarIcons}`]: {
    flex: "1 1 100%",
    textAlign: "end",
  },

  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },
}));

export {};
const EnhancedTableToolbar = (props) => {
  const { t } = useTranslation();

  const { selectedShipment, invoicesId: invoiceId } = props;

  const shipmentId = selectedShipment.map((i) => i.shipment.id);
  return (
    <Toolbar className={clsx(classes.root)}>
      <Typography
        className={classes.title}
        color="inherit"
        variant="subtitle1"
        component="div"
      >
        {t("totalShipments") + " " + selectedShipment.length}
      </Typography>
      <Can showException permission="shipping.shipment.print">
        <PrintComponent shipmentsId={shipmentId} />
      </Can>
      <ExportFinanceShipments type="invoice" id={invoiceId} />
    </Toolbar>
  );
};

const InvoicesView = (props) => {
  const { t } = useTranslation();

  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);

  const [pickedShipment, setPickedShipment] = useState({ shipments: [] });
  const invoiceShipments = pickedShipment.shipments;
  const [invoicesData, setInvoicesData] = useState();


  const { details: invoicesDetails } = useInvoicesDetails({
    TotalData : invoicesData
  });

  /******************************************* Start Pagination Functions ********************************************/

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  /******************************************* Start invoices By Id ********************************************/
  const invoicesId = props.match.params.id?.trim();

  const user = Globals.user;

  const journalEntryPermission = user.hasPermission(
    "accounting.journal_entry.list"
  );
  const invoicesQueryBody = INVOICES_VIEW(journalEntryPermission);

  const { data, loading: invoicesByIdLoad } = useQuery(
    gql`
      ${invoicesQueryBody.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      skip: !invoicesId,
      variables: {
        id: parseInt(invoicesId),
      },
      onCompleted: (data) => {
        if (data?.invoice === null) return;
        setInvoicesData(data?.invoice)

        setPickedShipment({ shipments: data.invoice.invoiceShipments });
      },
    }
  );
  

  // const invoiceQ = data?.invoice

  const branchId = data?.invoice?.branch?.id;
  const canAccessBranch = branchId ? user.canAccessBranch(branchId) : true;
  const canDelete =
    user.hasPermission("shipping.invoice.delete") &&
    canAccessBranch &&
    !data?.invoice?.approved &&
    !invoicesByIdLoad;

  const canEdit =
    user.hasPermission("shipping.invoice.update") &&
    canAccessBranch &&
    !data?.invoice?.glApproved &&
    !invoicesByIdLoad;

  /******************************************* End invoices List ********************************************/

  let tableBody = null;
  let viewBody = null;
  tableBody = (
    <Grid container xs={12} justifyContent="center" sx={{ width: "100%" }}>
      {/* *******Table******* */}
      <Paper className={clsx(classes.table, classes.paper)}>
        <EnhancedTableToolbar
          {...props}
          selectedShipment={invoiceShipments}
          invoicesId={invoicesId}
        />

        {invoicesDetails && (
          <TableFixedHeaderWraper>
            <ShipmentsTable
              page={page}
              rowsPerPage={rowsPerPage}
              data={invoiceShipments}
              headCells={invoicesDetails.tableHead}
              parseBodyCell={invoicesDetails.tableBody}
              total={invoicesDetails.tableTotal}
              sumTableNumber={invoicesDetails?.sumTableNumber}
              sum={true}
            />
          </TableFixedHeaderWraper>
        )}
        {invoiceShipments.length > 4 && (
          <MUITablePagination
            count={invoiceShipments.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}

        <Grid container justifyContent="center" alignItems="center">
          {invoiceShipments.length === 0 && (
            <Box textAlign="center" marginTop="50px" marginBottom="50px">
              <img width="50%" src={emptyList} alt={"delivery box"} />
              <Box
                component={Typography}
                variant="h6"
                color="text.secondary"
                marginTop="0"
              >
                {t("noShipments")}
              </Box>
            </Box>
          )}
        </Grid>
      </Paper>
    </Grid>
  );

  viewBody = (
    <Grid
      container
      spacing={3}
      justifyContent="center"
      sx={{ margin: "0", width: "100%" }}
    >
      <Fragment>
        <Toolbar className={clsx(classes.toolbar)} variant="regular">
          <Grid
            container
            sx={{ flexWrap: "initial", width: "100%", flexGrow: 1 }}
            alignItems="center"
          >
            <Grid className={classes.field}>
              <Typography variant="h6">{invoicesDetails?.label}</Typography>
            </Grid>
            <Grid className={classes.toolbarIcons}>
              <IconButton
                disabled={invoicesByIdLoad}
                onClick={() =>
                  window.open(
                    `${window.location.origin}/report/print/invoice/${invoicesId}`
                  )
                }
                color="default"
                size="large"
              >
                <Print fontSize="inherit" />
              </IconButton>
              <SecuredNavLink
                permission={canEdit}
                to={{
                  pathname: `/admin/invoices/${invoicesId}/edit`,
                }}
              >
                <IconButton color={"primary"} size="large">
                  <Edit fontSize="inherit" />
                </IconButton>
              </SecuredNavLink>
              <MutationWithDialog
                mutaion={DELETE_INVOICES_MUTATION.query}
                icon={DeleteOutline}
                permission={Boolean(canDelete)}
                iconTooltip={t("deleteRecord")}
                dialogTitle={t("deleteRecord")}
                dialogContent={t("deleteRecordMessage")}
                mutaionProps={{ variables: { id: parseInt(invoicesId) } }}
                onCompleted={() => pushUrl(props, `/admin/invoices`)}
                onCompleteMessage={t("successfullyDeletedRecord")}
              />
            </Grid>
          </Grid>
        </Toolbar>
      </Fragment>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ margin: "0", width: "100%" }}
      >
        {invoicesByIdLoad ? (
          <FullScreenLoading minHeight="25%" />
        ) : (
          <Paper
            container
            className={classes.viewPaper}
            sx={{ mt: 1, width: "100%" }}
            component={Grid}
          >
            <KeyValuePair title={t("recordCode")} value={data?.invoice?.code} />
            <KeyValuePair title={t("date")} value={data?.invoice?.date} />
            <KeyValuePair
              title={t("branch")}
              value={
                data?.invoice?.branch?.name && (
                  <SpanLink
                    pathname={`/admin/branches/${data?.invoice?.branch?.id}`}
                  >
                    {data?.invoice?.branch?.name}
                  </SpanLink>
                )
              }
            />

            <KeyValuePair
              title={t("transactionType")}
              value={
                <SpanLink
                  pathname={`/admin/shipment-transaction-types/${data?.invoice?.transactionType?.id}`}
                >
                  {data?.invoice?.transactionType?.name}
                </SpanLink>
              }
            />
            {data?.invoice?.customer ? (
              <KeyValuePair
                title={t("customer")}
                value={
                  data?.invoice?.customer?.name && (
                    <SpanLink
                      pathname={`/admin/customers/${data?.invoice?.customer?.id}`}
                    >
                      {data?.invoice?.customer?.name}
                    </SpanLink>
                  )
                }
              />
            ) : null}

            <KeyValuePair
              title={t("journalEntry")}
              value={
                data?.invoice?.journalEntry?.code && (
                  <SpanLink
                    pathname={`/admin/finance/journal-entry/${data?.invoice?.journalEntry?.id}`}
                  >
                    {data?.invoice?.journalEntry?.code}
                  </SpanLink>
                )
              }
            />

            <KeyValuePair title={t("notes")} value={data?.invoice?.notes} />
            <KeyValuePair
              title={t("approved")}
              value={
                data?.invoice?.approved ? (
                  <Icon className={classes.iconColor}>
                    check_circle_outline
                  </Icon>
                ) : (
                  <Icon color="error">highlight_off</Icon>
                )
              }
            />
            <KeyValuePair
              title={t("glApproved")}
              value={
                data?.invoice?.glApproved ? (
                  <Icon className={classes.iconColor}>
                    check_circle_outline
                  </Icon>
                ) : (
                  <Icon color="error">highlight_off</Icon>
                )
              }
            />
          </Paper>
        )}
      </Grid>
    </Grid>
  );

  return data?.invoice === null ? (
    <NotFound />
  ) : (
    <Root>
      {props.children}
      {viewBody}

      <Grid
        container
        spacing={3}
        justifyContent="center"
        className={classes.mainTracking}
      >
        {invoicesDetails && !invoicesByIdLoad && tableBody}
      </Grid>
    </Root>
  );
};

export default InvoicesView;
