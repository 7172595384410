import { useQuery, gql } from "@apollo/client";
import { styled } from '@mui/material/styles';
import { IconButton, Paper, Typography, Toolbar, Icon } from "@mui/material";
import { Edit } from "@mui/icons-material";
import clsx from "clsx";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import NotFound from "../../Error/NotFound";
import { KeyValuePair } from "../HOC/CustomComponents/KeyValuePair";
import { SecuredNavLink } from "../HOC/CustomComponents/Secured";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { PRICE_LIST } from "./Graphql";
import PriceListTable from "./PriceListTables";
import Grid from "@mui/material/Unstable_Grid2";
import { GetCustomerPermissionSlug } from "../../helpers/getManifestPermissionSlug";

const PREFIX = 'PriceListView';

const classes = {
  toolbar: `${PREFIX}-toolbar`,
  toolbarIcons: `${PREFIX}-toolbarIcons`,
  box: `${PREFIX}-box`,
  field: `${PREFIX}-field`,
  iconColor: `${PREFIX}-iconColor`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.toolbar}`]: {
    borderBottom: "1px #ccd1d6 solid",
    backgroundColor: "#f5f7f9",
    width: "100%",
  },

  [`& .${classes.toolbarIcons}`]: {
    flex: "1 1 100%",
    textAlign: "end",
  },

  [`& .${classes.box}`]: {
    padding: theme.spacing(3),
    flexGrow: 1,
  },

  [`& .${classes.field}`]: {
    width: 260,
    [theme.breakpoints.down("sm")]: {
      width: 170,
    },
  },

  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  }
}));

const PriceListView = (props) => {
  const { t } = useTranslation();

  const priceListId = parseInt(props.match.params.id);

  const { data, loading } = useQuery(
    gql`
      ${PRICE_LIST.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: { id: priceListId },
    }
  );

  const viewType = data?.priceList?.businessType.code

  return data?.priceList === null ? (
    <NotFound />
  ) : (
    <Root>
      <Grid
        container
        justifyContent="center"
        sx={{ margin: "0", width: "100%" }}
      >
        <Toolbar className={clsx(classes.toolbar)} variant="regular">
          <Grid
            container
            sx={{ flexWrap: "initial", flexGrow: 1 }}
            alignItems="center"
          >
            <Grid className={classes.field}>
              <Typography variant="h6">{t(`listPriceLists`)}</Typography>
            </Grid>
            <Grid className={classes.toolbarIcons}>
              <SecuredNavLink
                permission={viewType && GetCustomerPermissionSlug("shipping", "price_list", viewType, "update")}
                to={{
                  pathname: `/admin/price-list/${priceListId}/edit`,
                }}
              >
                <IconButton color={"primary"} size="large">
                  <Edit fontSize="inherit" />
                </IconButton>
              </SecuredNavLink>
              <SecuredNavLink
                permission="shipping.price_list.create"
                to={{
                  pathname: `/admin/price-list/${priceListId}/copy`,
                }}
              >
                <IconButton color={"primary"} size="large">
                  <Icon>content_copy</Icon>
                </IconButton>
              </SecuredNavLink>
            </Grid>
          </Grid>
        </Toolbar>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ p: 2, width: "100%" }}
        >
          {loading ? (
            <FullScreenLoading minHeight="15%" />
          ) : (
            <>
              <Paper container className={classes.box} component={Grid}>
                <KeyValuePair
                  md={6}
                  title={t("code")}
                  value={data?.priceList?.code}
                />
                <KeyValuePair
                  md={6}
                  title={t("name")}
                  value={data?.priceList.name}
                />
                <KeyValuePair
                  md={6}
                  title={t("listDescription")}
                  value={data?.priceList?.description}
                />
                <KeyValuePair
                  md={6}
                  title={t("active")}
                  value={
                    data?.priceList?.active ? (
                      <Icon className={classes.iconColor}>
                        check_circle_outline
                      </Icon>
                    ) : (
                      <Icon color="error">highlight_off</Icon>
                    )
                  }
                />
              </Paper>
            </>
          )}
        </Grid>
        {data?.priceList?.pickups && (
          <PriceListTable pickups={data.priceList?.pickups} />
        )}
      </Grid>
    </Root>
  );
};

export default PriceListView;
