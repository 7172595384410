import * as gqlb from "gql-query-builder";
export const SAVE_CUSTOMER = gqlb.mutation({
  operation: "saveCustomer",
  fields: ["id", "code", "active", "specifyShipmentCode"],
  variables: {
    input: {
      type: "CustomerInput",
      required: true,
    },
  },
});

export const LIST_CUSTOMERS = (paymentDuePermission) =>
  gqlb.query({
    operation: "listCustomers",
    fields: [
      {
        operation: "paginatorInfo",
        fields: [
          "count",
          "currentPage",
          "firstItem",
          "hasMorePages",
          "lastItem",
          "lastPage",
          "perPage",
          "total",
        ],
        variables: {},
      },
      {
        operation: "data",
        fields: [
          "id",
          "code",
          "createdAt",
          "name",
          "active",
          "phone",
          "mobile",
          "email",
          "representative",
          `paymentDue @include(if:${paymentDuePermission})`,
          {
            zone: ["id", "name"],
          },
          {
            subzone: ["name"],
          },
          {
            branch: ["name", "id"],
          },
        ],
        variables: {},
      },
    ],
    variables: {
      page: {
        type: "Int",
      },
      first: {
        type: "Int",
      },
      input: {
        type: "ListCustomersFilterInput",
      },
    },
  });

export const CUSTOMERID = gqlb.query([{
  operation: "customer",
  fields: [
    "id",
    "code",
    "createdAt",
    "name",
    "address",
    "active",
    "taxable",
    "phone",
    "mobile",
    "postalCode",
    "mailBox",
    "email",
    "vodafoneCash",
    "bankAccount",
    "representative",
    "activityName",
    "specifyShipmentCode",
    "shareCollectedFees",
    "payUncollected",
    "hideWaybillMobile",
    "warehousing",
    {
      createdBy: ["id", "username"],
    },
    {
      zone: ["id", "name"],
    },
    {
      subzone: ["id", "name"],
    },
    {
      branch: ["id", "name"],
    },
    {
      bank: ["id", "name"],
    },
    {
      country: ["id", "name"],
    },
    {
      state: ["id", "name"],
    },
    {
      city: ["id", "name"],
    },
    {
      area: ["id", "name"],
    },
    {
      glAccount: ["id", "name"],
    },
    {
      priceList: ["id", "name"],
    },
    {
      businessType: ["code", "name"],
    },
    {
      category: ["code", "name"],
    },
    {
      paymentMethod: ["code", "name"],
    },
    {
      businessType: ["code", "name"],
    },
    {
      defaultShipmentType: ["code", "name"],
    },
    {
      paymentTypes: ["code", "id", "name"],
    },
    {
      defaultPriceType: ["code", "name"],
    },
    {
      defaultOpenable: ["code", "name"],
    },
    {
      users: ["id", "username"],
    },
    {
      businessType: ["id", "code"],
    },
  ],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
},
{
  operation: "listPaymentTypesDropdown",
  fields: ["id", "name", "code"],
  variables: {
    input: {
      type: "ListPaymentTypesDropdownInput",
    },
  },
}
]);

export const SETTINGS = gqlb.query({
  operation: "shippingSettings",
  fields: [
    { mainCustomerGLAccount: ["id", "name"] },
    { mainConsigneeGLAccount: ["id", "name"] },
    "warehousing "],
  variables: {},
});
