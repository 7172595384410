import * as gqlb from "gql-query-builder";

export const SAVE_PRODUCT = gqlb.mutation({
  operation: "saveProduct",
  fields: ["id"],
  variables: {
    input: {
      type: "ProductInput",
      required: true,
    },
  },
});
export const DELETE_PRODUCT = gqlb.mutation({
  operation: "deleteProduct",
  fields: ["id"],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const LIST_PRODUCTS =
  gqlb.query({
    operation: "listProducts",
    fields: [
      {
        operation: "paginatorInfo",
        fields: [
          "count",
          "currentPage",
          "firstItem",
          "hasMorePages",
          "lastItem",
          "lastPage",
          "perPage",
          "total",
        ],
        variables: {},
      },
      {
        operation: "data",
        fields: [
          "id",
          "code",
          "createdAt",
          "name",
          "active",
          "price",
          "weight",
          {
            operation: "availableQuantity",
            fields: [],
            variables: {
              branchId: {
                type: "Int",
              },
            },
          },
          {
            customer: ["id", "name", "code"],
          },
          {
            createdBy: ["id", "username"],
          },
        ],
        variables: {},
      },
    ],
    variables: {
      page: {
        type: "Int",
      },
      first: {
        type: "Int",
      },
      input: {
        type: "ListProductsFilterInput",
      },
    },
  });
export const LIST_PRODUCTS_TRANSACTIONS =
  gqlb.query([
    {
      operation: "listProductTransactions",
      fields: [
        {
          operation: "paginatorInfo",
          fields: [
            "count",
            "currentPage",
            "firstItem",
            "hasMorePages",
            "lastItem",
            "lastPage",
            "perPage",
            "total",
          ],
          variables: {},
        },
        {
          operation: "data",
          fields: [
            "id",
            {
              transactionType: [
                "id",
                "code",
                "name"
              ]
            },
            {
              branch: [
                "id",
                "name"
              ]
            },
            {
              product: [
                "id",
                "name",
                "code"
              ]
            },
            {
              shipment: [
                "id",
                "code"
              ]
            },
            {
              operation: "status",
              fields: [
                {
                  lookup: [
                    "id"
                  ]
                },
                "id",
                "code",
                "name"
              ],
              variables: {},
            },
            {
              warehouseManifest: [
                "id",
                "code"
              ]
            },
            {
              createdBy: [
                "id",
                "username"
              ]
            },
            "quantity",
            "createdAt",
            "date",
          ],
          variables: {},
        },
      ],
      variables: {
        page: {
          type: "Int",
        },
        first: {
          type: "Int",
        },
        input: {
          type: "ListProductTransactionsInput",
        },
      },
    }, {
      operation: "sumProductTransactions",
      fields: ["quantity"],
      variables: {
        input: {
          type: "ListProductTransactionsInput",
        },
      },
    }
  ]);

export const PRODUCTID = gqlb.query({
  operation: "product",
  fields: [
    "id",
    "code",
    "createdAt",
    "name",
    "active",
    "weight",
    "price",
    "updatedAt",
    "availableQuantity",
    {
      branchesBalance: [
        {
          branch: ["id", "name"]
        },
        "quantity"
      ]
    },
    "deletable",
    {
      customer: ["id", "name"],
    },
    {
      createdBy: ["id", "username"],
    },
    {
      updatedBy: ["id", "username"],
    }
  ],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const SETTINGS = gqlb.query({
  operation: "shippingSettings",
  fields: [{ localCurrency: ["name"] }],
  variables: {},
});