import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import { TableContainer } from "@mui/material";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

const PREFIX = 'TableFixedHeaderWraper';

const classes = {
  floatingHeadHidden: `${PREFIX}-floatingHeadHidden`,
  floatingHead: `${PREFIX}-floatingHead`
};

const StyledTableContainer = styled(TableContainer)((
  {
    theme
  }
) => ({
  [`& .${classes.floatingHeadHidden}`]: {
    position: "fixed",
    opacity: 0,
    minWidth: "100%",
    "& table": {
      minWidth: "100%",
    },
  },

  [`& .${classes.floatingHead}`]: {
    display: "block",
    position: "absolute",
    opacity: 1,
    transition: "opacity 1s",
    zIndex: 105,
  }
}));

export { };

const TableFixedHeaderWraper = (props) => {
  const { headCells, tableRows, containerScroll, ...restProps } = props;

  const [shouldShowHeader, setShouldShowHeader] = useState(false);

  const getHeadCellWidth = (id) => {
    return document.getElementById(id).getBoundingClientRect().width;
  };

  const getTableWithFloatingClass = (floatingTableHead, mainTableHead) => {
    let table = document.createElement("table");
    floatingTableHead.appendChild(table);
    const cloneThead = mainTableHead.cloneNode(true);
    const mainTh = mainTableHead.querySelectorAll("th");
    mainTh.forEach((i, index) => {
      i.id = index + "-head-cell";
    });

    cloneThead.querySelectorAll("th").forEach((i, index) => {
      if (index === 0 && i.querySelector("input")) {
        i.querySelector("svg").remove();
      }

      if (!i.classList.contains("MuiTableCell-stickyHeader")) {
        i.style.backgroundColor = "white";
      }
      const width = getHeadCellWidth(index + "-head-cell");
      i.style.minWidth = width + "px";
      // i.style.position = "fixed";
    });

    floatingTableHead.querySelector("table").appendChild(cloneThead);
  };

  useEffect(() => {
    const tableContainer = document.getElementById(
      "floating-head-table-container"
    );
    const floatingTableHead = document.getElementById("floating-tabel-head");
    floatingTableHead.parentElement.style.position = "relative";
    const mainTableHead = tableContainer.getElementsByTagName("thead")[0];
    const headerRect =
      document?.querySelector("header")?.getBoundingClientRect() ?? 0;
    const listenToScroll = () => {
      if (!floatingTableHead.querySelector("th"))
        getTableWithFloatingClass(floatingTableHead, mainTableHead);
      // const headerMargin = document
      //   .getElementById("header-margin")
      //   .getBoundingClientRect();
      const mainHeadRect = mainTableHead.getBoundingClientRect();
      setShouldShowHeader(mainHeadRect.top < 0);
      const mainTableHeadPosition = mainTableHead.getBoundingClientRect().top;
      floatingTableHead.style.top =
        -mainTableHeadPosition + headerRect.height + "px";
    };

    const elementListener = containerScroll
      ? tableContainer.parentElement
      : document;
    elementListener.addEventListener("scroll", listenToScroll, {
      passive: true,
    });

    // document.querySelector("table").onscroll = (e) => {
    //   console.log("tabll scroll");
    //   floatingTableHead.scrollLeft(this.scrollLeft());
    // };
    return () => {
      elementListener.removeEventListener("scroll", listenToScroll);
    };
  }, []);

  const { i18n } = useTranslation();

  return (
    <StyledTableContainer
      {...restProps}
      sx={{
        position: "relative",
        overflowY: "clip"
      }}
      id="floating-head-table-container"
      key={i18n.language}
    >
      <div
        className={clsx(
          { [classes.floatingHead]: shouldShowHeader },
          classes.floatingHeadHidden
        )}
        id="floating-tabel-head"
      ></div>
      {props.children}
    </StyledTableContainer>
  );
};

export default TableFixedHeaderWraper;
