import React from "react";

import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import * as color from "@mui/material/colors";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { SnackbarProvider } from "notistack";
import { useTranslation } from "react-i18next";
import { prefixer } from "stylis";
import rtlPlugin from "stylis-plugin-rtl";
import config from "../../config.json";
import i18n from "../../i18n";

export const muiCache = createCache({
  key: "mui",
  prepend: true,
});
const cacheRtl = createCache({
  key: "muirtl",
  prepend: true,
  stylisPlugins: [prefixer, rtlPlugin],
});

// const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const theme = createTheme({
  direction: i18n.dir(),

  components: {
    MuiGrid2: {
      // defaultProps: {
      //   disableEqualOverflow: true,
      // },
    },
    MuiCssBaseline: {
      styleOverrides: (themeParam) => ({
        body: { backgroundColor: themeParam.palette.grey[100] },
      }),
    },
    MuiIcon: {
      styleOverrides: {
        root: {
          fontFamily: "'Material Icons Outlined' !important",
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          textTransform: "capitalize",
        },
      },
    },

    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: "0 16px",
          maxHeight: "45px",
          height: "45px",
          whiteSpace: "nowrap",
        },
        head: {
          // fontWeight: 600,
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: "filled",
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          "& label ,& input ,& .MuiSelect-select": {
            // fontSize: "0.800rem",
          },
        },
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        "& legend": {
          float: "none",
          margin: "initial",
        },
      },
    },
  },
  palette: {
    // mode: "dark",
    primary: {
      main: config.theme.primaryColor.startsWith("#")
        ? config.theme.primaryColor
        : color[`${config.theme.primaryColor}`][500],
    },
    // secondary: { main: color.blue[400] }
  },
  typography: {
    fontFamily: ['"Cairo"', "sans-serif"].join(","),
    fontSize: 12.5,
  },
});

function withRoot(Component) {
  function WithRoot(props) {
    const { i18n } = useTranslation();

    // JssProvider allows customizing the JSS styling solution.
    return (
      <CacheProvider value={i18n.dir() === "rtl" ? cacheRtl : muiCache}>
        {/* <StyledProvider injectFirst> */}
        <ThemeProvider
          theme={
            i18n.dir() === "rtl"
              ? {
                  ...theme,

                  direction: "rtl",
                }
              : {
                  ...theme,

                  direction: "ltr",
                }
          }
        >
          <div>
            <SnackbarProvider maxSnack={3}>
              <Component {...props} />
            </SnackbarProvider>
          </div>
        </ThemeProvider>
        {/* </StyledProvider> */}
      </CacheProvider>
    );
  }

  return WithRoot;
}

export default withRoot;
