import * as gqlb from "gql-query-builder";

export const LIST_USERS = gqlb.query({
  operation: "listUsers",
  fields: [
    {
      operation: "paginatorInfo",
      fields: [
        "count",
        "currentPage",
        "firstItem",
        "hasMorePages",
        "lastItem",
        "lastPage",
        "perPage",
        "total",
      ],
      variables: {},
    },
    {
      operation: "data",
      fields: [
        "id",
        "username",
        "active",

        {
          operation: "account",
          fields: [
            {
              operation: "...on Customer",
              fields: ["id", "name", "email"],
              variables: {},
            },
            {
              operation: "...on DeliveryAgent",
              fields: ["id", "name", "code"],
              variables: {},
            },
          ],
          variables: {},
        },
        {
          role: ["id", "name", "code"],
        },
      ],
      variables: {},
    },
  ],
  variables: {
    input: {
      type: "ListUsersFilterInput",
    },
    page: {
      type: "Int",
    },
    first: {
      type: "Int",
    },
  },
});

export const USER_ID = gqlb.query({
  operation: "user",
  fields: [
    "id",
    "username",
    "active",
    {
      role: ["id", "name"],
    },
    {
      userBranches: ["default", { branch: ["id", "name"] }],
    },
    {
      operation: "account",
      fields: [
        {
          operation: "...on Customer",
          fields: ["id", "name", "code"],
          variables: {},
        },
        {
          operation: "...on DeliveryAgent",
          fields: ["id", "name", "code"],
          variables: {},
        },
      ],
      variables: {},
    },
    {
      role: ["name", "code"],
    },
  ],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const SAVE_USER = gqlb.mutation({
  operation: "saveUser",
  fields: ["id", "username"],
  variables: {
    input: {
      type: "UserInput",
      required: true,
    },
  },
});
