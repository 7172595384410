import React, { useEffect, useState } from "react";

import { styled } from "@mui/material/styles";

import { useQuery, gql } from "@apollo/client";
import {
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
  Toolbar,
  Box,
  Table,
  TableBody,
  TableRow,
  TableHead,
  Icon,
  Button,
  Drawer,
  useTheme,
} from "@mui/material";
import { Add, Clear, FilterList, Search } from "@mui/icons-material";
import clsx from "clsx";
import { SecuredNavLink } from "../HOC/CustomComponents/Secured";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import { LIST_USERS } from "./Graphql";
import { useTranslation } from "react-i18next";
import emptyList from "../../assets/Image/received.png";
import CellLink from "../HOC/CustomComponents/CellLink";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import MUIselect from "../HOC/MUI/MUIselect";
import { urlParameters } from "../HOC/CustomFunctions/urlParameters";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { windowUrl } from "../HOC/CustomFunctions/pushUrl";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import {
  LIST_CUSTOMERS_DROPDOWN,
  LIST_DELIVERY_AGENTS_DROPDOWN,
  LIST_ROLES_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";
import useWidth from "../../Hooks/useWidth";

const PREFIX = "ListUsers";

const classes = {
  button: `${PREFIX}-button`,
  btnMargin: `${PREFIX}-btnMargin`,
  mainSearch: `${PREFIX}-mainSearch`,
  titles: `${PREFIX}-titles`,
  toolbar: `${PREFIX}-toolbar`,
  searchForm: `${PREFIX}-searchForm`,
  searchField: `${PREFIX}-searchField`,
  overlay: `${PREFIX}-overlay`,
  searchPadding: `${PREFIX}-searchPadding`,
  drawer: `${PREFIX}-drawer`,
  drawerPaper: `${PREFIX}-drawerPaper`,
  drawerHeader: `${PREFIX}-drawerHeader`,
  content: `${PREFIX}-content`,
  contentShift: `${PREFIX}-contentShift`,
  tableRow: `${PREFIX}-tableRow`,
  shipmentTable_shipmentStatus: `${PREFIX}-shipmentTable_shipmentStatus`,
  shipmentTable_shipmentCode: `shipmentTable_shipmentCode`,
  tablePaper: `${PREFIX}-tablePaper`,
  table_status: `${PREFIX}-table_status`,
  table_code: `${PREFIX}-table_code`,
  toolbarHeader: `${PREFIX}-toolbarHeader`,
  tableBodyRow: `${PREFIX}-tableBodyRow`,
  iconColor: `${PREFIX}-iconColor`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.button}`]: {
    position: "sticky",
    bottom: 0,
    zIndex: 3,
    backgroundColor: "#fff",
    marginTop: 10,
    padding: 7,
  },

  [`& .${classes.btnMargin}`]: {
    width: "100%",
  },

  [`& .${classes.mainSearch}`]: {
    margin: theme.spacing(0),
    width: "100%",
  },

  [`& .${classes.titles}`]: {
    flex: "1 1 100%",
  },

  [`& .${classes.toolbar}`]: {
    borderBottom: "1px #ccd1d6 solid",
    backgroundColor: "#f5f7f9",
    width: "100%",
    // justifyContent: "space-between",
  },

  [`& .${classes.searchForm}`]: {
    overflowY: "auto",
    width: "100%",
    height: "100%",
    margin: 0,
  },

  [`& .${classes.searchField}`]: {
    padding: theme.spacing(1, 1, 0, 1),
    position: "relative",
    overflow: "hidden",
  },

  [`& .${classes.overlay}`]: {
    width: "100%",
    height: "100%",
    position: "absolute",
    backgroundColor: "#ffffffa8",
    zIndex: 2,
  },

  [`& .${classes.searchPadding}`]: {
    padding: theme.spacing(1),
  },

  //////////////////////Drawer///////////////////

  [`& .${classes.drawer}`]: {
    width: drawerWidth,
    flexShrink: 0,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },

  [`& .${classes.drawerPaper}`]: {
    width: drawerWidth,

    zIndex: "250",

    left: "initial",
    right: "initial",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },

  [`& .${classes.drawerHeader}`]: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },

  [`& .${classes.content}`]: {
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
  },

  [`& .${classes.contentShift}`]: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: "auto",
    width: "calc(100% - 240px)",
    [theme.breakpoints.down("sm")]: {
      margin: "auto",
      width: "auto",
    },
  },

  [`& .${classes.tableRow}`]: {
    "&:hover": {
      "& .MuiTableCell-root": {
        backgroundColor: "#f5f5f5",
      },
    },
  },

  [`& .${classes.shipmentTable_shipmentStatus}`]: {
    [theme.breakpoints.up("sm")]: {
      zIndex: "100",
      backgroundColor: "#fff",
      position: "sticky",
      top: "0",
      right: "0",
    },
  },

  [`& .${classes.shipmentTable_shipmentCode}`]: {
    [theme.breakpoints.up("sm")]: {
      zIndex: "100",
      backgroundColor: "#fff",
      position: "sticky",
      top: "0",
      left: "0em",
    },
  },

  [`& .${classes.tablePaper}`]: {
    borderRadius: 0,
    width: "100%",
    display: "grid",
  },

  [`& .${classes.table_status}`]: {
    [theme.breakpoints.up("sm")]: {
      zIndex: "100",
      backgroundColor: "#fff",
      position: "sticky",
      top: "0",
      right: "0",
    },
  },

  [`& .${classes.table_code}`]: {
    [theme.breakpoints.up("sm")]: {
      zIndex: "100",
      backgroundColor: "#fff",
      position: "sticky",
      top: "0",
      left: "0",
    },
  },

  [`& .${classes.toolbarHeader}`]: {
    flex: 1,
  },

  [`& .${classes.tableBodyRow}`]: {
    "&:hover": {
      "& .MuiTableCell-root": {
        backgroundColor: "#f5f5f5",
      },
    },
  },

  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },
}));

const drawerWidth = 240;

const ListUsers = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const urlQuery = urlParameters(window.location.search);
  const validUrlParameters = Object.keys(urlQuery).length !== 0;
  const [autocompleteValues, setAutocompleteValues] = useState({
    role: null,
    account: null,
  });
  const [roleCode, setRoleCode] = useState(null);
  const screenWidth = useWidth();

  const [drawerState, setDrawerState] = React.useState({
    top: true,
    left: screenWidth === "xs" ? false : true,
    bottom: screenWidth === "xs" ? false : true,
    right: screenWidth === "xs" ? false : true,
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm();

  const [rowsPerPage, setRowsPerPage] = useState(urlQuery["rowsPerPage"] ?? 20);
  const initSearch = () => {
    let searchParameters = {
      page: 0,
      refetch: true,
    };
    delete urlQuery["rowsPerPage"];
    if (validUrlParameters) {
      searchParameters = urlQuery;
    }
    return {
      ...searchParameters,
    };
  };
  const [search, setSearch] = useState(initSearch());

  useEffect(() => {
    urlQuery["active"] !== undefined && setValue("active", urlQuery["active"]);
    if (urlQuery["username"])
      urlQuery["username"] = urlQuery["username"].toString();
    urlQuery["username"] && setValue("username", urlQuery["username"]);

    return () => { };
  }, []);

  const history = useHistory();

  const pushUrlSearch = (param) => {
    const queryParams = [];
    for (const i in param) {
      encodeURIComponent(param[i]) &&
        queryParams.push(
          encodeURIComponent(i) + "=" + encodeURIComponent(param[i])
        );
    }
    const queryString = queryParams.join("&");

    const url = history.createHref({
      pathname: "/admin/users",
      search: "?" + queryString,
    });
    windowUrl(url);
    //this will not effect on history.location.search
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      (event.type === "keydown" || event.type === "submit") &&
      (event.type === "submit" || event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerState({ ...drawerState, [anchor]: open });
  };
  const filterAnchor = screenWidth === "xs" ? "bottom" : "left";

  const onSubmit = (data) => {
    filterAnchor === "bottom" &&
      setDrawerState({ ...drawerState, [filterAnchor]: false });
    let handledData = {
      active: data.active,
      username: data.username.toString(),
      roleId: data.roleId,
      accountId: data.accountId,
      page: 0,
    };
    data["active"] === "" && delete handledData["active"];

    for (const key in handledData) {
      if (
        handledData[key] === undefined ||
        handledData[key] === "" ||
        handledData[key] === null
      ) {
        delete handledData[key];
      }
    }
    setSearch((prev) => ({
      ...handledData,
      refetch: !prev.refetch,
    }));
    for (const key in handledData) {
      if (handledData[key] === null) {
        delete handledData[key];
      }
    }
    pushUrlSearch({
      ...handledData,
      rowsPerPage: rowsPerPage,
    });
  };

  const handleChangePage = (event, newPage) => {
    pushUrlSearch({
      ...urlQuery,

      page: newPage,
      rowsPerPage: rowsPerPage,
    });

    setSearch((prev) => ({ ...prev, page: newPage }));
  };

  const handleChangeRowsPerPage = (event) => {
    pushUrlSearch({
      ...urlQuery,
      page: 0,
      rowsPerPage: +event.target.value,
    });

    setRowsPerPage(+event.target.value);
    setSearch((prev) => ({ ...prev, page: 0 }));
  };

  const handelAutocompleteDefaultValue = (data, id, fieldName) => {
    const defaultValue = data[Object.keys(data)[0]].find(
      (i) => i.id === parseInt(id)
    );
    defaultValue &&
      setAutocompleteValues((prev) => ({
        ...prev,
        [fieldName]: defaultValue,
      }));
  };
  const parseData = (data) => {
    return data;
  };

  const { data, loading } = useQuery(
    gql`
      ${LIST_USERS.query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: {
        first: rowsPerPage,
        page: search?.page + 1,
        input: {
          ...(search.active != null && { active: search.active }),
          ...(search.username && { username: search.username }),
          ...(search.roleId && { roleId: search.roleId }),
          ...(search.accountId && { accountId: search.accountId }),
        },
      },
    }
  );
  const listUsers =
    data?.listUsers?.data !== null ? data?.listUsers?.data : null;
  let tableBody;
  tableBody = (
    <Grid
      container
      justifyContent="center"
      spacing={3}
      className={clsx(classes.content, classes.mainSearch, {
        [classes.contentShift]: drawerState[filterAnchor],
      })}
    >
      {/* *******Table******* */}
      <Toolbar className={clsx(classes.toolbar)} variant="dense">
        <Typography
          className={classes.toolbarHeader}
          color="inherit"
          variant="h6"
        >
          {t("users")}
        </Typography>
        <IconButton onClick={toggleDrawer(filterAnchor, true)} size="large">
          <FilterList fontSize="inherit" />
        </IconButton>
        <SecuredNavLink
          permission="core.user.create"
          to={{ pathname: `/admin/users/create` }}
        >
          <Tooltip title="Add user">
            <IconButton aria-label="Add user" size="large">
              <Add color="primary" />
            </IconButton>
          </Tooltip>
        </SecuredNavLink>
      </Toolbar>

      {loading || listUsers?.length === 0 ? (
        <Grid container item justifyContent="center" className={classes.main}>
          {loading ? (
            <FullScreenLoading minHeight={30} />
          ) : (
            <Box textAlign="center">
              <img width="50%" src={emptyList} alt={"delivery box"} />
              <Box
                component={Typography}
                variant="h6"
                color="text.secondary"
                marginTop="0"
              >
                {t("searchNoResult")}
              </Box>
            </Box>
          )}
        </Grid>
      ) : (
        <Paper className={classes.tablePaper}>
          <TableFixedHeaderWraper>
            <Table aria-labelledby="tableTitle" aria-label="enhanced table">
              <TableHead>
                <TableRow>
                  <FixedTableCell>{t("username")}</FixedTableCell>
                  <FixedTableCell>{t("userType")}</FixedTableCell>
                  <FixedTableCell>{t("account")}</FixedTableCell>
                  <FixedTableCell>{t("role")}</FixedTableCell>
                  <FixedTableCell>{t("active")}</FixedTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {listUsers &&
                  listUsers?.map((row, index) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                        <CellLink pathname={`/admin/users/${row?.id}`}>
                          {row?.username}
                        </CellLink>
                        <FixedTableCell>{row?.role?.name}</FixedTableCell>
                        {row?.account ? (
                          <CellLink
                            pathname={
                              row.role.code === "CSTMR"
                                ? `/admin/customers/${row?.account?.id}`
                                : `/admin/delivery-agents/${row?.account?.id}`
                            }
                          >
                            {row?.account?.name}
                          </CellLink>
                        ) : (
                          <FixedTableCell></FixedTableCell>
                        )}
                        {row?.role ? (
                          <CellLink pathname={`/admin/roles/${row?.role?.id}`}>
                            {row?.role?.name}
                          </CellLink>
                        ) : (
                          <FixedTableCell></FixedTableCell>
                        )}
                        <FixedTableCell>
                          {row?.active ? (
                            <Icon className={classes.iconColor}>
                              check_circle_outline
                            </Icon>
                          ) : (
                            <Icon color="error">highlight_off</Icon>
                          )}
                        </FixedTableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableFixedHeaderWraper>
          <MUITablePagination
            count={data?.listUsers?.paginatorInfo?.total}
            rowsPerPage={rowsPerPage}
            page={!data?.listUsers ? 0 : search?.page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      )}
    </Grid>
  );

  return (
    <Root>
      <Drawer
        className={clsx(classes.drawer)}
        variant="persistent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor={filterAnchor}
        open={drawerState[filterAnchor]}
        onClose={() => false}
        dir={theme.direction}
      >
        <div className={classes.drawerHeader} />
        <Toolbar
          variant="dense"
          className={classes.toolbar}
          sx={{ marginBottom: 1 }}
        >
          <Typography
            className={classes.titles}
            color="inherit"
            variant="h6"
            component="div"
          >
            {t("search")}
          </Typography>
          <IconButton
            aria-label="done"
            size="small"
            onClick={toggleDrawer(filterAnchor, false)}
            color={"primary"}
          >
            <Clear />
          </IconButton>
        </Toolbar>

        <Grid
          container
          item
          justifyContent="center"
          alignContent="space-between"
          component={"form"}
          onSubmit={handleSubmit(onSubmit)}
          className={classes.searchForm}
        >
          <Grid
            container
            item
            sm={12}
            justifyContent="flex-start"
            spacing={1}
            className={classes.searchField}
          >
            <Grid container item sm={12} alignItems="flex-start">
              <ControlMUItextField
                control={control}
                errors={errors}
                name={"username"}
                label={t("username")}
              />
            </Grid>
            <Grid container item sm={12} alignItems="flex-start">
              <MUIselect
                name={"active"}
                label={t("active")}
                control={control}
                errors={errors}
                data={[
                  { key: t("notActive"), value: false },
                  { key: t("active"), value: true },
                ]}
              />
            </Grid>
            <Grid container item sm={12} alignItems="flex-start">
              <CustomAutocomplete
                control={control}
                errors={errors}
                name={"roleId"}
                label={t("role")}
                onChangeValue={(e) => {
                  setRoleCode(e.code);
                  setValue("accountId", "");
                }}
                onCompleted={(data) => {
                  const role = data[Object.keys(data)[0]].find(
                    (i) => i.id === urlQuery["roleId"]
                  )?.code;
                  if (role === "DLVRY" || role === "CSTMR") setRoleCode(role);

                  handelAutocompleteDefaultValue(
                    data,
                    urlQuery["roleId"],
                    "role"
                  );
                }}
                parseData={(data) => parseData(data)}
                query={LIST_ROLES_DROPDOWN.query}
                defaultValue={autocompleteValues.role}
              />
            </Grid>
            <Grid container item sm={12} alignItems="flex-start">
              {(roleCode === "DLVRY" || roleCode === "CSTMR") && (
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  name={"accountId"}
                  label={t("account")}
                  parseData={(data) => parseData(data)}
                  query={
                    roleCode === "CSTMR"
                      ? LIST_CUSTOMERS_DROPDOWN.query
                      : LIST_DELIVERY_AGENTS_DROPDOWN.query
                  }
                  onCompleted={(data) =>
                    handelAutocompleteDefaultValue(
                      data,
                      urlQuery["accountId"],
                      "account"
                    )
                  }
                  variables={{
                    input: {
                      active: true,
                    },
                  }}
                  defaultValue={autocompleteValues.account}
                />
              )}
            </Grid>
          </Grid>
          <Grid
            container
            item
            sm={12}
            className={clsx(classes.searchPadding, classes.button)}
            alignItems="center"
            alignContent="flex-end"
            justifyContent="flex-end"
          >
            <Button
              className={clsx(classes.btnMargin)}
              variant="contained"
              color="primary"
              type="submit"
              size="large"
              startIcon={<Search />}
              disabled={loading}
            >
              {t("search")}
            </Button>
          </Grid>
        </Grid>
      </Drawer>
      {tableBody}
    </Root>
  );
};

export default ListUsers;
