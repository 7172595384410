import {
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import clsx from "clsx";
import React, { useEffect, useState } from "react";

import { Add, Clear, FilterList } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { urlParameters } from "../HOC/CustomFunctions/urlParameters";

import { useHistory } from "react-router";
import { Can } from "../HOC/CustomComponents/Secured";
import SearchTable, { SEARCH, defaultQueryFields } from "../ShipmentSearch/SearchTable";

import moment from "moment";
import FiltersForm from "../../Hooks/shipmentListFilters";
import useWidth from "../../Hooks/useWidth";
import HFWraper from "../../Layout/WraperHeaderFooter";
import { windowUrl } from "../HOC/CustomFunctions/pushUrl";
import ExportShipments from "./ExportShipments";
import CreateShipmentInDialog from "./CreateShipmentInDialog";
import { RiPrinterCloudFill } from "react-icons/ri";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import { useForm } from "react-hook-form";
import { RECEIVE_SHIPMENTS_IN_WAREHOUSE } from "./Graphql";
import { gql, useMutation } from "@apollo/client";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import { LIST_WAREHOUSE_DROPDOWN } from "../../GlobalsQuery/ListDropdown/ListDropdown";
import { useSnackbar } from "notistack";
import { Globals } from "../HOC/Classes/Globals";
import PrintComponent from "./PrintComponent";

const PREFIX = "Shipments";

const classes = {
  button: `${PREFIX}-button`,
  btnMargin: `${PREFIX}-btnMargin`,
  mainSearch: `${PREFIX}-mainSearch`,
  titles: `${PREFIX}-titles`,
  toolbar: `${PREFIX}-toolbar`,
  searchForm: `${PREFIX}-searchForm`,
  searchField: `${PREFIX}-searchField`,
  overlay: `${PREFIX}-overlay`,
  searchPadding: `${PREFIX}-searchPadding`,
  drawer: `${PREFIX}-drawer`,
  drawerPaper: `${PREFIX}-drawerPaper`,
  content: `${PREFIX}-content`,
  contentShift: `${PREFIX}-contentShift`,
};

const drawerWidth = 240;

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.button}`]: {
    position: "sticky",
    bottom: 0,
    zIndex: 3,
    backgroundColor: "#fff",
    marginTop: 10,
    padding: 7,
  },

  [`& .${classes.btnMargin}`]: {
    width: "100%",
  },

  [`& .${classes.mainSearch}`]: {
    margin: theme.spacing(0),
    width: "100%",
  },

  [`& .${classes.titles}`]: {
    flex: "1 1 100%",
  },

  [`& .${classes.toolbar}`]: {
    borderBottom: "1px #ccd1d6 solid",
    backgroundColor: "#f5f7f9",
    width: "100%",
    justifyContent: "end",
  },

  [`& .${classes.searchForm}`]: {
    overflowY: "scroll",
    width: "100%",
    margin: 0,
  },

  [`& .${classes.searchField}`]: {
    padding: theme.spacing(1, 1, 0, 1),
    position: "relative",
    overflow: "hidden",
  },

  [`& .${classes.overlay}`]: {
    width: "100%",
    height: "100%",
    position: "absolute",
    backgroundColor: "#ffffffa8",
    zIndex: 2,
  },

  [`& .${classes.searchPadding}`]: {
    padding: theme.spacing(1),
  },

  //////////////////////Drawer///////////////////

  [`& .${classes.drawer}`]: {
    width: drawerWidth,
    flexShrink: 0,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },

  [`& .${classes.drawerPaper}`]: {
    width: drawerWidth,

    zIndex: "250",

    left: "initial",
    right: "initial",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },

  [`& .${classes.content}`]: {
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
  },

  [`& .${classes.contentShift}`]: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: "auto",
    width: "calc(100% - 240px)",
    [theme.breakpoints.down("sm")]: {
      margin: "auto",
      width: "auto",
    },
  },
}));

//*********Table Function*********

const Shipments = (props) => {
  const urlQuery = urlParameters(window.location.search);
  const FILTER_INIT_DATE = {
    fromDate: moment(new Date())
      .locale("en")
      .subtract(1, "month")
      .add(1, "day")
      .format("YYYY-MM-DD"),
    toDate: moment(new Date()).locale("en").format("YYYY-MM-DD"),
  };

  const theme = useTheme();

  const { t } = useTranslation();

  const [checkedIds, setCheckedIds] = useState([]);
  const [checkedShipments, setCheckedShipments] = useState([]);

  const [tableLoad, setTableLoad] = useState(false);

  const screenWidth = useWidth();
  const { enqueueSnackbar } = useSnackbar();

  const [drawerState, setDrawerState] = React.useState({
    top: true,
    left: screenWidth === "xs" ? false : true,
    bottom: screenWidth === "xs" ? false : true,
    right: screenWidth === "xs" ? false : true,
  });

  const [rowsPerPage, setRowsPerPage] = useState(urlQuery["rowsPerPage"] ?? 20);
  const [page, setPage] = useState(urlQuery["page"] ?? 0);
  const [queryVariables, setQueryVariables] = useState({
    refetch: false,
    page: 0,
  });
  const [dateValue, setDateValue] = useState();
  const resetPage = () => setPage(0);

  // const { refetch, ...excelFilters } = queryVariables;
  const { refetch, page: pageNum, ...excelFilters } = queryVariables;

  const history = useHistory();

  const pushUrlSearch = (param) => {
    const queryParams = [];
    for (const i in param) {
      encodeURIComponent(param[i]) &&
        queryParams.push(
          encodeURIComponent(i) + "=" + encodeURIComponent(param[i])
        );
    }
    const queryString = queryParams.join("&");

    const url = history.createHref({
      pathname: "/admin/shipments",
      search: "?" + queryString,
    });
    windowUrl(url);
    //this will not effect on history.location.search
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      (event.type === "keydown" || event.type === "submit") &&
      (event.type === "submit" || event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerState({ ...drawerState, [anchor]: open });
  };
  const filterAnchor = screenWidth === "xs" ? "bottom" : "left";

  const onSubmitFunc = () => {
    filterAnchor === "bottom" &&
      setDrawerState({ ...drawerState, [filterAnchor]: false });
  };

  //////////Table Function////////////
  const handleChangePage = (event, newPage) => {
    pushUrlSearch({
      ...urlQuery,
      ...dateValue,
      page: newPage,
      rowsPerPage: rowsPerPage,
    });
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    pushUrlSearch({
      ...urlQuery,
      ...dateValue,
      page: 0,
      rowsPerPage: +event.target.value,
    });

    setRowsPerPage(+event.target.value);
    resetPage();
  };

  const clearCheckBox = () => setCheckedIds([]);

  const [openShipmentDialog, setOpenShipmentDialog] = useState(false);
  const openShipmentDialogHandler = () => {
    setOpenShipmentDialog(true);
  };
  const closeShipmentDialogHandler = () => {
    setOpenShipmentDialog(false);
  };
  const [autocompleteValues, setAutocompleteValues] = useState({
    section: null
  });
  const { handleSubmit, control, formState, setError, setValue } = useForm();
  const { errors } = formState;

  const [dialogState, setDialogState] = useState(false);
  const [openPrintDialog, setOpenPrintDialog] = React.useState(false);

  const selectDefaultValue = (data) => {
    return data;
  };

  const [receiveShipmentsInWarehouse, { loading: receiveShipmentsInWarehouseLoading, client }] = useMutation(
    gql`
      ${RECEIVE_SHIPMENTS_IN_WAREHOUSE.query}
    `
  );

  const handelShipmentInWarehouse = () => {
    setDialogState(true);
  };

  const handleDialogClose = () => {
    setDialogState(false);
    setAutocompleteValues({
      section: null
    })
    setValue("sectionId", "")
  };

  const afterSave = () => {
    handleDialogClose();
    client.refetchQueries({
      include: [
        gql`
              ${SEARCH(defaultQueryFields(adminNotesPermission)).query}
            `,
      ],
    });
    setCheckedIds([]);
  }

  const [disableInWarehousePrint, setReceiveInWarehousePrint] = useState(true)
  const shipmentsSelected = checkedShipments.filter((i) => checkedIds.includes(i.id))
  useEffect(() => {
    const canReceive = shipmentsSelected.every((e) => e.status.code === "PKR") && shipmentsSelected.length > 0
    setReceiveInWarehousePrint(!canReceive)
  }, [shipmentsSelected])


  const submitData = (data) => {
    receiveShipmentsInWarehouse({
      variables: {
        input: {
          id: checkedIds,
          ...(data.sectionId && { sectionId: data.sectionId })
        },
      },
    })
      .then((res) => {
        setOpenPrintDialog(true)
        handleDialogClose()
        enqueueSnackbar(t("saveSuccessful"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      })
      .catch(({ graphQLErrors }) => {
        const shipmentsSelect = checkedShipments.filter((i) => checkedIds.includes(i.id))
        const shipmentsErrorId = Object.entries(graphQLErrors[0].extensions.validation).map((shipment) => parseInt(shipment[0].split(".")[2]))
        const shipmentsError = shipmentsSelect.filter((i, index) => shipmentsErrorId.includes(index))
        const Errors = Object.entries(graphQLErrors[0].extensions.validation).map((shipment) => shipment[1])
        const errorMsg = (
          <List
            sx={{
              width: '100%',
              '& ul': { padding: 0 },
            }}
            subheader={<li />}
          >
            {shipmentsError.slice(0, 3).map((shipment, index) => (
              <li key={shipment.id} style={{ marginBottom: index === shipmentsError.length - 1 ? 0 : 16 }}>
                <ul>
                  <ListItem sx={{ p: 0, fontSize: 15 }}>
                    {`${t('shipment')} ${shipment.code}`}
                  </ListItem>
                  {Errors[index].map((item, index) => (
                    <ListItem sx={{ p: 0, fontSize: 12 }} key={index}>
                      <ListItemAvatar sx={{ minWidth: "20px" }}>
                        -
                      </ListItemAvatar>
                      <ListItemText primary={`${item}`} />
                    </ListItem>
                  ))}
                </ul>
              </li>
            ))}
            {shipmentsError.length > 3 && <ListItem sx={{ p: 0, fontSize: 15 }}>
              {t('moreShipmentErrors')}
            </ListItem>}
          </List>
        )
        setCheckedIds([]);

        handleDialogClose();
        enqueueSnackbar(errorMsg, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      });
  }
  const user = Globals.user;

  const adminNotesPermission = user.hasPermission(
    "shipping.shipment.view_admin_note"
  );

  return (
    <Root>
      {openPrintDialog && (
        <PrintComponent
          shipmentsId={checkedIds}
          // oneShipment
          // hidden={["3d"]}
          noIcon={true}
          openPrintDialogAfterSave={openPrintDialog}
          type={"receiveShipments"}
          afterSave={afterSave}
        />
      )}
      <Dialog
        open={dialogState}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"xs"}
      >
        <form onSubmit={handleSubmit(submitData)}>
          <DialogTitle id="alert-dialog-title">
            {t("receiveShipmentInWarehouse")}
          </DialogTitle>
          <DialogContent>
            <CustomAutocomplete
              control={control}
              errors={errors}
              name={"sectionId"}
              label={t("wareHouse")}
              parseData={(data) => selectDefaultValue(data)}
              query={LIST_WAREHOUSE_DROPDOWN.query}
              defaultValue={autocompleteValues.section}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="primary">
              {t("cancel")}
            </Button>
            <Button color="primary" autoFocus disabled={receiveShipmentsInWarehouseLoading} type="submit">
              {receiveShipmentsInWarehouseLoading ? <ButtonLoading /> : t("confirmAndPrint")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Drawer
        className={clsx(classes.drawer)}
        variant="persistent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor={filterAnchor}
        open={drawerState[filterAnchor]}
        onClose={() => false}
        dir={theme.direction}
      >
        <HFWraper />
        <Toolbar
          variant="dense"
          className={classes.toolbar}
          sx={{ marginBottom: 1 }}
        >
          <Typography
            className={classes.titles}
            color="inherit"
            variant="h6"
            component="div"
          >
            {t("search")}
          </Typography>
          <IconButton
            aria-label="done"
            size="small"
            onClick={toggleDrawer(filterAnchor, false)}
            color={"primary"}
          >
            <Clear />
          </IconButton>
        </Toolbar>
        <FiltersForm
          {...{ onSubmitFunc, resetPage, rowsPerPage, loading: tableLoad }}
          queryVariables={(variables) => setQueryVariables(variables)}
          removeResetDate={true}
          dateValue={(date) => setDateValue(date)}
          initData={FILTER_INIT_DATE}
          pathname="shipments"
          clearCheckBox={clearCheckBox}
          filtersAllowed={[
            "date",
            "dlvAtemp",
            "status",
            "packageType",
            "new",
            "branch",
            "originBranch",
            "businessType",
            "custm",
            "dlvAgent",
            "zone",
            "subZone",
            "refNumber",
            "service",
            "deliveryType",
            "returnType",
            "paymentType",
            "collection",
            "paid",
          ]}
        />

      </Drawer>

      <Grid
        container
        justifyContent="center"
        spacing={3}
        className={clsx(classes.content, classes.mainSearch, {
          [classes.contentShift]: drawerState[filterAnchor],
        })}
      >
        {/* *******Table******* */}

        <Toolbar className={classes.toolbar} variant="dense">
          <IconButton onClick={toggleDrawer(filterAnchor, true)} size="large">
            <FilterList fontSize="inherit" />
          </IconButton>
          <Can permission="shipping.shipment.create" showException>
            <IconButton
              onClick={openShipmentDialogHandler}
              color={"primary"}
              size="large"
            >
              <Add fontSize="inherit" />
            </IconButton>
          </Can>
          <ExportShipments
            disabled={checkedIds.length > 0}
            filters={excelFilters}
          />

          <Can showException permission="shipping.shipment.print">
            <PrintComponent shipmentsId={checkedIds} />
          </Can>
          <Can showException permission="shipping.shipment.receive_in_warehouse">
            <IconButton
              disabled={disableInWarehousePrint}
              onClick={() => handelShipmentInWarehouse()}
              size="large"
              color="primary"
            >
              <RiPrinterCloudFill fontSize="inherit" />
            </IconButton>
          </Can>
        </Toolbar>

        <CreateShipmentInDialog
          open={openShipmentDialog}
          onClose={closeShipmentDialogHandler}
        />

        {queryVariables && (
          <SearchTable
            {...props}
            onPageChange={handleChangePage}
            onChangeRows={handleChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            queryVariables={queryVariables}
            loading={(param) => setTableLoad(param)}
            setCheckedIds={(ids) => setCheckedIds(ids)}
            setCheckedShipments={(shipments) => setCheckedShipments(shipments)}
            checkedIds={checkedIds}
            withCheckAll
          />
        )}
      </Grid>
    </Root>
  );
};
export default Shipments;
