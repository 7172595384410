import React, { useEffect } from "react";
import { styled } from '@mui/material/styles';
import clsx from "clsx";
import { Paper, Typography, Collapse, Box, InputAdornment } from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { SAVE_PRODUCT, PRODUCTID, SETTINGS } from "./Graphql";
import { useMutation, gql, useQuery } from "@apollo/client";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { useState } from "react";
import FormButton from "../CustomComponents/Buttons/FormButton";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import { useSnackbar } from "notistack";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import {
  LIST_CUSTOMERS_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import MuiSwitch from "../HOC/MUI/MUIswitch";
import Grid from "@mui/material/Unstable_Grid2";
import { Globals } from "../HOC/Classes/Globals";

const PREFIX = 'ProductForm';

const classes = {
  spacing: `${PREFIX}-spacing`,
  mainGrid: `${PREFIX}-mainGrid`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.spacing}`]: {
    width: "100%",
    margin: theme.spacing(2, 0, 0, 0),
  },

  [`& .${classes.mainGrid}`]: {
    width: "100%",
    margin: theme.spacing(0),
    padding: theme.spacing(2),
  }
}));

const ProductForm = (props) => {
  const [autocompleteValues, setAutocompleteValues] = useState({
    customer: null,
  });

  const customer = Globals.user.account

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { handleSubmit, control, formState, setValue, setError } =
    useForm();
  const { errors } = formState;
  const productId = parseInt(props.match.params.id);

  useEffect(() => {
    if (!productId) {
      setValue("active", true);
    }

    return () => { };
  }, []);

  const selectDefaultValue = (data) => {
    return data;
  };

  const { data: settings } = useQuery(
    gql`
      ${SETTINGS.query}
    `,
    {
      fetchPolicy: "no-cache",
    }
  );

  const { data: updateProduct } = useQuery(
    gql`
      ${PRODUCTID.query}
    `,
    {
      skip: !productId,
      notifyOnNetworkStatusChange: true,
      variables: { id: productId },
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        const productData = data.product;
        const productParams = [
          "id",
          "name",
          "code",
          "weight",
          "price",
        ];
        productParams.forEach((i) => {
          productData[i] !== null && setValue(i, productData[i]);
        });
        setValue("active", productData["active"]);

        setAutocompleteValues({
          customer: productData?.customer,
        });
      },
    }
  );

  const [saveWareHouse, { loading: saveProductLoading }] = useMutation(
    gql`
      ${SAVE_PRODUCT.query}
    `
  );

  const onSubmit = (data) => {

    for (const key in data) {
      if (data[key] === "") {
        data[key] = null;
      }
      if (customer && key === "customerId") {
        delete data[key]
      }
    }

    saveWareHouse({
      variables: {
        input: {
          ...data,
          weight: parseFloat(data.weight),
          price: parseFloat(data.price),
        },
      },
    })
      .then((data) => {
        pushUrl(props, `/admin/products/${data?.data?.saveProduct?.id}`);
        // window.location.reload(true);
        enqueueSnackbar(t("saveSuccessful"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      })
      .catch(({ graphQLErrors }) => {
        setValidationError(graphQLErrors, setError);
        console.log(graphQLErrors);
      });
  };

  const currencyCode = settings?.shippingSettings?.localCurrency?.name ?? "";

  const body = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid
        container
        justifyContent="flex-start"
        aligns="center"
        className={clsx(classes.mainGrid)}
        spacing={2}
      >
        <Paper container component={Grid} className={clsx(classes.spacing)}>
          <Grid container justifyContent="space-between" xs={12}>
            <Typography variant="h6">{t("product")}</Typography>
            <Box>
              <MuiSwitch
                edge="end"
                name="active"
                label={t("active")}
                control={control}
              />
            </Box>
          </Grid>
          <Grid xs={12} sm={4} aligns="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"code"}
              label={t("code")}
            />
          </Grid>
          <Grid xs={12} sm={8} aligns="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"name"}
              label={t("name")}
              rules={{ required: t("fieldIsRequired") }}
            />
          </Grid>
          {!customer && (
            <Grid xs={12} sm={4} aligns="flex-start">
              <CustomAutocomplete
                control={control}
                errors={errors}
                name={"customerId"}
                label={t("customer")}
                disabled={Boolean(productId)}
                rules={{ required: t("fieldIsRequired") }}
                parseData={(data) => selectDefaultValue(data)}
                query={LIST_CUSTOMERS_DROPDOWN.query}
                variables={{ input: { warehousing: true } }}
                defaultValue={autocompleteValues.customer}
              />
            </Grid>
          )}
          <Grid xs={12} sm={4} aligns="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              type={"number"}
              name={"weight"}
              label={t("weight")}
              rules={{
                required: t("fieldIsRequired"),
                min: {
                  value: 0,
                  message: t("weightValidation"),
                },
              }}
            />
          </Grid>
          <Grid xs={12} sm={customer ? 8 : 4} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              type={"number"}
              name={"price"}
              label={t("price")}
              InputProps={{
                ...(currencyCode && {
                  endAdornment: (
                    <InputAdornment position="end">
                      {currencyCode}
                    </InputAdornment>
                  ),
                }),
              }}
              rules={{ required: t("fieldIsRequired") }}
            />
          </Grid>


        </Paper>
        <Grid container justifyContent="flex-end" className={classes.spacing}>
          <FormButton disabled={saveProductLoading}>
            {saveProductLoading ? <ButtonLoading /> : t("save")}
          </FormButton>
        </Grid>
      </Grid>
    </form>
  );
  let DOM;

  if (productId) {
    DOM = updateProduct ? body : <FullScreenLoading minHeight="10%" />;
  } else {
    DOM = body;
  }

  return <Root>{DOM}</Root>;
};

export default ProductForm;
