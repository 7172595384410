import { gql, useMutation } from "@apollo/client";
import {
  Box,
  Button,
  Checkbox,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";
import * as React from "react";
import { useState } from "react";

import * as gqlb from "gql-query-builder";
import { Fragment, useEffect } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import config from "../../config.json";
// import { Globals } from "../HOC/Classes/Globals";
import CustomDialog from "../HOC/CustomComponents/CustomDialog";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";

export const EXPOERT_PICKUPS = gqlb.mutation({
  operation: "exportPaymentEntries",
  fields: [],
  variables: {
    input: {
      type: "ExportPaymentEntriesInput",
      required: true,
    },
  },
});

export default function ExportPickupsList(props) {
  const { id, disabled } = props;

  const [pickupsFieldsState, setPickupsFieldsState] = useState([]);
  const { t } = useTranslation();
  const pickupsFields = [
    { name: "CODE", label: t("requestCode") },
    { name: "DATE", label: t("date") },
    { name: "CUSTOMER_NOTES", label: t("notes") },
    { name: "DELIVERY_AGENT_NOTES", label: t("cancellationReasons") },
    { name: "SHIPMENTS_COUNT", label: t("expectedShipments") },
    { name: "PICKED_COUNT", label: t("pickedShipments") },
    { name: "STATUS", label: t("status") },
    { name: "VEHICLE", label: t("vehicle") },
    { name: "BRANCH", label: t("branch") },
    { name: "CUSTOMER", label: t("customer") },
    { name: "DELIVERY_AGENT", label: t("shippingAgent") },
  ];

  useEffect(() => {
    if (!pickupsFields[0].id)
      pickupsFields.forEach((ele, index) => {
        ele["id"] = index + 1;
        ele["checked"] = true;
      });

    const localStorageExportPickups = localStorage.getItem("export-pickups");
    const parsedExportPickups = localStorageExportPickups
      ? JSON.parse(localStorageExportPickups)
      : null;

    const notCheckedFields = pickupsFields.filter((i) => {
      if (
        !parsedExportPickups?.includes(i.name) &&
        parsedExportPickups !== null
      ) {
        i.checked = false;
        return true;
      }
      return false;
    });

    const checkedFields = pickupsFields.filter((i) => {
      if (parsedExportPickups?.includes(i.name)) {
        i.checked = true;
        return true;
      }
      return false;
    });

    const sortedFields = checkedFields.sort((a, b) => {
      return parsedExportPickups?.indexOf(a.name) >
        parsedExportPickups?.indexOf(b.name)
        ? 1
        : -1;
    });

    setPickupsFieldsState(
      localStorageExportPickups
        ? [...sortedFields, ...notCheckedFields]
        : pickupsFields
    );
    return () => { };
  }, [t]);

  const [dialogState, setDialogState] = useState(false);

  const fieldCount = pickupsFieldsState.length;
  const fieldSelected = pickupsFieldsState.filter((i) => i.checked).length;
  const onSelectAllClick = (e) => {
    const fileds = [...pickupsFieldsState];
    fileds.forEach((i) => {
      if (e.target.checked) {
        i.checked = true;
      } else {
        i.checked = false;
      }
    });
    setPickupsFieldsState(fileds);
  };

  const onCloseDialog = () => {
    setDialogState(false);
  };

  const onOpenDialog = () => {
    setDialogState(true);
  };

  let getBackendUri = (imgPath) => {
    const domain = config.backend.domain
      ? config.backend.domain
      : window.location.hostname;

    return `${config.backend.protocol}://${domain}:${config.backend.port}/${imgPath}`;
  };
  const [exportPickups, { loading }] = useMutation(
    gql`
      ${EXPOERT_PICKUPS.query}
    `,
    {
      onCompleted: (data) => {
        const path = data.exportPaymentEntries;
        onCloseDialog();
        window.open(getBackendUri(path));
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(pickupsFieldsState);
    const [reorderedItem] = items.splice(result.source.index, 1);
    const isNotChecked = items[result.destination.index - 1]?.checked;
    if (isNotChecked === false) return;
    items.splice(result.destination.index, 0, reorderedItem);

    setPickupsFieldsState(items);
  }

  const handelExportPickup = () => {
    const updatedFields = pickupsFieldsState.reduce((previous, current) => {
      if (current.checked) {
        previous.push(current.name);
      }
      return previous;
    }, []);
    localStorage.setItem("export-pickups", JSON.stringify(updatedFields));
    const fields = pickupsFieldsState
      .filter((i) => i.checked)
      .map((i) => ({ label: i.label, name: i.name }));
    exportPickups({
      variables: {
        input: {
          id: parseInt(id),
          pickupFields: fields
        },
      },
    });
  };

  return (
    <>
      <Tooltip title={t("exportExcel")}>
        <Box component="span">
          <IconButton
            disabled={disabled}
            onClick={() => onOpenDialog()}
            size="large"
          >
            <Icon>sim_card_download</Icon>
          </IconButton>
        </Box>
      </Tooltip>
      <CustomDialog
        fullWidth
        maxWidth="xs"
        open={dialogState}
        onClose={onCloseDialog}
        title={
          <Fragment>
            <Checkbox
              edge="start"
              indeterminate={fieldSelected > 0 && fieldSelected < fieldCount}
              checked={fieldCount > 0 && fieldSelected === fieldCount}
              onChange={onSelectAllClick}
              inputProps={{ "aria-label": "select all desserts" }}
            />
            {t("exportExcel")}
          </Fragment>
        }
        actions={
          <>
            <Button color="primary" onClick={onCloseDialog}>
              {t("cancel")}
            </Button>
            <Button
              color="primary"
              disabled={loading || fieldSelected === 0}
              onClick={handelExportPickup}
            >
              {loading ? <ButtonLoading /> : t("confirm")}
            </Button>
          </>
        }
        content={
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="characters">
              {(provided) => (
                <List
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  component="ul"
                  sx={{
                    width: "100%",
                    bgcolor: "background.paper",
                  }}
                >
                  {/* <ListItem dense button>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      indeterminate={
                        fieldSelected > 0 && fieldSelected < fieldCount
                      }
                      checked={fieldCount > 0 && fieldSelected === fieldCount}
                      onChange={onSelectAllClick}
                      inputProps={{ "aria-label": "select all desserts" }}
                    />
                  
                  </ListItemIcon>
                  <ListItemText primary={t("selectAll")} />
                </ListItem> */}

                  {pickupsFieldsState.map((value, index) => {
                    const labelId = `checkbox-list-label-${value.id}`;
                    return (
                      <Draggable
                        isDragDisabled={!value.checked}
                        key={value.id}
                        draggableId={value.name}
                        index={index}
                      >
                        {(provided) => (
                          <CustomeItemList
                            innerRef={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            itemDetails={value}
                            labelId={labelId}
                            state={pickupsFieldsState}
                            setState={setPickupsFieldsState}
                          />
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.label}
                </List>
              )}
            </Droppable>
          </DragDropContext>
        }
      />
    </>
  );
}

const CustomeItemList = (props) => {
  const { itemDetails, labelId, state, setState, innerRef, ...restProps } =
    props;
  const { label, checked } = itemDetails;
  // const [checkboxState,setCheckboxState] = React.state(checked)
  const handleToggle = (e) => {
    const checked = e.target.checked;
    const update = [...state];
    const checkedItemLength = update.filter((i) => i.checked).length;
    const index = update.findIndex((i) => i.name === itemDetails.name);
    const [sortItem] = update.splice(index, 1);

    sortItem["checked"] = checked;
    update.splice(checkedItemLength, 0, sortItem);

    setState(update);
  };
  return (
    <ListItem {...restProps} ref={innerRef} dense button>
      <ListItemIcon>
        <Checkbox
          edge="start"
          checked={checked}
          tabIndex={-1}
          onChange={handleToggle}
          disableRipple
          inputProps={{ "aria-labelledby": labelId }}
        />
      </ListItemIcon>
      <ListItemText id={labelId} primary={label} />
    </ListItem>
  );
};
