/* eslint-disable no-useless-concat */
import React, { Fragment } from "react";
import { styled } from '@mui/material/styles';
import { gql, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { SecuredNavLink } from "../HOC/CustomComponents/Secured";
import { Typography, Paper, Toolbar, IconButton, Icon } from "@mui/material";
import { Edit } from "@mui/icons-material";

import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { SHIPPING_SERVICE_ID } from "./Graphql";
import { KeyValuePair } from "../HOC/CustomComponents/KeyValuePair";
import { Globals } from "../HOC/Classes/Globals";
import Grid from "@mui/material/Unstable_Grid2";

const PREFIX = 'ShippingServiceView';

const classes = {
  loading: `${PREFIX}-loading`,
  paper: `${PREFIX}-paper`,
  toolbar: `${PREFIX}-toolbar`,
  toolbarIcons: `${PREFIX}-toolbarIcons`,
  iconButton: `${PREFIX}-iconButton`,
  iconColor: `${PREFIX}-iconColor`,
  main: `${PREFIX}-main`,
  field: `${PREFIX}-field`
};

const StyledGrid = styled(Grid)((
  {
    theme
  }
) => ({
  [`& .${classes.loading}`]: {},

  [`& .${classes.paper}`]: {
    padding: theme.spacing(3),
    flexGrow: 1,
  },

  [`& .${classes.toolbar}`]: {
    borderBottom: "1px #ccd1d6 solid",
    backgroundColor: "#f5f7f9",
    width: "100%",
  },

  [`& .${classes.toolbarIcons}`]: {
    flex: "1 1 100%",
    textAlign: "end",
  },

  [`& .${classes.iconButton}`]: {
    borderRadius: "0 4px 4px 0 ",
    boxShadow: "none",
    height: 45,
  },

  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },

  [`& .${classes.main}`]: {
    margin: 0,
    width: "100%",
  },

  [`& .${classes.field}`]: {
    width: 260,
    [theme.breakpoints.down("sm")]: {
      width: 170,
    },
  }
}));

const ShippingServiceView = (props) => {

  const shippingServiceId = props.match.params.id?.trim();
  const { t } = useTranslation();
  const { loading, data } = useQuery(
    gql`
      ${SHIPPING_SERVICE_ID.query}
    `,
    {
      fetchPolicy: "network-only",
      variables: { id: parseInt(shippingServiceId) },
    }
  );
  const user = Globals.user;
  const branchId = data?.shippingService?.branch?.id;
  const canAccessBranch = branchId ? user.canAccessBranch(branchId) : true;

  const canEdit = user.hasPermission("shipping.shipping_service.update")
    ? canAccessBranch
    : false;
  const shipmentTypes = data?.shippingService?.shipmentTypes
    ?.map((i) => i.name)
    .toString()
    .replace(/,/g, " , ");
  return (
    <StyledGrid
      container
      justifyContent="center"
      className={classes.mainTracking}
      sx={{ margin: "0", width: "100%" }}
    >
      {loading ? (
        <FullScreenLoading minHeight="25%" />
      ) : (
        <>
          <Fragment>
            <Toolbar className={clsx(classes.toolbar)} variant="regular">
              <Grid
                container
                sx={{ flexWrap: "initial", flexGrow: 1 }}
                alignItems="center"
              >
                <Grid className={classes.field}>
                  <Typography variant="h6">
                    {t(`shippingServiceDetails`)}
                  </Typography>
                </Grid>
                <Grid className={classes.toolbarIcons}>
                  <SecuredNavLink
                    permission={canEdit}
                    to={{
                      pathname: `/admin/shipping-service/${shippingServiceId}/edit`,
                    }}
                  >
                    <IconButton color={"primary"} size="large">
                      <Edit fontSize="inherit" />
                    </IconButton>
                  </SecuredNavLink>
                </Grid>
              </Grid>
            </Toolbar>
          </Fragment>
          <Grid
            container
            justifyContent="center"
            sx={{ width: "100%", p: 2, gap: 2 }}
          >
            <Paper container className={classes.paper} component={Grid}>
              <KeyValuePair
                title={t("code")}
                value={data?.shippingService?.code}
              />
              <KeyValuePair
                title={t("name")}
                value={data?.shippingService?.name}
              />
              <KeyValuePair
                title={t("active")}
                value={
                  data?.shippingService?.active ? (
                    <Icon className={classes.iconColor}>
                      check_circle_outline
                    </Icon>
                  ) : (
                    <Icon color="error">highlight_off</Icon>
                  )
                }
              />
              <KeyValuePair
                title={t("shippingDays")}
                value={data?.shippingService?.days}
              />
              <KeyValuePair
                title={t("branch")}
                value={data?.shippingService?.branch?.name}
              />
              <KeyValuePair
                title={t("deliveryAccount")}
                value={data?.shippingService?.deliveryAccount?.name}
              />
              <KeyValuePair
                title={t("collectionAccount")}
                value={data?.shippingService?.collectionAccount?.name}
              />
              <KeyValuePair
                title={t("commissionAccount")}
                value={data?.shippingService?.commissionAccount?.name}
              />
              <KeyValuePair title={t("shipmentType")} value={shipmentTypes} />
            </Paper>
          </Grid>
        </>
      )}
    </StyledGrid>
  );
};

export default ShippingServiceView;
