import * as gqlb from "gql-query-builder";

export const SUM_MANIFEST_QUERY = gqlb.query({
  operation: "sumManifests",
  fields: ["shipmentsCount","returnedValue"],
  variables: {
    input: {
      type: "ListManifestsFilterInput",
      required: true,
    },
  },
});
export const LISTMANIFESTS_QUERY = gqlb.query({
  operation: "listManifests",
  fields: [
    {
      operation: "paginatorInfo",
      fields: [
        "count",
        "currentPage",
        "total",
        "firstItem",
        "hasMorePages",
        "lastItem",
        "lastPage",
        "perPage",
      ],
      variables: {},
    },
    {
      operation: "data",
      fields: [
        "code",
        "id",
        "notes",
        "date",
        "approved",
        "sacksCount",
        "shipmentsCount",
        "returnedValue",
        {
          branch: ["id", "name"],
        },
        {
          transferType: ["id", "code", "name"],
        },
        {
          referenceBranch: ["id", "name"],
        },
        {
          transactionType: ["id", "name"],
        },
        {
          deliveryAgent: ["name", "id"],
        },
        {
          referenceManifest: ["id", "code"],
        },
        {
          customer: ["name", "id"],
        },
      ],
      variables: {},
    },
  ],
  variables: {
    input: {
      type: "ListManifestsFilterInput",
      required: true,
    },
    first: {
      type: "Int",
      required: true,
      value: 15,
    },
    page: {
      type: "Int",
      value: 1,
    },
  },
});

export const manifestFields = [
  "id",
  "date",
  "code",
  "approved",
  "sacksCount",
  "notes",
  "shipmentsCount",
  {
    referenceBranch: ["id", "name"],
  },
  {
    referenceManifest: ["id", "code"],
  },
  {
    referredManifests: ["id", "code"],
  },
  {
    branch: ["id", "name"],
  },
  {
    updatedBy: ["id", "username"],
  },
  {
    createdBy: ["id", "username"],
  },
  {
    transferType: ["id", "code", "name"],
  },
  {
    transactionType: [
      "id",
      "code",
      "name",
      {
        type: ["code", "name"],
      },
    ],
  },
  {
    deliveryAgent: ["id", "name", "code"],
  },
  {
    route: ["id", "name"],
  },
  {
    customer: [
      "id",
      "name",
      "phone",
      "address",
      "code",
      {
        zone: ["name"],
      },
      {
        subzone: ["name"],
      },
    ],
  },
  {
    operation: "manifestShipments",
    fields: [
      "fees",
      "notes",
      "deliveryDate",
      { warehouseSection: ["id", "name"] },
      { cancellationReason: ["id", "name"] },
      { returnType: ["code", "name"] },
      {
        operation: "shipment",
        fields: [
          "id",
          "code",
          "recipientName",
          "recipientAddress",
          "recipientMobile",
          "recipientPhone",
          "description",
          "weight",
          "price",
          "piecesCount",
          "totalAmount",
          "senderName",
          "senderAddress",
          "senderMobile",
          "senderPhone",
          "returningDueFees",
          "customerDue",
          "amount",
          "returnedValue",
          "allDueFees",
          "notes",
          {
            transactions: [{ returnType: ["name"] }],
          },
          {
            warehouseSection: ["id", "name"],
          },
          {
            status: ["name"],
          },
          {
            paymentType: ["name", "code"],
          },
          {
            returnType: ["name"],
          },
          {
            type: ["name", "code"],
          },
          {
            openable: ["code"],
          },
          {
            recipientZone: ["id", "name"],
          },
          {
            recipientSubzone: ["name"],
          },
          {
            senderZone: ["id", "name"],
          },
          {
            senderSubzone: ["name"],
          },
          {
            customer: [
              "id",
              "name",
              "mobile",
              { zone: ["id", "name"] },
              { subzone: ["name"] },
            ],
          },
        ],
        variables: {},
      },
    ],
    variables: {},
  },
];
export const MANIFEST_BY_ID_QUERY = gqlb.query({
  operation: "manifest",
  fields: manifestFields,
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
export const MANIFEST_BY_ID_AND_PRINT = gqlb.query([
  {
    operation: "manifest",
    fields: manifestFields,
    variables: {
      id: {
        type: "Int",
        required: true,
      },
    },
  },
  {
    operation: "organization",
    fields: [
      "name",
      "phone",
      "email",
      "website",
      "reportHeader",
      "reportFooter",
    ],
    variables: {},
  },
]);

export const SAVE_MANIFEST_MUTATION = gqlb.mutation({
  operation: "saveManifest",
  fields: manifestFields,
  variables: {
    input: {
      type: "ManifestInput",
      required: true,
    },
  },
});
export const DELETE_MANIFEST_MUTATION = gqlb.mutation({
  operation: "deleteManifest",
  fields: [],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
export const APPROVE_MANIFEST_MUTATION = gqlb.mutation({
  operation: "approveManifest",
  fields: manifestFields,
  variables: {
    input: {
      type: "ApproveManifestInput",
      required: true,
    },
  },
});

export const COMPLETE_MANIFEST_MUTATION = gqlb.mutation({
  operation: "completeManifest",
  fields: manifestFields,
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const DELIVERY_AGENT_ID = gqlb.query({
  operation: "deliveryAgent",
  fields: [
    {
      route: ["name", "id"],
    },
  ],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
