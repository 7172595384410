import * as gqlb from "gql-query-builder";
export const LIST_SHIPPING_SERVICES = gqlb.query({
  operation: "listShippingServices",
  fields: [
    {
      operation: "paginatorInfo",
      fields: [
        "count",
        "currentPage",
        "firstItem",
        "hasMorePages",
        "lastItem",
        "lastPage",
        "perPage",
        "total",
      ],
      variables: {},
    },
    {
      operation: "data",
      fields: [
        "id",
        "code",
        "name",
        "days",
        "active",
        {
          branch: ["id", "name"],
        },
        {
          commissionAccount: ["id", "name"],
        },
        {
          deliveryAccount: ["id", "name"],
        },
        {
          collectionAccount: ["id", "name"],
        },
        {
          shipmentTypes: ["code", "name"],
        },
      ],
      variables: {},
    },
  ],
  variables: {
    input: {
      type: "ListShippingServicesFilterInput",
    },
    page: {
      type: "Int",
    },
    first: {
      type: "Int",
    },
  },
});

export const SHIPPING_SERVICE_ID = gqlb.query({
  operation: "shippingService",
  fields: [
    "id",
    "code",
    "name",
    "days",
    "active",
    {
      branch: ["id", "name"],
    },
    {
      commissionAccount: ["id", "name"],
    },
    {
      deliveryAccount: ["id", "name"],
    },
    {
      collectionAccount: ["id", "name"],
    },
    {
      shipmentTypes: ["code", "name"],
    },
  ],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const SAVE_SHIPPING_SERVICE = gqlb.mutation({
  operation: "saveShippingService",
  fields: [
    "id",
    "code",
    "name",
    "days",
    "active",
    {
      branch: ["id", "name"],
    },
    {
      commissionAccount: ["id", "name"],
    },
    {
      deliveryAccount: ["id", "name"],
    },
    {
      collectionAccount: ["id", "name"],
    },
  ],
  variables: {
    input: {
      type: "ShippingServiceInput",
      required: true,
    },
  },
});
