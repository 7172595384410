import { Box } from "@mui/material";
import React from "react";
import { Marker, Popup } from "react-leaflet";
import L from "leaflet";
import SpanLink from "../../HOC/CustomComponents/SpanLink";
import marker from "../../../assets/Image/marker.png";
import trexMarker from "../../../assets/Image/marker-2.png";

export function MarkerPopup(props) {
  const { position } = props;
  const { transaction, id, latitude, longitude, createdAt, deliveryAgent } =
    position;
  const shipment = transaction?.shipment;
  const shipmentStatus = transaction?.shipmentStatus;
  const markerPosition = [latitude, longitude];
  const iconUrl = Boolean(shipmentStatus) ? trexMarker : marker;
  const icon = new L.icon({
    iconUrl: iconUrl,
    iconSize: [32, 32],
    iconAnchor: [16, 32],
    popupAnchor: [0, -32],
  });
  return (
    <Marker id={id} position={markerPosition} icon={icon}>
      <Popup minWidth={90}>
        <Box>
          <SpanLink pathname={`/admin/delivery-agents/${deliveryAgent?.id}`}>
            {deliveryAgent?.name}
          </SpanLink>
        </Box>
        <Box>
          <Box component="span">{createdAt}</Box>
        </Box>
        {shipment && (
          <Box>
            <SpanLink pathname={`/admin/shipments/${shipment?.id}`}>
              {shipment?.code}
            </SpanLink>
          </Box>
        )}
        {shipmentStatus && <Box>{shipmentStatus?.name}</Box>}
      </Popup>
    </Marker>
  );
}
