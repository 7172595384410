import React, { useEffect, useState } from "react";
import { FormControlLabel, Switch, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { useController } from "react-hook-form";

const MuiSwitch = (props) => {
  const { control, name, label, onChange, ...restProps } = props;

  const {
    field: { onChange: checkboxChange, value },
  } = useController({
    name,
    control,
    defaultValue: false,
  });
  const [checkState, setCheckState] = useState(value);
  useEffect(() => {
    setCheckState(value);
    return () => {};
  }, [value]);
  return (
    <FormControlLabel
      checked={checkState}
      control={<Switch color="primary" />}
      label={<Typography variant="body2">{label}</Typography>}
      labelPlacement="end"
      onChange={(event) => {
        setCheckState(event.target.checked);
        checkboxChange(event.target.checked);
        onChange && onChange(event);
      }}
      {...restProps}
    />
  );
};

MuiSwitch.propTypes = {
  control: PropTypes.any,
  name: PropTypes.string,
  label: PropTypes.string,
};

export default MuiSwitch;
