import * as gqlb from "gql-query-builder";

export const LIST_TICKETS = gqlb.query({
  operation: "listTickets",
  fields: [
    {
      operation: "paginatorInfo",
      fields: ["count", "currentPage", "lastPage", "perPage", "total"],
      variables: {},
    },
    {
      operation: "data",
      fields: [
        "id",
        "title",
        "code",
        "createdAt",
        {
          customer: ["id", "code", "name"]
        },
        {
          category: ["id", "code", "name"]
        },
        {
          status: ["id", "code", "name"]
        },
        {
          assignee: ["id", "username"]
        },
      ],
      variables: {},
    },
  ],
  variables: {
    input: {
      type: "ListTicketsFilterInput",
    },
    page: {
      type: "Int",
    },
    first: {
      type: "Int",
    },
  },
});

export const SAVE_TICKET = gqlb.mutation({
  operation: "saveTicket",
  fields: ["id"],
  variables: {
    input: {
      type: "TicketInput",
      required: true,
    },
  },
});

export const DELETE_TICKET = gqlb.mutation({
  operation: "deleteSupportCategory",
  fields: [],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const ASSIGN_MEMBER = gqlb.mutation({
  operation: "assignTicket",
  fields: ["id"],
  variables: {
    input: {
      type: "AssignTicketInput",
      required: true,
    },
  },
});

export const UPDATE_TICKET_STATUS = gqlb.mutation({
  operation: "updateTicketStatus",
  fields: ["id"],
  variables: {
    input: {
      type: "UpdateTicketStatusInput",
      required: true,
    },
  },
});

export const CREATE_TICKET_REPLY = gqlb.mutation({
  operation: "createTicketReply",
  fields: ["id"],
  variables: {
    input: {
      type: "TicketReplyInput",
      required: true,
    },
  },
});