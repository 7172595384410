import { Box, Collapse, Grid, TableCell, TableRow } from "@mui/material";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { dateFormat } from "../../../helpers/dateFunctions";
import ControlMUItextField from "../../HOC/MUI/ControlMUItextField";
import MUIDate from "../../HOC/MUI/MUIDate";
import { useManifestShipmentsContext } from "./ManifestShipmentsProvider";

const HTRTableRowForm = (props) => {
  const { shipment } = props;
  const [deliveryDate, setDeliveryDate] = useState(null);
  const {
    manifestShipmentsContext: { updateSelectedShipmentByForm },
  } = useManifestShipmentsContext();

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    setError,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      notes: "",
    },
  });
  const { t } = useTranslation(["translation", "validation"]);

  const shipmentDetails = shipment.shipment;

  const updateShipment = (name, value) => {
    /************* Validation Funcs *******************/

    let allField = watch();
    for (const key in allField) {
      if (key.startsWith(name)) {
        delete allField[key];
      }
    }

    /*************************************************/

    updateSelectedShipmentByForm({ id: shipmentDetails.id, name, value });
  };

  // useEffect(() => {
  //   return () => {
  //     // reset();
  //     // updateShipment("notes", "");
  //   };
  // }, []);

  const onChangeNotes = (e) => {
    const value = e.target.value;
    updateShipment("notes", value || "");
  };

  const onChangeDeliveryDate = (date) => {
    const value = dateFormat(date);
    updateShipment("deliveryDate", value || "");
  };

  const onSubmit = (data) => {
    // console.log(data);
  };

  return (
    <TableRow>
      <TableCell
        sx={{ paddingBottom: 0, paddingTop: 0, height: 0 }}
        colSpan={10}
      >
        <Collapse in={shipment.select} timeout="auto" unmountOnExit>
          <Box sx={{ margin: 1, padding: "16px 8px" }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid
                container
                item
                justifyContent="flex-start"
                alignContent="space-between"
                // className={classes.form}
                spacing={2}
              >
                <Grid container item sm={3} alignItems="flex-start">
                  <MUIDate
                    name="deliveryDate"
                    label={t("redeliveryDate")}
                    control={control}
                    value={deliveryDate}
                    onChange={(date) => {
                      setDeliveryDate(date);
                      onChangeDeliveryDate(date);
                    }}
                    readOnly={false}
                    disablePast
                    shouldDisableDate={(day) =>
                      day.toDateString() === new Date().toDateString()
                    }
                    onError={(resone, value) => {
                      setError("deliveryDate", { message: resone });
                    }}
                  />
                </Grid>

                <Grid container item sm={3} alignItems="flex-start">
                  <ControlMUItextField
                    control={control}
                    errors={errors}
                    name={"notes"}
                    label={t("notes")}
                    onChange={onChangeNotes}
                  />
                </Grid>
              </Grid>
            </form>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  );
};

export default HTRTableRowForm;
