import React, { Fragment, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Paper,
  Toolbar,
  IconButton,
  Typography,
  Box,
  Icon,
} from "@mui/material";

import {
  DeleteOutline,
  Edit,
  LockOpenOutlined,
  Print,
} from "@mui/icons-material";
import clsx from "clsx";
import { gql, useQuery } from "@apollo/client";
import emptyList from "../../assets/Image/received.png";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";

import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { useTranslation } from "react-i18next";
import ShipmentsTable from "../HOC/CustomComponents/ShipmentsTable";
import { Can, SecuredNavLink } from "../HOC/CustomComponents/Secured";
import { KeyValuePair } from "../HOC/CustomComponents/KeyValuePair";
import useCollectionsDetails from "./CollectionDetails";
import {
  DELETE_COLLECTIONS_MUTATION,
  COLLECTION_ID_AND_PRINT,
  DISAPPROVE_COLLECTION_MUTATION,
  COLLECTION_VIEW,
} from "./CollectionListQuary";
import NotFound from "../../Error/NotFound";
import { Globals } from "../HOC/Classes/Globals";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import MutationWithDialog from "../HOC/CustomComponents/MutationWithDialog";
import PrintIcon from "../Shipments/PrintIcon";
import ExportFinanceShipments from "../Payment/ExportFinanceShipments";
import SpanLink from "../HOC/CustomComponents/SpanLink";
import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";
import Grid from "@mui/material/Unstable_Grid2";
import { RootCollectionView, classesCollectionView } from "./CollectionStyle";
import { GetCollectionPermissionSlug } from "../../helpers/getManifestPermissionSlug";
import PrintComponent from "../Shipments/PrintComponent";

const EnhancedTableToolbar = (props) => {
  const { t } = useTranslation();

  const { selectedShipment, collectionId } = props;

  const shipmentId = selectedShipment.map((i) => i.shipment.id);
  return (
    <Toolbar className={clsx(classesCollectionView.root)}>
      <Typography
        className={classesCollectionView.title}
        color="inherit"
        variant="subtitle1"
        component="div"
      >
        {t("totalShipments") + " " + selectedShipment.length}
      </Typography>
      <Can showException permission="shipping.shipment.print">
        <PrintComponent shipmentsId={shipmentId} />
      </Can>
      <ExportFinanceShipments type="collection" id={collectionId} />
    </Toolbar>
  );
};

const CollectionView = (props) => {
  const { t } = useTranslation();

  const [collectionType, setdCollectionType] = useState(null);

  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [collectionsData, setCollectionsData] = useState();
  const [page, setPage] = useState(0);

  const { details: collectionDetails } = useCollectionsDetails({
    type: collectionType,
    TotalData: collectionsData
  });

  /******************************************* Start Pagination Functions ********************************************/

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  /******************************************* Start collection By Id ********************************************/
  const collectionId = props.match.params.id?.trim();
  const user = Globals.user;

  const journalEntryPermission = user.hasPermission(
    "accounting.journal_entry.list"
  );
  const collectionQueryBody = COLLECTION_VIEW(journalEntryPermission);

  const {
    data,
    loading: collectionByIdLoad,
    refetch,
  } = useQuery(
    gql`
      ${collectionQueryBody.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      skip: !collectionId,
      variables: {
        id: parseInt(collectionId),
      },
      onCompleted: (data) => {
        if (data?.collection === null) return;
        const collection = data?.collection;
        setCollectionsData(collection)
        setdCollectionType(collection?.type.code);
      },
    }
  );

  const collectionShipments = data?.collection?.entries;

  const branchId = data?.collection?.branch?.id;
  const canAccessBranch = branchId ? user.canAccessBranch(branchId) : true;
  const canDelete =
    !collectionByIdLoad &&
    user.hasPermission(GetCollectionPermissionSlug(collectionType?.toLowerCase(), 'delete')) &&
    canAccessBranch &&
    !data?.collection?.approved;

  const canEdit =
    !collectionByIdLoad &&
    user.hasPermission(GetCollectionPermissionSlug(collectionType?.toLowerCase(), 'update')) &&
    canAccessBranch &&
    !data?.collection?.glApproved;

  const canDisapprove =
    !collectionByIdLoad &&
    user.hasPermission(GetCollectionPermissionSlug(collectionType?.toLowerCase(), 'disapprove')) &&
    canAccessBranch &&
    data?.collection?.approved;

  /******************************************* End collection List ********************************************/

  let tableBody = null;
  let viewBody = null;
  tableBody = (
    <Grid container xs={12} justifyContent="center" sx={{ width: "100%" }}>
      {/* *******Table******* */}
      <Paper
        className={clsx(
          classesCollectionView.table,
          classesCollectionView.paper
        )}
      >
        <EnhancedTableToolbar
          {...props}
          selectedShipment={collectionShipments}
          collectionId={collectionId}
        />

        {collectionDetails && (
          <TableFixedHeaderWraper>
            <ShipmentsTable
              page={page}
              rowsPerPage={rowsPerPage}
              data={collectionShipments}
              headCells={collectionDetails.tableHead}
              parseBodyCell={collectionDetails.tableBody}
              total={collectionDetails.tableTotal}
              sumRow={collectionDetails?.sum}
              sumTableNumber={collectionDetails?.sumTableNumber}
              sum={true}
            />
          </TableFixedHeaderWraper>
        )}
        {collectionShipments?.length > 4 && (
          <MUITablePagination
            count={collectionShipments?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}

        <Grid container justifyContent="center" alignItems="center">
          {collectionShipments?.length === 0 && (
            <Box textAlign="center" marginTop="50px" marginBottom="50px">
              <img width="50%" src={emptyList} alt={"delivery box"} />
              <Box
                component={Typography}
                variant="h6"
                color="text.secondary"
                marginTop="0"
              >
                {t("noShipments")}
              </Box>
            </Box>
          )}
        </Grid>
      </Paper>
    </Grid>
  );

  viewBody = (
    <Grid
      container
      spacing={3}
      justifyContent="center"
      sx={{ margin: "0", width: "100%" }}
    >
      <Fragment>
        <Toolbar
          className={clsx(classesCollectionView.toolbar)}
          variant="regular"
        >
          <Grid
            container
            sx={{ flexWrap: "initial", width: "100%", flexGrow: 1 }}
            alignItems="center"
          >
            <Grid className={classesCollectionView.field}>
              <Typography variant="h6">{collectionDetails?.label}</Typography>
            </Grid>
            <Grid className={classesCollectionView.toolbarIcons}>
              <IconButton
                disabled={collectionByIdLoad}
                onClick={() =>
                  window.open(
                    `${window.location.origin}/report/print/collection/${collectionId}/${collectionType}`
                  )
                }
                color="default"
                size="large"
              >
                <Print fontSize="inherit" />
              </IconButton>

              <SecuredNavLink
                permission={canEdit}
                to={{
                  pathname: `/admin/collections/${collectionId}/edit`,
                }}
              >
                <IconButton color={"primary"} size="large">
                  <Edit fontSize="inherit" />
                </IconButton>
              </SecuredNavLink>

              <MutationWithDialog
                mutaion={DELETE_COLLECTIONS_MUTATION.query}
                icon={DeleteOutline}
                permission={Boolean(canDelete)}
                iconTooltip={t("deleteRecord")}
                dialogTitle={t("deleteRecord")}
                dialogContent={t("deleteRecordMessage")}
                mutaionProps={{
                  variables: { id: parseInt(collectionId) },
                }}
                onCompleted={() =>
                  pushUrl(props, `/admin/collections/${collectionType.toLowerCase()}`)
                }
                onCompleteMessage={t("successfullyDeletedRecord")}
              />

              <MutationWithDialog
                mutaion={DISAPPROVE_COLLECTION_MUTATION.query}
                icon={LockOpenOutlined}
                permission={Boolean(canDisapprove)}
                iconTooltip={t("disapprove")}
                dialogTitle={t("disapprove")}
                dialogContent={t("disapprovedMessage")}
                mutaionProps={{
                  variables: { id: parseInt(collectionId) },
                }}
                onCompleted={() => refetch()}
                onCompleteMessage={t("successfullyDisapproved")}
              />
            </Grid>
          </Grid>
        </Toolbar>
      </Fragment>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ margin: "0", width: "100%" }}
      >
        {collectionByIdLoad ? (
          <FullScreenLoading minHeight="25%" />
        ) : (
          <Paper
            container
            className={classesCollectionView.viewPaper}
            component={Grid}
            sx={{ width: "100%", mt: 1 }}
          >
            <KeyValuePair
              title={t("recordCode")}
              value={data?.collection?.code}
            />
            <KeyValuePair title={t("date")} value={data?.collection?.date} />
            <KeyValuePair
              title={t("branch")}
              value={
                <SpanLink
                  pathname={`/admin/branches/${data?.collection?.branch?.id}`}
                >
                  {data?.collection?.branch?.name}
                </SpanLink>
              }
            />
            <KeyValuePair
              title={t("safe")}
              value={
                data?.collection?.safe?.name && (
                  <SpanLink
                    pathname={`/admin/safes/${data?.collection?.safe?.id}`}
                  >
                    {data?.collection?.safe?.name}
                  </SpanLink>
                )
              }
            />
            {data?.collection?.deliveryAgent ? (
              <KeyValuePair
                title={t("shippingAgent")}
                value={
                  data?.collection?.deliveryAgent?.name && (
                    <SpanLink
                      pathname={`/admin/delivery-agents/${data?.collection?.deliveryAgent?.id}`}
                    >
                      {data?.collection?.deliveryAgent?.name}
                    </SpanLink>
                  )
                }
              />
            ) : null}
            <KeyValuePair
              title={t("transactionType")}
              value={
                <SpanLink
                  pathname={`/admin/shipment-transaction-types/${data?.collection?.transactionType?.id}`}
                >
                  {data?.collection?.transactionType?.name}
                </SpanLink>
              }
            />
            {data?.collection?.customer ? (
              <KeyValuePair
                title={t("customer")}
                value={
                  data?.collection?.customer?.name && (
                    <SpanLink
                      pathname={`/admin/customers/${data?.collection?.customer?.id}`}
                    >
                      {data?.collection?.customer?.name}
                    </SpanLink>
                  )
                }
              />
            ) : null}

            <KeyValuePair
              title={t("journalEntry")}
              value={
                data?.collection?.journalEntry?.code && (
                  <SpanLink
                    pathname={`/admin/finance/journal-entry/${data?.collection?.journalEntry?.id}`}
                  >
                    {data?.collection?.journalEntry?.code}
                  </SpanLink>
                )
              }
            />

            <KeyValuePair title={t("notes")} value={data?.collection?.notes} />
            <KeyValuePair
              title={t("createdBy")}
              value={
                data?.collection && (
                  <SpanLink
                    pathname={`/admin/users/${data?.collection?.createdBy?.id}`}
                  >
                    {data?.collection?.createdBy?.username}
                  </SpanLink>
                )
              }
            />
            <KeyValuePair
              title={t("updatedBy")}
              value={
                data?.collection && (
                  <SpanLink
                    pathname={`/admin/users/${data?.collection?.updatedBy?.id}`}
                  >
                    {data?.collection?.updatedBy?.username}
                  </SpanLink>
                )
              }
            />
            <KeyValuePair
              title={t("approved")}
              value={
                data?.collection?.approved ? (
                  <Icon className={classesCollectionView.iconColor}>
                    check_circle_outline
                  </Icon>
                ) : (
                  <Icon color="error">highlight_off</Icon>
                )
              }
            />
            <KeyValuePair
              title={t("glApproved")}
              value={
                data?.collection?.glApproved ? (
                  <Icon className={classesCollectionView.iconColor}>
                    check_circle_outline
                  </Icon>
                ) : (
                  <Icon color="error">highlight_off</Icon>
                )
              }
            />
          </Paper>
        )}
      </Grid>
    </Grid>
  );

  return data?.collection === null ? (
    <NotFound />
  ) : (
    <RootCollectionView>
      {props.children}

      {viewBody}

      <Grid
        container
        spacing={3}
        justifyContent="center"
        className={classesCollectionView.mainTracking}
      >
        {collectionDetails && tableBody}
      </Grid>
    </RootCollectionView>
  );
};

export default CollectionView;
