const PRINT_WIDTH_LANDSCAPE = 297;
const PRINT_WIDTH_PORTRAIT = 210;

export const mmToPx = (mm) => {
  let e = document.createElement("div");
  e.style.position = "absolute";
  e.style.width = "100mm";
  document.body.appendChild(e);
  let rect = e.getBoundingClientRect();
  document.body.removeChild(e);
  let pixels = rect.width / 100;
  return pixels * mm;
};

export const zoom = (width = PRINT_WIDTH_PORTRAIT) => {
  let table = document.getElementById("manifestPrintTable");
  let tableWidth = table.offsetWidth;
  let percent = mmToPx(width) / tableWidth;

  if (width === PRINT_WIDTH_PORTRAIT && percent < 0.75) {
    return zoom(PRINT_WIDTH_LANDSCAPE);
  }

  return {
    percent: percent,
    css:
      width === PRINT_WIDTH_PORTRAIT
        ? "print-portrait.css"
        : "print-landscape.css",
  };
};
