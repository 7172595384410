import { Dialog, FormControlLabel, Switch, Typography } from "@mui/material";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import useWidth, { isWidthDown } from "../../Hooks/useWidth";
import SaveShipment from "./SaveShipment";

const CreateShipmentInDialog = (props) => {
  const { open, onClose } = props;
  const [reset, setReset] = useState({
    key: Math.random().toString(),
    defaultCustomer: null,
  });
  const [anotherShipment, setAnotherShipment] = useState(false);

  const { key, defaultCustomer } = reset;
  const screenWidth = useWidth();
  const isScreenXSmall = isWidthDown("xs", screenWidth);
  const { t } = useTranslation();
  const closeDialog = () => {
    onClose();
    setReset({
      key: Math.random().toString(),
      defaultCustomer: null,
    });
    setAnotherShipment(false);
  };
  const anotherShipmentSwitch = (
    <FormControlLabel
      checked={anotherShipment}
      control={<Switch color="primary" />}
      label={
        <Typography variant="body2">{t("createAnotherShipment")}</Typography>
      }
      labelPlacement="end"
      onChange={(event) => {
        setAnotherShipment(event.target.checked);
      }}
    />
  );

  return (
    <Fragment>
      <Dialog
        fullScreen={isScreenXSmall}
        fullWidth
        maxWidth={"lg"}
        open={open}
        onClose={closeDialog}
      // TransitionComponent={Transition}
      >
        <SaveShipment
          defaultCustomer={defaultCustomer}
          closeDialog={closeDialog}
          anotherShipmentSwitch={anotherShipmentSwitch}
          anotherShipment={anotherShipment}
          key={key}
          reset={(customerId) =>
            setReset({
              key: Math.random().toString(),
              defaultCustomer: customerId,
            })
          }
        />
      </Dialog>
    </Fragment>
  );
};

export default CreateShipmentInDialog;
