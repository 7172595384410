import * as gqlb from "gql-query-builder";

export const LIST_INVOICES_QUERY = gqlb.query([
  {
    operation: "listInvoices",
    fields: [
      {
        operation: "paginatorInfo",
        fields: [
          "count",
          "currentPage",
          "total",
          "firstItem",
          "hasMorePages",
          "lastItem",
          "lastPage",
          "perPage",
        ],
        variables: {},
      },
      {
        operation: "data",
        fields: [
          "code",
          "id",
          "date",
          "approved",
          "glApproved",
          "weight",
          "pieces",
          "shipmentsCount",
          "deliveryFees",
          "postFees",
          "tax",
          "allFees",
          "notes",
          {
            operation: "transactionType",
            fields: ["id", "name"],
            variables: {},
          },

          {
            customer: ["id", "name", "code"],
          },
        ],
        variables: {},
      },
    ],
    variables: {
      input: {
        type: "ListInvoicesFilterInput",
        required: true,
      },
      first: {
        type: "Int",
        required: true,
        value: 15,
      },
      page: {
        type: "Int",
        value: 1,
      },
    },
  },
  {
    operation: "sumInvoices",
    fields: [
      "shipmentsCount",
      "piecesCount",
      "weight",
      "deliveryFees",
      "postFees",
      "tax",
      "allFees",
    ],
    variables: {
      input: {
        type: "ListInvoicesFilterInput",
        required: true,
      },
    },
  },
]);

export const invoiceFields = [
  "id",
  "date",
  "code",
  "approved",
  "glApproved",
  "notes",
  "tax",
  "postFees",
  "deliveryFees",
  "allFees",
  "weight",
  "pieces",
  {
    customer: [
      "id",
      "code",
      "name",
      "mobile",
      "phone",
      "address",
      { zone: ["name"] },
      { subzone: ["name"] },
    ],
  },
  {
    operation: "branch",
    fields: ["id", "name"],
    variables: {},
  },
  {
    transactionType: ["id", "code", "name", { type: ["name", "code"] }],
  },

  {
    operation: "invoiceShipments",
    fields: [
      "deliveryFees",
      "postFees",
      "tax",
      "allFees",
      {
        operation: "shipment",
        fields: [
          "id",
          "code",
          "recipientName",
          "description",
          "totalAmount",
          "deliveredAmount",
          "recipientAddress",
          "deliveredAmount",
          "collectedFees",
          "returningDueFees",
          "customerDue",
          "piecesCount",
          "weight",
          "date",
          "senderName",
          "senderMobile",
          "recipientMobile",
          {
            senderZone: ["id", "name"],
          },
          {
            senderSubzone: ["name"],
          },
          {
            recipientZone: ["id", "name"],
          },
          {
            recipientSubzone: ["name"],
          },
          {
            deliveryType: ["name"],
          },
          {
            recipientSubzone: ["name"],
          },
          {
            customer: ["name"],
          },
          {
            status: ["name"],
          },
        ],
        variables: {},
      },
    ],
    variables: {},
  },
];

export const INVOICES_BY_ID_QUERY = gqlb.query({
  operation: "invoice",
  fields: invoiceFields,
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const INVOICES_PRINT = gqlb.query([
  {
    operation: "invoice",
    fields: invoiceFields,
    variables: {
      id: {
        type: "Int",
        required: true,
      },
    },
  },
  {
    operation: "organization",
    fields: [
      "name",
      "phone",
      "email",
      "website",
      "reportHeader",
      "reportFooter",
      "registrationNumber",
      "taxNumber",
    ],
    variables: {},
  },
]);

export const INVOICES_VIEW = (permission) =>
  gqlb.query([
    {
      operation: "invoice",
      fields: [
        "id",
        "date",
        "code",
        "approved",
        "glApproved",
        "notes",
        "tax",
        "postFees",
        "deliveryFees",
        "allFees",
        "weight",
        "pieces",
        {
          customer: [
            "id",
            "code",
            "name",
            "mobile",
            "phone",
            "address",
            { zone: ["name"] },
            { subzone: ["name"] },
          ],
        },
        {
          operation: `journalEntry @include(if: ${permission})`,
          fields: ["code", "id"],
          variables: {},
        },
        {
          operation: "branch",
          fields: ["id", "name"],
          variables: {},
        },
        {
          transactionType: ["id", "code", "name", { type: ["name", "code"] }],
        },

        {
          operation: "invoiceShipments",
          fields: [
            "deliveryFees",
            "postFees",
            "tax",
            "allFees",
            {
              operation: "shipment",
              fields: [
                "id",
                "code",
                "recipientName",
                "description",
                "totalAmount",
                "deliveredAmount",
                "recipientAddress",
                "deliveredAmount",
                "collectedFees",
                "returningDueFees",
                "customerDue",
                "piecesCount",
                "weight",
                "date",
                "senderName",
                "senderMobile",
                "recipientMobile",
                {
                  senderZone: ["id", "name"],
                },
                {
                  senderSubzone: ["name"],
                },
                {
                  recipientZone: ["id", "name"],
                },
                {
                  recipientSubzone: ["name"],
                },
                {
                  deliveryType: ["name"],
                },
                {
                  recipientSubzone: ["name"],
                },
                {
                  customer: ["name"],
                },
                {
                  status: ["name"],
                },
              ],
              variables: {},
            },
          ],
          variables: {},
        },
      ],
      variables: {
        id: {
          type: "Int",
          required: true,
        },
      },
    },
  ]);

export const SAVE_INVOICES_MUTATION = gqlb.mutation({
  operation: "saveInvoice",
  fields: invoiceFields,
  variables: {
    input: {
      type: "InvoiceInput",
      required: true,
    },
  },
});

export const DELETE_INVOICES_MUTATION = gqlb.mutation({
  operation: "deleteInvoice",
  fields: [],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
export const APPROVE_INVOICES_MUTATION = gqlb.mutation({
  operation: "approveInvoice",
  fields: invoiceFields,
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
export const DISAPPROVE_INVOICES_MUTATION = gqlb.mutation({
  operation: "disapproveInvoice",
  fields: ["id"],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
export const GL_APPROVE_INVOICES_MUTATION = gqlb.mutation({
  operation: "glApproveInvoice",
  fields: invoiceFields,
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
