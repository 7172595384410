import React, { Fragment, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useQuery, gql } from "@apollo/client";
import {
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
  Toolbar,
  Table,
  TableBody,
  TableRow,
  TableHead,
  Icon,
  Button,
  useTheme,
  Drawer,
} from "@mui/material";
import { Add, Clear, FilterList, Search } from "@mui/icons-material";
import clsx from "clsx";
import { Can, SecuredNavLink } from "../HOC/CustomComponents/Secured";
// import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import { LIST_PRICE_LIST_PAGED } from "./Graphql";
import { useTranslation } from "react-i18next";
import CellLink from "../HOC/CustomComponents/CellLink";
import { useState } from "react";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import EmptyTableMessage from "../HOC/FunctionComponents/EmptyTableMessage";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import { useHistory } from "react-router";
import { windowUrl } from "../HOC/CustomFunctions/pushUrl";
import { urlParameters } from "../HOC/CustomFunctions/urlParameters";
import { useForm } from "react-hook-form";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";
import useWidth from "../../Hooks/useWidth";
import { GetCustomerPermissionSlug } from "../../helpers/getManifestPermissionSlug";

const PREFIX = "ListPriceList";

const classes = {
  appBar: `${PREFIX}-appBar`,
  flex: `${PREFIX}-flex`,
  button: `${PREFIX}-button`,
  mainSearch: `${PREFIX}-mainSearch`,
  paper: `${PREFIX}-paper`,
  main: `${PREFIX}-main`,
  mainContainer: `${PREFIX}-mainContainer`,
  iconColor: `${PREFIX}-iconColor`,
  toolbar: `${PREFIX}-toolbar`,
  searchForm: `${PREFIX}-searchForm`,
  searchField: `${PREFIX}-searchField`,
  searchPadding: `${PREFIX}-searchPadding`,
  drawer: `${PREFIX}-drawer`,
  drawerPaper: `${PREFIX}-drawerPaper`,
  drawerHeader: `${PREFIX}-drawerHeader`,
  content: `${PREFIX}-content`,
  contentShift: `${PREFIX}-contentShift`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.appBar}`]: {
    position: "relative",
  },

  [`& .${classes.flex}`]: {
    flex: "1 1 100%",
  },

  [`& .${classes.button}`]: {
    position: "sticky",
    bottom: 0,
    zIndex: 3,
    backgroundColor: "#fff",
    marginTop: 10,
    padding: 7,
  },

  [`& .${classes.mainSearch}`]: {
    margin: theme.spacing(0),
    width: "100%",
  },

  [`& .${classes.paper}`]: {
    display: "grid",
    width: "100%",
    borderRadius: 0,
  },

  [`& .${classes.main}`]: {
    marginTop: theme.spacing(2),
  },

  [`& .${classes.mainContainer}`]: {
    width: "100%",
  },

  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },

  [`& .${classes.toolbar}`]: {
    borderBottom: "1px #ccd1d6 solid",
    backgroundColor: "#f5f7f9",
    width: "100%",
    // justifyContent: "space-between",
  },

  [`& .${classes.searchForm}`]: {
    overflowY: "auto",
    width: "100%",
    height: "100%",
    margin: 0,
  },

  [`& .${classes.searchField}`]: {
    padding: theme.spacing(1, 1, 0, 1),
    position: "relative",
    overflow: "hidden",
  },

  [`& .${classes.searchPadding}`]: {
    padding: theme.spacing(1),
  },

  [`& .${classes.drawer}`]: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },

  [`& .${classes.drawerPaper}`]: {
    width: DRAWER_WIDTH,
    zIndex: "250",
    height: "100%",
    left: "initial",
    right: "initial",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },

  [`& .${classes.drawerHeader}`]: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },

  [`& .${classes.content}`]: {
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
  },

  [`& .${classes.contentShift}`]: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: "auto",
    width: "calc(100% - 240px)",
    [theme.breakpoints.down("sm")]: {
      margin: "auto",
      width: "auto",
    },
  },
}));

const DRAWER_WIDTH = 240;

const ListPriceList = (props) => {
  const { t } = useTranslation();

  const listName = {
    B2C: t("listPriceListsCustomers"),
    C2C: t("listPriceListsIndividuals"),
  };

  const theme = useTheme();
  const urlQuery = urlParameters(window.location.search);
  const listType = props.match?.params?.type.toUpperCase();

  const validUrlParameters = Object.keys(urlQuery).length !== 0;

  const screenWidth = useWidth();

  const [drawerState, setDrawerState] = React.useState({
    top: true,
    left: screenWidth === "xs" ? false : true,
    bottom: screenWidth === "xs" ? false : true,
    right: screenWidth === "xs" ? false : true,
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm();
  const [rowsPerPage, setRowsPerPage] = useState(urlQuery["rowsPerPage"] ?? 20);
  const initSearch = () => {
    let searchParameters = {
      page: 0,
      refetch: true,
    };
    delete urlQuery["rowsPerPage"];
    if (validUrlParameters) {
      if (urlQuery["name"]) urlQuery["name"] = urlQuery["name"].toString();
      if (urlQuery["code"]) urlQuery["code"] = urlQuery["code"].toString();
      searchParameters = urlQuery;
    }
    return {
      ...searchParameters,
    };
  };
  const [search, setSearch] = useState(initSearch());
  useEffect(() => {
    urlQuery["name"] && setValue("name", urlQuery["name"]);
    urlQuery["code"] && setValue("code", urlQuery["code"]);

    return () => { };
  }, []);

  const history = useHistory();

  const pushUrlSearch = (param) => {
    const queryParams = [];
    for (const i in param) {
      encodeURIComponent(param[i]) &&
        queryParams.push(
          encodeURIComponent(i) + "=" + encodeURIComponent(param[i])
        );
    }
    const queryString = queryParams.join("&");

    const url = history.createHref({
      pathname: `/admin/price-list/${listType.toLowerCase()}`,
      search: "?" + queryString,
    });
    windowUrl(url);
    //this will not effect on history.location.search
  };
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      (event.type === "keydown" || event.type === "submit") &&
      (event.type === "submit" || event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerState({ ...drawerState, [anchor]: open });
  };
  const filterAnchor = screenWidth === "xs" ? "bottom" : "left";

  const onSubmit = (data) => {
    filterAnchor === "bottom" &&
      setDrawerState({ ...drawerState, [filterAnchor]: false });
    let handledData = {
      name: data.name,
      code: data.code,
      page: 0,
    };

    for (const key in handledData) {
      if (
        handledData[key] === undefined ||
        handledData[key] === "" ||
        handledData[key] === null
      ) {
        delete handledData[key];
      }
    }
    setSearch((prev) => ({
      ...handledData,
      refetch: !prev.refetch,
    }));
    for (const key in handledData) {
      if (handledData[key] === null) {
        delete handledData[key];
      }
    }
    pushUrlSearch({
      ...handledData,
      rowsPerPage: rowsPerPage,
    });
  };

  const { data, loading } = useQuery(
    gql`
      ${LIST_PRICE_LIST_PAGED.query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: {
        first: rowsPerPage,
        page: search?.page + 1,
        input: {
          ...(search.name && { name: search.name }),
          ...(search.code && { code: search.code }),
          ...(listType && { businessTypeCode: listType }),
        },
      },
    }
  );
  const listPriceLists = data?.listPriceLists?.data;

  const handleChangePage = (event, newPage) => {
    pushUrlSearch({
      ...urlQuery,
      page: newPage,
      rowsPerPage: rowsPerPage,
    });

    setSearch((prev) => ({ ...prev, page: newPage }));
  };

  const handleChangeRowsPerPage = (event) => {
    pushUrlSearch({
      ...urlQuery,
      page: 0,
      rowsPerPage: +event.target.value,
    });

    setRowsPerPage(+event.target.value);
    setSearch((prev) => ({ ...prev, page: 0 }));
  };

  let tableBody;
  tableBody = (
    <Paper className={clsx(classes.paper)}>
      {loading || listPriceLists?.length === 0 ? (
        <EmptyTableMessage loading={loading} message={t("searchNoResult")} />
      ) : (
        <TableFixedHeaderWraper>
          <Table aria-labelledby="tableTitle" aria-label="enhanced table">
            <TableHead>
              <TableRow>
                <FixedTableCell>{t("name")}</FixedTableCell>
                <FixedTableCell>{t("code")}</FixedTableCell>
                <FixedTableCell>{t("description")}</FixedTableCell>
                <FixedTableCell>{t("active")}</FixedTableCell>
                <FixedTableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {listPriceLists &&
                listPriceLists?.map((row, index) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                      <CellLink pathname={`${row.id}`}>
                        {row?.name}
                      </CellLink>
                      <FixedTableCell>{row.code}</FixedTableCell>
                      <FixedTableCell>{row?.description}</FixedTableCell>
                      <FixedTableCell>
                        {row?.active ? (
                          <Icon className={classes.iconColor}>
                            check_circle_outline
                          </Icon>
                        ) : (
                          <Icon color="error">highlight_off</Icon>
                        )}
                      </FixedTableCell>
                      <FixedTableCell>
                        <SecuredNavLink
                          permission={GetCustomerPermissionSlug("shipping", "price_list", listType, "create")}
                          to={{
                            pathname: `/admin/price-list/${row.id}/copy`,
                          }}
                        >
                          <Tooltip title="import Pricelist">
                            <IconButton
                              aria-label="import Pricelist"
                              size="large"
                            >
                              <Icon>content_copy</Icon>
                            </IconButton>
                          </Tooltip>
                        </SecuredNavLink>
                      </FixedTableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableFixedHeaderWraper>
      )}
      <MUITablePagination
        count={data?.listPriceLists?.paginatorInfo?.total}
        rowsPerPage={rowsPerPage}
        page={!listPriceLists ? 0 : search.page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );

  const pagePermission = GetCustomerPermissionSlug("shipping", "price_list", listType, "list")

  return (
    <Can permission={pagePermission}>
      <Root>
        <Drawer
          className={clsx(classes.drawer)}
          variant="persistent"
          classes={{
            paper: classes.drawerPaper,
          }}
          anchor={filterAnchor}
          open={drawerState[filterAnchor]}
          onClose={() => false}
          dir={theme.direction}
        >
          <div className={classes.drawerHeader} />
          <Toolbar
            variant="dense"
            className={classes.toolbar}
            sx={{ marginBottom: 1 }}
          >
            <Typography
              className={classes.flex}
              color="inherit"
              variant="h6"
              component="div"
            >
              {t("search")}
            </Typography>
            <IconButton
              aria-label="done"
              size="small"
              onClick={toggleDrawer(filterAnchor, false)}
              color={"primary"}
            >
              <Clear />
            </IconButton>
          </Toolbar>

          <Grid
            container
            item
            justifyContent="center"
            alignContent="space-between"
            component={"form"}
            onSubmit={handleSubmit(onSubmit)}
            className={classes.searchForm}
          >
            <Grid
              container
              item
              sm={12}
              justifyContent="flex-start"
              spacing={1}
              className={classes.searchField}
            >
              <Grid container item sm={12} alignItems="flex-start">
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  name={"name"}
                  label={t("name")}
                />
              </Grid>
              <Grid container item sm={12} alignItems="flex-start">
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  name={"code"}
                  label={t("code")}
                />
              </Grid>
            </Grid>
            <Grid
              container
              item
              sm={12}
              className={clsx(classes.searchPadding, classes.button)}
              alignItems="center"
              alignContent="flex-end"
              justifyContent="flex-end"
            >
              <Button
                sx={{ width: "100%" }}
                variant="contained"
                color="primary"
                type="submit"
                size="large"
                startIcon={<Search />}
                disabled={loading}
              >
                {t("search")}
              </Button>
            </Grid>
          </Grid>
        </Drawer>

        <Grid
          container
          justifyContent="center"
          spacing={3}
          className={clsx(classes.content, classes.mainSearch, classes.root, {
            [classes.contentShift]: drawerState[filterAnchor],
          })}
        >
          {/* *******Table******* */}

          <Toolbar className={clsx(classes.toolbar)} variant="dense">
            <Typography className={classes.flex} color="inherit" variant="h6">
              {listName[listType]}
            </Typography>

            <IconButton onClick={toggleDrawer(filterAnchor, true)} size="large">
              <FilterList fontSize="inherit" />
            </IconButton>
            <SecuredNavLink
              permission={GetCustomerPermissionSlug("shipping", "price_list", listType, "create")}
              to={{ pathname: `/admin/price-list/create/${listType.toLowerCase()}` }}
            >
              <Tooltip title="Add Pricelist">
                <IconButton aria-label="Add Pricelist" size="large">
                  <Add color="primary" />
                </IconButton>
              </Tooltip>
            </SecuredNavLink>
          </Toolbar>
          {tableBody}
        </Grid>
      </Root>
    </Can>
  );
};

export default ListPriceList;
