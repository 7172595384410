import { Checkbox, TableRow } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import CellLink from "../../HOC/CustomComponents/CellLink";
import { FixedTableCell } from "../../HOC/CustomComponents/FixedTableCell";
import { QuantityTableCell } from "./QuantityTableCell";
import { invoiceShipmentsCells } from "./ShipmentListDetails";
const PREFIX = "CustomTableRow";

const classes = {
  shipmentTable_checkbox: `${PREFIX}-shipmentTable_checkbox`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled(TableRow)(({ theme }) => ({
  [`& .${classes.shipmentTable_checkbox}`]: {
    zIndex: "100",
    backgroundColor: "#fff",
    position: "sticky",
    top: 0,
    left: 0,
  },
}));

const CustomTableRow = (props) => {
  const { product, onChangeCheck, labelId } = props;

  const productDetails = product?.product;

  return (
    <Root
      hover
      role="checkbox"
      aria-checked={product.select}
      tabIndex={-1}
      selected={product.select}
    >
      <FixedTableCell
        className={classes.shipmentTable_checkbox}
        padding="checkbox"
      >
        <Checkbox
          onChange={(event) => onChangeCheck(event)}
          checked={product.select}
          inputProps={{ "aria-labelledby": labelId }}
        />
      </FixedTableCell>

      <CellLink
        component="th"
        scope="shipment"
        pathname={`/admin/shipments/${productDetails.id}`}
      >
        {productDetails?.code}
      </CellLink>
      {invoiceShipmentsCells(productDetails)}
      <QuantityTableCell productDetails={productDetails} product={product} checked={product.select && true}/>
    </Root>
  );
};

export default CustomTableRow;
