import React, { useEffect } from "react";
import { styled } from '@mui/material/styles';
import clsx from "clsx";
import {
  Paper,
  Typography,
  Switch,
  FormControlLabel,
  Collapse,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Dialog,
  FormHelperText,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Toolbar,
} from "@mui/material";
import { SAFE_ID, SAVE_SAFE } from "./Graphql";

import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { useMutation, gql, useQuery } from "@apollo/client";
import { useState } from "react";
import FormButton from "../CustomComponents/Buttons/FormButton";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import { useSnackbar } from "notistack";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import TreasurersTable from "./TreasurersTable";
import ListBranches from "../HOC/ComponentWithSpecificQuery/ListBranches";
import {
  LIST_GL_ACCOUNTS_DROPDOWN,
  LIST_PAYMENT_TYPES_DROPDOWN,
  LIST_USERS_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import Grid from "@mui/material/Unstable_Grid2";
import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import DragHandleIcon from '@mui/icons-material/DragHandle';
import MuiSwitch from "../HOC/MUI/MUIswitch";


const PREFIX = 'SafeForm';

const classes = {
  spacing: `${PREFIX}-spacing`,
  mainGrid: `${PREFIX}-mainGrid`
};

const Root = styled('form')((
  {
    theme
  }
) => ({
  [`& .${classes.spacing}`]: {
    width: "100%",
    margin: theme.spacing(2, 0, 2, 0),
  },

  [`& .${classes.mainGrid}`]: {
    width: "100%",
    margin: theme.spacing(0),
    padding: theme.spacing(2),
  }
}));

const SafeForm = (props) => {
  const [autocompleteValues, setAutocompleteValues] = useState({
    branch: null,
    glAccount: null,
  });
  const [treasurersList, setTreasurersList] = useState([]);

  const [treasurersIndex, setTreasurersIndex] = useState({
    index: 0,
    update: false,
  });
  const [dialog, setDialog] = useState(false);
  const [treasurersErrorMessage, setTreasurersErrorMessage] = useState(false);
  const [selectedNames, setSelectedNames] = useState({
    user: "",
  });

  const handelCloseDialog = () => {
    setDialog(false);
    setTreasurersErrorMessage(false);
    setTreasurersIndex((prev) => ({ ...prev, update: false }));
  };

  const addTreasurersDialog = (index) => {
    treasurersReset();
    if (index || index === 0) {
      setTreasurersIndex({
        index,
        update: true,
      });
    } else {
      setTreasurersIndex({
        index,
        update: false,
      });
    }
    setDialog(true);
  };

  const onChangeNames = (e, parameter, remove) => {
    const name = e.name;
    setSelectedNames((prev) => ({
      ...prev,
      [parameter]: name,
      ...(remove && { [remove]: "" }),
    }));
    setTreasurersErrorMessage(false);
  };


  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { handleSubmit, control, formState, watch, setValue, setError } =
    useForm();

  const {
    control: treasurersControl,
    formState: { errors: treasurersErrors },
    handleSubmit: treasurersHandleSubmit,
    setValue: treasurersSetValue,
    watch: treasurersWatch,
    reset: treasurersReset,
  } = useForm({
    defaultValues: {
      userId: "",
      active: true,
    },
  });

  const { errors } = formState;

  const parseData = (data) => {
    return data;
  };

  const onSubmitTreasurers = (data) => {
    const newTreasurers = {
      user: {
        id: data.userId,
        username: selectedNames.user,
      },
      active: data.active,
    };
    const updateTreasurers = [...treasurersList];
    const invalidTreasurers = updateTreasurers.some((i, index) =>
      treasurersIndex.update && index === treasurersIndex.index
        ? false
        : i.user.id === newTreasurers.user.id
    );

    if (invalidTreasurers) {
      setTreasurersErrorMessage(true);
    } else {
      if (treasurersIndex.update) {
        updateTreasurers[treasurersIndex.index] = {
          ...updateTreasurers[treasurersIndex.index],
          ...newTreasurers,
        };
        setTreasurersIndex({ index: treasurersIndex.index, update: false });
      } else {
        updateTreasurers.push(newTreasurers);
        setTreasurersIndex({
          index: 0,
          update: false,
        });
      }
      setTreasurersList(updateTreasurers);
      handelCloseDialog();
    }
  };

  const [saveSafe, { loading: saveSafeLoading }] = useMutation(
    gql`
      ${SAVE_SAFE.query}
    `
  );
  const [activeUser, setActiveUser] = useState(true);
  const [depositeUser, setDepositeUser] = useState(true);
  const [withdrawUser, setWithdrawUser] = useState(true);
  const [transferInUser, setTransferInUser] = useState(true);
  const [transferOutUser, setTransferOutUser] = useState(true);

  const handelActivate = (e) => {
    const active = e.target.checked;
    setActiveUser(active);
  };
  const handelDeposite = (e) => {
    const deposite = e.target.checked;
    setDepositeUser(deposite);
  };
  const handelWithdraw = (e) => {
    const withdraw = e.target.checked;
    setWithdrawUser(withdraw);
  };
  const handelTransferIn = (e) => {
    const transferIn = e.target.checked;
    setTransferInUser(transferIn);
  };
  const handelTransferOut = (e) => {
    const transferOut = e.target.checked;
    setTransferOutUser(transferOut);
  };
  // update
  const safeId = parseInt(props.match.params.id);

  const { data: allPaymentTypes } = useQuery(
    gql`
      ${LIST_PAYMENT_TYPES_DROPDOWN.query}
    `,
    {
      nextFetchPolicy: "no-cache",
      fetchPolicy: "no-cache",
      skip: safeId,
      variables: {},
      onCompleted: (data) => {
        const paymentTypes = data?.listPaymentTypesDropdown
        setPaymentTypes(paymentTypes);
        paymentTypes.forEach((row) => {
          !safeId && setValue(`${row.code}`, true);
        })
      },
    }
  );
  const { data, loading } = useQuery(
    gql`
      ${SAFE_ID.query}
    `,

    {
      skip: !safeId,
      variables: { id: safeId },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        const safesData = data.safe;
        // payments
        const allPaymentTypes = data.listPaymentTypesDropdown;
        setPaymentTypes(allPaymentTypes);
        setActiveUser(safesData.active);
        setDepositeUser(safesData.deposite);
        setWithdrawUser(safesData.withdraw);
        setTransferInUser(safesData.transferIn);
        setTransferOutUser(safesData.transferOut);
        setTreasurersList(safesData.treasurers);
        const saveSafesParams = [
          "id",
          "code",
          "name",
          "deposite",
          "withdraw",
          "transferIn",
          "transferOut",
          "treasurers",
        ];
        saveSafesParams.forEach((i) => {
          safesData[i] && setValue(i, safesData[i]);
        });
        setValue("active", safesData["active"]);

        setAutocompleteValues({
          branch: safesData?.branch,
          glAccount: safesData?.glAccount,
        });
      },
    }
  );

  const safePaymentTypes = data?.safe.paymentTypes;

  useEffect(() => {
    if (safeId && !loading) {
      const paymentCode = [];
      safePaymentTypes.map(ele => paymentCode.push(ele.code))
      const notSelect = paymentTypes.filter(ele => !paymentCode.includes(ele.code))
      setPaymentTypes([...safePaymentTypes, ...notSelect]);
      safePaymentTypes.forEach((row) => {
        setValue(`${row.code}`, true);
      })
    }

    return () => { }
  }, [loading])


  const onSubmit = (data) => {
    let codes = [];
    let paymentTypesSelect = [];
    for (var i = 0; i < paymentTypes.length; ++i) {
      codes.push(paymentTypes[i].code);
      data[paymentTypes[i].code] === true && paymentTypesSelect.push(paymentTypes[i].code);
    }
    for (const key in data) {
      codes.includes(key) && delete data[key]
      if (data[key] === "") {
        data[key] = null;
      }
    }
    const treasurers = treasurersList
      ? treasurersList.map((i) => {
        const treasurers = {
          userId: i.user.id,
          active: i.active,
        };
        return treasurers;
      })
      : [];
    saveSafe({
      variables: {
        input: {
          ...data,
          paymentTypes: paymentTypesSelect,
          active: activeUser,
          deposite: depositeUser,
          withdraw: withdrawUser,
          transferIn: transferInUser,
          transferOut: transferOutUser,
          treasurers: treasurers,
        },
      },
    })
      .then((data) => {
        pushUrl(props, `/admin/safes/${data?.data?.saveSafe?.id}`);
        enqueueSnackbar(t("saveSuccessful"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      })
      .catch(({ graphQLErrors }) => {
        setValidationError(graphQLErrors, setError);
      });
  };

  // payments
  const [paymentTypes, setPaymentTypes] = useState();
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const reorderedItems = reorder(
      paymentTypes,
      result.source.index,
      result.destination.index
    );
    setPaymentTypes(reorderedItems);
  };

  const treasurersForm = (
    <TreasurersForm
      handleSubmit={treasurersHandleSubmit}
      onSubmitTreasurers={onSubmitTreasurers}
      control={treasurersControl}
      errors={treasurersErrors}
      setValue={treasurersSetValue}
      watch={treasurersWatch}
      parseData={parseData}
      onChangeNames={onChangeNames}
      setSelectedNames={setSelectedNames}
      treasurersIndex={treasurersIndex}
      treasurersList={treasurersList}
      treasurersErrorMessage={treasurersErrorMessage}
      handelCloseDialog={handelCloseDialog}
    />
  );

  const body = (
    <Root onSubmit={handleSubmit(onSubmit)}>
      <Grid
        container
        justifyContent="flex-start"
        alignItems="center"
        className={clsx(classes.mainGrid)}
        spacing={2}
      >
        <Paper container component={Grid} className={clsx(classes.spacing)}>
          <Grid container justifyContent="space-between" xs={12}>
            <Typography variant="h6">{t("safe")}</Typography>
            <FormControlLabel
              checked={activeUser}
              control={<Switch color="primary" />}
              label={t("active")}
              labelPlacement="start"
              onChange={handelActivate}
            />
          </Grid>
          <Grid xs={12} sm={6} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"code"}
              label={t("code")}
            />
          </Grid>
          <Grid xs={12} sm={6} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"name"}
              label={t("name")}
              rules={{ required: t("fieldIsRequired") }}
            />
          </Grid>
          <Grid xs={12} sm={6} alignItems="flex-start">
            <ListBranches
              control={control}
              errors={errors}
              name={"branchId"}
              rules={{ required: t("fieldIsRequired") }}
              onChangeValue={() => {
                setValue("glAccountId", "");
              }}
              defaultValue={autocompleteValues.branch}
              skipDefaultBranch={Boolean(safeId)}
            />
          </Grid>
          <Grid xs={12} sm={6} alignItems="flex-start">
            <CustomAutocomplete
              control={control}
              errors={errors}
              name={"glAccountId"}
              label={t("glAccount")}
              parseData={(data) => parseData(data)}
              query={LIST_GL_ACCOUNTS_DROPDOWN.query}
              variables={{
                input: {
                  typeCode: "SUB",
                  ...(watch("branchId") && {
                    branchId: { value: watch("branchId") },
                  }),
                },
              }}
              defaultValue={autocompleteValues.glAccount}
            />
          </Grid>
          {/* <Grid   sm={12} alignItems="flex-start">
            <CustomAutocomplete
              name={"treasurers"}
              label={t("treasurers")}
              control={control}
              errors={errors}
              parseData={(data) => selectDefaultTreasurers(data)}
              query={LIST_USERS_DROPDOWN.query}
              
              defaultValue={autocompleteValues.treasurers}
            />
          </Grid> */}
          <Grid justifyContent="space-between" xs={12} sm={6}>
            <FormControlLabel
              checked={depositeUser}
              control={<Switch color="primary" />}
              label={t("deposite")}
              labelPlacement="start"
              onChange={handelDeposite}
            />
          </Grid>
          <Grid justifyContent="space-between" xs={12} sm={6}>
            <FormControlLabel
              checked={transferInUser}
              control={<Switch color="primary" />}
              label={t("transferIn")}
              labelPlacement="start"
              onChange={handelTransferIn}
            />
          </Grid>
          <Grid justifyContent="space-between" xs={12} sm={6}>
            <FormControlLabel
              checked={withdrawUser}
              control={<Switch color="primary" />}
              label={t("withdraw")}
              labelPlacement="start"
              onChange={handelWithdraw}
            />
          </Grid>
          <Grid justifyContent="space-between" xs={12} sm={6}>
            <FormControlLabel
              checked={transferOutUser}
              control={<Switch color="primary" />}
              label={t("transferOut")}
              labelPlacement="start"
              onChange={handelTransferOut}
            />
          </Grid>
        </Paper>
        {!loading && (
          <TreasurersTable
            treasurers={treasurersList}
            addTreasurers={addTreasurersDialog}
            setTreasurersList={setTreasurersList}
          />
        )}
        {!loading && (
          <Paper container component={Grid} className={clsx(classes.spacing)}>
            <Toolbar className={classes.toolbarTable} variant="dense">
              <Typography
                className={classes.title}
                color="inherit"
                variant="h6"
              >
                {t("paymentTypes")}
              </Typography>
            </Toolbar>
            <TableFixedHeaderWraper>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <FixedTableCell> </FixedTableCell>
                    <FixedTableCell>{t("code")}</FixedTableCell>
                    <FixedTableCell>{t("name")}</FixedTableCell>
                    <FixedTableCell> </FixedTableCell>
                  </TableRow>
                </TableHead>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable" direction="vertical">
                    {(provided, snapshot) => (
                      <TableBody {...provided.droppableProps}
                        ref={provided.innerRef}>
                        {paymentTypes &&
                          paymentTypes?.map((row, index) => (
                            <Draggable key={row.id} draggableId={row?.code} index={index}>
                              {(provided, snapshot) => (
                                <TableRow
                                  hover
                                  role="checkbox"
                                  tabIndex={-1}
                                  key={index}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <FixedTableCell>
                                    <DragHandleIcon />
                                  </FixedTableCell>
                                  <FixedTableCell>
                                    {row?.code}
                                  </FixedTableCell>
                                  <FixedTableCell>
                                    {row?.name}
                                  </FixedTableCell>
                                  <FixedTableCell>
                                    <MuiSwitch
                                      edge="end"
                                      name={`${row.code}`}
                                      control={control}
                                    />
                                  </FixedTableCell>
                                </TableRow>
                              )}
                            </Draggable>
                          ))}
                        {provided.placeholder}
                      </TableBody>
                    )}
                  </Droppable>
                </DragDropContext>
              </Table>
            </TableFixedHeaderWraper>
          </Paper>
        )}
        <Grid container justifyContent="flex-end" className={classes.spacing}>
          <FormButton disabled={saveSafeLoading}>
            {saveSafeLoading ? <ButtonLoading /> : t("save")}
          </FormButton>
        </Grid>
      </Grid>
    </Root>
  );

  // let DOM;

  // if (safeId) {
  //   DOM = updateSafe ? body : <FullScreenLoading minHeight="10%" />;
  // } else {
  //   DOM = body;
  // }

  return (
    <>
      {loading ? (
        <FullScreenLoading minHeight="25%" />
      ) : (
        <>
          <Dialog
            fullWidth
            maxWidth="xs"
            open={dialog}
            onClose={handelCloseDialog}
          >
            {treasurersForm}
          </Dialog>
          {body}

        </>
      )}
    </>
  );
};

export default SafeForm;

function TreasurersForm({
  handleSubmit,
  onSubmitTreasurers,
  control,
  errors,
  parseData,
  onChangeNames,
  treasurersIndex,
  treasurersList,
  setValue,
  setSelectedNames,
  watch,
  treasurersErrorMessage,
  handelCloseDialog,
}) {
  const { t } = useTranslation();
  const [autocompleteValues, setAutocompleteValues] = useState({
    user: null,
  });
  const [activeTreasurers, setActiveTreasurers] = useState(true);

  const handelActivate = (e) => {
    const active = e.target.checked;
    setActiveTreasurers(active);
    setValue("active", active);
  };
  useEffect(() => {
    if (treasurersIndex.update) {
      const update = treasurersList[treasurersIndex.index];
      setSelectedNames({ user: update?.user?.username });
      setValue("active", update?.active);
      setActiveTreasurers(update?.active);
      setAutocompleteValues({
        user: { id: update?.user?.id, name: update?.user?.username },
      });
    } else {
      setValue("active", true);
    }
    return () => { };
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmitTreasurers)}>
      <DialogTitle>
        <Grid container justifyContent="space-between" sm={12}>
          <Typography variant="h6">{t("addTreasurers")}</Typography>
          <FormControlLabel
            checked={activeTreasurers}
            control={<Switch color="primary" />}
            label={t("active")}
            labelPlacement="start"
            onChange={handelActivate}
          />
        </Grid>
      </DialogTitle>
      <DialogContent>
        <CustomAutocomplete
          control={control}
          errors={errors}
          rules={{ required: t("fieldIsRequired") }}
          name={"userId"}
          label={t("treasurers")}
          parseData={(data) => parseData(data)}
          query={LIST_USERS_DROPDOWN.query}
          onChangeValue={(e) => {
            onChangeNames(e, "user");
          }}
          hideCode={true}
          variables={{
            input: {
              accountId: null
            }
          }}
          defaultValue={autocompleteValues.user}
        />
        {treasurersErrorMessage && (
          <FormHelperText error>
            {t("thisSecretaryAlreadyExists")}
          </FormHelperText>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handelCloseDialog}>{t("cancel")}</Button>
        <Button type="submit">{t("confirm")}</Button>
      </DialogActions>
    </form>
  );
}
