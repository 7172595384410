import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { gql, useQuery } from "@apollo/client";
import {
    Grid,
    Paper,
    Table,
    TableBody,
    TableHead,
    TableRow,
    Toolbar,
    Typography,
} from "@mui/material";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import CellLink from "../HOC/CustomComponents/CellLink";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";
import { windowUrl } from "../HOC/CustomFunctions/pushUrl";
import { urlParameters } from "../HOC/CustomFunctions/urlParameters";
import EmptyTableMessage from "../HOC/FunctionComponents/EmptyTableMessage";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
// import { LIST_CUSTOMER_WITH_NEW_SHIPMENT } from "./Graphql";
import * as gqlb from "gql-query-builder";

const PREFIX = "CustomerList";

const classes = {
    button: `${PREFIX}-button`,
    iconColor: `${PREFIX}-iconColor`,
    btnMargin: `${PREFIX}-btnMargin`,
    mainSearch: `${PREFIX}-mainSearch`,
    titles: `${PREFIX}-titles`,
    toolbar: `${PREFIX}-toolbar`,
    searchForm: `${PREFIX}-searchForm`,
    searchField: `${PREFIX}-searchField`,
    overlay: `${PREFIX}-overlay`,
    searchPadding: `${PREFIX}-searchPadding`,
    drawer: `${PREFIX}-drawer`,
    drawerPaper: `${PREFIX}-drawerPaper`,
    drawerHeader: `${PREFIX}-drawerHeader`,
    content: `${PREFIX}-content`,
    contentShift: `${PREFIX}-contentShift`,
    tableRow: `${PREFIX}-tableRow`,
    shipmentTable_shipmentStatus: `${PREFIX}-shipmentTable_shipmentStatus`,
    shipmentTable_shipmentCode: `shipmentTable_shipmentCode`,
    tablePaper: `${PREFIX}-tablePaper`,
    table_status: `${PREFIX}-table_status`,
    table_code: `${PREFIX}-table_code`,
    toolbarHeader: `${PREFIX}-toolbarHeader`,
    tableBodyRow: `${PREFIX}-tableBodyRow`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
    [`& .${classes.button}`]: {
        position: "sticky",
        bottom: 0,
        zIndex: 3,
        backgroundColor: "#fff",
        marginTop: 10,
        padding: 7,
    },

    [`& .${classes.iconColor}`]: {
        color: theme.palette.success.main,
    },

    [`& .${classes.btnMargin}`]: {
        width: "100%",
    },

    [`& .${classes.mainSearch}`]: {
        margin: theme.spacing(0),
        width: "100%",
    },

    [`& .${classes.titles}`]: {
        flex: "1 1 100%",
    },

    [`& .${classes.toolbar}`]: {
        borderBottom: "1px #ccd1d6 solid",
        backgroundColor: "#f5f7f9",
        width: "100%",
        // justifyContent: "space-between",
    },

    [`& .${classes.searchForm}`]: {
        overflowY: "auto",
        width: "100%",
        height: "100%",
        margin: 0,
    },

    [`& .${classes.searchField}`]: {
        padding: theme.spacing(1, 1, 0, 1),
        position: "relative",
        overflow: "hidden",
    },

    [`& .${classes.overlay}`]: {
        width: "100%",
        height: "100%",
        position: "absolute",
        backgroundColor: "#ffffffa8",
        zIndex: 2,
    },

    [`& .${classes.searchPadding}`]: {
        padding: theme.spacing(1),
    },

    //////////////////////Drawer///////////////////

    [`& .${classes.drawer}`]: {
        width: drawerWidth,
        flexShrink: 0,
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },

    [`& .${classes.drawerPaper}`]: {
        width: drawerWidth,

        zIndex: "250",
        height: "100%",
        left: "initial",
        right: "initial",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },

    [`& .${classes.drawerHeader}`]: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: "flex-end",
    },

    [`& .${classes.content}`]: {
        flexGrow: 1,
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: 0,
    },

    [`& .${classes.contentShift}`]: {
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: "auto",
        // width: "calc(100% - 240px)",
        [theme.breakpoints.down("sm")]: {
            margin: "auto",
            width: "auto",
        },
    },

    [`& .${classes.tableRow}`]: {
        "&:hover": {
            "& .MuiTableCell-root": {
                backgroundColor: "#f5f5f5",
            },
        },
    },

    [`& .${classes.shipmentTable_shipmentStatus}`]: {
        [theme.breakpoints.up("sm")]: {
            zIndex: "100",
            backgroundColor: "#fff",
            position: "sticky",
            top: "0",
            right: "0",
        },
    },

    [`& .${classes.shipmentTable_shipmentCode}`]: {
        [theme.breakpoints.up("sm")]: {
            zIndex: "100",
            backgroundColor: "#fff",
            position: "sticky",
            top: "0",
            left: "0em",
        },
    },

    [`& .${classes.tablePaper}`]: {
        borderRadius: 0,
        width: "100%",
        display: "grid",
    },

    [`& .${classes.table_status}`]: {
        [theme.breakpoints.up("sm")]: {
            zIndex: "100",
            backgroundColor: "#fff",
            position: "sticky",
            top: "0",
            right: "0",
        },
    },

    [`& .${classes.table_code}`]: {
        [theme.breakpoints.up("sm")]: {
            zIndex: "100",
            backgroundColor: "#fff",
            position: "sticky",
            top: "0",
            left: "0",
        },
    },

    [`& .${classes.toolbarHeader}`]: {
        flex: 1,
    },

    [`& .${classes.tableBodyRow}`]: {
        "&:hover": {
            "& .MuiTableCell-root": {
                backgroundColor: "#f5f5f5",
            },
        },
    },
}));

export const LIST_CUSTOMER_WITH_NEW_SHIPMENT = (fromDate, toDate) => gqlb.query([
    {
        operation: "listCustomerWithNewShipments",
        fields: [
            {
                operation: "paginatorInfo",
                fields: [
                    "count",
                    "currentPage",
                    "firstItem",
                    "hasMorePages",
                    "lastItem",
                    "lastPage",
                    "perPage",
                    "total",
                ],
                variables: {},
            },
            {
                operation: "data",
                fields: [
                    "id",
                    "name",
                    // "newShipmentCount",
                    {
                        operation: `newShipmentCount(fromDate: ${fromDate ? `"${fromDate}"` : null},toDate: ${toDate ? `"${toDate}"` : null})`,
                        fields: [],
                        variables: {
                        },
                    },
                ],
                variables: {},
            },
        ],
        variables: {
            page: {
                type: "Int",
            },
            first: {
                type: "Int",
            },
            fromDate:{
                type: "Date"
            },
            toDate:{
                type: "Date"
            }
        },
    }
]);
const drawerWidth = 240;

const CustomerNewShipment = (props) => {
    const { t } = useTranslation();
    const urlQuery = urlParameters(window.location.search);

    const [rowsPerPage, setRowsPerPage] = useState(urlQuery["rowsPerPage"] ?? 20);
    const initSearch = () => {
        let searchParameters = {
            page: 0,
            refetch: true,
        };
        delete urlQuery["rowsPerPage"];
        return {
            ...searchParameters,
        };
    };
    const [search, setSearch] = useState(initSearch());

    const history = useHistory();
    const pushUrlSearch = (param) => {
        const queryParams = [];
        for (const i in param) {
            encodeURIComponent(param[i]) &&
                queryParams.push(
                    encodeURIComponent(i) + "=" + encodeURIComponent(param[i])
                );
        }
        const queryString = queryParams.join("&");

        const url = history.createHref({
            pathname: "/admin/customer-new-shipment",
            search: "?" + queryString,
        });
        windowUrl(url);
        //this will not effect on history.location.search
    };

    const handleChangePage = (event, newPage) => {
        pushUrlSearch({
            ...urlQuery,

            page: newPage,
            rowsPerPage: rowsPerPage,
        });

        setSearch((prev) => ({ ...prev, page: newPage }));
    };

    const handleChangeRowsPerPage = (event) => {
        pushUrlSearch({
            ...urlQuery,
            page: 0,
            rowsPerPage: +event.target.value,
        });

        setRowsPerPage(+event.target.value);
        setSearch((prev) => ({ ...prev, page: 0 }));
    };
    // ...(urlQuery['fromDate'] && { fromDate: urlQuery['fromDate'] }),
    //             ...(urlQuery['toDate'] && { toDate: urlQuery['toDate'] }),

    const listQuery = LIST_CUSTOMER_WITH_NEW_SHIPMENT(urlQuery['fromDate'], urlQuery['toDate'])
    console.log(listQuery);
    const { data, loading } = useQuery(
        gql`
      ${listQuery.query}
    `,
        {
            notifyOnNetworkStatusChange: true,
            fetchPolicy: "no-cache",
            nextFetchPolicy: "no-cache",
            variables: {
                first: rowsPerPage,
                page: search?.page + 1,
                fromDate: urlQuery['fromDate'] ?? null,
                toDate: urlQuery['toDate'] ?? null,
            },
            onCompleted: (data) => {
            },
        }
    );

    const customerNewShipment =
        data?.listCustomerWithNewShipments?.data !== null ? data?.listCustomerWithNewShipments?.data : null;

    return (
        <Root>
            <Grid
                container
                justifyContent="center"
                spacing={3}
                className={clsx(classes.content, classes.mainSearch)}
            >
                {/* *******Table******* */}
                <Toolbar className={clsx(classes.toolbar)} variant="dense">
                    <Typography className={classes.toolbarHeader} variant="h6">
                        {t("newSHipments")}
                    </Typography>
                </Toolbar>

                {loading || customerNewShipment?.length === 0 ? (
                    <Grid container item justifyContent="center" className={classes.main}>
                        {loading ? (
                            <FullScreenLoading minHeight='15%' />
                        ) : (
                            <EmptyTableMessage
                                loading={false}
                                message={t("searchNoResult")}
                            />
                        )}
                    </Grid>
                ) : (
                    <Paper className={classes.tablePaper}>
                        <TableFixedHeaderWraper>
                            <Table aria-labelledby="tableTitle" aria-label="enhanced table">
                                <TableHead>
                                    <TableRow>
                                        <FixedTableCell>{t("customer")}</FixedTableCell>
                                        <FixedTableCell>{t("shipmentsNumber")}</FixedTableCell>
                                        <FixedTableCell></FixedTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {customerNewShipment &&
                                        customerNewShipment?.map((row, index) => {
                                            return (
                                                <TableRow
                                                    hover
                                                    role="checkbox"
                                                    tabIndex={-1}
                                                    key={row.id}
                                                    className={classes.tableBodyRow}
                                                >
                                                    <CellLink pathname={`/admin/customers/${row?.id}`}>
                                                        {row?.name}
                                                    </CellLink>
                                                    <FixedTableCell>{row.newShipmentCount}</FixedTableCell>
                                                    <CellLink pathname={`/admin/shipments`}
                                                        search={`new=true&customerId=${row?.id}${urlQuery['fromDate'] ? `&fromDate=${urlQuery['fromDate']}&toDate=${urlQuery['toDate']}` : ''}`}
                                                    >
                                                        {t('viewShipments')}
                                                    </CellLink>
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableFixedHeaderWraper>
                        <MUITablePagination
                            count={data?.listCustomerWithNewShipments?.paginatorInfo?.total}
                            rowsPerPage={rowsPerPage}
                            page={!data?.listCustomerWithNewShipments ? 0 : search?.page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                )}
            </Grid>
        </Root>
    );
};

export default CustomerNewShipment;
