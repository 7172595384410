import {
  Collapse,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { memo, useRef, useState } from "react";

import { ChevronLeft, ChevronRight, ExpandMore } from "@mui/icons-material";
import clsx from "clsx";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineControl, AiOutlineDashboard } from "react-icons/ai";
import { BiLock, BiPhoneCall, BiTransferAlt, BiWrench, BiBarcodeReader, BiSupport } from "react-icons/bi";
import { BsGear, BsCart3 } from "react-icons/bs";
import { FiUser, FiUsers } from "react-icons/fi";
import { GoDash } from "react-icons/go";
import { HiOutlineClipboardList } from "react-icons/hi";
import { MdOutlinePendingActions } from "react-icons/md";
// import { CiBarcode } from "react-icons/ci";
import {
  RiBankLine,
  RiEBike2Line,
  RiMoneyDollarCircleLine,
} from "react-icons/ri";
import { VscPackage } from "react-icons/vsc";
import { withRouter } from "react-router-dom";
import { Globals } from "../Component/HOC/Classes/Globals";
import { SecuredNavLink } from "../Component/HOC/CustomComponents/Secured";
import CreateShipmentInDialog from "../Component/Shipments/CreateShipmentInDialog";
import Profile from "./NavDrawerProfile";
import HFWraper from "./WraperHeaderFooter";
import { GetCollectionPermissionSlug, GetManifestPermissionSlug, GetPaymentPermissionSlug, GetCustomerReqPermissionSlug } from "../helpers/getManifestPermissionSlug";

const PREFIX = "NavDrawer";

const classes = {
  root: `${PREFIX}-root`,
  bottomDrawer: `${PREFIX}-bottomDrawer`,
  dialog: `${PREFIX}-dialog`,
  drawer: `${PREFIX}-drawer`,
  drawerPaper: `${PREFIX}-drawerPaper`,
  profile: `${PREFIX}-profile`,
  topList: `${PREFIX}-topList`,
  navLink: `${PREFIX}-navLink`,
  listItemFocus: `${PREFIX}-listItemFocus`,
  outline: `${PREFIX}-outline`,
  nestedListItem: `${PREFIX}-nestedListItem`,
  navIcon: `${PREFIX}-navIcon`,
  navSubItem: `${PREFIX}-navSubItem`,
  renewalStyle: `${PREFIX}-renewalStyle`,
};

const drawerWidth = 248;

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.root}`]: {
    display: "flex",
  },

  [`& .${classes.bottomDrawer}`]: {
    [theme.breakpoints.down("sm")]: {
      width: "auto !important",
      height: "100%",
    },
  },

  [`& .${classes.dialog}`]: {
    minWidth: "325px",
  },

  [`& .${classes.drawer}`]: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },

  [`& .${classes.drawerPaper}`]: {
    zIndex: 1090,
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
    },
    "& .MuiList-padding": {
      padding: 0,
    },
    // overflow: "hidden"
  },
  [`& .${classes.renewalStyle}`]: {
    top: 48,
    height: "calc(100% - 48px)",
  },

  [`& .${classes.profile}`]: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    "& a": {
      color: theme.palette.text.secondary + "!important",
      textDecoration: "none",
    },
  },

  [`& .${classes.topList}`]: {
    "&:hover": {
      overflowY: "auto",
    },
    overflow: "hidden",
    height: "100vh",
    "& .MuiListItemIcon-root": {
      minWidth: theme.spacing(4),
    },

    textTransform: "capitalize",
  },

  [`& .${classes.navLink}`]: {
    textDecoration: "none",
    color: theme.palette.text.primary + "!important",
    "& svg": {
      color: theme.palette.text.secondary + "!important",
    },
    "&:hover": {
      color: theme.palette.text.primary + "!important",
    },
  },

  [`& .${classes.listItemFocus}`]: {
    color: `${theme.palette.primary.main}!important`,
    "& svg": {
      color: theme.palette.primary.main,
    },
  },

  [`& .${classes.outline}`]: {
    fontFamily: "Material Icons Outlined",
  },

  [`& .${classes.nestedListItem}`]: {
    paddingLeft: theme.spacing(4),
  },

  [`& .${classes.navIcon}`]: {
    fontSize: 20,
    color: "inherit",
  },

  [`& .${classes.navSubItem}`]: {
    minWidth: "20px !important",
  },
}));

const NavDrawer = (props) => {
  const { navDrawer, handleDrawerClose, drawerAnchor, top } = props;
  let collapseOpened = useRef(true);
  const { t } = useTranslation();
  const theme = useTheme();
  const storeNavLinkIndex = (index) =>
    localStorage.setItem("activeNavLink", index);

  const [nestedList, setNestedList] = useState({});
  const handleNestedNavLink = (type) => {
    storeNavLinkIndex(type);
    setNestedList((prev) => ({
      ...prev,
      [type]: !prev[type],
    }));
  };

  const [openShipmentDialog, setOpenShipmentDialog] = useState(false);
  const openShipmentDialogHandler = () => {
    setOpenShipmentDialog(true);
  };
  const closeShipmentDialogHandler = () => {
    setOpenShipmentDialog(false);
  };

  const linksList = [
    {
      pathname: "/admin",
      exact: true,
      icon: AiOutlineDashboard,
      primary: t("dashboard"),
    },
    {
      sectionName: "shipments",
      icon: VscPackage,
      primary: t("shipments"),
      children: [
        {
          pathname: "/admin/shipments",
          exact: true,
          primary: t("shipmentList"),
          permission: "shipping.shipment.list",
        },
        {
          pathname: "/admin/shipments/create",
          exact: true,
          primary: t("createShipment"),
          permission: "shipping.shipment.create",
          hideLink: true,
          action: openShipmentDialogHandler,
        },
        {
          pathname: "/admin/shipments-transactions",
          exact: true,
          primary: t("shipmentsTransactions"),
          permission: 'shipping.shipment_transaction.list',
        },
        {
          pathname: "/admin/shipments/import",
          exact: true,
          primary: t("exelImport"),
          permission: "shipping.shipment.import",

        },
        {
          pathname: "/admin/shipments-in-warehouse",
          exact: true,
          primary: t("shipmentsInWarehouse"),
          permission: "shipping.shipment.list_in_warehouse",

        },
        {
          pathname: "/admin/stocktake",
          exact: true,
          primary: t("stocktake"),
          permission: "shipping.shipment.list_in_warehouse",

        },
        {
          pathname: "/admin/delivery-list-actions",
          exact: true,
          primary: t("outToDeliver"),
          permission: "shipping.shipment.update_status",

        },
        {
          pathname: "/admin/receive-undelivered-shipments",
          exact: true,
          primary: t("receiveUndeliveredShipments"),
          permission: "shipping.shipment.receive_in_warehouse",
        },
        {
          pathname: "/admin/unpacked-shipments",
          exact: true,
          primary: t("unpackShipments"),
          permission: Globals.settings.warehousing ? "shipping.shipment.unpack" : Globals.settings.warehousing,
        },
      ],
    },
    {
      sectionName: "barcode",
      icon: BiBarcodeReader,
      primary: t("barcode"),
      children: [
        {
          pathname: "/admin/barcode-batches",
          exact: true,
          primary: t("barcodebatches"),
          permission: "shipping.barcode_batch.create",
        },
        {
          pathname: "/admin/barcode-batches/create",
          exact: true,
          primary: t("genrateBarcode"),
          permission: "shipping.barcode_batch.create",
        },
      ],
    },
    {
      sectionName: "pickupRequests",
      icon: BiPhoneCall,
      primary: t("pickupRequests"),
      children: [
        {
          pathname: "/admin/pickups",
          exact: true,
          primary: t("pickupRequestsList"),
          permission: "shipping.pickup.list",

        },
        {
          pathname: "/admin/pickups/create",
          exact: true,
          primary: t("createPickupRequest"),
          permission: "shipping.pickup.create",

        },
      ],
    },
    {
      sectionName: "transferBetweenBranches",
      icon: BiTransferAlt,
      primary: t("transferBetweenBranches"),
      children: [
        {
          pathname: "/admin/manifests/bmt",
          exact: true,
          primary: t("branchTransfareTo"),
          permission: !Globals.user.hasPermission("shipping.manifest_bmt.use_type_dlv") && !Globals.user.hasPermission("shipping.manifest_bmt.use_type_rts") && GetManifestPermissionSlug('bmt', 'list'),
        },
        {
          pathname: "/admin/manifests/bmr",
          exact: true,
          primary: t("branchTransfareFrom"),
          permission: !Globals.user.hasPermission("shipping.manifest_bmt.use_type_dlv") && !Globals.user.hasPermission("shipping.manifest_bmt.use_type_rts") && GetManifestPermissionSlug('bmr', 'list'),
        },
        {
          pathname: "/admin/manifests/bmt/dlv",
          exact: true,
          primary: t('distributionTrips'),
          permission: Globals.user.hasPermission("shipping.manifest_bmt.use_type_dlv") && GetManifestPermissionSlug('bmt', 'list'),
        },
        {
          pathname: "/admin/manifests/bmt/rts",
          exact: true,
          primary: t('returnTrips'),
          permission: Globals.user.hasPermission("shipping.manifest_bmt.use_type_rts") && GetManifestPermissionSlug('bmt', 'list'),
        },
      ],
    },
    {
      sectionName: "mainfests",
      icon: HiOutlineClipboardList,
      primary: t("operationLists"),
      children: [
        {
          pathname: "/admin/manifests/pkm",
          exact: true,
          primary: t("pickupManifestLists"),
          permission: GetManifestPermissionSlug('pkm', 'list'),

        },
        {
          pathname: "/admin/manifests/rits",
          exact: true,
          primary: t("receiveInWarehouseLists"),
          permission: GetManifestPermissionSlug('rits', 'list'),

        },
        {
          pathname: "/admin/manifests/otd",
          exact: true,
          primary: t("outForDeliveryLists"),
          permission: GetManifestPermissionSlug('otd', 'list'),

        },
        {
          pathname: "/admin/manifests/htr",
          exact: true,
          primary: t("retryDeliveryLists"),
          permission: GetManifestPermissionSlug('htr', 'list'),

        },

        {
          pathname: "/admin/manifests/rts",
          exact: true,
          primary: t("returnToCustomerLists"),
          permission: GetManifestPermissionSlug('rts', 'list'),

        },
        {
          pathname: "/admin/manifests/otr",
          exact: true,
          primary: t("returnDeliveryLists"),
          permission: GetManifestPermissionSlug('otr', 'list'),

        },
        {
          pathname: "/admin/manifests/rtrn",
          exact: true,
          primary: t("returnHandlingLists"),
          permission: GetManifestPermissionSlug('rtrn', 'list'),

        },
      ],
    },
    {
      sectionName: "financialOperations",
      icon: RiMoneyDollarCircleLine,
      primary: t("financialOperations"),
      children: [
        {
          pathname: "/admin/collections/dlvby",
          exact: true,
          primary: t("collectFromDeliveryAgents"),
          permission: GetCollectionPermissionSlug('dlvby', 'list'),

        },
        {
          pathname: "/admin/collections/custm",
          exact: true,
          primary: t("collectCashShipments"),
          permission: GetCollectionPermissionSlug('custm', 'list'),

        },
        {
          pathname: "/admin/payments/custm",
          exact: true,
          primary: t("customerPayment"),
          permission: GetPaymentPermissionSlug('custm', 'list')

        },
        {
          pathname: "/admin/payments/dlvby",
          exact: true,
          primary: t("deliveryAgentPayment"),
          permission: GetPaymentPermissionSlug('dlvby', 'list'),

        },
        {
          pathname: "/admin/invoices",
          exact: true,
          primary: t("postpaidInvoices"),
          permission: "shipping.invoice.list",

        },
      ],
    },
    {
      sectionName: "customers",
      icon: FiUsers,
      primary: t("customers"),
      children: [
        {
          pathname: "/admin/customers/b2c",
          exact: true,
          primary: t("customersList"),
          permission: "shipping.customer_b2c.list",
        },
        {
          pathname: "/admin/customers/create/b2c",
          exact: true,
          primary: t("createCustomer"),
          permission: "shipping.customer_b2c.create",
        },
        {
          pathname: "/admin/registrations/b2c",
          exact: true,
          primary: t("registeredAccountsCustomers"),
          permission: "shipping.registration_b2c.list",

        },
        {
          pathname: "/admin/price-list/b2c",
          exact: true,
          primary: t("listPriceListsCustomers"),
          permission: "shipping.price_list_b2c.list",
        },
        {
          pathname: "/admin/not-paid-to-customer",
          exact: true,
          primary: t("shipmentNotPaidToCustomer"),
          permission: "shipping.shipment.list_customer_not_paid",
        },
      ],
    },
    {
      sectionName: "individuals",
      icon: FiUsers,
      primary: t("individuals"),
      children: [
        {
          pathname: "/admin/customers/c2c",
          exact: true,
          primary: t("individualsList"),
          permission: "shipping.customer_c2c.list",
        },
        {
          pathname: "/admin/customers/create/c2c",
          exact: true,
          primary: t("createIndividuals"),
          permission: "shipping.customer_c2c.create",
        },
        {
          pathname: "/admin/registrations/c2c",
          exact: true,
          primary: t("registeredAccountsIndividuals"),
          permission: "shipping.registration_c2c.list",
        },
        {
          pathname: "/admin/price-list/c2c",
          exact: true,
          primary: t("listPriceListsIndividuals"),
          permission: "shipping.price_list_c2c.list",

        },
      ],
    },
    {
      sectionName: "deliveryAgents",
      icon: RiEBike2Line,
      primary: t("deliveryAgents"),
      children: [
        {
          pathname: "/admin/delivery-agents",
          exact: true,
          primary: t("deliveryAgentsList"),
          permission: "shipping.delivery_agent.list",

        },
        {
          pathname: "/admin/delivery-agents/create",
          exact: true,
          primary: t("createDeliveryAgents"),
          permission: "shipping.delivery_agent.create",

        },
        {
          pathname: "/admin/commission-list",
          exact: true,
          primary: t("deliveryAgentsCommissionsList"),
          permission: "shipping.commission_list.list",

        },
        {
          pathname: "/admin/routes",
          exact: true,
          primary: t("deliveryAgentRoutes"),
          permission: "shipping.route.list",

        },
        {
          pathname: "/admin/uncollected-from-delivery-agents",
          exact: true,
          primary: t("uncollectedFromDeliveryAgents"),
          permission: "shipping.shipment.list_delivery_agent_custody",

        },
        {
          pathname: "/admin/delivery-agents/track",
          exact: true,
          primary: t("trackDeliveryAgent"),
          permission: "shipping.delivery_agent_position.list",

        },
      ],
    },
    {
      sectionName: "finance",
      icon: RiBankLine,
      primary: t("finance"),
      children: [
        {
          pathname: "/admin/finance/gl-account",
          exact: true,
          primary: t("accountIndex"),
          permission: "accounting.gl_account.list",

        },
        {
          pathname: "/admin/finance/statement/main",
          exact: true,
          primary: t("mainAccountStatement"),
          permission: "accounting.gl_account.list",

        },
        {
          pathname: "/admin/finance/statement/sub",
          exact: true,
          primary: t("accountStatement"),
          permission: "accounting.journal_entry_record.list",

        },
        {
          pathname: "/admin/finance/statement/trialBalance",
          exact: true,
          primary: t("trialBalance"),
          permission: "accounting.gl_account.list",

        },
        {
          pathname: "/admin/finance/journal-entry",
          exact: true,
          primary: t("journalEntries"),
          permission: "accounting.journal_entry.list",

        },
        {
          pathname: "/admin/vouchers",
          exact: true,
          primary: t("vouchers"),
          permission: "cash.voucher.list",

        },
        {
          pathname: "/admin/create-sheets",
          exact: true,
          primary: t("createSheets"),
          permission: "accounting.sheet.create",

        },
      ],
    },
    {
      sectionName: "warehousing",
      icon: BsCart3,
      primary: t("warehousing"),
      children: [
        {
          pathname: "/admin/products",
          exact: true,
          primary: t("productList"),
          permission: 'shipping.product.list',
          show: Globals.user.warehousing
        },
        {
          pathname: "/admin/products/create",
          exact: true,
          primary: t("createProduct"),
          permission: 'shipping.product.create',
          show: Globals.user.warehousing
        },
        {
          pathname: "/admin/products-transactions",
          exact: true,
          primary: t("productsTransactions"),
          permission: 'shipping.product_transaction.list',
          show: Globals.user.warehousing
        },
        {
          pathname: "/admin/warehouse-manifests/padd",
          exact: true,
          primary: t("receiveProductFromCustomer"),
          permission: Globals.settings.warehousing ? "shipping.warehouse_manifest.list" : Globals.settings.warehousing,
        },
        {
          pathname: "/admin/warehouse-manifests/prtrn",
          exact: true,
          primary: t("returnProductToCustomer"),
          permission: Globals.settings.warehousing ? "shipping.warehouse_manifest.list" : Globals.settings.warehousing,
        },
        {
          pathname: "/admin/warehouse-manifests/pmbt",
          exact: true,
          primary: t("sendProductTransfer"),
          permission: Globals.settings.warehousing ? "shipping.warehouse_manifest.list" : Globals.settings.warehousing,

        },
        {
          pathname: "/admin/warehouse-manifests/pmbr",
          exact: true,
          primary: t("receiveProductTransfer"),
          permission: Globals.settings.warehousing ? "shipping.warehouse_manifest.list" : Globals.settings.warehousing,
        },
        {
          pathname: "/admin/warehouse-pickups",
          exact: true,
          primary: t("pickupRequestsList"),
          permission: Globals.user.warehousing ? "shipping.warehouse_pickup.list" : Globals.user.warehousing,
        },
        {
          pathname: "/admin/warehouse-pickups/create",
          exact: true,
          primary: t("createPickupRequest"),
          permission: Globals.user.warehousing ? "shipping.warehouse_pickup.create" : Globals.user.warehousing,
        },
      ],
    },
    {
      sectionName: "basicConfig",
      icon: BiWrench,
      primary: t("basicConfig"),
      children: [
        {
          pathname: "/admin/organization",
          exact: true,
          primary: t("organizationData"),
          permission: "core.organization.view",

        },
        {
          pathname: "/admin/branches",
          exact: true,
          primary: t("branches"),
          permission: "core.branch.list",

        },
        {
          pathname: "/admin/zones",
          exact: true,
          primary: t("zonesAndSubzones"),
          permission: "shipping.zone.list",

        },
        {
          pathname: "/admin/shipping-services",
          exact: true,
          primary: t("shippingServicesList"),
          permission: "shipping.shipping_service.list",

        },
        {
          pathname: "/admin/cancellation-reasons",
          exact: true,
          primary: t("cancellationReasons"),
          permission: "shipping.cancellation_reason.list",

        },
        {
          pathname: "/admin/shipment-transaction-types",
          exact: true,
          primary: t("shipmentTransactionTypes"),
          permission: "shipping.shipment_transaction_type.list",

        },
        {
          pathname: "/admin/safes",
          exact: true,
          primary: t("safes"),
          permission: "cash.safe.list",

        },
        {
          pathname: "/admin/warehouse",
          exact: true,
          primary: t("WareHouseSections"),
          permission: "shipping.warehouse_section.list",

        },
        {
          pathname: "/admin/subsidiaries",
          exact: true,
          primary: t("subsidiaries"),
          permission: "accounting.subsidiary.list",

        },
        {
          pathname: "/admin/finance/journal-types",
          exact: true,
          primary: t("journalType"),
          permission: "accounting.journal_type.list",

        },
        {
          pathname: "/admin/lookup",
          exact: true,
          primary: t("lookup"),
          permission: "core.lookup.list",

        },
      ],
    },
    {
      sectionName: "technicalSupport",
      icon: BiSupport,
      primary: t("technicalSupport"),
      children: [
        {
          pathname: "/admin/crm/tickets",
          exact: true,
          primary: t("complaints"),
          show: Globals.settings.support && (Globals.user.hasPermission("crm.ticket.list") || Globals.user.member.teams.length > 0)
        },
        {
          pathname: "/admin/crm/categories",
          exact: true,
          primary: t("supportCategories"),
          permission: Globals.settings.support && "crm.category.list",
        },
        {
          pathname: "/admin/crm/teams",
          exact: true,
          primary: t("supportTeams"),
          permission: Globals.settings.support && "crm.team.list",
        },
      ],
    },
    {
      sectionName: "customersRequests",
      icon: FiUser,
      primary: t("customersRequests"),
      children: [
        {
          pathname: "/admin/customer-requests/pmnt",
          exact: true,
          primary: t("paymentRequests"),
          permission: Globals.settings.support && GetCustomerReqPermissionSlug('pmnt', 'list'),
        },
        {
          pathname: "/admin/customer-requests/rtrn",
          exact: true,
          primary: t("returnRequests"),
          permission: Globals.settings.support && GetCustomerReqPermissionSlug('rtrn', 'list'),
        },
        {
          pathname: "/admin/customer-requests/mtrl",
          exact: true,
          primary: t("materialRequests"),
          permission: Globals.settings.support && GetCustomerReqPermissionSlug('mtrl', 'list'),
        },
      ],
    },
    // {
    //   sectionName: "eInvoice",
    //   icon: BiLock,
    //   primary: t("eInvoice"),
    //   children: [
    //     {
    //       pathname: "/admin/e-invoice",
    //       exact: true,
    //       primary: t("itemList"),
    //       permission: "invoicing.item.list",
    //     },
    //   ],
    // },
    {
      sectionName: "security",
      icon: BiLock,
      primary: t("security"),
      children: [
        {
          pathname: "/admin/users",
          exact: true,
          primary: t("users"),
          permission: "core.user.list",
        },
        {
          pathname: "/admin/roles",
          exact: true,
          primary: t("roles"),
          permission: "core.role.list",
        },
      ],
    },
    {
      sectionName: "settings",
      icon: BsGear,
      primary: t("settings"),
      children: [
        {
          pathname: "/admin/shipping-settings",
          exact: true,
          primary: t("shippingSettings"),
          permission: "shipping.settings.update",
        },
        {
          pathname: "/admin/accounting-settings",
          exact: true,
          primary: t("accountingSettings"),
          permission: "accounting.settings.update",
        },
      ],
    },
    {
      sectionName: "maintenance",
      icon: AiOutlineControl,
      primary: t("maintenance"),
      children: [
        {
          pathname: "/admin/logs",
          exact: true,
          primary: t("logs"),
          permission: "core.log.view",
        },
      ],
    },
  ];

  return (
    <Root>
      <CreateShipmentInDialog
        open={openShipmentDialog}
        onClose={closeShipmentDialogHandler}
      />
      <Drawer
        // sx={{ visibility: !token ? "hidd/en" : undefined }}
        className={clsx(classes.drawer, {
          [classes.bottomDrawer]: navDrawer[drawerAnchor],
        })}
        variant="persistent"
        anchor={drawerAnchor}
        open={navDrawer[drawerAnchor]}
        onClose={() => handleDrawerClose()}
        classes={{
          paper: clsx(classes.drawerPaper, {
            [classes.bottomDrawer]: navDrawer[drawerAnchor],
            [classes.renewalStyle]: Boolean(top),
          }),
        }}
      >
        <HFWraper />
        <Divider />

        <div className={classes.profile}>
          <Profile profileData={Globals.user} />
        </div>
        <List className={classes.topList}>
          {linksList.map((link, index) => {
            if (!link.children) {
              return (
                <SecuredNavLink
                  key={index}
                  to={{ pathname: link.pathname }}
                  activeClassName={classes.listItemFocus}
                  className={classes.navLink}
                  exact={link.exact}
                >
                  <ListItem
                    button
                    onClick={() => {
                      drawerAnchor === "bottom" && handleDrawerClose();
                    }}
                  >
                    <ListItemIcon className={classes.navIcon}>
                      <link.icon />
                    </ListItemIcon>
                    <ListItemText
                      disableTypography={true}
                      primary={link.primary}
                    />
                  </ListItem>
                </SecuredNavLink>
              );
            } else {
              if (
                +localStorage.getItem("activeNavLink") === index &&
                collapseOpened.current
              ) {
                nestedList[index] = true;
                collapseOpened.current = false;
              }
              const authorized = link.children.some((child) =>
                child.show !== undefined ? child.show : Globals.user.hasPermission(child.permission)
              );
              return (
                authorized && (
                  <Fragment key={index}>
                    <ListItem button onClick={() => handleNestedNavLink(index)}>
                      <ListItemIcon>
                        <link.icon className={classes.navIcon} />
                      </ListItemIcon>
                      <ListItemText
                        disableTypography={true}
                        primary={link.primary}
                      />
                      {nestedList[index] ? (
                        <ExpandMore />
                      ) : theme.direction === "ltr" ? (
                        <ChevronRight />
                      ) : (
                        <ChevronLeft />
                      )}
                    </ListItem>
                    <Collapse
                      key={index}
                      in={nestedList[index] ?? false}
                      timeout="auto"
                      unmountOnExit
                    >
                      {link.children.map((child, i) => {
                        return (
                          <SecuredNavLink
                            key={i}
                            hideLink={child?.hideLink}
                            to={{ pathname: child.pathname }}
                            activeClassName={classes.listItemFocus}
                            exact={child.exact}
                            className={classes.navLink}
                            permission={child.permission}
                          >
                            <ListItem
                              className={classes.nestedListItem}
                              button
                              onClick={() => {
                                child?.action && child.action();
                                storeNavLinkIndex(index);
                                drawerAnchor === "bottom" &&
                                  handleDrawerClose();
                              }}
                            >
                              <ListItemIcon className={classes.navSubItem}>
                                <GoDash />
                              </ListItemIcon>
                              <ListItemText
                                disableTypography={true}
                                primary={child.primary}
                              />
                            </ListItem>
                          </SecuredNavLink>
                        );
                      })}
                    </Collapse>
                  </Fragment>
                )
              );
            }
          })}
        </List>
      </Drawer>
    </Root>
  );
};

export default withRouter(memo(NavDrawer));
