import { gql, useMutation } from "@apollo/client";
import {
  Box,
  Icon,
  IconButton,
  Tooltip,
} from "@mui/material";
import * as React from "react";

import * as gqlb from "gql-query-builder";
import { useTranslation } from "react-i18next";
import config from "../../config.json";

export const EXPOERT_MAIN_ACCOUNT_STATEMENT = gqlb.mutation({
  operation: "exportGlAccounts",
  variables: {
    input: {
      type: "ExportGlAccountsInput",
      required: true,
    },
  },
});

export default function ExportMainAccountStatement(props) {
  const { filters, disabled } = props;

  const { t } = useTranslation();

  let getBackendUri = (imgPath) => {
    const domain = config.backend.domain
      ? config.backend.domain
      : window.location.hostname;

    return `${config.backend.protocol}://${domain}:${config.backend.port}/${imgPath}`;
  };
  const [ExportAccountStatements] = useMutation(
    gql`
      ${EXPOERT_MAIN_ACCOUNT_STATEMENT.query}
    `,
    {
      onCompleted: (data) => {
        const path = data.exportGlAccounts;
        window.open(getBackendUri(path));
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  const handelExportAccountStatement = () => {
    ExportAccountStatements({
      variables: {
        input: {
          listFilters: { 
            ...(!filters?.level && filters?.glAccountId !=='null' && {parentId: filters?.glAccountId}),
            ...(filters?.level && {level: parseInt(filters?.level)})
          },
          dates: {
            fromDate: filters?.startDate,
            toDate: filters?.endDate,
            dayEdge: 'END_OF_DAY',
          }
        }
      },
    });
  };


  return (
    <>
      <Tooltip title={t("exportExcel")}>
        <Box component="span">
          <IconButton
            disabled={disabled}
            onClick={() => handelExportAccountStatement()}
            size="large"
          >
            <Icon>sim_card_download</Icon>
          </IconButton>
        </Box>
      </Tooltip>
    </>
  );
}

