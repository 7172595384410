import { Icon } from "@mui/material";
import { styled } from '@mui/material/styles';
import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";

import "./style.css";
const PREFIX = 'ServiceArea';

const classes = {
  root: `${PREFIX}-root`,
  icon: `${PREFIX}-icon`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    // background: theme.palette.background.default,
    "& .wpo-section-title span": {
      color: theme.palette.primary.main,
      fontSize: "16px",
    },
    "& .wpo-section-title h2:before": {
      background: theme.palette.primary.main,
    },
    "& .wpo-service-item .wpo-service-single .wpo-service-icon": {
      background: theme.palette.primary.main,
    },
  },

  [`& .${classes.icon}`]: {
    fontFamily: "Material Icons Outlined",
    fontSize: 50,
    verticalAlign: "middle",
    color: "white",
  }
}));

const ServiceArea = (props) => {
  const { t } = useTranslation("home");

  const sections = t("home:serviceSections", { returnObjects: true });

  return (
    <Root id="service" className={clsx("wpo-service-s2", classes.root)}>
      <div className="container">
        <div className="col-l2">
          <div className="wpo-section-title text-center">
            <span>{t("home:servicesSubtitle")}</span>
            <h2>{t("home:servicesTitle")}</h2>
          </div>
        </div>
        <div className="row">
          {sections.map((section, i) => (
            <Service
              key={i}
              icon={section.icon}
              title={section.title}
              body={section.body}
            />
          ))}
        </div>
      </div>
    </Root>
  );
};

export default ServiceArea;

const Service = (props) => {
  const { title, body, icon } = props;


  return (
    <div className="col-lg-4 col-md-6 col-12 flex">
      <div className="wpo-service-item flex">
        <div className="wpo-service-single">
          <div className="wpo-service-icon">
            <Icon className={classes.icon}>{icon}</Icon>
          </div>
          <h2>{title}</h2>
          <p>{body}</p>
        </div>
      </div>
    </div>
  );
};
