/* eslint-disable no-useless-concat */
import { gql, useQuery } from "@apollo/client";
import { styled } from '@mui/material/styles';
import { Edit } from "@mui/icons-material";
import { Icon, IconButton, Paper, Toolbar, Typography } from "@mui/material";
import clsx from "clsx";
import moment from "moment";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Globals } from "../HOC/Classes/Globals";
import { KeyValuePair } from "../HOC/CustomComponents/KeyValuePair";
import { Can, SecuredNavLink } from "../HOC/CustomComponents/Secured";
import SpanLink from "../HOC/CustomComponents/SpanLink";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import PieCharts from "../Home/PieCharts";
import StatisticsFilterHeader from "../Home/StatisticsFilterHeader";
import { CUSTOMERID } from "./Graphql";
import Grid from "@mui/material/Unstable_Grid2";
import { GetCustomerPermissionSlug } from "../../helpers/getManifestPermissionSlug";

const PREFIX = 'CustomerView';

const classes = {
  box: `${PREFIX}-box`,
  toolbar: `${PREFIX}-toolbar`,
  toolbarIcons: `${PREFIX}-toolbarIcons`,
  iconButton: `${PREFIX}-iconButton`,
  main: `${PREFIX}-main`,
  field: `${PREFIX}-field`,
  iconColor: `${PREFIX}-iconColor`,
  span: `${PREFIX}-span`,
  boxPie: `${PREFIX}-boxPie`
};

const StyledGrid = styled(Grid)((
  {
    theme
  }
) => ({
  [`& .${classes.box}`]: {
    padding: theme.spacing(3),
    flexGrow: 1,
  },
  [`& .${classes.boxPie}`]: {
    // padding: theme.spacing(3),
    flexGrow: 1,
  },

  [`& .${classes.toolbar}`]: {
    borderBottom: "1px #ccd1d6 solid",
    backgroundColor: "#f5f7f9",
    width: "100%",
  },

  [`& .${classes.toolbarIcons}`]: {
    flex: "1 1 100%",
    textAlign: "end",
  },

  [`& .${classes.iconButton}`]: {
    borderRadius: "0 4px 4px 0 ",
    boxShadow: "none",
    height: 45,
  },

  [`& .${classes.main}`]: {
    margin: 0,
    width: "100%",
  },

  [`& .${classes.field}`]: {
    width: 260,
    [theme.breakpoints.down("sm")]: {
      width: 170,
    },
  },

  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },
  [`& .${classes.span}`]: {
    display: "inline"
  }
}));

const formatDate = (date) => moment(date).locale("en").format("YYYY-MM-DD");
const initToDate = moment(new Date()).locale("en").format("YYYY-MM-DD");

const CustomerView = (props) => {
  const week = useRef(
    moment(new Date()).locale("en").subtract(7, "day").format("YYYY-MM-DD")
  );
  const month = useRef(
    moment(new Date()).locale("en").subtract(30, "day").format("YYYY-MM-DD")
  );

  const { t } = useTranslation();
  const viewName = {
    B2C: t("customerDetails"),
    C2C: t("individualDetails"),
  };

  const customerId = props.id ?? props.match?.params?.id?.trim();
  const [queryVariables, setQueryVariables] = useState({
    fromDate: month.current,
    toDate: moment(new Date()).locale("en").format("YYYY-MM-DD"),
    statusCode: null,
  });

  const onDateRangeAccept = (date, closeDateRangeDialog) => {
    setQueryVariables((prev) => ({
      ...prev,
      fromDate: formatDate(date[0]),
      toDate: formatDate(date[1]),
    }));
    closeDateRangeDialog();
  };
  const handleDateRange = (value, setSelectValue, setDateRangeDialog) => {
    let defaultParamter = {
      toDate: initToDate,
    };

    switch (value) {
      case "month":
        setSelectValue("month");
        defaultParamter.fromDate = month.current;
        break;
      case "week":
        setSelectValue("week");
        defaultParamter.fromDate = week.current;
        break;
      case "all":
        setSelectValue("all");
        break;

      case "period":
      default:
        setSelectValue("period");

        return setDateRangeDialog(true);
    }

    setQueryVariables((prev) => ({
      statusCode: prev.statusCode,
      ...defaultParamter,
    }));
  };

  const { loading, data } = useQuery(
    gql`
      ${CUSTOMERID.query}
    `,
    {
      fetchPolicy: "network-only",
      variables: { id: parseInt(customerId) },
    }
  );

  const isWarehousing = Globals.settings.warehousing;
  const user = Globals.user;
  const branchId = data?.customer?.branch?.id;
  const canAccessBranch = branchId ? user.canAccessBranch(branchId) : true;

  const pagePermission = data && user.hasPermission(GetCustomerPermissionSlug("shipping", "customer", data?.customer?.businessType?.code, "update"))

  const businessType = data?.customer?.businessType?.code
  const canEdit =
    pagePermission && canAccessBranch;
  const paymentTypes = data?.customer?.paymentTypes
    ?.map((i) => i.name)
    .toString()
    .replace(/,/g, " , ");

  return (
    <StyledGrid container justifyContent="center" sx={{ margin: "0", width: "100%" }}>
      {loading ? (
        <FullScreenLoading minHeight="25%" />
      ) : (
        <>
          <Toolbar className={clsx(classes.toolbar)} variant="regular">
            <Grid
              container
              sx={{ flexWrap: "initial", flexGrow: 1 }}
              alignItems="center"
            >
              <Grid className={classes.field}>
                <Typography variant="h6">{viewName[businessType]}</Typography>
              </Grid>
              <Grid className={classes.toolbarIcons}>
                <SecuredNavLink
                  permission={canEdit}
                  to={{ pathname: `/admin/customers/${customerId}/edit` }}
                >
                  <IconButton color={"primary"} size="large">
                    <Edit fontSize="inherit" />
                  </IconButton>
                </SecuredNavLink>
              </Grid>
            </Grid>
          </Toolbar>

          <Grid
            container
            justifyContent="center"
            sx={{ width: "100%", p: 2, gap: 2 }}
          >
            <Paper container className={classes.box} component={Grid}>
              <KeyValuePair title={t("code")} value={data?.customer.code} />
              <KeyValuePair title={businessType === "B2C" ? t("storeName") : t("individualName")} value={data?.customer.name} />
              <KeyValuePair
                title={t("createdAt")}
                value={data?.customer.createdAt}
              />
              <KeyValuePair
                title={t("active")}
                value={
                  data?.customer?.active ? (
                    <Icon className={classes.iconColor}>
                      check_circle_outline
                    </Icon>
                  ) : (
                    <Icon color="error">highlight_off</Icon>
                  )
                }
              />
              <KeyValuePair
                title={t("enterShipmentCode")}
                value={
                  data?.customer?.specifyShipmentCode ? (
                    <Icon className={classes.iconColor}>
                      check_circle_outline
                    </Icon>
                  ) : (
                    <Icon color="error">highlight_off</Icon>
                  )
                }
              />
              <KeyValuePair
                title={t("taxable")}
                value={
                  data?.customer?.taxable ? (
                    <Icon className={classes.iconColor}>
                      check_circle_outline
                    </Icon>
                  ) : (
                    <Icon color="error">highlight_off</Icon>
                  )
                }
              />
              <KeyValuePair
                title={t("shareCollectedFees")}
                value={
                  data?.customer?.shareCollectedFees ? (
                    <Icon className={classes.iconColor}>
                      check_circle_outline
                    </Icon>
                  ) : (
                    <Icon color="error">highlight_off</Icon>
                  )
                }
              />
              <KeyValuePair
                title={t("hideWaybillMobile")}
                value={
                  data?.customer?.hideWaybillMobile ? (
                    <Icon className={classes.iconColor}>
                      check_circle_outline
                    </Icon>
                  ) : (
                    <Icon color="error">highlight_off</Icon>
                  )
                }
              />
              <KeyValuePair
                title={t("payUncollected")}
                value={
                  data?.customer?.payUncollected ? (
                    <Icon className={classes.iconColor}>
                      check_circle_outline
                    </Icon>
                  ) : (
                    <Icon color="error">highlight_off</Icon>
                  )
                }
              />
              {isWarehousing && businessType === "B2C" && (<KeyValuePair
                title={t("warehousing")}
                value={
                  data?.customer?.warehousing ? (
                    <Icon className={classes.iconColor}>
                      check_circle_outline
                    </Icon>
                  ) : (
                    <Icon color="error">highlight_off</Icon>
                  )
                }
              />)}
              <KeyValuePair
                title={t("branch")}
                value={
                  data?.customer?.branch?.name && (
                    <SpanLink
                      pathname={`/admin/branches/${data?.customer?.branch?.id}`}
                    >
                      {data?.customer?.branch?.name}
                    </SpanLink>
                  )
                }
              />
              <KeyValuePair
                title={t("glAccount")}
                value={data?.customer.glAccount?.name}
              />
              <KeyValuePair
                title={t("source")}
                value={
                  <SpanLink pathname={`/admin/zone/${data?.customer.zone.id}`}>
                    {data?.customer?.zone?.name +
                      " / " +
                      data?.customer.subzone.name}
                  </SpanLink>
                }
              />
              <KeyValuePair
                title={t("priceType")}
                value={data?.customer.defaultPriceType?.name}
              />
              <KeyValuePair
                title={t("packageType")}
                value={data?.customer.defaultShipmentType?.name}
              />
              <KeyValuePair
                title={t("paymentTypes")}
                value={paymentTypes}
              />
              <KeyValuePair
                title={t("packageOpenPossibility")}
                value={data?.customer.defaultOpenable?.name}
              />
              <KeyValuePair
                title={t("customerCategories")}
                value={data?.customer.category?.name}
              />
              <KeyValuePair
                title={t("name")}
                value={data?.customer.representative}
              />
              <KeyValuePair
                title={t("activity")}
                value={data?.customer.activityName}
              />
              <KeyValuePair
              title={t("listPriceList")}
              value={
                data?.customer?.priceList?.name && user.hasPermission(GetCustomerPermissionSlug("shipping", "price_list", data?.customer?.businessType?.code, "list")) ? (
                  <SpanLink
                    pathname={`/admin/price-list/${data?.customer?.priceList?.id}`}
                  >
                    {data?.customer?.priceList?.name}
                  </SpanLink>
                ) : (
                  data?.customer?.priceList?.name
                )
              }
              />
              <KeyValuePair
                title={t("type")}
                value={data?.customer.businessType?.name}
              />
              <KeyValuePair
                title={t("paymentMethod")}
                value={data?.customer.paymentMethod?.name}
              />
              {
                data?.customer?.paymentMethod?.code === "VCSH" && (
                  <KeyValuePair
                    title={t("vodafoneCash")}
                    value={data?.customer?.vodafoneCash}
                  />
                )
              }
              {
                data?.customer?.paymentMethod?.code === "BNK" && (
                  <KeyValuePair
                    title={t("bank")}
                    value={data?.customer?.bank?.name}
                  />
                )
              }
              {
                data?.customer?.paymentMethod?.code === "BNK" && (
                  <KeyValuePair
                    title={t("accountNumber")}
                    value={data?.customer?.bankAccount}
                  />
                )
              }
              <KeyValuePair
                title={t("createdBy")}
                value={
                  data?.customer?.createdBy?.username && (
                    <SpanLink
                      pathname={`/admin/users/${data?.customer?.createdBy?.id}`}
                    >
                      {data?.customer?.createdBy?.username}
                    </SpanLink>
                  )
                }
              />
              <KeyValuePair
                title={t("users")}
                value={
                  data?.customer?.users && (
                    data?.customer?.users.map((user, index) =>
                      <SpanLink className={classes.span}
                        pathname={`/admin/users/${user.id}`}
                      >
                        {user.username} {!(data?.customer?.users.length - 1 === index) && ' , '}
                      </SpanLink>
                    )
                  )
                }
              />
            </Paper>
            <Paper
              container
              className={classes.box}
              sx={{ width: "100%" }}
              component={Grid}
            >
              <KeyValuePair
                title={t("country")}
                value={data?.customer.country?.name}
              />
              <KeyValuePair
                title={t("state")}
                value={data?.customer.state?.name}
              />
              <KeyValuePair
                title={t("city")}
                value={data?.customer.city?.name}
              />
              <KeyValuePair
                title={t("subzone")}
                value={data?.customer.area?.name}
              />
              <KeyValuePair title={t("phone")} value={data?.customer.phone} />
              <KeyValuePair title={t("mobile")} value={data?.customer.mobile} />
              <KeyValuePair
                title={t("mailBox")}
                value={data?.customer.mailBox}
              />
              <KeyValuePair
                title={t("postalCode")}
                value={data?.customer.postalCode}
              />
              <KeyValuePair
                title={t("address")}
                value={data?.customer.address}
              />
              <KeyValuePair title={t("email")} value={data?.customer.email} />
            </Paper>
            <Can showException permission={!user.account}>
              <Grid container className={classes.boxPie}>
                {/* <StatisticsFilterHeader
                  onDateRangeAccept={onDateRangeAccept}
                  handleDateRange={handleDateRange}
                /> */}
                <PieCharts
                  inputs={{ customerId: +customerId }}
                  onDateRangeAccept={onDateRangeAccept}
                  handleDateRange={handleDateRange}
                  dateRange={{
                    fromDate: queryVariables?.fromDate,
                    toDate: queryVariables?.toDate,
                  }}
                />
              </Grid>
            </Can>
          </Grid>
        </>
      )}
    </StyledGrid>
  );
};

export default CustomerView;
