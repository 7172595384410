import {
  Box,
  Checkbox,
  Grid,
  Paper,
  TableRow,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useMemo } from "react";

import { gql, useQuery } from "@apollo/client";
import * as gqlb from "gql-query-builder";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import CellLink from "../HOC/CustomComponents/CellLink";
import ShipmentRegion from "../HOC/CustomComponents/ShipmentRegion";
import ShipmentsTable from "../HOC/CustomComponents/ShipmentsTable";
import { TableCellColor } from "../HOC/CustomComponents/TableCellColor";
import EmptyTableMessage from "../HOC/FunctionComponents/EmptyTableMessage";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";
import { Globals } from "../HOC/Classes/Globals";
import { dateFormatLL } from "../../helpers/dateFunctions";

const PREFIX = "SearchTable";

const classes = {
  table: `${PREFIX}-table`,
  checkbox: `${PREFIX}-checkbox`,
  shipmentTable_code: `shipmentTable_code`,
  shipmentTable_status: `shipmentTable_status`,
  bodyCheckbox: `bodyCheckbox`,
  shipmentTable_bodyCode: `shipmentTable_bodyCode`,
  shipmentTable_bodyStatus: `shipmentTable_bodyStatus`,
  tableBodyRow: `${PREFIX}-tableBodyRow`,
  message: `${PREFIX}-message`,
  tablePaper: `${PREFIX}-tablePaper`,
  pagination: `${PREFIX}-pagination`,
  tableHead: `${PREFIX}-tableHead`,
  mainTableClass: `${PREFIX}-mainTableClass`,
};

const StyledPaper = styled(Paper)(({ theme }) => ({
  [`& .${classes.table}`]: {
    display: "grid",
    minHeight: "250px",
  },

  [`& .${classes.checkbox}`]: {
    [theme.breakpoints.up("sm")]: {
      zIndex: "101",
      left: 0,
      minWidth: 60,
    },
  },

  [`& .${classes.shipmentTable_code}`]: {
    [theme.breakpoints.up("sm")]: {
      zIndex: "101",
      left: "60px",
    },
  },

  [`& .${classes.shipmentTable_status}`]: {
    [theme.breakpoints.up("sm")]: {
      zIndex: "101",
      right: "0",
    },
  },

  [`& .${classes.bodyCheckbox}`]: {
    [theme.breakpoints.up("sm")]: {
      zIndex: "100",
      backgroundColor: "#fff",
      position: "sticky",
      top: 0,
      left: 0,
      minWidth: 60,
    },
  },

  [`& .${classes.shipmentTable_bodyCode}`]: {
    [theme.breakpoints.up("sm")]: {
      zIndex: "100",
      backgroundColor: "#fff",
      position: "sticky",
      top: 0,
      left: "60px",
    },
  },

  [`& .${classes.shipmentTable_bodyStatus}`]: {
    [theme.breakpoints.up("sm")]: {
      zIndex: "100",
      backgroundColor: "#fff",
      position: "sticky",
      top: "0",
      right: "0",
    },
  },

  [`& .${classes.tableBodyRow}`]: {
    "&:hover": {
      "& .MuiTableCell-root": {
        backgroundColor: "#f5f5f5",
      },
    },
  },

  [`& .${classes.message}`]: {
    display: "inline-block",
    maxWidth: 150,
    whiteSpace: "nowrap",
    overflow: "hidden !important",
    textOverflow: "ellipsis",
  },

  [`&.${classes.tablePaper}`]: {
    position: "relative",
    borderRadius: 0,
    width: "100%",
  },

  [`& .${classes.pagination}`]: {
    flexShrink: 0,
  },

  [`& .${classes.tableHead}`]: {
    "& .MuiTableCell-head": { fontWeight: 600 },
  },

  [`& .${classes.mainTableClass}`]: {
    "& td": {
      maxWidth: 250,
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
}));

const SUM_SHIPMENTS = gqlb.query({
  operation: "sumShipments",
  fields: [
    "price",
    "allDueFees",
    "deliveredAmount",
    "collectedFees",
    "pendingCollectionAmount",
    "collectedAmount",
    "amount",
    "totalAmount",
    "customerDueCredit",
    "customerDue",
    "customerDueDebit",
    "returningDueFees",
  ],
  variables: {
    input: {
      type: "ListShipmentsFilterInput",
    },
  },
});

export const defaultQueryFields = (permission) => [
  "id",
  "code",
  "date",
  "deliveredCanceledDate",
  `adminNotes @include(if: ${permission})`,
  "description",
  "senderName",
  "recipientName",
  "recipientAddress",
  "recipientMobile",
  "refNumber",
  "piecesCount",
  "price",
  "allDueFees",
  "deliveredAmount",
  "collectedFees",
  "amount",
  "totalAmount",
  "customerDue",
  "returningDueFees",
  "collected",
  "paidToCustomer",
  "collectedAmount",
  "deliveryDate",
  "attempts",
  {
    status: ["name", "code"],
  },
  {
    deliveryType: ["name"],
  },
  {
    customer: ["id", "name", "code"],
  },
  {
    recipientZone: ["id", "name"],
  },
  {
    originBranch: ["id", "name"],
  },
  {
    branch: ["id", "name"],
  },
  {
    recipientSubzone: ["name"],
  },
  {
    senderZone: ["id", "name"],
  },
  {
    senderSubzone: ["name"],
  },
  {
    priceType: ["name"],
  },
  {
    paymentType: ["name"],
  },
  {
    type: ["name"],
  },
  {
    openable: ["name"],
  },
  {
    lastDeliveryAgent: ["id", "name"],
  },
  {
    returnType: ["name"],
  },
  {
    cancellationReason: ["id", "name"],
  },
];

export const SEARCH = (dataFields) => {
  return gqlb.query({
    operation: "listShipments",
    fields: [
      {
        operation: "data",
        fields: dataFields,
        variables: {},
      },
      {
        operation: "paginatorInfo",
        fields: ["total"],
        variables: {},
      },
    ],
    variables: {
      input: {
        type: "ListShipmentsFilterInput",
      },
      first: {
        type: "Int",
      },
      page: {
        type: "Int",
      },
    },
  });
};

const SearchTable = (props) => {
  const {
    skipListShipmentsQuery,
    brife,
    sum,
    queryVariables,
    onPageChange,
    onChangeRows,
    loading,
    page,
    rowsPerPage,
    tableBody,
    tableHeadCell,
    className,
    notifyOnNetworkStatusChange,
    queryFields,
    withCheckAll,
    setCheckedIds,
    setCheckedShipments,
    checkedIds = [],
  } = props;

  const { refetch, page: pageNum, ...restVariables } = queryVariables;
  const mergedClasses = Object.assign(classes, { ...className });

  const { t } = useTranslation();
  for (const key in restVariables) {
    if (restVariables[key] === "" || restVariables[key] === null) {
      delete restVariables[key];
    }

    // if (key === "search") {
    //   restVariables[key] = restVariables[key]
    //     .split(",")
    //     .filter((i) => i.trim() !== "")
    //     .map((i) => i.trim());
    // }
  }

  const input = {
    ...restVariables,
    ...(queryVariables?.toDate && { toDate: queryVariables?.toDate }),
    ...(queryVariables?.inWarehouse !== null &&
      queryVariables?.inWarehouse !== undefined && {
        inWarehouse: queryVariables?.inWarehouse,
      }),
  };

  const user = Globals.user;

  const adminNotesPermission = user.hasPermission(
    "shipping.shipment.view_admin_note"
  );

  const skipQuery = skipListShipmentsQuery || Object.keys(input).length === 0;
  const queryBody = queryFields ?? defaultQueryFields(adminNotesPermission);
  const { data, loading: listLoading } = useQuery(
    gql`
      ${SEARCH(queryBody).query}
    `,
    {
      skip: skipQuery,
      partialRefetch: refetch,
      notifyOnNetworkStatusChange: notifyOnNetworkStatusChange ?? true,
      variables: {
        input,
        first: rowsPerPage,
        page: page + 1,
      },

      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      onCompleted: (data) => {
        const shipments = data.listShipments.data;
        // console.log(shipments);
        setCheckedShipments && setCheckedShipments(shipments)
        // const checkbox = shipments.reduce((previous, current) => {
        //   if (!checkedIds.includes(current.id)) {
        //     previous.push(current.id);
        //   }
        //   return previous;
        // }, []);
        // setCheckedIds((prev) => [...prev, ...checkbox]);
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );
  const { data: sumShipments } = useQuery(
    gql`
      ${SUM_SHIPMENTS.query}
    `,
    {
      skip: brife || Boolean(skipQuery) || !data,
      partialRefetch: refetch,
      notifyOnNetworkStatusChange: true,
      variables: {
        input,
      },

      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      onError: (error) => {
        console.log(error);
      },
    }
  );

  useEffect(() => {
    !brife && loading(listLoading);

    return () => {};
  }, [brife, listLoading]);

  const listShipments = data?.listShipments?.data;
  const dataEmpty = listShipments?.length === 0;
  const defaultTableHeadCell = tableHeadCell
    ? tableHeadCell
    : [
        "code",
        "date",
        "deliveryCanclingDate",
        "redeliveryDate",
        "originBranch",
        "currentBranch",
        "attempts",
        "customer",
        "senderName",
        "source",
        "recipient",
        "destination",
        "address",
        "phone",
        "packageDescription",
        "packageOpen",
        "priceType",
        "paymentType",
        "packageType",
        "deliveryType",
        "returnType",
        "refNumber",
        "pieceCount",
        "packagePrice",
        "allDueFees",
        "deliveredAmount",
        "collectedFees",
        "customerNet",
        "totalPackagePrice",
        "customerDueAmount",
        "returningDueFees",
        "shippingAgent",
        "collected",
        "paidToCustomer",
        "CancellationReasonName",
        "adminNotes",
        "status",
      ];

  /////////////////////////////////// * CheckBox Function * ///////////////////////////////////
  const isChecked = (id) => {
    return checkedIds.includes(id);
  };
  const currentShipmentIds = listShipments?.map((i) => i.id);
  const shipmentsCount = currentShipmentIds?.length;

  const fieldSelected = currentShipmentIds?.filter((i) =>
    checkedIds.includes(i)
  )?.length;

  const indeterminate = () =>
    fieldSelected > 0 && fieldSelected < shipmentsCount;
  const selectAllChecked = () =>
    shipmentsCount > 0 && fieldSelected === shipmentsCount;

  const onCheckAll = (e) => {
    let ids = [];
    if (e.target.checked) {
      const checkedAll = new Set([...checkedIds, ...currentShipmentIds]);
      ids = [...checkedAll];
    } else {
      ids = checkedIds.filter((i) => !currentShipmentIds.includes(i));
    }
    setCheckedIds(ids);
  };
  const toggleCheck = (e, id) => {
    const checked = e.target.checked;
    let updateCheckedIds = [...checkedIds];
    if (checked) {
      updateCheckedIds.push(id);
    } else {
      updateCheckedIds = updateCheckedIds.filter((i) => i !== id);
    }
    setCheckedIds(updateCheckedIds);
  };

  const checkAll = (
    <FixedTableCell className={classes.checkbox}>
      <Checkbox
        edge="start"
        id="check-all-shipment"
        indeterminate={indeterminate()}
        checked={selectAllChecked()}
        inputProps={{ "aria-label": "select all desserts" }}
        tabIndex={-1}
        onChange={(e) => onCheckAll(e)}
        disableRipple
      />
    </FixedTableCell>
  );
  /////////////////////////////////// * End CheckBox Function * ///////////////////////////////////
  const defaultTableBody = tableBody
    ? (shipment, index) => tableBody(shipment, index, isChecked, toggleCheck)
    : (shipment, index) => (
        <TableRow key={index} className={classes.tableBodyRow}>
          <FixedTableCell className={classes.bodyCheckbox}>
            <Checkbox
              edge="start"
              checked={isChecked(shipment.id)}
              onChange={(e) => toggleCheck(e, shipment.id)}
              disableRipple
            />
          </FixedTableCell>
          <CellLink
            className={classes.shipmentTable_bodyCode}
            pathname={`/admin/shipments/${shipment.id}`}
          >
            {shipment.code}
          </CellLink>

          <FixedTableCell>{dateFormatLL(shipment.date)}</FixedTableCell>
          <FixedTableCell>{dateFormatLL(shipment.deliveredCanceledDate)}</FixedTableCell>
          <FixedTableCell>{shipment.deliveryDate}</FixedTableCell>
          <CellLink pathname={`/admin/branches/${shipment.originBranch.id}`}>
            {shipment.originBranch.name}
          </CellLink>
          <CellLink pathname={`/admin/branches/${shipment.branch.id}`}>
            {shipment.branch.name}
          </CellLink>
          <FixedTableCell>{shipment.attempts}</FixedTableCell>
          <CellLink pathname={`/admin/customers/${shipment.customer.id}`}>
            <Box
              component="span"
              fontWeight="bold"
            >{` (${shipment.customer.code}) `}</Box>
            <Box component="span">{shipment.customer.name}</Box>
          </CellLink>
          <FixedTableCell>{shipment.senderName}</FixedTableCell>
          <ShipmentRegion
            pathname={`/admin/zone/${shipment.senderZone.id}`}
            zone={shipment.senderZone?.name}
            subzone={shipment.senderSubzone.name}
          />
          <FixedTableCell>{shipment.recipientName}</FixedTableCell>
          <ShipmentRegion
            pathname={`/admin/zone/${shipment.recipientZone.id}`}
            zone={shipment.recipientZone?.name}
            subzone={shipment.recipientSubzone.name}
          />
          <FixedTableCell>{shipment.recipientAddress}</FixedTableCell>

          <FixedTableCell>{shipment.recipientMobile}</FixedTableCell>
          <FixedTableCell>{shipment.description}</FixedTableCell>
          <FixedTableCell>{shipment.openable?.name}</FixedTableCell>

          <FixedTableCell>{shipment.priceType.name}</FixedTableCell>

          <FixedTableCell>{shipment.paymentType.name}</FixedTableCell>

          <FixedTableCell>{shipment.type.name}</FixedTableCell>

          <FixedTableCell>{shipment.deliveryType?.name}</FixedTableCell>
          <FixedTableCell>{shipment.returnType?.name}</FixedTableCell>

          <FixedTableCell>{shipment.refNumber}</FixedTableCell>

          <FixedTableCell>{shipment.piecesCount}</FixedTableCell>

          <FixedTableCell>{shipment.price}</FixedTableCell>

          <FixedTableCell>{shipment.allDueFees}</FixedTableCell>

          <FixedTableCell>{shipment.deliveredAmount}</FixedTableCell>

          <FixedTableCell>{shipment.collectedFees}</FixedTableCell>

          <FixedTableCell>{shipment.amount}</FixedTableCell>
          <FixedTableCell>{shipment.totalAmount}</FixedTableCell>

          <FixedTableCell>{shipment.customerDue}</FixedTableCell>

          <FixedTableCell>{shipment.returningDueFees}</FixedTableCell>

          {shipment.lastDeliveryAgent?.id ? (
            <CellLink
              pathname={`/admin/delivery-agents/${shipment.lastDeliveryAgent?.id}`}
            >
              {shipment.lastDeliveryAgent?.name}
            </CellLink>
          ) : (
            <FixedTableCell></FixedTableCell>
          )}

          <FixedTableCell>
            {shipment.collected === false ? t("no") : t("yes")}
          </FixedTableCell>

          <FixedTableCell>
            {shipment.paidToCustomer === false ? t("no") : t("yes")}
          </FixedTableCell>

          <FixedTableCell>{shipment.cancellationReason?.name}</FixedTableCell>
          <FixedTableCell>{shipment.adminNotes}</FixedTableCell>

          <TableCellColor
            className={classes.shipmentTable_bodyStatus}
            code={shipment.status.code}
          >
            {shipment.status.name}
          </TableCellColor>
        </TableRow>
      );

  const memorizedTableBody = useMemo(
    () => defaultTableBody,
    [isChecked, toggleCheck]
  );

  const sumRow =
    sum === false ? (
      sum
    ) : sum ? (
      sum(sumShipments?.sumShipments)
    ) : (
      <TableRow>
        <FixedTableCell className={classes.bodyCheckbox}> </FixedTableCell>
        <FixedTableCell className={classes.shipmentTable_bodyCode}>
          {" "}
        </FixedTableCell>
        <FixedTableCell colSpan={22} > </FixedTableCell>
        <FixedTableCell>
          <Typography variant="h6">
            {sumShipments?.sumShipments?.price?.toFixed(2) ?? "--"}
          </Typography>
        </FixedTableCell>
        <FixedTableCell>
          <Typography variant="h6">
            {sumShipments?.sumShipments?.allDueFees?.toFixed(2) ?? "--"}
          </Typography>
        </FixedTableCell>
        <FixedTableCell>
          <Typography variant="h6">
            {sumShipments?.sumShipments?.deliveredAmount?.toFixed(2) ?? "--"}
          </Typography>
        </FixedTableCell>
        <FixedTableCell>
          <Typography variant="h6">
            {sumShipments?.sumShipments?.collectedFees?.toFixed(2) ?? "--"}
          </Typography>
        </FixedTableCell>
        <FixedTableCell>
          <Typography variant="h6">
            {sumShipments?.sumShipments?.amount?.toFixed(2) ?? "--"}
          </Typography>
        </FixedTableCell>
        <FixedTableCell>
          <Typography variant="h6">
            {sumShipments?.sumShipments?.totalAmount?.toFixed(2) ?? "--"}
          </Typography>
        </FixedTableCell>
        <FixedTableCell>
          <Typography variant="h6">
            {(
              sumShipments?.sumShipments?.customerDueCredit -
                sumShipments?.sumShipments?.customerDueDebit
            )?.toFixed(2)}
          </Typography>
        </FixedTableCell>
        <FixedTableCell>
          <Typography variant="h6">
            {sumShipments?.sumShipments?.returningDueFees?.toFixed(2) ?? "--"}
          </Typography>
        </FixedTableCell>
        <FixedTableCell colSpan={5}> </FixedTableCell>
        <FixedTableCell
          className={classes.shipmentTable_bodyStatus}
        > </FixedTableCell>
      </TableRow>
    );

  return (
    <StyledPaper className={classes.tablePaper}>
      <Grid container item alignItems="flex-start" className={classes.table}>
        {listLoading || dataEmpty ? (
          <EmptyTableMessage loading={listLoading} message={t("noShipments")} />
        ) : (
          <TableFixedHeaderWraper>
            <ShipmentsTable
              stickyHeader
              className={classes.mainTableClass}
              classes={mergedClasses}
              data={data?.listShipments?.data ?? []}
              headCells={defaultTableHeadCell}
              parseBodyCell={memorizedTableBody}
              sumRow={sumRow}
              checkAll={withCheckAll ? checkAll : null}
            />
          </TableFixedHeaderWraper>
        )}
      </Grid>

      <MUITablePagination
        classeName={classes.pagination}
        count={data?.listShipments?.paginatorInfo?.total}
        rowsPerPage={rowsPerPage}
        page={!data?.listShipments ? 0 : page}
        onPageChange={onPageChange}
        onRowsPerPageChange={onChangeRows}
        rowsPerPageOptions={brife && !onChangeRows ? [20] : null}
      />
    </StyledPaper>
  );
};
SearchTable.propTypes = {
  skipListShipmentsQuery: PropTypes.bool,
  brife: PropTypes.bool,
  // sum: PropTypes.bool,
  queryVariables: PropTypes.object,
  onPageChange: PropTypes.func,
  onChangeRows: PropTypes.func,
  loading: PropTypes.func,
  setCheckedIds: PropTypes.func,
};

export default SearchTable;
