import * as React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import { useTranslation } from "react-i18next";
import {
  IconButton,
  Box,
  Collapse,
  Typography,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import { gql, useMutation } from '@apollo/client';
import { SAVE_TICKET } from './Graphql';
import { useForm } from 'react-hook-form';
import { styled } from "@mui/material/styles";
import { Add, Edit } from '@mui/icons-material';
import MuiSwitch from '../HOC/MUI/MUIswitch';
import ControlMUItextField from '../HOC/MUI/ControlMUItextField';
import FormButton from '../CustomComponents/Buttons/FormButton';
import ButtonLoading from '../HOC/FunctionComponents/LoadingPages/ButtonLoading';
import clsx from 'clsx';
import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { setValidationError } from '../HOC/CustomFunctions/setValidationError';
import UploadFile from '../HOC/MUI/UploadFile';
import { LIST_CUSTOMERS_DROPDOWN, LIST_SUPPORT_CATEGORIES_DROPDOWN } from '../../GlobalsQuery/ListDropdown/ListDropdown';
import { CustomAutocomplete } from '../HOC/MUI/CustomAutocomplete';
import { Globals } from '../HOC/Classes/Globals';


const PREFIX = "BranchList";

const classes = {
  mainGrid: `${PREFIX}-mainGrid`,
  button: `${PREFIX}-button`,
};

const Root = styled("div")(({ theme }) => ({

  [`& .${classes.mainGrid}`]: {
    width: "100%",
    margin: theme.spacing(0),
    padding: theme.spacing(2),
    paddingTop: 0,
  },
  [`& .${classes.button}`]: {
    width: "100%",
    paddingRight: 0,
  }

}));

export default function CategoryDialog(props) {
  const { supportCategoriesObject } = props;
  const {
    handleSubmit,
    setValue,
    control,
    setError,
    errors,
  } = useForm();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const user = Globals.user;
  const [autocompleteValues, setAutocompleteValues] = React.useState({
    customer: null,
    category: null,
  });

  useEffect(() => {
    if (supportCategoriesObject) {
      const categoriesParams = [
        "id",
        "name",
        "code",
        "active",
      ];
      categoriesParams.forEach((i) => {
        supportCategoriesObject[i] && setValue(i, supportCategoriesObject[i]);
      });
    }
  }, [supportCategoriesObject, setValue])

  const [saveCategory, { loading: saveCategoryLoading }] = useMutation(
    gql`
      ${SAVE_TICKET.query}
    `
  );
  const onSubmit = (data) => {
    const image = data?.image?.[0];
    for (const key in data) {
      if (data[key] === "" || key === "fileName") {
        delete data[key]
      }
    }
    saveCategory({
      variables: {
        input: {
          ...data,
          ...(image && { image: image }),
        },
      },
    })
      .then((data) => {
        props.handleClose()
        props.refetch()
        enqueueSnackbar(t("saveSuccessful"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      })
      .catch(({ graphQLErrors }) => {
        setValidationError(graphQLErrors, setError);
        console.log(graphQLErrors);
      });
  };

  const parseData = (data) => {
    return data;
  };


  return (
    <Dialog
      onClose={props.handleClose}
      open={props.open}
      maxWidth={'xs'}
      fullWidth={true}
    >
      <DialogTitle id="scroll-dialog-title">
        <Grid container justifyContent="space-between" xs={12}>
          <Typography variant="h6">
            {t("createComplaint")}
          </Typography>
        </Grid>
      </DialogTitle>
      <DialogContent sx={{ p: 0 }}>
        <Root>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid
              container
              justifyContent="flex-start"
              aligns="center"
              className={clsx(classes.mainGrid)}
              spacing={2}
            >
              <Grid xs={12} sm={12} aligns="flex-start">
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  name={"code"}
                  label={t("code")}
                />
              </Grid>
              {!user.account &&<Grid xs={12} sm={12} aligns="flex-start">
                <CustomAutocomplete
                  control={control}
                  name={"customerId"}
                  label={t("customer")}
                  rules={{ required: t("fieldIsRequired") }}
                  parseData={(data) => parseData(data)}
                  query={LIST_CUSTOMERS_DROPDOWN.query}
                  variables={{
                    input: {

                    },
                  }}
                  defaultValue={autocompleteValues.customer}
                />
              </Grid>}
              <Grid xs={12} sm={12} aligns="flex-start">
                <CustomAutocomplete
                  control={control}
                  name={"categoryId"}
                  label={t("category")}
                  rules={{ required: t("fieldIsRequired") }}
                  parseData={(data) => parseData(data)}
                  query={LIST_SUPPORT_CATEGORIES_DROPDOWN.query}
                  variables={{
                    input: {

                    },
                  }}
                  defaultValue={autocompleteValues.category}
                />
              </Grid>
              <Grid xs={12} sm={12} aligns="flex-start">
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  name={"title"}
                  label={t("title")}
                  rules={{ required: t("fieldIsRequired") }}
                />
              </Grid>
              <Grid xs={12} sm={12} aligns="flex-start">
                <UploadFile
                  control={control}
                  setValue={setValue}
                  name="image"
                  icon={"add_photo_alternate"}
                  label={t("uploadImage")}
                  accept=".png,.jpg"
                // rules={{
                //   validate: {
                //     require: (value) =>
                //       value || watch("body") ? true : t("fieldIsRequired"),
                //   },
                // }}
                />
              </Grid>

              <Grid xs={12} sm={12} aligns="flex-start">
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  name={"body"}
                  rows={3}
                  label={t("details")}
                  rules={{ required: t("fieldIsRequired") }}
                />
              </Grid>
              <Grid container justifyContent="flex-end" className={classes.button}>
                <FormButton disabled={saveCategoryLoading}>
                  {saveCategoryLoading ? <ButtonLoading /> : t("save")}
                </FormButton>
              </Grid>

            </Grid>
          </form>
        </Root>
      </DialogContent>
    </Dialog>
  );
}