/* eslint-disable no-useless-concat */
import React, { Fragment } from "react";
import { styled } from '@mui/material/styles';
import { gql, useMutation, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import {
    Typography,
    Paper,
    Toolbar,
    Button,
    Collapse,
    IconButton,
    Box,
    Table,
    TableHead,
    TableRow,
    TableBody,
    DialogContent,
    DialogActions,
} from "@mui/material";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { ASSIGN_MEMBER, CREATE_TICKET_REPLY, UPDATE_TICKET_STATUS } from "./Graphql";
import { KeyValuePair } from "../HOC/CustomComponents/KeyValuePair";
// import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
// import EmptyTableMessage from "../HOC/FunctionComponents/EmptyTableMessage";
import { Globals } from "../HOC/Classes/Globals";
// import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
// import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";
import Grid from "@mui/material/Unstable_Grid2";
import SpanLink from "../HOC/CustomComponents/SpanLink";
import CustomTab from "../HOC/FunctionComponents/CustomTab";
import { Add, DoneAll, Image } from "@mui/icons-material";
import CustomButton from "../HOC/MUI/CustomButton";
import { useTheme } from "@emotion/react";
import { useState } from "react";
import CustomDialog from "../HOC/CustomComponents/CustomDialog";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import { LIST_MEMBERS_DROPDOWN } from "../../GlobalsQuery/ListDropdown/ListDropdown";
import { useForm } from "react-hook-form";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import { useSnackbar } from "notistack";
import config from "../../config.json";
import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import CellLink from "../HOC/CustomComponents/CellLink";
import EmptyTableMessage from "../HOC/FunctionComponents/EmptyTableMessage";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import UploadFile from "../HOC/MUI/UploadFile";
import TicketHistory from "./TicketHistory";
import * as gqlb from "gql-query-builder";

const PREFIX = 'WareHouseView';

const classes = {
    loading: `${PREFIX}-loading`,
    paper: `${PREFIX}-paper`,
    buttonContainer: `${PREFIX}-buttonContainer`,
    toolbar: `${PREFIX}-toolbar`,
    toolbarTable: `${PREFIX}-toolbarTable`,
    iconColor: `${PREFIX}-iconColor`,
    toolbarIcons: `${PREFIX}-toolbarIcons`,
    iconButton: `${PREFIX}-iconButton`,
    main: `${PREFIX}-main`,
    field: `${PREFIX}-field`,
    table: `${PREFIX}-table`,
    button: `${PREFIX}-button`,
    title: `${PREFIX}-title`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
    {
        theme
    }
) => ({
    [`& .${classes.loading}`]: {},

    [`& .${classes.button}`]: {
        margin: theme.spacing(1),
    },

    [`& .${classes.paper}`]: {
        padding: theme.spacing(2),
        margin: theme.spacing(2),
        flexGrow: 1,
    },
    [`& .${classes.buttonContainer}`]: {
        margin: theme.spacing(2),
        flexGrow: 1,
    },

    [`& .${classes.toolbar}`]: {
        borderBottom: "1px #ccd1d6 solid",
        backgroundColor: "#f5f7f9",
        width: "100%",
    },

    [`& .${classes.toolbarTable}`]: {
        width: "100%",
        backgroundColor: theme.palette.grey[50],
        borderBottom: "1px solid " + theme.palette.divider,
        borderRight: "1px solid " + theme.palette.divider,
    },

    [`& .${classes.iconColor}`]: {
        color: theme.palette.success.main,
    },

    [`& .${classes.toolbarIcons}`]: {
        flex: "1 1 100%",
        textAlign: "end",
    },

    [`& .${classes.iconButton}`]: {
        borderRadius: "0 4px 4px 0 ",
        boxShadow: "none",
        height: 45,
    },

    [`& .${classes.main}`]: {
        margin: 0,
        width: "100%",
    },

    [`& .${classes.field}`]: {
        width: 260,
        [theme.breakpoints.down("sm")]: {
            width: 170,
        },
    },

    [`& .${classes.table}`]: {
        display: "grid",
        minWidth: "100%",
    },

    [`& .${classes.title}`]: {
        flex: 1,
    }
}));

const TicketsView = (props) => {
    const ticketId = props.match.params.id?.trim();
    const { t } = useTranslation();
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();
    const user = Globals.user;
    const view_history = user.hasPermission("crm.ticket.view_history")
    const updateStatusPer = user.hasPermission("crm.ticket.update_status")
    const assigneePer = user.hasPermission("crm.ticket.assign")

    const commonTicketFields = [
        "id",
        "title",
        "code",
        "body",
        "updatedAt",
        "createdAt",
        "image",
        {
            customer: ["id", "code", "name"]
        },
        {
            category: ["id", "code", "name"]
        },
        {
            status: ["id", "code", "name"]
        },
        {
            assignee: ["id", "username"]
        },
        {
            createdBy: ["id", "username"]
        },
        {
            updatedBy: ["id", "username"]
        },
    ]

    const TICKET_ID = gqlb.query({
        operation: "ticket",
        fields: [
            ...commonTicketFields,
            {
                replies: [
                    "id",
                    "body",
                    "image",
                    {
                        createdBy: [
                            "id",
                            "username",
                            {
                                operation: "account",
                                fields: [
                                    {
                                        operation: "...on Customer",
                                        fields: ["id", "name"],
                                        variables: {},
                                    },
                                ],
                                variables: {},
                            },
                        ]
                    },
                    "createdAt"]
            },
            {
                operation: `history @include(if: ${view_history})`,
                fields: [
                    ...commonTicketFields,
                ],
                variables: {},
            }
        ],
        variables: {
            id: {
                type: "Int",
                required: true,
            },
        },
    });

    let getBackendUri = (imgPath) => {
        const domain = config.backend.domain
            ? config.backend.domain
            : window.location.hostname;

        return `${config.backend.protocol}://${domain}:${config.backend.port}${imgPath}`;
    };

    const {
        handleSubmit,
        setValue,
        control,
        setError,
        errors,
        watch
    } = useForm();

    const [formDialog, setFormDialog] = useState({
        state: false,
        function: null,
        confirmAction: true,
        title: "",
        content: "",
    });

    const closeFormDialog = () => {
        setValue("assigneeId", "")
        setValue("image", "");
        setValue("body", "");
        setValue("fileName", "");
        setFormDialog((prev) => ({
            ...prev,
            state: false,
        }));
    };

    const { loading, data, refetch } = useQuery(
        gql`
            ${TICKET_ID.query}
        `,
        {
            fetchPolicy: "network-only",
            variables: { id: parseInt(ticketId) },
            // onCompleted: (data) => {console.log(data);}
        }
    );

    const ticketData = data?.ticket ?? null
    const assignee = ticketData?.assignee;
    const ticketHistory = ticketData?.history;

    // Assign Content // 
    const [assignMemberMutation, { loading: assignMemberLoading }] =
        useMutation(
            gql`
        ${ASSIGN_MEMBER.query}
      `,
            {
                fetchPolicy: "no-cache",
                nextFetchPolicy: "no-cache",
                onError: (error) => {
                    enqueueSnackbar(error.message, {
                        variant: "error",
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "right",
                        },
                        TransitionComponent: Collapse,
                    });
                },
            }
        );

    const [updateTicketStatus, { loading: updateTicketStatusLoading }] =
        useMutation(
            gql`
        ${UPDATE_TICKET_STATUS.query}
      `,
            {
                fetchPolicy: "no-cache",
                nextFetchPolicy: "no-cache",
                onError: (error) => {
                    enqueueSnackbar(error.message, {
                        variant: "error",
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "right",
                        },
                        TransitionComponent: Collapse,
                    });
                },
            }
        );
    const [createTicketReply, { loading: createTicketReplyLoading }] =
        useMutation(
            gql`
        ${CREATE_TICKET_REPLY.query}
      `,
            {
                fetchPolicy: "no-cache",
                nextFetchPolicy: "no-cache",
                onCompleted: (data) => {
                    closeFormDialog()
                    refetch()
                    enqueueSnackbar(t("saveSuccessful"), {
                        variant: "success",
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "right",
                        },
                        TransitionComponent: Collapse,
                    });
                    setValue("image", "");
                    setValue("body", "");
                    setValue("fileName", "");
                },
                onError: (error) => {
                    enqueueSnackbar(error.message, {
                        variant: "error",
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "right",
                        },
                        TransitionComponent: Collapse,
                    });
                },
            }
        );

    const assigneeFun = () => {
        if (watch("assigneeId")) {
            assignMember();
        } else {
            setError("assigneeId", {
                type: "required",
                message: t("fieldIsRequired"),
            });
        }
    }

    const ticketStatus = ticketData?.status.code

    const assigneeButton = () => {
        return ticketStatus !== "CLOSED" && (
            <CustomButton
                className={classes.button}
                disabled={assignMemberLoading || updateTicketStatusLoading}
                variant="contained"
                size="medium"
                customcolor={theme.palette.success.main}
                onClick={() => setFormDialog((prev) => ({
                    state: true,
                    title: t("assign"),
                    content: assigneeContent,
                    confirmAction: true,
                    function: assigneeFun,
                }))}
                name="updateStatus"
                loading={assignMemberLoading || updateTicketStatusLoading}
                startIcon={
                    <DoneAll />
                }
            >
                {t("assign")}
            </CustomButton>
        )
    }

    const updateStatusMutationFunction = (status) => {
        updateTicketStatus({
            variables: {
                input: {
                    id: ticketData.id,
                    statusCode: status
                }
            }
        })
            .then((data) => {
                closeFormDialog()
                refetch()
                enqueueSnackbar(t("saveSuccessful"), {
                    variant: "success",
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    TransitionComponent: Collapse,
                });
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const updateButton = () => {
        const status = ticketStatus
        return status === "CLOSED" && (
            <CustomButton
                className={classes.button}
                disabled={updateTicketStatusLoading}
                variant="contained"
                size="medium"
                customcolor={theme.palette.success.main}
                onClick={() => setFormDialog((prev) => ({
                    state: true,
                    title: false,
                    content: t("confirmationStatus"),
                    confirmAction: true,
                    function: () => updateStatusMutationFunction("REOPEND"),
                }))}
                name="updateStatus"
                loading={updateTicketStatusLoading}
                startIcon={
                    <DoneAll />
                }
            >
                {t("reopen")}
            </CustomButton>
        )
    }

    const closeButton = () => {
        return ticketStatus !== "CLOSED" && (
            <CustomButton
                className={classes.button}
                disabled={updateTicketStatusLoading}
                variant="contained"
                size="medium"
                customcolor={theme.palette.error.main}
                onClick={() => setFormDialog((prev) => ({
                    state: true,
                    title: false,
                    content: t("confirmationStatus"),
                    confirmAction: true,
                    function: () => updateStatusMutationFunction("CLOSED"),
                }))}
                name="updateStatus"
                loading={updateTicketStatusLoading}
                startIcon={
                    <DoneAll />
                }
            >
                {t("close")}
            </CustomButton>
        )
    }

    const parseData = (data) => {
        return data;
    };

    const assigneeContent = (
        <Grid container item sm={12} alignItems="flex-start">
            <CustomAutocomplete
                control={control}
                errors={errors}
                name={"assigneeId"}
                label={t("assignee")}
                variables={{ input: { categoryId: ticketData?.category.id } }}
                parseData={(data) => parseData(data)}
                query={LIST_MEMBERS_DROPDOWN.query}
            />
        </Grid>
    )

    const assignMember = () => {
        assignMemberMutation({
            variables: {
                input: {
                    id: ticketData.id,
                    assigneeId: watch("assigneeId")
                },
            },
        })
            .then(({ data }) => {
                if (data?.assignTicket) {
                    updateTicketStatus({
                        variables: {
                            input: {
                                id: ticketData.id,
                                statusCode: "INPROGRESS"
                            }
                        }
                    })
                        .then((data) => {
                            closeFormDialog()
                            refetch()
                            setValue("assigneeId", "")
                            enqueueSnackbar(t("memberAssignSuccessfully"), {
                                variant: "success",
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "right",
                                },
                                TransitionComponent: Collapse,
                            });
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const openSignatureDialog = (imgPath) => {
        setFormDialog((prev) => ({
            state: true,
            title: null,
            content: (
                <Box component="img" alt="signature" src={getBackendUri(imgPath)} />
            ),
            confirmAction: false,
            function: () => { },
        }));
    };

    const userTeams = user.member?.teams
    const sameCat = userTeams?.map((e) => e.categories.find((i) => i.id === ticketData?.category.id))
    const createReplyCondition = ticketStatus !== "CLOSED" && (user.hasPermission("crm.ticket_reply.create") || sameCat.length > 0)
    const hasReply = ticketData?.replies.length > 0

    const onSubmitReply = (data) => {
        const image = data?.image?.[0];
        createTicketReply({
            variables: {
                input: {
                    ticketId: ticketData.id,
                    body: data.body,
                    ...(image && { image: image }),
                },
            },
        }).catch((error) => console.log(error));
    };

    const replyForm = (
        <Fragment>
            <form onSubmit={handleSubmit(onSubmitReply)}>
                <DialogContent>
                    <ControlMUItextField
                        control={control}
                        errors={errors}
                        name={"body"}
                        label={t("description")}
                        margin="normal"
                        rows={3}
                        rules={{ required: t("fieldIsRequired") }}
                    />
                    <UploadFile
                        control={control}
                        setValue={setValue}
                        name="image"
                        icon={"add_photo_alternate"}
                        label={t("uploadImage")}
                        accept=".png,.jpg"
                    />
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={closeFormDialog}>
                        {t("cancel")}
                    </Button>
                    <Button color="primary" type="submit" disabled={createTicketReplyLoading}>
                        {createTicketReplyLoading ? <ButtonLoading /> : t("confirm")}
                    </Button>
                </DialogActions>
            </form>
        </Fragment>
    );

    const openReplyDialog = () => {
        setFormDialog((prev) => ({
            state: true,
            title: t("addReply"),
            content: null,
            confirmAction: true,
            function: null,
        }));
    };

    const messageContent = (
        <Grid
            container
            sx={{ m: 0 }}
            className={clsx(classes.table, classes.tabpanel)}
        >
            <TableFixedHeaderWraper component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <FixedTableCell>{t("date")}</FixedTableCell>
                            <FixedTableCell align="left">{t("user")}</FixedTableCell>
                            <FixedTableCell align="left">{t("reply")}</FixedTableCell>
                            {createReplyCondition && (
                                <FixedTableCell align="left">
                                    <IconButton
                                        color="primary"
                                        onClick={openReplyDialog}
                                        size="large"
                                    >
                                        <Add />
                                    </IconButton>
                                </FixedTableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    {hasReply && (
                        <TableBody>
                            {ticketData?.replies.map((items, index) => {
                                // console.log(items?.createdBy?.account);
                                return (
                                    <TableRow key={index}>
                                        <FixedTableCell component="th" scope="row">
                                            {items.createdAt}
                                        </FixedTableCell>
                                        <CellLink
                                            align="left"
                                            pathname={"/admin/users/" + items.createdBy.id}
                                        // pathname={`${items?.createdBy?.account
                                        //     ? items.createdBy.account?.Customer
                                        //     && "/admin/customers/" +
                                        //     items.createdBy.account?.CustomerId
                                        //     : "/admin/users/" + items.createdBy.id
                                        //     }`}
                                        >
                                            {items.createdBy?.username}
                                        </CellLink>
                                        <FixedTableCell align="left">
                                            {items?.body}
                                            {items?.image && (
                                                <IconButton
                                                    size="small"
                                                    onClick={() => openSignatureDialog(items?.image)}
                                                >
                                                    <Image fontSize="small" />
                                                </IconButton>
                                            )}
                                        </FixedTableCell>
                                        {createReplyCondition && <FixedTableCell allowPlaceholder={false} />}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    )}
                </Table>

                {!hasReply && (
                    <EmptyTableMessage loading={loading} message={t("noReplies")} />
                )}
            </TableFixedHeaderWraper>
        </Grid >
    )

    const tapsArray = [
        {
            tabHead: t("replies"),
            panel: messageContent,
        },
    ];

    const hashKeys = ["replies"];

    if (view_history) {
        tapsArray.push({
            tabHead: t("history"),
            panel: <TicketHistory tickets={ticketHistory} />,
        });
        hashKeys.push("history");
    }

    return (
        <Root>
            <CustomDialog
                open={formDialog.state}
                title={formDialog.title}
                content={formDialog.content}
                fullWidth
                onClose={closeFormDialog}
                maxWidth="xs"
                actions={
                    formDialog.function !== null && (
                        <>
                            <Button color="primary" onClick={closeFormDialog}>
                                {formDialog.confirmAction ? t("cancel") : t("close")}
                            </Button>
                            {formDialog.confirmAction && (
                                <Button
                                    color="primary"
                                    disabled={assignMemberLoading ||
                                        updateTicketStatusLoading}
                                    type="submit"
                                    onClick={formDialog.function}
                                >
                                    {(assignMemberLoading || updateTicketStatusLoading) ? <ButtonLoading /> : t("confirm")}
                                </Button>
                            )}
                        </>
                    )
                }
            >
                {formDialog.function === null && replyForm}
            </CustomDialog>
            <Grid
                container
                justifyContent="center"
                sx={{ margin: "0", width: "100%" }}
            >
                {loading ? (
                    <FullScreenLoading minHeight="15%" />
                ) : (
                    <>
                        <Toolbar className={clsx(classes.toolbar)} variant="regular">
                            <Grid
                                container
                                sx={{ flexWrap: "initial", flexGrow: 1 }}
                                alignItems="center"
                            >
                                <Grid className={classes.field}>
                                    <Typography variant="h6">{t(`complaintData`)}</Typography>
                                </Grid>
                                {/* <Grid className={classes.toolbarIcons}>
                                    
                                </Grid> */}
                            </Grid>
                        </Toolbar>
                        <Paper container className={classes.paper} component={Grid}>
                            <KeyValuePair title={t("code")} value={data?.ticket?.code} />
                            <KeyValuePair
                                title={t("status")}
                                value={data?.ticket?.status.name}
                            />
                            {data?.ticket?.image &&
                                <KeyValuePair
                                    title={t("image")}
                                    value={
                                        <IconButton
                                            size="small"
                                            onClick={() => openSignatureDialog(data?.ticket?.image)}
                                        >
                                            <Image fontSize="small" />
                                        </IconButton>
                                    }
                                />}
                            <KeyValuePair title={t("title")} value={data?.ticket?.title} />
                            <KeyValuePair title={t("description")} value={data?.ticket?.body} />
                            <KeyValuePair
                                title={t("assignee")}
                                value={
                                    data?.ticket?.assignee ? (
                                        <SpanLink
                                            pathname={`/admin/users/${data?.ticket?.assignee.id}`}
                                        >
                                            {data?.ticket?.assignee.username}
                                        </SpanLink>
                                    ) : (
                                        "--"
                                    )
                                }
                            />
                            <KeyValuePair
                                title={t("category")}
                                value={
                                    <SpanLink
                                        pathname={`/admin/crm/categories`}
                                        search={`code=${data?.ticket?.category.id}`}
                                    >
                                        {data?.ticket?.category.name}
                                    </SpanLink>
                                }
                            />
                            <KeyValuePair
                                title={t("customer")}
                                value={
                                    data?.ticket?.customer ? (
                                        <SpanLink
                                            pathname={`/admin/customers/${data?.ticket?.customer.id}`}
                                        >
                                            {data?.ticket?.customer.name}
                                        </SpanLink>
                                    ) : (
                                        "--"
                                    )
                                }
                            />
                            <KeyValuePair title={t("createdAt")} value={data?.ticket?.createdAt} />
                            <KeyValuePair
                                title={t("createdBy")}
                                value={
                                    data?.ticket?.createdBy && (
                                        <SpanLink
                                            pathname={`/admin/users/${data?.ticket?.createdBy?.id}`}
                                        >
                                            {data?.ticket?.createdBy?.username}
                                        </SpanLink>
                                    )
                                }
                            />
                            <KeyValuePair title={t("updatedAt")} value={data?.ticket?.updatedAt} />
                            <KeyValuePair
                                title={t("updatedBy")}
                                value={
                                    data?.ticket?.updatedBy && (
                                        <SpanLink
                                            pathname={`/admin/users/${data?.ticket?.updatedBy?.id}`}
                                        >
                                            {data?.ticket?.updatedBy?.username}
                                        </SpanLink>
                                    )
                                }
                            />
                        </Paper>
                        <Grid sm={12} xs={12} container justifyContent="flex-end" className={classes.buttonContainer}>
                            {!assignee && assigneePer && assigneeButton()}
                            {updateStatusPer && (assignee || user.account || ticketStatus === "CLOSED") && updateButton()}
                            {(assignee || user.account) && updateStatusPer && closeButton()}
                        </Grid>
                        <CustomTab tapDetails={tapsArray} hashKeys={hashKeys} />

                    </>
                )}
            </Grid>
        </Root>
    );
};

export default TicketsView;
