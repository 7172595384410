import React, { useState, Fragment, useEffect } from "react";
import { styled } from '@mui/material/styles';
import clsx from "clsx";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Tooltip,
} from "@mui/material";

import { gql, useQuery } from "@apollo/client";
// import MUIDate from '../HOC/MUI/MUIDate';
import moment from "moment";
import * as gqlb from "gql-query-builder";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import { useTranslation } from "react-i18next";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { useForm } from "react-hook-form";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import FormButton from "../CustomComponents/Buttons/FormButton";
import MUIDateRangeCustom from "../HOC/MUI/MUIDateRangeCustom";
import { Globals } from "../HOC/Classes/Globals";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import ListBranches from "../HOC/ComponentWithSpecificQuery/ListBranches";
import { LIST_GL_ACCOUNTS_DROPDOWN } from "../../GlobalsQuery/ListDropdown/ListDropdown";
import { OpenInBrowser, Print } from "@mui/icons-material";
import { pushUrl, windowUrl } from "../HOC/CustomFunctions/pushUrl";
import { useHistory } from "react-router";
import { urlParameters } from "../HOC/CustomFunctions/urlParameters";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";
import ExportMainAccountStatement from "./ExportMainAccountStatement";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";

const PREFIX = 'MainFinanceStatement';

const classes = {
  divider: `${PREFIX}-divider`,
  ingButton: `${PREFIX}-ingButton`,
  mainSearch: `${PREFIX}-mainSearch`,
  table: `${PREFIX}-table`,
  toolbar: `${PREFIX}-toolbar`,
  tableContainer: `${PREFIX}-tableContainer`,
  balanceFont: `${PREFIX}-balanceFont`,
  fieldsContainer: `${PREFIX}-fieldsContainer`,
  balance: `${PREFIX}-balance`,
  headerToolbar: `${PREFIX}-headerToolbar`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.divider}`]: {
    minWidth: "93%",
  },

  [`& .${classes.ingButton}`]: {
    margin: theme.spacing(2, 1, 2),
    height: "3.5em",
    padding: "0 30px",
    width: "100%",
  },

  [`& .${classes.mainSearch}`]: {
    margin: theme.spacing(0),
    width: "100%",
  },

  [`& .${classes.table}`]: {
    display: "grid",
  },

  [`& .${classes.toolbar}`]: {
    padding: theme.spacing(1.5, 0),
    borderBottom: "1px solid #ccd1d6",
    backgroundColor: "#f5f7f9",
  },

  [`& .${classes.tableContainer}`]: {
    position: "relative",
    borderRadius: 0,
    boxShadow: "none",
  },

  [`& .${classes.balanceFont}`]: {
    "& .MuiTableCell-body": {
      fontWeight: 600,
      fontSize: "1rem",
    },
  },

  [`& .${classes.fieldsContainer}`]: {
    padding: theme.spacing(1),
    textAlign: "center",
  },

  [`& .${classes.balance}`]: {
    textAlign: "center",
  },

  [`& .${classes.headerToolbar}`]: {
    background: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
  }
}));

//*********Table Function*********

export const FINANCE = gqlb.query(
  [{
    operation: "listGLAccounts",
    fields: [
      {
        paginatorInfo: ["total"],
      },
      {
        data: [
          "id",
          "code",
          "name",
          "level",
          { type: ["code"] },
          {
            operation: "sumEntryRecords",
            fields: ["debit", "credit"],
            variables: {
              fromDate: { type: "Date" },
              toDate: { type: "Date" },
            },
          },
          {
            operation: "startBalance:balance",
            fields: [],
            variables: {
              startBalanceDate: { name: "date", type: "Date", required: true },
            },
          },
          {
            operation: "endBalance:balance",
            fields: [],
            variables: {
              endBalanceDate: { name: "date", type: "Date", required: true },
              endDayEdge: {
                name: "dayEdge",
                type: "DayStartEnd",
              },
            },
          },
        ],
      },
    ],
    variables: {
      input: {
        type: "ListGLAccountsFilterInput",
      },
      first: {
        type: "Int",
      },
      page: {
        type: "Int",
      },
    },
  },
  {
    operation: "sumGlAccounts",
    fields: [
      "startSumDebit",
      "startSumCredit",
      "trxSumDebit",
      "trxSumCredit",
      "endSumDebit",
      "endSumCredit",
    ],
    variables: {
      sumInput: {
        name: "input",
        type: "SumGlAccountsInput"
      }
    },
  }
  ]
);

//   [
//   {
//     operation: "listGLAccounts",
//     fields: [
//       "id",
//       "code",
//       "name",
//       { type: ["code"] },
//       {
//         operation: "sumEntryRecords",
//         fields: ["debit", "credit"],
//         variables: {
//           fromDate: { type: "Date" },
//           toDate: { type: "Date" },
//         },
//       },
//       {
//         operation: "startBalance:balance",
//         fields: [],
//         variables: {
//           startBalanceDate: { name: "date", type: "Date", required: true },
//         },
//       },
//       {
//         operation: "endBalance:balance",
//         fields: [],
//         variables: {
//           endBalanceDate: { name: "date", type: "Date", required: true },
//           endDayEdge: {
//             name: "dayEdge",
//             type: "DayStartEnd",
//           },
//         },
//       },
//       {
//         paginatorInfo: ["total"],
//       },
//     ],
//     variables: {
//       input: {
//         type: "ListGLAccountsFilterInput",
//       },
//     },
//   },
// ]
const initstartDate = moment(new Date())
  .locale("en")
  .subtract(1, "month")
  .add(1, "day")
  .format("YYYY-MM-DD");
const initEndDate = moment(new Date()).locale("en").format("YYYY-MM-DD");

const TrialBalance = (props) => {
  const { t } = useTranslation(["translation", "validation"]);

  // const theme = useTheme();
  // const dir = theme.direction
  const urlQuery = urlParameters(window.location.search);

  const initDateRange = [
    urlQuery["startDate"] ? urlQuery["startDate"] : initstartDate,
    urlQuery["endDate"] ? urlQuery["endDate"] : initEndDate,
  ];
  const [dateRange, setDateRange] = useState(initDateRange);

  const [autocompleteValues, setAutocompleteValues] = useState({
    glAccount: null,
  });
  const level = urlQuery['level'] ? urlQuery["level"] : 1
  const startDate = moment(dateRange[0]).locale("en").format("YYYY-MM-DD");
  const endDate = moment(dateRange[1]).locale("en").format("YYYY-MM-DD");
  const [search, setSearch] = useState({
    glAccountId: null,
    startDate: startDate,
    endDate: endDate,
    level: level
  });
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const glAccountListPermission = Globals.user.hasPermission(
    "accounting.gl_account.list"
  );

  const history = useHistory();

  const searchParams = (param) => {
    const queryParams = [];
    for (const i in param) {
      encodeURIComponent(param[i]) &&
        queryParams.push(
          encodeURIComponent(i) + "=" + encodeURIComponent(param[i])
        );
    }
    const queryString = queryParams.join("&");
    return queryString;
  };

  const pushUrlSearch = (param, pathname, render) => {
    const params = searchParams(param);
    const url = history.createHref({
      pathname,
      search: "?" + params,
    });
    render ? pushUrl(props, url) : windowUrl(url);
    //this will not effect on history.location.search
  };

  useEffect(() => {
    if (!glAccountListPermission) {
      setSearch({
        startDate: startDate,
        endDate: endDate,
        level: level,
        renderState: true,
      });
    }
    if (props.match?.params?.id) {
      setSearch({
        startDate: startDate,
        endDate: endDate,
        level: level,
        renderState: true,
      });
    }
    return () => { };
  }, [level]);

  const {
    formState: { errors },
    handleSubmit,
    control,
    setError,
    setValue,
  } = useForm();

  useEffect(() => {
    setValue("level", level);
  }, [level]);
  const { data, loading } = useQuery(
    gql`
      ${FINANCE.query}
    `,
    {
      variables: {
        first: rowsPerPage,
        page: page + 1,
        input: {
          level: level
        },
        sumInput: {
          fromDate: search?.["startDate"],
          toDate: search?.["endDate"],
        },

        startBalanceDate: search?.["startDate"],
        endBalanceDate: search?.["endDate"],
        fromDate: search?.["startDate"],
        toDate: search?.["endDate"],

        endDayEdge: "END_OF_DAY",
      },
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
      notifyOnNetworkStatusChange: !search?.["renderState"],
      skip: !search,
      onError: ({ graphQLErrors }) => {
        console.log(graphQLErrors);
        setValidationError(graphQLErrors, setError);
      },
    }
  );
  const tableData = data?.listGLAccounts?.data;
  const tableTotalData = data?.sumGlAccounts;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const onSubmit = (data) => {
    const level = data.level
    setPage(0);
    pushUrlSearch(
      { startDate, endDate, level },
      `/admin/finance/statement/trialBalance/`
    );
    data["startDate"] = startDate;
    data["endDate"] = endDate;
    data["level"] = level;

    setSearch({ ...data, renderState: !search?.["renderState"] });
  };

  // const totals = (array = [], paramter, type) => {
  //   let sum = 0;

  //   array.forEach((ele) => {
  //     if (type === "credit" && ele[paramter] >= 0) {
  //       sum += ele[paramter];
  //     } else if (type === "debit" && ele[paramter] <= 0) {
  //       sum += ele[paramter];
  //     }
  //   });
  //   return sum;
  // };

  // const totalEntryRecords = (array = [], type) => {
  //   let sum = 0;

  //   array.forEach((ele) => {
  //     sum += ele?.["sumEntryRecords"]?.[type];
  //   });
  //   return sum;
  // };

  // const startBalanceDebit = totals(tableData, "startBalance", "debit");
  // const startBalanceCredit = totals(tableData, "startBalance", "credit");
  const startBalance = tableTotalData?.startSumCredit - tableTotalData?.startSumDebit;

  // const endBalanceDebit = totals(tableData, "endBalance", "debit");
  // const endBalanceCredit = totals(tableData, "endBalance", "credit");
  const endBalance = tableTotalData?.endSumCredit - tableTotalData?.endSumDebit;

  // const entryRacordsDebit = totalEntryRecords(tableData, "debit");
  // const entryRacordsCredit = totalEntryRecords(tableData, "credit");
  const entryRacords = tableTotalData?.trxSumCredit - tableTotalData?.trxSumDebit;

  const onPrint = (param) => {
    const params = searchParams(search);
    const domain = `${window.location.origin}`;
    window.open(`${domain}${param}?${params}`);
  };
  const HandelLevel = (num) => {
    let level = ''
    for (let index = 0; index < num; index++) {
      level += '-'
    }
    return level
  }
  return (
    <Root>
      <Grid
        container
        justifyContent="center"
        className={clsx(classes.mainSearch)}
      >
        {/* *******Table******* */}
        <Grid container item className={classes.table}>
          <Toolbar variant="dense" className={classes.headerToolbar}>
            <Grid container item justifyContent="flex-start">
              <Typography variant="h6">{t("trialBalance")}</Typography>
            </Grid>
            <ExportMainAccountStatement
              disabled={!Boolean(data)}
              filters={search}
            />
            <Tooltip title="print trail balance">
              <Box component="span">
                <IconButton
                  aria-label="filter list"
                  size="large"
                  onClick={() => onPrint("/finance/statement/trialBalance")}
                  disabled={!Boolean(data)}
                >
                  <Print />
                </IconButton>
              </Box>
            </Tooltip>
          </Toolbar>
          <Toolbar className={classes.toolbar} variant="regular">
            <Grid
              container
              item
              component={"form"}
              alignItems="center"
              justifyContent="flex-start"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                className={classes.fieldsContainer}
              >
                <MUIDateRangeCustom
                  value={dateRange}
                  onChangeValue={(value) => setDateRange(value)}
                />
              </Grid>
              <Grid item
                sm={6}
                md={4}
                xs={12}
                className={classes.fieldsContainer}>
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  type="number"
                  name={"level"}
                  label={t("level")}
                  rules={{
                    required: t("fieldIsRequired"),
                    min: {
                      value: 1,
                      message: t("validation:min", {
                        field: t("level"),
                        number: "1",
                      }),
                    },
                    max: {
                      value: 20,
                      message: t("validation:max", {
                        field: t("level"),
                        number: "20",
                      }),
                    },
                  }}
                />
              </Grid>
              <Grid
                item
                sm={12}
                md={3}
                xs={12}
                className={classes.fieldsContainer}
              >
                <FormButton disabled={loading}>{t("search")}</FormButton>
              </Grid>
            </Grid>
          </Toolbar>
          {search && loading ? (
            <Grid container item justifyContent="center" alignItems="center">
              <Box textAlign="center" marginTop="50px" marginBottom="50px">
                <FullScreenLoading />
              </Box>
            </Grid>
          ) : (
            <Fragment>
              <TableFixedHeaderWraper
                component={Paper}
                className={classes.tableContainer}
              >
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <FixedTableCell colSpan={2} />

                      <FixedTableCell className={classes.balance} colSpan={2}>
                        {t("startBalance")}
                      </FixedTableCell>
                      <FixedTableCell className={classes.balance} colSpan={2}>
                        {t("sumEntryRecords")}
                      </FixedTableCell>
                      <FixedTableCell className={classes.balance} colSpan={2}>
                        {t("balance")}
                      </FixedTableCell>
                      <FixedTableCell />
                    </TableRow>
                    <TableRow>
                      <FixedTableCell>{t("code")}</FixedTableCell>
                      <FixedTableCell>{t("name")}</FixedTableCell>
                      <FixedTableCell>{t("debit")}</FixedTableCell>
                      <FixedTableCell>{t("credit")}</FixedTableCell>
                      <FixedTableCell>{t("debit")}</FixedTableCell>
                      <FixedTableCell>{t("credit")}</FixedTableCell>
                      <FixedTableCell>{t("debit")}</FixedTableCell>
                      <FixedTableCell>{t("credit")}</FixedTableCell>
                      <FixedTableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableData?.map((row, index) => {
                      return (
                        <TableRow key={Math.random()}>
                          <FixedTableCell component="th" scope="row">
                            {row.code}
                          </FixedTableCell>
                          <FixedTableCell>{HandelLevel(row.level)} {row.name}</FixedTableCell>
                          <FixedTableCell>
                            {row?.startBalance >= 0
                              ? "0"
                              : Math.abs(row?.startBalance)?.toFixed(2) ||
                              "0"}
                          </FixedTableCell>
                          <FixedTableCell>
                            {row?.startBalance <= 0
                              ? "0"
                              : Math.abs(row?.startBalance)?.toFixed(2) ||
                              "0"}
                          </FixedTableCell>
                          <FixedTableCell>
                            {row?.sumEntryRecords?.debit?.toFixed(2)}
                          </FixedTableCell>
                          <FixedTableCell>
                            {row?.sumEntryRecords?.credit?.toFixed(2)}
                          </FixedTableCell>
                          <FixedTableCell>
                            {row?.endBalance >= 0
                              ? "0"
                              : Math.abs(row?.endBalance)?.toFixed(2) || "0"}
                          </FixedTableCell>
                          <FixedTableCell>
                            {row?.endBalance <= 0
                              ? "0"
                              : Math.abs(row?.endBalance)?.toFixed(2) || "0"}
                          </FixedTableCell>
                          <FixedTableCell>
                            <IconButton
                              color="primary"
                              onClick={() =>
                                pushUrlSearch(
                                  {
                                    startDate,
                                    endDate,
                                  },
                                  `/admin/finance/statement/${row.type.code === "SUB" ? "sub" : "main"
                                  }/${row.id}`,
                                  true
                                )
                              }
                              size="large"
                            >
                              <OpenInBrowser />
                            </IconButton>
                          </FixedTableCell>
                        </TableRow>
                      );
                    })}

                    <TableRow className={classes.balanceFont}>
                      <FixedTableCell />
                      <FixedTableCell>{t("total")}</FixedTableCell>
                      <FixedTableCell>
                        {tableTotalData?.startSumDebit}
                      </FixedTableCell>
                      <FixedTableCell>
                        {tableTotalData?.startSumCredit}
                      </FixedTableCell>

                      <FixedTableCell>
                        {tableTotalData?.trxSumDebit}
                      </FixedTableCell>
                      <FixedTableCell>
                        {tableTotalData?.trxSumCredit}
                      </FixedTableCell>

                      <FixedTableCell>
                        {tableTotalData?.endSumDebit}
                      </FixedTableCell>
                      <FixedTableCell>
                        {tableTotalData?.endSumCredit}
                      </FixedTableCell>
                      <FixedTableCell />
                    </TableRow>

                    {/* <TableRow className={classes.balanceFont}>
                      <FixedTableCell />
                      <FixedTableCell>{t("balance")}</FixedTableCell>
                      <FixedTableCell>
                        {startBalance >= 0
                          ? "0"
                          : Math.abs(startBalance)?.toFixed(2) || "0"}
                      </FixedTableCell>
                      <FixedTableCell>
                        {startBalance <= 0
                          ? "0"
                          : Math.abs(startBalance)?.toFixed(2) || "0"}
                      </FixedTableCell>

                      <FixedTableCell>
                        {entryRacords >= 0
                          ? "0"
                          : Math.abs(entryRacords)?.toFixed(2) || "0"}
                      </FixedTableCell>
                      <FixedTableCell>
                        {entryRacords <= 0
                          ? "0"
                          : Math.abs(entryRacords)?.toFixed(2) || "0"}
                      </FixedTableCell>

                      <FixedTableCell>
                        {endBalance >= 0
                          ? "0"
                          : Math.abs(endBalance)?.toFixed(2) || "0"}
                      </FixedTableCell>
                      <FixedTableCell>
                        {endBalance <= 0
                          ? "0"
                          : Math.abs(endBalance)?.toFixed(2) || "0"}
                      </FixedTableCell>
                      <FixedTableCell />
                    </TableRow> */}
                  </TableBody>
                </Table>
              </TableFixedHeaderWraper>
              <MUITablePagination
                count={data?.listGLAccounts?.paginatorInfo?.total}
                rowsPerPage={rowsPerPage}
                page={!tableData.length === 0 ? 0 : page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                disableLastPage
              />
            </Fragment>
          )}
        </Grid>
      </Grid>
    </Root>
  );
};
export default TrialBalance;
