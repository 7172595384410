import React, { useState } from "react";
import { styled } from '@mui/material/styles';
import { useQuery, gql } from "@apollo/client";
import {
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
  Toolbar,
  Table,
  TableBody,
  TableRow,
  TableHead,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import clsx from "clsx";
import { SecuredNavLink } from "../HOC/CustomComponents/Secured";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { useTranslation } from "react-i18next";
import CellLink from "../HOC/CustomComponents/CellLink";
import { LIST_COMMISSION_LISTS } from "./Graphql";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import EmptyTableMessage from "../HOC/FunctionComponents/EmptyTableMessage";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";

const PREFIX = 'ListCommissions';

const classes = {
  appBar: `${PREFIX}-appBar`,
  title: `${PREFIX}-title`,
  paper: `${PREFIX}-paper`,
  main: `${PREFIX}-main`,
  mainContainer: `${PREFIX}-mainContainer`
};

const StyledGrid = styled(Grid)((
  {
    theme
  }
) => ({
  [`& .${classes.appBar}`]: {
    position: "relative",
  },

  [`& .${classes.title}`]: {
    flex: 1,
  },

  [`& .${classes.paper}`]: {
    display: "grid",
    width: "100%",
    borderRadius: 0,
  },

  [`& .${classes.main}`]: {
    marginTop: theme.spacing(2),
  },

  [`&.${classes.mainContainer}`]: {
    width: "100%",
  }
}));

const ListCommissions = (props) => {
  const { t } = useTranslation();

  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);
  const { data, loading } = useQuery(
    gql`
      ${LIST_COMMISSION_LISTS.query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: {
        first: rowsPerPage,
        page: page + 1,
      },
      onCompleted: (data) => {},
    }
  );
  const listCommissionLists = data?.listCommissionLists?.data;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  let tableBody;
  tableBody = (
    <StyledGrid
      container
      item
      xs={12}
      justifyContent="center"
      className={classes.mainContainer}
    >
      {/* *******Table******* */}
      <Paper className={clsx(classes.paper)}>
        <Toolbar className={clsx(classes.toolbar)} variant="dense">
          <Typography className={classes.title} color="inherit" variant="h6">
            {t("commissionList")}
          </Typography>

          <SecuredNavLink
            permission="shipping.commission_list.create"
            to={{ pathname: `/admin/commission-list/create` }}
          >
            <Tooltip title="Add Route">
              <IconButton aria-label="Add Route" size="large">
                <Add color="primary" />
              </IconButton>
            </Tooltip>
          </SecuredNavLink>
        </Toolbar>

        {loading || listCommissionLists?.length === 0 ? (
          <Grid container item justifyContent="center" className={classes.main}>
            {loading ? (
              <FullScreenLoading minHeight={30} />
            ) : (
              <EmptyTableMessage
                loading={false}
                message={t("searchNoResult")}
              />
            )}
          </Grid>
        ) : (
          <TableFixedHeaderWraper>
            <Table aria-labelledby="tableTitle" aria-label="enhanced table">
              <TableHead>
                <TableRow>
                  <FixedTableCell>{t("name")}</FixedTableCell>
                  <FixedTableCell>{t("code")}</FixedTableCell>
                  <FixedTableCell>{t("description")}</FixedTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {listCommissionLists &&
                  listCommissionLists?.map((row, index) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                        <CellLink
                          pathname={`/admin/commission-list/${row?.id}`}
                        >
                          {row?.name}
                        </CellLink>

                        <FixedTableCell>{row?.code}</FixedTableCell>
                        <FixedTableCell>{row?.description} </FixedTableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableFixedHeaderWraper>
        )}
        <MUITablePagination
          count={data?.listCommissionLists?.paginatorInfo?.total}
          rowsPerPage={rowsPerPage}
          page={!data?.listCommissionLists ? 0 : page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </StyledGrid>
  );
  return tableBody;
};

export default ListCommissions;
