import {
  Box,
  Icon,
  IconButton,
  Paper,
  Toolbar,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2";
import React, { Fragment, useState } from "react";

import { gql, useQuery } from "@apollo/client";
import { DeleteOutline, Edit, Print } from "@mui/icons-material";
import clsx from "clsx";
import emptyList from "../../assets/Image/received.png";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import { DELETE_MANIFEST_MUTATION, MANIFEST_BY_ID_AND_PRINT } from "./Graphql";

import { useTranslation } from "react-i18next";
import NotFound from "../../Error/NotFound";
import { KeyValuePair } from "../HOC/CustomComponents/KeyValuePair";
import { SecuredNavLink } from "../HOC/CustomComponents/Secured";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { EnhancedTableToolbar } from "./EnhancedTableToolbar";
import useWarehouseManifestDetails from "./WarehouseManifestDetails";

import { Globals } from "../HOC/Classes/Globals";
import MutationWithDialog from "../HOC/CustomComponents/MutationWithDialog";
import SpanLink from "../HOC/CustomComponents/SpanLink";
import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";
import moment from "moment";
import { dateFormatLL } from "../../helpers/dateFunctions";
import ProductsTable from "../HOC/CustomComponents/ProductsTable";

const PREFIX = "ManifestView";

const classes = {
  mainTracking: `${PREFIX}-mainTracking`,
  paper: `${PREFIX}-paper`,
  viewPaper: `${PREFIX}-viewPaper`,
  title: `${PREFIX}-title`,
  textAlign: `${PREFIX}-textAlign`,
  divider: `${PREFIX}-divider`,
  table: `${PREFIX}-table`,
  filterAnchor: `${PREFIX}-filterAnchor`,
  picker: `${PREFIX}-picker`,
  button: `${PREFIX}-button`,
  toolbar: `${PREFIX}-toolbar`,
  selectPadding: `${PREFIX}-selectPadding`,
  margin: `${PREFIX}-margin`,
  overlay: `${PREFIX}-overlay`,
  field: `${PREFIX}-field`,
  toolbarIcons: `${PREFIX}-toolbarIcons`,
  iconColor: `${PREFIX}-iconColor`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.mainTracking}`]: {
    margin: theme.spacing(2, 0),
    width: "100%",
  },

  [`& .${classes.paper}`]: {
    marginBottom: theme.spacing(2),
    position: "relative",
    minHeight: "327px",
    gridAutoRows: "max-content",
  },

  [`& .${classes.viewPaper}`]: {
    padding: theme.spacing(3),
  },

  [`& .${classes.title}`]: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },

  [`& .${classes.textAlign}`]: {
    textAlign: "justify",
    padding: theme.spacing(1),
  },

  [`& .${classes.divider}`]: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
      height: "1px",
    },
  },

  [`& .${classes.table}`]: {
    display: "grid",
    width: "100%",
  },

  [`& .${classes.filterAnchor}`]: {
    [theme.breakpoints.down("md")]: {
      width: "auto",
    },
    // overflow: "scroll",
    "& .MuiDrawer-paper": {
      [theme.breakpoints.up("sm")]: {
        // width: "50%"
      },
      top: "auto",
      width: "25%",
      border: "1px solid #0000001f",
      height: "100%",
      overflow: "hidden",
    },
  },

  [`& .${classes.picker}`]: {
    [theme.breakpoints.down("md")]: {
      width: "auto",
    },
    // overflow: "scroll",
    "& .MuiDrawer-paper": {
      [theme.breakpoints.up("sm")]: {
        width: "50%",
      },

      height: "100%",
      overflow: "hidden",
    },
  },

  [`& .${classes.button}`]: {
    margin: theme.spacing(1),
  },

  [`& .${classes.toolbar}`]: {
    borderBottom: "1px #ccd1d6 solid",
    backgroundColor: "#f5f7f9",
    width: "100%",
  },

  [`& .${classes.selectPadding}`]: {
    paddingTop: "6px",
    paddingBottom: "5px",
  },

  [`& .${classes.margin}`]: {
    margin: theme.spacing(1, 2),
  },

  [`& .${classes.overlay}`]: {
    backgroundColor: "#ffffffad",
    position: "absolute",
    zIndex: 2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    top: 0,
    right: 0,
  },

  [`& .${classes.field}`]: {
    width: 260,
    [theme.breakpoints.down("sm")]: {
      width: 170,
    },
  },

  [`& .${classes.toolbarIcons}`]: {
    flex: "1 1 100%",
    textAlign: "end",
  },

  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },
}));

export { };

const ManifestView = (props) => {
  const { t } = useTranslation();

  const [manifestType, setManifestType] = useState(null);

  const [disableForm, setDisableForm] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);

  const { details: manifestDetails } = useWarehouseManifestDetails({
    type: manifestType,
  });
  const manifestStatus = manifestDetails;

  /******************************************* Start Pagination Functions ********************************************/

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  /******************************************* Start Manifest By Id ********************************************/
  const manifestId = props.match.params.id?.trim();

  const { data, loading: manifestByIdLoad } = useQuery(
    gql`
      ${MANIFEST_BY_ID_AND_PRINT.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      // skip: !props.match.params.id,
      variables: {
        id: parseInt(props.match.params.id),
      },
      onCompleted: (data) => {
        if (data?.warehouseManifest === null) return;
        const warehouseManifest = data?.warehouseManifest;
        setManifestType(warehouseManifest?.transactionType?.type.code);

        setDisableForm(true);
      },
    }
  );
  const manifestProducts = data?.warehouseManifest?.manifestProducts;

  const user = Globals.user;
  const branchId = data?.safe?.branch?.id;
  const canAccessBranch = branchId ? user.canAccessBranch(branchId) : true;
  const canEdit =
    user.hasPermission("shipping.warehouse_manifest.update") &&
    canAccessBranch &&
    !data?.warehouseManifest?.approved &&
    !manifestByIdLoad;
  const canDelete =
    user.hasPermission("shipping.warehouse_manifest.delete") &&
    canAccessBranch &&
    !data?.warehouseManifest?.approved &&
    !manifestByIdLoad;

  /******************************************* End Manifest List ********************************************/
  let tableBody = null;
  let viewBody = null;
  tableBody = (
    <Grid container xs={12} justifyContent="center" sx={{ width: "100%" }}>
      {/* *******Table******* */}
      <Paper className={clsx(classes.table, classes.paper)}>
        <EnhancedTableToolbar
          {...props}
          selectedProduct={manifestProducts}
          manifestType={manifestType}
        />

        {manifestDetails && (
          <TableFixedHeaderWraper>
            <ProductsTable
              page={page}
              rowsPerPage={rowsPerPage}
              data={manifestProducts}
              headCells={manifestStatus.tableHead}
              parseBodyCell={manifestStatus.tableBody}
              total={manifestStatus.tableTotal}
              sumRow={manifestStatus?.sum}
              sumTableNumber={manifestStatus?.sumTableNumber}
            />
          </TableFixedHeaderWraper>
        )}
        {manifestProducts?.length > 4 && (
          <MUITablePagination
            count={manifestProducts?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}

        {(!manifestId || !disableForm) && (
          <Box className={classes.overlay}>
            <Typography color="textPrimary" variant="body1">
              {t("saveManifestRequired")}
            </Typography>
          </Box>
        )}
        <Grid container justifyContent="center" alignItems="center">
          {manifestProducts?.length === 0 && manifestId && disableForm && (
            <Box textAlign="center" marginTop="50px" marginBottom="50px">
              <img width="50%" src={emptyList} alt={"delivery box"} />
              <Box
                component={Typography}
                variant="h6"
                color="text.secondary"
                marginTop="0"
              >
                {t("noProducts")}
              </Box>
            </Box>
          )}
        </Grid>
      </Paper>
    </Grid>
  );
  viewBody = (
    <Grid
      container
      spacing={3}
      justifyContent="center"
      sx={{ margin: "0", width: "100%" }}
    >
      <Fragment>
        <Toolbar className={clsx(classes.toolbar)} variant="regular">
          <Grid
            container
            sx={{ flexWrap: "initial", width: "100%", flexGrow: 1 }}
            alignItems="center"
          >
            <Grid className={classes.field}>
              <Typography variant="h6">{t(`manifestDetails`)}</Typography>
            </Grid>
            <Grid className={classes.toolbarIcons}>
              <IconButton
                disabled={manifestByIdLoad}
                onClick={() =>
                  window.open(
                    `${window.location.origin}/report/print/warehousemanifest/${manifestId}/${manifestType}`
                  )
                }
                color="default"
                size="large"
              >
                <Print fontSize="inherit" />
              </IconButton>
              <SecuredNavLink
                permission={canEdit}
                to={{
                  pathname: `/admin/warehouse-manifests/${manifestId}/edit`,
                }}
              >
                <IconButton color={"primary"} size="large">
                  <Edit fontSize="inherit" />
                </IconButton>
              </SecuredNavLink>
              <MutationWithDialog
                mutaion={DELETE_MANIFEST_MUTATION.query}
                icon={DeleteOutline}
                iconTooltip={t("deleteManifest")}
                dialogTitle={t("deleteManifest")}
                dialogContent={t("deleteManifestConfirmation")}
                permission={Boolean(canDelete)}
                mutaionProps={{ variables: { id: parseInt(manifestId) } }}
                onCompleted={() =>
                  pushUrl(props, `/admin/warehouse-manifests/${manifestType}`)
                }
                onCompleteMessage={t("manifestDeleted")}
              />
            </Grid>
          </Grid>
        </Toolbar>
      </Fragment>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ margin: "0", width: "100%" }}
      >
        {manifestByIdLoad ? (
          <FullScreenLoading minHeight="25%" />
        ) : (
          <Paper
            container
            className={classes.viewPaper}
            sx={{ width: "100%", mt: 1 }}
            component={Grid}
          >
            <KeyValuePair
              title={t("manifestCode")}
              value={data?.warehouseManifest?.code}
            />
            <KeyValuePair
              title={t("date")}
              value={dateFormatLL(data?.warehouseManifest?.date)}
            />
            <KeyValuePair
              title={t("branch")}
              value={
                data?.warehouseManifest?.branch?.name && (
                  <SpanLink
                    pathname={`/admin/branches/${data?.warehouseManifest?.branch?.id}`}
                  >
                    {data?.warehouseManifest?.branch?.name}
                  </SpanLink>
                )
              }
            />
            <KeyValuePair
              title={t("transactionType")}
              value={
                data?.warehouseManifest?.transactionType?.name && (
                  <SpanLink
                    pathname={`/admin/shipment-transaction-types/${data?.warehouseManifest?.transactionType?.id}`}
                  >
                    {data?.warehouseManifest?.transactionType?.name}
                  </SpanLink>
                )
              }
            />
            {data?.warehouseManifest?.customer ? (
              <KeyValuePair
                title={t("customer")}
                value={
                  data?.warehouseManifest?.customer?.name && (
                    <SpanLink
                      pathname={`/admin/customers/${data?.warehouseManifest?.customer?.id}`}
                    >
                      {data?.warehouseManifest?.customer?.name}
                    </SpanLink>
                  )
                }
              />
            ) : null}
            {data?.warehouseManifest?.referenceBranch ? (
              <KeyValuePair
                title={
                  data?.warehouseManifest?.transactionType?.type?.code === "PMBT"
                    ? t("toBranch")
                    : t("fromBranch")
                }
                value={
                  data?.warehouseManifest?.referenceBranch?.name && (
                    <SpanLink
                      pathname={`/admin/branches/${data?.warehouseManifest?.referenceBranch?.id}`}
                    >
                      {data?.warehouseManifest?.referenceBranch?.name}
                    </SpanLink>
                  )
                }
              />
            ) : null}
            <KeyValuePair title={t("notes")} value={data?.warehouseManifest?.notes} />
            <KeyValuePair
              title={t("approved")}
              value={
                data?.warehouseManifest?.approved ? (
                  <Icon className={classes.iconColor}>
                    check_circle_outline
                  </Icon>
                ) : (
                  <Icon color="error">highlight_off</Icon>
                )
              }
            />
            {data?.warehouseManifest?.referenceManifest ? (
              <KeyValuePair
                title={t("referenceManifestCode")}
                value={
                  data?.warehouseManifest?.referenceManifest?.code && (
                    <SpanLink
                      pathname={`/admin/warehouse-manifests/${data?.warehouseManifest?.referenceManifest?.id}`}
                    >
                      {data?.warehouseManifest?.referenceManifest?.code}
                    </SpanLink>
                  )
                }
              />
            ) : null}
            <KeyValuePair
              title={t("createdBy")}
              value={
                data?.warehouseManifest?.createdBy?.username && (
                  <SpanLink
                    pathname={`/admin/users/${data?.warehouseManifest?.createdBy?.id}`}
                  >
                    {data?.warehouseManifest?.createdBy?.username}
                  </SpanLink>
                )
              }
            />
            <KeyValuePair
              title={t("updatedBy")}
              value={
                data?.warehouseManifest?.updatedBy?.username && (
                  <SpanLink
                    pathname={`/admin/users/${data?.warehouseManifest?.updatedBy?.id}`}
                  >
                    {data?.warehouseManifest?.updatedBy?.username}
                  </SpanLink>
                )
              }
            />
          </Paper>
        )}
      </Grid>
    </Grid>
  );

  const tableCondition = Boolean(manifestId) && !manifestByIdLoad;

  return data?.warehouseManifest === null ? (
    <NotFound />
  ) : (
    <Root>
      {props.children}

      {viewBody}

      <Grid
        container
        spacing={3}
        justifyContent="center"
        className={classes.mainTracking}
      >
        {tableCondition && tableBody}
      </Grid>
    </Root>
  );
};

export default ManifestView;
