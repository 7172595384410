import React, { useState, Fragment } from "react";
import { styled } from "@mui/material/styles";
import clsx from "clsx";
import {
  Grid,
  IconButton,
  useTheme,
  Typography,
  Toolbar,
  Drawer,
  TableRow,
  Box,
  Checkbox,
} from "@mui/material";

import { Clear, FilterList } from "@mui/icons-material";
import moment from "moment";
import { urlParameters } from "../HOC/CustomFunctions/urlParameters";
import { useTranslation } from "react-i18next";
import SearchTable from "../ShipmentSearch/SearchTable";
import { useHistory } from "react-router";
import { windowUrl } from "../HOC/CustomFunctions/pushUrl";
import CellLink from "../HOC/CustomComponents/CellLink";
import { TableCellColor } from "../HOC/CustomComponents/TableCellColor";
import ShipmentRegion from "../HOC/CustomComponents/ShipmentRegion";
import { } from "../../GlobalsQuery/ListDropdown/ListDropdown";
import FiltersForm from "../../Hooks/shipmentListFilters";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import ExportShipments from "../Shipments/ExportShipments";
import { Can } from "../HOC/CustomComponents/Secured";
import PrintIcon from "../Shipments/PrintIcon";
import HFWraper from "../../Layout/WraperHeaderFooter";
import useWidth from "../../Hooks/useWidth";
import { dateFormatLL } from "../../helpers/dateFunctions";
import PrintComponent from "../Shipments/PrintComponent";

const PREFIX = "ShipmentsInWarehouse";

const classes = {
  button: `${PREFIX}-button`,
  btnMargin: `${PREFIX}-btnMargin`,
  mainSearch: `${PREFIX}-mainSearch`,
  titles: `${PREFIX}-titles`,
  toolbar: `${PREFIX}-toolbar`,
  searchForm: `${PREFIX}-searchForm`,
  searchField: `${PREFIX}-searchField`,
  overlay: `${PREFIX}-overlay`,
  searchPadding: `${PREFIX}-searchPadding`,
  drawer: `${PREFIX}-drawer`,
  drawerPaper: `${PREFIX}-drawerPaper`,
  content: `${PREFIX}-content`,
  contentShift: `${PREFIX}-contentShift`,
  tableRow: `${PREFIX}-tableRow`,
  shipmentTable_shipmentStatus: `${PREFIX}-shipmentTable_shipmentStatus`,
  bodyCheckbox: `bodyCheckbox`,
  shipmentTable_bodyCode: `shipmentTable_bodyCode`,
  shipmentTable_bodyStatus: `shipmentTable_bodyStatus`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.button}`]: {
    position: "sticky",
    bottom: 0,
    zIndex: 3,
    backgroundColor: "#fff",
    marginTop: 10,
    padding: 7,
  },

  [`& .${classes.btnMargin}`]: {
    width: "100%",
  },

  [`& .${classes.mainSearch}`]: {
    margin: theme.spacing(0),
    width: "100%",
  },

  [`& .${classes.titles}`]: {
    flex: "1 1 100%",
  },

  [`& .${classes.toolbar}`]: {
    borderBottom: "1px #ccd1d6 solid",
    backgroundColor: "#f5f7f9",
    width: "100%",
    justifyContent: "space-between",
  },

  [`& .${classes.searchForm}`]: {
    overflowY: "auto",
    width: "100%",
    height: "100%",
    margin: 0,
  },

  [`& .${classes.searchField}`]: {
    padding: theme.spacing(1, 1, 0, 1),
    position: "relative",
    overflow: "hidden",
  },

  [`& .${classes.overlay}`]: {
    width: "100%",
    height: "100%",
    position: "absolute",
    backgroundColor: "#ffffffa8",
    zIndex: 2,
  },

  [`& .${classes.searchPadding}`]: {
    padding: theme.spacing(1),
  },

  //////////////////////Drawer///////////////////

  [`& .${classes.drawer}`]: {
    width: drawerWidth,
    flexShrink: 0,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },

  [`& .${classes.drawerPaper}`]: {
    width: drawerWidth,

    zIndex: "250",

    left: "initial",
    right: "initial",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },

  [`& .${classes.content}`]: {
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
  },

  [`& .${classes.contentShift}`]: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: "auto",
    width: "calc(100% - 240px)",
    [theme.breakpoints.down("sm")]: {
      margin: "auto",
      width: "auto",
    },
  },

  [`& .${classes.tableRow}`]: {
    "&:hover": {
      "& .MuiTableCell-root": {
        backgroundColor: "#f5f5f5",
      },
    },
  },

  [`& .${classes.shipmentTable_shipmentStatus}`]: {
    [theme.breakpoints.up("sm")]: {
      zIndex: "101",
      right: "0",
    },
  },

  [`& .${classes.bodyCheckbox}`]: {
    [theme.breakpoints.up("sm")]: {
      zIndex: "100",
      backgroundColor: "#fff",
      position: "sticky",
      top: 0,
      left: 0,
      minWidth: 60,
    },
  },

  [`& .${classes.shipmentTable_bodyCode}`]: {
    [theme.breakpoints.up("sm")]: {
      zIndex: "100",
      backgroundColor: "#fff",
      position: "sticky",
      top: 0,
      left: "60px",
    },
  },

  [`& .${classes.shipmentTable_bodyStatus}`]: {
    [theme.breakpoints.up("sm")]: {
      zIndex: "100",
      backgroundColor: "#fff",
      position: "sticky",
      top: "0",
      right: "0",
    },
  },
}));

const drawerWidth = 240;

const ShipmentsInWarehouse = (props) => {
  const urlQuery = urlParameters(window.location.search);

  const FILTER_INIT_DATE = {
    fromDate: moment(new Date())
      .locale("en")
      .subtract(1, "month")
      .add(1, "day")
      .format("YYYY-MM-DD"),
    toDate: moment(new Date()).locale("en").format("YYYY-MM-DD"),
  };

  const [tableLoad, setTableLoad] = useState(false);

  const screenWidth = useWidth();

  const [drawerState, setDrawerState] = React.useState({
    top: true,
    left: screenWidth === "xs" ? false : true,
    bottom: screenWidth === "xs" ? false : true,
    right: screenWidth === "xs" ? false : true,
  });

  const theme = useTheme();

  const { t } = useTranslation();

  const [rowsPerPage, setRowsPerPage] = useState(urlQuery["rowsPerPage"] ?? 20);
  const [page, setPage] = useState(urlQuery["page"] ?? 0);
  const [dateValue, setDateValue] = useState();

  const [checkedIds, setCheckedIds] = useState([]);
  const [queryVariables, setQueryVariables] = useState({
    refetch: false,
    page: 0,
  });
  const resetPage = () => setPage(0);
  const queryVariablesWithInitValue = {
    ...(queryVariables && queryVariables),
  };
  const {
    refetch,
    page: pageNum,
    ...excelFilters
  } = queryVariablesWithInitValue;
  const history = useHistory();

  const pushUrlSearch = (param) => {
    const queryParams = [];
    for (const i in param) {
      encodeURIComponent(param[i]) &&
        queryParams.push(
          encodeURIComponent(i) + "=" + encodeURIComponent(param[i])
        );
    }
    const queryString = queryParams.join("&");

    const url = history.createHref({
      pathname: "/admin/shipments-in-warehouse",
      search: "?" + queryString,
    });
    windowUrl(url);
    //this will not effect on history.location.search
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      (event.type === "keydown" || event.type === "submit") &&
      (event.type === "submit" || event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerState({ ...drawerState, [anchor]: open });
  };
  const filterAnchor = screenWidth === "xs" ? "bottom" : "left";

  const onSubmitFunc = () => {
    filterAnchor === "bottom" &&
      setDrawerState({ ...drawerState, [filterAnchor]: false });
  };

  const handleChangePage = (event, newPage) => {
    pushUrlSearch({
      ...urlQuery,
      ...dateValue,
      page: newPage,
      rowsPerPage: rowsPerPage,
    });

    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    pushUrlSearch({
      ...urlQuery,
      ...dateValue,
      page: 0,
      rowsPerPage: +event.target.value,
    });

    setRowsPerPage(+event.target.value);
    resetPage();
  };
  const clearCheckBox = () => setCheckedIds([]);

  const statusDataHandler = (data) => {
    return data && data.filter((i) => i.code === "HTR" || i.code === "RITS" || i.code === "DEX" || i.code === "RTS");
  };

  const queryFields = [
    "id",
    "code",
    "date",
    "collected",
    "paidToCustomer",
    "price",
    "allDueFees",
    "amount ",
    "collectedFees",
    "returningDueFees",
    "deliveredAmount",
    "customerDue",
    "senderName",
    "recipientName",
    "recipientAddress",
    "recipientMobile",
    {
      lastDeliveryAgent: ["id", "name"],
    },
    {
      recipientZone: ["id", "name"],
    },
    {
      recipientSubzone: ["name"],
    },
    {
      priceType: ["name"],
    },
    {
      paymentType: ["name"],
    },
    {
      deliveryType: ["name"],
    },
    {
      transactions: [
        {
          trxType: [
            {
              type: ["code"],
            },
          ],
        },
        {
          cancellationReason: ["id", "name"],
        },
      ],
    },
    {
      status: ["name", "code"],
    },
    {
      warehouseSection: ["id", "name"],
    },
  ];
  const brifeCells = [
    "code",
    "date",
    "wareHouse",
    "deliveryAgents",
    "senderName",
    "recipient",
    "destination",
    "recipientAddress",
    "phone",
    "priceType",
    "paymentType",
    "deliveryType",
    "collected",
    "paidToCustomer",
    "CancellationReasonName",
    "packagePrice",
    "allDueFees",
    "totalPackagePrice",
    "collectedFees",
    "returningDueFees",
    "deliveredAmount",
    "customerDueAmount",
    "shipmentStatus",
  ];
  const tableBody = (shipment, index, isChecked, toggleCheck) => (
    <TableRow className={classes.tableRow} key={index}>
      <FixedTableCell className={classes.bodyCheckbox}>
        <Checkbox
          edge="start"
          checked={isChecked(shipment.id)}
          onChange={(e) => toggleCheck(e, shipment.id)}
          disableRipple
        />
      </FixedTableCell>
      <CellLink
        className={classes.shipmentTable_bodyCode}
        pathname={`/admin/shipments/${shipment.id}`}
      >
        {shipment.code}
      </CellLink>
      <FixedTableCell>{dateFormatLL(shipment.date)}</FixedTableCell>
      <CellLink pathname={`/admin/warehouse/${shipment.warehouseSection?.id}`}>
        {shipment.warehouseSection?.name}
      </CellLink>
      <CellLink
        pathname={`/admin/delivery-agents/${shipment.lastDeliveryAgent?.id}`}
      >
        {shipment.lastDeliveryAgent?.name}
      </CellLink>
      <FixedTableCell>{shipment.senderName}</FixedTableCell>
      <FixedTableCell>{shipment.recipientName}</FixedTableCell>
      <ShipmentRegion
        pathname={`/admin/zone/${shipment.recipientZone.id}`}
        zone={shipment.recipientZone?.name}
        subzone={shipment.recipientSubzone.name}
      />
      <FixedTableCell>{shipment.recipientAddress}</FixedTableCell>
      <FixedTableCell>{shipment.recipientMobile}</FixedTableCell>
      <FixedTableCell>{shipment?.priceType?.name}</FixedTableCell>
      <FixedTableCell>{shipment?.paymentType?.name}</FixedTableCell>
      <FixedTableCell>{shipment?.deliveryType?.name}</FixedTableCell>
      <FixedTableCell>
        {shipment.collected === false ? t("no") : t("yes")}
      </FixedTableCell>
      <FixedTableCell>
        {shipment.paidToCustomer === false ? t("no") : t("yes")}
      </FixedTableCell>
      {shipment?.transactions.find((i) => i.trxType.type.code === "DEX")
        ?.cancellationReason ? (
        <FixedTableCell>
          {
            shipment?.transactions.find((i) => i.trxType.type.code === "DEX")
              ?.cancellationReason?.name
          }
        </FixedTableCell>
      ) : (
        <FixedTableCell> </FixedTableCell>
      )}
      <FixedTableCell>{shipment?.price}</FixedTableCell>
      <FixedTableCell>{shipment?.allDueFees}</FixedTableCell>
      <FixedTableCell>{shipment?.amount}</FixedTableCell>
      <FixedTableCell>{shipment?.collectedFees}</FixedTableCell>
      <FixedTableCell>{shipment?.returningDueFees}</FixedTableCell>
      <FixedTableCell>{shipment?.deliveredAmount}</FixedTableCell>
      <FixedTableCell>{shipment?.customerDue}</FixedTableCell>
      <TableCellColor
        code={shipment.status.code}
        className={classes.shipmentTable_bodyStatus}
      >
        {shipment?.status.name}
      </TableCellColor>
    </TableRow>
  );
  const sumShipments = (sum) => (
    <TableRow>
      <FixedTableCell className={classes.bodyCheckbox}> </FixedTableCell>

      <FixedTableCell className={classes.shipmentTable_bodyCode}>
        {" "}
      </FixedTableCell>
      <FixedTableCell colSpan={14}> </FixedTableCell>
      <FixedTableCell>
        <Typography variant="h6">{sum?.price?.toFixed(2) ?? 0}</Typography>
      </FixedTableCell>
      <FixedTableCell>
        <Typography variant="h6">{sum?.allDueFees?.toFixed(2) ?? 0}</Typography>
      </FixedTableCell>
      <FixedTableCell>
        <Typography variant="h6">{sum?.amount?.toFixed(2) ?? 0}</Typography>
      </FixedTableCell>
      <FixedTableCell>
        <Typography variant="h6">
          {sum?.collectedFees?.toFixed(2) ?? 0}
        </Typography>
      </FixedTableCell>
      <FixedTableCell>
        <Typography variant="h6">
          {sum?.returningDueFees?.toFixed(2) ?? 0}
        </Typography>
      </FixedTableCell>
      <FixedTableCell>
        <Typography variant="h6">
          {sum?.deliveredAmount?.toFixed(2) ?? 0}
        </Typography>
      </FixedTableCell>
      <FixedTableCell>
        <Typography variant="h6">
          {(sum?.customerDueCredit - sum?.customerDueDebit)?.toFixed(2) ?? 0}
        </Typography>
      </FixedTableCell>

      <FixedTableCell className={classes.shipmentTable_bodyStatus} colSpan={2}>
        {" "}
      </FixedTableCell>
    </TableRow>
  );

  return (
    <Root>
      <Drawer
        className={clsx(classes.drawer)}
        variant="persistent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor={filterAnchor}
        open={drawerState[filterAnchor]}
        onClose={() => false}
        dir={theme.direction}
      >
        <HFWraper />
        <Toolbar
          variant="dense"
          className={classes.toolbar}
          sx={{ marginBottom: 1 }}
        >
          <Typography
            className={classes.titles}
            color="inherit"
            variant="h6"
            component="div"
          >
            {t("search")}
          </Typography>
          <IconButton
            aria-label="done"
            size="small"
            onClick={toggleDrawer(filterAnchor, false)}
            color={"primary"}
          >
            <Clear />
          </IconButton>
        </Toolbar>

        <FiltersForm
          {...{ onSubmitFunc, resetPage, rowsPerPage, loading: tableLoad }}
          queryVariables={(variables) =>
            setQueryVariables({
              statusCode: ["DEX", "RITS", "HTR", "RTS"],
              ...variables,
              inWarehouse: true,
            })
          }
          statusDataHandler={statusDataHandler}
          dateValue={(date) => setDateValue(date)}
          initData={FILTER_INIT_DATE}
          pathname="shipments-in-warehouse"
          filtersAllowed={[
            "date",
            "dlvAtemp",
            "status",
            "warehouseSection",
            "branch",
            "originBranch",
            "businessType",
            "custm",
            "dlvAgent",
            "zone",
            "subZone",
            "refNumber",
            "service",
            "deliveryType",
            "returnType",
            "packageType",
            "paymentType",
          ]}
          clearCheckBox={clearCheckBox}
        />
      </Drawer>

      <Grid
        container
        justifyContent="center"
        spacing={3}
        className={clsx(classes.content, classes.mainSearch, {
          [classes.contentShift]: drawerState[filterAnchor],
        })}
      >
        {/* *******Table******* */}

        <Toolbar className={classes.toolbar} variant="dense">
          <Typography variant="h6">{t("shipmentsInWarehouse")}</Typography>
          <Box>
            <IconButton onClick={toggleDrawer(filterAnchor, true)} size="large">
              <FilterList fontSize="inherit" />
            </IconButton>
            <ExportShipments
              disabled={checkedIds.length > 0}
              filters={excelFilters}
            />

            <Can showException permission="shipping.shipment.print">
              <PrintComponent shipmentsId={checkedIds} />
            </Can>
          </Box>
        </Toolbar>
        {queryVariables && (
          <SearchTable
            {...props}
            onPageChange={handleChangePage}
            onChangeRows={handleChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            queryVariables={queryVariablesWithInitValue}
            loading={(param) => setTableLoad(param)}
            sum={sumShipments}
            tableHeadCell={brifeCells}
            tableBody={tableBody}
            className={classes}
            queryFields={queryFields}
            setCheckedIds={(ids) => setCheckedIds(ids)}
            checkedIds={checkedIds}
            withCheckAll
          />
        )}
      </Grid>
    </Root>
  );
};

export default ShipmentsInWarehouse;
