import React, { Fragment } from "react";
import { styled } from '@mui/material/styles';
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { SecuredNavLink } from "../HOC/CustomComponents/Secured";
import {
  Typography,
  Paper,
  Toolbar,
  IconButton,
  Collapse,
  Button,
  Icon,
} from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { gql, useMutation, useQuery } from "@apollo/client";
import { KeyValuePair } from "../HOC/CustomComponents/KeyValuePair";
import { DELETE_ROLE, ROLE_ID } from "./Graphql";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import PermissionSection from "./PermissionSection";
import { useSnackbar } from "notistack";
import CustomDialog from "../HOC/CustomComponents/CustomDialog";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import { Globals } from "../HOC/Classes/Globals";
import Grid from "@mui/material/Unstable_Grid2";

const PREFIX = 'RoleView';

const classes = {
  loading: `${PREFIX}-loading`,
  paper: `${PREFIX}-paper`,
  mainTracking: `${PREFIX}-mainTracking`,
  toolbar: `${PREFIX}-toolbar`,
  toolbarIcons: `${PREFIX}-toolbarIcons`,
  field: `${PREFIX}-field`
};

const StyledGrid = styled(Grid)((
  {
    theme
  }
) => ({
  [`& .${classes.loading}`]: {},

  [`& .${classes.paper}`]: {
    padding: theme.spacing(3),
    flexGrow: 1,
  },

  [`&.${classes.mainTracking}`]: {
    margin: "0",
    width: "100%",
  },

  [`& .${classes.toolbar}`]: {
    borderBottom: "1px #ccd1d6 solid",
    backgroundColor: "#f5f7f9",
    width: "100%",
  },

  [`& .${classes.toolbarIcons}`]: {
    flex: "1 1 100%",
    textAlign: "end",
  },

  [`& .${classes.field}`]: {
    width: 260,
    [theme.breakpoints.down("sm")]: {
      width: 170,
    },
  }
}));

const RoleView = (props) => {

  const { t } = useTranslation();
  const roleId = props.match.params.id?.trim();
  const { enqueueSnackbar } = useSnackbar();
  const [openDialog, setOpenDialog] = React.useState(false);
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const { loading, data } = useQuery(
    gql`
      ${ROLE_ID.query}
    `,
    {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,

      variables: { id: parseInt(roleId) },
    }
  );

  const [deleteRoleMutation, { loading: deleteLoad }] = useMutation(
    gql`
      ${DELETE_ROLE.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
    }
  );

  const deleteRole = () => {
    deleteRoleMutation({
      variables: {
        id: parseInt(roleId),
      },
    })
      .then((data) => {
        handleCloseDialog();
        props.history.goBack();
        enqueueSnackbar(t("manifestDeleted"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      })
      .catch((error) => {
        enqueueSnackbar(error.message, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      });
  };
  const permission = data?.role?.permissions;
  const id = data?.role?.id;

  return (
    <StyledGrid
      container
      spacing={3}
      justifyContent="center"
      className={classes.mainTracking}
    >
      <CustomDialog
        open={openDialog}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth="sm"
        title={t("deleteRole")}
        content={t("deleteRoleConfirmation")}
        actions={
          <>
            <Button color="primary" onClick={handleCloseDialog}>
              {t("cancel")}
            </Button>
            <Button color="primary" onClick={deleteRole}>
              {deleteLoad ? <ButtonLoading /> : t("confirm")}
            </Button>
          </>
        }
      />
      <Fragment>
        <Toolbar className={clsx(classes.toolbar)} variant="regular">
          <Grid
            container
            sx={{ flexWrap: "initial", flexGrow: 1 }}
            alignItems="center"
          >
            <Grid className={classes.field}>
              <Typography variant="h6">{t(`role`)}</Typography>
            </Grid>
            <Grid className={classes.toolbarIcons}>
              {data?.role &&
                (Globals.user.isSuper || data.role.code !== "ADMN") && (
                  <SecuredNavLink
                    permission="core.role.update"
                    to={{
                      pathname: `/admin/roles/${roleId}/edit`,
                    }}
                  >
                    <IconButton color={"primary"} size="large">
                      <Edit fontSize="inherit" />
                    </IconButton>
                  </SecuredNavLink>
                )}
              <SecuredNavLink
                permission="core.role.create"
                to={{
                  pathname: `/admin/roles/${roleId}/copy`,
                }}
              >
                <IconButton color={"primary"} size="large">
                  <Icon>content_copy</Icon>
                </IconButton>
              </SecuredNavLink>

              {data?.role.usersCount === 0 && (
                <IconButton
                  onClick={handleOpenDialog}
                  color={"primary"}
                  size="large"
                >
                  <Delete fontSize="inherit" />
                </IconButton>
              )}
            </Grid>
          </Grid>
        </Toolbar>
      </Fragment>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ margin: "0", width: "100%" }}
      >
        {loading ? (
          <FullScreenLoading minHeight="25%" />
        ) : (
          <>
            <Grid
              container
              justifyContent="center"
              sx={{ width: "100%", p: 2, gap: 2 }}
            >
              <Paper container className={classes.paper} component={Grid}>
                <KeyValuePair title={t("code")} value={data?.role?.code} />
                <KeyValuePair title={t("name")} value={data?.role?.name} />
              </Paper>
            </Grid>

            <Grid container justifyContent="center" className={classes.spacing}>
              <PermissionSection permission={permission} id={id} />
            </Grid>
          </>
        )}
      </Grid>
    </StyledGrid>
  );
};

export default RoleView;
