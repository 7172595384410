import React, { Fragment, useEffect } from "react";
import { gql, useQuery } from "@apollo/client";
import * as gqlb from "gql-query-builder";
import { withRouter } from "react-router";
import clsx from "clsx";

import { urlParameters } from "../HOC/CustomFunctions/urlParameters";
import EmptyTableMessage from "../HOC/FunctionComponents/EmptyTableMessage";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import logo from "../../assets/Image/logo.png";
import { Box } from "@mui/material";

import QRCode from "react-qr-code";
import moment from "moment";

const PREFIX = "SHIPMENT_STICKY_PRINT";

export const classes = {
  flex: `${PREFIX}-flex`,
  flexBetween: `${PREFIX}-flexBetween`,
  customHeight16: `${PREFIX}-customHeight16`,
  customHeight5: `${PREFIX}-customHeight5`,
  customHeight6: `${PREFIX}-customHeight6`,
  customHeight13: `${PREFIX}-customHeight13`,
  customHeight12: `${PREFIX}-customHeight12`,
  customHeight8: `${PREFIX}-customHeight8`,
  printSticky: `${PREFIX}-printSticky`,
  stickyCode: `${PREFIX}-stickyCode`,
  line: `${PREFIX}-line`,
  lineTop: `${PREFIX}-lineTop`,
  tableData: `${PREFIX}-tableData`,
  reMarks: `${PREFIX}-reMarks`,
  width50: `${PREFIX}-width50`,
  width60: `${PREFIX}-width60`,
  borderRight: `${PREFIX}-borderRight`,
  padding2: `${PREFIX}-padding2`,
  hideSenderPhone: `${PREFIX}-hideSenderPhone`,
  minHeight: `${PREFIX}-minHeight`,
  borderLeft: `${PREFIX}-borderLeft`,
  rotate: `${PREFIX}-rotate`,
  borderTop: `${PREFIX}-borderTop`,
  borderBottom: `${PREFIX}-borderBottom`,
  bold: `${PREFIX}-bold`,
  imgDiv: `${PREFIX}-imgDiv`,
  logoImg: `${PREFIX}-logoImg`,
  logoWrapper: `${PREFIX}-logoWrapper`,
  paddingX: `${PREFIX}-paddingX`,
  tableDataZones: `${PREFIX}-tableDataZones`,
  capitalize: `${PREFIX}-capitalize`,
  uppercase: `${PREFIX}-uppercase`,
  qrWrapper: `${PREFIX}-qrWrapper`,
  overflowHidden: `${PREFIX}-overflowHidden`,
  rotateWrapper: `${PREFIX}-rotateWrapper`,
  contentWrapper: `${PREFIX}-contentWrapper`,
  rotateHeight38: `${PREFIX}-rotateHeight38`,
  rotateHeight24: `${PREFIX}-rotateHeight24`,
};
export const Root = styled("div")(({ theme }) => ({
  [`& .${classes.hideSenderPhone}`]: {
    display: "none !important",
  },
  [`& .${classes.printSticky}`]: {
    border: "1px solid #000",
    pageBreakAfter: "always",
    position: "relative",
    pageBreakInside: "avoid",
    width: "10cm",
    height: "15cm",
    overflow: "hidden",
    fontSize: 11,
    color: "black",
    boxSizing: "border-box",
  },
  [`& .${classes.imgDiv}`]: {
    padding: theme.spacing(0.5, 0, 0, 0)
  },
  [`& .${classes.line}`]: {
    borderBottom: "1px solid #000",
  },
  [`& .${classes.lineTop}`]: {
    borderTop: "1px solid #000",
  },
  [`& .${classes.borderRight}`]: {
    borderRight: "1px solid #000",
  },
  [`& .${classes.borderLeft}`]: {
    borderLeft: "1px solid #000",
  },
  [`& .${classes.flexBetween}`]: {
    display: 'flex',
    justifyContent: "space-between",
    alignItems: "center",
  },
  [`& .${classes.flex}`]: {
    display: 'flex',
    justifyContent: "center",
    alignItems: "center",
  },
  [`& .${classes.width60}`]: {
    width: "60%"
  },
  [`& .${classes.stickyCode}`]: {
    fontFamily: "'Libre Barcode 39 Text'",
    // height: "100px",
    overflow: "hidden",
    fontSize: 50,
  },
  [`& .${classes.tableData}`]: {
    width: "calc(100% * (1/3))",
    display: "flex",
    flexDirection: "column",
    alignItems: 'center',
    textAlign: "center",
    padding: "6px 0",
    height: "100%",
    fontSize: "13px",
    justifyContent: 'space-between'
  },
  [`& .${classes.tableDataZones}`]: {
    width: "calc(100% * (1/2))",
    display: "flex",
    flexDirection: "column",
    alignItems: 'center',
    textAlign: "center",
    padding: "6px 0",
    height: "100%",
    fontSize: "13px",
    justifyContent: 'space-between'
  },
  [`& .${classes.reMarks}`]: {
    display: "flex",
    flexDirection: "column"
  },
  [`& .${classes.padding2}`]: {
    padding: "2px 0"
  },
  [`& .${classes.customHeight16}`]: {
    height: "16%",
  },
  [`& .${classes.customHeight12}`]: {
    height: "12%"
  },
  [`& .${classes.customHeight5}`]: {
    height: "5%"
  },
  [`& .${classes.customHeight6}`]: {
    height: "6%"
  },
  [`& .${classes.customHeight13}`]: {
    height: "13%"
  },
  [`& .${classes.customHeight8}`]: {
    height: "8%"
  },
  [`& .${classes.contentWrapper}`]: {
    padding: "2px 8px",
    width: "92%",
    height: "100%"
  },
  [`& .${classes.rotateWrapper}`]: {
    width: "8%",
    height: "100%",
    display: "flex"
  },
  [`& .${classes.rotate}`]: {
    rotate: "-90deg",
    fontSize: "16px",
    margin: "auto"
  },
  [`& .${classes.rotateHeight38}`]: {
    height: "38%"
  },
  [`& .${classes.rotateHeight24}`]: {
    height: "24%"
  },
  [`& .${classes.borderBottom}`]: {
    borderBottom: "1px solid #000",
  },
  [`& .${classes.borderTop}`]: {
    borderTop: "1px solid #000",
  },
  [`& .${classes.bold}`]: {
    fontWeight: 700,
  },
  [`& .${classes.logoImg}`]: {
    maxHeight: "75%",
    maxWidth: "fit-content"
  },
  [`& .${classes.logoWrapper}`]: {
    paddingTop: theme.spacing(1),
    height: "100%",
  },
  [`& .${classes.paddingX}`]: {
    padding: theme.spacing(0, 1),
  },
  [`& .${classes.capitalize}`]: {
    textTransform: "capitalize"
  },
  [`& .${classes.uppercase}`]: {
    textTransform: "uppercase"
  },
  [`& .${classes.qrWrapper}`]: {
    height: "100%",
    width: "25%",
    padding: theme.spacing(1),
  },
  [`& .${classes.overflowHidden}`]: {
    overflow: "hidden"
  },
}));
const organizationQuery = {
  operation: "organization",
  fields: ["reportHeader"],
  variables: {},
};
const listShipmentsFields = [
  {
    data: [
      "code",
      "recipientName",
      "recipientAddress",
      "recipientMobile",
      "recipientPhone",
      "description",
      "weight",
      "piecesCount",
      "totalAmount",
      "senderName",
      "senderPhone",
      "senderMobile",
      "refNumber",
      "notes",
      "date",
      {
        type: ["name", "code"],
      },
      {
        originBranch: ["name"],
      },
      {
        customer: ["code", "hideWaybillMobile"],
      },
      {
        openable: ["code"],
      },

      {
        recipientZone: ["name"],
      },
      {
        recipientSubzone: ["name"],
      },
      {
        senderZone: ["name"],
      },
      {
        senderSubzone: ["name"],
      },
    ],
  },
];
const barcodeBatch = [
  "id",
  { barcodes: ["id", "code"] },
  {
    customer: [
      "code",
      "name",
      "hideWaybillMobile",
      {
        zone: ["id", "code", "name"]
      },
      {
        subzone: ["id", "code", "name"]
      },
      "phone",
      "mobile"
    ],
  },
];
const queryBuilder = (ids) => {
  const queryNumber = Math.ceil(ids.length / 100);
  const queryArray = [organizationQuery];
  for (let index = 0; index < queryNumber; index++) {
    const id = ids.slice(100 * index, 100 * (index + 1));
    const initialQuery = {
      operation: `list${index}:listShipments(first:100,input:{id:[${id}]})`,
      fields: listShipmentsFields,
      variables: {},
    };
    queryArray.push(initialQuery);
  }
  return queryArray;
};
const queryBuilderCode = (ids) => {
  const queryArray = [organizationQuery];
  const initialQuery = {
    operation: `listCode:barcodeBatch(id:${ids[0]})`,
    fields: barcodeBatch,
    variables: {},
  };
  queryArray.push(initialQuery);
  return queryArray;
};
export const SHIPPING_SETTING = gqlb.query({
  operation: "shippingSettings",
  fields: [{ localCurrency: ["name"] }],
  variables: {},
});

const dateFormat = (date) => moment(date).locale("en").format("YYYY-MM-DD HH:mm");

const ShipmentStickyPrint = (props) => {
  const id = props.match.params.id.split(",").map(Number);
  const isBatch = props.location.pathname.startsWith('/batch');
  const urlQuery = urlParameters(window.location.search);

  const { t, i18n } = useTranslation();

  const queryBody = isBatch ? queryBuilderCode(id) : queryBuilder(id);
  // const [hideSenderPhone, setHideSenderPhone] = useState(true);

  const SHIPMENT_QUERY = gqlb.query(queryBody);

  const token = urlQuery["token"];
  const lang = urlQuery["lang"];

  const { data: settings, loading: settingsLoad } = useQuery(
    gql`
      ${SHIPPING_SETTING.query}
    `,
    {
      nextFetchPolicy: "no-cache",
      fetchPolicy: "no-cache",
      ...(token && {
        context: {
          headers: {
            authorization: `Bearer ${token}`,
            ...(lang && { "Content-Language": lang }),
          },
        },
      }),
    }
  );

  useEffect(() => {
    if (lang) {
      i18n.changeLanguage(lang);
    }
    return () => { };
  }, [i18n, lang, settings]);

  const { data, loading } = useQuery(
    gql`
      ${SHIPMENT_QUERY.query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      skip: !id,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: {
        input: {
          id,
        },
      },
      ...(token && {
        context: {
          headers: {
            authorization: `Bearer ${token}`,
            ...(lang && { "Content-Language": lang }),
          },
        },
      }),
      onCompleted: () => {
        document.fonts.onloadingdone = function (fontFaceSetEvent) {
          window.print();
          document.fonts.onloadingdone = null;
        };
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  const currency = ` ${settings?.shippingSettings?.localCurrency?.name} ` ?? "";
  const reportHeader = data?.organization?.reportHeader?.split("\n")
  const footer = reportHeader?.toString().replace(/,/g, " | ");

  let parsedData = [];
  if (data?.list0) {
    let concatData = [];
    for (const key in data) {
      if (key.startsWith("list")) {
        const listData = data[key].data;
        concatData = concatData.concat(listData);
      }
    }
    parsedData = concatData;
  }
  let customer

  if (data?.listCode) {
    customer = data?.listCode.customer
    let concatData = [...data?.listCode.barcodes];
    parsedData = concatData;
  }


  const validData = parsedData.length > 0;

  const getSenderMobiles = (shipment) => {
    return customer ? customer?.mobile && customer?.phone
      ? `${customer?.mobile} - ${customer?.phone}`
      : customer?.mobile ??
      customer?.phone :
      shipment?.senderMobile && shipment?.senderPhone
        ? `${shipment?.senderMobile} - ${shipment?.senderPhone}`
        : shipment?.senderMobile ??
        shipment?.senderPhone
  }

  return (
    <Fragment>
      <link
        rel="stylesheet"
        type="text/css"
        href={process.env.PUBLIC_URL + "/print-sticky10X15.css"}
      />
      {loading || (!loading && !validData) || settingsLoad ? (
        <>
          <EmptyTableMessage
            loading={loading}
            message={t("noShipmentWithCode")}
          />

          {/* <FullScreenLoading minHeight="25%" /> */}
        </>
      ) : (
        parsedData.map((shipment, index) => {
          const rtsShipment = shipment?.type?.code === "RTS";
          return (
            <Root key={index}>
              <div
                className={clsx({
                  [classes.printSticky]: !loading && validData,
                })}
                style={{ lineHeight: "1.2" }}
              >
                <div className={clsx(
                  classes.flexBetween,
                  classes.line,
                  classes.customHeight16,
                  classes.paddingX
                )}>
                  <div className={clsx(classes.bold, classes.width60, classes.logoWrapper, "flex flex-column")}>
                    <img src={logo} alt="logo" className={classes.logoImg} />
                    <div className={clsx(classes.imgDiv, classes.capitalize)}>
                      {t("printDate")} : {dateFormat(shipment?.date)}
                    </div>
                  </div>
                  <div className={clsx(classes.flex, classes.qrWrapper)}>
                    <QRCode value={shipment.code} style={{ height: "100%" }} />
                  </div>
                </div>

                <div className={clsx(
                  classes.flex,
                  classes.line,
                  classes.customHeight8,
                  classes.bold
                )}>
                  <div className={clsx(classes.tableDataZones, classes.borderRight)}>
                    <Box component="span" className={classes.capitalize}>
                      {t("origin")}
                    </Box>
                    <div>
                      <Box component="span">
                        {!isBatch && (shipment?.senderZone?.name)}
                      </Box>
                    </div>
                  </div>
                  <div className={clsx(classes.tableDataZones)}>
                    <Box component="span" className={classes.capitalize}>
                      {t("destination")}
                    </Box>
                    <div>
                      {!isBatch && (shipment?.recipientZone?.name)}
                    </div>
                  </div>
                </div>

                <div className={clsx(
                  classes.line,
                  classes.flex,
                  classes.customHeight12,
                  classes.overflowHidden,
                  classes.bold
                )}>
                  <div className={clsx(classes.rotateWrapper, classes.borderRight)}>
                    <div className={clsx(classes.rotate, classes.capitalize, classes.rotateHeight38)}>{t("from")}</div>
                  </div>
                  <div className={classes.contentWrapper}>
                    <div className={classes.padding2}>{customer ? customer.name : shipment?.senderName}</div>
                    <div
                      className={clsx(classes.padding2, {
                        [classes.hideSenderPhone]: customer ? customer.hideWaybillMobile : shipment?.customer?.hideWaybillMobile,
                      }
                      )}
                    >
                      {getSenderMobiles(shipment)}
                    </div>
                    <div
                      className={clsx(classes.padding2
                      )}
                    >
                      {isBatch ? customer ? (customer?.zone?.name +
                        " - " +
                        customer?.subzone?.name) : "" : (shipment?.senderZone?.name +
                          " - " +
                          shipment?.senderSubzone?.name)}
                    </div>
                  </div>
                </div>
                <div className={clsx(
                  classes.line,
                  classes.flex,
                  classes.customHeight16,
                  classes.overflowHidden,
                  classes.bold
                )}>
                  <div className={clsx(classes.rotateWrapper, classes.borderRight)}>
                    <div className={clsx(classes.rotate, classes.capitalize, classes.rotateHeight24)}>{t("to")}</div>
                  </div>
                  <div className={classes.contentWrapper}>
                    <div className={classes.padding2}>{shipment?.recipientName}</div>
                    <div
                      className={clsx(classes.padding2)}
                    >
                      {isBatch ? ' ' : (shipment?.recipientMobile &&
                        shipment?.recipientPhone
                        ? `${shipment?.recipientMobile} - ${shipment?.recipientPhone}`
                        : shipment?.recipientMobile ??
                        shipment?.recipientPhone)}
                    </div>
                    <div
                      className={clsx(classes.padding2
                      )}
                    >
                      {!isBatch && (shipment?.recipientZone?.name +
                        " - " +
                        shipment?.recipientSubzone?.name)}
                    </div>
                    <div
                      className={clsx(classes.padding2)}
                    >
                      {shipment?.recipientAddress}
                    </div>
                  </div>
                </div>

                <div
                  className={clsx(
                    classes.barcode,
                    classes.flex,
                    classes.stickyCode,
                    classes.line,
                    classes.customHeight13,
                  )}
                >
                  *{shipment.code}*
                </div>
                <div className={clsx(
                  classes.flex,
                  classes.line,
                  classes.customHeight8,
                  classes.bold
                )}>
                  <div className={clsx(classes.tableData, classes.borderRight)}>
                    <Box component="span" className={classes.uppercase}>
                      {t("cod")}
                    </Box>
                    <div>
                      <Box component="span">
                        {rtsShipment ? 0 : shipment?.totalAmount}
                      </Box>
                      <Box component="span" pl="2px">
                        {!isBatch && currency}
                      </Box>
                    </div>
                  </div>
                  <div className={clsx(classes.tableData, classes.borderRight)}>
                    <Box component="span" className={classes.capitalize}>
                      {t("pieces")}
                    </Box>
                    <div>{shipment?.piecesCount}</div>
                  </div>
                  <div className={clsx(classes.tableData)}>
                    <Box component="span" className={classes.capitalize}>
                      {t("weight")}
                    </Box>
                    <div>{shipment?.weight}</div>
                  </div>
                  {/* <div className={clsx(classes.tableData)}>
                    <Box component="span">
                      {t("open")}
                    </Box>
                    <div>
                      {!isBatch && (shipment?.openable?.code === "Y" ? (<MdOutlineDone />) : (<AiOutlineClose />))}
                    </div>
                  </div> */}
                </div>
                <div className={clsx(
                  classes.line,
                  classes.customHeight5,
                  classes.bold,
                  classes.paddingX,
                  "pt-1"
                )}>
                  <div className={classes.capitalize}>{t("refNumber")}  {shipment?.refNumber && " : " + shipment?.refNumber}</div>
                </div>
                <div className={clsx(
                  classes.reMarks,
                  classes.customHeight16,
                  classes.bold,
                  classes.paddingX,
                  classes.capitalize,
                  "py-2"
                )}>
                  <Box
                    component="span"
                  >
                    {t("notes")}
                  </Box>
                  <Box component="span" pt="1px">
                    {shipment?.notes}
                  </Box>
                </div>
                <Box component="div" className={clsx(
                  classes.lineTop,
                  classes.customHeight6,
                  classes.bold,
                  classes.flex,
                  "py-1"
                )}
                  sx={{ display: "flex", justifyContent: 'center', position: "absolute", bottom: 0, width: "100%" }}>
                  <Box component="span" pl="2px">
                    {footer}
                  </Box>
                </Box>
              </div>
            </Root>
          )
        })
      )}
    </Fragment>
  );
};

export default withRouter(ShipmentStickyPrint);
