import React, { useEffect, useState } from "react";
import {
  Button,
  Collapse,
  FormControlLabel,
  Grid,
  Switch,
} from "@mui/material";
import { SAVE_JOURNAL_TYPE } from "./Graphql";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { useMutation, gql } from "@apollo/client";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import { useSnackbar } from "notistack";
import { DialogActions } from "@mui/material";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import CustomDialog from "../HOC/CustomComponents/CustomDialog";
import { LIST_SEQUENCES_DROPDOWN } from "../../GlobalsQuery/ListDropdown/ListDropdown";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import ListBranches from "../HOC/ComponentWithSpecificQuery/ListBranches";
import LookupDropdown from "../HOC/CustomComponents/LookupDropdown";
import { lookupCodeIsValid } from "../HOC/CustomFunctions/lookupCodeIsValid";

export default function JournalTypeForm(props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { handleSubmit, control, formState, setValue, setError } = useForm();
  const { errors } = formState;
  const [activeUser, setActiveUser] = useState(true);
  const [autocompleteValues, setAutocompleteValues] = useState({
    branch: null,
    sequence: null,
  });
  const [saveJournalType, { loading: saveJournalTypeLoading }] = useMutation(
    gql`
      ${SAVE_JOURNAL_TYPE.query}
    `
  );

  useEffect(() => {
    if (props.journalTypeObject?.id) {
      const journalTypeParams = ["id", "code", "name"];
      journalTypeParams.forEach((i) => {
        props.journalTypeObject[i] && setValue(i, props.journalTypeObject[i]);
      });
      setActiveUser(props.journalTypeObject["active"]);
      setAutocompleteValues({
        ...(props?.journalTypeObject?.branch && {
          branch: props?.journalTypeObject?.branch,
        }),
        sequence: props?.journalTypeObject?.sequence,
      });
    }
    return () => {};
  }, []);

  const handelActivate = (e) => {
    const active = e.target.checked;
    setActiveUser(active);
  };
  const lookupsComplete = (data, updateValue, name) => {
    if (updateValue && lookupCodeIsValid(data, updateValue)) {
      setValue(name, updateValue);
    }
  };

  const onSubmit = (data) => {
    for (const key in data) {
      if (data[key] === "") {
        data[key] = null;
      }
    }
    saveJournalType({
      variables: {
        input: {
          active: activeUser,
          ...data,
        },
      },
    })
      .then((data) => {
        props.handleClose();
        props.refetch();
        enqueueSnackbar(t("saveSuccessful"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      })
      .catch(({ graphQLErrors }) => {
        setValidationError(graphQLErrors, setError);
      });
  };

  const updateId = props.journalTypeObject?.id;

  const body = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ControlMUItextField
        control={control}
        errors={errors}
        name={"code"}
        label={t("code")}
        margin="normal"
      />

      <ControlMUItextField
        control={control}
        errors={errors}
        name={"name"}
        label={t("name")}
        rules={{ required: t("fieldIsRequired") }}
        margin="normal"
      />

      <ListBranches
        control={control}
        errors={errors}
        name={"branchId"}
        label={t("branch")}
        margin="normal"
        defaultValue={autocompleteValues.branch ?? {}}
        disabled={Boolean(updateId)}
        skipDefaultBranch={Boolean(updateId)}
      />
      <CustomAutocomplete
        control={control}
        errors={errors}
        label={t("sequence")}
        name={"sequenceId"}
        rules={{ required: t("fieldIsRequired") }}
        parseData={(data) => data}
        query={LIST_SEQUENCES_DROPDOWN.query}
        margin="normal"
        defaultValue={autocompleteValues.sequence}
        disabled={Boolean(updateId)}
      />

      <LookupDropdown
        control={control}
        errors={errors}
        name={"referenceCode"}
        label={t("reference")}
        margin="normal"
        rules={{ required: t("fieldIsRequired") }}
        variables={{ input: { code: "GL_VOURCHERS_REFERNCE" } }}
        onCompleted={(data) =>
          lookupsComplete(
            data,
            props.journalTypeObject?.reference?.code,
            "referenceCode"
          )
        }
        disabled={Boolean(updateId)}
      />

      <DialogActions>
        <Button color="primary" disabled={saveJournalTypeLoading} type="submit">
          {saveJournalTypeLoading ? <ButtonLoading /> : t("save")}
        </Button>
        <Button
          color="primary"
          disabled={saveJournalTypeLoading}
          onClick={props.handleClose}
        >
          {t("cancel")}
        </Button>
      </DialogActions>
    </form>
  );

  return (
    <div>
      <CustomDialog
        open={props.open}
        onClose={props.handleClose}
        title={
          <Grid container justifyContent="space-between">
            {props.journalTypeObject?.id
              ? t("updateJournalType")
              : t("createNewJournalType")}
            <FormControlLabel
              checked={activeUser}
              control={<Switch color="primary" />}
              label={t("active")}
              labelPlacement="start"
              onChange={handelActivate}
            />
          </Grid>
        }
        content={body}
      />
    </div>
  );
}
