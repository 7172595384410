import { InputBase, Button, Grid } from "@mui/material";
import { styled } from '@mui/material/styles';
import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";
import background from "../images/news/bg.jpg";
import "./style.css";
import { FaHashtag } from "react-icons/fa";
import { withRouter } from "react-router";
import { pushUrl } from "../../HOC/CustomFunctions/pushUrl";

const PREFIX = 'index';

const classes = {
  button: `${PREFIX}-button`,
  track: `${PREFIX}-track`,
  paper: `${PREFIX}-paper`,
  dialogContent: `${PREFIX}-dialogContent`,
  bottomSection: `${PREFIX}-bottomSection`,
  form: `${PREFIX}-form`,
  trackButtonContainer: `${PREFIX}-trackButtonContainer`,
  outline: `${PREFIX}-outline`,
  inputBase: `${PREFIX}-inputBase`,
  item: `${PREFIX}-item`
};

const Root = styled('section')((
  {
    theme
  }
) => ({
  [`& .${classes.button}`]: {
    background: theme.palette.primary.main,
    color: "#fff",
    border: 0,
    outline: 0,
    position: "absolute",
    width: "200px",
    height: "50px",
    transition: "all .3s",
  },

  [`&.${classes.track}`]: {
    padding: "60px 0 70px",
    position: "relative",
    "&::before": {
      content: "''",
      width: "100%",
      height: "100%",
      top: 0,
      zIndex: "-1",
      position: "absolute",
      background: `url(${background}) no-repeat center center / cover`,
      ...(theme.direction === "rtl" && { transform: "scaleX(-1)" }),
    },
  },

  [`& .${classes.paper}`]: {
    backgroundColor: theme.palette.background.default,
  },

  [`& .${classes.dialogContent}`]: {
    padding: theme.spacing(1),
    minHeight: 200,
    display: "flex",
    alignItems: "center",
  },

  [`& .${classes.bottomSection}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  [`& .${classes.form}`]: {
    padding: theme.spacing(0.5),
    margin: "auto",
    justifyContent: "space-between",
    [theme.breakpoints.up("sm")]: {
      backgroundColor: theme.palette.common.white,
      borderRadius: "50rem!important",
    },

    "& button": {
      padding: theme.spacing(1.5, 0),
      minWidth: theme.spacing(25),
      fontWeight: 600,
      [theme.breakpoints.up("sm")]: {
        borderRadius: "50rem!important",
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(2),
        minWidth: "100%",
      },
    },
  },

  [`& .${classes.trackButtonContainer}`]: {
    [theme.breakpoints.down('sm')]: {
      minWidth: "100%",
    },
  },
  [`& .${classes.item}`]: {
    [theme.breakpoints.down('sm')]: {
      width: "100%",
    },
  },

  [`& .${classes.outline}`]: {
    color: theme.palette.text.secondary,
    fontSize: 20,
  },

  [`& .${classes.inputBase}`]: {
    display: "flex",
    alignItems: "center",

    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      width: "100%",
    },
    "& .MuiInputBase-root": {
      [theme.breakpoints.down('sm')]: {
        borderBottom: "1px solid " + theme.palette.divider,
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.common.white,
        padding: theme.spacing(0, 2),
      },
    },
    "& .MuiInputBase-input": {
      fontSize: 17,
      marginLeft: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        minHeight: theme.spacing(5),
      },
    },
  }
}));

const TrackArea = (props) => {
  const { t } = useTranslation("home");

  const [TrackCode, setTrackCode] = React.useState(null);

  const onTrack = (e) => {
    e.preventDefault();
    TrackCode && pushUrl(props, `/track/${TrackCode}`);
  };



  return (
    <Root id="track" className={clsx("wpo-track-section", classes.track)}>
      <div className="container">
        <Grid container>
          <Grid item className={clsx(classes.item)}>
            <div className="track">
              <h3>{t("trackShipmentTitle")}</h3>
              <div className="wpo-tracking-form">
                <Grid container justifyContent="flex-start" alignItems="center">
                  <Grid item xs={12} className={classes.bottomSection}>
                    <Grid
                      component="form"
                      onSubmit={onTrack}
                      container
                      alignItems="center"
                      className={classes.form}
                      autoComplete="off"
                    >
                      <Grid item className={classes.inputBase}>
                        <InputBase
                          fullWidth
                          label={t("home:shipmentTrackingCode")}
                          name="trackCode"
                          inputProps={{ "aria-label": "naked" }}
                          startAdornment={
                            <FaHashtag className={classes.outline} />
                          }
                          onChange={(e) => {
                            setTrackCode(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item className={classes.trackButtonContainer}>
                        <Button
                          fullWidth
                          size="large"
                          variant="contained"
                          color="primary"
                          type="submit"
                        >
                          {t("home:trackTheShipment")}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </Root>
  );
};

export default withRouter(TrackArea);
