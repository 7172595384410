import React, { memo } from "react";
import { styled } from '@mui/material/styles';
import { TableCell } from "@mui/material";
import { useTranslation } from "react-i18next";

const PREFIX = 'FixedTableCell';

const classes = {
  cellWidth: `${PREFIX}-cellWidth`
};

const StyledTableCell = styled(TableCell)((
  {
    theme
  }
) => ({
  [`& .${classes.cellWidth}`]: {
    whiteSpace: "normal",
    maxWidth: "200px",
    inlineSize: "max-content",
  }
}));

export const FixedTableCell = memo((props) => {

  const { allowPlaceholder = true, ...restProps } = props;
  const { t } = useTranslation();
  return (
    <StyledTableCell {...restProps}>
      <div className={classes.cellWidth}>
        {props.children ?? (allowPlaceholder && t("placeholder"))}
      </div>
    </StyledTableCell>
  );
});
