import React, { useEffect, useState } from "react";

import { styled } from "@mui/material/styles";

import { gql, useQuery } from "@apollo/client";
import { Add, Clear, FilterList, Search } from "@mui/icons-material";
import {
  Button,
  Divider,
  Drawer,
  Grid,
  Icon,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import clsx from "clsx";
import moment from "moment";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import {
  LIST_BRANCHES_DROPDOWN,
  LIST_CUSTOMERS_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import useWidth from "../../Hooks/useWidth";
import CellLink from "../HOC/CustomComponents/CellLink";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import { SecuredNavLink } from "../HOC/CustomComponents/Secured";
import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";
import { windowUrl } from "../HOC/CustomFunctions/pushUrl";
import { urlParameters } from "../HOC/CustomFunctions/urlParameters";
import EmptyTableMessage from "../HOC/FunctionComponents/EmptyTableMessage";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import { LIST_BARCODE_BATCH } from "./Graphql";
import MUIDateRangeCustom from "../HOC/MUI/MUIDateRangeCustom";
import MUItextField from "../HOC/MUI/MUItextField";
import MUIselect from "../HOC/MUI/MUIselect";


const PREFIX = "GenrateBarcodesList";

const classes = {
  button: `${PREFIX}-button`,
  iconColor: `${PREFIX}-iconColor`,
  btnMargin: `${PREFIX}-btnMargin`,
  mainSearch: `${PREFIX}-mainSearch`,
  titles: `${PREFIX}-titles`,
  toolbar: `${PREFIX}-toolbar`,
  searchForm: `${PREFIX}-searchForm`,
  searchField: `${PREFIX}-searchField`,
  overlay: `${PREFIX}-overlay`,
  searchPadding: `${PREFIX}-searchPadding`,
  drawer: `${PREFIX}-drawer`,
  drawerPaper: `${PREFIX}-drawerPaper`,
  drawerHeader: `${PREFIX}-drawerHeader`,
  content: `${PREFIX}-content`,
  contentShift: `${PREFIX}-contentShift`,
  tableRow: `${PREFIX}-tableRow`,
  shipmentTable_shipmentStatus: `${PREFIX}-shipmentTable_shipmentStatus`,
  shipmentTable_shipmentCode: `shipmentTable_shipmentCode`,
  tablePaper: `${PREFIX}-tablePaper`,
  table_status: `${PREFIX}-table_status`,
  table_code: `${PREFIX}-table_code`,
  toolbarHeader: `${PREFIX}-toolbarHeader`,
  tableBodyRow: `${PREFIX}-tableBodyRow`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.button}`]: {
    position: "sticky",
    bottom: 0,
    zIndex: 3,
    backgroundColor: "#fff",
    marginTop: 10,
    padding: 7,
  },

  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },

  [`& .${classes.btnMargin}`]: {
    width: "100%",
  },

  [`& .${classes.mainSearch}`]: {
    margin: theme.spacing(0),
    width: "100%",
  },

  [`& .${classes.titles}`]: {
    flex: "1 1 100%",
  },

  [`& .${classes.toolbar}`]: {
    borderBottom: "1px #ccd1d6 solid",
    backgroundColor: "#f5f7f9",
    width: "100%",
    // justifyContent: "space-between",
  },

  [`& .${classes.searchForm}`]: {
    overflowY: "auto",
    width: "100%",
    // height: "100%",
    margin: 0,
  },

  [`& .${classes.searchField}`]: {
    padding: theme.spacing(1, 1, 0, 1),
    position: "relative",
    overflow: "hidden",
  },

  [`& .${classes.overlay}`]: {
    width: "100%",
    height: "100%",
    position: "absolute",
    backgroundColor: "#ffffffa8",
    zIndex: 2,
  },

  [`& .${classes.searchPadding}`]: {
    padding: theme.spacing(1),
  },

  //////////////////////Drawer///////////////////

  [`& .${classes.drawer}`]: {
    width: drawerWidth,
    flexShrink: 0,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },

  [`& .${classes.drawerPaper}`]: {
    width: drawerWidth,

    zIndex: "250",
    height: "100%",
    left: "initial",
    right: "initial",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },

  [`& .${classes.drawerHeader}`]: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },

  [`& .${classes.content}`]: {
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
  },

  [`& .${classes.contentShift}`]: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: "auto",
    width: "calc(100% - 240px)",
    [theme.breakpoints.down("sm")]: {
      margin: "auto",
      width: "auto",
    },
  },

  [`& .${classes.tableRow}`]: {
    "&:hover": {
      "& .MuiTableCell-root": {
        backgroundColor: "#f5f5f5",
      },
    },
  },

  [`& .${classes.shipmentTable_shipmentStatus}`]: {
    [theme.breakpoints.up("sm")]: {
      zIndex: "100",
      backgroundColor: "#fff",
      position: "sticky",
      top: "0",
      right: "0",
    },
  },

  [`& .${classes.shipmentTable_shipmentCode}`]: {
    [theme.breakpoints.up("sm")]: {
      zIndex: "100",
      backgroundColor: "#fff",
      position: "sticky",
      top: "0",
      left: "0em",
    },
  },

  [`& .${classes.tablePaper}`]: {
    borderRadius: 0,
    width: "100%",
    display: "grid",
  },

  [`& .${classes.table_status}`]: {
    [theme.breakpoints.up("sm")]: {
      zIndex: "100",
      backgroundColor: "#fff",
      position: "sticky",
      top: "0",
      right: "0",
    },
  },

  [`& .${classes.table_code}`]: {
    [theme.breakpoints.up("sm")]: {
      zIndex: "100",
      backgroundColor: "#fff",
      position: "sticky",
      top: "0",
      left: "0",
    },
  },

  [`& .${classes.toolbarHeader}`]: {
    flex: 1,
  },

  [`& .${classes.tableBodyRow}`]: {
    "&:hover": {
      "& .MuiTableCell-root": {
        backgroundColor: "#f5f5f5",
      },
    },
  },
}));

const drawerWidth = 240;

const GenrateBarcodesList = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const urlQuery = urlParameters(window.location.search);

  const initFromDate = urlQuery["fromDate"]
    ? urlQuery["fromDate"]
    : null;
  const initToDate = urlQuery["toDate"]
    ? urlQuery["toDate"]
    : null;

  const validUrlParameters = Object.keys(urlQuery).length !== 0;
  const [dateRange, setDateRange] = useState([initFromDate, initToDate]);

  const [autocompleteValues, setAutocompleteValues] = useState({
    branch: null,
    customer: null,
  });

  const screenWidth = useWidth();
  const [drawerState, setDrawerState] = React.useState({
    top: true,
    left: screenWidth === "xs" ? false : true,
    bottom: screenWidth === "xs" ? false : true,
    right: screenWidth === "xs" ? false : true,
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    register,
    watch
  } = useForm();
  const fromDate = moment(dateRange[0]).locale("en").format("YYYY-MM-DD");
  const toDate = moment(dateRange[1]).locale("en").format("YYYY-MM-DD");
  const [rowsPerPage, setRowsPerPage] = useState(urlQuery["rowsPerPage"] ?? 20);
  const [page, setPage] = useState(0);

  const initSearch = () => {
    let searchParams = {
      page: page,
      refetch: true,
      ...(dateRange[0] && { fromDate }),
      ...(dateRange[1] && { toDate }),
    };
    delete urlQuery["rowsPerPage"];
    if (validUrlParameters) {
      if (urlQuery["barcode"]) {
        urlQuery["barcode"] = urlQuery["barcode"].toString().trim();
      }
      searchParams = urlQuery;
      if (searchParams["barcode"])
        searchParams["barcode"] = searchParams["barcode"]
          .split(",")
          .filter((i) => i.trim() !== "")
          .map((i) => i.trim());
    }
    return {
      ...searchParams,
    };
  };
  const [search, setSearch] = useState(initSearch());

  useEffect(() => {
    urlQuery["barcode"] && setValue("barcode", urlQuery["barcode"].toString());
    urlQuery["approved"] !== undefined && setValue("approved", urlQuery["approved"]);
    // setAutocompleteValues((prev) => ({
    //   ...prev,
    //   ...(urlQuery["paymentTypes"] && {
    //     paymentTypes: urlQuery["paymentTypes"],
    //   }),
    // }));
    return () => { };
  }, []);

  const history = useHistory();

  const handelAutocompleteDefaultValue = (data, id, fieldName) => {
    const defaultValue = data[Object.keys(data)[0]].find(
      (i) => i.id === parseInt(id)
    );
    defaultValue &&
      setAutocompleteValues((prev) => ({
        ...prev,
        [fieldName]: defaultValue,
      }));
  };
  const pushUrlSearch = (param) => {
    const queryParams = [];
    for (const i in param) {
      encodeURIComponent(param[i]) &&
        queryParams.push(
          encodeURIComponent(i) + "=" + encodeURIComponent(param[i])
        );
    }
    const queryString = queryParams.join("&");

    const url = history.createHref({
      pathname: "/admin/barcode-batches",
      search: "?" + queryString,
    });
    windowUrl(url);
    //this will not effect on history.location.search
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      (event.type === "keydown" || event.type === "submit") &&
      (event.type === "submit" || event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerState({ ...drawerState, [anchor]: open });
  };
  const filterAnchor = screenWidth === "xs" ? "bottom" : "left";

  const onSubmit = (data) => {
    filterAnchor === "bottom" &&
      setDrawerState({ ...drawerState, [filterAnchor]: false });
    let handledData = {
      ...(dateRange[0] && {
        fromDate,
      }),
      ...(dateRange[1] && {
        toDate,
      }),
      barcode: data?.barcode?.toString().trim(),
      branchId: data.branchId,
      customerId: data.customerId,
      approved: data.approved,
      page: 0,
    };
    data["active"] === "" && delete handledData["active"];
    data["approved"] === "" && delete handledData["approved"];

    for (const key in handledData) {
      if (
        handledData[key] === undefined ||
        handledData[key] === "" ||
        handledData[key] === null ||
        handledData[key].length === 0
      ) {
        delete handledData[key];
      }
    }
    if (data.barcode) {
      handledData = {
        barcode: data.barcode.toString(),
      };
    }
    setSearch((prev) => ({
      ...handledData,
      refetch: !prev.refetch,
    }));
    for (const key in handledData) {
      if (handledData[key] === null) {
        delete handledData[key];
      }
    }
    pushUrlSearch({
      ...handledData,
      rowsPerPage: rowsPerPage,
    });
    if (handledData.barcode) {
      handledData.barcode = handledData.barcode
        .split(",")
        .filter((i) => i.trim() !== "")
        .map((i) => i.trim());
    }
  };

  const parseData = (data) => {
    return data;
  };

  const handleChangePage = (event, newPage) => {
    pushUrlSearch({
      ...urlQuery,

      page: newPage,
      rowsPerPage: rowsPerPage,
    });

    setSearch((prev) => ({ ...prev, page: newPage }));
  };

  const resetPage = () => setPage(0);

  const handleChangeRowsPerPage = (event) => {
    pushUrlSearch({
      ...urlQuery,
      page: page,
      rowsPerPage: +event.target.value,
    });

    setRowsPerPage(+event.target.value);
    setSearch((prev) => ({ ...prev, page: page }));
  };

  const resetDate = () => {
    setDateRange([null, null]);
  };

  const { data, loading } = useQuery(
    gql`
      ${LIST_BARCODE_BATCH.query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: {
        first: rowsPerPage,
        page: search?.page + 1,
        input: {
          approved: search.approved,
          ...(search.barcode && { barcode: search.barcode }),
          ...(search.fromDate && { fromDate: search.fromDate }),
          ...(search.toDate && { toDate: search.toDate }),
          ...(search.customerId && { customerId: search.customerId }),
          ...(search.branchId && {
            branchId: search.branchId,
          }),
        },
      },
      onCompleted: (data) => { },
    }
  );

  const GenrateBarcodesList =
    data?.listBarcodeBatches?.data !== null ? data?.listBarcodeBatches?.data : null;

  const clearCode = () => {
    setValue("barcode", "");
    pushUrlSearch({});
    resetPage();
    setSearch((prev) => ({
      ...(dateRange[0] && { fromDate }),
      ...(dateRange[1] && { toDate }),
      refetch: !prev.refetch,
    }));
  };
  return (
    <Root>
      <Drawer
        className={clsx(classes.drawer)}
        variant="persistent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor={filterAnchor}
        open={drawerState[filterAnchor]}
        onClose={() => false}
        dir={theme.direction}
      >
        <div className={classes.drawerHeader} />
        <Toolbar
          variant="dense"
          className={classes.toolbar}
          sx={{ marginBottom: 1 }}
        >
          <Typography
            className={classes.titles}
            color="inherit"
            variant="h6"
            component="div"
          >
            {t("search")}
          </Typography>
          <IconButton
            aria-label="done"
            size="small"
            onClick={toggleDrawer(filterAnchor, false)}
            color={"primary"}
          >
            <Clear />
          </IconButton>
        </Toolbar>

        <Grid
          container
          item
          justifyContent="center"
          alignContent="space-between"
          component={"form"}
          onSubmit={handleSubmit(onSubmit)}
          className={classes.searchForm}
        >
          <Grid
            container
            item
            sm={12}
            justifyContent="flex-start"
            spacing={1}
            className={classes.searchPadding}
          >
            <Grid container item sm={12} alignItems="flex-start">
              <MUItextField
                label={t("barcode")}
                name={"barcode"}
                inputProps={{
                  dir: "ltr",
                }}
                onPaste={(e) => {
                  e.preventDefault();
                  const input = e.target;
                  const inputValue = e.clipboardData
                    .getData("Text")
                    .replace(/\n/g, ",");

                  const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
                    window.HTMLInputElement.prototype,
                    "value"
                  ).set;
                  nativeInputValueSetter.call(input, input.value + inputValue);

                  input.dispatchEvent(new Event("input", { bubbles: true }));
                }}
                onKeyPress={(e) => {
                  const value = e.target.value.trim();
                  if (value.slice(-1) !== ',' && value !== '') {
                    if (e.key === "Enter") {
                      // e.preventDefault();
                      setValue("barcode", `${value},`);
                    }
                  }
                }}
                formType={"requireFalse"}
                register={register}
                errors={errors}
                InputProps={{
                  endAdornment: Boolean(watch("barcode")) && (
                    <InputAdornment position="end">
                      <IconButton
                        disabled={loading}
                        aria-label="code"
                        onClick={clearCode}
                        edge="end"
                        size="large"
                      >
                        <Clear color="error" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                watch={watch("barcode")}
              />
            </Grid>
          </Grid>
          <Divider sx={{ width: "100%" }} />

          <Grid
            container
            item
            sm={12}
            justifyContent="flex-start"
            spacing={1}
            className={classes.searchField}
          >
            {watch("barcode") && <Grid item className={classes.overlay}></Grid>}

            <Grid container item sm={12} alignItems="flex-start">
              <MUIDateRangeCustom
                value={dateRange}
                onChangeValue={(value) => setDateRange(value)}
                resetDate={resetDate}
              />
            </Grid>
            <Grid container item sm={12} alignItems="flex-start">
              <CustomAutocomplete
                control={control}
                errors={errors}
                name={"branchId"}
                label={t("branch")}
                onCompleted={(data) =>
                  handelAutocompleteDefaultValue(
                    data,
                    urlQuery["branchId"],
                    "branch"
                  )
                }
                parseData={(data) => parseData(data)}
                query={LIST_BRANCHES_DROPDOWN.query}
                defaultValue={autocompleteValues.branch}
              />
            </Grid>
            <Grid container item sm={12} alignItems="flex-start">
              <CustomAutocomplete
                name={"customerId"}
                label={t("customer")}
                control={control}
                errors={errors}
                onCompleted={(data) =>
                  handelAutocompleteDefaultValue(
                    data,
                    urlQuery["customerId"],
                    "customer"
                  )
                }
                parseData={(data) => parseData(data)}
                query={LIST_CUSTOMERS_DROPDOWN.query}
                defaultValue={autocompleteValues.customer}
              />
            </Grid>
            <Grid container item sm={12} alignItems="flex-start">
              <MUIselect
                name={"approved"}
                label={t("approved")}
                control={control}
                errors={errors}
                data={[
                  { key: t("no"), value: false },
                  { key: t("yes"), value: true },
                ]}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            sm={12}
            className={clsx(classes.searchPadding, classes.button)}
            alignItems="center"
            alignContent="flex-end"
            justifyContent="flex-end"
          >
            <Button
              className={clsx(classes.btnMargin)}
              variant="contained"
              color="primary"
              type="submit"
              size="large"
              startIcon={<Search />}
              disabled={loading}
            >
              {t("search")}
            </Button>
          </Grid>
        </Grid>
      </Drawer>

      <Grid
        container
        justifyContent="center"
        spacing={3}
        className={clsx(classes.content, classes.mainSearch, {
          [classes.contentShift]: drawerState[filterAnchor],
        })}
      >
        {/* *******Table******* */}

        <Toolbar className={clsx(classes.toolbar)} variant="dense">
          <Typography className={classes.toolbarHeader} variant="h6">
            {t("barcodebatches")}
          </Typography>
          <IconButton onClick={toggleDrawer(filterAnchor, true)} size="large">
            <FilterList fontSize="inherit" />
          </IconButton>
          <SecuredNavLink
            permission="shipping.barcode_batch.create"
            to={{ pathname: "/admin/barcode-batches/create" }}
          >
            <IconButton color={"primary"} size="large">
              <Add fontSize="inherit" />
            </IconButton>
          </SecuredNavLink>
        </Toolbar>

        {loading || GenrateBarcodesList?.length === 0 ? (
          <Grid container item justifyContent="center" className={classes.main}>
            {loading ? (
              <FullScreenLoading minHeight='15%' />
            ) : (
              <EmptyTableMessage
                loading={false}
                message={t("searchNoResult")}
              />
            )}
          </Grid>
        ) : (
          <Paper className={classes.tablePaper}>
            <TableFixedHeaderWraper>
              <Table aria-labelledby="tableTitle" aria-label="enhanced table">
                <TableHead>
                  <TableRow>
                    <FixedTableCell>{t("date")}</FixedTableCell>
                    <FixedTableCell>{t("branch")}</FixedTableCell>
                    <FixedTableCell>{t("customer")}</FixedTableCell>
                    <FixedTableCell>{t("barcodesCount")}</FixedTableCell>
                    <FixedTableCell>{t("approved")}</FixedTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {GenrateBarcodesList &&
                    GenrateBarcodesList?.map((row, index) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.id}
                          className={classes.tableBodyRow}
                        >
                          <CellLink pathname={`/admin/barcode-batches/${row?.id}`}>
                            {moment(row.date).format("ll")}
                          </CellLink>
                          <CellLink
                            pathname={`/admin/branches/${row?.branch?.id}`}
                          >
                            {row?.branch?.name}
                          </CellLink>
                          <CellLink
                            pathname={`/admin/customers/${row?.customer?.id}`}
                          >
                            {row?.customer?.name}
                          </CellLink>
                          <FixedTableCell>{row?.barcodesCount}</FixedTableCell>
                          <FixedTableCell>
                            {row?.approved ? (
                              <Icon className={classes.iconColor}>
                                check_circle_outline
                              </Icon>
                            ) : (
                              <Icon color="error">highlight_off</Icon>
                            )}
                          </FixedTableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableFixedHeaderWraper>
            <MUITablePagination
              count={data?.listBarcodeBatches?.paginatorInfo?.total}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        )}
      </Grid>
    </Root>
  );
};

export default GenrateBarcodesList;
