import * as gqlb from "gql-query-builder";

export const LIST_SAFES = gqlb.query({
  operation: "listSafes",
  fields: [
    {
      operation: "paginatorInfo",
      fields: [
        "count",
        "currentPage",
        "firstItem",
        "hasMorePages",
        "lastItem",
        "lastPage",
        "perPage",
        "total",
      ],
      variables: {},
    },
    {
      operation: "data",
      fields: [
        "id",
        "code",
        "name",
        "active",
        "deposite",
        "withdraw",
        "transferIn",
        "transferOut",
        {
          branch: ["id", "name"],
        },
        {
          glAccount: ["name"],
        },
        {
          treasurers: [
            {
              user: ["id", "username"],
            },
          ],
        },
      ],
      variables: {},
    },
  ],

  variables: {
    input: {
      type: "ListSafesFilterInput",
    },
    page: {
      type: "Int",
    },
    first: {
      type: "Int",
    },
  },
});

export const SAVE_SAFE = gqlb.mutation({
  operation: "saveSafe",
  fields: [
    "id",
    "code",
    "active",
    "deposite",
    "withdraw",
    "transferIn",
    "transferOut",
  ],
  variables: {
    input: {
      type: "SafeInput",
      required: true,
    },
  },
});

export const SAFE_ID = gqlb.query([{
  operation: "safe",
  fields: [
    "id",
    "code",
    "name",
    "active",
    "deposite",
    "withdraw",
    "transferIn",
    "transferOut",
    {
      branch: ["id", "name"],
    },
    {
      glAccount: ["id", "name"],
    },
    {
      paymentTypes: ["code", "id", "name"],
    },
    {
      treasurers: [
        "active",
        {
          user: ["id", "username"],
        },
      ],
    },
  ],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
}, {
  operation: "listPaymentTypesDropdown",
  fields: ["id", "name", "code"],
  variables: {
    input: {
      type: "ListPaymentTypesDropdownInput",
    },
  },
}
]);
