import React, { Fragment, useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  Button,
  Drawer,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
  useTheme,
  Paper,
  Icon,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Collapse,
} from "@mui/material";
import clsx from "clsx";
import { Clear, FilterList, Search } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { gql, useMutation, useQuery } from "@apollo/client";
import { APPROVE_REGISTRATION, LIST_REGISTRATIONS } from "./Graphql";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";

import { useForm } from "react-hook-form";
import { urlParameters } from "../HOC/CustomFunctions/urlParameters";
import { useHistory } from "react-router";
import { windowUrl } from "../HOC/CustomFunctions/pushUrl";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import MUIselect from "../HOC/MUI/MUIselect";
import { useSnackbar } from "notistack";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import CellLink from "../HOC/CustomComponents/CellLink";
import EmptyTableMessage from "../HOC/FunctionComponents/EmptyTableMessage";
import ShipmentRegion from "../HOC/CustomComponents/ShipmentRegion";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";
import useWidth from "../../Hooks/useWidth";
import ExportRegistrationsList from "./ExportRegistrationsList";
import moment from "moment";
import MUIDateRangeCustom from "../HOC/MUI/MUIDateRangeCustom";
import LookupDropdown from "../HOC/CustomComponents/LookupDropdown";
import { lookupCodeIsValid } from "../HOC/CustomFunctions/lookupCodeIsValid";
import { Can } from "../HOC/CustomComponents/Secured";
import { GetCustomerPermissionSlug } from "../../helpers/getManifestPermissionSlug";


const PREFIX = "Registrations";

const classes = {
  content: `${PREFIX}-content`,
  mainSearch: `${PREFIX}-mainSearch`,
  contentShift: `${PREFIX}-contentShift`,
  toolbar: `${PREFIX}-toolbar`,
  drawer: `${PREFIX}-drawer`,
  drawerPaper: `${PREFIX}-drawerPaper`,
  drawerHeader: `${PREFIX}-drawerHeader`,
  titles: `${PREFIX}-titles`,
  searchForm: `${PREFIX}-searchForm`,
  searchField: `${PREFIX}-searchField`,
  btnMargin: `${PREFIX}-btnMargin`,
  tablePaper: `${PREFIX}-tablePaper`,
  iconColor: `${PREFIX}-iconColor`,
  approveRegistration: `${PREFIX}-approveRegistration`,
  toolbarHeader: `${PREFIX}-toolbarHeader`,
  button: `${PREFIX}-button`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.button}`]: {
    position: "sticky",
    bottom: 0,
    zIndex: 3,
    backgroundColor: "#fff",
    marginTop: 10,
    padding: 7,
  },

  [`& .${classes.content}`]: {
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
  },

  [`& .${classes.mainSearch}`]: {
    margin: theme.spacing(0),
    width: "100%",
  },

  [`& .${classes.contentShift}`]: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: "auto",
    width: "calc(100% - 240px)",
    [theme.breakpoints.down("sm")]: {
      margin: "auto",
      width: "auto",
    },
  },

  [`& .${classes.toolbar}`]: {
    borderBottom: "1px #ccd1d6 solid",
    backgroundColor: "#f5f7f9",
    width: "100%",
    // justifyContent: "space-between",
  },

  [`& .${classes.toolbarHeader}`]: {
    flex: 1,
  },

  [`& .${classes.drawer}`]: {
    width: drawerWidth,
    flexShrink: 0,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },

  [`& .${classes.drawerPaper}`]: {
    width: drawerWidth,

    zIndex: "250",
    height: "100%",

    left: "initial",
    right: "initial",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },

  [`& .${classes.drawerHeader}`]: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },

  [`& .${classes.titles}`]: {
    flex: "1 1 100%",
  },

  [`& .${classes.searchForm}`]: {
    overflowY: "auto",
    width: "100%",
    height: "100%",
    margin: 0,
  },

  [`& .${classes.searchField}`]: {
    padding: theme.spacing(1, 1, 0, 1),
    position: "relative",
    overflow: "hidden",
  },

  [`& .${classes.btnMargin}`]: {
    width: "100%",
  },

  [`& .${classes.tablePaper}`]: {
    borderRadius: 0,
    width: "100%",
    display: "grid",
  },

  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },

  [`& .${classes.approveRegistration}`]: {
    [theme.breakpoints.up("sm")]: {
      zIndex: "100",
      backgroundColor: "#fff",
      position: "sticky",
      top: "0",
      right: "0",
    },
  },
}));

const drawerWidth = 240;

const Registrations = (props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const history = useHistory();
  const urlQuery = urlParameters(window.location.search);
  const [rowsPerPage, setRowsPerPage] = useState(urlQuery["rowsPerPage"] ?? 20);
  const validUrlParameters = Object.keys(urlQuery).length !== 0;
  const listType = props.match?.params?.type.toUpperCase();

  const viewName = {
    B2C: t("registeredAccountsCustomers"),
    C2C: t("registeredAccountsIndividuals"),
  };


  const initFromDate = urlQuery["fromDate"]
    ? urlQuery["fromDate"]
    : null;
  const initToDate = urlQuery["toDate"]
    ? urlQuery["toDate"]
    : null;

  const [dateRange, setDateRange] = useState([initFromDate, initToDate]);

  const fromDate = moment(dateRange[0]).locale("en").format("YYYY-MM-DD");
  const toDate = moment(dateRange[1]).locale("en").format("YYYY-MM-DD");

  const initSearch = () => {
    let searchParameters = {
      page: 0,
      refetch: true,
      ...(dateRange[0] && { fromDate }),
      ...(dateRange[1] && { toDate }),
    };
    if (validUrlParameters) {
      if (urlQuery["token"]) urlQuery["token"] = urlQuery["token"]?.toString();
      const searchParams = urlQuery;
      delete searchParams["rowsPerPage"];
      searchParameters = searchParams;
    }
    return searchParameters;
  };
  const [search, setSearch] = useState(initSearch());
  const [page, setPage] = useState(0);
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const { data, loading } = useQuery(
    gql`
      ${LIST_REGISTRATIONS.query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: {
        first: rowsPerPage,
        page: page + 1,
        input: {
          ...(search.fromDate && { fromDate: search.fromDate }),
          ...(search.toDate && { toDate: search.toDate }),
          ...(search.token && { token: search.token }),
          ...(listType && { businessTypeCode: listType }),
          ...(search.verified !== "" && { verified: search.verified }),
          ...(search.new !== "" && { new: search.new }),
          ...(search.new !== "" && { new: search.new }),
          ...(search.emailVerified !== "" && {
            emailVerified: search.emailVerified,
          }),
        },
      },
      onCompleted: (data) => { },
    }
  );
  const listRegistrations = data?.listRegistrations?.data;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const screenWidth = useWidth();

  const [drawerState, setDrawerState] = React.useState({
    top: true,
    left: screenWidth === "xs" ? false : true,
    bottom: screenWidth === "xs" ? false : true,
    right: screenWidth === "xs" ? false : true,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      (event.type === "keydown" || event.type === "submit") &&
      (event.type === "submit" || event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerState({ ...drawerState, [anchor]: open });
  };
  const filterAnchor = screenWidth === "xs" ? "bottom" : "left";

  useEffect(() => {
    if (urlQuery["token"]) urlQuery["token"] = urlQuery["token"].toString();
    urlQuery["token"] && setValue("token", urlQuery["token"]);
    urlQuery["verified"] !== undefined &&
      setValue("verified", urlQuery["verified"]);
    urlQuery["emailVerified"] !== undefined &&
      setValue("emailVerified", urlQuery["emailVerified"]);
    urlQuery["new"] !== undefined &&
      setValue("new", urlQuery["new"]);

    return () => { };
  }, []);

  const pushUrlSearch = (param) => {
    const queryParams = [];
    for (const i in param) {
      encodeURIComponent(param[i]) &&
        queryParams.push(
          encodeURIComponent(i) + "=" + encodeURIComponent(param[i])
        );
    }
    const queryString = queryParams.join("&");

    const url = history.createHref({
      pathname: `/admin/registrations/${listType.toLowerCase()}`,
      search: "?" + queryString,
    });
    windowUrl(url);
  };

  const onSubmit = (data) => {
    filterAnchor === "bottom" &&
      setDrawerState({ ...drawerState, [filterAnchor]: false });

    let handledData = {
      ...(dateRange[0] && {
        fromDate,
      }),
      ...(dateRange[1] && {
        toDate,
      }),
      token: data.token.toString(),
      ...data,
      page: 0,
    };
    data["verified"] === "" && delete handledData["verified"];
    data["new"] === "" && delete handledData["new"];
    data["emailVerified"] === "" && delete handledData["emailVerified"];

    for (const key in handledData) {
      if (
        handledData[key] === undefined ||
        handledData[key] === "" ||
        handledData[key] === null
      ) {
        delete handledData[key];
      }
    }

    pushUrlSearch({
      ...handledData,
      rowsPerPage: rowsPerPage,
    });
    setSearch((prev) => ({
      // ...prev,
      ...handledData,
      refetch: !prev.refetch,
    }));
  };


  const lookupsComplete = (data, updateValue, name) => {
    if (updateValue && lookupCodeIsValid(data, updateValue)) {
      setValue(name, updateValue);
    }
  };

  const [dialogState, setDialogState] = useState(false);
  const [registrationId, setRegistrationId] = useState(null);
  const handelApproveRegistration = (id) => {
    setRegistrationId(id);
    setDialogState(true);
  };
  const handleDialogClose = () => {
    setDialogState(false);
  };
  const resetDate = () => {
    setDateRange([null, null]);
  };
  const [approveRegistration, { loading: approveRegistrationLoading, client }] =
    useMutation(
      gql`
        ${APPROVE_REGISTRATION.query}
      `
    );
  const approveRegistrationData = () => {
    approveRegistration({
      variables: {
        id: registrationId,
      },
    })
      .then((data) => {
        setRegistrationId(null);
        handleDialogClose();
        client.refetchQueries({
          include: [
            gql`
              ${LIST_REGISTRATIONS.query}
            `,
          ],
        });
        enqueueSnackbar(t("saveSuccessful"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      })
      .catch((error) => {
        console.log(error);
        setRegistrationId(null);
        handleDialogClose();
        enqueueSnackbar(error.message, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      });
  };

  const exportFilters = {
    verified: search.verified,
    new: search.new,
    ...(search.fromDate && { fromDate: search.fromDate }),
    ...(search.toDate && { toDate: search.toDate }),
    emailVerified: search.emailVerified,
    ...(search.token && { token: search.token }),
    ...(listType && { businessTypeCode: listType }),
  }

  const pagePermission = GetCustomerPermissionSlug("shipping", "registration", listType, "list")

  return (
    <Can permission={pagePermission}>
      <Root>
        <Drawer
          className={clsx(classes.drawer)}
          variant="persistent"
          classes={{
            paper: classes.drawerPaper,
          }}
          anchor={filterAnchor}
          open={drawerState[filterAnchor]}
          onClose={() => false}
          dir={theme.direction}
        >
          <div className={classes.drawerHeader} />
          <Toolbar
            variant="dense"
            className={classes.toolbar}
            sx={{ marginBottom: 1 }}
          >
            <Typography
              className={classes.titles}
              color="inherit"
              variant="h6"
              component="div"
            >
              {t("search")}
            </Typography>
            <IconButton
              aria-label="done"
              size="small"
              onClick={toggleDrawer(filterAnchor, false)}
              color={"primary"}
            >
              <Clear />
            </IconButton>
          </Toolbar>

          <Grid
            container
            item
            justifyContent="center"
            alignContent="space-between"
            component={"form"}
            onSubmit={handleSubmit(onSubmit)}
            className={classes.searchForm}
          >
            <Grid
              container
              item
              sm={12}
              justifyContent="flex-start"
              spacing={1}
              className={classes.searchField}
            >
              {/* filters */}
              <Grid container item sm={12} alignItems="flex-start">
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  name={"token"}
                  label={t("search")}
                />
              </Grid>
              <Grid container item sm={12} alignItems="flex-start">
                <MUIDateRangeCustom
                  value={dateRange}
                  onChangeValue={(value) => setDateRange(value)}
                  // disabled={codeValidation}
                  resetDate={resetDate}
                />
              </Grid>
              <Grid container item sm={12} alignItems="flex-start">
                <MUIselect
                  name={"new"}
                  label={t("new")}
                  control={control}
                  errors={errors}
                  data={[
                    { key: t("yes"), value: true },
                    { key: t("no"), value: false },
                  ]}
                />
              </Grid>
              {/* <Grid container item sm={12} alignItems="flex-start">
              <LookupDropdown
                control={control}
                errors={errors}
                name={"businessTypeCode"}
                label={t("businessType")}
                variables={{
                  input: { code: "SHP_BUSINESS_TYPE" },
                }}
                onCompleted={(data) =>
                  lookupsComplete(
                    data,
                    urlQuery["businessTypeCode"],
                    "businessTypeCode"
                  )
                }
              />
            </Grid> */}
              <Grid container item sm={12} alignItems="flex-start">
                <MUIselect
                  name={"verified"}
                  label={t("verified")}
                  control={control}
                  errors={errors}
                  data={[
                    { key: t("notVerified"), value: false },
                    { key: t("verified"), value: true },
                  ]}
                />
              </Grid>
              <Grid container item sm={12} alignItems="flex-start">
                <MUIselect
                  name={"emailVerified"}
                  label={t("emailVerified")}
                  control={control}
                  errors={errors}
                  data={[
                    { key: t("emailNotVerified"), value: false },
                    { key: t("emailVerified"), value: true },
                  ]}
                />
              </Grid>
            </Grid>
            <Grid
              container
              item
              sm={12}
              className={clsx(classes.searchPadding, classes.button)}
              alignItems="center"
              alignContent="flex-end"
              justifyContent="flex-end"
            >
              <Button
                className={clsx(classes.btnMargin)}
                variant="contained"
                color="primary"
                type="submit"
                size="large"
                startIcon={<Search />}
                disabled={loading}
              >
                {t("search")}
              </Button>
            </Grid>
          </Grid>
        </Drawer>

        <Grid
          container
          justifyContent="center"
          spacing={3}
          className={clsx(classes.content, classes.mainSearch, {
            [classes.contentShift]: drawerState[filterAnchor],
          })}
        >
          {/* dialog--------------------- */}
          <Dialog
            open={dialogState}
            onClose={handleDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            maxWidth={"xs"}
          >
            <DialogTitle id="alert-dialog-title">
              {t("registeredAccounts")}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {t("areYouSureToActivateTheUser?")}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose} color="primary">
                {t("cancel")}
              </Button>
              <Button
                onClick={approveRegistrationData}
                color="primary"
                autoFocus
                disabled={approveRegistrationLoading}
              >
                {approveRegistrationLoading ? <ButtonLoading /> : t("confirm")}
              </Button>
            </DialogActions>
          </Dialog>
          {/* *******Table******* */}

          <Toolbar className={clsx(classes.toolbar)} variant="dense">
            <Typography className={classes.toolbarHeader} variant="h6">{viewName[listType]}</Typography>
            <ExportRegistrationsList
              disabled={Boolean(listRegistrations?.length === 0)}
              filters={exportFilters}
            />
            <IconButton onClick={toggleDrawer(filterAnchor, true)} size="large">
              <FilterList fontSize="inherit" />
            </IconButton>
          </Toolbar>

          {loading || listRegistrations?.length === 0 ? (
            <Grid
              container
              item
              justifyContent="center"
              className={classes.main}
              component={Paper}
            >
              {loading ? (
                <FullScreenLoading minHeight={30} />
              ) : (
                <EmptyTableMessage
                  loading={false}
                  message={t("searchNoResult")}
                />
              )}
            </Grid>
          ) : (
            <Paper className={classes.tablePaper}>
              <TableFixedHeaderWraper>
                <Table aria-labelledby="tableTitle" aria-label="enhanced table">
                  <TableHead>
                    <TableRow>
                      <FixedTableCell>{t("storeName")}</FixedTableCell>
                      <FixedTableCell>{t("name")}</FixedTableCell>
                      <FixedTableCell>{t("date")}</FixedTableCell>
                      <FixedTableCell>{t("username")}</FixedTableCell>
                      <FixedTableCell>{t("source")}</FixedTableCell>
                      <FixedTableCell>{t("address")}</FixedTableCell>
                      <FixedTableCell>{t("email")}</FixedTableCell>
                      <FixedTableCell>{t("mobile")}</FixedTableCell>
                      <FixedTableCell>{t("verified")}</FixedTableCell>
                      <FixedTableCell>{t("emailVerified")}</FixedTableCell>
                      <FixedTableCell
                        className={classes.approveRegistration}
                      ></FixedTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {listRegistrations &&
                      listRegistrations?.map((row, index) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={index}
                          >
                            <FixedTableCell>{row?.businessName}</FixedTableCell>
                            <FixedTableCell>{row?.name}</FixedTableCell>
                            <FixedTableCell>{row?.createdAt}</FixedTableCell>
                            <CellLink pathname={`/admin/users/${row?.user?.id}`}>
                              {row?.user?.username}
                            </CellLink>
                            <ShipmentRegion
                              pathname={`/admin/zone/${row?.zone?.id}`}
                              zone={row?.zone?.name}
                              subzone={row?.subzone?.name}
                            />
                            <FixedTableCell>{row?.address}</FixedTableCell>
                            <FixedTableCell>{row?.email}</FixedTableCell>
                            <FixedTableCell>{row?.mobile}</FixedTableCell>
                            <FixedTableCell>
                              {row?.verified ? (
                                <Icon className={classes.iconColor}>
                                  check_circle_outline
                                </Icon>
                              ) : (
                                <Icon color="error">highlight_off</Icon>
                              )}
                            </FixedTableCell>
                            <FixedTableCell>
                              {row?.emailVerified ? (
                                <Icon className={classes.iconColor}>
                                  check_circle_outline
                                </Icon>
                              ) : (
                                <Icon color="error">highlight_off</Icon>
                              )}
                            </FixedTableCell>

                            <FixedTableCell
                              className={classes.approveRegistration}
                              component="th"
                              scope="row"
                            >
                              {!row.verified && (
                                <Button
                                  color="primary"
                                  variant="outlined"
                                  size="small"
                                  onClick={() =>
                                    handelApproveRegistration(row.id)
                                  }
                                  startIcon={<Icon>task_alt</Icon>}
                                >
                                  {t("approve")}
                                </Button>
                              )}
                            </FixedTableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableFixedHeaderWraper>
              <MUITablePagination
                count={data?.listRegistrations?.paginatorInfo?.total}
                rowsPerPage={rowsPerPage}
                page={!data?.listRegistrations ? 0 : page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          )}
        </Grid>
      </Root>
    </Can>
  );
};

export default Registrations;
