import styled from "@emotion/styled";
import { AddCircleOutline } from "@mui/icons-material";
import { IconButton, Toolbar, Typography } from "@mui/material";
import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";

const PREFIX = "ManifestTableToolbar";

const classes = {
  titles: `${PREFIX}-titles`,
  addIcon: `${PREFIX}-addIcon`,
};
const Root = styled(Toolbar)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(1),
  [`& .${classes.titles}`]: {
    flex: "1 1 100%",
  },

  [`& .${classes.addIcon}`]: {
    color: theme.palette.success.main,
  },
}));
//*********Table Function*********
const EnhancedTableToolbar = (props) => {
  const { selectedProduct, addShipment, disabledAddIcon } = props;

  const { t } = useTranslation();

  return (
    <Root className={clsx(classes.root)}>
      <Typography
        className={classes.titles}
        color="inherit"
        variant="subtitle1"
        component="div"
      >
        {t("productsNumber") + " " + selectedProduct?.length}
      </Typography>
      {addShipment && (
        <IconButton
          aria-label="Add"
          className={classes.addIcon}
          onClick={addShipment}
          disabled={disabledAddIcon}
          size="large"
        >
          <AddCircleOutline />
        </IconButton>
      )}
      {/* {barcode ? (
        <IconButton aria-label="Add" onClick={barcode} size="large">
          <Icon>qr_code_scanner</Icon>
        </IconButton>
      ) : null}
      <Can showException permission="shipping.shipment.print">
        <PrintComponent shipmentsId={shipmentId} />
      </Can> */}
    </Root>
  );
};


export default EnhancedTableToolbar;
