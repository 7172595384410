import React from "react";
import { styled } from "@mui/material/styles";
import { Checkbox, TableHead, TableRow } from "@mui/material";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
// import ShipmentRegion from "../../HOC/CustomComponents/ShipmentRegion";
import { FixedTableCell } from "../../HOC/CustomComponents/FixedTableCell";
// import { WarehouseSectionTableCell } from "./WarehouseSectionTableCell";
// import { QuantityTableCell } from "./QuantityTableCell";
// import { WarehouseSectionTableCell } from "./WarehouseSectionTableCell";
// import CellLink from "../../HOC/CustomComponents/CellLink";

const PREFIX = "ProductListDetails";

const classes = {
  productTable_checkbox: `${PREFIX}-productTable_checkbox`,
};

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  [`& .${classes.productTable_checkbox}`]: {
    zIndex: "100",
    backgroundColor: "#fff",
    position: "sticky",
    top: 0,
    left: 0,
  },
}));

export const ManifestProductsCells = ({ productDetails, type}) => {
  const cellsByType = {
    PADD: (
      <>
        {/* {product.select && (<WarehouseSectionTableCell shipmentDetails={productDetails} product={product} />)} */}
        {/* <FixedTableCell>{productDetails?.customer?.name}</FixedTableCell> */}
        {/* <FixedTableCell>{productDetails?.code}</FixedTableCell> */}
        {/* <FixedTableCell>{productDetails?.name}</FixedTableCell> */}
        <FixedTableCell>{productDetails?.price}</FixedTableCell>
        <FixedTableCell>{productDetails?.weight}</FixedTableCell>
      </>
    ),
    PRTRN: (
      <>
        {/* <QuantityTableCell productDetails={productDetails} product={product} /> */}
        {/* <FixedTableCell>{productDetails?.customer?.name}</FixedTableCell> */}
        {/* <FixedTableCell>{productDetails?.code}</FixedTableCell> */}
        {/* <FixedTableCell>{productDetails?.name}</FixedTableCell> */}
        <FixedTableCell>{productDetails?.availableQuantity}</FixedTableCell>
        <FixedTableCell>{productDetails?.price}</FixedTableCell>
        <FixedTableCell>{productDetails?.weight}</FixedTableCell>
      </>
    ),
    PMBR: (
      <>
        {/* <QuantityTableCell productDetails={productDetails} product={product} /> */}
        {/* <FixedTableCell>{productDetails?.customer?.name}</FixedTableCell> */}
        {/* <FixedTableCell>{productDetails?.code}</FixedTableCell> */}
        {/* <FixedTableCell>{productDetails?.name}</FixedTableCell> */}
        {/* <FixedTableCell>{productDetails?.availableQuantity}</FixedTableCell> */}
        <FixedTableCell>{productDetails?.price}</FixedTableCell>
        <FixedTableCell>{productDetails?.weight}</FixedTableCell>
      </>
    ),
    PMBT: (
      <>
        {/* <QuantityTableCell productDetails={productDetails} product={product} /> */}
        {/* <FixedTableCell>{productDetails?.customer?.name}</FixedTableCell> */}
        {/* <FixedTableCell>{productDetails?.code}</FixedTableCell> */}
        {/* <FixedTableCell>{productDetails?.name}</FixedTableCell> */}
        <FixedTableCell>{productDetails?.availableQuantity}</FixedTableCell>
        <FixedTableCell>{productDetails?.price}</FixedTableCell>
        <FixedTableCell>{productDetails?.weight}</FixedTableCell>
      </>
    ),
  };

  return cellsByType[type];
};

export function EnhancedTableHead(props) {
  const { onSelectAllClick, numSelected, rowCount, type } = props;

  const { t } = useTranslation();
  const headCells = {
    PADD: [
      t("code"),
      // t("customer"),
      t("name"),
      t("quantity"),
      t("wareHouse"),
      t("price"),
      t("weight"),
    ],
    PRTRN: [
      t("code"),
      // t("customer"),
      t("name"),
      t("quantity"),
      t("availableQuantity"),
      t("price"),
      t("weight"),
    ],
    PMBR: [
      t("code"),
      // t("customer"),
      t("name"),
      t("wareHouse"),
      // t("availableQuantity"),
      t("price"),
      t("weight"),
    ],
    PMBT: [
      t("code"),
      // t("customer"),
      t("name"),
      t("quantity"),
      t("availableQuantity"),
      t("price"),
      t("weight"),
    ],
  };

  return (
    <StyledTableHead>
      <TableRow>
        <FixedTableCell
          className={classes.productTable_checkbox}
          padding="checkbox"
        >
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </FixedTableCell>
        {headCells[type].map((headCell, index) => (
          <FixedTableCell
            key={index}
          // align={headCell.numeric ? 'right' : 'left'}
          >
            {headCell}
          </FixedTableCell>
        ))}
      </TableRow>
    </StyledTableHead>
  );
}

export const removeFiltersFields = {
  PADD: [
    "custm",
  ],
  PRTRN: [
    "custm",
  ],
};

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
};
