/* eslint-disable no-useless-concat */
import React, { Fragment, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { SecuredNavLink } from "../HOC/CustomComponents/Secured";
import {
  Typography,
  Paper,
  Toolbar,
  IconButton,
  Icon,
  Table,
  TableRow,
  TableHead,
  TableBody,
} from "@mui/material";
import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";
import EmptyTableMessage from "../HOC/FunctionComponents/EmptyTableMessage";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import CellLink from "../HOC/CustomComponents/CellLink";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import { Edit } from "@mui/icons-material";
import { BRANCH } from "./Graphql";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { KeyValuePair } from "../HOC/CustomComponents/KeyValuePair";
import Grid from "@mui/material/Unstable_Grid2";
import CheckCrossIcons from "../HOC/FunctionComponents/CheckCrossIcons";
import styled from "@emotion/styled";

const PREFIX = "BranchView";

const classes = {
  loading: `${PREFIX}-loading`,
  paper: `${PREFIX}-paper`,
  toolbar: `${PREFIX}-toolbar`,
  toolbarIcons: `${PREFIX}-toolbarIcons`,
  iconButton: `${PREFIX}-iconButton`,
  main: `${PREFIX}-main`,
  field: `${PREFIX}-field`,
  iconColor: `${PREFIX}-iconColor`,
  toolbarTable: `${PREFIX}-toolbarTable`,
  table: `${PREFIX}-table`,
  title: `${PREFIX}-title`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.loading}`]: {},

  [`& .${classes.paper}`]: {
    padding: theme.spacing(3),
    flexGrow: 1,
  },

  [`& .${classes.toolbar}`]: {
    borderBottom: "1px #ccd1d6 solid",
    backgroundColor: "#f5f7f9",
    width: "100%",
  },

  [`& .${classes.toolbarIcons}`]: {
    flex: "1 1 100%",
    textAlign: "end",
  },

  [`& .${classes.toolbarTable}`]: {
    width: "100%",
    backgroundColor: theme.palette.grey[50],
    borderBottom: "1px solid " + theme.palette.divider,
    borderRight: "1px solid " + theme.palette.divider,
  },
  [`& .${classes.table}`]: {
    display: "grid",
    minWidth: "100%",
  },
  [`& .${classes.title}`]: {
    flex: 1,
  },
  [`& .${classes.iconButton}`]: {
    borderRadius: "0 4px 4px 0 ",
    boxShadow: "none",
    height: 45,
  },

  [`& .${classes.main}`]: {
    margin: 0,
    width: "100%",
  },

  [`& .${classes.field}`]: {
    width: 260,
    [theme.breakpoints.down("sm")]: {
      width: 170,
    },
  },

  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },
}));

const BranchView = (props) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const handlePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  const branchId = props.id ?? props.match?.params?.id?.trim();
  const { t } = useTranslation();
  const { loading, data } = useQuery(
    gql`
      ${BRANCH.query}
    `,
    {
      fetchPolicy: "network-only",
      variables: { id: parseInt(branchId) },
    }
  );

  const warehouseSections = data?.branch?.warehouseSections ?? [];

  return (
    <StyledGrid
      container
      justifyContent="center"
      sx={{ margin: "0", width: "100%" }}
    >
      {!props.id ? (
        <Fragment>
          <Toolbar className={clsx(classes.toolbar)} variant="regular">
            <Grid
              container
              sx={{ flexWrap: "initial", flexGrow: 1 }}
              alignItems="center"
            >
              <Grid className={classes.field}>
                <Typography variant="h6">{t("branchDetails")}</Typography>
              </Grid>
              <Grid className={classes.toolbarIcons}>
                <SecuredNavLink
                  permission="core.branch.update"
                  to={{ pathname: `/admin/branches/${branchId}/edit` }}
                >
                  <IconButton color={"primary"} size="large">
                    <Edit fontSize="inherit" />
                  </IconButton>
                </SecuredNavLink>
              </Grid>
            </Grid>
          </Toolbar>
        </Fragment>
      ) : null}

      <Grid
        container
        justifyContent="center"
        sx={{ width: "100%", p: 2, gap: 2 }}
      >
        {loading ? (
          <FullScreenLoading minHeight="25%" />
        ) : (
          <Fragment>
            <Paper container xs={12} className={classes.paper} component={Grid}>
              <KeyValuePair title={t("code")} value={data?.branch.code} />
              <KeyValuePair title={t("name")} value={data?.branch.name} />
              <KeyValuePair
                title={t("active")}
                value={
                  data?.branch?.active ? (
                    <Icon className={classes.iconColor}>
                      check_circle_outline
                    </Icon>
                  ) : (
                    <Icon color="error">highlight_off</Icon>
                  )
                }
              />
              <KeyValuePair
                title={t("main")}
                value={
                  data?.branch?.main ? (
                    <Icon className={classes.iconColor}>
                      check_circle_outline
                    </Icon>
                  ) : (
                    <Icon color="error">highlight_off</Icon>
                  )
                }
              />
            </Paper>

            <Paper container xs={12} className={classes.paper} component={Grid}>
              <KeyValuePair
                title={t("country")}
                value={data?.branch.country?.name}
              />
              <KeyValuePair
                title={t("state")}
                value={data?.branch.state?.name}
              />
              <KeyValuePair title={t("city")} value={data?.branch.city?.name} />

              <KeyValuePair title={t("phone")} value={data?.branch.phone} />
              <KeyValuePair title={t("fax")} value={data?.branch.fax} />

              <KeyValuePair title={t("address")} value={data?.branch.address} />
            </Paper>
            <Paper
              container
              sm={12}
              md={8}
              className={classes.table}
              component={Grid}
            >
              <Toolbar className={classes.toolbarTable} variant="dense">
                <Typography
                  className={classes.title}
                  color="inherit"
                  variant="h6"
                >
                  {t("WareHouseSections")}
                </Typography>
              </Toolbar>
              <TableFixedHeaderWraper component={Paper}>
                {loading || data?.branch.warehouseSections.length <= 0 ? (
                  <EmptyTableMessage
                    loading={loading}
                    message={t("noResult")}
                  />
                ) : (
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <FixedTableCell>{t("wareHouse")}</FixedTableCell>
                        <FixedTableCell>{t("active")}</FixedTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {warehouseSections &&
                        (rowsPerPage > 0
                          ? warehouseSections.slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                          : warehouseSections
                        )?.map((row, index) => (
                          <TableRow hover key={index}>
                            <CellLink pathname={`/admin/warehouse/${row?.id}`}>
                              {row?.name}
                            </CellLink>
                            <FixedTableCell>
                              <CheckCrossIcons active={row?.active} />
                            </FixedTableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                )}
              </TableFixedHeaderWraper>
              <MUITablePagination
                count={warehouseSections.length ?? 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handlePage}
                onRowsPerPageChange={handleRowsPerPage}
              />
            </Paper>
          </Fragment>
        )}
      </Grid>
    </StyledGrid>
  );
};

export default BranchView;
