import * as gqlb from "gql-query-builder";

export const LIST_COLLECTIONS_QUERY = gqlb.query([
  {
    operation: "listCollections",
    fields: [
      {
        operation: "paginatorInfo",
        fields: [
          "count",
          "currentPage",
          "total",
          "firstItem",
          "hasMorePages",
          "lastItem",
          "lastPage",
          "perPage",
        ],
        variables: {},
      },
      {
        operation: "data",
        fields: [
          "code",
          "id",
          "date",
          "approved",
          "glApproved",
          "notes",
          "collectedAmount",
          "shipmentCount",
          "returningDueFees",
          "collectedFees",
          "totalAmount",
          "deliveredAmount",
          "collectedAmount",
          {
            operation: "transactionType",
            fields: ["id", "name"],
            variables: {},
          },
          {
            operation: "safe",
            fields: ["id", "name"],
            variables: {},
          },
          {
            operation: "deliveryAgent",
            fields: ["name", "id"],
            variables: {},
          },
          {
            customer: ["name", "id"],
          },
        ],
        variables: {},
      },
    ],
    variables: {
      input: {
        type: "ListCollectionFilterInput",
        required: true,
      },
      first: {
        type: "Int",
        required: true,
        value: 15,
      },
      page: {
        type: "Int",
        value: 1,
      },
    },
  },
  {
    operation: "sumCollections",
    fields: ["collectedAmount", "shipmentCount"],
    variables: {
      input: {
        type: "ListCollectionFilterInput",
        required: true,
      },
    },
  },
]);

export const collectionFields = [
  "id",
  "date",
  "code",
  "approved",
  "glApproved",
  "notes",
  "returningDueFees",
  "collectedFees",
  "totalAmount",
  "deliveredAmount",
  "collectedAmount",
  "totalCommission",
  {
    type: ["code"],
  },
  {
    createdBy: ["username", "id"],
  },
  {
    updatedBy: ["username", "id"],
  },
  {
    customer: ["id", "name", "code", "address"],
  },
  {
    operation: "branch",
    fields: ["id", "name"],
    variables: {},
  },
  {
    transactionType: ["id", "code", "name", { type: ["name", "code"] }],
  },
  {
    operation: "deliveryAgent",
    fields: ["id", "name", "code", "autoPayCommission"],
    variables: {},
  },
  {
    operation: "safe",
    fields: ["id", "name"],
    variables: {},
  },

  {
    operation: "entries",
    fields: [
      "collectedAmount",
      "commission",
      {
        operation: "shipment",
        fields: [
          "id",
          "code",
          "recipientName",
          "recipientMobile",
          "recipientAddress",
          "senderName",
          "description",
          "totalAmount",
          "deliveredAmount",
          "collectedFees",
          "returningDueFees",
          "returnFees",
          {
            returnType: ["code"],
          },
          {
            recipientZone: ["id", "name"],
          },
          {
            originBranch: ["id", "name"],
          },
          {
            deliveryType: ["name"],
          },
          {
            recipientSubzone: ["name"],
          },
          {
            customer: ["name"],
          },
          {
            status: ["name"],
          },
        ],
        variables: {},
      },
    ],
    variables: {},
  },
];

export const COLLECTION_BY_ID_QUERY = gqlb.query({
  operation: "collection",
  fields: collectionFields,
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
export const COLLECTION_PRINT = gqlb.query([
  {
    operation: "collection",
    fields: collectionFields,
    variables: {
      id: {
        type: "Int",
        required: true,
      },
    },
  },
  {
    operation: "organization",
    fields: [
      "name",
      "phone",
      "email",
      "website",
      "reportHeader",
      "reportFooter",
    ],
    variables: {},
  },
]);

export const COLLECTION_VIEW = (permission) =>
  gqlb.query([
    {
      operation: "collection",
      fields: [
        "id",
        "date",
        "code",
        "approved",
        "glApproved",
        "notes",
        "returningDueFees",
        "collectedFees",
        "totalAmount",
        "deliveredAmount",
        "collectedAmount",
        "totalCommission",
        {
          type: ["code"],
        },
        {
          createdBy: ["username", "id"],
        },
        {
          operation: `journalEntry @include(if: ${permission})`,
          fields: ["code", "id"],
          variables: {},
        },
        {
          updatedBy: ["username", "id"],
        },
        {
          customer: ["id", "name", "code", "address"],
        },
        {
          operation: "branch",
          fields: ["id", "name"],
          variables: {},
        },
        {
          transactionType: ["id", "code", "name", { type: ["name", "code"] }],
        },
        {
          operation: "deliveryAgent",
          fields: ["id", "name", "code", "autoPayCommission"],
          variables: {},
        },
        {
          operation: "safe",
          fields: ["id", "name"],
          variables: {},
        },

        {
          operation: "entries",
          fields: [
            "collectedAmount",
            "commission",
            {
              operation: "shipment",
              fields: [
                "id",
                "code",
                "recipientName",
                "recipientMobile",
                "recipientAddress",
                "senderName",
                "description",
                "totalAmount",
                "deliveredAmount",
                "collectedFees",
                "returningDueFees",
                "returnFees",
                {
                  returnType: ["code"],
                },
                {
                  recipientZone: ["id", "name"],
                },
                {
                  originBranch: ["id", "name"],
                },
                {
                  deliveryType: ["name"],
                },
                {
                  recipientSubzone: ["name"],
                },
                {
                  customer: ["name"],
                },
                {
                  status: ["name"],
                },
              ],
              variables: {},
            },
          ],
          variables: {},
        },
      ],
      variables: {
        id: {
          type: "Int",
          required: true,
        },
      },
    },
  ]);

export const SAVE_COLLECTION_MUTATION = gqlb.mutation({
  operation: "saveCollection",
  fields: collectionFields,
  variables: {
    input: {
      type: "CollectionInput",
      required: true,
    },
  },
});

export const DELETE_COLLECTIONS_MUTATION = gqlb.mutation({
  operation: "deleteCollection",
  fields: [],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
export const APPROVE_COLLECTION_MUTATION = gqlb.mutation({
  operation: "approveCollection",
  fields: collectionFields,
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
export const DISAPPROVE_COLLECTION_MUTATION = gqlb.mutation({
  operation: "disapproveCollection",
  fields: ["id"],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
export const GL_APPROVE_COLLECTION_MUTATION = gqlb.mutation({
  operation: "glApproveCollection",
  fields: collectionFields,
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
