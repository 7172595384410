import * as gqlb from "gql-query-builder";
export const SETTINGS = gqlb.query({
  operation: "shippingSettings",
  fields: [{ localCurrency: ["name"] }],
  variables: {},
});
export const LIST_SHIPMENTS_TRANSACTIONS =
  gqlb.query([
    {
      operation: "listShipmentTransactions",
      fields: [
        {
          operation: "paginatorInfo",
          fields: [
            "count",
            "currentPage",
            "firstItem",
            "hasMorePages",
            "lastItem",
            "lastPage",
            "perPage",
            "total",
          ],
          variables: {},
        },
        {
          operation: "data",
          fields: [
            "id",
            "date",
            "deliverdAmount",
            "fees",
            {
              cancellationReason: ["name"],
            },
            {
              branch: ["name"],
            },
            {
              shipmentStatus: ["code", "name"],
            },
            {
              deliveryType: ["code", "name"],
            },
            {
              returnType: ["code", "name"],
            },
            {
              deliveryAgent: ["id", "name"],
            },
            {
              createdBy: ["id", "username"],
            },
            {
              trxType: ["id", "name", { type: ["name"] }],
            },
            {
              manifest: ["id", "code", { transactionType: [{ type: ["code"] }] }],
            },
            {
              shipment: ["id", "code", { customer: ["id", "code", "name"] }],
            },
            {
              manifestShipment: ["notes"],
            },
          ],
          variables: {},
        },
      ],
      variables: {
        page: {
          type: "Int",
        },
        first: {
          type: "Int",
        },
        input: {
          type: "ListShipmentTransactionsInput",
        },
      },
    }
  ]);
export const SHIPMENT_MESSAGES = gqlb.query({
  operation: "shipment",
  fields: [
    {
      messages: [
        "id",
        "body",
        "image",
        "createdAt",

        {
          user: [
            "username",
            "id",
            {
              operation: "account",
              fields: [
                {
                  operation: "...on Customer",
                  fields: ["customerId:id", "name"],
                  variables: {},
                },
                {
                  operation: "...on DeliveryAgent",
                  fields: ["deliveryAgentId:id", "name"],
                  variables: {},
                },
              ],
              variables: {},
            },
          ],
        },
      ],
    },
  ],
  variables: {
    id: {
      type: "Int",
    },
    code: {
      type: "String",
    },
  },
});
export const SAVE_SHIPMENT_QUERY = gqlb.mutation({
  operation: "saveShipment",
  fields: [
    "id",
    "date",
    "code",
    "recipientName",
    "description",
    "piecesCount",
    "recipientAddress",
    "amount",
    "totalAmount",
    "allDueFees",
    "inWarehouse",
    {
      recipientZone: ["id", "name"],
    },
    {
      customer: ["id", "name", "code"],
    },
    {
      recipientSubzone: ["id", "name"],
    },
  ],
  variables: {
    input: {
      type: "ShipmentInput",
      required: true,
    },
  },
});
export const IMPORT_SHIPMENTS = gqlb.mutation({
  operation: "importShipments",
  fields: ["name", "value", "originalValue", "errors"],
  variables: {
    input: {
      type: "ImportShipmentsInput",
      required: true,
    },
  },
});

export const CALCULATE_FEES_QUERY = gqlb.query({
  operation: "calculateShippingFees",
  fields: [
    "amount",
    "delivery",
    "weight",
    "collection",
    "total",
    "tax",
    "post",
    "return",
  ],
  variables: {
    input: {
      type: "CalculateFeesInput",
      required: true,
    },
  },
});

export const CUSTOMERID = gqlb.query([
  {
    operation: "customer",
    fields: [
      "id",
      "name",
      "address",
      "phone",
      "mobile",
      "activityName",
      "specifyShipmentCode",
      "postalCode",
      "warehousing",
      {
        zone: [
          "id",
          "name",
          {
            branch: [
              {
                country: ["id"],
              },
            ],
          },
        ],
      },
      {
        subzone: ["id", "name"],
      },
      {
        defaultShipmentType: ["code", "name"],
      },
      {
        paymentTypes: ["code", "id"],
      },
      {
        defaultPriceType: ["code", "name"],
      },
      {
        defaultOpenable: ["code", "name"],
      },
      {
        businessType: ["id", "name", "code"],
      },
    ],
    variables: {
      id: {
        type: "Int",
        required: true,
      },
    },
  },
  {
    operation: "listShippingServicesDropdown",
    fields: ["id", "name"],

    variables: {
      input: {
        type: "ListShippingServicesFilterInput",
      },
    },
  },
]);

export const SHIPMENT_UPDATE_QUERY = () =>
  gqlb.query({
    operation: "shipment",
    fields: [
      "id",
      "date",
      "code",
      "description",
      "weight",
      "piecesCount",
      "price",
      "notes",
      "recipientName",
      "recipientAddress",
      "recipientPhone",
      "recipientMobile",
      "deliveredAmount",
      "senderName",
      "senderPhone",
      "senderMobile",
      "senderAddress",
      "collected",
      "paidToCustomer",
      "paidToDeliveryAgent",
      "returnedValue",
      "amount",
      "allDueFees",
      "editable",
      "returningDueFees",
      "extraWeightFees",
      "collectionFees",
      "collectedFees",
      "totalAmount",
      "editable",
      "senderName",
      "refNumber",
      "returnPiecesCount",
      "deliveryFees",
      "extraWeightFees",
      "collectionFees",
      "returnFees",
      "tax",
      "postFees",
      "recipientPostalCode",
      "senderPostalCode",
      {
        shipmentProducts: [
          "price", "quantity",
          {
            product: [
              "id",
              "name",
              {
                operation: "availableQuantity",
                fields: [],
                variables: {
                  excludeShipmentId: {
                    type: "Int",
                    required: true,
                  },
                },
              },
              "weight"
            ]
          }
        ]
      },
      {
        size: ["length", "width", "height"],
      },
      {
        service: ["id", "name"],
      },
      {
        recipientZone: [
          "id",
          "name",
          {
            branch: [
              {
                country: ["id", "name"],
              },
            ],
          },
        ],
      },
      {
        recipientSubzone: ["id", "name"],
      },
      {
        senderZone: [
          "id",
          "name",
          {
            branch: [
              {
                country: ["id", "name"],
              },
            ],
          },
        ],
      },
      {
        senderSubzone: ["id", "name"],
      },
      {
        paymentType: ["code", "name"],
      },
      {
        priceType: ["code", "name"],
      },
      {
        type: ["code", "name"],
      },
      {
        openable: ["code", "name"],
      },
      {
        status: ["code", "name"],
      },
      {
        lastDeliveryAgent: ["id", "name"],
      },
      {
        transactionType: ["id", "name"],
      },
      {
        branch: ["id", "name"],
      },
      {
        originBranch: ["id", "name"],
      },
      {
        returnStatus: ["code", "name"],
      },
      {
        pickup: [{ customer: ["id", "name"] }, { branch: ["id", "name"] }],
      },
      {
        customer: [
          "id",
          "name",
          "address",
          "phone",
          "mobile",
          "warehousing",
          {
            zone: ["id", "name"],
          },
          {
            subzone: ["id", "name"],
          },
          {
            businessType: ["id", "name", "code"],
          },
        ],
      },
      {
        transactions: [
          "id",
          "createdAt",
          {
            cancellationReason: ["name"],
          },
          {
            shipmentStatus: ["code", "name"],
          },
        ],
      },
    ],
    variables: {
      id: {
        type: "Int",
      },
    },
  });

export const DELETE_SHIPMENT = gqlb.mutation({
  operation: "deleteShipment",
  fields: ["id"],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
export const SHIPMENT_MESSAGE = gqlb.mutation({
  operation: "createShipmentMessage",
  fields: ["id"],
  variables: {
    input: {
      type: "ShipmentMessageInput",
      required: true,
    },
  },
});

export const DELETE_LAST_SHIPMENT_ACTION = gqlb.mutation({
  operation: "deleteLastShipmentAction",
  fields: ["id"],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
export const RECEIVE_SHIPMENTS_IN_WAREHOUSE = gqlb.mutation({
  operation: "receiveShipmentsInWarehouse",
  fields: ["id"],
  variables: {
    input: {
      type: "ReceiveShipmentInWarehouseInput",
      required: true,
    },
  },
});

export const BACODE = gqlb.query({
  operation: "barcode",
  fields: [
    "id",
    "code",
    { customer: ["id", "name", "code"] },
  ],
  variables: {
    code: {
      name: "code"
    },
  },
});

