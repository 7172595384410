import React, { useEffect, useState } from "react";

import { styled } from "@mui/material/styles";

import { gql, useQuery } from "@apollo/client";
import { Clear, FilterList, Search } from "@mui/icons-material";
import {
    Box,
    Button,
    Drawer,
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableHead,
    TableRow,
    Toolbar,
    Typography,
    useTheme,
} from "@mui/material";
import clsx from "clsx";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import {
    LIST_BRANCHES_DROPDOWN,
    LIST_CUSTOMERS_DROPDOWN,
    LIST_DELIVERY_AGENTS_DROPDOWN,
    LIST_LOOKUP_ENTRIES_DROPDOWN,
    LIST_Product_DROPDOWN,
    LIST_WAREHOUSE_MANIFEST_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import useWidth from "../../Hooks/useWidth";
import { Globals } from "../HOC/Classes/Globals";
import CellLink from "../HOC/CustomComponents/CellLink";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";
import { windowUrl } from "../HOC/CustomFunctions/pushUrl";
import { urlParameters } from "../HOC/CustomFunctions/urlParameters";
import EmptyTableMessage from "../HOC/FunctionComponents/EmptyTableMessage";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import { LIST_SHIPMENTS_TRANSACTIONS, SETTINGS } from "./Graphql";
import moment from "moment";
import MUIDateRangeCustom from "../HOC/MUI/MUIDateRangeCustom";
import { MultipleAutocomplete } from "../HOC/MUI/MultipleAutocomplete";
import { Can } from "../HOC/CustomComponents/Secured";
import { TableCellColor } from "../HOC/CustomComponents/TableCellColor";
import { GetManifestPermissionSlug } from "../../helpers/getManifestPermissionSlug";

const PREFIX = "ListShipmentsTransactions";

const classes = {
    button: `${PREFIX}-button`,
    iconColor: `${PREFIX}-iconColor`,
    btnMargin: `${PREFIX}-btnMargin`,
    mainSearch: `${PREFIX}-mainSearch`,
    titles: `${PREFIX}-titles`,
    toolbar: `${PREFIX}-toolbar`,
    searchForm: `${PREFIX}-searchForm`,
    searchField: `${PREFIX}-searchField`,
    overlay: `${PREFIX}-overlay`,
    searchPadding: `${PREFIX}-searchPadding`,
    drawer: `${PREFIX}-drawer`,
    drawerPaper: `${PREFIX}-drawerPaper`,
    drawerHeader: `${PREFIX}-drawerHeader`,
    content: `${PREFIX}-content`,
    contentShift: `${PREFIX}-contentShift`,
    tableRow: `${PREFIX}-tableRow`,
    shipmentTable_shipmentStatus: `${PREFIX}-shipmentTable_shipmentStatus`,
    shipmentTable_shipmentCode: `shipmentTable_shipmentCode`,
    tablePaper: `${PREFIX}-tablePaper`,
    table_status: `${PREFIX}-table_status`,
    table_code: `${PREFIX}-table_code`,
    toolbarHeader: `${PREFIX}-toolbarHeader`,
    tableBodyRow: `${PREFIX}-tableBodyRow`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
    [`& .${classes.button}`]: {
        position: "sticky",
        bottom: 0,
        zIndex: 3,
        backgroundColor: "#fff",
        marginTop: 10,
        padding: 7,
    },

    [`& .${classes.iconColor}`]: {
        color: theme.palette.success.main,
    },

    [`& .${classes.btnMargin}`]: {
        width: "100%",
    },

    [`& .${classes.mainSearch}`]: {
        margin: theme.spacing(0),
        width: "100%",
    },

    [`& .${classes.titles}`]: {
        flex: "1 1 100%",
    },

    [`& .${classes.toolbar}`]: {
        borderBottom: "1px #ccd1d6 solid",
        backgroundColor: "#f5f7f9",
        width: "100%",
        // justifyContent: "space-between",
    },

    [`& .${classes.searchForm}`]: {
        overflowY: "auto",
        width: "100%",
        height: "100%",
        margin: 0,
    },

    [`& .${classes.searchField}`]: {
        padding: theme.spacing(1, 1, 0, 1),
        position: "relative",
        overflow: "hidden",
    },

    [`& .${classes.overlay}`]: {
        width: "100%",
        height: "100%",
        position: "absolute",
        backgroundColor: "#ffffffa8",
        zIndex: 2,
    },

    [`& .${classes.searchPadding}`]: {
        padding: theme.spacing(1),
    },

    //////////////////////Drawer///////////////////

    [`& .${classes.drawer}`]: {
        width: drawerWidth,
        flexShrink: 0,
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },

    [`& .${classes.drawerPaper}`]: {
        width: drawerWidth,

        zIndex: "250",
        height: "100%",
        left: "initial",
        right: "initial",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },

    [`& .${classes.drawerHeader}`]: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: "flex-end",
    },

    [`& .${classes.content}`]: {
        flexGrow: 1,
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: 0,
    },

    [`& .${classes.contentShift}`]: {
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: "auto",
        width: "calc(100% - 240px)",
        [theme.breakpoints.down("sm")]: {
            margin: "auto",
            width: "auto",
        },
    },

    [`& .${classes.tableRow}`]: {
        "&:hover": {
            "& .MuiTableCell-root": {
                backgroundColor: "#f5f5f5",
            },
        },
    },

    [`& .${classes.shipmentTable_shipmentStatus}`]: {
        [theme.breakpoints.up("sm")]: {
            zIndex: "100",
            backgroundColor: "#fff",
            position: "sticky",
            top: "0",
            right: "0",
        },
    },

    [`& .${classes.shipmentTable_shipmentCode}`]: {
        [theme.breakpoints.up("sm")]: {
            zIndex: "100",
            backgroundColor: "#fff",
            position: "sticky",
            top: "0",
            left: "0em",
        },
    },

    [`& .${classes.tablePaper}`]: {
        borderRadius: 0,
        width: "100%",
        display: "grid",
    },

    [`& .${classes.table_status}`]: {
        [theme.breakpoints.up("sm")]: {
            zIndex: "100",
            backgroundColor: "#fff",
            position: "sticky",
            top: "0",
            right: "0",
        },
    },

    [`& .${classes.table_code}`]: {
        [theme.breakpoints.up("sm")]: {
            zIndex: "100",
            backgroundColor: "#fff",
            position: "sticky",
            top: "0",
            left: "0",
        },
    },

    [`& .${classes.toolbarHeader}`]: {
        flex: 1,
    },

    [`& .${classes.tableBodyRow}`]: {
        "&:hover": {
            "& .MuiTableCell-root": {
                backgroundColor: "#f5f5f5",
            },
        },
    },
}));

const drawerWidth = 240;

const ShipmentsTransactions = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const urlQuery = urlParameters(window.location.search);

    const validUrlParameters = Object.keys(urlQuery).length !== 0;
    const initFromDate = urlQuery["fromDate"]
        ? urlQuery["fromDate"]
        : null;
    const initToDate = urlQuery["toDate"]
        ? urlQuery["toDate"]
        : null;
    const [dateRange, setDateRange] = useState([initFromDate, initToDate]);


    const [autocompleteValues, setAutocompleteValues] = useState({
        branch: null,
        zone: null,
        subzone: null,
        customer: null,
        product: null,
        statusCode: [],
        transactionType: [],
        warehouseManifest: null,
    });
    const screenWidth = useWidth();

    const [drawerState, setDrawerState] = React.useState({
        top: true,
        left: screenWidth === "xs" ? false : true,
        bottom: screenWidth === "xs" ? false : true,
        right: screenWidth === "xs" ? false : true,
    });

    const {
        handleSubmit,
        control,
        formState: { errors },
        setValue,
        watch,
    } = useForm();
    const fromDate = moment(dateRange[0]).locale("en").format("YYYY-MM-DD");
    const toDate = moment(dateRange[1]).locale("en").format("YYYY-MM-DD");
    const [rowsPerPage, setRowsPerPage] = useState(urlQuery["rowsPerPage"] ?? 20);
    const initSearch = () => {
        let searchParameters = {
            page: 0,
            refetch: true,
            ...(dateRange[0] && { fromDate }),
            ...(dateRange[1] && { toDate }),
        };
        delete urlQuery["rowsPerPage"];
        if (validUrlParameters) {
            console.log(urlQuery["shipmentCode"]);
            if (urlQuery["shipmentCode"]) urlQuery["shipmentCode"] = urlQuery["shipmentCode"].toString().split(',');
            if (urlQuery["name"]) urlQuery["name"] = urlQuery["name"].toString();
            if (urlQuery["statusCode"])
                urlQuery["statusCode"] = urlQuery["statusCode"].split(",");
            if (urlQuery["transactionType"])
                urlQuery["transactionType"] = urlQuery["transactionType"].split(",");
            searchParameters = urlQuery;
        }
        return {
            ...searchParameters,
        };
    };
    const [search, setSearch] = useState(initSearch());
    useEffect(() => {
        urlQuery["name"] && setValue("name", urlQuery["name"]);
        urlQuery["shipmentCode"] && setValue("shipmentCode", urlQuery["shipmentCode"]);
        setAutocompleteValues((prev) => ({
            ...prev,
            ...(urlQuery["statusCode"] && {
                statusCode: urlQuery["statusCode"],
            }),
        }));
        setAutocompleteValues((prev) => ({
            ...prev,
            ...(urlQuery["transactionType"] && {
                transactionType: urlQuery["transactionType"],
            }),
        }));
        return () => { };
    }, []);


    const { data: settings } = useQuery(
        gql`
            ${SETTINGS.query}
        `,
        {
            fetchPolicy: "no-cache",
        }
    );
    const currencyCode = settings?.shippingSettings?.localCurrency?.name ?? "";

    const history = useHistory();

    const handelAutocompleteDefaultValue = (data, id, fieldName) => {
        const defaultValue = data[Object.keys(data)[0]].find(
            (i) => i.id === parseInt(id)
        );
        defaultValue &&
            setAutocompleteValues((prev) => ({
                ...prev,
                [fieldName]: defaultValue,
            }));
    };
    const pushUrlSearch = (param) => {
        const queryParams = [];
        for (const i in param) {
            encodeURIComponent(param[i]) &&
                queryParams.push(
                    encodeURIComponent(i) + "=" + encodeURIComponent(param[i])
                );
        }
        const queryString = queryParams.join("&");

        const url = history.createHref({
            pathname: "/admin/shipments-transactions",
            search: "?" + queryString,
        });
        windowUrl(url);
        //this will not effect on history.location.search
    };

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            (event.type === "keydown" || event.type === "submit") &&
            (event.type === "submit" || event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }

        setDrawerState({ ...drawerState, [anchor]: open });
    };
    const filterAnchor = screenWidth === "xs" ? "bottom" : "left";

    const onSubmit = (data) => {
        filterAnchor === "bottom" &&
            setDrawerState({ ...drawerState, [filterAnchor]: false });
        let handledData = {
            ...(dateRange[0] && {
                fromDate,
            }),
            ...(dateRange[1] && {
                toDate,
            }),
            customerId: data.customerId,
            branchId: data.branchId,
            warehouseManifestId: data.warehouseManifestId,
            deliveryAgentId: data.deliveryAgentId,
            statusCode: data.statusCode,
            transactionType: data.transactionType,
            name: data.name,
            ...(data.shipmentCode && { shipmentCode: data.shipmentCode.split(',') }),
            page: 0,
        };

        for (const key in handledData) {
            if (
                handledData[key] === undefined ||
                handledData[key] === "" ||
                handledData[key] === null
            ) {
                delete handledData[key];
            }
        }
        setSearch((prev) => ({
            ...handledData,
            refetch: !prev.refetch,
        }));
        for (const key in handledData) {
            if (handledData[key] === null) {
                delete handledData[key];
            }
        }
        pushUrlSearch({
            ...handledData,
            rowsPerPage: rowsPerPage,
        });
    };

    const parseData = (data) => {
        return data;
    };

    const handleChangePage = (event, newPage) => {
        pushUrlSearch({
            ...urlQuery,
            page: newPage,
            rowsPerPage: rowsPerPage,
        });
        setSearch((prev) => ({ ...prev, page: newPage }));
    };

    const handleChangeRowsPerPage = (event) => {
        pushUrlSearch({
            ...urlQuery,
            page: 0,
            rowsPerPage: +event.target.value,
        });
        setRowsPerPage(+event.target.value);
        setSearch((prev) => ({ ...prev, page: 0 }));
    };

    const codeValidation = Boolean(watch("shipmentCode"));
    const user = Globals.user;
    // const canViewManifest = user?.hasPermission("shipping.manifest.list");

    const { data, loading } = useQuery(
        gql`
        ${LIST_SHIPMENTS_TRANSACTIONS.query}
        `,
        {
            notifyOnNetworkStatusChange: true,
            fetchPolicy: "no-cache",
            nextFetchPolicy: "no-cache",
            variables: {
                first: rowsPerPage,
                page: search?.page + 1,
                input: {
                    ...(search.customerId && { customerId: search.customerId }),
                    ...(search.fromDate && { fromDate: search.fromDate }),
                    ...(search.toDate && { toDate: search.toDate }),
                    ...(search.shipmentCode && { shipmentCode: search.shipmentCode }),
                    ...(search.name && { name: search.name }),
                    ...(search.customerId && { customerId: search.customerId, }),
                    ...(search.branchId && { branchId: search.branchId }),
                    ...(search.warehouseManifestId && { warehouseManifestId: search.warehouseManifestId }),
                    ...(search.deliveryAgentId && { deliveryAgentId: search.deliveryAgentId }),
                    ...(search.statusCode && { statusCode: search.statusCode }),
                    ...(search.transactionType && { transactionType: search.transactionType }),
                },
            },
            onCompleted: (data) => {
            },
        }
    );
    const ListShipmentsTransactions =
        data?.listShipmentTransactions?.data !== null ? data?.listShipmentTransactions?.data : null;

    const resetDate = () => {
        setDateRange([null, null]);
    };

    if (autocompleteValues.fromManifest) {
        autocompleteValues.fromManifest.name = autocompleteValues.fromManifest.code;
    }

    return (
        <Root>
            <Drawer
                className={clsx(classes.drawer)}
                variant="persistent"
                classes={{
                    paper: classes.drawerPaper,
                }}
                anchor={filterAnchor}
                open={drawerState[filterAnchor]}
                onClose={() => false}
                dir={theme.direction}
            >
                <div className={classes.drawerHeader} />
                <Toolbar
                    variant="dense"
                    className={classes.toolbar}
                    sx={{ marginBottom: 1 }}
                >
                    <Typography
                        className={classes.titles}
                        color="inherit"
                        variant="h6"
                        component="div"
                    >
                        {t("search")}
                    </Typography>
                    <IconButton
                        aria-label="done"
                        size="small"
                        onClick={toggleDrawer(filterAnchor, false)}
                        color={"primary"}
                    >
                        <Clear />
                    </IconButton>
                </Toolbar>

                <Grid
                    container
                    item
                    justifyContent="center"
                    alignContent="space-between"
                    component={"form"}
                    onSubmit={handleSubmit(onSubmit)}
                    className={classes.searchForm}
                >
                    <Grid
                        container
                        item
                        sm={12}
                        justifyContent="flex-start"
                        spacing={1}
                        className={classes.searchField}
                    >
                        <Grid container item sm={12} alignItems="flex-start">
                            <MUIDateRangeCustom
                                value={dateRange}
                                onChangeValue={(value) => setDateRange(value)}
                                disabled={codeValidation}
                                resetDate={resetDate}
                            />
                        </Grid>
                        <Grid container item sm={12} alignItems="flex-start">
                            <ControlMUItextField
                                control={control}
                                errors={errors}
                                name={"shipmentCode"}
                                label={t("shipmentCode")}
                            />
                        </Grid>
                        <Can showException permission={!Boolean(user.account)}>
                            <Grid container item sm={12} alignItems="flex-start">
                                <CustomAutocomplete
                                    name={"customerId"}
                                    label={t("customer")}
                                    control={control}
                                    errors={errors}
                                    onCompleted={(data) =>
                                        handelAutocompleteDefaultValue(
                                            data,
                                            urlQuery["customerId"],
                                            "customer"
                                        )
                                    }
                                    parseData={(data) => parseData(data)}
                                    query={LIST_CUSTOMERS_DROPDOWN.query}
                                    defaultValue={autocompleteValues.customer}
                                    disabled={codeValidation}
                                />
                            </Grid>
                        </Can>
                        <Grid container item sm={12} alignItems="flex-start">
                            <CustomAutocomplete
                                name={"deliveryAgentId"}
                                label={t("deliveryAgent")}
                                control={control}
                                errors={errors}
                                parseData={(data) => parseData(data)}
                                onCompleted={(data) =>
                                    handelAutocompleteDefaultValue(
                                        data,
                                        urlQuery["deliveryAgentId"],
                                        "deliveryAgent"
                                    )
                                }
                                query={LIST_DELIVERY_AGENTS_DROPDOWN.query}
                                defaultValue={autocompleteValues.product}
                            />
                        </Grid>
                        <Grid container item sm={12} alignItems="flex-start">
                            <MultipleAutocomplete
                                multiple
                                valueKey="code"
                                control={control}
                                errors={errors}
                                name={"transactionType"}
                                label={t("transactionType")}
                                parseData={(data) => parseData(data)}
                                query={LIST_LOOKUP_ENTRIES_DROPDOWN.query}
                                variables={{
                                    input: { code: "SHP_ACTION_TYPES" },
                                }}
                                onChangeValue={(e) => {
                                    setValue("warehouseManifestId", "");
                                }}
                                defaultValue={autocompleteValues.transactionType}
                            />
                        </Grid>
                        {/* <Can permission={showManifest} showException>
                            
                        </Can> */}
                        <Grid container item sm={12} alignItems="flex-start">
                            <CustomAutocomplete
                                control={control}
                                errors={errors}
                                name={"branchId"}
                                label={t("branch")}
                                onCompleted={(data) =>
                                    handelAutocompleteDefaultValue(
                                        data,
                                        urlQuery["branchId"],
                                        "branch"
                                    )
                                }
                                parseData={(data) => parseData(data)}
                                query={LIST_BRANCHES_DROPDOWN.query}
                                defaultValue={autocompleteValues.branch}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        item
                        sm={12}
                        className={clsx(classes.searchPadding, classes.button)}
                        alignItems="center"
                        alignContent="flex-end"
                        justifyContent="flex-end"
                    >
                        <Button
                            className={clsx(classes.btnMargin)}
                            variant="contained"
                            color="primary"
                            type="submit"
                            size="large"
                            startIcon={<Search />}
                            disabled={loading}
                        >
                            {t("search")}
                        </Button>
                    </Grid>
                </Grid>
            </Drawer>

            <Grid
                container
                justifyContent="center"
                spacing={3}
                className={clsx(classes.content, classes.mainSearch, {
                    [classes.contentShift]: drawerState[filterAnchor],
                })}
            >
                {/* *******Table******* */}

                <Toolbar className={clsx(classes.toolbar)} variant="dense">
                    <Typography className={classes.toolbarHeader} variant="h6">
                        {t("shipmentsTransactions")}
                    </Typography>
                    <IconButton onClick={toggleDrawer(filterAnchor, true)} size="large">
                        <FilterList fontSize="inherit" />
                    </IconButton>
                </Toolbar>

                {loading || ListShipmentsTransactions?.length === 0 ? (
                    <Grid container item justifyContent="center" className={classes.main}>
                        {loading ? (
                            <FullScreenLoading minHeight={30} />
                        ) : (
                            <EmptyTableMessage
                                loading={false}
                                message={t("searchNoResult")}
                            />
                        )}
                    </Grid>
                ) : (
                    <Paper className={classes.tablePaper}>
                        <TableFixedHeaderWraper>
                            <Table aria-labelledby="tableTitle" aria-label="enhanced table">
                                <TableHead>
                                    <TableRow>
                                        <FixedTableCell>{t("date")}</FixedTableCell>
                                        <FixedTableCell>{t("shipmentCode")}</FixedTableCell>
                                        <FixedTableCell align="left">{t("code")}</FixedTableCell>
                                        <FixedTableCell align="left">{t("status")}</FixedTableCell>
                                        <FixedTableCell align="left">{t("customer")}</FixedTableCell>
                                        <FixedTableCell align="left">{t("branch")}</FixedTableCell>
                                        <FixedTableCell align="left">
                                            {t("manifestName")}
                                        </FixedTableCell>
                                        <FixedTableCell align="left">
                                            {t("manifestCode")}
                                        </FixedTableCell>
                                        <FixedTableCell align="left">
                                            {t("shippingAgent")}
                                        </FixedTableCell>
                                        <FixedTableCell align="left">
                                            {t("transactionTypeName")}
                                        </FixedTableCell>
                                        <FixedTableCell align="left">
                                            {t("deliveryType")}
                                        </FixedTableCell>
                                        <FixedTableCell align="left">
                                            {t("deliveredAmount")}
                                        </FixedTableCell>
                                        <FixedTableCell align="left">{t("returnType")}</FixedTableCell>
                                        <FixedTableCell align="left">{t("fees")}</FixedTableCell>
                                        <FixedTableCell align="left">{t("notes")}</FixedTableCell>
                                        <FixedTableCell align="left">{t("reasonName")}</FixedTableCell>
                                        <Can
                                            permission="shipping.shipment.choose_customer"
                                            showException
                                        >
                                            <FixedTableCell align="left">{t("updatedBy")}</FixedTableCell>
                                        </Can>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {ListShipmentsTransactions.map((items, index) => {
                                        return (
                                            <TableRow key={index}>
                                                <FixedTableCell component="th" scope="row">
                                                    {items.date}
                                                </FixedTableCell>
                                                <CellLink pathname={`/admin/shipments/${items?.shipment?.id}`}>
                                                    {items?.shipment?.code}
                                                </CellLink>
                                                <FixedTableCell align="left">
                                                    {items?.shipmentStatus.code}
                                                </FixedTableCell>
                                                <TableCellColor
                                                    align="left"
                                                    code={items?.shipmentStatus.code}
                                                >
                                                    {items?.shipmentStatus.name}
                                                </TableCellColor>
                                                <CellLink pathname={`/admin/products/${items?.shipment?.customer?.id}`}>
                                                    <Box
                                                        component="span"
                                                        fontWeight="bold"
                                                    >{` (${items?.shipment?.customer?.code}) `}</Box>
                                                    <Box component="span">{items?.shipment?.customer?.name}</Box>
                                                </CellLink>
                                                <CellLink
                                                    align="left"
                                                    pathname={`/admin/branches/${items?.branch?.id}`}
                                                >
                                                    {items?.branch?.name}
                                                </CellLink>
                                                <FixedTableCell align="left">
                                                    {items?.trxType?.type?.name}
                                                </FixedTableCell>
                                                {Globals.supportedTransactionType.some(
                                                    (i) => i === items?.manifest?.transactionType?.type?.code
                                                ) && user?.hasPermission(GetManifestPermissionSlug(items?.manifest?.transactionType?.type?.code.toLowerCase() , 'list')) ? (
                                                    <CellLink
                                                        align="left"
                                                        pathname={`/admin/manifests/${items?.manifest?.id}`}
                                                    >
                                                        {items?.manifest?.code}
                                                    </CellLink>
                                                ) : (
                                                    <FixedTableCell align="left">
                                                        {items?.manifest?.code}
                                                    </FixedTableCell>
                                                )}
                                                {items?.deliveryAgent ? (
                                                    <CellLink
                                                        align="left"
                                                        pathname={`/admin/delivery-agents/${items?.deliveryAgent?.id}`}
                                                    >
                                                        {items?.deliveryAgent?.name}
                                                    </CellLink>
                                                ) : (
                                                    <FixedTableCell align="left"></FixedTableCell>
                                                )}

                                                {items?.trxType ? (
                                                    <CellLink
                                                        align="left"
                                                        pathname={`/admin/shipment-transaction-types/${items?.trxType?.id}`}
                                                    >
                                                        {items?.trxType?.name}
                                                    </CellLink>
                                                ) : (
                                                    <FixedTableCell align="left"></FixedTableCell>
                                                )}
                                                <FixedTableCell align="left">
                                                    {items?.returnType?.code === "PRTS"
                                                        ? items?.returnType?.name
                                                        : null ?? items?.deliveryType?.name}
                                                </FixedTableCell>
                                                <FixedTableCell align="left">
                                                    {items?.deliverdAmount + " " + currencyCode}
                                                </FixedTableCell>
                                                <FixedTableCell align="left">
                                                    {items?.returnType?.name}
                                                </FixedTableCell>
                                                <FixedTableCell align="left">
                                                    {items?.fees + " " + currencyCode}
                                                </FixedTableCell>
                                                <FixedTableCell align="left">
                                                    {items?.manifestShipment?.notes}
                                                </FixedTableCell>
                                                <FixedTableCell align="left">
                                                    {items?.cancellationReason?.name}
                                                </FixedTableCell>
                                                <Can
                                                    permission="shipping.shipment.choose_customer"
                                                    showException
                                                >
                                                    {items?.createdBy ? (
                                                        <CellLink
                                                            align="left"
                                                            pathname={`/admin/users/${items?.createdBy?.id}`}
                                                        >
                                                            {items?.createdBy?.username}
                                                        </CellLink>
                                                    ) : (
                                                        <FixedTableCell> </FixedTableCell>
                                                    )}
                                                </Can>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableFixedHeaderWraper>
                        <MUITablePagination
                            count={data?.listShipmentTransactions?.paginatorInfo?.total}
                            rowsPerPage={rowsPerPage}
                            page={!data?.listShipmentTransactions ? 0 : search?.page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                )}
            </Grid>
        </Root>
    );
};

export default ShipmentsTransactions;
