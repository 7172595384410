import React, { useEffect, useState } from "react";

import { styled } from "@mui/material/styles";

import { gql, useQuery } from "@apollo/client";
import { Clear, FilterList, Search } from "@mui/icons-material";
import {
    Box,
    Button,
    Drawer,
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableHead,
    TableRow,
    Toolbar,
    Typography,
    useTheme,
} from "@mui/material";
import clsx from "clsx";
// import moment from "moment";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import {
    LIST_BRANCHES_DROPDOWN,
    LIST_CUSTOMERS_DROPDOWN,
    LIST_LOOKUP_ENTRIES_DROPDOWN,
    LIST_Product_DROPDOWN,
    LIST_WAREHOUSE_MANIFEST_DROPDOWN,
    // LIST_PRICE_LIST_DROPDOWN,
    // LSIT_ZONES_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import useWidth from "../../Hooks/useWidth";
import { Globals } from "../HOC/Classes/Globals";
import CellLink from "../HOC/CustomComponents/CellLink";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
// import ShipmentRegion from "../HOC/CustomComponents/ShipmentRegion";
import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";
import { windowUrl } from "../HOC/CustomFunctions/pushUrl";
import { urlParameters } from "../HOC/CustomFunctions/urlParameters";
import EmptyTableMessage from "../HOC/FunctionComponents/EmptyTableMessage";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import { LIST_PRODUCTS_TRANSACTIONS } from "./Graphql";
import moment from "moment";
import MUIDateRangeCustom from "../HOC/MUI/MUIDateRangeCustom";
import { MultipleAutocomplete } from "../HOC/MUI/MultipleAutocomplete";
import { Can } from "../HOC/CustomComponents/Secured";

const PREFIX = "ProductList";

const classes = {
    button: `${PREFIX}-button`,
    iconColor: `${PREFIX}-iconColor`,
    btnMargin: `${PREFIX}-btnMargin`,
    mainSearch: `${PREFIX}-mainSearch`,
    titles: `${PREFIX}-titles`,
    toolbar: `${PREFIX}-toolbar`,
    searchForm: `${PREFIX}-searchForm`,
    searchField: `${PREFIX}-searchField`,
    overlay: `${PREFIX}-overlay`,
    searchPadding: `${PREFIX}-searchPadding`,
    drawer: `${PREFIX}-drawer`,
    drawerPaper: `${PREFIX}-drawerPaper`,
    drawerHeader: `${PREFIX}-drawerHeader`,
    content: `${PREFIX}-content`,
    contentShift: `${PREFIX}-contentShift`,
    tableRow: `${PREFIX}-tableRow`,
    shipmentTable_shipmentStatus: `${PREFIX}-shipmentTable_shipmentStatus`,
    shipmentTable_shipmentCode: `shipmentTable_shipmentCode`,
    tablePaper: `${PREFIX}-tablePaper`,
    table_status: `${PREFIX}-table_status`,
    table_code: `${PREFIX}-table_code`,
    toolbarHeader: `${PREFIX}-toolbarHeader`,
    tableBodyRow: `${PREFIX}-tableBodyRow`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
    [`& .${classes.button}`]: {
        position: "sticky",
        bottom: 0,
        zIndex: 3,
        backgroundColor: "#fff",
        marginTop: 10,
        padding: 7,
    },

    [`& .${classes.iconColor}`]: {
        color: theme.palette.success.main,
    },

    [`& .${classes.btnMargin}`]: {
        width: "100%",
    },

    [`& .${classes.mainSearch}`]: {
        margin: theme.spacing(0),
        width: "100%",
    },

    [`& .${classes.titles}`]: {
        flex: "1 1 100%",
    },

    [`& .${classes.toolbar}`]: {
        borderBottom: "1px #ccd1d6 solid",
        backgroundColor: "#f5f7f9",
        width: "100%",
        // justifyContent: "space-between",
    },

    [`& .${classes.searchForm}`]: {
        overflowY: "auto",
        width: "100%",
        height: "100%",
        margin: 0,
    },

    [`& .${classes.searchField}`]: {
        padding: theme.spacing(1, 1, 0, 1),
        position: "relative",
        overflow: "hidden",
    },

    [`& .${classes.overlay}`]: {
        width: "100%",
        height: "100%",
        position: "absolute",
        backgroundColor: "#ffffffa8",
        zIndex: 2,
    },

    [`& .${classes.searchPadding}`]: {
        padding: theme.spacing(1),
    },

    //////////////////////Drawer///////////////////

    [`& .${classes.drawer}`]: {
        width: drawerWidth,
        flexShrink: 0,
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },

    [`& .${classes.drawerPaper}`]: {
        width: drawerWidth,

        zIndex: "250",
        height: "100%",
        left: "initial",
        right: "initial",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },

    [`& .${classes.drawerHeader}`]: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: "flex-end",
    },

    [`& .${classes.content}`]: {
        flexGrow: 1,
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: 0,
    },

    [`& .${classes.contentShift}`]: {
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: "auto",
        width: "calc(100% - 240px)",
        [theme.breakpoints.down("sm")]: {
            margin: "auto",
            width: "auto",
        },
    },

    [`& .${classes.tableRow}`]: {
        "&:hover": {
            "& .MuiTableCell-root": {
                backgroundColor: "#f5f5f5",
            },
        },
    },

    [`& .${classes.shipmentTable_shipmentStatus}`]: {
        [theme.breakpoints.up("sm")]: {
            zIndex: "100",
            backgroundColor: "#fff",
            position: "sticky",
            top: "0",
            right: "0",
        },
    },

    [`& .${classes.shipmentTable_shipmentCode}`]: {
        [theme.breakpoints.up("sm")]: {
            zIndex: "100",
            backgroundColor: "#fff",
            position: "sticky",
            top: "0",
            left: "0em",
        },
    },

    [`& .${classes.tablePaper}`]: {
        borderRadius: 0,
        width: "100%",
        display: "grid",
    },

    [`& .${classes.table_status}`]: {
        [theme.breakpoints.up("sm")]: {
            zIndex: "100",
            backgroundColor: "#fff",
            position: "sticky",
            top: "0",
            right: "0",
        },
    },

    [`& .${classes.table_code}`]: {
        [theme.breakpoints.up("sm")]: {
            zIndex: "100",
            backgroundColor: "#fff",
            position: "sticky",
            top: "0",
            left: "0",
        },
    },

    [`& .${classes.toolbarHeader}`]: {
        flex: 1,
    },

    [`& .${classes.tableBodyRow}`]: {
        "&:hover": {
            "& .MuiTableCell-root": {
                backgroundColor: "#f5f5f5",
            },
        },
    },
}));

const drawerWidth = 240;

const ProductsTransactions = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const urlQuery = urlParameters(window.location.search);

    const validUrlParameters = Object.keys(urlQuery).length !== 0;
    const initFromDate = urlQuery["fromDate"]
        ? urlQuery["fromDate"]
        : null;
    const initToDate = urlQuery["toDate"]
        ? urlQuery["toDate"]
        : null;
    const [dateRange, setDateRange] = useState([initFromDate, initToDate]);


    const [autocompleteValues, setAutocompleteValues] = useState({
        branch: null,
        zone: null,
        subzone: null,
        customer: null,
        product: null,
        statusCode: [],
        transactionTypeCode: [],
        warehouseManifest: null,
    });
    const screenWidth = useWidth();

    const [drawerState, setDrawerState] = React.useState({
        top: true,
        left: screenWidth === "xs" ? false : true,
        bottom: screenWidth === "xs" ? false : true,
        right: screenWidth === "xs" ? false : true,
    });

    const {
        handleSubmit,
        control,
        formState: { errors },
        setValue,
        watch,
    } = useForm();
    const fromDate = moment(dateRange[0]).locale("en").format("YYYY-MM-DD");
    const toDate = moment(dateRange[1]).locale("en").format("YYYY-MM-DD");
    const [rowsPerPage, setRowsPerPage] = useState(urlQuery["rowsPerPage"] ?? 20);
    const initSearch = () => {
        let searchParameters = {
            page: 0,
            refetch: true,
            ...(dateRange[0] && { fromDate }),
            ...(dateRange[1] && { toDate }),
        };
        delete urlQuery["rowsPerPage"];
        if (validUrlParameters) {
            if (urlQuery["code"]) urlQuery["code"] = urlQuery["code"].toString();
            if (urlQuery["name"]) urlQuery["name"] = urlQuery["name"].toString();
            if (urlQuery["statusCode"])
                urlQuery["statusCode"] = urlQuery["statusCode"].split(",");
            if (urlQuery["transactionTypeCode"])
                urlQuery["transactionTypeCode"] = urlQuery["transactionTypeCode"].split(",");
            if (urlQuery["quantity"]) urlQuery["quantity"] = parseInt(urlQuery["quantity"]);
            searchParameters = urlQuery;
        }
        return {
            ...searchParameters,
        };
    };
    const [search, setSearch] = useState(initSearch());
    useEffect(() => {
        urlQuery["name"] && setValue("name", urlQuery["name"]);
        urlQuery["quantity"] && setValue("quantity", parseInt(urlQuery["quantity"]));
        urlQuery["code"] && setValue("code", urlQuery["code"]);
        setAutocompleteValues((prev) => ({
            ...prev,
            ...(urlQuery["statusCode"] && {
                statusCode: urlQuery["statusCode"],
            }),
        }));
        setAutocompleteValues((prev) => ({
            ...prev,
            ...(urlQuery["transactionTypeCode"] && {
                transactionTypeCode: urlQuery["transactionTypeCode"],
            }),
        }));
        return () => { };
    }, []);


    const history = useHistory();

    const handelAutocompleteDefaultValue = (data, id, fieldName) => {
        const defaultValue = data[Object.keys(data)[0]].find(
            (i) => i.id === parseInt(id)
        );
        defaultValue &&
            setAutocompleteValues((prev) => ({
                ...prev,
                [fieldName]: defaultValue,
            }));
    };
    const pushUrlSearch = (param) => {
        const queryParams = [];
        for (const i in param) {
            encodeURIComponent(param[i]) &&
                queryParams.push(
                    encodeURIComponent(i) + "=" + encodeURIComponent(param[i])
                );
        }
        const queryString = queryParams.join("&");

        const url = history.createHref({
            pathname: "/admin/products-transactions",
            search: "?" + queryString,
        });
        windowUrl(url);
        //this will not effect on history.location.search
    };

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            (event.type === "keydown" || event.type === "submit") &&
            (event.type === "submit" || event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }

        setDrawerState({ ...drawerState, [anchor]: open });
    };
    const filterAnchor = screenWidth === "xs" ? "bottom" : "left";

    const onSubmit = (data) => {
        filterAnchor === "bottom" &&
            setDrawerState({ ...drawerState, [filterAnchor]: false });
        let handledData = {
            ...(dateRange[0] && {
                fromDate,
            }),
            ...(dateRange[1] && {
                toDate,
            }),
            ...(data.quantity && {
                quantity: parseInt(data.quantity)
            }),
            customerId: data.customerId,
            branchId: data.branchId,
            warehouseManifestId: data.warehouseManifestId,
            productId: data.productId,
            statusCode: data.statusCode,
            transactionTypeCode: data.transactionTypeCode,
            name: data.name,
            code: data.code,
            page: 0,
        };

        for (const key in handledData) {
            if (
                handledData[key] === undefined ||
                handledData[key] === "" ||
                handledData[key] === null
            ) {
                delete handledData[key];
            }
        }
        setSearch((prev) => ({
            ...handledData,
            refetch: !prev.refetch,
        }));
        for (const key in handledData) {
            if (handledData[key] === null) {
                delete handledData[key];
            }
        }
        pushUrlSearch({
            ...handledData,
            rowsPerPage: rowsPerPage,
        });
    };

    const parseData = (data) => {
        return data;
    };

    const handleChangePage = (event, newPage) => {
        pushUrlSearch({
            ...urlQuery,

            page: newPage,
            rowsPerPage: rowsPerPage,
        });

        setSearch((prev) => ({ ...prev, page: newPage }));
    };

    const handleChangeRowsPerPage = (event) => {
        pushUrlSearch({
            ...urlQuery,
            page: 0,
            rowsPerPage: +event.target.value,
        });

        setRowsPerPage(+event.target.value);
        setSearch((prev) => ({ ...prev, page: 0 }));
    };
    const codeValidation = Boolean(watch("code"));
    const user = Globals.user;
    const showManifest = user.hasPermission(
        "shipping.warehouse_manifest.view"
    );
    // const paymentDue = user.hasPermission("shipping.payment.create");
    // const queryBody = LIST_CUSTOMERS(paymentDue).query;
    const { data, loading } = useQuery(
        gql`
        ${LIST_PRODUCTS_TRANSACTIONS.query}
        `,
        {
            notifyOnNetworkStatusChange: true,
            fetchPolicy: "no-cache",
            nextFetchPolicy: "no-cache",
            variables: {
                first: rowsPerPage,
                page: search?.page + 1,
                input: {
                    ...(search.customerId && { customerId: search.customerId }),
                    ...(search.fromDate && { fromDate: search.fromDate }),
                    ...(search.toDate && { toDate: search.toDate }),
                    ...(search.code && { code: search.code }),
                    ...(search.name && { name: search.name }),
                    ...(search.quantity && { quantity: parseInt(search.quantity) }),
                    ...(search.customerId && { customerId: search.customerId, }),
                    ...(search.branchId && { branchId: search.branchId }),
                    ...(search.warehouseManifestId && { warehouseManifestId: search.warehouseManifestId }),
                    ...(search.productId && { productId: search.productId }),
                    ...(search.statusCode && { statusCode: search.statusCode }),
                    ...(search.transactionTypeCode && { transactionTypeCode: search.transactionTypeCode }),
                },
            },
            onCompleted: (data) => {
            },
        }
    );
    const listProductTransactions =
        data?.listProductTransactions?.data !== null ? data?.listProductTransactions?.data : null;

    const resetDate = () => {
        setDateRange([null, null]);
    };

    const transactionTypeDataHandler = (data) => {
        return data && data.filter((i) => i.code === "PADD" || i.code === "PRTRN" || i.code === "PMBT" || i.code === "PMBR" || i.code === "PRTS" || i.code === "PISSUE");
    };

    const statusDataHandler = (data) => {
        return data && data.filter((i) => i.code === "TRANSACTED" || i.code === "RESERVED" || i.code === "IN_TRANSIT");
    };

    const parseDataWarehouse = (data) => {
        const parsed = data?.filter((i) => i.name = i.code);
        return parsed;
    };
    if (autocompleteValues.fromManifest) {
        autocompleteValues.fromManifest.name = autocompleteValues.fromManifest.code;
    }

    return (
        <Root>
            <Drawer
                className={clsx(classes.drawer)}
                variant="persistent"
                classes={{
                    paper: classes.drawerPaper,
                }}
                anchor={filterAnchor}
                open={drawerState[filterAnchor]}
                onClose={() => false}
                dir={theme.direction}
            >
                <div className={classes.drawerHeader} />
                <Toolbar
                    variant="dense"
                    className={classes.toolbar}
                    sx={{ marginBottom: 1 }}
                >
                    <Typography
                        className={classes.titles}
                        color="inherit"
                        variant="h6"
                        component="div"
                    >
                        {t("search")}
                    </Typography>
                    <IconButton
                        aria-label="done"
                        size="small"
                        onClick={toggleDrawer(filterAnchor, false)}
                        color={"primary"}
                    >
                        <Clear />
                    </IconButton>
                </Toolbar>

                <Grid
                    container
                    item
                    justifyContent="center"
                    alignContent="space-between"
                    component={"form"}
                    onSubmit={handleSubmit(onSubmit)}
                    className={classes.searchForm}
                >
                    <Grid
                        container
                        item
                        sm={12}
                        justifyContent="flex-start"
                        spacing={1}
                        className={classes.searchField}
                    >
                        <Grid container item sm={12} alignItems="flex-start">
                            <MUIDateRangeCustom
                                value={dateRange}
                                onChangeValue={(value) => setDateRange(value)}
                                disabled={codeValidation}
                                resetDate={resetDate}
                            />
                        </Grid>
                        <Can showException permission={!Boolean(user.account)}>
                            <Grid container item sm={12} alignItems="flex-start">
                                <CustomAutocomplete
                                    name={"customerId"}
                                    label={t("customer")}
                                    control={control}
                                    errors={errors}
                                    onCompleted={(data) =>
                                        handelAutocompleteDefaultValue(
                                            data,
                                            urlQuery["customerId"],
                                            "customer"
                                        )
                                    }
                                    parseData={(data) => parseData(data)}
                                    query={LIST_CUSTOMERS_DROPDOWN.query}
                                    defaultValue={autocompleteValues.customer}
                                    disabled={codeValidation}
                                />
                            </Grid>
                        </Can>
                        <Grid container item sm={12} alignItems="flex-start">
                            <CustomAutocomplete
                                name={"productId"}
                                label={t("product")}
                                control={control}
                                errors={errors}
                                parseData={(data) => parseData(data)}
                                onCompleted={(data) =>
                                    handelAutocompleteDefaultValue(
                                        data,
                                        urlQuery["productId"],
                                        "product"
                                    )
                                }
                                query={LIST_Product_DROPDOWN.query}
                                variables={{
                                    input: {
                                        ...(watch('customerId') && { customerId: watch('customerId') }),
                                        active: true,
                                    },
                                }}
                                defaultValue={autocompleteValues.product}
                            />
                        </Grid>
                        <Grid container item sm={12} alignItems="flex-start">
                            <MultipleAutocomplete
                                multiple
                                valueKey="code"
                                control={control}
                                errors={errors}
                                name={"statusCode"}
                                label={t("status")}
                                parseData={(data) => statusDataHandler(data)}
                                query={LIST_LOOKUP_ENTRIES_DROPDOWN.query}
                                variables={{
                                    input: { code: "STATUS_IND" },
                                }}
                                defaultValue={autocompleteValues.statusCode}
                            />
                        </Grid>
                        <Grid container item sm={12} alignItems="flex-start">
                            <MultipleAutocomplete
                                multiple
                                valueKey="code"
                                control={control}
                                errors={errors}
                                name={"transactionTypeCode"}
                                label={t("transactionType")}
                                parseData={(data) => transactionTypeDataHandler(data)}
                                query={LIST_LOOKUP_ENTRIES_DROPDOWN.query}
                                variables={{
                                    input: { code: "SHP_ACTION_TYPES" },
                                }}
                                onChangeValue={(e) => {
                                    setValue("warehouseManifestId", "");
                                }}
                                defaultValue={autocompleteValues.transactionTypeCode}
                            />
                        </Grid>
                        <Can permission={showManifest} showException>
                            <Grid container item sm={12} alignItems="flex-start">
                                <CustomAutocomplete
                                    control={control}
                                    errors={errors}
                                    hideCode={true}
                                    name={"warehouseManifestId"}
                                    label={t("manifest")}
                                    parseData={(data) => parseDataWarehouse(data)}
                                    variables={{
                                        input: {
                                            transactionTypeCode: watch('transactionTypeCode') ? watch('transactionTypeCode') : [],
                                        },
                                    }}
                                    onCompleted={(data) =>
                                        handelAutocompleteDefaultValue(
                                            data,
                                            urlQuery["warehouseManifestId"],
                                            "warehouseManifest"
                                        )
                                    }
                                    query={LIST_WAREHOUSE_MANIFEST_DROPDOWN.query}
                                    defaultValue={autocompleteValues.warehouseManifest}
                                />
                            </Grid>
                        </Can>
                        <Grid container item sm={12} alignItems="flex-start">
                            <ControlMUItextField
                                control={control}
                                errors={errors}
                                name={"quantity"}
                                label={t("quantity")}
                            />
                        </Grid>
                        <Grid container item sm={12} alignItems="flex-start">
                            <CustomAutocomplete
                                control={control}
                                errors={errors}
                                name={"branchId"}
                                label={t("branch")}
                                onCompleted={(data) =>
                                    handelAutocompleteDefaultValue(
                                        data,
                                        urlQuery["branchId"],
                                        "branch"
                                    )
                                }
                                parseData={(data) => parseData(data)}
                                query={LIST_BRANCHES_DROPDOWN.query}
                                defaultValue={autocompleteValues.branch}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        item
                        sm={12}
                        className={clsx(classes.searchPadding, classes.button)}
                        alignItems="center"
                        alignContent="flex-end"
                        justifyContent="flex-end"
                    >
                        <Button
                            className={clsx(classes.btnMargin)}
                            variant="contained"
                            color="primary"
                            type="submit"
                            size="large"
                            startIcon={<Search />}
                            disabled={loading}
                        >
                            {t("search")}
                        </Button>
                    </Grid>
                </Grid>
            </Drawer>

            <Grid
                container
                justifyContent="center"
                spacing={3}
                className={clsx(classes.content, classes.mainSearch, {
                    [classes.contentShift]: drawerState[filterAnchor],
                })}
            >
                {/* *******Table******* */}

                <Toolbar className={clsx(classes.toolbar)} variant="dense">
                    <Typography className={classes.toolbarHeader} variant="h6">
                        {t("productsTransactions")}
                    </Typography>
                    <IconButton onClick={toggleDrawer(filterAnchor, true)} size="large">
                        <FilterList fontSize="inherit" />
                    </IconButton>
                </Toolbar>

                {loading || listProductTransactions?.length === 0 ? (
                    <Grid container item justifyContent="center" className={classes.main}>
                        {loading ? (
                            <FullScreenLoading minHeight={30} />
                        ) : (
                            <EmptyTableMessage
                                loading={false}
                                message={t("searchNoResult")}
                            />
                        )}
                    </Grid>
                ) : (
                    <Paper className={classes.tablePaper}>
                        <TableFixedHeaderWraper>
                            <Table aria-labelledby="tableTitle" aria-label="enhanced table">
                                <TableHead>
                                    <TableRow>
                                        <FixedTableCell>{t("createdAt")}</FixedTableCell>
                                        <FixedTableCell>{t("product")}</FixedTableCell>
                                        <FixedTableCell>{t("quantity")}</FixedTableCell>
                                        <FixedTableCell>{t("branch")}</FixedTableCell>
                                        <FixedTableCell>{t("theShipment")}</FixedTableCell>
                                        <FixedTableCell>{t("manifest")}</FixedTableCell>
                                        <FixedTableCell>{t("transactionType")}</FixedTableCell>
                                        <FixedTableCell>{t("status")}</FixedTableCell>
                                        <FixedTableCell>{t("createdBy")}</FixedTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {listProductTransactions &&
                                        listProductTransactions?.map((row, index) => {
                                            return (
                                                <TableRow
                                                    hover
                                                    role="checkbox"
                                                    tabIndex={-1}
                                                    key={row.id}
                                                    className={classes.tableBodyRow}
                                                >
                                                    <FixedTableCell>{row?.date}</FixedTableCell>
                                                    <CellLink pathname={`/admin/products/${row?.product?.id}`}>
                                                        <Box
                                                            component="span"
                                                            fontWeight="bold"
                                                        >{` (${row?.product?.code}) `}</Box>
                                                        <Box component="span">{row?.product?.name}</Box>
                                                    </CellLink>
                                                    <FixedTableCell>{row?.quantity}</FixedTableCell>
                                                    <CellLink pathname={`/admin/branches/${row?.branch?.id}`}>
                                                        {row?.branch?.name}
                                                    </CellLink>
                                                    <CellLink pathname={`/admin/shipments/${row?.shipment?.id}`}>
                                                        {row?.shipment?.code}
                                                    </CellLink>
                                                    <CellLink pathname={`/admin/warehouse-manifests/${row?.warehouseManifest?.id}`}>
                                                        {row?.warehouseManifest?.code}
                                                    </CellLink>
                                                    <CellLink pathname={`/admin/shipment-transaction-types/${row?.transactionType?.id}`}>
                                                        {row?.transactionType?.name}
                                                    </CellLink>
                                                    {/* <FixedTableCell>{row?.productStatus?.code}</FixedTableCell> */}
                                                    <CellLink pathname={`/admin/lookup/${row?.status?.lookup?.id}`}>
                                                        {row?.status?.name}
                                                    </CellLink>
                                                    <CellLink pathname={`/admin/users/${row?.createdBy?.id}`}>
                                                        {row?.createdBy?.username}
                                                    </CellLink>
                                                </TableRow>
                                            );
                                        })}
                                    <TableRow>
                                        <FixedTableCell colSpan={1} />
                                        <FixedTableCell variant="head">{t("total")}</FixedTableCell>
                                        <FixedTableCell variant="head">
                                            {data?.sumProductTransactions?.quantity}
                                        </FixedTableCell>
                                        <FixedTableCell colSpan={3}> </FixedTableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableFixedHeaderWraper>
                        <MUITablePagination
                            count={data?.listProductTransactions?.paginatorInfo?.total}
                            rowsPerPage={rowsPerPage}
                            page={!data?.listProductTransactions ? 0 : search?.page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                )}
            </Grid>
        </Root>
    );
};

export default ProductsTransactions;
