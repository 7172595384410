import React from "react";
import { styled } from '@mui/material/styles';
import { useTranslation } from "react-i18next";
import {
  FaFacebookF,
  FaLinkedinIn,
  FaInstagram,
  FaWhatsapp,
  FaFacebookMessenger,
  FaTiktok,
  FaPhone,
} from "react-icons/fa";

import "./style.css";
import config from "../../../config.json";
import clsx from "clsx";

const PREFIX = 'FooterSection';

const classes = {
  background: `${PREFIX}-background`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.background}`]: {
    "& li a": {
      "&:hover": {
        background: theme.palette.primary.main,
        color: theme.palette.common.white,
      },
    },
  }
}));

const FooterSection = () => {
  const { t } = useTranslation("home");


  return (
    <Root id="footer" className="wpo-footer-area">
      <div className="wpo-footer-top">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <ul className={clsx(classes.background, "d-flex social")}>
              {config.socialLinks.phone && (
                  <li>
                    <a
                      rel="noopener noreferrer"
                      href={config.socialLinks.phone}
                    >
                      <FaPhone />
                    </a>
                  </li>
                )}
                {config.socialLinks.facebook && (
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={config.socialLinks.facebook}
                    >
                      <FaFacebookF />
                    </a>
                  </li>
                )}
                {config.socialLinks.messenger && (
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={config.socialLinks.messenger}
                    >
                      <FaFacebookMessenger />
                    </a>
                  </li>
                )}
                {config.socialLinks.whatsapp && (
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={config.socialLinks.whatsapp}
                    >
                      <FaWhatsapp />
                    </a>
                  </li>
                )}
                {config.socialLinks.linkedIn && (
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={config.socialLinks.linkedIn}
                    >
                      <FaLinkedinIn />
                    </a>
                  </li>
                )}
                {config.socialLinks.instgram && (
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={config.socialLinks.instgram}
                    >
                      <FaInstagram />
                    </a>
                  </li>
                )}
                {config.socialLinks.tiktok && (
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={config.socialLinks.tiktok}
                    >
                      <FaTiktok />
                    </a>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="wpo-footer-bottom-content">
            <div className="row">
              <div className="col-12">
                <div>
                  {t("home:footerCopyright")}. {t("home:poweredBy")}{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="http://accuratess.com"
                  >
                    {t("home:accurate")}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Root>
  );
};

export default FooterSection;
