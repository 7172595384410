import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Drawer,
  Grid,
  Icon,
  IconButton,
  TableRow,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import clsx from "clsx";
import React, { useState } from "react";

import { gql, useMutation } from "@apollo/client";
import { Clear, FilterList, TaskAlt } from "@mui/icons-material";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import CellLink from "../HOC/CustomComponents/CellLink";
import ShipmentRegion from "../HOC/CustomComponents/ShipmentRegion";
import { TableCellColor } from "../HOC/CustomComponents/TableCellColor";
import { windowUrl } from "../HOC/CustomFunctions/pushUrl";
import { urlParameters } from "../HOC/CustomFunctions/urlParameters";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import SearchTable, { SEARCH } from "../ShipmentSearch/SearchTable";
import { RECEIVE_SHIPMENT_IN_WAREHOUSE } from "./Graphql";

import FiltersForm from "../../Hooks/shipmentListFilters";
import useWidth from "../../Hooks/useWidth";
import HFWraper from "../../Layout/WraperHeaderFooter";
import { Globals } from "../HOC/Classes/Globals";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import { Can } from "../HOC/CustomComponents/Secured";
import ExportShipments from "../Shipments/ExportShipments";
import PrintIcon from "../Shipments/PrintIcon";
import { dateFormatLL } from "../../helpers/dateFunctions";
import { useForm } from "react-hook-form";
import { LIST_WAREHOUSE_DROPDOWN } from "../../GlobalsQuery/ListDropdown/ListDropdown";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import DeliveryActionForm from "../DeliveryListActions/DeliveryActionForm";
import { useMemo } from "react";
import { useCallback } from "react";
import { useRef } from "react";
import PrintComponent from "../Shipments/PrintComponent";

const PREFIX = "ReceiveUndeliveredShipments";

const classes = {
  button: `${PREFIX}-button`,
  btnMargin: `${PREFIX}-btnMargin`,
  mainSearch: `${PREFIX}-mainSearch`,
  titles: `${PREFIX}-titles`,
  toolbar: `${PREFIX}-toolbar`,
  searchForm: `${PREFIX}-searchForm`,
  searchField: `${PREFIX}-searchField`,
  overlay: `${PREFIX}-overlay`,
  searchPadding: `${PREFIX}-searchPadding`,
  drawer: `${PREFIX}-drawer`,
  drawerPaper: `${PREFIX}-drawerPaper`,
  content: `${PREFIX}-content`,
  contentShift: `${PREFIX}-contentShift`,
  tableRow: `${PREFIX}-tableRow`,
  shipmentTable_: `${PREFIX}-shipmentTable_`,
  bodyCheckbox: `bodyCheckbox`,
  shipmentTable_bodyCode: `shipmentTable_bodyCode`,
  shipmentTable_button: `${PREFIX}-shipmentTable_button`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.button}`]: {
    position: "sticky",
    bottom: 0,
    zIndex: 3,
    backgroundColor: "#fff",
    marginTop: 10,
    padding: 7,
  },

  [`& .${classes.btnMargin}`]: {
    width: "100%",
  },

  [`& .${classes.mainSearch}`]: {
    margin: theme.spacing(0),
    width: "100%",
  },

  [`& .${classes.titles}`]: {
    flex: "1 1 100%",
  },

  [`& .${classes.toolbar}`]: {
    borderBottom: "1px #ccd1d6 solid",
    backgroundColor: "#f5f7f9",
    width: "100%",
    justifyContent: "space-between",
  },

  [`& .${classes.searchForm}`]: {
    overflowY: "auto",
    width: "100%",
    height: "100%",
    margin: 0,
  },

  [`& .${classes.searchField}`]: {
    padding: theme.spacing(1, 1, 0, 1),
    position: "relative",
    overflow: "hidden",
  },

  [`& .${classes.overlay}`]: {
    width: "100%",
    height: "100%",
    position: "absolute",
    backgroundColor: "#ffffffa8",
    zIndex: 2,
  },

  [`& .${classes.searchPadding}`]: {
    padding: theme.spacing(1),
  },

  //////////////////////Drawer///////////////////

  [`& .${classes.drawer}`]: {
    width: drawerWidth,
    flexShrink: 0,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },

  [`& .${classes.drawerPaper}`]: {
    width: drawerWidth,

    zIndex: "250",

    left: "initial",
    right: "initial",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100%",
    },
  },

  [`& .${classes.content}`]: {
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
  },

  [`& .${classes.contentShift}`]: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: "auto",
    width: "calc(100% - 240px)",
    [theme.breakpoints.down("sm")]: {
      margin: "auto",
      width: "auto",
    },
  },

  [`& .${classes.tableRow}`]: {
    "&:hover": {
      "& .MuiTableCell-root": {
        backgroundColor: "#f5f5f5",
      },
    },
  },

  [`& .${classes.shipmentTable_}`]: {
    [theme.breakpoints.up("sm")]: {
      zIndex: "101",
      right: "0",
    },
  },

  [`& .${classes.bodyCheckbox}`]: {
    [theme.breakpoints.up("sm")]: {
      zIndex: "100",
      backgroundColor: "#fff",
      position: "sticky",
      top: 0,
      left: 0,
      minWidth: 60,
    },
  },

  [`& .${classes.shipmentTable_bodyCode}`]: {
    [theme.breakpoints.up("sm")]: {
      zIndex: "100",
      backgroundColor: "#fff",
      position: "sticky",
      top: 0,
      left: "60px",
    },
  },

  [`& .${classes.shipmentTable_button}`]: {
    [theme.breakpoints.up("sm")]: {
      zIndex: "100",
      backgroundColor: "#fff",
      position: "sticky",
      top: "0",
      right: "0",
    },
  },
}));

const drawerWidth = 240;

//*********Table Function*********

const ReceiveUndeliveredShipments = (props) => {
  const urlQuery = urlParameters(window.location.search);

  const FILTER_INIT_DATE = {
    fromDate: moment(new Date())
      .locale("en")
      .subtract(1, "month")
      .add(1, "day")
      .format("YYYY-MM-DD"),
    toDate: moment(new Date()).locale("en").format("YYYY-MM-DD"),
  };

  const [tableLoad, setTableLoad] = useState(false);

  const screenWidth = useWidth();

  const [drawerState, setDrawerState] = React.useState({
    top: true,
    left: screenWidth === "xs" ? false : true,
    bottom: screenWidth === "xs" ? false : true,
    right: screenWidth === "xs" ? false : true,
  });

  const theme = useTheme();

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [rowsPerPage, setRowsPerPage] = useState(urlQuery["rowsPerPage"] ?? 20);
  const [page, setPage] = useState(urlQuery["page"] ?? 0);
  const [dateValue, setDateValue] = useState();
  const [queryVariables, setQueryVariables] = useState({
    refetch: false,
    page: 0,
  });
  const [checkedIds, setCheckedIds] = useState([]);
  const [shipmentDetails, setShipmentDetails] = useState();

  const checkedIdsIsEmpty = checkedIds.length === 0;

  const resetPage = () => setPage(0);
  const queryVariablesWithInitValue = {
    ...(queryVariables && queryVariables),
  };
  const {
    refetch,
    page: pageNum,
    ...excelFilters
  } = queryVariablesWithInitValue;

  //autocompleteValues
  const [autocompleteValues, setAutocompleteValues] = useState({
    section: null
  });
  const selectDefaultValue = (data) => {
    return data;
  };
  const { handleSubmit, control, formState, setError, setValue } = useForm();
  const { errors } = formState;

  const [dialogState, setDialogState] = useState(false);
  const [shipmentId, setShipmentId] = useState([0]);
  const [receiveShipment, { loading, client }] = useMutation(
    gql`
      ${RECEIVE_SHIPMENT_IN_WAREHOUSE.query}
    `,
    {
      onCompleted: ((data) => {
        checkedShipmentStatus.valid && setDialogStateAction(true)
      })
    }
  );
  const handelShipmentInWarehouse = (id) => {
    checkedIds.length >= 2 && setUpdateMultiple(true)
    setCheckedIds(id);
    setShipmentId(id);
    setDialogState(true);
  };
  const handleDialogClose = () => {
    setDialogState(false);
    setAutocompleteValues({
      section: null
    })
    setValue("sectionId", "")
  };
  const queryFields = [
    "id",
    "code",
    "date",
    "senderName",
    "recipientName",
    "deliveryDate",
    "attempts",
    "returnFees",
    {
      lastDeliveryAgent: ["id", "name"],
    },
    {
      status: ["name", "code"],
    },
    {
      type: ["name"],
    },
    {
      recipientZone: ["id", "name"],
    },
    {
      recipientSubzone: ["name"],
    },
    {
      deliveryType: ["name"],
    },
    {
      branch: ["id"],
    },
  ];
  const receiveShipmentInWarehose = (data) => {
    receiveShipment({
      variables: {
        input: {
          id: shipmentId,
          ...(data.sectionId && { sectionId: data.sectionId }),
        }
      },
    })
      .then((data) => {
        handleDialogClose();
        !checkedShipmentStatus.valid && client.refetchQueries({
          include: [
            gql`
              ${SEARCH(queryFields).query}
            `,
          ],
        });
        enqueueSnackbar(t("saveSuccessful"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      })
      .catch((error) => {
        console.log(error);
        setShipmentId([0]);
        setCheckedIds([]);

        handleDialogClose();
        enqueueSnackbar(error.message, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      });
  };
  const history = useHistory();

  const pushUrlSearch = (param) => {
    const queryParams = [];
    for (const i in param) {
      encodeURIComponent(param[i]) &&
        queryParams.push(
          encodeURIComponent(i) + "=" + encodeURIComponent(param[i])
        );
    }
    const queryString = queryParams.join("&");

    const url = history.createHref({
      pathname: "/admin/receive-undelivered-shipments",
      search: "?" + queryString,
    });
    windowUrl(url);
    //this will not effect on history.location.search
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      (event.type === "keydown" || event.type === "submit") &&
      (event.type === "submit" || event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerState({ ...drawerState, [anchor]: open });
  };
  const filterAnchor = screenWidth === "xs" ? "bottom" : "left";
  const onSubmitFunc = () => {
    filterAnchor === "bottom" &&
      setDrawerState({ ...drawerState, [filterAnchor]: false });
  };

  const handleChangePage = (event, newPage) => {
    pushUrlSearch({
      ...urlQuery,
      ...dateValue,
      page: newPage,
      rowsPerPage: rowsPerPage,
    });

    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    pushUrlSearch({
      ...urlQuery,
      ...dateValue,
      page: 0,
      rowsPerPage: +event.target.value,
    });

    setRowsPerPage(+event.target.value);
    resetPage();
  };
  const clearCheckBox = () => setCheckedIds([]);

  const statusDataHandler = (data) => {
    return data && data.filter((i) => i.code === "DEX" || i.code === "HTR" || i.code === "RTS");
  };

  const brifeCells = [
    "code",
    "shipmentStatus",
    "packageType",
    "date",
    "redeliveryDate",
    "attempts",
    "deliveryAgents",
    "senderName",
    "recipient",
    "destination",
    "deliveryType",
    "",
  ];
  const shipmentList = useRef([]);

  const checkedShipmentStatus = useMemo(() => {
    let selectedShipments = shipmentList.current.filter(({ id }) =>
      checkedIds.includes(id)
    );

    checkedIds.length === 1 ? setShipmentDetails(selectedShipments[0]) : setShipmentDetails()

    const valid = selectedShipments.every(
      ({ status }) => status.code === "DEX"
    );
    return { valid, shipments: selectedShipments };
  }, [checkedIds]);

  const tableBody = useCallback((shipment, index, isChecked, toggleCheck) => {
    const user = Globals.user;
    const branchId = shipment.branch.id;
    const canReceivShipment = user.canAccessBranch(branchId);

    shipmentList.current = [...shipmentList.current, shipment];
    return (
      <TableRow className={classes.tableRow} key={index}>
        <FixedTableCell className={classes.bodyCheckbox}>
          <Checkbox
            edge="start"
            checked={isChecked(shipment.id)}
            onChange={(e) => toggleCheck(e, shipment.id)}
            disableRipple
          />
        </FixedTableCell>
        <CellLink
          className={classes.shipmentTable_bodyCode}
          pathname={`/admin/shipments/${shipment.id}`}
        >
          {shipment.code}
        </CellLink>
        <TableCellColor code={shipment?.status.code}>
          {shipment?.status.name}
        </TableCellColor>
        <FixedTableCell>{shipment.type.name}</FixedTableCell>

        <FixedTableCell>{dateFormatLL(shipment.date)}</FixedTableCell>
        <FixedTableCell>
          {shipment.deliveryDate && dateFormatLL(shipment.deliveryDate)}
        </FixedTableCell>
        <FixedTableCell>{shipment.attempts}</FixedTableCell>
        <CellLink
          pathname={`/admin/delivery-agents/${shipment.lastDeliveryAgent?.id}`}
        >
          {shipment.lastDeliveryAgent?.name}
        </CellLink>
        <FixedTableCell>{shipment.senderName}</FixedTableCell>
        <FixedTableCell>{shipment.recipientName}</FixedTableCell>
        {/* <CellLink pathname={`/admin/zone/${shipment.recipientZone.id}`}>
        {shipment.recipientZone?.name + " - " + shipment.recipientSubzone.name}
      </CellLink> */}
        <ShipmentRegion
          pathname={`/admin/zone/${shipment.recipientZone.id}`}
          zone={shipment.recipientZone?.name}
          subzone={shipment.recipientSubzone.name}
        />
        <FixedTableCell>{shipment.deliveryType?.name}</FixedTableCell>

        <FixedTableCell
          className={classes.shipmentTable_button}
          component="th"
          scope="row"
        >
          <Button
            color="primary"
            variant="outlined"
            size="small"
            disabled={!canReceivShipment}
            onClick={() => {
              handelShipmentInWarehouse([shipment.id])
              setShipmentDetails(shipment)
            }}
            startIcon={<Icon>task_alt</Icon>}
          >
            {t("receive")}
          </Button>
        </FixedTableCell>
      </TableRow>
    );
  }, []);
  const [updateMultiple, setUpdateMultiple] = useState(false);

  const [dialogStateAction, setDialogStateAction] = useState(false);
  const handleClose = () => {
    setDialogStateAction(false);
    setUpdateMultiple(false);
    setCheckedIds([]);
    setShipmentDetails();
    client.refetchQueries({
      include: [
        gql`
          ${SEARCH(queryFields).query}
        `,
      ],
    });
  };

  return (
    <Root>
      <Drawer
        className={clsx(classes.drawer)}
        variant="persistent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor={filterAnchor}
        open={drawerState[filterAnchor]}
        onClose={() => false}
        dir={theme.direction}
      >
        <HFWraper />
        <Toolbar
          variant="dense"
          className={classes.toolbar}
          sx={{ marginBottom: 1 }}
        >
          <Typography
            className={classes.titles}
            color="inherit"
            variant="h6"
            component="div"
          >
            {t("search")}
          </Typography>
          <IconButton
            aria-label="done"
            size="small"
            onClick={toggleDrawer(filterAnchor, false)}
            color={"primary"}
          >
            <Clear />
          </IconButton>
        </Toolbar>

        <FiltersForm
          {...{ onSubmitFunc, resetPage, rowsPerPage, loading: tableLoad }}
          queryVariables={(variables) =>
            setQueryVariables({
              ...variables,
              statusCode:
                (variables.statusCode && variables.statusCode.length > 0) ?
                  variables.statusCode
                  : ["DEX", "HTR", "RTS"],
              inWarehouse: false,
            })
          }
          dateValue={(date) => setDateValue(date)}
          statusDataHandler={statusDataHandler}
          initData={FILTER_INIT_DATE}
          pathname="receive-undelivered-shipments"
          filtersAllowed={[
            "date",
            "status",
            "branch",
            "originBranch",
            "businessType",
            "custm",
            "dlvAgent",
            "zone",
            "subZone",
            "refNumber",
            "service",
            "deliveryType",
            "returnType",
            "packageType",
            "paymentType",
          ]}
          clearCheckBox={clearCheckBox}
        />
      </Drawer>

      <Dialog
        open={dialogState}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"xs"}
      >
        <form onSubmit={handleSubmit(receiveShipmentInWarehose)}>
          <DialogTitle id="alert-dialog-title">
            {t("receiveShipmentInWarehouse")}
          </DialogTitle>
          <DialogContent>
            {/* <DialogContentText id="alert-dialog-description">
              {t("receiveShipmentInWarehouseConfirmation", {
                shipment: t(checkedIds.length > 1 ? "shipments" : "shipment"),
              })}
            </DialogContentText> */}
            <CustomAutocomplete
              control={control}
              errors={errors}
              name={"sectionId"}
              label={t("wareHouse")}
              parseData={(data) => selectDefaultValue(data)}
              query={LIST_WAREHOUSE_DROPDOWN.query}
              defaultValue={autocompleteValues.section}
            />
          </DialogContent>
          <DialogActions>
            {/* <Button onClick={handleDialogClose} color="primary"
              variant="contained"
            >
              {t("cancel")}
            </Button> */}
            <Button color="primary" autoFocus disabled={loading} variant="contained" type="submit">
              {loading ? <ButtonLoading /> : t("confirm")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Dialog
        open={dialogStateAction}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"xs"}
      >
        <DialogTitle>{t("updateShipmentStatus")}</DialogTitle>
        <DialogContent>
          <DeliveryActionForm
            closeDialog={handleClose}
            shipment={shipmentDetails}
            queryFields={queryFields}
            shipmentsId={checkedIds}
            updateMultiple={updateMultiple}
            listShipments={checkedShipmentStatus.shipments}
            dexShipment={checkedShipmentStatus.valid}

            filterTransactionType={["RTS", "HTR"]}
          />
        </DialogContent>
      </Dialog>
      <Grid
        container
        justifyContent="center"
        spacing={3}
        className={clsx(classes.content, classes.mainSearch, {
          [classes.contentShift]: drawerState[filterAnchor],
        })}
      >
        {/* *******Table******* */}

        <Toolbar className={classes.toolbar} variant="dense">
          <Typography variant="h6">
            {t("receiveUndeliveredShipments")}
          </Typography>

          <Box>
            <IconButton onClick={toggleDrawer(filterAnchor, true)} size="large">
              <FilterList fontSize="inherit" />
            </IconButton>
            <ExportShipments
              disabled={checkedIds.length > 0}
              filters={excelFilters}
            />

            <Can showException permission="shipping.shipment.print">
              <PrintComponent shipmentsId={checkedIds} />
            </Can>
            <IconButton
              disabled={checkedIdsIsEmpty}
              onClick={() => handelShipmentInWarehouse(checkedIds)}
              size="large"
              color="primary"
            >
              <TaskAlt fontSize="inherit" />
            </IconButton>
          </Box>
        </Toolbar>

        <SearchTable
          {...props}
          onPageChange={handleChangePage}
          onChangeRows={handleChangeRowsPerPage}
          page={page}
          rowsPerPage={rowsPerPage}
          queryVariables={queryVariablesWithInitValue}
          loading={(param) => setTableLoad(param)}
          tableHeadCell={brifeCells}
          tableBody={tableBody}
          className={classes}
          brife
          sum={false}
          notifyOnNetworkStatusChange={false}
          queryFields={queryFields}
          setCheckedIds={(ids) => setCheckedIds(ids)}
          checkedIds={checkedIds}
          withCheckAll
        />
      </Grid>
    </Root>
  );
};
export default ReceiveUndeliveredShipments;
