import React, { useEffect, useRef, useState } from "react";

import { styled } from "@mui/material/styles";

import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Icon,
  IconButton,
  Paper,
  SwipeableDrawer,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";

import { gql, useMutation, useQuery } from "@apollo/client";
import {
  AddCircleOutline,
  AttachMoney,
  Done,
  DoneAll,
  Print,
} from "@mui/icons-material";
import { Alert } from "@mui/material";
import clsx from "clsx";
import moment from "moment";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import {
  APPROVE_COLLECTION_MUTATION,
  COLLECTION_BY_ID_QUERY,
  GL_APPROVE_COLLECTION_MUTATION,
  SAVE_COLLECTION_MUTATION,
} from "./CollectionListQuary";
import ShipmentList from "./CollectionShipments/ShipmentList";
// import ControlMUItextField from '../HOC/MUI/ControlMUItextField';
import Grid from "@mui/material/Unstable_Grid2";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useHistory, withRouter } from "react-router";
import NotFound from "../../Error/NotFound";
import {
  LIST_CUSTOMERS_DROPDOWN,
  LIST_DELIVERY_AGENTS_DROPDOWN,
  LIST_SAFES_DROPDOWN,
  LIST_TRANSACTION_TYPES_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import useWidth from "../../Hooks/useWidth";
import ListBranches from "../HOC/ComponentWithSpecificQuery/ListBranches";
import ShipmentsTable from "../HOC/CustomComponents/ShipmentsTable";
import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";
import { pushUrl, windowReplaceUrl } from "../HOC/CustomFunctions/pushUrl";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import EmptyTableMessage from "../HOC/FunctionComponents/EmptyTableMessage";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import CustomButton from "../HOC/MUI/CustomButton";
// import MUIDate from "../HOC/MUI/MUIDate";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import useCollectionsDetails from "./CollectionDetails";
import BarcodeSelector from "./CollectionShipments/BarcodeSelector";
import { RootCollectionForm, classesCollectionForm } from "./CollectionStyle";
import MUIDateTime from "../HOC/MUI/MUIDateTime";

//*********Table Function*********
const EnhancedTableToolbar = (props) => {
  const { t } = useTranslation();

  const { selectedShipment, addShipment, approved, barcode } = props;

  return (
    <Toolbar className={clsx(classesCollectionForm.root)}>
      <Typography
        className={classesCollectionForm.titles}
        color="inherit"
        variant="subtitle1"
        component="div"
      >
        {t("totalShipments") + " " + selectedShipment.length}
      </Typography>

      <IconButton
        aria-label="Add"
        className={classesCollectionForm.addIcon}
        onClick={addShipment}
        disabled={approved}
        size="large"
      >
        <AddCircleOutline />
      </IconButton>
      {barcode ? (
        <IconButton
          aria-label="Add"
          onClick={barcode}
          disabled={approved}
          size="large"
        >
          <Icon>qr_code_scanner</Icon>
        </IconButton>
      ) : null}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  selectedShipment: PropTypes.array.isRequired,
};

/////////////////DIALOG///////////////
const dateFormat = (date) => moment(date).locale("en").format("YYYY-MM-DD HH:mm:ss");

const CollectionsForm = (props) => {
  const formType = props.match.params.type?.toUpperCase();
  const [collectionType, setCollectionsType] = useState(
    formType ? formType : null
  );

  const theme = useTheme();
  const [autocompleteValues, setAutocompleteValues] = useState({
    branch: null,
    customer: null,
    safe: null,
    deliveryAgent: null,
    transactionType: null,
  });

  const [disableForm, setDisableForm] = useState(false);
  const [openDialog, setOpenDialog] = useState({
    open: false,
    edit: false,
    approve: false,
    glApprove: false,
  });
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);
  const [pickedShipment, setPickedShipment] = useState({ shipments: [] });
  const collectionShipments = pickedShipment.shipments;

  const [collectionData, setCollectionData] = useState();

  const [fieldsState, setfieldsState] = useState({
    createdAt: new Date(),
  });
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const collectionId = props.match.params.id;

  const disabledFirstRender = useRef(false);

  const {
    formState: { errors },
    handleSubmit,
    setError,
    control,
    setValue,
    watch,
    clearErrors,
  } = useForm();

  /////////////////////////////////////////////////////////////////////////////////////////////////////

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const [saveCollectionMutation, { loading: saveCollectionLoad }] = useMutation(
    gql`
      ${SAVE_COLLECTION_MUTATION.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      onCompleted: (data) => {
        pickedShipment["shipments"] = collectionShipments
          .filter((i) => !i["pendingDelete"])
          .map((i) => {
            const response = data?.saveCollection.entries.find(
              (ele) => ele.shipment.id === i.shipment.id
            );
            if (response) {
              return {
                ...i,
                collectedAmount: response.collectedAmount,
                commission: response.commission
              };
            } else {
              return i;
            }
          });

        setCollectionData(data["saveCollection"]);
      },
    }
  );

  const { details: collectionDetails } = useCollectionsDetails({
    TotalData: collectionData,
    type: collectionType,
    approved: !collectionData?.approved,
    collectionShipments,
    setPickedShipment,
    saveCollectionLoad,
  });

  const [approveCollectionMutation, { loading: approveLoad }] = useMutation(
    gql`
      ${APPROVE_COLLECTION_MUTATION.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      onCompleted: (data) => {
        setCollectionData(data["approveCollection"]);
      },
    }
  );

  const [glApproveCollectionMutation, { loading: glApproveLoad }] = useMutation(
    gql`
      ${GL_APPROVE_COLLECTION_MUTATION.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      onCompleted: (data) => {
        setCollectionData(data["glApproveCollection"]);
      },
    }
  );

  useEffect(() => {
    if (disabledFirstRender.current) {
      const shipments = collectionShipments
        .filter((i) => !i["pendingDelete"])
        .map((item) => parseInt(item.shipment.id));
      const variables = watch();
      variables["date"] = dateFormat(variables["date"]);

      saveCollectionMutation({
        variables: {
          input: {
            ...variables,
            type: collectionType,
            shipmentIds: shipments,
          },
        },
      })
        .then((data) => { })
        .catch(({ graphQLErrors }) => {
          setValidationError(graphQLErrors, setError);

          console.log(graphQLErrors);
        });
    }
    disabledFirstRender.current = true;
  }, [pickedShipment]);

  const { data: collectionByIdQuery, loading: collectionByIdLoad } = useQuery(
    gql`
      ${COLLECTION_BY_ID_QUERY.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      skip: !collectionId,
      variables: {
        id: parseInt(collectionId),
      },
      onCompleted: (data) => {
        if (data?.collection === null) return;
        const collection = data?.collection;
        setCollectionsType(collection?.type?.code);

        setCollectionData(collection);
        setValue("id", parseInt(collection?.id));
        setValue("code", collection?.code);
        setValue("date", collection?.date);

        collection?.notes && setValue("notes", collection?.notes);

        setfieldsState((prev) => ({
          ...prev,
          createdAt: collection?.date,
        }));
        setAutocompleteValues({
          branch: collection.branch,
          ...(collection?.customer && {
            customer: collection?.customer,
          }),
          ...(collection?.deliveryAgent && {
            deliveryAgent: collection?.deliveryAgent,
          }),
          safe: collection.safe,
          transactionType: collection.transactionType,
        });

        pickedShipment["shipments"] = collection.entries;
        setDisableForm(true);
      },
    }
  );

  const setSelect = (obj) => {
    setPickedShipment((prev) => {
      const shipments = [...prev.shipments, ...obj];
      // fireMutaion(shipments)
      return { shipments };
    });
  };

  const [state, setState] = useState({
    shipmentList: {
      left: false,
      bottom: false,
      right: false,
    },
  });

  const [barcodeDarwerState, setBarcodeDarwerState] = useState({
    left: false,
    bottom: false,
    right: false,
  });

  const shipmentDrawerHandler = (anchor, open) => {
    setState((prev) => ({
      ...prev,
      shipmentList: { ...prev.shipmentList, [anchor]: open },
    }));
  };

  const barcodeDrawerHandler = (anchor, open) => {
    setBarcodeDarwerState((prev) => ({
      ...prev,
      [anchor]: open,
    }));
  };

  const screenWidth = useWidth();
  const anchor = screenWidth === "xs" ? "bottom" : "right";
  const shipLength = collectionShipments.length === 0;

  ////////////////////END DRAWER////////////////
  const history = useHistory();

  const onSubmit = (data) => {
    const formChange =
      collectionData &&
      ((data?.deliveryAgentId &&
        data?.deliveryAgentId !==
        parseInt(collectionData?.deliveryAgent?.id)) ||
        (data?.customerId &&
          data?.customerId !== parseInt(collectionData?.customer?.id)) ||
        (data?.branchId &&
          data?.branchId !== parseInt(collectionData?.branch?.id)));

    if (collectionShipments.length !== 0 && formChange) {
      setOpenDialog((prev) => ({ ...prev, open: true, edit: true }));
    } else {
      const shipments = collectionShipments.map((item) =>
        parseInt(item.shipment.id)
      );

      data["date"] = dateFormat(data["date"]);

      saveCollectionMutation({
        variables: {
          input: {
            ...data,
            type: collectionType,
            shipmentIds: shipments,
          },
        },
      })
        .then((data) => {
          setDisableForm(true);
          setValue("code", data?.data?.saveCollection?.code);
          setValue("id", parseInt(data?.data?.saveCollection?.id));

          const url = history.createHref({
            pathname: `/admin/collections/${data?.data?.saveCollection?.id}/edit`,
          });
          windowReplaceUrl(url);
        })
        .catch(({ graphQLErrors }) => {
          setValidationError(graphQLErrors, setError);

          console.log({ graphQLErrors });
        });
    }
  };

  const handleDialogClose = () => {
    setOpenDialog({
      edit: false,
      open: false,
      approve: false,
      glApprove: false,
    });
  };

  const approveCollection = () => {
    approveCollectionMutation({
      variables: {
        id: parseInt(watch("id")),
      },
    })
      .then((data) => {
        handleDialogClose();
        enqueueSnackbar(t("successfullyApproved"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
        pushUrl(props, `/admin/collections/${watch("id")}`);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const glApproveCollection = () => {
    glApproveCollectionMutation({
      variables: {
        id: parseInt(watch("id")),
      },
    })
      .then((data) => {
        handleDialogClose();
        enqueueSnackbar(t("successfullyGlApproved"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      })
      .catch(({ graphQLErrors }) => {
        console.log(graphQLErrors);
        handleDialogClose();

        enqueueSnackbar(graphQLErrors[0].message, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      });
  };

  const onApproved = () => {
    setOpenDialog((prev) => ({ ...prev, open: true, approve: true }));
  };
  const onGlApproved = () => {
    setOpenDialog((prev) => ({ ...prev, open: true, glApprove: true }));
  };
  // const disabledAddIcon = () => {
  //   const value = manifestType === "PKM" ? ["customerId", "branchId"] : ["deliveryAgentId", "branchId"]
  //   return !value.every(val => Object.keys(queryVariables.collection).includes(val))
  // }

  const parseData = (data) => {
    return data;
  };

  const customerField = (type) => {
    if (type === "CUSTM") {
      return (
        <Grid sm={4} xs={12}>
          <CustomAutocomplete
            control={control}
            name={"customerId"}
            label={t("customer")}
            rules={{ required: t("fieldIsRequired") }}
            parseData={(data) => parseData(data)}
            disabled={!watch("branchId")}
            query={LIST_CUSTOMERS_DROPDOWN.query}
            variables={{
              input: {
                withDue: "COLLECT",
                branchId: {
                  value: watch("branchId"),
                  includeNull: true,
                },
              },
            }}
            skip={collectionType !== "CUSTM" || !watch("branchId")}
            defaultValue={autocompleteValues.customer}
          />
        </Grid>
      );
    } else if (type === "DLVBY") {
      return (
        <Grid sm={4} xs={12}>
          <CustomAutocomplete
            control={control}
            errors={errors}
            name={"deliveryAgentId"}
            label={t("shippingAgent")}
            rules={{ required: t("fieldIsRequired") }}
            disabled={!watch("branchId")}
            onChangeValue={(e) => {
              if (e.id !== "") {
                clearErrors("deliveryAgentId");
              }
            }}
            parseData={(data) => parseData(data)}
            variables={{
              input: {
                withDue: "COLLECT",
                branchId: {
                  value: watch("branchId"),
                  includeNull: true,
                },
              },
            }}
            query={LIST_DELIVERY_AGENTS_DROPDOWN.query}
            skip={!watch("branchId") || collectionType !== "DLVBY"}
            defaultValue={autocompleteValues.deliveryAgent}
          />
        </Grid>
      );
    }
  };

  let formBody = null;
  let tableBody = null;
  /////////////////////// Customer Shipments Table /////////////////////////////

  //////////////////////////////////////////////////////////////////////////
  tableBody = (
    <Grid container xs={12} justifyContent="center" sx={{ width: "100%" }}>
      {/* *******Table******* */}
      <Paper
        sx={{ width: "100%" }}
        className={clsx(classesCollectionForm.table, classesCollectionForm.paper)}
      >
        <EnhancedTableToolbar
          {...props}
          selectedShipment={collectionShipments}
          addShipment={() => shipmentDrawerHandler(anchor, true)}
          barcode={() => barcodeDrawerHandler(anchor, true)}
          approved={collectionData?.approved}
        />

        <TableFixedHeaderWraper>
          {collectionDetails && (
            <ShipmentsTable
              page={page}
              rowsPerPage={rowsPerPage}
              data={collectionShipments}
              headCells={collectionDetails.tableHead}
              parseBodyCell={collectionDetails.tableBody}
              total={collectionDetails.tableTotal}
              sumRow={collectionDetails?.sum}
              sumTableNumber={collectionDetails?.sumTableNumber}
              sum={true}
            />
          )}
        </TableFixedHeaderWraper>
        {collectionShipments.length > 4 && (
          <MUITablePagination
            // classeName={classes.pagination}
            count={collectionShipments.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}

        {(!watch("id") || !disableForm) && (
          <Box className={classesCollectionForm.overlay}>
            <Typography color="textPrimary" variant="body1">
              {t("saveManifestFirst")}
            </Typography>
          </Box>
        )}
        <Grid container justifyContent="center" alignItems="center">
          {collectionShipments.length === 0 && watch("id") && disableForm && (
            <EmptyTableMessage loading={false} message={t("noShipments")} />
          )}
        </Grid>
      </Paper>
    </Grid>
  );

  const formCondition = !formType
    ? Boolean(watch("id")) && !collectionByIdLoad
    : !collectionByIdLoad;

  formBody = (
    <Grid
      container
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      spacing={2}
      sx={{ padding: "24px", width: "100%", margin: "0" }}
    >
      {formCondition ? (
        <Paper spacing={2} container component={Grid} sx={{ padding: 1.25 }}>
          <Grid container sm={12} xs={12} justifyContent="space-between">
            <Typography color="inherit" variant="h6" component="div">
              {collectionDetails?.label}
            </Typography>
            {!shipLength && (
              <IconButton
                disabled={collectionByIdLoad}
                onClick={() =>
                  window.open(
                    `${window.location.origin}/report/print/collection/${watch(
                      "id"
                    )}/${collectionType}`
                  )
                }
                color="default"
                size="large"
              >
                <Print fontSize="inherit" />
              </IconButton>
            )}
          </Grid>
          {watch("id") && disableForm && (
            <Grid sm={12} xs={12}>
              <Alert
                severity="warning"
                action={
                  !collectionData?.approved && (
                    <Button
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setDisableForm(false);
                        // fireMutaion([])
                      }}
                    >
                      {t("update")}
                    </Button>
                  )
                }
              >
                {collectionData?.approved
                  ? t("updateRecordsForbidden")
                  : t("updateRecord")}
              </Alert>
            </Grid>
          )}

          <Grid
            spacing={2}
            container
            sx={{ position: "relative", flexGrow: 1 }}
          >
            {(collectionData?.approved || disableForm) && (
              <Box
                className={classesCollectionForm.overlay}
                sx={{ backgroundColor: "rgb(255 255 255 / 45%)" }}
              ></Box>
            )}
            <Grid sm={4} xs={12}>
              <ControlMUItextField
                name="code"
                control={control}
                label={t("recordCode")}
                rules={{ required: watch("id") ? t("fieldIsRequired") : false }}

              // defaultValue={queryVariables?.collection?.code}
              // disabled={collectionByIdQuery?.collection?.approved || disableForm}
              />
            </Grid>
            <Grid sm={4} xs={12}>
              <MUIDateTime
                name="date"
                label={t("date")}
                control={control}
                defaultValue={collectionByIdQuery?.collection?.date}
                value={fieldsState.createdAt}
                onChange={(e) =>
                  setfieldsState((prev) => ({ ...prev, createdAt: e }))
                }
              />
            </Grid>
            <Grid sm={4} xs={12}>
              <ListBranches
                control={control}
                errors={errors}
                name={"branchId"}
                rules={{ required: t("fieldIsRequired") }}
                skip={collectionType === null}
                onChangeValue={(e) => {
                  collectionType === "DLVBY" && setValue("deliveryAgentId", "");
                  collectionType === "CUSTM" && setValue("customerId", "");
                  setValue("safeId", "");
                  // setPickedShipment({ shipments: [] })
                }}
                defaultValue={autocompleteValues.branch}
                skipDefaultBranch={collectionId}
              />
            </Grid>
            {customerField(collectionType)}
            <Grid sm={4} xs={12}>
              <CustomAutocomplete
                control={control}
                errors={errors}
                name={"transactionTypeId"}
                label={t("transactionType")}
                selectFirst={true}
                rules={{ required: t("fieldIsRequired") }}
                parseData={(data) => parseData(data)}
                query={LIST_TRANSACTION_TYPES_DROPDOWN.query}
                skip={collectionType === null}
                variables={{
                  input: {
                    type: "CSHC",
                  },
                }}
                defaultValue={autocompleteValues.transactionType}
              />
            </Grid>
            <Grid sm={4} xs={12}>
              <CustomAutocomplete
                control={control}
                errors={errors}
                name={"safeId"}
                disabled={!watch("branchId")}
                label={t("safe")}
                rules={{ required: t("fieldIsRequired") }}
                parseData={(data) => parseData(data)}
                query={LIST_SAFES_DROPDOWN.query}
                skip={!watch("branchId")}
                variables={{
                  input: {
                    branchId: watch("branchId"),
                  },
                }}
                defaultValue={autocompleteValues.safe}
              />
            </Grid>
            <Grid sm={12} xs={12}>
              <ControlMUItextField
                name="notes"
                control={control}
                label={t("notes")}
                rows={2}
              />
            </Grid>
          </Grid>

          <Grid sm={12} xs={12} container justifyContent="flex-end">
            {!disableForm && (
              <CustomButton
                customcolor={theme.palette.success.main}
                type="submit"
                className={classesCollectionForm.button}
                disabled={
                  collectionData?.approved || disableForm || saveCollectionLoad
                }
                variant="contained"
                size="medium"
                // className={classes.button}
                loading={saveCollectionLoad}
                startIcon={!saveCollectionLoad && <Done />}
              >
                {!saveCollectionLoad && t("save")}
              </CustomButton>
            )}
            {disableForm && (
              <Button
                disabled={
                  collectionShipments.length === 0 ||
                  collectionData?.approved ||
                  saveCollectionLoad
                }
                className={classesCollectionForm.button}
                variant="contained"
                size="medium"
                color="primary"
                onClick={onApproved}
                name="approved"
                startIcon={<DoneAll />}
              >
                {t("approve")}
              </Button>
            )}
            {collectionData?.approved && (
              <Button
                disabled={collectionData?.glApproved}
                className={classesCollectionForm.glApprovedButton}
                variant="contained"
                size="medium"
                onClick={onGlApproved}
                name="glApproved"
                startIcon={<AttachMoney />}
              >
                {t("glApprove")}
              </Button>
            )}
          </Grid>
        </Paper>
      ) : (
        <FullScreenLoading />
      )}
    </Grid>
  );

  return collectionByIdQuery?.collection === null ? (
    <NotFound />
  ) : (
    <RootCollectionForm>
      {props.children}

      {formBody}

      <Dialog
        open={openDialog.open}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"xs"}
      >
        <DialogTitle id="alert-dialog-title">
          {openDialog.edit && t("updateRecord")}
          {openDialog.approve && t("approve")}
          {openDialog.glApprove && t("glApprove")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {openDialog.edit && t("updateRecordMessage")}
            {openDialog.approve && t("approveRecordConfirmationMessage")}
            {openDialog.glApprove && t("glApproveRecordMessage")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            {t("cancel")}
          </Button>
          <Button
            disabled={approveLoad || glApproveLoad}
            onClick={() => {
              openDialog.edit && handleDialogClose();
              openDialog.edit && setDisableForm(true);
              openDialog.edit && setPickedShipment({ shipments: [] });
              openDialog.approve && approveCollection();
              openDialog.glApprove && glApproveCollection();
            }}
            color="primary"
            autoFocus
          >
            {openDialog.edit && t("update")}
            {openDialog.approve && !approveLoad && t("approve")}
            {openDialog.glApprove && !glApproveLoad && t("glApprove")}
            {(approveLoad || glApproveLoad) && <ButtonLoading />}
          </Button>
        </DialogActions>
      </Dialog>

      <SwipeableDrawer
        variant="persistent"
        disableDiscovery={true}
        disableSwipeToOpen={true}
        anchor={anchor}
        open={state.shipmentList[anchor]}
        onClose={() => false}
        onOpen={() => shipmentDrawerHandler(anchor, true)}
        classes={{ paper: classesCollectionForm.shipmentList }}
        dir={theme.direction}
      >
        {state.shipmentList[anchor] && (
          <ShipmentList
            setSelected={(obj) => {
              setSelect(obj);
            }}
            type={collectionType}
            selected={collectionShipments}
            done={() => shipmentDrawerHandler(anchor, false)}
            variables={watch()}
          />
        )}
      </SwipeableDrawer>

      <SwipeableDrawer
        disableDiscovery={true}
        disableSwipeToOpen={true}
        anchor={anchor}
        open={barcodeDarwerState[anchor]}
        onClose={() => false}
        onOpen={() => barcodeDrawerHandler(anchor, true)}
        sx={{
          width: "auto",
          "& .MuiDrawer-paper": {
            width: { md: "50%" },

            height: "100%",
            overflow: "hidden",
          },
        }}
        dir={theme.direction}
      >
        {barcodeDarwerState[anchor] && (<BarcodeSelector
          setSelected={(obj) => {
            setSelect(obj);
          }}
          type={collectionType}
          selected={collectionShipments}
          done={() => barcodeDrawerHandler(anchor, false)}
          variables={watch()}
        />)}
      </SwipeableDrawer>

      <Grid
        container
        spacing={3}
        justifyContent="center"
        className={classesCollectionForm.mainTracking}
      >
        {formCondition && tableBody}
      </Grid>
    </RootCollectionForm>
  );
};

export default withRouter(CollectionsForm);
