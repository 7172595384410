import React, { Fragment } from "react";
import { styled } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import KeyIcon from '@mui/icons-material/Key';
import DoneIcon from '@mui/icons-material/Done';
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import MUItextField from "../HOC/MUI/MUItextField";
import { useForm } from "react-hook-form";
import {
    Alert,
    Collapse
} from "@mui/material";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import * as gqlb from "gql-query-builder";
import { useTranslation } from "react-i18next";
import CustomSpinner from "../HOC/FunctionComponents/CustomSpinner";
import { useSnackbar } from "notistack";
import { Globals } from "../HOC/Classes/Globals";
import moment from "moment";
import { useState } from "react";
import Header from "../../Layout/Header";
import clsx from "clsx";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import { pushUrl, replaceUrl } from "../HOC/CustomFunctions/pushUrl";
import { useEffect } from "react";
import SpanLink from "../HOC/CustomComponents/SpanLink";

const PREFIX = "Login";

const classes = {
    paper: `${PREFIX}-paper`,
    avatar: `${PREFIX}-avatar`,
    form: `${PREFIX}-form`,
    submit: `${PREFIX}-submit`,
    button: `${PREFIX}-button`,
    spinner: `${PREFIX}-spinner`,
    divider: `${PREFIX}-divider`,
    inestancePage: `${PREFIX}-inestancePage`,
    companyCodeRoot: `${PREFIX}-companyCodeRoot`,
    companyCode: `${PREFIX}-companyCode`,
    signup: `${PREFIX}-signup`,
    textField: `${PREFIX}-textField`,
    root: `${PREFIX}-root`,
    login: `${PREFIX}-login`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({

    [`&.${classes.root}`]: {
        height: "100vh",
        display: "flex",
        alignItems: "center",
    },
    [`& .${classes.paper}`]: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: theme.spacing(3, 0)
    },

    [`& .${classes.avatar}`]: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },

    [`& .${classes.form}`]: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },

    [`& .${classes.submit}`]: {
        margin: theme.spacing(3, 0, 2),
    },

    [`& .${classes.button}`]: {
        marginBottom: theme.spacing(2),
    },

    [`& .${classes.spinner}`]: {
        width: 24,
        margin: theme.spacing(0, 1),
    },

    [`& .${classes.divider}`]: {
        backgroundColor: theme.palette.action.active,
        marginTop: theme.spacing(1),
    },

    [`& .${classes.inestancePage}`]: {
        position: "absolute",
        width: "100%",
        height: "100%",
        zIndex: 1000,
        backgroundColor: "#fff",
    },
    [`& .${classes.textField}`]: {
        marginBottom: theme.spacing(2)
    },

    [`& .${classes.companyCodeRoot}`]: { marginTop: theme.spacing(2) },
    [`& .${classes.companyCode}`]: { marginRight: theme.spacing(1) },
    [`& .${classes.signup}`]: { marginLeft: theme.spacing(1), textTransform: 'capitalize' },
    [`& .${classes.login}`]: {
        marginLeft: theme.spacing(1),
        textTransform: 'capitalize'
    }
}));

export const RENEW_SUBSCRIPTION = gqlb.mutation({
    operation: "renewSubscription",
    fields: ["renewalDate"],
    variables: {
        key: {
            type: "String",
        },
    },
});

export default function Renewal(props) {
    const { t } = useTranslation();
    const { closeDialog, removeMsg } = props
    const subscriptionExpired = JSON.parse(localStorage.getItem("subscriptionExpired"))
    const token = localStorage.getItem("token")
    const isAdmin = subscriptionExpired?.code ? subscriptionExpired?.code === 'SUBSCRIPTION_EXPIRED_RENEW' : !Globals?.user?.account

    useEffect(() => {
        if (!subscriptionExpired && !closeDialog) {
            replaceUrl(props, "/admin");
        }
        return () => { };
    }, []);
    const { enqueueSnackbar } = useSnackbar();
    const {
        handleSubmit,
        register,
        formState,
        setError
    } = useForm();
    const { errors } = formState;

    const [renewSubscription, { loading: renewSubscriptionLoading }] = useMutation(
        gql`
            ${RENEW_SUBSCRIPTION.query}
        `
    );
    const onSubmitInstance = (data) => {
        renewSubscription({
            variables: {
                key: data.key
            },
        })
            .then((data) => {
                localStorage.removeItem("subscriptionExpired")
                !closeDialog && pushUrl(props, `/admin`);
                enqueueSnackbar(t("renewalSuccessful"), {
                    variant: "success",
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    TransitionComponent: Collapse,
                });
                closeDialog && closeDialog()
                removeMsg && removeMsg(false)
            })
            .catch(({ graphQLErrors }) => {
                setError('key', { type: 'custom', message: graphQLErrors?.[0].message });
            });
    };

    return (
        <Root className={clsx(classes.appBar, {
            [classes.root]: subscriptionExpired,
        })}>
            <Container maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <KeyIcon />
                    </Avatar>
                    <Typography sx={{ textTransform: 'capitalize' }} component="h1" variant="h5">
                        {isAdmin ?
                            t("renewSubscription")
                            : t("systemIsDown")}
                    </Typography>
                    {subscriptionExpired && <Alert sx={{ width: "100%", mt: 2 }} severity="error">{subscriptionExpired.message}</Alert>}
                    <Fragment>
                        {token ?
                            isAdmin && <form className={classes.form} onSubmit={handleSubmit(onSubmitInstance)}>
                                <MUItextField
                                    name={"key"}
                                    label={t("renewalCode")}
                                    register={register}
                                    errors={errors}
                                    margin="normal"
                                    className={classes.textField}
                                />
                                <Grid item container justifyContent="center">
                                    <Button
                                        fullWidth
                                        className={classes.button}
                                        size="large"
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        disabled={renewSubscriptionLoading}
                                        startIcon={
                                            renewSubscriptionLoading ? (
                                                <CustomSpinner
                                                    size={8}
                                                    css={{ fontSize: "10px !important" }}
                                                    name={"PulseLoader"}
                                                />
                                            ) : (
                                                <DoneIcon />
                                            )
                                        }
                                    >
                                        {t("confirm")}
                                    </Button>
                                </Grid>
                            </form> :
                            <Grid item container justifyContent="start" fontSize={16} mt={2}>
                                {t("alreadyhaveaccount")}
                                <SpanLink className={classes.login} pathname={`/login`}>
                                    {t("login")}
                                </SpanLink>
                            </Grid>
                        }
                    </Fragment>
                </div>
            </Container>
        </Root>
    );
}
