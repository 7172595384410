import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect } from "react";

import { gql, useQuery } from "@apollo/client";
import clsx from "clsx";
import moment from "moment";
import { useTranslation } from "react-i18next";
import logo from "../../assets/Image/logo.png";
import NotFound from "../../Error/NotFound";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";

import * as gqlb from "gql-query-builder";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import { urlParameters } from "../HOC/CustomFunctions/urlParameters";
import CheckCrossIcons from "../HOC/FunctionComponents/CheckCrossIcons";
import "../ManifestPrint/print.css";
import { dateFormatLL } from "../../helpers/dateFunctions";

const PREFIX = "PaymentPrint";

const classes = {
  headerCells: `${PREFIX}-headerCells`,
  title: `${PREFIX}-title`,
  notes: `${PREFIX}-notes`,
  noteCell: `${PREFIX}-noteCell`,
  barcodeFont: `${PREFIX}-barcodeFont`,
  cairoFont: `${PREFIX}-cairoFont`,
  barcode: `${PREFIX}-barcode`,
  headerTableCell: `${PREFIX}-headerTableCell`,
  mainCellBorder: `${PREFIX}-mainCellBorder`,
  signature: `${PREFIX}-signature`,
  reportHeader: `${PREFIX}-reportHeader`,
  hiddenFooter: `${PREFIX}-hiddenFooter`,
  pageFooter: `${PREFIX}-pageFooter`,
  logoSection: `${PREFIX}-logoSection`,
  headerDate: `${PREFIX}-headerDate`,
  whiteSpace: `${PREFIX}-whiteSpace`,
  approve: `${PREFIX}-approve`,
  rowWithoutBorder: `${PREFIX}-rowWithoutBorder`,
  mainDetailsCell: `${PREFIX}-mainDetailsCell`,
  tableHeadCell: `${PREFIX}-tableHeadCell`,
  tableWidth: `${PREFIX}-tableWidth`,
  shipmentsTable: `${PREFIX}-shipmentsTable`,
  signatureBlock: `${PREFIX}-signatureBlock`,
};

const Root = styled("div")(({ theme }) => ({
  fontFamily: ['"Cairo"', "sans-serif"].join(","),
  [`& .${classes.headerCells}`]: {
    paddingBottom: theme.spacing(1),
  },

  [`& .${classes.title}`]: {
    color: theme.palette.info.main,
    // whiteS;pace: "break-spaces",
  },

  [`& .${classes.notes}`]: {
    padding: theme.spacing(1),
    border: "1px solid " + theme.palette.action.disabled,
    wordBreak: "break-all",
    width: "100%",
    minHeight: 80,
  },

  [`& .${classes.noteCell}`]: {
    width: "30%",
    overflow: "hidden",
  },

  [`& .${classes.barcodeFont}`]: {
    fontFamily: '"Libre Barcode 39 Text"',
  },

  [`& .${classes.cairoFont}`]: {
    fontFamily: ['"Cairo"', "sans-serif"].join(","),
  },

  [`& .${classes.barcode}`]: {
    fontSize: 40,
    textAlign: "center",
  },

  [`& .${classes.headerTableCell}`]: {
    border: "none",
    padding: 0,
  },

  [`& .${classes.mainCellBorder}`]: {
    border: "none",
    padding: 0,
  },

  [`& .${classes.signature}`]: {
    textAlign: "center",
    padding: theme.spacing(2, 1, 1, 1),
    border: "none",
  },

  [`& .${classes.reportHeader}`]: {
    whiteSpace: "pre-line",
    padding: theme.spacing(0, 2),
    lineHeight: "120%",
    fontSize: 12,
  },

  [`& .${classes.hiddenFooter}`]: {
    visibility: "hidden",
  },

  [`& .${classes.pageFooter}`]: {
    //   "&::after": {
    //    " content:  counter(page)  of  counter(pages)"
    //   },
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    borderTop: "1px solid " + theme.palette.text.secondary,
    display: "flex",
    alignItems: "flex-end",
  },

  [`& .${classes.logoSection}`]: {
    display: "flex",
    alignItems: "center",
  },

  [`& .${classes.headerDate}`]: {
    textAlign: "end",
  },

  [`& .${classes.whiteSpace}`]: {
    "& tr": {
      "& td": {
        whiteSpace: "initial",
      },
    },
  },

  [`& .${classes.approve}`]: {
    textAlign: "end",
  },

  [`& .${classes.rowWithoutBorder}`]: {
    "& td": {
      border: "none",
      padding: 0,
    },
  },

  [`& .${classes.mainDetailsCell}`]: {
    "& td": { border: "none", verticalAlign: "top" },
    "& td:first-of-type": { paddingLeft: 0 },
    "& td:last-of-type": { paddingRight: 0 },

    border: "none",
    padding: theme.spacing(1, 0),
  },

  [`& .${classes.tableHeadCell}`]: {
    padding: 0,
  },

  [`& .${classes.tableWidth}`]: {
    width: "100vw",
  },

  [`& .${classes.shipmentsTable}`]: {
    "& tr": {
      "&:hover": {
        backgroundColor: "#ffffff00 !important",
      },
    },
    "& td , & th": {
      padding: theme.spacing(1),
    },
  },

  [`& .${classes.signatureBlock}`]: {
    breakInside: "avoid",
  },
}));

export {};

const org = {
  operation: "organization",
  fields: [
    "name",
    "phone",
    "email",
    "website",
    "reportHeader",
    "reportFooter",
    "registrationNumber",
    "taxNumber",
  ],
  variables: {},
};

const paymentsSum = {
  operation: "sumPayments",
  fields: ["paymentAmount","dueFees"],
  variables: {
    input: {
      type: "ListPaymentFilterInput",
      required: true,
    },
  },
};
const paymentsListFields = [
  {
    data: [
      "code",
      "id",
      "date",
      "paymentAmount",
      "dueFees",
      {
        deliveryAgent: ["id", "name", "code"],
      },
      {
        safe: ["id", "name"],
      },
      {
        customer: ["id", "name", "code"],
      },
    ],
  },
];

const PAGE_COUNT = gqlb.query({
  operation: "listPayments",
  fields: [{ paginatorInfo: ["lastPage"] }],
  variables: {
    input: {
      type: "ListPaymentFilterInput",
      required: true,
    },
    first: {
      type: "Int",
    },
  },
});

const PaymentPrint = (props) => {
  const urlQuery = urlParameters(window.location.search);

  const stringValues = ["code", "fromCode", "toCode"];
  stringValues.forEach((i) => {
    if (urlQuery[i]) urlQuery[i] = String(urlQuery[i]);
  });

  const { data: pageCount, loading: pageLoad } = useQuery(
    gql`
      ${PAGE_COUNT.query}
    `,
    {
      first: 100,
      variables: {
        input: urlQuery,
        first: 100,
      },
    }
  );
  const pages = pageCount?.listPayments?.paginatorInfo?.lastPage;

  const queryBuilder = () => {
    const queryNumber = Math.ceil(pages / 100);
    const queryArray = [org, paymentsSum];
    for (let index = 0; index < queryNumber; index++) {
      const initialQuery = {
        operation: `listPayments${index}:listPayments`,
        fields: paymentsListFields,
        variables: {
          input: {
            type: "ListPaymentFilterInput",
            required: true,
          },
          first: {
            type: "Int",
          },
          ["page" + index]: {
            type: "Int",
            name: "page",
          },
        },
      };
      queryArray.push(initialQuery);
    }
    return queryArray;
  };

  const queryBody = queryBuilder();
  const PAYMENTS_PRINT = gqlb.query(queryBody);

  const { t } = useTranslation();

  let variables = {
    input: urlQuery,
    first: 100,
  };
  for (let index = 0; index < pages; index++) {
    variables["page" + index] = index + 1;
  }

  const { data: queryData, loading } = useQuery(
    gql`
      ${PAYMENTS_PRINT.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      skip: !pages,
      variables,
      onError: () => {
        return;
      },
    }
  );

  let parsedData = [];
  if (queryData?.listPayments0) {
    let concatData = [];
    for (const key in queryData) {
      if (key.startsWith("list")) {
        const listData = queryData[key].data;
        concatData = concatData.concat(listData);
      }
    }
    parsedData = concatData;
  }

  useEffect(() => {
    if (parsedData.length > 0) {
      window.print();
    }
    return () => {};
  }, [queryData]);

  const type = urlQuery?.type;
  const paymentTitles = {
    DLVBY: t("deliveryAgentPayment"),
    CUSTM: t("customerPayment"),
  };

  const sumPayments =
    queryData?.sumPayments !== null ? queryData?.sumPayments : null;
  const reportDetails = parsedData.length > 0;
  const header = !loading && !pageLoad && reportDetails && (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell padding="none">
            <Table>
              <TableBody>
                <TableRow className={classes.rowWithoutBorder}>
                  <TableCell>
                    <Box className={classes.logoSection}>
                      <img src={logo} alt="logo" height={30} />
                      <Typography
                        className={clsx(classes.reportHeader, "landscape-zoom")}
                      >
                        {queryData.organization.reportHeader}
                      </Typography>
                    </Box>
                  </TableCell>

                  <TableCell className={clsx(classes.headerDate)}>
                    <Typography variant="h6">
                      {moment(new Date()).format("ll")}
                    </Typography>
                    <TypographyKeyValue
                      title={t("registrationNumber")}
                      value={queryData.organization?.registrationNumber}
                      hidden={!queryData.organization?.registrationNumber}
                    />
                    <TypographyKeyValue
                      title={t("taxNumber")}
                      value={queryData.organization?.taxNumber}
                      hidden={!queryData.organization?.taxNumber}
                    />
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell className={classes.headerTableCell}>
                    <Table className={classes.whiteSpace}>
                      <TableBody>
                        <TableRow className={classes.rowWithoutBorder}>
                          <TableCell>
                            <Typography variant="h5">
                              {paymentTitles[type]}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <TypographyKeyValue
                              title={t("fromDate")}
                              value={urlQuery?.fromDate && moment(urlQuery?.fromDate).format("ll")}
                            />
                            <TypographyKeyValue
                              title={t("toDate")}
                              value={urlQuery?.toDate && moment(urlQuery?.toDate).format("ll")}
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
  const table = !loading && !pageLoad && reportDetails !== null && (
    <Table id="manifestPrintTable">
      <EnhancedTableHead type={type} />

      <TableBody>
        {parsedData.length > 0 &&
          parsedData?.map((row, index) => {
            return (
              <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                <FixedTableCell component="th" scope="row">
                  {row.code}
                </FixedTableCell>
                <FixedTableCell>{dateFormatLL(row?.date)}</FixedTableCell>
                {row?.deliveryAgent || row?.customer ? (
                  <FixedTableCell>
                    {type === "DLVBY"
                      ? row?.deliveryAgent?.name
                      : row?.customer?.name}
                  </FixedTableCell>
                ) : (
                  <FixedTableCell> </FixedTableCell>
                )}

                <FixedTableCell>{row?.safe?.name}</FixedTableCell>
                {type !== "DLVBY" && (
                  <FixedTableCell>{row?.dueFees}</FixedTableCell>
                )}
                <FixedTableCell>{row?.paymentAmount}</FixedTableCell>
              </TableRow>
            );
          })}
        <TableRow>
          <FixedTableCell colSpan={3} />
          <FixedTableCell variant="head">{t("total")}</FixedTableCell>
          {type !== "DLVBY" && (
            <FixedTableCell>{sumPayments?.dueFees}</FixedTableCell>
          )}
          <FixedTableCell variant="head">
            {sumPayments?.paymentAmount}
          </FixedTableCell>
        </TableRow>
      </TableBody>
    </Table>
  );

  return (
    <Root>
      {loading || pageLoad ? (
        <>
          <FullScreenLoading minHeight="20%" />
          {/* this spans for download font before print */}
          <span className={clsx(classes.barcodeFont)} />
          <span style={{ opacity: 0 }} className={classes.cairoFont}>
            lا
          </span>
        </>
      ) : reportDetails === null ? (
        <NotFound />
      ) : (
        <>
          <div>
            <div className="page-header">{header}</div>

            <div className="page-footer">
              {queryData.organization.reportFooter}
            </div>

            <table style={{ width: "100%" }}>
              <thead>
                <tr>
                  <td>
                    <div className="page-header-space">{header}</div>
                  </td>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>
                    <div
                      className={clsx(classes.shipmentsTable, {
                        // [classes.tableWidth]: tableZoom,
                      })}
                    >
                      {table}
                    </div>
                  </td>
                </tr>
              </tbody>

              <tfoot>
                <tr>
                  <td>
                    <div className="page-footer-space">
                      {queryData.organization.reportFooter}
                    </div>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </>
      )}
    </Root>
  );

  function TypographyKeyValue(props) {
    const { title, value, hidden, ...restProps } = props;
    return hidden ? null : (
      <Typography variant="body1" paddingBottom={1} {...restProps}>
        <span className={classes.title}>{title}:</span> <span>{value}</span>
      </Typography>
    );
  }
};

function EnhancedTableHead(props) {
  //*********Table Function*********
  const { type } = props;
  const { t } = useTranslation();
  const headCells = [
    { numeric: true, disablePadding: false, label: t("recordCode") },
    { numeric: true, disablePadding: false, label: t("paymentDate") },
    {
      numeric: true,
      disablePadding: false,
      label: t(type === "DLVBY" ? "shippingAgent" : "customer"),
    },
    { numeric: true, disablePadding: false, label: t("safe") },
    { numeric: true, disablePadding: false, label: t("value") },
  ];
  if (type === "CUSTM") {
    const referenceBranch = {
      id: "5",
      numeric: true,
      disablePadding: false,
      label: t("returningDueFees"),
    };
    headCells.splice(4, 0, referenceBranch);
  }
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => {
          return (
            <FixedTableCell
              key={index}
              padding={headCell.disablePadding ? "none" : "normal"}
            >
              {headCell.label}
            </FixedTableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

export default PaymentPrint;
