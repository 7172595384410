import React, { Fragment, useEffect } from "react";
import { styled } from '@mui/material/styles';
import clsx from "clsx";
import {
  Paper,
  Typography,
  Collapse,
  Box,
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Slide,
  Table,
  TableHead,
  TableRow,
  TableBody,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { SAVE_CUSTOMER, CUSTOMERID, SETTINGS } from "./Graphql";
import LookupDropdown from "../HOC/CustomComponents/LookupDropdown";
import { useMutation, gql, useQuery } from "@apollo/client";
import { lookupCodeIsValid } from "../HOC/CustomFunctions/lookupCodeIsValid";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { useState } from "react";
import FormButton from "../CustomComponents/Buttons/FormButton";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import { useSnackbar } from "notistack";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import ListBranches from "../HOC/ComponentWithSpecificQuery/ListBranches";
import {
  LIST_BANK_DROPDOWN,
  LIST_COUNTRIES_DROPDOWN,
  LIST_GL_ACCOUNTS_DROPDOWN,
  LIST_PAYMENT_TYPES_DROPDOWN,
  LIST_PRICE_LIST_DROPDOWN,
  LSIT_ZONES_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import MuiSwitch from "../HOC/MUI/MUIswitch";
import MUIselect from "../HOC/MUI/MUIselect";
import CustomDialog from "../HOC/CustomComponents/CustomDialog";
import { AddCircleOutline, Close } from "@mui/icons-material";
import PriceListForm from "../PriceList/CreatePriceList";
import { Can } from "../HOC/CustomComponents/Secured";
import Grid from "@mui/material/Unstable_Grid2";
import { selectedIdIsValid } from "../HOC/CustomFunctions/selectedIsValid";
import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { GetCustomerPermissionSlug } from "../../helpers/getManifestPermissionSlug";
import { Globals } from "../HOC/Classes/Globals";

const PREFIX = 'CustomerForm';

const classes = {
  spacing: `${PREFIX}-spacing`,
  mainGrid: `${PREFIX}-mainGrid`,
  appBar: `${PREFIX}-appBar`,
  priceList: `${PREFIX}-priceList`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.spacing}`]: {
    width: "100%",
    margin: theme.spacing(2, 0, 0, 0),
  },

  [`& .${classes.mainGrid}`]: {
    width: "100%",
    margin: theme.spacing(0),
    padding: theme.spacing(2),
  },

  [`& .${classes.appBar}`]: {
    position: "relative",
  },

  [`& .${classes.priceList}`]: {
    flexWrap: "nowrap",
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const CustomerForm = (props) => {
  const [autocompleteValues, setAutocompleteValues] = useState({
    branch: null,
    glAccount: null,
    zone: null,
    subzone: null,
    priceList: null,
    bank: null,
    country: null,
    state: null,
    city: null,
    area: null,
  });
  const [priceListDialogState, setPriceListDialogState] = useState(false);

  const GlobalSettings = Globals.settings

  const { t } = useTranslation();

  const formName = {
    B2C: t("createCustomer"),
    C2C: t("createIndividual"),
  };

  const { enqueueSnackbar } = useSnackbar();

  const {
    handleSubmit,
    control,
    formState,
    watch,
    setValue,
    getValues,
    setError,
  } = useForm({
    defaultValues: {
      active: true,
      specifyShipmentCode: false,
      taxable: false,
      shareCollectedFees: true,
      payUncollected: false,
      hideWaybillMobile: false,
    },
  });
  const { errors } = formState;

  const selectDefaultValue = (data) => {
    return data;
  };

  const closePriceListDialog = () => {
    setPriceListDialogState(false);
  };
  const customerId = parseInt(props.match.params.id);
  const formType = props?.match?.params?.type?.toUpperCase();
  const [customerType, setcustomerType] = useState(formType ? formType : null)

  const { data: allPaymentTypes } = useQuery(
    gql`
      ${LIST_PAYMENT_TYPES_DROPDOWN.query}
    `,
    {
      nextFetchPolicy: "no-cache",
      fetchPolicy: "no-cache",
      variables: {},
      skip: customerId,
      onCompleted: (data) => {
        const paymentTypes = data?.listPaymentTypesDropdown
        setPaymentTypes(paymentTypes);
        paymentTypes.forEach((row) => {
          customerId ? setValue(`${row.code}`, false) : setValue(`${row.code}`, true);
        })
      },
    }
  );
  const { data: settings } = useQuery(
    gql`
      ${SETTINGS.query}
    `,
    {
      fetchPolicy: "no-cache",
    }
  );
  const { data: updateCustomer } = useQuery(
    gql`
      ${CUSTOMERID.query}
    `,
    {
      skip: !customerId,
      notifyOnNetworkStatusChange: true,
      variables: { id: customerId },
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        const customerData = data.customer;
        setcustomerType(customerData.businessType.code)
        const customerPaymentTypes = data?.customer?.paymentTypes;
        const paymentCode = [];
        customerPaymentTypes.map(ele => paymentCode.push(ele.code))
        const notSelect = data.listPaymentTypesDropdown.filter(ele => !paymentCode.includes(ele.code))
        setPaymentTypes([...customerPaymentTypes, ...notSelect]);
        customerPaymentTypes.forEach((row) => {
          setValue(`${row.code}`, true);
        })

        const customerParams = [
          "id",
          "code",
          "name",
          "address",
          "phone",
          "mobile",
          "postalCode",
          "mailBox",
          "email",
          "vodafoneCash",
          "bankAccount",
          "representative",
          "activityName",
        ];
        customerParams.forEach((i) => {
          customerData[i] && setValue(i, customerData[i]);
        });
        setValue("active", customerData["active"]);
        setValue("shareCollectedFees", customerData["shareCollectedFees"]);
        setValue("payUncollected", customerData["payUncollected"]);
        setValue("hideWaybillMobile", customerData["hideWaybillMobile"]);
        setValue("specifyShipmentCode", customerData["specifyShipmentCode"]);
        setValue("warehousing", customerData["warehousing"]);
        customerData["taxable"] !== null &&
          setValue("taxable", customerData["taxable"]);
        setAutocompleteValues({
          branch: customerData?.branch,
          glAccount: customerData?.glAccount,
          zone: customerData.zone,
          subzone: customerData.subzone,
          priceList: customerData.priceList,
          bank: customerData?.bank,
          country: customerData?.country,
          state: customerData?.state,
          city: customerData?.city,
          area: customerData?.area,
        });
      },
    }
  );


  const settingWarehouse = settings?.shippingSettings?.warehousing

  const customerData = updateCustomer?.customer;


  const lookupsComplete = (data, updateValue, name) => {
    if (updateValue && lookupCodeIsValid(data, updateValue)) {
      setValue(name, updateValue);
    }
  };

  const onGlAccountComplete = (data) => {
    const currentValue = watch("glAccountId");

    const isValueNotValid = !selectedIdIsValid(data, currentValue);
    if (isValueNotValid && currentValue) {
      setValue("glAccountId", "");
      setAutocompleteValues((prev) => ({
        ...prev,
        glAccount: null,
      }));
    }
  };

  const [saveCustomer, { loading: saveCustomerLoading }] = useMutation(
    gql`
      ${SAVE_CUSTOMER.query}
    `
  );

  const onSubmit = (data) => {
    let codes = [];
    let paymentTypesSelect = [];
    for (var i = 0; i < paymentTypes.length; ++i) {
      codes.push(paymentTypes[i].code);
      data[paymentTypes[i].code] === true && paymentTypesSelect.push(paymentTypes[i].code);
    }
    for (const key in data) {
      codes.includes(key) && delete data[key]
      if (data[key] === "") {
        data[key] = null;
      }
      if (!settingWarehouse && key === 'warehousing') {
        delete data[key]
      }
    }
    saveCustomer({
      variables: {
        input: {
          ...(settingWarehouse && { warehousing: data.warehousing }),
          paymentTypes: paymentTypesSelect,
          businessTypeCode: customerType.toUpperCase(),
          ...data,
        },
      },
    })
      .then((data) => {
        pushUrl(props, `/admin/customers/${data?.data?.saveCustomer?.id}`);

        enqueueSnackbar(t("saveSuccessful"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      })
      .catch(({ graphQLErrors }) => {
        setValidationError(graphQLErrors, setError);
        console.log(graphQLErrors);
      });
  };

  const [paymentTypes, setPaymentTypes] = useState();
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const reorderedItems = reorder(
      paymentTypes,
      result.source.index,
      result.destination.index
    );
    setPaymentTypes(reorderedItems);
  };

  const body = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid
        container
        justifyContent="flex-start"
        alignItems="center"
        className={clsx(classes.mainGrid)}
        spacing={2}
      >
        <Paper container component={Grid} className={clsx(classes.spacing)}>
          <Grid
            justifyContent="space-between"
            xs={12}
            sx={{ display: "flex", flexWrap: "wrap" }}
          >
            <Typography variant="h6">{formName[customerType?.toUpperCase()]}</Typography>
            <Box>
              <MuiSwitch
                edge="end"
                name="active"
                label={t("active")}
                control={control}
              />
            </Box>
          </Grid>

          <Grid sm={2} xs={12} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"code"}
              label={t("code")}
            />
          </Grid>

          <Grid sm={4} xs={12} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"name"}
              label={t("name")}
              rules={{ required: t("fieldIsRequired") }}
            />
          </Grid>

          {customerType === "B2C" && <Grid sm={3} xs={12} md={3} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"representative"}
              label={t("representative")}
            />
          </Grid>}

          <Grid sm={customerType === "B2C" ? 3 : 6} xs={12} alignItems="flex-start">
            <ListBranches
              control={control}
              errors={errors}
              name={"branchId"}
              defaultValue={autocompleteValues.branch}
              skipDefaultBranch={customerId}
            />
          </Grid>
          <Grid sm={3} xs={12} alignItems="flex-start">
            <CustomAutocomplete
              control={control}
              errors={errors}
              name={"glAccountId"}
              label={t("glAccount")}
              parseData={(data) => selectDefaultValue(data)}
              query={LIST_GL_ACCOUNTS_DROPDOWN.query}
              onCompleted={onGlAccountComplete}
              variables={{
                input: {
                  typeCode: "SUB",
                  ...(watch("branchId") && {
                    branchId: { value: watch("branchId"), includeNull: true },
                  }),
                  ...(customerType === "B2C" && {
                    parentId: settings?.shippingSettings?.mainCustomerGLAccount?.id,
                  }),
                  ...(customerType === "C2C" && {
                    parentId: settings?.shippingSettings?.mainConsigneeGLAccount?.id,
                  }),
                },
              }}
              defaultValue={autocompleteValues.glAccount}
            />
          </Grid>
          <Grid sm={3} xs={12} md={3} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"activityName"}
              label={t("activity")}
            />
          </Grid>

          <Grid sm={6} xs={12} md={3} alignItems="flex-start">
            <LookupDropdown
              control={control}
              errors={errors}
              name={"defaultPriceTypeCode"}
              label={t("priceType")}
              variables={{
                input: { code: "SHP_PRICE_TYPE" },
              }}
              readOnly={Boolean(
                watch("defaultPaymentTypeCode") === "CASH" ||
                watch("defaultPaymentTypeCode") === "CRDT"
              )}
              onCompleted={(data) =>
                lookupsComplete(
                  data,
                  customerData?.defaultPriceType?.code,
                  "defaultPriceTypeCode"
                )
              }
            />
          </Grid>
          <Grid sm={6} xs={12} md={3} alignItems="flex-start">
            <LookupDropdown
              control={control}
              errors={errors}
              name={"defaultShipmentTypeCode"}
              label={t("packageType")}
              variables={{
                input: { code: "SHP_REQUEST_TYPE" },
              }}
              onCompleted={(data) =>
                lookupsComplete(
                  data,
                  customerData?.defaultShipmentType?.code,
                  "defaultShipmentTypeCode"
                )
              }
            />
          </Grid>

          <Grid sm={6} xs={12} md={3} alignItems="flex-start">
            <LookupDropdown
              control={control}
              errors={errors}
              name={"defaultOpenableCode"}
              label={t("packageOpenPossibility")}
              variables={{
                input: { code: "SHP_OPEN_PACKAGE" },
              }}
              onCompleted={(data) =>
                lookupsComplete(
                  data,
                  customerData?.defaultOpenable?.code,
                  "defaultOpenableCode"
                )
              }
            />
          </Grid>
          <Grid sm={6} xs={12} md={3} alignItems="flex-start">
            <LookupDropdown
              control={control}
              errors={errors}
              name={"categoryCode"}
              label={t("customerCategories")}
              variables={{
                input: { code: "SHP_CUSTOMER_CATGORIES" },
              }}
              onCompleted={(data) =>
                lookupsComplete(
                  data,
                  customerData?.category?.code,
                  "categoryCode"
                )
              }
            />
          </Grid>

          <Grid
            sm={6}
            xs={12}
            md={6}
            alignItems="flex-start"
            className={classes.priceList}
            sx={{ display: "flex" }}
          >
            <CustomAutocomplete
              control={control}
              errors={errors}
              rules={{ required: t("fieldIsRequired") }}
              name={"priceListId"}
              label={t("listPriceList")}
              parseData={(data) => selectDefaultValue(data)}
              query={LIST_PRICE_LIST_DROPDOWN.query}
              defaultValue={autocompleteValues.priceList}
              variables={{
                input: {
                  businessTypeCode: customerType?.toUpperCase()
                }
              }}
            />
            <Can showException permission={customerType && GetCustomerPermissionSlug("shipping", "price_list", customerType, "create")}>
              <IconButton
                onClick={() => setPriceListDialogState(true)}
                size="large"
              >
                <AddCircleOutline />
              </IconButton>
            </Can>
          </Grid>
          {/* <Grid sm={6} xs={12} md={3} alignItems="flex-start">
            <LookupDropdown
              control={control}
              errors={errors}
              name={"businessTypeCode"}
              label={t("type")}
              variables={{
                input: { code: "SHP_BUSINESS_TYPE" },
              }}
              rules={{ required: t("fieldIsRequired") }}
              onCompleted={(data) =>
                lookupsComplete(
                  data,
                  customerData?.businessType?.code,
                  "businessTypeCode"
                )
              }
            />
          </Grid> */}
          <Grid sm={6} xs={12} md={3} alignItems="flex-start">
            <MUIselect
              name={"taxable"}
              label={t("tax")}
              control={control}
              errors={errors}
              rules={{
                validate: {
                  lessThan: (value) => value !== "" || t("fieldIsRequired"),
                },
              }}
              data={[
                { key: t("taxable"), value: true },
                { key: t("notTaxable"), value: false },
              ]}
            />
          </Grid>
          {/* //////////PAYMENT/////////// */}
          <Grid sm={6} xs={12} md={3} alignItems="flex-start">
            <LookupDropdown
              control={control}
              errors={errors}
              name={"paymentMethodCode"}
              label={t("paymentMethod")}
              variables={{
                input: { code: "SHP_PAYMENT_METHODS" },
              }}
              onCompleted={(data) =>
                lookupsComplete(
                  data,
                  customerData?.paymentMethod?.code,
                  "paymentMethodCode"
                )
              }
              onChanges={(e) => {
                setValue("bankId", "");
              }}
            />
          </Grid>
          {watch("paymentMethodCode") === "BNK" && (
            <Grid sm={6} xs={12} md={3} alignItems="flex-start">
              <CustomAutocomplete
                control={control}
                errors={errors}
                name={"bankId"}
                label={t("bank")}
                parseData={(data) => selectDefaultValue(data)}
                query={LIST_BANK_DROPDOWN.query}
                defaultValue={autocompleteValues.bank}
              />
            </Grid>
          )}
          {watch("paymentMethodCode") === "BNK" && (
            <Grid sm={6} xs={12} md={3} alignItems="flex-start">
              <ControlMUItextField
                control={control}
                errors={errors}
                name={"bankAccount"}
                label={t("accountNumber")}
              />
            </Grid>
          )}
          {watch("paymentMethodCode") === "VCSH" && (
            <Grid sm={6} xs={12} md={3} alignItems="flex-start">
              <ControlMUItextField
                control={control}
                errors={errors}
                name={"vodafoneCash"}
                label={t("vodafoneCash")}
                rules={{
                  minLength: {
                    value: 10,
                    message: t("typeCorrectPhone"),
                  },
                }}
              />
            </Grid>
          )}
          <Grid sm={12} xs={12} md={12} container
            justifyContent="flex-start"
            alignItems="center"
            className={clsx(classes.mainGrid)}
            spacing={2}>
            <Grid sm={6} xs={12} md={3} alignItems="flex-start">
              <MuiSwitch
                edge="end"
                name="specifyShipmentCode"
                label={t("enterShipmentCode")}
                control={control}
              />
            </Grid>
            <Grid sm={6} xs={12} md={3} alignItems="flex-start">
              <MuiSwitch
                edge="end"
                name="shareCollectedFees"
                label={t("shareCollectedFees")}
                control={control}
              />
            </Grid>
            <Grid sm={6} xs={12} md={3} alignItems="flex-start">
              <MuiSwitch
                edge="end"
                name="payUncollected"
                label={t("payUncollected")}
                control={control}
              />
            </Grid>
            <Grid sm={6} xs={12} md={3} alignItems="flex-start">
              <MuiSwitch
                edge="end"
                name="hideWaybillMobile"
                label={t("hideWaybillMobile")}
                control={control}
              />
            </Grid>
            {customerType === "B2C" &&
              <Grid sm={6} xs={12} md={3} alignItems="flex-start">
                <Can showException permission={settingWarehouse}>
                  <MuiSwitch
                    edge="end"
                    name="warehousing"
                    label={t("warehousing")}
                    control={control}
                  />
                </Can>
              </Grid>}
          </Grid>
        </Paper>

        <Paper container component={Grid} className={clsx(classes.spacing)}>
          {GlobalSettings.multiCountries && <Grid sm={6} xs={12} md={4} alignItems="flex-start">
            <CustomAutocomplete
              control={control}
              errors={errors}
              name={"countryId"}
              label={t("country")}
              parseData={(data) => selectDefaultValue(data)}
              query={LIST_COUNTRIES_DROPDOWN.query}
              onChangeValue={(e) => {
                setValue("stateId", "");
                setValue("cityId", "");
                setValue("areaId", "");
              }}
              defaultValue={autocompleteValues.country}
            />
          </Grid>}
          <Grid sm={6} xs={12} md={GlobalSettings.multiCountries ? 4 : 6} alignItems="flex-start">
            <CustomAutocomplete
              control={control}
              errors={errors}
              rules={{ required: t("fieldIsRequired") }}
              name={"zoneId"}
              label={t("zone")}
              parseData={(data) => selectDefaultValue(data)}
              query={LSIT_ZONES_DROPDOWN.query}
              variables={{
                input: {
                  ...(watch("countryId") && { countryId: watch("countryId") }),
                  parentId: null,
                },
              }}
              onChangeValue={(e) => {
                setValue("subzoneId", "");
              }}
              defaultValue={autocompleteValues.zone}
            />
          </Grid>
          <Grid sm={6} xs={12} md={GlobalSettings.multiCountries ? 4 : 6} alignItems="flex-start">
            <CustomAutocomplete
              control={control}
              errors={errors}
              rules={{ required: t("fieldIsRequired") }}
              disabled={!watch("zoneId")}
              skip={!watch("zoneId")}
              name={"subzoneId"}
              label={t("subzone")}
              parseData={(data) => selectDefaultValue(data)}
              query={LSIT_ZONES_DROPDOWN.query}
              variables={{
                input: { parentId: watch("zoneId"), active: true },
              }}
              defaultValue={autocompleteValues.subzone}
            />
          </Grid>
          {/* <Grid sm={6} xs={12} md={3} alignItems="flex-start">
            <CustomAutocomplete
              control={control}
              errors={errors}
              disabled={!watch("countryId")}
              skip={!watch("countryId")}
              name={"stateId"}
              label={t("state")}
              parseData={(data) => selectDefaultValue(data)}
              query={LIST_STATES_DROPDOWN.query}
              variables={{
                countryId: watch("countryId"),
              }}
              onChangeValue={(e) => {
                setValue("cityId", "");
                setValue("areaId", "");
              }}
              defaultValue={autocompleteValues.state}
            />
          </Grid>
          <Grid sm={6} xs={12} md={3} alignItems="flex-start">
            <CustomAutocomplete
              control={control}
              errors={errors}
              disabled={!watch("stateId")}
              skip={!watch("stateId")}
              name={"cityId"}
              label={t("city")}
              parseData={(data) => selectDefaultValue(data)}
              query={LIST_CITIES_DROPDOWN.query}
              variables={{
                stateId: watch("stateId"),
              }}
              onChangeValue={(e) => {
                setValue("areaId", "");
              }}
              defaultValue={autocompleteValues.city}
            />
          </Grid>
          <Grid sm={6} xs={12} md={3} alignItems="flex-start">
            <CustomAutocomplete
              control={control}
              errors={errors}
              disabled={!watch("cityId")}
              skip={!watch("cityId")}
              name={"areaId"}
              label={t("area")}
              parseData={(data) => selectDefaultValue(data)}
              query={LIST_AREAS_DROPDOWN.query}
              variables={{
                cityId: watch("cityId"),
              }}
              defaultValue={autocompleteValues.area}
            />
          </Grid> */}
          <Grid sm={6} xs={12} md={4} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"phone"}
              label={t("phone")}
              rules={{
                required: t("fieldIsRequired"),
              }}
              onBlur={(e) => {
                Boolean(!getValues().mobile) &&
                  setValue("mobile", getValues().phone, {
                    shouldDirty: true,
                  });
              }}
            />
          </Grid>
          <Grid sm={6} xs={12} md={4} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"mobile"}
              label={t("mobile")}
              rules={{
                required: t("fieldIsRequired"),
              }}
            />
          </Grid>
          {/* <Grid sm={6} xs={12} md={3} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"mailBox"}
              label={t("mailBox")}
            />
          </Grid> */}
          <Grid sm={6} xs={12} md={4} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"postalCode"}
              label={t("postalCode")}
            />
          </Grid>
          <Grid sm={6} xs={12} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"address"}
              label={t("address")}
            />
          </Grid>
          <Grid sm={6} xs={12} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"email"}
              label={t("email")}
              rules={{
                pattern: {
                  value:
                    /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: t("emailIsInvalid"),
                },
              }}
            />
          </Grid>
        </Paper>
        <Paper container component={Grid} className={clsx(classes.spacing)}>
          <Grid sm={12} xs={12} alignItems="flex-start" display={"grid"}>
            <Toolbar className={classes.toolbarTable} variant="dense">
              <Typography
                className={classes.title}
                color="inherit"
                variant="h6"
              >
                {t("paymentTypes")}
              </Typography>
            </Toolbar>
            <TableFixedHeaderWraper>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <FixedTableCell> </FixedTableCell>
                    <FixedTableCell>{t("code")}</FixedTableCell>
                    <FixedTableCell>{t("name")}</FixedTableCell>
                    <FixedTableCell> </FixedTableCell>
                  </TableRow>
                </TableHead>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable" direction="vertical">
                    {(provided, snapshot) => (
                      <TableBody {...provided.droppableProps}
                        ref={provided.innerRef}>
                        {paymentTypes &&
                          paymentTypes?.map((row, index) => (
                            <Draggable key={row.id} draggableId={row?.code} index={index}>
                              {(provided, snapshot) => (
                                <TableRow
                                  hover
                                  role="checkbox"
                                  tabIndex={-1}
                                  key={index}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <FixedTableCell>
                                    <DragHandleIcon />
                                  </FixedTableCell>
                                  <FixedTableCell>
                                    {row?.code}
                                  </FixedTableCell>
                                  <FixedTableCell>
                                    {row?.name}
                                  </FixedTableCell>
                                  <FixedTableCell>
                                    <MuiSwitch
                                      edge="end"
                                      name={`${row.code}`}
                                      control={control}
                                    />
                                  </FixedTableCell>
                                </TableRow>
                              )}
                            </Draggable>
                          ))}
                        {provided.placeholder}
                      </TableBody>
                    )}
                  </Droppable>
                </DragDropContext>
              </Table>
            </TableFixedHeaderWraper>
          </Grid>
        </Paper>

        <Grid container justifyContent="flex-end" className={classes.spacing}>
          <FormButton disabled={saveCustomerLoading}>
            {saveCustomerLoading ? <ButtonLoading /> : t("save")}
          </FormButton>
        </Grid>
      </Grid>
    </form>
  );
  let DOM;

  if (customerId) {
    DOM = updateCustomer ? body : <FullScreenLoading minHeight="10%" />;
  } else {
    DOM = body;
  }


  // const pagePermission = customerType &&
  //   GetCustomerPermissionSlug("shipping", "customer", customerType, "create")

  return (
    // <Can permission={pagePermission}>
    <Root>
      <CustomDialog
        open={priceListDialogState}
        onClose={closePriceListDialog}
        TransitionComponent={Transition}
        fullWidth
        fullScreen
        content={
          <PriceListForm
            key={Math.random()}
            customerName={watch("name") && t("priceList") + " " + watch("name")}
            businessType={customerType}
            onSave={(data) => {
              const priceList = data.data.savePriceList;
              setAutocompleteValues((prev) => ({
                ...prev,
                priceList: {
                  id: priceList.id,
                  name: priceList.name,
                },
              }));
              closePriceListDialog();
            }}
          />
        }
        actions={<Button onClick={closePriceListDialog}>{t("cancel")}</Button>}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={closePriceListDialog}
              aria-label="close"
              size="large"
            >
              <Close />
            </IconButton>
            <Typography color="inherit" variant="h6">
              {t("priceList")}
            </Typography>
          </Toolbar>
        </AppBar>
      </CustomDialog>
      {DOM}
    </Root>
    // </Can>
  );
};

export default CustomerForm;
