/* eslint-disable no-useless-concat */
import React, { useState, Fragment, useEffect } from "react";

import { styled } from "@mui/material/styles";

import {
  Typography,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  IconButton,
  Toolbar,
  Button,
  Collapse,
  Box,
  Tooltip,
  DialogContent,
  DialogActions,
  Icon,
} from "@mui/material";
import useWidth, { isWidthDown } from "../../Hooks/useWidth";

import { useForm } from "react-hook-form";
import {
  Add,
  BackspaceOutlined,
  Delete,
  Edit,
  Image,
  RadioButtonUnchecked,
} from "@mui/icons-material";
import { gql, useQuery, useMutation } from "@apollo/client";
import moment from "moment";
import CustomizedSteppers from "../HOC/FunctionComponents/Stepper";
import { Alert } from "@mui/material";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

import { Can, SecuredNavLink } from "../HOC/CustomComponents/Secured";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import { Globals } from "../HOC/Classes/Globals";
import { KeyValuePair } from "../HOC/CustomComponents/KeyValuePair";
import SpanLink from "../HOC/CustomComponents/SpanLink";
import CellLink from "../HOC/CustomComponents/CellLink";
import * as gqlb from "gql-query-builder";
import {
  DELETE_LAST_SHIPMENT_ACTION,
  DELETE_SHIPMENT,
  SETTINGS,
  SHIPMENT_MESSAGE,
  SHIPMENT_MESSAGES,
} from "./Graphql";
import { TableCellColor } from "../HOC/CustomComponents/TableCellColor";
import CustomDialog from "../HOC/CustomComponents/CustomDialog";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import CustomTab from "../HOC/FunctionComponents/CustomTab";
import config from "../../config.json";
import EmptyTableMessage from "../HOC/FunctionComponents/EmptyTableMessage";
import PrintIcon from "./PrintIcon";
import UploadFile from "../HOC/MUI/UploadFile";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";
import Grid from "@mui/material/Unstable_Grid2";
import MutationDialogWithTextField from "../HOC/CustomComponents/MutationDialogWithTextField";
import ShipmentHistory from "./ShipmentHistory";
import { dateFormatLL } from "../../helpers/dateFunctions";
import { GetCollectionPermissionSlug, GetCustomerPermissionSlug, GetManifestPermissionSlug, GetPaymentPermissionSlug } from "../../helpers/getManifestPermissionSlug";
import PrintComponent from "./PrintComponent";

const PREFIX = "ShipmentsView";

const classes = {
  typo: `${PREFIX}-typo`,
  mainTracking: `${PREFIX}-mainTracking`,
  paper: `${PREFIX}-paper`,
  textAlign: `${PREFIX}-textAlign`,
  divider: `${PREFIX}-divider`,
  table: `${PREFIX}-table`,
  tabpanel: `${PREFIX}-tabpanel`,
  alert: `${PREFIX}-alert`,
  alertDisabled: `${PREFIX}-alertDisabled`,
  input: `${PREFIX}-input`,
  iconButton: `${PREFIX}-iconButton`,
  searchBar: `${PREFIX}-searchBar`,
  toolbar: `${PREFIX}-toolbar`,
  codeSection: `${PREFIX}-codeSection`,
  codeTitle: `${PREFIX}-codeTitle`,
  toolbarIcons: `${PREFIX}-toolbarIcons`,
  alertHover: `${PREFIX}-alertHover`,
  link: `${PREFIX}-link`,
  delivered: `${PREFIX}-delivered`,
  returned: `${PREFIX}-returned`,
  iconColor: `${PREFIX}-iconColor`
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.typo}`]: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    fontWeight: 600,
  },

  [`& .${classes.mainTracking}`]: {
    margin: 0,
    width: "100%",
  },

  [`& .${classes.paper}`]: {
    padding: theme.spacing(3),
    width: "100%",
    margin: 0,
  },

  [`& .${classes.textAlign}`]: {
    textAlign: "justify",
    padding: theme.spacing(1),
  },

  [`& .${classes.divider}`]: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
      height: "1px",
    },
  },

  [`& .${classes.table}`]: {
    display: "grid",
  },

  [`& .${classes.tabpanel}`]: {
    padding: theme.spacing(2, 0),
  },

  [`& .${classes.alert}`]: {
    color: "rgb(94 183 97)",
    backgroundColor: "white",
    borderRadius: "64px",
    boxShadow: theme.shadows[1],
    border: "none",
  },

  [`& .${classes.alertDisabled}`]: {
    color: "rgb(181 181 181)",
  },

  [`& .${classes.input}`]: {
    margin: theme.spacing(0, 2, 0, 2),
    flex: 1,
  },

  [`& .${classes.iconButton}`]: {
    borderRadius: "0 4px 4px 0 ",
    boxShadow: "none",
    height: 45,
  },

  [`& .${classes.searchBar}`]: {
    width: 260,
    [theme.breakpoints.down("sm")]: {
      width: 170,
    },
  },

  [`& .${classes.toolbar}`]: {
    borderBottom: "1px #ccd1d6 solid",
    backgroundColor: "#f5f7f9",
    width: "100%",
  },

  [`& .${classes.codeSection}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: theme.spacing(1),
  },

  [`& .${classes.codeTitle}`]: {
    float: "left",
    marginRight: theme.spacing(1),
  },

  [`& .${classes.toolbarIcons}`]: {
    flex: "1 1 100%",
    textAlign: "end",
  },

  [`& .${classes.alertHover}`]: {
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },

  [`& .${classes.link}`]: {
    textDecoration: "none",
  },
  [`& .${classes.delivered}`]: {
    color: "rgb(94 183 97)"
  },
  [`& .${classes.returned}`]: {
    color: '#f44336',
  },
  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  }
}));

//*********Table Function*********

const convertArNumToEn = (str) => {
  if (str !== null && str !== undefined) {
    const english = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
    const arabic = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];

    for (let i = 0; i < english.length; i++) {
      while (str.includes(arabic[i])) str = str.replace(arabic[i], english[i]);
    }
  }
  return str;
};

const ADMIN_NOTES = gqlb.mutation({
  operation: "updateShipmentAdminNotes",
  fields: ["adminNotes"],
  variables: {
    input: {
      type: "UpdateShipmentAdminNotesInput",
      required: true,
    },
  },
});

const ShipmentsView = (props) => {
  const user = Globals.user;
  const warehousing = Globals.user.warehousing;

  const collectionValidCUSTM = user?.hasPermission(GetCollectionPermissionSlug('custm', 'list'))
  const collectionValidDLVBY = user?.hasPermission(GetCollectionPermissionSlug('dlvby', 'list'))
  const invoiceValid = user?.hasPermission("shipping.invoice.list");
  const paymentValidCUSTM = user?.hasPermission(GetPaymentPermissionSlug('custm', 'list'))
  const paymentValidDLVBY = user?.hasPermission(GetPaymentPermissionSlug('dlvby', 'list'))
  // const canViewManifest = user?.hasPermission("shipping.manifest.list");
  const warehousePermission = user?.hasPermission(
    "shipping.shipment.list_in_warehouse"
  );
  const adminNotesPermission = user.hasPermission(
    "shipping.shipment.view_admin_note"
  );
  const hasDeliveryCalls = user?.hasPermission("shipping.delivery_call.list");
  const historyPermission = user.hasPermission(
    "shipping.shipment.view_history"
  );

  const commonShipmentFields = [
    "id",
    "code",
    "customerDue",
    "date",
    "createdAt",
    "description",
    "weight",
    "piecesCount",
    "price",
    "notes",

    `adminNotes @include(if: ${adminNotesPermission})`,

    "recipientName",
    "recipientAddress",
    "recipientPhone",
    "recipientMobile",
    "senderName",
    "senderPhone",
    "senderMobile",
    "senderAddress",
    "deliveredAmount",
    "collected",
    "inWarehouse",
    "unpacked",
    "paidToCustomer",
    "paidToDeliveryAgent",

    "returnedValue",
    "amount",
    "allDueFees",
    "returningDueFees",
    "deliveryFees",
    "extraWeightFees",
    "collectionFees",
    "collectedFees",
    "totalAmount",
    "refNumber",
    "tax",

    "postFees",
    "returnFees",
    "returnPiecesCount",
    "recipientPostalCode",
    "senderPostalCode",
    "deliveryDate",

    {
      size: ["length", "width", "height"],
    },

    {
      service: ["id", "name"],
    },
    {
      recipientZone: ["id", "name"],
    },
    {
      recipientSubzone: ["id", "name"],
    },
    {
      senderZone: ["id", "name"],
    },
    {
      deliveryType: ["code", "name"],
    },
    {
      senderSubzone: ["id", "name"],
    },
    {
      paymentType: ["code", "name"],
    },
    {
      priceType: ["code", "name"],
    },
    {
      type: ["code", "name"],
    },
    {
      openable: ["code", "name"],
    },
    {
      status: ["code", "name"],
    },
    {
      lastDeliveryAgent: ["id", "name", "phone"],
    },
    {
      branch: ["id", "name"],
    },
    {
      originBranch: ["id", "name"],
    },
    {
      returnStatus: ["code", "name"],
    },
    {
      operation: `warehouseSection @include(if:${warehousePermission})`,
      fields: ["id", "name"],
      variables: {},
    },
  ];

  const TRACKING = gqlb.query({
    operation: "shipment",
    fields: [
      ...commonShipmentFields,
      {
        operation: `history @include(if: ${historyPermission})`,

        fields: [
          ...commonShipmentFields,
          "createdAt",
          "updatedAt",
          {
            createdBy: ["id", "username"],
          },
          {
            updatedBy: ["id", "username"],
          },
          {
            customer: ["id", "name", "code"],
          },
          {
            returnType: ["name", "code"],
          },
          {
            pickup: ["id", "code"],
          },
        ],
        variables: {},
      },
      "recipientLatitude",
      "recipientLongitude",
      "deletable",
      "editable",
      "signature",
      "unpacked",

      {
        pickup: ["id", "code"],
      },
      {
        customer: [
          "id",
          "name",
          "code",
          "address",
          "phone",
          "mobile",
          {
            zone: ["id", "name"],
          },
          {
            subzone: ["id", "name"],
          },
          {
            businessType: ["id", "name", "code"],
          },
        ],
      },

      {
        operation: `invoice @include(if: ${invoiceValid})`,
        fields: ["id", "code"],
        variables: {},
      },

      {
        operation: `paymentCUSTM:payment(type: CUSTM) @include(if: ${paymentValidCUSTM})`,
        fields: [
          "id",
          "code",
          "approved",
          {
            type: ["code", "name"],
          },
        ],
        variables: {
        },
      },
      {
        operation: `paymentDLVBY:payment(type: DLVBY) @include(if: ${paymentValidDLVBY})`,
        fields: [
          "id",
          "code",
          "approved",
          {
            type: ["code", "name"],
          },
        ],
        variables: {
        },
      },

      {
        operation: `collectionCUSTM:collection(type: CUSTM) @include(if: ${collectionValidCUSTM})`,

        fields: [
          "id",
          "code",
          "approved",
          {
            type: ["code", "name"],
          },
        ],
        variables: {},
      },
      {
        operation: `collectionDLVBY:collection(type: DLVBY) @include(if: ${collectionValidDLVBY})`,

        fields: [
          "id",
          "code",
          "approved",
          {
            type: ["code", "name"],
          },
        ],
        variables: {},
      },

      {
        transactions: [
          "id",
          "date",
          "deliverdAmount",
          "fees",
          {
            cancellationReason: ["name"],
          },
          {
            branch: ["name"],
          },
          {
            shipmentStatus: ["code", "name"],
          },
          {
            deliveryType: ["code", "name"],
          },
          {
            returnType: ["code", "name"],
          },
          {
            deliveryAgent: ["id", "name"],
          },
          {
            createdBy: ["id", "username"],
          },
          {
            trxType: ["id", "name", { type: ["name"] }],
          },
          {
            manifest: ["id", "code", { transactionType: [{ type: ["code"] }] }],
          },
          {
            manifestShipment: ["notes"],
          },
        ],
      },

      {
        messages: [
          "id",
          "body",
          "image",
          "createdAt",

          {
            user: [
              "username",
              "id",
              {
                operation: "account",
                fields: [
                  {
                    operation: "...on Customer",
                    fields: ["customerId:id", "name"],
                    variables: {},
                  },
                  {
                    operation: "...on DeliveryAgent",
                    fields: ["deliveryAgentId:id", "name"],
                    variables: {},
                  },
                ],
                variables: {},
              },
            ],
          },
        ],
      },

      {
        operation: `deliveryCalls @include(if: ${hasDeliveryCalls})`,
        fields: [
          "id",
          "createdAt",
          "duration",
          "phoneNumber",
          {
            deliveryAgent: ["id", "name"],
          },
          {
            type: ["name"],
          },
        ],
        variables: {},
      },
      {
        shipmentProducts: [
          "price", "quantity", "returned", "delivered",
          {
            product: [
              "id",
              "name",
              "availableQuantity",
              "weight",
            ]
          }
        ]
      }
    ],

    variables: {
      id: {
        type: "Int",
      },
      code: {
        type: "String",
      },
    },
  });

  const urlCode = props.match.params.code?.trim();
  const urlId = props.match.params.id?.trim();
  const [tracking, setTracking] = useState({ refetch: false });
  const [messages, setMessages] = useState([]);

  const [dialogDetails, setDialogDetails] = useState({
    state: false,
    function: null,
    confirmAction: true,
    title: "",
    content: "",
  });

  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setTracking((prev) => ({
      ...prev,
      ...(urlCode && { trackingCode: urlCode }),
      ...(urlId && { trackingId: urlId }),
      refetch: !prev.refetch,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const screenWidth = useWidth();

  const isWidthXs = isWidthDown("xs", screenWidth);

  const { handleSubmit, formState, control, setValue, watch } = useForm({
    defaultValues: {
      body: "",
      image: "",
    },
  });
  const { errors } = formState;

  const { data: settings } = useQuery(
    gql`
      ${SETTINGS.query}
    `,
    {
      fetchPolicy: "no-cache",
    }
  );
  const currencyCode = settings?.shippingSettings?.localCurrency?.name ?? "";

  const { data, loading, refetch } = useQuery(
    gql`
      ${TRACKING.query}
    `,
    {
      variables: {
        ...(tracking.trackingCode && {
          code: convertArNumToEn(tracking.trackingCode),
        }),
        ...(tracking.trackingId && { id: parseInt(tracking.trackingId) }),
      },
      skip: Boolean(!tracking.trackingCode && !tracking.trackingId),
      // notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      onCompleted: (data) => {
        const messages = data.shipment?.messages;
        setMessages(messages);
      },
    }
  );
  const trackingData = data?.shipment !== null ? data?.shipment : null;
  const productList = trackingData?.shipmentProducts;
  const shipmentId = trackingData?.id;

  const [deleteLastShipmentActionMutation, { loading: loadDeleteLastAction }] =
    useMutation(
      gql`
        ${DELETE_LAST_SHIPMENT_ACTION.query}
      `,
      {
        variables: { id: shipmentId },

        fetchPolicy: "no-cache",
        onCompleted: (data) => {
          refetch();
          enqueueSnackbar(t("successfullyDeleteLastAction"), {
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            TransitionComponent: Collapse,
          });
          closeConfirmationDialog();
        },
        onError: (error) => {
          console.log(error);
          enqueueSnackbar(error.message, {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            TransitionComponent: Collapse,
          });
          closeConfirmationDialog();
        },
      }
    );

  const [deleteShipmentMutation, { loading: loadDelete }] = useMutation(
    gql`
      ${DELETE_SHIPMENT.query}
    `,
    {
      variables: { id: shipmentId },

      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        enqueueSnackbar(t("shipmentDeleted"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
        closeConfirmationDialog();
        if (props?.location?.state?.prevUrl) {
          pushUrl(props, props.history.location.state.prevUrl);
        } else {
          pushUrl(props, "/admin/shipments");
        }
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );
  const [createShipmentMessageMutation, { loading: loadMessage }] = useMutation(
    gql`
      ${SHIPMENT_MESSAGE.query}
    `,
    {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        closeConfirmationDialog();
        refetch();
        setValue("image", "");
        setValue("body", "");
        setValue("fileName", "");
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  const branchId = trackingData?.originBranch?.id;
  const canAccessBranch = user?.account ? true : user.canAccessBranch(branchId);

  const closeConfirmationDialog = () => {
    setDialogDetails((prev) => ({
      ...prev,
      state: false,
    }));
  };

  const openDeleteLastShipmentActionDialog = () => {
    setDialogDetails((prev) => ({
      state: true,
      title: t("deleteLastAction"),
      content: t("deleteLastActionMessage"),
      confirmAction: true,
      function: deleteLastShipmentActionMutation,
    }));
  };

  const openDeleteDialog = () => {
    setDialogDetails((prev) => ({
      state: true,
      title: t("delete"),
      content: t("deleteShipmentConfirmation"),
      confirmAction: true,
      function: deleteShipmentMutation,
    }));
  };

  const onSubmitMessage = (data) => {
    const image = data?.image?.[0];
    createShipmentMessageMutation({
      variables: {
        input: {
          shipmentId,
          ...(data?.body && { body: data.body }),
          ...(image && { image: image }),
        },
      },
    }).catch((error) => console.log(error));
  };
  const createMessageCondation =
    user.hasPermission("shipping.shipment_message.create") && canAccessBranch;

  const messageForm = (
    <Fragment>
      <form onSubmit={handleSubmit(onSubmitMessage)}>
        <DialogContent>
          <ControlMUItextField
            control={control}
            errors={errors}
            name={"body"}
            label={t("message")}
            margin="normal"
            rules={{
              validate: {
                require: (value) =>
                  value || watch("image") ? true : t("fieldIsRequired"),
              },
            }}
          />
          <UploadFile
            control={control}
            setValue={setValue}
            name="image"
            icon={"add_photo_alternate"}
            label={t("uploadImage")}
            accept=".png,.jpg"
            rules={{
              validate: {
                require: (value) =>
                  value || watch("body") ? true : t("fieldIsRequired"),
              },
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={closeConfirmationDialog}>
            {t("cancel")}
          </Button>
          <Button color="primary" type="submit" disabled={loadMessage}>
            {loadMessage ? <ButtonLoading /> : t("confirm")}
          </Button>
        </DialogActions>
      </form>
    </Fragment>
  );
  const openMessageDialog = () => {
    setDialogDetails((prev) => ({
      state: true,
      title: t("newMessage"),
      content: null,
      confirmAction: true,
      function: null,
    }));
  };

  let getBackendUri = (imgPath) => {
    const domain = config.backend.domain
      ? config.backend.domain
      : window.location.hostname;

    return `${config.backend.protocol}://${domain}:${config.backend.port}${imgPath}`;
  };

  const openSignatureDialog = (imgPath) => {
    setDialogDetails((prev) => ({
      state: true,
      title: null,
      content: (
        <Box component="img" alt="signature" src={getBackendUri(imgPath)} />
      ),
      confirmAction: false,
      function: () => { },
    }));
  };

  const validCustomer =
    user.account === null || trackingData?.customer?.id === user?.account?.id;

  const canDelete =
    (user.hasPermission("shipping.shipment.delete_any") ||
      (user.hasPermission("shipping.shipment.delete") &&
        trackingData?.customer?.id === user?.account?.id)) &&
    canAccessBranch &&
    trackingData?.deletable;

  const canDeleteLastAction =
    user.hasPermission("shipping.shipment.delete_last_action") &&
    trackingData?.status?.code !== "PKR" &&
    canAccessBranch;

  const collection = trackingData?.collectionDLVBY ?? trackingData?.collectionCUSTM;
  const creditPayment = trackingData?.paymentType.code === "CRDT";
  const customerPayments = trackingData?.paymentCUSTM
  const invoice = trackingData?.invoice;
  const paidToCustomerType = Boolean(creditPayment)
    ? invoice
    : customerPayments;
  const hasPaymentsToCustomer = Boolean(paidToCustomerType);
  const paidToCustomer = {
    active: trackingData?.paidToCustomer,
    permission: creditPayment
      ? "shipping.invoice.list"
      : Boolean(customerPayments),
    code: paidToCustomerType?.code,
    link:
      hasPaymentsToCustomer &&
      `/admin/${creditPayment ? "invoices" : "payments"}/${paidToCustomerType?.id
      }`,
  };

  const paidToDeliveryAgent = trackingData?.paymentDLVBY

  const updateAdminNotes =
    user.hasPermission("shipping.shipment.update_admin_note") &&
    trackingData?.editable;

  let trackingBody = null;

  const invalidId = (
    <Grid container justifyContent="center" className={classes.track}>
      <EmptyTableMessage loading={loading} message={t("noShipmentWithCode")} />
    </Grid>
  );

  const description =
    !loading && trackingData ? (
      <Grid spacing={2} container className={classes.tabpanel}>
        <Grid xs={12} className={classes.track}>
          <Paper container className={classes.paper} component={Grid}>
            <KeyValuePair
              title={t("date")}
              value={dateFormatLL(trackingData?.date)}
            />

            <KeyValuePair
              title={t("originBranch")}
              value={
                trackingData?.originBranch?.name ? (
                  <SpanLink
                    pathname={`/admin/branches/${trackingData?.originBranch?.id}`}
                  >
                    {trackingData?.originBranch?.name}
                  </SpanLink>
                ) : (
                  "--"
                )
              }
            />
            <KeyValuePair
              title={t("currentBranch")}
              value={
                trackingData?.branch?.name ? (
                  <SpanLink
                    pathname={`/admin/branches/${trackingData?.branch?.id}`}
                  >
                    {trackingData?.branch?.name}
                  </SpanLink>
                ) : (
                  "--"
                )
              }
            />
            <KeyValuePair
              title={t("packageWeight")}
              value={trackingData?.weight ?? "0"}
            />
            <KeyValuePair
              title={t("pieceCount")}
              value={trackingData?.piecesCount ?? "0"}
            />
            <KeyValuePair
              title={t("packageType")}
              value={trackingData?.type.name}
            />

            <KeyValuePair
              title={t("service")}
              value={
                trackingData?.service ? (
                  <SpanLink
                    pathname={`/admin/shipping-services/${trackingData?.service?.id}`}
                  >
                    {trackingData?.service?.name}
                  </SpanLink>
                ) : (
                  "--"
                )
              }
            />
            <KeyValuePair
              title={t("pickup")}
              value={
                trackingData?.pickup ? (
                  <SpanLink
                    pathname={`/admin/pickups/${trackingData?.pickup?.id}`}
                  >
                    {trackingData?.pickup?.code}
                  </SpanLink>
                ) : (
                  "--"
                )
              }
            />
            <KeyValuePair
              valuesx={{ whiteSpace: "pre-line" }}
              title={t("notes")}
              value={trackingData?.notes ?? "--"}
            />
            <MutationDialogWithTextField
              id={shipmentId}
              title={t("adminNotes")}
              value={trackingData?.adminNotes}
              mutaion={ADMIN_NOTES.query}
              dialogTitle="adminNotes"
              onCompleteMessage="saveSuccessful"
              fieldName="adminNotes"
              fieldLabel="adminNotes"
              viewPermission="shipping.shipment.view_admin_note"
              updatePermission={updateAdminNotes}
              onCompleted={() => refetch()}
            />
            <KeyValuePair
              title={t("packageDescription")}
              value={trackingData?.description}
            />
            <KeyValuePair
              title={t("packageOpen")}
              value={trackingData?.openable.name}
            />
            <KeyValuePair
              title={t("refNumber")}
              value={trackingData?.refNumber ?? "--"}
            />
            <Can permission="shipping.shipment.list_in_warehouse" showException>
              {trackingData?.inWarehouse &&
                <KeyValuePair
                  title={t("wareHouse")}
                  value={
                    trackingData?.warehouseSection?.name ? (
                      <SpanLink
                        pathname={`/admin/warehouse/${trackingData?.warehouseSection?.id}`}
                      >
                        {trackingData?.warehouseSection?.name}
                      </SpanLink>
                    ) : (
                      "--"
                    )
                  }
                />}
            </Can>
            {trackingData?.returnPiecesCount ? (
              <KeyValuePair
                title={t("returnPiecesCount")}
                value={trackingData?.returnPiecesCount ?? "--"}
              />
            ) : null}
            {trackingData?.deliveryDate ? (
              <KeyValuePair
                title={t("redeliveryDate")}
                value={trackingData?.deliveryDate}
              />
            ) : null}
            {trackingData?.signature ? (
              <KeyValuePair
                title={t("signature")}
                value={
                  <IconButton
                    size="small"
                    onClick={() => openSignatureDialog(trackingData?.signature)}
                  >
                    <Image fontSize="small" />
                  </IconButton>
                }
              />
            ) : null}
            <KeyValuePair
              title={t("createdAt")}
              value={dateFormatLL(trackingData?.createdAt)}
            />
            {warehousing && productList.length > 0 && <KeyValuePair
              title={t("unpacked")}
              value={
                trackingData?.unpacked ? (
                  <Icon className={classes.iconColor}>
                    check_circle_outline
                  </Icon>
                ) : (
                  <Icon color="error">highlight_off</Icon>
                )
              }
            />}
          </Paper>
        </Grid>

        <Grid xs={12} md={6} sx={{ display: "flex" }}>
          <Paper container className={classes.paper} component={Grid}>
            <KeyValuePair
              title={t("customer")}
              // value={trackingData?.customer?.name}
              value={
                trackingData?.customer ? (
                  user.hasPermission(GetCustomerPermissionSlug("shipping", "customer", trackingData?.customer?.businessType.code, "list")) ? <SpanLink
                    pathname={`/admin/customers/${trackingData?.customer?.id}`}
                  >
                    {trackingData?.customer?.name +
                      ` - (${trackingData?.customer?.code})`}
                  </SpanLink> :
                    trackingData?.customer?.name +
                    ` - (${trackingData?.customer?.code})`
                ) : (
                  "--"
                )
              }
              md={6}
            />
            <KeyValuePair
              title={t("senderName")}
              value={trackingData?.senderName}
              md={6}
            />
            <KeyValuePair
              title={t("source")}
              value={
                <SpanLink
                  pathname={`/admin/zone/${trackingData.senderZone.id}`}
                >
                  {trackingData?.senderZone?.name +
                    " / " +
                    trackingData?.senderSubzone.name}
                </SpanLink>
              }
              md={6}
            />
            <KeyValuePair
              title={t("phone")}
              value={
                trackingData?.senderMobile + ` / ${trackingData?.senderPhone}`
              }
              md={6}
            />
            <KeyValuePair
              title={t("postalCode")}
              value={trackingData?.senderPostalCode}
              md={6}
            />
            <KeyValuePair
              title={t("address")}
              value={trackingData?.senderAddress}
              md={6}
            />
          </Paper>
        </Grid>
        <Grid xs={12} md={6} sx={{ display: "flex" }}>
          <Paper container className={classes.paper} component={Grid}>
            <KeyValuePair
              title={t("recipient")}
              value={trackingData?.recipientName}
              sm={12}
              md={12}
            />
            <KeyValuePair
              title={t("destination")}
              value={
                <SpanLink
                  pathname={`/admin/zone/${trackingData.recipientZone.id}`}
                >
                  {trackingData?.recipientZone?.name +
                    " / " +
                    trackingData?.recipientSubzone.name}
                </SpanLink>
              }
              md={6}
            />
            <KeyValuePair
              title={t("phone")}
              value={
                trackingData?.recipientMobile +
                ` / ${trackingData?.recipientPhone}`
              }
              md={6}
            />
            <KeyValuePair
              title={t("postalCode")}
              value={trackingData?.recipientPostalCode}
              md={6}
            />
            <KeyValuePair
              title={t("address")}
              value={trackingData?.recipientAddress}
              md={6}
            />
          </Paper>
        </Grid>

        <Grid xs={12} justifyContent="center">
          <Paper container className={classes.paper} component={Grid}>
            <KeyValuePair
              title={t("paymentType")}
              value={trackingData?.paymentType?.name}
              sm={6}
            />
            <KeyValuePair
              title={t("priceType")}
              value={trackingData?.priceType?.name}
              sm={6}
            />
            <KeyValuePair
              title={t("packagePrice")}
              value={(trackingData?.price || "0.00") + " " + currencyCode}
              sm={6}
            />
            <KeyValuePair
              title={t("value")}
              value={(trackingData?.amount || "0.00") + " " + currencyCode}
              sm={6}
            />
            <KeyValuePair
              title={t("deliveryFees")}
              value={
                (trackingData?.deliveryFees || "0.00") + " " + currencyCode
              }
              sm={6}
            />
            <KeyValuePair
              title={t("extraWeightCost")}
              value={
                (trackingData?.extraWeightFees || "0.00") + " " + currencyCode
              }
              sm={6}
            />
            <KeyValuePair
              title={t("collectionFees")}
              value={
                (trackingData?.collectionFees || "0.00") + " " + currencyCode
              }
              sm={6}
            />
            <KeyValuePair
              title={t("totalCost")}
              value={(trackingData?.totalAmount || "0.00") + " " + currencyCode}
              sm={6}
            />
            <KeyValuePair
              title={t("returnFees")}
              value={(trackingData?.returnFees || "0.00") + " " + currencyCode}
              sm={6}
            />
            <KeyValuePair
              title={t("deliveryType")}
              value={trackingData?.deliveryType?.name ?? "--"}
              sm={6}
            />
            <KeyValuePair
              title={t("deliveredAmount")}
              value={
                (trackingData?.deliveredAmount || "0.00") + " " + currencyCode
              }
              sm={6}
            />
            <KeyValuePair
              title={t("customerDueAmount")}
              value={(trackingData?.customerDue || "0.00") + " " + currencyCode}
              sm={6}
            />
            <KeyValuePair
              title={t("returningDueFees")}
              value={
                (trackingData?.returningDueFees || "0.00") + " " + currencyCode
              }
              sm={6}
            />
            <KeyValuePair
              title={t("partialReturnStatusName")}
              value={trackingData?.returnStatus?.name ?? "--"}
              sm={6}
            />
            <KeyValuePair
              title={t("returnedValue")}
              value={
                (trackingData?.returnedValue || "0.00") + " " + currencyCode
              }
              sm={6}
            />
            <KeyValuePair
              title={t("collectedFees")}
              value={
                (trackingData?.collectedFees || "0.00") + " " + currencyCode
              }
              sm={6}
            />
            {trackingData?.paymentType?.code === "CRDT" && (
              <Fragment>
                <KeyValuePair
                  title={t("tax")}
                  value={(trackingData?.tax || "0.00") + " " + currencyCode}
                  sm={6}
                />
                <KeyValuePair
                  title={t("postFees")}
                  value={
                    (trackingData?.postFees || "0.00") + " " + currencyCode
                  }
                  sm={6}
                />
              </Fragment>
            )}
          </Paper>
        </Grid>
        {warehousing && productList.length > 0 && (<Grid xs={12} justifyContent="center">
          <Paper container className={classes.paper} component={Grid}>
            <Toolbar className={clsx(classes.toolbar)} variant="dense">
              <Typography variant="h6">
                {t("productList")}
              </Typography>
            </Toolbar>
            <TableFixedHeaderWraper >
              <Table aria-labelledby="tableTitle" aria-label="enhanced table">
                <TableHead>
                  <TableRow>
                    <FixedTableCell>#</FixedTableCell>
                    <FixedTableCell>{t("name")}</FixedTableCell>
                    <FixedTableCell>{t("quantity")}</FixedTableCell>
                    {trackingData?.status?.code === 'DTR' && (<FixedTableCell>{t("delivered")}</FixedTableCell>)}
                    {trackingData?.status?.code === 'DTR' && (<FixedTableCell>{t("returned")}</FixedTableCell>)}
                    <FixedTableCell>{t("price")}</FixedTableCell>
                    <FixedTableCell>{t("weight")}</FixedTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {productList.map((product, index) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={index}
                      // className={classes.tableBodyRow}
                      >
                        <FixedTableCell>{index + 1}</FixedTableCell>
                        <CellLink
                          align="left"
                          pathname={`/admin/products/${product?.product?.id}`}
                        >
                          {product.product.name}
                        </CellLink>
                        <FixedTableCell>{product.quantity}</FixedTableCell>
                        {trackingData?.status?.code === 'DTR' && (<FixedTableCell className={clsx(classes.delivered)}>{product?.delivered}</FixedTableCell>)}
                        {trackingData?.status?.code === 'DTR' && (<FixedTableCell className={clsx(classes.returned)}>{product?.returned}</FixedTableCell>)}
                        <FixedTableCell>{product.price}</FixedTableCell>
                        <FixedTableCell>{product.product.weight}</FixedTableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableFixedHeaderWraper>
          </Paper>
        </Grid>)}
      </Grid>
    ) : null;

  const transactionTable =
    !loading && trackingData ? (
      <Grid
        container
        sx={{ m: 0 }}
        className={clsx(classes.table, classes.tabpanel)}
      >
        <TableFixedHeaderWraper component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <FixedTableCell>{t("date")}</FixedTableCell>
                <FixedTableCell align="left">{t("code")}</FixedTableCell>
                <FixedTableCell align="left">{t("status")}</FixedTableCell>
                <FixedTableCell align="left">{t("branch")}</FixedTableCell>
                <FixedTableCell align="left">
                  {t("manifestName")}
                </FixedTableCell>
                <FixedTableCell align="left">
                  {t("manifestCode")}
                </FixedTableCell>
                <FixedTableCell align="left">
                  {t("shippingAgent")}
                </FixedTableCell>
                <FixedTableCell align="left">
                  {t("transactionTypeName")}
                </FixedTableCell>
                <FixedTableCell align="left">
                  {t("deliveryType")}
                </FixedTableCell>
                <FixedTableCell align="left">
                  {t("deliveredAmount")}
                </FixedTableCell>
                <FixedTableCell align="left">{t("returnType")}</FixedTableCell>
                <FixedTableCell align="left">{t("fees")}</FixedTableCell>
                <FixedTableCell align="left">{t("notes")}</FixedTableCell>
                <FixedTableCell align="left">{t("reasonName")}</FixedTableCell>
                <Can
                  permission="shipping.shipment.choose_customer"
                  showException
                >
                  <FixedTableCell align="left">{t("updatedBy")}</FixedTableCell>
                </Can>
              </TableRow>
            </TableHead>
            <TableBody>
              {trackingData?.transactions.map((items, index) => {
                return (
                  <TableRow key={index}>
                    <FixedTableCell component="th" scope="row">
                      {items.date}
                    </FixedTableCell>
                    <FixedTableCell align="left">
                      {items?.shipmentStatus.code}
                    </FixedTableCell>
                    <TableCellColor
                      align="left"
                      code={items?.shipmentStatus.code}
                    >
                      {items?.shipmentStatus.name}
                    </TableCellColor>
                    {/* <FixedTableCell align="left">
                  {items?.shipmentStatus?.name}
                </FixedTableCell> */}
                    <FixedTableCell align="left">
                      {items?.branch?.name}
                    </FixedTableCell>
                    <FixedTableCell align="left">
                      {items?.trxType?.type?.name}
                    </FixedTableCell>
                    {Globals.supportedTransactionType.some(
                      (i) => i === items?.manifest?.transactionType?.type?.code
                    ) && user?.hasPermission(GetManifestPermissionSlug(items?.manifest?.transactionType?.type?.code.toLowerCase(), 'list')) ? (
                      <CellLink
                        align="left"
                        pathname={`/admin/manifests/${items?.manifest?.id}`}
                      >
                        {items?.manifest?.code}
                      </CellLink>
                    ) : (
                      <FixedTableCell align="left">
                        {items?.manifest?.code}
                      </FixedTableCell>
                    )}
                    {items?.deliveryAgent ? (
                      <CellLink
                        align="left"
                        pathname={`/admin/delivery-agents/${items?.deliveryAgent?.id}`}
                      >
                        {items?.deliveryAgent?.name}
                      </CellLink>
                    ) : (
                      <FixedTableCell align="left"></FixedTableCell>
                    )}

                    {items?.trxType ? (
                      <CellLink
                        align="left"
                        pathname={`/admin/shipment-transaction-types/${items?.trxType?.id}`}
                      >
                        {items?.trxType?.name}
                      </CellLink>
                    ) : (
                      <FixedTableCell align="left"></FixedTableCell>
                    )}
                    <FixedTableCell align="left">
                      {items?.returnType?.code === "PRTS"
                        ? items?.returnType?.name
                        : null ?? items?.deliveryType?.name}
                    </FixedTableCell>
                    <FixedTableCell align="left">
                      {items?.deliverdAmount + " " + currencyCode}
                    </FixedTableCell>
                    <FixedTableCell align="left">
                      {items?.returnType?.name}
                    </FixedTableCell>
                    <FixedTableCell align="left">
                      {items?.fees + " " + currencyCode}
                    </FixedTableCell>
                    <FixedTableCell align="left">
                      {items?.manifestShipment?.notes}
                    </FixedTableCell>
                    <FixedTableCell align="left">
                      {items?.cancellationReason?.name}
                    </FixedTableCell>
                    <Can
                      permission="shipping.shipment.choose_customer"
                      showException
                    >
                      {items?.createdBy ? (
                        <CellLink
                          align="left"
                          pathname={`/admin/users/${items?.createdBy?.id}`}
                        >
                          {items?.createdBy?.username}
                        </CellLink>
                      ) : (
                        <FixedTableCell> </FixedTableCell>
                      )}
                    </Can>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableFixedHeaderWraper>
      </Grid>
    ) : null;

  const deliveryCallTable = (
    <Grid
      container
      sx={{ m: 0 }}
      className={clsx(classes.table, classes.tabpanel)}
    >
      <TableFixedHeaderWraper component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <FixedTableCell>{t("date")}</FixedTableCell>
              <FixedTableCell>{t("duration")}</FixedTableCell>
              <FixedTableCell>{t("phone")}</FixedTableCell>
              <FixedTableCell>{t("type")}</FixedTableCell>
              <FixedTableCell>{t("deliveryAgent")}</FixedTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading && trackingData?.deliveryCalls?.length > 0 && trackingData?.deliveryCalls.map((items, index) => {
              return (
                <TableRow key={index}>
                  <FixedTableCell>{items.createdAt}</FixedTableCell>
                  <FixedTableCell>
                    {moment
                      .utc(items.duration * 1000)
                      .locale("en")
                      .format("HH:mm:ss")}
                  </FixedTableCell>
                  <FixedTableCell>{items.phoneNumber}</FixedTableCell>
                  <FixedTableCell>{items.type.name}</FixedTableCell>
                  <CellLink
                    pathname={`${"/admin/delivery-agents/" + items?.deliveryAgent.id
                      }`}
                  >
                    {items.deliveryAgent.name}
                  </CellLink>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {!loading && !trackingData?.deliveryCalls?.length > 0 && (
          <EmptyTableMessage loading={loading} message={t("noCalls")} />
        )}
      </TableFixedHeaderWraper>

    </Grid>
  );
  const hasMessage = messages?.length > 0;
  const hasMessagesPermission = user.hasPermission(
    "shipping.shipment_message.list"
  );

  const MessageTable = (props) => {
    useEffect(() => {
      if (hasMessagesPermission) {
        startPolling(1 * 60 * 100);
      }
      return () => {
        stopPolling();
      };
    }, []);

    const { startPolling, stopPolling } = useQuery(
      gql`
        ${SHIPMENT_MESSAGES.query}
      `,
      {
        variables: {
          ...(tracking.trackingCode && {
            code: convertArNumToEn(tracking.trackingCode),
          }),
          ...(tracking.trackingId && { id: parseInt(tracking.trackingId) }),
        },
        skip:
          Boolean(!tracking.trackingCode && !tracking.trackingId) ||
          !hasMessagesPermission,
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true,
        // nextFetchPolicy: "network-only",
        pollInterval: 2 * 60 * 100,
        onCompleted: (data) => {
          const shipmentMessages = data.shipment?.messages;
          if (shipmentMessages?.length > messages?.length)
            setMessages(shipmentMessages);
        },
      }
    );
    return (
      <Grid
        container
        sx={{ m: 0 }}
        className={clsx(classes.table, classes.tabpanel)}
      >
        <TableFixedHeaderWraper component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <FixedTableCell>{t("date")}</FixedTableCell>
                <FixedTableCell align="left">{t("user")}</FixedTableCell>
                <FixedTableCell align="left">{t("message")}</FixedTableCell>
                {createMessageCondation && (
                  <FixedTableCell align="left">
                    <IconButton
                      color="primary"
                      onClick={openMessageDialog}
                      size="large"
                    >
                      <Add />
                    </IconButton>
                  </FixedTableCell>
                )}
              </TableRow>
            </TableHead>
            {hasMessage && (
              <TableBody>
                {messages.map((items, index) => {
                  return (
                    <TableRow key={index}>
                      <FixedTableCell component="th" scope="row">
                        {items.createdAt}
                      </FixedTableCell>
                      <CellLink
                        align="left"
                        pathname={`${items.user.account
                          ? items.user.account?.customerId
                            ? "/admin/customers/" +
                            items.user.account?.customerId
                            : "/admin/delivery-agents/" +
                            items.user.account?.deliveryAgentId
                          : "/admin/users/" + items.user.id
                          }`}
                      >
                        {items.user?.account?.name ?? items.user?.username}
                      </CellLink>
                      <FixedTableCell align="left">
                        {items?.body}
                        {items?.image && (
                          <IconButton
                            size="small"
                            onClick={() => openSignatureDialog(items?.image)}
                          >
                            <Image fontSize="small" />
                          </IconButton>
                        )}
                      </FixedTableCell>
                      {createMessageCondation && <FixedTableCell allowPlaceholder={false} />}
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>

          {!hasMessage && (
            <EmptyTableMessage loading={loading} message={t("noMessages")} />
          )}
        </TableFixedHeaderWraper>
      </Grid>
    );
  };

  const tapsArray = [
    {
      tabHead: t("description"),
      panel: description,
    },
    {
      tabHead: t("transactions"),
      panel: transactionTable,
    },
  ];

  const hashKeys = ["description", "transactions"];

  if (hasMessagesPermission) {
    tapsArray.push({
      tabHead: t("messages"),
      panel: <MessageTable />,
    });
    hashKeys.push("messages");
  }

  if (hasDeliveryCalls) {
    tapsArray.push({
      tabHead: t("calls"),
      panel: deliveryCallTable,
    });
    hashKeys.push("calls");
  }

  //////////////// Shipment History //////////////////

  const shipmentHistory = trackingData?.history;

  if (historyPermission) {
    tapsArray.push({
      tabHead: t("history"),
      panel: <ShipmentHistory shipments={shipmentHistory} warehousing={warehousing} shipmentProducts={productList?.length > 0} />,
    });
    hashKeys.push("history");
  }
  if (trackingData && !loading && validCustomer) {
    trackingBody = (
      <Grid
        container
        spacing={3}
        justifyContent="center"
        sx={{ width: "100%", gap: 2 }}
      >
        <Grid container justifyContent="center" className={classes.track}>
          <Grid className={classes.codeSection}>
            <Typography
              className={classes.codeTitle}
              variant="h6"
              color="textSecondary"
            >
              {t("shipmentCode")}
            </Typography>
            <Typography variant="h5" color="primary" sx={{ ml: 1 }}>
              {trackingData?.code}
            </Typography>
          </Grid>

          <Grid container xs={12} justifyContent={"center"}>
            <ShipmentStatus isWidthXs={isWidthXs} active={true}>
              {trackingData?.status?.name}
            </ShipmentStatus>

            <ShipmentStatus
              className={classes.alertHover}
              isWidthXs={isWidthXs}
              active={trackingData?.collected}
              permission={Boolean(collection)}
              code={collection?.code}
              link={collection?.id && `/admin/collections/${collection?.id}`}
            >
              {t("collectedFrom")}{" "}
              {collection?.type?.code === "CUSTM" ? t("customer") : t("agent")}
            </ShipmentStatus>

            <ShipmentStatus
              className={classes.alertHover}
              isWidthXs={isWidthXs}
              active={paidToCustomer.active}
              permission={paidToCustomer.permission}
              code={paidToCustomer.code}
              link={paidToCustomer.link}
            >
              {t("paidToCustomer")}
            </ShipmentStatus>

            <ShipmentStatus
              className={classes.alertHover}
              isWidthXs={isWidthXs}
              active={trackingData?.paidToDeliveryAgent}
              permission={Boolean(paidToDeliveryAgent)}
              code={paidToDeliveryAgent?.code}
              link={
                paidToDeliveryAgent?.id &&
                `/admin/payments/${paidToDeliveryAgent?.id}`
              }
            >
              {t("paidToDeliveryAgent")}
            </ShipmentStatus>
          </Grid>
        </Grid>
        <Grid xs={12} justifyContent="center">
          <CustomizedSteppers
            key={i18n.language}
            statusCode={trackingData?.status?.code}
            inWareHouse={trackingData?.inWarehouse}
          />
        </Grid>

        <CustomTab tapDetails={tapsArray} hashKeys={hashKeys} />

        {/* *******Table******* */}
      </Grid>
    );
  }
  return (
    <Root>
      <CustomDialog
        title={dialogDetails.title}
        fullWidth
        maxWidth="xs"
        onClose={closeConfirmationDialog}
        content={dialogDetails.content}
        open={dialogDetails.state}
        actions={
          dialogDetails.function !== null && (
            <>
              <Button color="primary" onClick={closeConfirmationDialog}>
                {dialogDetails.confirmAction ? t("cancel") : t("close")}
              </Button>
              {dialogDetails.confirmAction && (
                <Button
                  color="primary"
                  disabled={loadDelete || loadDeleteLastAction}
                  onClick={dialogDetails.function}
                >
                  {loadDelete || loadDeleteLastAction ? (
                    <ButtonLoading />
                  ) : (
                    t("confirm")
                  )}
                </Button>
              )}
            </>
          )
        }
      >
        {dialogDetails.function === null && messageForm}
      </CustomDialog>

      {loading || !trackingData ? (
        invalidId
      ) : !validCustomer ? (
        <Can permission="unEditable" />
      ) : (
        <Grid
          container
          spacing={3}
          justifyContent="center"
          sx={{ maxWidth: "100vw" }}
          className={classes.mainTracking}
        >
          <Toolbar className={clsx(classes.toolbar)} variant="regular">
            <Grid
              container
              sx={{ flexWrap: "initial", flexGrow: 1, p: 0 }}
              alignItems="center"
            >
              <Grid className={classes.toolbarIcons}>
                <Can showException permission={canDelete}>
                  <IconButton
                    color={"primary"}
                    onClick={openDeleteDialog}
                    size="large"
                  >
                    <Delete fontSize="inherit" />
                  </IconButton>
                </Can>
                <Can showException permission={canDeleteLastAction}>
                  <Tooltip title={t("deleteLastAction")}>
                    <IconButton
                      color={"primary"}
                      onClick={openDeleteLastShipmentActionDialog}
                      size="large"
                    >
                      <BackspaceOutlined fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                </Can>
                <SecuredNavLink
                  to={{ pathname: `/admin/shipments/${shipmentId}/edit` }}
                  permission={trackingData?.editable}
                >
                  <Tooltip title={t("update")}>
                    <IconButton color={"primary"} size="large">
                      <Edit fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                </SecuredNavLink>
                {shipmentId && (
                  <Can showException permission="shipping.shipment.print">
                    <PrintComponent
                      shipmentsId={shipmentId}
                      oneShipment
                      hidden={["3d"]}
                    />
                  </Can>
                )}
              </Grid>
            </Grid>
          </Toolbar>
          {trackingBody}
        </Grid>
      )}
    </Root>
  );
};

export default ShipmentsView;

const ShipmentStatus = (props) => {
  const { isWidthXs, active, link, className, permission, code } = props;

  const alert = (
    <Alert
      variant="outlined"
      icon={!active ? <RadioButtonUnchecked color={"disabled"} /> : undefined}
      className={clsx(
        classes.alert,
        {
          [classes.alertDisabled]: !active,
        },
        className && className
      )}
    >
      {props.children}
    </Alert>
  );
  return (
    <Grid xs={isWidthXs && 12}>
      {link ? (
        <SecuredNavLink
          className={classes.link}
          permission={permission}
          to={{
            pathname: link,
          }}
        >
          {code ? <Tooltip title={code}>{alert}</Tooltip> : alert}
        </SecuredNavLink>
      ) : (
        alert
      )}
    </Grid>
  );
};
