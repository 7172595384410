import React, { Fragment, useEffect } from "react";
import { styled } from '@mui/material/styles';
import clsx from "clsx";
import {
  Paper,
  Typography,
  Collapse,
  Box,
  Button,
  useTheme,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { CUSTOMER_REQUEST_BY_ID_QUERY, SAVE_CUSTOMER_REQUEST, UPDATE_CUSTOMER_REQUEST_STATUS } from "./Graphql";
import LookupDropdown from "../HOC/CustomComponents/LookupDropdown";
import { useMutation, gql, useQuery } from "@apollo/client";
import { lookupCodeIsValid } from "../HOC/CustomFunctions/lookupCodeIsValid";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { useState } from "react";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import { useSnackbar } from "notistack";
import { pushUrl, windowReplaceUrl } from "../HOC/CustomFunctions/pushUrl";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import ListBranches from "../HOC/ComponentWithSpecificQuery/ListBranches";
import {
  LIST_CUSTOMERS_DROPDOWN,
  LIST_DELIVERY_AGENTS_DROPDOWN,
  LIST_MANIFEST_DROPDOWN,
  LIST_PAYMENT_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import CustomDialog from "../HOC/CustomComponents/CustomDialog";
import { CancelOutlined, Done, DoneAll } from "@mui/icons-material";
import { Can } from "../HOC/CustomComponents/Secured";
import Grid from "@mui/material/Unstable_Grid2";
import moment from "moment";
import { Globals } from "../HOC/Classes/Globals";
import MUIDateTime from "../HOC/MUI/MUIDateTime";
import { useHistory } from "react-router";
import CustomButton from "../HOC/MUI/CustomButton";
import NotFound from "../../Error/NotFound";

const PREFIX = 'CustomerForm';

const classes = {
  spacing: `${PREFIX}-spacing`,
  mainGrid: `${PREFIX}-mainGrid`,
  appBar: `${PREFIX}-appBar`,
  priceList: `${PREFIX}-priceList`,
  button: `${PREFIX}-button`,
  overlay: `${PREFIX}-overlay`,

};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.spacing}`]: {
    width: "100%",
    margin: theme.spacing(2, 0, 0, 0),
  },

  [`& .${classes.mainGrid}`]: {
    width: "100%",
    margin: theme.spacing(0),
    padding: theme.spacing(2),
  },

  [`& .${classes.appBar}`]: {
    position: "relative",
  },

  [`& .${classes.priceList}`]: {
    flexWrap: "nowrap",
  },
  [`& .${classes.button}`]: {
    margin: theme.spacing(1),
  },
  [`& .${classes.overlay}`]: {
    backgroundColor: "#ffffffad",
    position: "absolute",
    zIndex: 2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    top: 0,
    right: 0,
  },
}));

const dateFormat = (date) => moment(date).locale("en").format("YYYY-MM-DD  HH:mm:ss");
const updateStatusPermission = (code) => `shipping.customer_request_${code.toLowerCase()}.update_status`;

const CustomerRequestsForm = (props) => {
  const user = Globals.user
  const isNotAdmin = user.account
  const formType = props.match.params.type?.toUpperCase();
  const [customerRequestType, setCustomerRequestType] = useState(formType ? formType : null);
  const [canEdit, setCanEdit] = useState(true);
  const [customerRequestDataById, setCustomerRequestDataById] = useState({});
  const history = useHistory();
  const [cancelDialog, setCancelDialog] = useState(false);
  const { t } = useTranslation();

  const customerRequestName = {
    PMNT: t("paymentRequest"),
    RTRN: t("returnRequest"),
    MTRL: t("materialRequest"),
  };

  const [date, setDate] = useState({
    date: new Date(),
    timeFrom: null,
    timeTo: null,
  });

  const [autocompleteValues, setAutocompleteValues] = useState({
    payment: null,
    customer: null,
    manifest: null,
    deliveryAgent: null,
    branch: null
  });

  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  const {
    handleSubmit,
    control,
    formState,
    watch,
    setValue,
    getValues,
    setError,
  } = useForm();
  const { errors } = formState;

  const selectDefaultValue = (data) => {
    const parsed = data?.filter((i) => i.name = i.code);
    return parsed;
  };
  const parseData = (data) => {
    return data;
  };

  const customerRequestStatus = customerRequestDataById?.status?.code;

  const updateCustomerRequestPer = customerRequestType && Globals.user.hasPermission(
    updateStatusPermission(customerRequestType)
  );
  const informDeliveryAgentPer = customerRequestType && Globals.user.hasPermission("shipping.customer_request.choose_delivery_agent");

  const openCancelDialog = () => {
    setCancelDialog(true)
  };
  const closeCancelDialog = () => {
    setCancelDialog(false);
  };

  const customerRequestId = parseInt(props.match.params.id);

  const { data: updateCustomerRequest, loading: CustomerRequestLoading } = useQuery(
    gql`
      ${CUSTOMER_REQUEST_BY_ID_QUERY.query}
    `,
    {
      skip: !customerRequestId,
      notifyOnNetworkStatusChange: true,
      variables: { id: customerRequestId },
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        if (data?.customerRequest === null) return;
        const customerRequestData = data.customerRequest;

        setCanEdit(customerRequestData.editable);
        setCustomerRequestDataById(customerRequestData);

        setCustomerRequestType(customerRequestData.type.code)
        const customerParams = [
          "id",
          "payeeName",
          "notes",
        ];
        customerParams.forEach((i) => {
          customerRequestData[i] && setValue(i, customerRequestData[i]);
        });
        setAutocompleteValues({
          payment: customerRequestData?.payment,
          customer: customerRequestData?.customer,
          manifest: customerRequestData?.manifest,
          branch: customerRequestData?.branch,
          deliveryAgent: customerRequestData?.deliveryAgent,
        });
        setDate({
          date: customerRequestData?.date
        });
      },
    }
  );

  const [saveCustomerRequest, { loading: saveCustomerRequestLoading }] = useMutation(
    gql`
      ${SAVE_CUSTOMER_REQUEST.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      onCompleted: (data) => setCustomerRequestDataById(data.saveCustomerRequest),
      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      },
    }
  );

  const [updateCustomerRequestStatusMutation, { loading: updateStatusLoad }] =
    useMutation(
      gql`
        ${UPDATE_CUSTOMER_REQUEST_STATUS.query}
      `,
      {
        fetchPolicy: "no-cache",
        nextFetchPolicy: "no-cache",
        onError: (error) => {
          enqueueSnackbar(error.message, {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            TransitionComponent: Collapse,
          });
        },
      }
    );

  const handelCustomerRequestData = () => {
    let customerRequestDetails = watch();

    customerRequestDetails["date"] = dateFormat(customerRequestDetails["date"]);
    customerRequestDetails["typeCode"] = customerRequestDataById.type.code;
    for (const key in customerRequestDetails) {
      if (customerRequestDetails[key] === "") {
        delete customerRequestDetails[key];
      }
    }
    return customerRequestDetails;
  };

  const informDeliveryAgent = (status) => {
    let customerRequestDetails = handelCustomerRequestData();

    saveCustomerRequest({
      variables: {
        input: {
          ...customerRequestDetails,
        },
      },
    })
      .then((data) => {
        updateCustomerRequestStatusMutation({
          variables: {
            input: {
              id: parseInt(watch("id")),
              statusCode: status ?? "INPROGRESS",
            },
          },
        })
          .then((data) => {
            setCanEdit(data.data.updateCustomerRequestStatus.editable)
            setCustomerRequestDataById(data.data.updateCustomerRequestStatus);
            ["DELIVERED"].includes(status) && pushUrl(props, `/admin/customer-requests/${watch("id")}`);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch(({ graphQLErrors }) => {
        setValidationError(graphQLErrors, setError);
        console.log(graphQLErrors);
      });
  };

  const updateCustomerRequestStatus = (status) => {
    updateCustomerRequestStatusMutation({
      variables: {
        input: {
          id: parseInt(watch("id")),
          statusCode: status,
        },
      },
    })
      .then((data) => {
        setCustomerRequestDataById(data.data.updateCustomerRequestStatus);
        ["CANCELLED"].includes(status) && pushUrl(props, `/admin/customer-requests/${watch("id")}`);
      })
      .catch((error) => {
        console.log(error);
      });
  };



  const informButton = (status) => {
    const buttonCondition = status && status === "NEW" && informDeliveryAgentPer
    const data = watch()
    let disableButton = false
    for (const key in data) {
      if (data[key] === "" && key !== "notes") {
        disableButton = true
      }
    }
    return (
      buttonCondition &&
      customerRequestDataById["deliveryAgent"] && (
        <CustomButton
          className={classes.button}
          disabled={updateStatusLoad || CustomerRequestLoading || disableButton}
          variant="contained"
          size="medium"
          color="primary"
          onClick={() => informDeliveryAgent()}
          name="updateStatus"
          customcolor={theme.palette.info.main}
          loading={updateStatusLoad || CustomerRequestLoading}
          startIcon={
            !updateStatusLoad && <DoneAll />
          }
        >
          {t("informDeliveryAgent")}
        </CustomButton>
      )
    );
  };

  const deliveryTypeCodeIsDeliveryAgent = watch("deliveryTypeCode") === "DELIVERYAGENT"

  const deliveredButton = (status) => {
    const buttonCondition = status && status === "NEW" && !isNotAdmin && !deliveryTypeCodeIsDeliveryAgent
    const data = watch()
    let disableButton = false
    for (const key in data) {
      if (data[key] === "" && key !== "notes") {
        disableButton = true
      }
      if (!deliveryTypeCodeIsDeliveryAgent) {
        delete data["deliveryAgentId"]
      }
    }
    return (
      (buttonCondition || status === "INPROGRESS") && (
        <CustomButton
          className={classes.button}
          disabled={updateStatusLoad || CustomerRequestLoading || disableButton}
          variant="contained"
          size="medium"
          color="primary"
          onClick={() => informDeliveryAgent("DELIVERED")}
          name="updateStatus"
          customcolor={theme.palette.info.main}
          loading={updateStatusLoad || CustomerRequestLoading}
          startIcon={
            !updateStatusLoad && <DoneAll />
          }
        >
          {t("delivered")}
        </CustomButton>
      )
    );
  };

  // const finishButton = (status) => {
  //   const buttonCondition = status && status === "DELIVERED"
  //   const informDeliveryAgentLoading = CustomerRequestLoading;
  //   return (
  //     buttonCondition && (
  //       <CustomButton
  //         className={classes.button}
  //         disabled={updateStatusLoad || informDeliveryAgentLoading}
  //         variant="contained"
  //         size="medium"
  //         customcolor={theme.palette.error.main}
  //         onClick={() => updateCustomerRequestStatus("FINISHED")}
  //         name="updateStatus"
  //         loading={updateStatusLoad || informDeliveryAgentLoading}
  //         startIcon={
  //           !updateStatusLoad && <DoneAll />
  //         }
  //       >
  //         {t("finish")}
  //       </CustomButton>
  //     )
  //   );
  // };

  const customerRequestData = updateCustomerRequest?.customerRequest;

  const lookupsComplete = (data, updateValue, name) => {
    if (updateValue && lookupCodeIsValid(data, updateValue)) {
      setValue(name, updateValue);
    }
  };

  const customerRequestTypeFilters = customerRequestType === "PMNT" ? ["all"] : ["DELIVERYAGENT", "OFFICE"];

  const onSubmit = (data) => {
    for (const key in data) {
      if (data[key] === "") {
        delete data[key];
      }
    }
    saveCustomerRequest({
      variables: {
        input: {
          ...data,
          typeCode: customerRequestType,
          date: dateFormat(data.date),
        },
      },
    })
      .then((data) => {
        setValue("id", parseInt(data?.data?.saveCustomerRequest?.id));
        const url = history.createHref({
          pathname: `/admin/customer-requests/${data?.data?.saveCustomerRequest?.id}/edit`,
        });
        windowReplaceUrl(url);
        enqueueSnackbar(t("saveSuccessful"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      })
      .catch(({ graphQLErrors }) => {
        setValidationError(graphQLErrors, setError);
        console.log(graphQLErrors);
      });
  };

  const showDeliveryAgent = informDeliveryAgentPer && deliveryTypeCodeIsDeliveryAgent

  const paymentField = (type, admin) => {
    const parseData = (data) => {
      const parsed = data?.filter((i) => i.name = i.code);
      return parsed;
    };
    if (autocompleteValues.payment) {

      autocompleteValues.payment.name = autocompleteValues.payment.code;
    }
    if (type === "PMNT" && admin) {
      return (
        <Grid xs={12} sm={6} md={showDeliveryAgent ? 4 : 6}>
          <CustomAutocomplete
            control={control}
            name={"paymentId"}
            disabled={!watch('customerId')}
            hideCode={true}
            label={t("payment")}
            skip={!watch('customerId')}
            rules={{ required: t("fieldIsRequired") }}
            parseData={(data) => parseData(data)}
            query={LIST_PAYMENT_DROPDOWN.query}
            variables={{
              input: {
                type: "CUSTM",
                forCustomerRequest: {
                  ...(watch("id") && { id: watch("id") }),
                  ...(!Boolean(isNotAdmin) && { customerId: watch('customerId') })
                }
              },
            }}
            defaultValue={autocompleteValues.payment}
          />
        </Grid>
      );
    }
  };

  const mainfestField = (type, admin) => {
    const parseData = (data) => {
      const parsed = data?.filter((i) => i.name = i.code);
      return parsed;
    };
    if (autocompleteValues.manifest) {
      autocompleteValues.manifest.name = autocompleteValues.manifest.code;
    }
    const skip = !watch("deliveryTypeCode") || !watch('customerId') || (deliveryTypeCodeIsDeliveryAgent && !watch('deliveryAgentId'))
    if (type === "RTRN" && admin) {
      return (
        <Grid xs={12} sm={6} md={deliveryTypeCodeIsDeliveryAgent && customerRequestType === "RTRN" ? 4 : 6}>
          <CustomAutocomplete
            control={control}
            name={"manifestId"}
            disabled={skip}
            hideCode={true}
            label={t("manifest")}
            rules={{ required: t("fieldIsRequired") }}
            parseData={(data) => parseData(data)}
            skip={skip}
            query={LIST_MANIFEST_DROPDOWN.query}
            variables={{
              input: {
                transactionTypeCode: deliveryTypeCodeIsDeliveryAgent ? "OTR" : "RTRN",
                forCustomerRequest: {
                  ...(watch("id") && { id: watch("id") }),
                  ...(!Boolean(isNotAdmin) && { customerId: parseInt(watch('customerId')) }),
                },
                ...(deliveryTypeCodeIsDeliveryAgent && { deliveryAgentId: parseInt(watch('deliveryAgentId')) })
              },
            }}
            defaultValue={autocompleteValues.manifest}
          />
        </Grid>
      );
    }
  };
  const deliveryAgentField = () => {
    return (
      <Grid sm={6} xs={12} md={4}>
        <CustomAutocomplete
          control={control}
          errors={errors}
          name={"deliveryAgentId"}
          label={t("shippingAgent")}
          rules={{ ...(watch("id") && customerRequestStatus !== "NEW" && { required: t("fieldIsRequired") }) }}
          parseData={(data) => parseData(data)}
          query={LIST_DELIVERY_AGENTS_DROPDOWN.query}
          defaultValue={autocompleteValues.deliveryAgent}
        />
      </Grid>
    );
  };

  const formCondition = watch("id") ? !CustomerRequestLoading : true;

  const body = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid
        container
        justifyContent="flex-start"
        alignItems="center"
        className={clsx(classes.mainGrid)}
        spacing={2}
      >
        {formCondition ? (
          <Paper container component={Grid} className={clsx(classes.spacing)}>
            <Grid
              justifyContent="space-between"
              xs={12}
              sx={{ display: "flex", flexWrap: "wrap" }}
            >
              <Typography variant="h6">{customerRequestName[customerRequestType]}</Typography>
            </Grid>
            <Grid container sx={{ position: "relative" }} sm={12} xs={12}>
              {!canEdit && (
                <Box
                  className={classes.overlay}
                  sx={{ backgroundColor: "rgb(255 255 255 / 45%)" }}
                ></Box>
              )}
              <Grid sm={6} md={Boolean(isNotAdmin) || customerRequestType === "MTRL" ? 4 : 6} xs={12}>
                <MUIDateTime
                  name="date"
                  label={t("date")}
                  control={control}
                  value={date.date}
                  onChange={(e) => setDate((prev) => ({ ...prev, date: e }))}
                  disablePast
                />
              </Grid>
              <Can showException permission="shipping.customer_request.choose_branch">
                <Grid sm={6} md={customerRequestType === "MTRL" ? 4 : 6} xs={12}>
                  <ListBranches
                    control={control}
                    errors={errors}
                    name={"branchId"}
                    rules={{ required: t("fieldIsRequired") }}
                    defaultValue={autocompleteValues.branch}
                    onChangeValue={(e) => {
                      setValue("deliveryAgentId", "");
                      setValue("customerId", "");
                      // setPickedShipment({ shipments: [] })
                    }}
                    skipDefaultBranch={Boolean(watch("id"))}
                  />
                </Grid>
              </Can>
              <Grid sm={6} xs={12} md={Boolean(isNotAdmin) || customerRequestType === "MTRL" ? 4 : 6} alignItems="flex-start">
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  name={"payeeName"}
                  label={t("payee")}
                  rules={{ required: t("fieldIsRequired") }}
                />
              </Grid>
              <Grid sm={6} xs={12} md={Boolean(isNotAdmin) || (showDeliveryAgent && customerRequestType === "MTRL") ? 4 : 6} alignItems="flex-start">
                <LookupDropdown
                  control={control}
                  errors={errors}
                  name={"deliveryTypeCode"}
                  label={t("deliveryType")}
                  filters={customerRequestTypeFilters}
                  rules={{ required: t("fieldIsRequired") }}
                  variables={{
                    input: { code: "SHP_DELIVERY_METHOD" },
                  }}
                  onChanges={(e) => {
                    if (e.target.value === "OFFICE") {
                      setValue("deliveryAgentId", "");
                    }
                    customerRequestType === "RTRN" && setValue("manifestId", "");
                  }}
                  onCompleted={(data) =>
                    lookupsComplete(
                      data,
                      customerRequestData?.deliveryType?.code,
                      "deliveryTypeCode"
                    )
                  }
                />
              </Grid>
              <Can showException permission={Boolean(!isNotAdmin)}>
                <Grid xs={12} sm={6} md={(showDeliveryAgent || (deliveryTypeCodeIsDeliveryAgent && customerRequestType === "RTRN")) ? 4 : 6}>
                  <CustomAutocomplete
                    control={control}
                    name={"customerId"}
                    label={t("customer")}
                    rules={{ required: t("fieldIsRequired") }}
                    parseData={(data) => parseData(data)}
                    query={LIST_CUSTOMERS_DROPDOWN.query}
                    onChangeValue={(e) => {
                      customerRequestType === "PMNT" && setValue("paymentId", "");
                    }}
                    variables={{
                      input: {
                        active: true,
                      },
                    }}
                    defaultValue={autocompleteValues.customer}
                  />
                </Grid>
              </Can>
              {paymentField(customerRequestType, !Boolean(isNotAdmin))}
              {showDeliveryAgent && deliveryAgentField()}
              {mainfestField(customerRequestType, !Boolean(isNotAdmin))}
              <Grid sm={12} xs={12}>
                <ControlMUItextField
                  name="notes"
                  control={control}
                  label={t("notes")}
                  rows={2}
                />
              </Grid>
            </Grid>
            <Grid sm={12} xs={12} container justifyContent="flex-end">
              {canEdit && <CustomButton
                customcolor={theme.palette.success.main}
                type="submit"
                className={classes.button}
                variant="contained"
                size="medium"
                loading={saveCustomerRequestLoading}
                // className={classes.button}
                disabled={saveCustomerRequestLoading}
                startIcon={!saveCustomerRequestLoading && <Done />}
              >
                {!saveCustomerRequestLoading && t("save")}
              </CustomButton>}
              {updateCustomerRequestPer && watch("deliveryAgentId") && showDeliveryAgent && informButton(customerRequestStatus)}
              {/* {updateCustomerRequestPer && finishButton(customerRequestStatus)} */}
              {updateCustomerRequestPer && deliveredButton(customerRequestStatus)}
              {updateCustomerRequestPer && customerRequestStatus === "NEW" && <CustomButton
                className={classes.button}
                customcolor={theme.palette.error.main}
                variant="contained"
                size="medium"
                color="inherit"
                onClick={openCancelDialog}
                name="cancel"
                // disabled={updateStatusLoad}
                loading={updateStatusLoad}
                startIcon={!updateStatusLoad && <CancelOutlined />}
              >
                {t("cancel")}
              </CustomButton>}
            </Grid>
          </Paper>
        ) : (
          <FullScreenLoading />
        )}

      </Grid>
    </form>
  );
  let DOM;

  if (customerRequestId) {
    DOM = updateCustomerRequest ? body : <FullScreenLoading minHeight="10%" />;
  } else {
    DOM = body;
  }

  return CUSTOMER_REQUEST_BY_ID_QUERY?.customerRequest === null ? (
    <NotFound />
  ) : ["DELIVERED", "CANCELLED"].includes(customerRequestStatus) ? (
    <Can permission="uneEditable" />
  ) : (
    <Root>
      <CustomDialog
        open={cancelDialog}
        title={t("cancelRequest")}
        content={t("cancelRequestMessage")}
        fullWidth
        maxWidth="xs"
        actions={
          <>
            <Button color="primary" onClick={closeCancelDialog}>
              {t("cancel")}
            </Button>
            <Button
              color="primary"
              disabled={updateStatusLoad}
              type="submit"
              onClick={() => {
                updateCustomerRequestStatus("CANCELLED");
              }}
            >
              {cancelDialog && t("confirm")}
              {updateStatusLoad && <ButtonLoading />}
            </Button>
          </>
        }
      />
      {DOM}
    </Root>
  );
};

export default CustomerRequestsForm;
