/* eslint-disable no-useless-concat */
import React, { Fragment } from "react";
import { styled } from '@mui/material/styles';
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { SecuredNavLink } from "../HOC/CustomComponents/Secured";
import { Typography, Paper, Toolbar, IconButton } from "@mui/material";
import { Edit } from "@mui/icons-material";
import { gql, useQuery } from "@apollo/client";
import { KeyValuePair } from "../HOC/CustomComponents/KeyValuePair";
import { COMMISSION_LIST_ID } from "./Graphql";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import DestinationsTable from "./DestinationsTable";
import Grid from "@mui/material/Unstable_Grid2";

const PREFIX = 'CommissionListView';

const classes = {
  loading: `${PREFIX}-loading`,
  box: `${PREFIX}-box`,
  toolbar: `${PREFIX}-toolbar`,
  toolbarIcons: `${PREFIX}-toolbarIcons`,
  iconButton: `${PREFIX}-iconButton`,
  main: `${PREFIX}-main`,
  field: `${PREFIX}-field`
};

const StyledGrid = styled(Grid)((
  {
    theme
  }
) => ({
  [`& .${classes.loading}`]: {},

  [`& .${classes.box}`]: {
    padding: theme.spacing(3),
    flexGrow: 1,
  },

  [`& .${classes.toolbar}`]: {
    borderBottom: "1px #ccd1d6 solid",
    backgroundColor: "#f5f7f9",
    width: "100%",
  },

  [`& .${classes.toolbarIcons}`]: {
    flex: "1 1 100%",
    textAlign: "end",
  },

  [`& .${classes.iconButton}`]: {
    borderRadius: "0 4px 4px 0 ",
    boxShadow: "none",
    height: 45,
  },

  [`& .${classes.main}`]: {
    margin: 0,
    width: "100%",
  },

  [`& .${classes.field}`]: {
    width: 260,
    [theme.breakpoints.down("sm")]: {
      width: 170,
    },
  }
}));

const CommissionListView = (props) => {

  const { t } = useTranslation();
  const commissionListId = props.match.params.id?.trim();
  const { loading, data } = useQuery(
    gql`
      ${COMMISSION_LIST_ID.query}
    `,
    {
      fetchPolicy: "network-only",
      variables: { id: parseInt(commissionListId) },
    }
  );

  return (
    <StyledGrid
      container
      justifyContent="center"
      className={classes.mainTracking}
      sx={{ margin: "0", width: "100%" }}
    >
      <Fragment>
        <Toolbar className={clsx(classes.toolbar)} variant="regular">
          <Grid
            container
            sx={{ flexWrap: "initial", flexGrow: 1 }}
            alignItems="center"
          >
            <Grid className={classes.field}>
              <Typography variant="h6">{t(`commissionDetails`)}</Typography>
            </Grid>
            <Grid className={classes.toolbarIcons}>
              <SecuredNavLink
                permission="shipping.commission_list.update"
                to={{
                  pathname: `/admin/commission-list/${commissionListId}/edit`,
                }}
              >
                <IconButton color={"primary"} size="large">
                  <Edit fontSize="inherit" />
                </IconButton>
              </SecuredNavLink>
            </Grid>
          </Grid>
        </Toolbar>
      </Fragment>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ margin: "0", width: "100%" }}
        sx={{ p: 2, width: "100%" }}
      >
        {loading ? (
          <FullScreenLoading minHeight="25%" />
        ) : (
          <Paper container className={classes.box} component={Grid}>
            <KeyValuePair
              title={t("name")}
              value={data?.commissionList?.name}
            />
            <KeyValuePair
              title={t("code")}
              value={data?.commissionList?.code}
            />
            <KeyValuePair
              title={t("description")}
              value={data?.commissionList?.description}
            />
          </Paper>
        )}
      </Grid>

      {data?.commissionList?.destinations && (
        <DestinationsTable destinations={data.commissionList?.destinations} />
      )}
    </StyledGrid>
  );
};

export default CommissionListView;
