/* eslint-disable no-useless-concat */
import React, { useEffect, useState } from "react";

import { styled } from "@mui/material/styles";

import {
  Button,
  Drawer,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";

import { gql, useQuery } from "@apollo/client";
import { Add, Clear, FilterList, Search } from "@mui/icons-material";
import clsx from "clsx";
import moment from "moment";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  LIST_BRANCHES_DROPDOWN,
  LIST_CUSTOMERS_DROPDOWN,
  LIST_DELIVERY_AGENTS_DROPDOWN,
  LIST_GL_ACCOUNTS_DROPDOWN,
  LIST_SAFES_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import useWidth from "../../Hooks/useWidth";
import CellLink from "../HOC/CustomComponents/CellLink";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import LookupDropdown from "../HOC/CustomComponents/LookupDropdown";
import { SecuredNavLink } from "../HOC/CustomComponents/Secured";
import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";
import { lookupCodeIsValid } from "../HOC/CustomFunctions/lookupCodeIsValid";
import { windowUrl } from "../HOC/CustomFunctions/pushUrl";
import { urlParameters } from "../HOC/CustomFunctions/urlParameters";
import CheckCrossIcons from "../HOC/FunctionComponents/CheckCrossIcons";
import EmptyTableMessage from "../HOC/FunctionComponents/EmptyTableMessage";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import MUIDateRangeCustom from "../HOC/MUI/MUIDateRangeCustom";
import MUIselect from "../HOC/MUI/MUIselect";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import { LIST_VOUCHER_QUERY } from "./VouchersListQuary";
import { dateFormatLL } from "../../helpers/dateFunctions";

const PREFIX = "VoucherList";

const classes = {
  mainTracking: `${PREFIX}-mainTracking`,
  title: `${PREFIX}-title`,
  paper: `${PREFIX}-paper`,
  button: `${PREFIX}-button`,
  toolbar: `${PREFIX}-toolbar`,
  titles: `${PREFIX}-titles`,
  filterForm: `${PREFIX}-filterForm`,
  filterField: `${PREFIX}-filterField`,

  tableIcon: `${PREFIX}-tableIcon`,
  drawer: `${PREFIX}-drawer`,
  drawerPaper: `${PREFIX}-drawerPaper`,
  drawerHeader: `${PREFIX}-drawerHeader`,
  content: `${PREFIX}-content`,
  contentShift: `${PREFIX}-contentShift`,
  iconColor: `${PREFIX}-iconColor`,
};
const drawerWidth = 240;

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.mainTracking}`]: {
    margin: theme.spacing(0, 0),
    width: "100%",
  },

  [`& .${classes.title}`]: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },

  [`& .${classes.paper}`]: {
    display: "grid",
    width: "100%",
    marginBottom: theme.spacing(2),
    borderRadius: 0,
  },

  [`& .${classes.button}`]: {
    position: "sticky",
    bottom: 0,
    zIndex: 1,
    backgroundColor: "#fff",
    marginTop: 10,
    padding: 7,
  },

  [`& .${classes.toolbar}`]: {
    borderBottom: "solid 1px",
    borderBottomColor: theme.palette.divider,
    // zIndex: "1300",
    // justify: "space-between"
  },

  [`& .${classes.titles}`]: {
    flex: "1 1 100%",
  },

  [`& .${classes.filterForm}`]: {
    overflowY: "auto",
    width: "100%",
    margin: 0,
    position: "relative",
    height: "100vh",
  },

  [`& .${classes.filterField}`]: {
    padding: theme.spacing(1),
    width: "100%",
    margin: 0,
  },

  /////////Drawer///////////

  [`& .${classes.drawer}`]: {
    width: drawerWidth,
    flexShrink: 0,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      // height: "100%",
    },
  },

  [`& .${classes.drawerPaper}`]: {
    width: drawerWidth,

    zIndex: "250",

    left: "initial",
    right: "initial",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100%",
    },
  },

  [`& .${classes.drawerHeader}`]: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },

  [`& .${classes.content}`]: {
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
  },

  [`& .${classes.contentShift}`]: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: "auto",
    width: "calc(100% - 240px)",
    [theme.breakpoints.down("sm")]: {
      margin: "auto",
      width: "auto",
    },
  },

  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },
}));

//////////////////////////////////////////////////

function EnhancedTableHead(props) {
  //*********Table Function*********
  const { t } = useTranslation();
  const headCells = [
    "code",
    "date",
    "type",
    "payeeType",
    "payee",
    "branch",
    "safe",
    "amount",
    "notes",
    "approved",
    "glApproved",
  ];
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => {
          return <FixedTableCell key={headCell}>{t(headCell)}</FixedTableCell>;
        })}
      </TableRow>
    </TableHead>
  );
}

/////////////////DIALOG///////////////

const initDate = moment(new Date()).locale("en").format("YYYY-MM-DD");

const VoucherList = (props) => {
  const { t } = useTranslation();
  const urlQuery = urlParameters(window.location.search);

  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);

  const [autocompleteValues, setAutocompleteValues] = useState({
    branch: null,
    customer: null,
    deliveryAgent: null,
    glAccount: null,
    safe: null,
  });

  const [queryVar, setQuaryVar] = useState();

  const initFromDate = urlQuery["fromDate"]
    ? urlQuery["fromDate"]
    : initDate?.["fromDate"] ?? null;
  const initToDate = urlQuery["toDate"]
    ? urlQuery["toDate"]
    : initDate?.["toDate"] ?? null;

  const [dateRange, setDateRange] = useState([initFromDate, initToDate]);

  // const [quaryVar, setQuaryVar] = React.useState({
  //     fromDate: initDate,
  //     toDate: initDate,
  //     first: 10,
  //     page:0
  // });

  const screenWidth = useWidth();

  const [state, setState] = React.useState({
    top: true,
    left: screenWidth === "xs" ? false : true,
    bottom: screenWidth === "xs" ? false : true,
    right: screenWidth === "xs" ? false : true,
  });

  /////////////////////////////////////////////////////////////////////////////////////////////////////

  const {
    formState: { errors },
    handleSubmit,
    control,
    setValue,
    watch,
  } = useForm();

  const { data, loading } = useQuery(
    gql`
      ${LIST_VOUCHER_QUERY.query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      skip: !queryVar,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: {
        first: rowsPerPage,
        page: page + 1,
        input: {
          fromDate: queryVar?.fromDate ?? initDate,
          toDate: queryVar?.toDate ?? initDate,
          ...queryVar,
        },
      },
    }
  );

  const voucherData =
    data?.listVouchers?.data !== null ? data?.listVouchers?.data : null;

  const history = useHistory();

  const pushUrlSearch = (param) => {
    const queryParams = [];
    for (const i in param) {
      encodeURIComponent(param[i]) &&
        queryParams.push(
          encodeURIComponent(i) + "=" + encodeURIComponent(param[i])
        );
    }
    const queryString = queryParams.join("&");
    const url = history.createHref({
      pathname: `/admin/vouchers`,
      search: "?" + queryString,
    });
    windowUrl(url);
  };

  useEffect(() => {
    const { first, page, ...input } = urlQuery;
    if (input["code"]) input["code"] = input["code"].toString();
    setQuaryVar({
      ...input,
    });

    urlQuery["approved"] !== undefined &&
      setValue("approved", urlQuery["approved"]);
    urlQuery["glApproved"] !== undefined &&
      setValue("glApproved", urlQuery["glApproved"]);
    urlQuery["first"] && setRowsPerPage(parseInt(urlQuery["first"]));
    urlQuery["page"] && setPage(parseInt(urlQuery["page"] - 1));
    urlQuery["code"] && setValue("code", urlQuery["code"].toString());

    setDateRange([
      moment(urlQuery["fromDate"]).locale("en").format("YYYY-MM-DD"),
      moment(urlQuery["toDate"]).locale("en").format("YYYY-MM-DD"),
    ]);

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);

    pushUrlSearch({ ...urlQuery, page: newPage + 1 });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);

    pushUrlSearch({ ...urlQuery, page: 1, first: +event.target.value });
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const filterAnchor = screenWidth === "xs" ? "bottom" : "left";

  ////////////////////END DRAWER////////////////

  const onSubmit = (data) => {
    filterAnchor === "bottom" &&
      setState((prev) => ({ ...prev, [filterAnchor]: false }));
    for (const key in data) {
      if (data[key] === "") {
        delete data[key];
      }
    }
    data["fromDate"] = moment(dateRange[0]).locale("en").format("YYYY-MM-DD");
    data["toDate"] = moment(dateRange[1]).locale("en").format("YYYY-MM-DD");
    pushUrlSearch({ ...data, first: rowsPerPage, page: 1 });
    setQuaryVar({
      ...data,
    });
    setPage(0);
  };

  const handelAutocompleteDefaultValue = (data, id, fieldName) => {
    const defaultValue = data[Object.keys(data)[0]].find(
      (i) => i.id === parseInt(id)
    );
    defaultValue &&
      setAutocompleteValues((prev) => ({
        ...prev,
        [fieldName]: defaultValue,
      }));
  };
  const parseData = (data) => {
    return data;
  };

  const lookupsComplete = (data, updateValue, name) => {
    if (updateValue && lookupCodeIsValid(data, updateValue)) {
      setValue(name, updateValue);
    }
  };

  const EnhancedTableToolbar = (props) => {
    // const { numSelected } = props;
    return (
      <Toolbar className={clsx(classes.toolbar)} variant="dense">
        <Typography
          className={classes.titles}
          color="inherit"
          variant="h6"
          component="div"
        >
          {t("vouchers")}
        </Typography>

        <Tooltip title="Filter list">
          <IconButton
            aria-label="filter list"
            onClick={() => props.filter()}
            size="large"
          >
            <FilterList />
          </IconButton>
        </Tooltip>

        <SecuredNavLink
          permission="cash.voucher.create"
          to={{ pathname: `/admin/vouchers/create` }}
        >
          <Tooltip title="create voucher">
            <IconButton
              aria-label="filter list"
              className={classes.addIcon}
              size="large"
            >
              <Add color="primary" />
            </IconButton>
          </Tooltip>
        </SecuredNavLink>
      </Toolbar>
    );
  };

  EnhancedTableToolbar.propTypes = {};

  let voucherAmountTotal = 0;
  let voucherBody;
  voucherBody = (
    <Grid container item xs={12} justifyContent="center" sx={{ width: "100%" }}>
      {/* *******Table******* */}
      <Grid container item>
        <Paper className={clsx(classes.paper)}>
          <EnhancedTableToolbar
            filter={toggleDrawer(filterAnchor, true)}
            {...props}
          />
          {loading || voucherData?.length === 0 ? (
            <EmptyTableMessage
              loading={loading}
              message={t("searchNoResult")}
            />
          ) : (
            <TableFixedHeaderWraper>
              <Table aria-labelledby="tableTitle" aria-label="enhanced table">
                <EnhancedTableHead rowCount={voucherData?.length} />
                <TableBody>
                  {voucherData &&
                    voucherData?.map((row, index) => {
                      voucherAmountTotal += row.amount;

                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={index}
                          // onClick={(e) => {
                          //   e.stopPropagation();
                          //   pushUrl(props, `/admin/vouchers/${row?.id}/edit`);
                          // }}
                        >
                          <CellLink
                            pathname={`/admin/vouchers/${row?.id}`}
                            component="th"
                            scope="row"
                          >
                            {row.code}
                          </CellLink>
                          <FixedTableCell>
                            {dateFormatLL(row?.date)}
                          </FixedTableCell>
                          <FixedTableCell>{row?.type?.name}</FixedTableCell>
                          <FixedTableCell>
                            {row?.payeeType?.name}
                          </FixedTableCell>

                          <CellLinkChecker
                            data={
                              row?.customer ??
                              row?.deliveryAgent ??
                              row?.glAccount
                            }
                            url={
                              row?.customer
                                ? "/admin/customers/"
                                : row?.deliveryAgent
                                ? "/admin/delivery-agents/"
                                : null
                            }
                          />

                          <CellLinkChecker
                            data={row?.branch}
                            url="/admin/branches/"
                          />

                          <CellLinkChecker
                            data={row?.safe}
                            url="/admin/safes/"
                          />

                          <FixedTableCell>{row?.amount}</FixedTableCell>
                          <FixedTableCell>{row?.notes}</FixedTableCell>
                          <FixedTableCell>
                            <CheckCrossIcons active={row?.approved} />
                          </FixedTableCell>
                          <FixedTableCell>
                            <CheckCrossIcons active={row?.glApproved} />
                          </FixedTableCell>
                        </TableRow>
                      );
                    })}
                  <TableRow>
                    <FixedTableCell colSpan={6} />
                    <FixedTableCell variant="head">{t("total")}</FixedTableCell>
                    <FixedTableCell variant="head">
                      {voucherAmountTotal}
                    </FixedTableCell>
                    <FixedTableCell colSpan={3} />
                  </TableRow>
                </TableBody>
              </Table>
            </TableFixedHeaderWraper>
          )}
          <MUITablePagination
            count={data?.listVouchers?.paginatorInfo?.total}
            rowsPerPage={rowsPerPage}
            page={!data?.listVouchers ? 0 : page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Grid>
    </Grid>
  );

  const payeeType = watch("payeeType");

  const clearPayeeFeild = () => {
    setValue("customerId", "");
    setValue("glAccountId", "");
    setValue("deliveryAgentId", "");
  };
  const payeeField = (type) => {
    if (type === "SHPCUST") {
      return (
        <Grid item sm={12} xs={12}>
          <CustomAutocomplete
            control={control}
            errors={errors}
            name={"customerId"}
            label={t("customer")}
            query={LIST_CUSTOMERS_DROPDOWN.query}
            onCompleted={(data) =>
              handelAutocompleteDefaultValue(
                data,
                urlQuery["customerId"],
                "customer"
              )
            }
            parseData={(data) => parseData(data)}
            variables={{
              input: {
                active: true,
              },
            }}
            defaultValue={autocompleteValues.customer}
          />
        </Grid>
      );
    } else if (type === "SHPDLVBY") {
      return (
        <Grid item sm={12} xs={12}>
          <CustomAutocomplete
            control={control}
            errors={errors}
            name={"deliveryAgentId"}
            label={t("shippingAgent")}
            onCompleted={(data) =>
              handelAutocompleteDefaultValue(
                data,
                urlQuery["deliveryAgentId"],
                "deliveryAgent"
              )
            }
            parseData={(data) => parseData(data)}
            query={LIST_DELIVERY_AGENTS_DROPDOWN.query}
            variables={{
              input: {
                active: true,
              },
            }}
            defaultValue={autocompleteValues.deliveryAgent}
          />
        </Grid>
      );
    } else {
      return (
        <Grid item sm={12} xs={12}>
          <CustomAutocomplete
            control={control}
            errors={errors}
            name={"glAccountId"}
            label={t("glAccount")}
            variables={{
              input: {
                typeCode: "SUB",
              },
            }}
            onCompleted={(data) =>
              handelAutocompleteDefaultValue(
                data,
                urlQuery["glAccountId"],
                "glAccount"
              )
            }
            parseData={(data) => parseData(data)}
            query={LIST_GL_ACCOUNTS_DROPDOWN.query}
            defaultValue={autocompleteValues.glAccount}
          />
        </Grid>
      );
    }
  };

  return (
    <Root>
      {props.children}

      <Drawer
        className={clsx(classes.drawer)}
        variant="persistent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor={filterAnchor}
        open={state[filterAnchor]}
        onClose={() => false}
      >
        <div className={classes.drawerHeader} />

        <Toolbar className={clsx(classes.toolbar)} variant="dense">
          <Typography
            className={classes.titles}
            color="inherit"
            variant="h6"
            component="div"
          >
            {t("search")}
          </Typography>
          <IconButton
            aria-label="done"
            size="small"
            // disabled={Boolean(numSelected <= 0)}
            onClick={toggleDrawer(filterAnchor, false)}
            color={"primary"}
          >
            <Clear />
          </IconButton>
        </Toolbar>

        <Grid
          container
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          className={classes.filterForm}
        >
          <Grid
            container
            item
            sm={12}
            justifyContent="flex-start"
            spacing={1}
            className={classes.filterField}
          >
            <Grid item sm={12} xs={12}>
              <MUIDateRangeCustom
                value={dateRange}
                onChangeValue={(value) => setDateRange(value)}
              />
            </Grid>
            <Grid item sm={12} xs={12}>
              <ControlMUItextField
                control={control}
                errors={errors}
                name={"code"}
                label={t("code")}
              />
            </Grid>
            <Grid item sm={12} xs={12}>
              <LookupDropdown
                control={control}
                errors={errors}
                name={"type"}
                label={t("type")}
                variables={{
                  input: { code: "CSH_VOUCHER_TYPES" },
                }}
                onCompleted={(data) =>
                  lookupsComplete(data, urlQuery["type"], "type")
                }
              />
            </Grid>
            <Grid item sm={12} xs={12}>
              <LookupDropdown
                control={control}
                errors={errors}
                name={"payeeType"}
                label={t("payeeType")}
                variables={{
                  input: { code: "PAYEE_TYPE" },
                }}
                onChanges={clearPayeeFeild}
                onCompleted={(data) =>
                  lookupsComplete(data, urlQuery["payeeType"], "payeeType")
                }
              />
            </Grid>
            {payeeField(payeeType)}

            <Grid item sm={12} xs={12}>
              <MUIselect
                control={control}
                errors={errors}
                name={"approved"}
                label={t("status")}
                data={[
                  { key: t("approved"), value: true },
                  { key: t("notApproved"), value: false },
                ]}
                defaultValue={urlQuery?.approved}
              />
            </Grid>

            <Grid container item sm={12}>
              <CustomAutocomplete
                name={"branchId"}
                label={t("currentBranch")}
                control={control}
                errors={errors}
                parseData={(data) => parseData(data)}
                onCompleted={(data) =>
                  handelAutocompleteDefaultValue(
                    data,
                    urlQuery["branchId"],
                    "branch"
                  )
                }
                query={LIST_BRANCHES_DROPDOWN.query}
                defaultValue={autocompleteValues.branch}
              />
            </Grid>
            <Grid item sm={12} xs={12}>
              <MUIselect
                control={control}
                errors={errors}
                name={"glApproved"}
                label={t("glApprove")}
                data={[
                  { key: t("glApproved"), value: true },
                  { key: t("notGlApproved"), value: false },
                ]}
                defaultValue={urlQuery?.approved}
              />
            </Grid>

            <Grid item sm={12} xs={12}>
              <CustomAutocomplete
                control={control}
                errors={errors}
                name={"safeId"}
                label={t("safe")}
                onCompleted={(data) =>
                  handelAutocompleteDefaultValue(
                    data,
                    urlQuery["safeId"],
                    "safe"
                  )
                }
                parseData={(data) => parseData(data)}
                query={LIST_SAFES_DROPDOWN.query}
                defaultValue={autocompleteValues.safe}
              />
            </Grid>
          </Grid>

          <Grid
            container
            item
            sm={12}
            className={classes.button}
            alignItems="center"
            justifyContent="flex-end"
          >
            <Button
              type="submit"
              fullWidth
              variant="contained"
              size="medium"
              color="primary"
              startIcon={<Search />}
            >
              {t("search")}
            </Button>
          </Grid>
        </Grid>
      </Drawer>

      <Grid
        container
        justifyContent="center"
        className={clsx(classes.content, classes.mainTracking, {
          [classes.contentShift]: state[filterAnchor],
        })}
      >
        {voucherBody}
      </Grid>
    </Root>
  );
};

export default VoucherList;

const CellLinkChecker = (props) => {
  const { data, url } = props;
  return url ? (
    <CellLink pathname={`${url}${data?.id}`}>{data?.name}</CellLink>
  ) : (
    <FixedTableCell>{data?.name} </FixedTableCell>
  );
};
