/* eslint-disable no-useless-concat */
import React, { useState, useCallback } from "react";

import { styled } from "@mui/material/styles";

import {
  Grid,
  Typography,
  Table,
  TableBody,
  Toolbar,
  IconButton,
  lighten,
  Box,
  Collapse,
  InputAdornment,
} from "@mui/material";

import { Cancel, CheckCircle } from "@mui/icons-material";
import clsx from "clsx";
import MUItextField from "../../HOC/MUI/MUItextField";
import PropTypes from "prop-types";
import emptyList from "../../../assets/Image/received.png";
import { gql, useQuery } from "@apollo/client";
import * as gqlb from "gql-query-builder";
import { useTranslation } from "react-i18next";
import CustomTableRow from "./CustomTableRow";
import { EnhancedTableHead } from "./ProductListDetails";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import CustomSpinner from "../../HOC/FunctionComponents/CustomSpinner";
import TableFixedHeaderWraper from "../../HOC/CustomComponents/TableWithFixedHeader";
import ManifestShipmentsProvider from "./ManifestProductsProvider";

const TOOLBAR_PREFIX = "styledToolbar";

const toolbarClasses = {
  highlight: `${TOOLBAR_PREFIX}-highlight`,
  title: `${TOOLBAR_PREFIX}-title`,
  filterButton: `${TOOLBAR_PREFIX}-filterButton`,
};

const StyledToolbar = styled("div")(({ theme }) => ({
  width: "100%",
  zIndex: 1,

  [`& .${toolbarClasses.highlight}`]:
    theme.palette.mode === "light"
      ? {
          color: theme.palette.info.main,
          backgroundColor: lighten(theme.palette.info.main, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.info.main,
        },

  [`& .${toolbarClasses.title}`]: {
    flex: "1 1 100%",
  },

  [`& .${toolbarClasses.filterButton}`]: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
}));

const PREFIX = "BarcodeSelector";

const classes = {
  track: `${PREFIX}-track`,
  paper: `${PREFIX}-paper`,
  filters: `${PREFIX}-filters`,
};

const Root = styled(Grid)(({ theme }) => ({
  margin: theme.spacing(0, 0),
  width: "100%",
  backgroundColor: "#fff",

  [`& .${classes.track}`]: {
    marginTop: theme.spacing(2),
  },

  [`& .${classes.paper}`]: {
    width: "100%",
    backgroundColor: "#fff",
    overflowX: "auto",
    maxHeight: `calc(100vh - ${theme.mixins.toolbar["minHeight"]}px)`,
  },

  [`& .${classes.filters}`]: {
    [theme.breakpoints.down("md")]: {
      width: "calc(100% - 72px)",
    },
    [theme.breakpoints.up("sm")]: {
      width: "360px",
    },

    height: "100%",
    overflow: "hidden",
  },
}));

const EnhancedTableToolbar = (props) => {
  const { t } = useTranslation();
  const { numSelected, done, clear, disabled } = props;
  return (
    <StyledToolbar>
      <Toolbar
        className={clsx({
          [toolbarClasses.highlight]: numSelected > 0,
        })}
      >
        {numSelected > 0 ? (
          <Typography
            className={toolbarClasses.title}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {t("shipment", { count: numSelected })}
          </Typography>
        ) : (
          <Typography
            className={toolbarClasses.title}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            {t("shipments")}
          </Typography>
        )}
        {/* <Tooltip title="تم" > */}
        <IconButton
          aria-label="done"
          disabled={disabled}
          onClick={done}
          sx={{ color: "success.main" }}
          size="large"
        >
          <CheckCircle />
        </IconButton>
        {/* </Tooltip> */}

        <IconButton
          aria-label="close"
          onClick={() => clear()}
          color={"primary"}
          size="large"
        >
          <Cancel />
        </IconButton>
      </Toolbar>
    </StyledToolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  done: PropTypes.func.isRequired,
};

//*********Table Function*********

const shipmentQuery = gqlb.query({
  operation: "listShipments",
  fields: [
    {
      operation: "data",
      fields: [
        "id",
        "code",
        "recipientName",
        "recipientAddress",
        "recipientMobile",
        "recipientPhone",
        "description",
        "weight",
        "price",
        "piecesCount",
        "totalAmount",
        "senderName",
        "senderMobile",
        "senderPhone",
        "senderAddress",
        "notes",
        "returningDueFees",
        "customerDue",
        "returnedValue",
        "amount",
        "allDueFees",
        "returnFees",
        {
          transactions: [{ returnType: ["name"] }],
        },
        {
          status: ["name"],
        },
        {
          paymentType: ["name", "code"],
        },
        {
          returnType: ["name"],
        },
        {
          type: ["name", "code"],
        },
        {
          recipientZone: ["id", "name"],
        },
        {
          recipientSubzone: ["name"],
        },
        {
          senderZone: ["id", "name"],
        },
        {
          senderSubzone: ["name"],
        },
        {
          openable: ["name"],
        },
        {
          customer: [
            "id",
            "name",
            "mobile",
            { zone: ["id", "name"] },
            { subzone: ["name"] },
          ],
        },
      ],
      variables: {},
    },
    {
      operation: "paginatorInfo",
      fields: ["total"],
      variables: {},
    },
  ],
  variables: {
    input: {
      type: "ListShipmentsFilterInput",
    },
  },
});

const BarcodeSelector = (props) => {
  const { type, variables, done, setSelected } = props;

  const [shipments, setShipments] = useState([]);

  const [shipmentCode, setShipmentCode] = useState();

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const { register, setValue } = useForm();

  const { loading } = useQuery(
    gql`
      ${shipmentQuery.query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      skip: !variables.id || !shipmentCode,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: {
        input: {
          forManifestId: variables.id,
          code: shipmentCode,
        },
      },
      onCompleted: (data) => {
        const handledData =
          data?.listShipments?.data !== null ? data?.listShipments?.data : [];
        if (handledData.length > 0) {
          const enhancedData = handledData.map((i) => ({
            shipment: i,
            select: true,
            ...(type === "RTS" && { valid: false }),
          }));

          setShipments((prev) => [...prev, ...enhancedData]);
          setValue("barCode", "");
          setShipmentCode(null);
        } else {
          enqueueSnackbar(t("noShipmentWithCode"), {
            variant: "warning",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            TransitionComponent: Collapse,
          });
        }
      },
    }
  );

  const updateSelectedShipmentByForm = useCallback(
    ({ id, checkIsEmpty, name, value }) => {
      const update = [...shipments];
      const shipmentIndex = update.findIndex((i) => {
        return i.shipment.id === id;
      });
      if (checkIsEmpty !== null || checkIsEmpty !== undefined)
        update[shipmentIndex]["valid"] = checkIsEmpty;
      update[shipmentIndex][name] = value;

      setShipments(update);
    },
    [shipments]
  );

  const handleSelectAllClick = (event, rows) => {
    const selected = shipments?.map((i) => {
      if (event.target.checked) {
        return {
          ...i,
          select: true,
        };
      } else {
        return {
          ...i,
          select: false,
        };
      }
    });
    setShipments(selected);
  };

  /////////////////////////////////////////////////////////////////////////////////////////////////////

  const selectedShipment = shipments.filter((i) => i.select === true);
  const selectedShipmentLength = selectedShipment.length;
  const selectedValidShipment = selectedShipment;

  let tableBody = null;
  const handleOnClose = () =>{
    setShipments([])
  }
  const validShipments = () => {
    return type === "RTS"
      ? selectedShipmentLength <= 0 ||
          selectedShipment.some((i) => i.valid === false)
      : selectedShipmentLength <= 0;
  };
  if (shipments) {
    // if (true) {
    tableBody = (
      <Grid container justifyContent="center" sx={{ width: "100%" }}>
        {/* *******Table******* */}
        <Grid container item>
          <div className={classes.paper}>
            <TableFixedHeaderWraper containerScroll>
              <Table
                // className={classes.table}
                aria-labelledby="tableTitle"
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  numSelected={selectedShipmentLength}
                  onSelectAllClick={(e) => handleSelectAllClick(e, shipments)}
                  rowCount={shipments?.length}
                  type={type}
                />
                <TableBody>
                  {
                    // stableSort(, getComparator(order, orderBy))
                    // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    shipments?.map((shipment, index) => {
                      // const isItemSelected = isSelected(row);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <CustomTableRow
                          key={index}
                          shipment={shipment}
                          type={type}
                          // selectedObject={shipments.selected}

                          onChangeCheck={(check, selectedShipment) => {
                            setShipments((prev) => {
                              let updated = [...prev];
                              updated[index] = {
                                ...updated[index],
                                select: !updated[index].select,
                              };

                              return updated;
                            });
                          }}
                          labelId={labelId}
                        />
                      );
                    })
                  }
                </TableBody>
              </Table>
            </TableFixedHeaderWraper>
          </div>
        </Grid>
      </Grid>
    );
  }
  return (
    <ManifestShipmentsProvider
      value={{ updateSelectedShipmentByForm, branchId: variables.branchId }}
    >
      <Root container justifyContent="center">
        <EnhancedTableToolbar
          numSelected={selectedShipmentLength}
          clear={() => {
            handleOnClose();
            done();
            setValue("barCode" , '')
          }}
          done={() => {
            setShipments([]);
            done();
            setSelected(selectedValidShipment);
          }}
          disabled={validShipments()}
        />

        <Grid container item sx={{ p: 3 }} justifyContent="center" xs={12}>
          <MUItextField
            label={t("barcode")}
            autoFocus
            name={"barCode"}
            register={register}
            inputProps={{
              dir: "ltr",
            }}
            readOnly={loading}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {loading && (
                    <CustomSpinner name="PulseLoader" size={5} margin={2} />
                  )}
                </InputAdornment>
              ),
            }}
            defaultValue=""
            onKeyPress={(e) => {
              const value = e.target.value;
              const duplicatedCode = shipments.findIndex(
                (i) => i.shipment.code.toLowerCase() === value.toLowerCase()
              );
              if (duplicatedCode !== -1) {
                enqueueSnackbar(t("thisShipmentIsRepeated"), {
                  variant: "info",
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right",
                  },
                  TransitionComponent: Collapse,
                });
                setValue("barCode", "");
                return;
              }
              if (e.key === "Enter") {
                setShipmentCode(value);
              }
            }}
          />
        </Grid>
        {shipments && shipments?.length !== 0 ? (
          tableBody
        ) : (
          <Grid container item justifyContent="center" alignItems="center">
            <Box textAlign="center" marginTop="50px">
              <img width="50%" src={emptyList} alt={"delivery box"} />
              <Box
                component={Typography}
                variant="h6"
                color="text.secondary"
                marginTop="0"
              >
                {t("noShipments")}
              </Box>
            </Box>
          </Grid>
        )}
      </Root>
    </ManifestShipmentsProvider>
  );
};

export default BarcodeSelector;
