import React, { useEffect, useRef, useState } from "react";

import { styled } from "@mui/material/styles";

import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  SwipeableDrawer,
  Typography,
  useTheme,
} from "@mui/material";

import { gql, useMutation, useQuery } from "@apollo/client";
import { Done, DoneAll } from "@mui/icons-material";
import { Alert } from "@mui/material";
import clsx from "clsx";
import moment from "moment";
import { useForm } from "react-hook-form";
import emptyList from "../../assets/Image/received.png";
import {
  APPROVE_MANIFEST_MUTATION,
  MANIFEST_BY_ID_QUERY,
  SAVE_MANIFEST_MUTATION,
} from "./Graphql";
import ProductList from "./ManifestProducts/ProductList";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";

import Grid from "@mui/material/Unstable_Grid2";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import NotFound from "../../Error/NotFound";
import {
  LIST_BRANCHES_DROPDOWN,
  LIST_CUSTOMERS_DROPDOWN,
  LIST_TRANSACTION_TYPES_DROPDOWN,
  LIST_WAREHOUSE_MANIFEST_DROPDOWN
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import useWidth from "../../Hooks/useWidth";
import ListBranches from "../HOC/ComponentWithSpecificQuery/ListBranches";
import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";
import { pushUrl, windowReplaceUrl } from "../HOC/CustomFunctions/pushUrl";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import CustomButton from "../HOC/MUI/CustomButton";
import BarcodeSelector from "./ManifestProducts/BarcodeSelector";
import { EnhancedTableToolbar } from "./EnhancedTableToolbar";
import useWarehouseManifestDetails from "./WarehouseManifestDetails";
import ProductsTable from "../HOC/CustomComponents/ProductsTable";
import MUIDateTime from "../HOC/MUI/MUIDateTime";

const PREFIX = "ManifestForm";

const classes = {
  track: `${PREFIX}-track`,
  typo: `${PREFIX}-typo`,
  mainTracking: `${PREFIX}-mainTracking`,
  paper: `${PREFIX}-paper`,
  appBar: `${PREFIX}-appBar`,
  title: `${PREFIX}-title`,
  textAlign: `${PREFIX}-textAlign`,
  divider: `${PREFIX}-divider`,
  table: `${PREFIX}-table`,
  filterAnchor: `${PREFIX}-filterAnchor`,
  productList: `${PREFIX}-productList`,
  barcode: `${PREFIX}-barcode`,
  button: `${PREFIX}-button`,
  toolbar: `${PREFIX}-toolbar`,
  selectPadding: `${PREFIX}-selectPadding`,
  margin: `${PREFIX}-margin`,

  errorColor: `${PREFIX}-errorColor`,
  overlay: `${PREFIX}-overlay`,
  pagination: `${PREFIX}-pagination`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.track}`]: {
    marginTop: theme.spacing(2),
  },

  [`& .${classes.typo}`]: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    fontWeight: 600,
  },

  [`& .${classes.mainTracking}`]: {
    margin: theme.spacing(1, 0),
    width: "100%",
  },

  [`& .${classes.paper}`]: {
    marginBottom: theme.spacing(2),
    position: "relative",
    minHeight: "327px",
    gridAutoRows: "max-content",
  },

  [`& .${classes.appBar}`]: {
    position: "relative",
  },

  [`& .${classes.title}`]: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },

  [`& .${classes.textAlign}`]: {
    textAlign: "justify",
    padding: theme.spacing(1),
  },

  [`& .${classes.divider}`]: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
      height: "1px",
    },
  },

  [`& .${classes.table}`]: {
    display: "grid",
    width: "100%",
  },

  [`& .${classes.filterAnchor}`]: {
    [theme.breakpoints.down("md")]: {
      width: "auto",
    },
    // overflow: "scroll",
    "& .MuiDrawer-paper": {
      [theme.breakpoints.up("sm")]: {
        // width: "50%"
      },
      top: "auto",
      width: "25%",
      border: "1px solid #0000001f",
      height: "100%",
      overflow: "hidden",
    },
  },

  [`& .${classes.productList}`]: {
    width: "100%",
    height: "100%",
    overflow: "hidden",
  },

  [`& .${classes.button}`]: {
    margin: theme.spacing(1),
  },

  [`& .${classes.toolbar}`]: {
    backgroundColor: "#fff",
    border: "solid 1px #eee",
    zIndex: "1300",
    justify: "space-between",
  },

  [`& .${classes.selectPadding}`]: {
    paddingTop: "6px",
    paddingBottom: "5px",
  },

  [`& .${classes.margin}`]: {
    margin: theme.spacing(1, 2),
  },

  [`& .${classes.errorColor}`]: {
    color: theme.palette.error.main,
  },

  [`& .${classes.overlay}`]: {
    backgroundColor: "#ffffffad",
    position: "absolute",
    zIndex: 2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    top: 0,
    right: 0,
  },

  [`& .${classes.pagination}`]: {
    position: "absolute",
    bottom: 0,
    right: 0,
  },
}));

const ManifestForm = (props) => {
  const formType = props.match.params.type?.toUpperCase();

  const { t } = useTranslation();

  const dateFormat = (date) => moment(date).locale("en").format("YYYY-MM-DD HH:mm:ss");

  const [manifestType, setManifestType] = useState(formType ? formType : null);
  const [autocompleteValues, setAutocompleteValues] = useState({
    branch: null,
    customer: null,
    deliveryAgent: null,
    transactionType: null,
    cancellationReason: null,
    referenceBranch: null,
    route: null,
    fromManifest: null,
  });

  const theme = useTheme();
  const [disableForm, setDisableForm] = useState(false);
  const [openDialog, setOpenDialog] = useState({
    open: false,
    edit: false,
    approve: false,
  });
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);
  const [pickedProduct, setPickedProduct] = useState({ products: [] });
  const manifestProducts = pickedProduct.products;

  const [manifestData, setManifestData] = useState();

  const manifestId = props.match.params.id;

  const [fieldsState, setFieldsState] = useState({
    createdAt: new Date(),
  });
  const { enqueueSnackbar } = useSnackbar();

  const disabledFirstRender = useRef(false);

  // const typeCode = [
  //   "DEX",
  //   "PKD",
  //   "RTS",
  //   "RJCT",
  //   "RTRN",
  //   "HTR",
  //   "DTR",
  //   "PKR",
  // ].find((i) => i === formType);

  // const defaultTransactionField = `default${typeCode}TransactionType`;

  // const SETTINGS = gqlb.query({
  //   operation: "shippingSettings",
  //   fields: [{ [defaultTransactionField]: ["id", "name"] }],
  //   variables: {},
  // });

  // const chooseCustomerPermission = Globals.user.hasPermission(
  //   "shipping.shipment.choose_customer"
  // );
  // const { data: settings } = useQuery(
  //   gql`
  //     ${SETTINGS.query}
  //   `,
  //   {
  //     fetchPolicy: "no-cache",
  //     skip: !chooseCustomerPermission || !typeCode,
  //     onCompleted: (data) => {
  //       const defaultTransactionType =
  //         data?.shippingSettings?.[defaultTransactionField];
  //       setAutocompleteValues((prev) => ({
  //         ...prev,
  //         transactionType: defaultTransactionType,
  //       }));
  //     },
  //   }
  // );

  const [saveManifetMutation, { loading: saveManifestLoad }] = useMutation(
    gql`
      ${SAVE_MANIFEST_MUTATION.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      onCompleted: (data) => {
        pickedProduct["products"] = manifestProducts.filter(
          (i) => !i["pendingDelete"]
        );

        setManifestData(data["saveWarehouseManifest"]);
      },
    }
  );

  const { details: manifestDetails } = useWarehouseManifestDetails({
    approved: manifestData?.approved,
    setPickedProduct,
    pickedProduct,
    type: manifestType,
    saveManifestLoad,
  });
  const manifestStatus = manifestDetails;

  // const sendWhatsappMessageStorage = localStorage.getItem(
  //   "sendWhatsappMessageStorage"
  // );
  const {
    formState: { errors },
    handleSubmit,
    control,
    setValue,
    watch,
    setError,
    clearErrors,
  } = useForm();

  /******************************************* Start Pagination Functions ********************************************/

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  /******************************************* End Pagination Functions ********************************************/

  /******************************************* Start Mutations ********************************************/
  const [approveManifetMutation, { loading: approveLoad }] = useMutation(
    gql`
      ${APPROVE_MANIFEST_MUTATION.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      onCompleted: (data) => setManifestData(data["approveWarehouseManifest"]),
    }
  );

  useEffect(() => {
    if (disabledFirstRender.current) {
      const products = manifestProducts
        .filter((i) => !i["pendingDelete"])
        .map((item) => ({
          productId: item.product.id,
          ...(manifestType !== "PMBR" && {
            ...(item?.quantity && {
              quantity: item?.quantity,
            }),
          }),
          ...(manifestType === "PADD" && {
            ...(item?.section?.id && {
              sectionId: item?.section?.id,
            }),
          }),
          ...(manifestType === "PMBR" && {
            ...(item?.section?.id && {
              sectionId: item?.section?.id,
            }),
          }),

        }));

      const variables = watch();
      // delete variables["sendWhatsappMessage"];

      for (const key in variables) {
        if (variables[key] === "") {
          variables[key] = null;
        }
      }

      variables["date"] = dateFormat(variables["date"]);

      saveManifetMutation({
        variables: {
          input: {
            ...variables,
            manifestProducts: products,
          },
        },
      })
        .then((data) => { })
        .catch(({ graphQLErrors }) => {
          setValidationError(graphQLErrors, setError);

          console.log(graphQLErrors);
        });
    }
    disabledFirstRender.current = true;
  }, [pickedProduct]);

  /******************************************* End Mutations ********************************************/

  /******************************************* Start Manifest By Id ********************************************/

  const supportedTransactionType = [
    "PADD",
    "PMBT",
    "PRTRN",
    "PMBR",
  ];
  const { data: manifestByIdQuery, loading: manifestByIdLoad } = useQuery(
    gql`
      ${MANIFEST_BY_ID_QUERY.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      skip: !manifestId,
      variables: {
        id: parseInt(manifestId),
      },
      onCompleted: (data) => {
        if (
          data?.warehouseManifest === null ||
          !supportedTransactionType.some(
            (i) => i === data?.warehouseManifest?.transactionType?.type?.code
          )
        ) {
        } else {
          const warehouseManifest = data?.warehouseManifest;
          setManifestType(warehouseManifest?.transactionType?.type.code);

          setManifestData(warehouseManifest);
          setValue("id", parseInt(warehouseManifest?.id));
          setValue("code", warehouseManifest?.code);
          setValue("date", warehouseManifest?.date);

          warehouseManifest?.notes && setValue("notes", warehouseManifest?.notes);

          warehouseManifest?.transactionType?.code &&
            setFieldsState((prev) => ({
              ...prev,
              createdAt: warehouseManifest?.date,
            }));

          setAutocompleteValues({
            branch: warehouseManifest.branch,
            ...(warehouseManifest?.customer && {
              customer: warehouseManifest?.customer,
            }),
            ...(warehouseManifest?.referenceBranch && {
              referenceBranch: warehouseManifest?.referenceBranch,
            }),
            ...(warehouseManifest?.referenceBranch && {
              referenceBranch: warehouseManifest?.referenceBranch,
            }),
            ...(warehouseManifest?.fromManifest && {
              fromManifest: warehouseManifest?.fromManifest,
            }),
            transactionType: warehouseManifest.transactionType,
          });

          pickedProduct["products"] = data.warehouseManifest.manifestProducts;
          setDisableForm(true);
        }
      },
    }
  );

  const setSelect = (obj) => {
    setPickedProduct((prev) => {
      const products = [...prev.products, ...obj];
      // fireMutaion(products)
      return { products: products };
    });
  };

  const [state, setState] = useState({
    productList: {
      left: false,
      bottom: false,
      right: false,
    },
    manifestList: {
      left: false,
      bottom: false,
      right: false,
    },
  });
  const [barcodeDarwerState, setBarcodeDarwerState] = useState({
    left: false,
    bottom: false,
    right: false,
  });

  const productDrawerHandler = (anchor, open) => {
    setState((prev) => ({
      ...prev,
      productList: { ...prev.productList, [anchor]: open },
    }));
  };

  const barcodeDrawerHandler = (anchor, open) => {
    setBarcodeDarwerState((prev) => ({
      ...prev,
      [anchor]: open,
    }));
  };

  const screenWidth = useWidth();

  const anchor = screenWidth === "xs" ? "bottom" : "right";

  const history = useHistory();
  ////////////////////END DRAWER////////////////

  /******************************************* Start OnSubmit ********************************************/
  const isFormValuesChange = (data) => {
    return (
      manifestData &&
      ((manifestType === "PADD" && data?.customerId !== manifestData?.customer?.id) ||
        (manifestType === "PRTRN" && data?.customerId !== manifestData?.customer?.id) ||
        (manifestType === "PMBR" && data?.referenceBranchId &&
          data?.referenceBranchId !== manifestData?.referenceBranch?.id) ||
        (manifestType === "PMBR" && data?.branchId &&
          data?.branchId !== manifestData?.branch?.id) ||
        (manifestType === "PMBR" && data?.fromManifestId &&
          data?.fromManifestId !== manifestData?.fromManifest?.id))
    );
  };

  const onSubmit = (data) => {
    if (manifestProducts.length > 0 && isFormValuesChange(data)) {
      setOpenDialog((prev) => ({ ...prev, open: true, edit: true }));
      return;
    }

    const products = manifestProducts.map((item) => ({
      productId: item.product.id,
      quantity: item.quantity,
      ...(manifestType === "PADD" && {
        ...(item?.section?.id && {
          sectionId: item?.section?.id,
        }),
      }),
    }));

    data["date"] = dateFormat(data["date"]);
    // delete data["sendWhatsappMessage"];

    for (const key in data) {
      if (data[key] === "") {
        data[key] = null;
      }
    }
    const newManifest = {
      ...data,
    };

    saveManifetMutation({
      variables: {
        input: {
          ...newManifest,
          manifestProducts: products,
        },
      },
    })
      .then((data) => {
        setDisableForm(true);
        setValue("code", data?.data?.saveWarehouseManifest?.code);
        setValue("id", parseInt(data?.data?.saveWarehouseManifest?.id));
        // if (!props.match.params.id) {
        //   data?.data?.saveManifest.customer?.id && setValue("customerId", "");
        //   data?.data?.saveManifest.deliveryAgent?.id &&
        //     setValue("deliveryAgentId", "");
        //   setValue("transactionTypeId", "");
        //   setValue("branchId", "");
        // }
        const url = history.createHref({
          pathname: `/admin/warehouse-manifests/${data?.data?.saveWarehouseManifest?.id}/edit`,
        });
        windowReplaceUrl(url);

        // props.history.replace(
        //   `/admin/manifests//edit`
        // );
      })
      .catch(({ graphQLErrors }) => {
        setValidationError(graphQLErrors, setError);
        console.log(graphQLErrors);
      });
  };

  /******************************************* End OnSubmit ********************************************/

  const handleDialogClose = () => {
    setOpenDialog({ edit: false, open: false, approve: false });
  };

  // const whatsappValidation =
  //   (manifestType === "RITS" || manifestType === "OTD") && openDialog.approve;

  const shipLength = manifestProducts.length === 0;

  const approveManifest = () => {
    approveManifetMutation({
      variables: {
        id: parseInt(watch("id")),
        // ...(whatsappValidation && {
        //   sendWhatsappMessage: watch("sendWhatsappMessage"),
        // }),
      },
    })
      .then((data) => {
        handleDialogClose();
        enqueueSnackbar(t("recordConfirmationSucceededMessage"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
        pushUrl(props, `/admin/warehouse-manifests/${watch("id")}`);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const onApproved = () => {
    setOpenDialog((prev) => ({ ...prev, open: true, approve: true }));
  };

  const parseData = (data) => {
    return data;
  };

  /******************************************* Start Fields By Type ********************************************/

  const customerField = (type) => {
    if (type) {
      return (
        <Grid
          sm={manifestType === "PADD" || manifestType === "PRTRN" ? 6 : 4}
          xs={12}
        >
          <CustomAutocomplete
            control={control}
            errors={errors}
            rules={{
              required: t("fieldIsRequired"),
            }}
            name={"customerId"}
            label={t("customer")}
            disabled={!watch("branchId")}
            parseData={(data) => parseData(data)}
            query={LIST_CUSTOMERS_DROPDOWN.query}
            skip={!watch("branchId")}
            variables={{
              input: {
                active: true,
                warehousing: true,
                branchId: {
                  value: watch("branchId"),
                  includeNull: true,
                },
              },
            }}
            defaultValue={autocompleteValues.customer}
          />
        </Grid>
      );
    }
  };

  const branchTransferField = (type) => {
    const branchData = (data) => {
      const parsed = data?.filter((i) => i.id !== watch("branchId"));
      return parsed;
    };
    if (type === "PMBT" || type === "PMBR") {
      return (
        <Grid sm={manifestType === "PMBT" ? 6 : 4} xs={12}>
          <CustomAutocomplete
            control={control}
            errors={errors}
            name={"referenceBranchId"}
            label={type === "PMBT" ? t("toBranch") : t("fromBranch")}
            rules={{ required: t("fieldIsRequired") }}
            parseData={(data) => branchData(data)}
            query={LIST_BRANCHES_DROPDOWN.query}
            defaultValue={autocompleteValues.referenceBranch}
          />
        </Grid>
      );
    }
  };

  const referencetransactionTypeCode = (type) => {
    const parseData = (data) => {
      const parsed = data?.filter((i) => i.name = i.code);
      return parsed;
    };
    if (autocompleteValues.fromManifest) {
      autocompleteValues.fromManifest.name = autocompleteValues.fromManifest.code;
    }
    if (type === "PMBR") {
      return (
        <Grid sm={4} xs={12}>
          <CustomAutocomplete
            control={control}
            errors={errors}
            hideCode={true}
            name={"fromManifestId"}
            label={t("fromManifestId")}
            disabled={!watch("referenceBranchId") || !watch("branchId")}
            rules={{ required: t("fieldIsRequired") }}
            parseData={(data) => parseData(data)}
            skip={!watch("branchId") || !watch("referenceBranchId")}
            variables={{
              input: {
                transactionTypeCode: ['PMBT'],
                withPendingReceiveInBranchId: watch("branchId"),
                branchId: watch("referenceBranchId")
              },
            }}
            query={LIST_WAREHOUSE_MANIFEST_DROPDOWN.query}
            defaultValue={autocompleteValues.fromManifest}
          />
        </Grid>
      );
    }
  };

  // const whatsappSwitch = (
  //   <MuiSwitch
  //     sx={{
  //       flex: "100% 1 1",
  //       padding: (theme) => theme.spacing(0, 1),
  //     }}
  //     edge="end"
  //     name="sendWhatsappMessage"
  //     label={t("sendWhatsappMessage")}
  //     control={control}
  //     onChange={(event) => {
  //       localStorage.setItem(
  //         "sendWhatsappMessageStorage",
  //         event.target.checked
  //       );
  //     }}
  //   />
  // );

  /******************************************* End Fields By Type ********************************************/

  let formBody = null;
  let tableBody = null;

  tableBody = (
    <Grid container xs={12} justifyContent="center" sx={{ width: "100%" }}>
      {/* *******Table******* */}
      <Paper className={clsx(classes.table, classes.paper)}>
        <EnhancedTableToolbar
          {...props}
          classes={classes}
          selectedProduct={manifestProducts}
          manifestType={manifestType}
          addProduct={() => productDrawerHandler(anchor, true)}
          barcode={() => barcodeDrawerHandler(anchor, true)}
          approved={manifestData?.approved}
        />

        {manifestType !== null && (
          <TableFixedHeaderWraper>
            <ProductsTable
              page={page}
              rowsPerPage={rowsPerPage}
              data={manifestProducts}
              headCells={manifestStatus.tableHead}
              parseBodyCell={manifestStatus.tableBody}
              total={manifestStatus.tableTotal}
              sumRow={manifestStatus?.sum}
              sumTableNumber={manifestStatus?.sumTableNumber}
            />
          </TableFixedHeaderWraper>
        )}
        {manifestProducts.length > 4 && (
          <MUITablePagination
            // classeName={classes.pagination}
            count={manifestProducts.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}

        {(!watch("id") || !disableForm) && (
          <Box className={classes.overlay}>
            <Typography color="textPrimary" variant="body1">
              {t("saveWarehouseManifestRequired")}
            </Typography>
          </Box>
        )}
        <Grid container justifyContent="center" alignItems="center">
          {shipLength && watch("id") && disableForm && (
            <Box textAlign="center" marginTop="50px" marginBottom="50px">
              <img width="50%" src={emptyList} alt={"delivery box"} />
              <Box
                component={Typography}
                variant="h6"
                color="text.secondary"
                marginTop="0"
              >
                {t("noProducts")}
              </Box>
            </Box>
          )}
        </Grid>
      </Paper>
    </Grid>
  );

  const formCondition = !formType
    ? Boolean(watch("id")) && !manifestByIdLoad
    : !manifestByIdLoad;

  formBody = (
    <Grid
      container
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      spacing={2}
      sx={{ padding: "24px", width: "100%", margin: "0" }}
    >
      {formCondition && manifestType ? (
        <Paper container component={Grid} sx={{ padding: 1, flexGrow: 1 }}>
          <Grid container sm={12} xs={12} justifyContent="space-between">
            <Typography color="inherit" variant="h6" component="div">
              {manifestStatus?.label}
            </Typography>
            {/* {!shipLength && (
              <IconButton
                disabled={manifestByIdLoad}
                onClick={() =>
                  window.open(
                    `${window.location.origin}/report/print/manifest/${watch(
                      "id"
                    )}/${manifestType}`
                  )
                }
                color="default"
                size="large"
              >
                <Print fontSize="inherit" />
              </IconButton>
            )} */}
          </Grid>
          {watch("id") && disableForm && (
            <Grid sm={12} xs={12}>
              <Alert
                severity="warning"
                action={
                  !manifestData?.approved && (
                    <Button
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setDisableForm(false);
                        // fireMutaion([])
                      }}
                    >
                      {t("update")}
                    </Button>
                  )
                }
              >
                {manifestData?.approved
                  ? t("updateRecordsForbidden")
                  : t("updateRecord")}
              </Alert>
            </Grid>
          )}

          <Grid
            spacing={2}
            container
            sx={{ position: "relative", flexGrow: 1 }}
          >
            {(manifestData?.approved || disableForm) && (
              <Box
                className={classes.overlay}
                sx={{ backgroundColor: "rgb(255 255 255 / 45%)" }}
              >
                {/* <Typography color="textPrimary" variant="body1" >لا يمكن التعديل</Typography> */}
              </Box>
            )}
            <Grid sm={4} xs={12}>
              <ControlMUItextField
                name="code"
                control={control}
                label={t("manifestCode")}
                rules={{ required: watch("id") ? t("fieldIsRequired") : false }}
              />
            </Grid>
            <Grid sm={4} xs={12}>
              <MUIDateTime
                name="date"
                label={t("date")}
                control={control}
                defaultValue={manifestByIdQuery?.manifest?.date}
                value={fieldsState.createdAt}
                onChange={(e) =>
                  setFieldsState((prev) => ({ ...prev, createdAt: e }))
                }
              />
            </Grid>
            <Grid sm={4} xs={12}>
              <ListBranches
                control={control}
                errors={errors}
                name={"branchId"}
                rules={{ required: t("fieldIsRequired") }}
                skip={manifestType === null}
                defaultValue={autocompleteValues.branch}
                skipDefaultBranch={Boolean(manifestId)}
                onChangeValue={(e) => {
                  manifestType === "PMBR" && setValue("fromManifestId", "");
                  (manifestType === "PMBR" || manifestType === "PMBT") &&
                    watch("referenceBranchId") === e.id && setValue("referenceBranchId", "");
                }}
              />
            </Grid>
            <Grid
              sm={
                manifestType === "PADD" ||
                  manifestType === "PMBT" ||
                  manifestType === "PRTRN"
                  ? 6
                  : 4
              }
              xs={12}
            >
              <CustomAutocomplete
                control={control}
                errors={errors}
                selectFirst={true}
                name={"transactionTypeId"}
                label={t("transactionType")}
                rules={{ required: t("fieldIsRequired") }}
                parseData={(data) => parseData(data)}
                query={LIST_TRANSACTION_TYPES_DROPDOWN.query}
                variables={{
                  input: {
                    type: manifestType,
                    ...(watch("branchId") && {
                      branchId: {
                        value: watch("branchId"),
                        includeNull: true,
                      },
                    }),
                  },
                }}
                defaultValue={autocompleteValues.transactionType}
              />
            </Grid>
            {/* {deliveryAgentField(manifestType)}
            {routeField(manifestType)} */}
            {customerField(manifestStatus?.customer)}
            {branchTransferField(manifestType)}
            {referencetransactionTypeCode(manifestType)}
            <Grid sm={12} xs={12}>
              <ControlMUItextField
                name="notes"
                control={control}
                label={t("notes")}
                rows={2}
              />
            </Grid>
          </Grid>

          <Grid sm={12} xs={12} container justifyContent="flex-end">
            {!disableForm && (
              <CustomButton
                customcolor={theme.palette.success.main}
                type="submit"
                className={classes.button}
                disabled={
                  manifestData?.approved || disableForm || saveManifestLoad
                }
                variant="contained"
                size="medium"
                loading={saveManifestLoad}
                // className={classes.button}
                startIcon={!saveManifestLoad && <Done />}
              >
                {!saveManifestLoad && t("save")}
              </CustomButton>
            )}
            {disableForm && (
              <Button
                disabled={
                  shipLength || manifestData?.approved || saveManifestLoad
                }
                className={classes.button}
                variant="contained"
                size="medium"
                color="primary"
                onClick={onApproved}
                name="approved"
                startIcon={<DoneAll />}
              >
                {t("approve")}
              </Button>
            )}
          </Grid>
        </Paper>
      ) : (
        <FullScreenLoading />
      )}
    </Grid>
  );

  const validCondetion =
    manifestByIdQuery?.warehouseManifest !== undefined &&
    !supportedTransactionType.some(
      (i) => i === manifestByIdQuery?.warehouseManifest?.transactionType?.type?.code
    );

  return validCondetion ? (
    <NotFound />
  ) : (
    <Root>
      {props.children}

      {formBody}

      <Dialog
        open={openDialog.open}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"xs"}
      >
        <DialogTitle id="alert-dialog-title">
          {openDialog.edit && t("updateRecord")}
          {openDialog.approve && t("approveRecord")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {openDialog.edit && t("updateWarehouseManifestWarningMessage")}
            {openDialog.approve && t("approveRecordConfirmationMessage")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* {whatsappValidation && whatsappSwitch} */}

          <Button onClick={handleDialogClose} color="primary">
            {t("cancel")}
          </Button>
          <Button
            disabled={approveLoad}
            onClick={() => {
              openDialog.edit && handleDialogClose();
              openDialog.edit && setDisableForm(true);
              openDialog.edit && setPickedProduct({ products: [] });
              openDialog.approve && approveManifest();
            }}
            color="primary"
            autoFocus
          >
            {openDialog.edit && t("update")}
            {openDialog.approve && !approveLoad && t("approve")}
            {approveLoad && <ButtonLoading />}
          </Button>
        </DialogActions>
      </Dialog>

      <SwipeableDrawer
        variant="persistent"
        disableDiscovery={true}
        disableSwipeToOpen={true}
        anchor={anchor}
        open={state.productList[anchor]}
        onClose={() => false}
        onOpen={() => productDrawerHandler(anchor, true)}
        classes={{ paper: classes.productList }}
        dir={theme.direction}
      >
        {state.productList[anchor] && (
          <ProductList
            setSelected={(obj) => {
              setSelect(obj);
            }}
            type={manifestType}
            selected={manifestProducts}
            done={() => productDrawerHandler(anchor, false)}
            variables={watch()}
          />
        )}
      </SwipeableDrawer>

      <SwipeableDrawer
        disableDiscovery={true}
        disableSwipeToOpen={true}
        anchor={anchor}
        open={barcodeDarwerState[anchor]}
        onClose={() => false}
        onOpen={() => barcodeDrawerHandler(anchor, true)}
        sx={{
          width: "auto",
          "& .MuiDrawer-paper": {
            width: { md: "50%" },

            height: "100%",
            overflow: "hidden",
          },
        }}
        dir={theme.direction}
      >
        {barcodeDarwerState[anchor] && <BarcodeSelector
          setSelected={(obj) => {
            setSelect(obj);
          }}
          type={manifestType}
          selected={manifestProducts}
          done={() => barcodeDrawerHandler(anchor, false)}
          variables={watch()}
        />}
      </SwipeableDrawer>

      <Grid
        container
        spacing={3}
        justifyContent="center"
        className={classes.mainTracking}
      >
        {formCondition && tableBody}
      </Grid>
    </Root>
  );
};

export default ManifestForm;
