import {
  Collapse,
  Icon,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import clsx from "clsx";
import React, { useState } from "react";
import { gql, useMutation } from "@apollo/client";
// import MUIDate from '../HOC/MUI/MUIDate';
import * as gqlb from "gql-query-builder";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { LIST_STATEMENTS_DROPDOWN } from "../../GlobalsQuery/ListDropdown/ListDropdown";
import FormButton from "../CustomComponents/Buttons/FormButton";
import ListBranches from "../HOC/ComponentWithSpecificQuery/ListBranches";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import LookupDropdown from "../HOC/CustomComponents/LookupDropdown";
import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import { Can } from "../HOC/CustomComponents/Secured";
import MUIDate from "../HOC/MUI/MUIDate";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import Grid from "@mui/material/Unstable_Grid2";
import config from "../../config.json";
import { Print } from "@mui/icons-material";


const PREFIX = "Sheets";

const classes = {
  divider: `${PREFIX}-divider`,
  ingButton: `${PREFIX}-ingButton`,
  mainSearch: `${PREFIX}-mainSearch`,
  mainGrid: `${PREFIX}-mainGrid`,
  table: `${PREFIX}-table`,
  toolbar: `${PREFIX}-toolbar`,
  tableContainer: `${PREFIX}-tableContainer`,
  balanceFont: `${PREFIX}-balanceFont`,
  fieldsContainer: `${PREFIX}-fieldsContainer`,
  balance: `${PREFIX}-balance`,
  headerToolbar: `${PREFIX}-headerToolbar`,
  errorBox: `${PREFIX}-errorBox`,
  marginTop: `${PREFIX}-marginTop`,
  errorIcon: `${PREFIX}-errorIcon`,
  toolbarHeader: `${PREFIX}-toolbarHeader`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.divider}`]: {
    minWidth: "93%",
  },

  [`& .${classes.ingButton}`]: {
    margin: theme.spacing(2, 1, 2),
    height: "3.5em",
    padding: "0 30px",
    width: "100%",
  },

  [`& .${classes.mainSearch}`]: {
    margin: 0,
    width: "100%",
  },

  [`& .${classes.mainGrid}`]: {
    margin: theme.spacing(3),
  },

  [`& .${classes.table}`]: {
    display: "grid",
    flexGrow: 1,
  },

  [`& .${classes.tableContainer}`]: {
    position: "relative",
    borderRadius: 0,
    boxShadow: "none",
  },

  [`& .${classes.balanceFont}`]: {
    "& .MuiTableCell-body": {
      fontWeight: 600,
      fontSize: "1rem",
    },
  },

  [`& .${classes.fieldsContainer}`]: {
    padding: theme.spacing(1),
    textAlign: "center",
  },

  [`& .${classes.balance}`]: {
    textAlign: "center",
  },

  [`& .${classes.headerToolbar}`]: {
    background: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },

  [`& .${classes.errorBox}`]: {
    transform: "translate(-50%, -50%)",
    top: "50%",
    left: "50%",
    position: "absolute",
    textAlign: "center",
  },

  [`& .${classes.marginTop}`]: {
    marginTop: theme.spacing(1),
  },

  [`& .${classes.errorIcon}`]: {
    fontSize: "7rem",
    color: theme.palette.error.main,
  },
  [`& .${classes.toolbar}`]: {
    width: "100%",
  },
  [`& .${classes.toolbarHeader}`]: {
    flex: 1,
  },
}));

//*********Table Function*********

export const FINANCE = gqlb.query([
  {
    operation: "glAccount",
    fields: [
      {
        operation: "startBalance:balance",
        fields: [],
        variables: {
          startBalanceDate: { name: "date", type: "Date", required: true },
        },
      },
      {
        operation: "endBalance:balance",
        fields: [],
        variables: {
          endBalanceDate: { name: "date", type: "Date", required: true },
          endDayEdge: {
            name: "dayEdge",
            type: "DayStartEnd",
          },
        },
      },
    ],
    variables: {
      glAccountId: {
        name: "id",
        type: "Int",
        required: true,
      },
    },
  },

  {
    operation: "sumJournalEntryRecords",
    fields: ["credit", "debit"],
    variables: {
      input: {
        type: "ListJournalEntryRecordsInput",
      },
    },
  },
  {
    operation: "listJournalEntryRecords",
    fields: [
      {
        paginatorInfo: ["total"],
      },
      {
        data: [
          "id",
          "debit",
          "credit",
          "description",
          { entry: ["id", "date", "code"] },
        ],
      },
    ],
    variables: {
      input: {
        type: "ListJournalEntryRecordsInput",
      },
      first: {
        type: "Int",
      },
      page: {
        type: "Int",
      },
    },
  },
]);

export const CREATE_SHEETS = gqlb.mutation({
  operation: "createSheets",
  fields: [
    "id",
    "code",
    "name",
    "debit",
    "credit",
    "totalDebit",
    "totalCredit",
  ],
  variables: {
    input: {
      type: "SheetInput",
      required: true,
    },
  },
});

export const EXPOERT_SHEETS = gqlb.mutation({
  operation: "exportSheets",
  fields: [],
  variables: {
    statementId: {
      type: "Int",
      required: true,
    },
  },
});

const initFromDate = moment(new Date())
  .locale("en")
  .subtract(1, "month")
  .add(1, "day")
  .format("YYYY-MM-DD");
const initToDate = moment(new Date()).locale("en").format("YYYY-MM-DD");

const Sheets = (props) => {
  const { t } = useTranslation();

  // const theme = useTheme();
  // const dir = theme.direction

  const [dateRange, setDateRange] = useState({
    fromDate: initFromDate,
    toDate: initToDate,
  });

  const [tableData, setTableData] = useState([]);
  const [sum, setSum] = useState({
    credit: 0,
    debit: 0,
    totalCredit: 0,
    totalDebit: 0,
  });

  const { enqueueSnackbar } = useSnackbar();

  const {
    formState: { errors },
    handleSubmit,
    control,
    watch,
    setValue,
    setError,
  } = useForm();

  const [createSheets, { loading }] = useMutation(
    gql`
      ${CREATE_SHEETS.query}
    `,
    {
      onCompleted: (data) => {
        setTableData(data.createSheets);
        const initial = {
          credit: 0,
          debit: 0,
          totalCredit: 0,
          totalDebit: 0,
        };
        data.createSheets.forEach((i) => {
          initial.credit += i.credit;
          initial.debit += i.debit;
          initial.totalCredit += i.totalCredit;
          initial.totalDebit += i.totalDebit;
        });
        setSum(initial);
      },
    }
  );

  const [statement, setStatement] = useState();
  const [sheetData, setSheetData] = useState();
  const parseData = (data) => {
    return data;
  };

  const onSubmit = (data) => {
    for (const key in data) {
      if (data[key] === "") {
        delete data[key];
      }
    }
    const PERIOD = data.typeCode === "PERIOD";
    if (PERIOD) {
      delete data["inDate"];

      data["fromDate"] = moment(data["fromDate"])
        .locale("en")
        .format("YYYY-MM-DD");
      data["toDate"] = moment(data["toDate"]).locale("en").format("YYYY-MM-DD");
    } else {
      data["inDate"] = moment(data["inDate"]).locale("en").format("YYYY-MM-DD");

      delete data["fromDate"];
      delete data["toDate"];
    }

    setSheetData(data)

    createSheets({
      variables: {
        input: data,
      },
    }).catch(({ graphQLErrors }) => {
      console.log(graphQLErrors);
      setValidationError(graphQLErrors, setError);

      enqueueSnackbar(graphQLErrors[0].message, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
        TransitionComponent: Collapse,
      });
    });
  };

  const PERIOD = watch("typeCode") === "PERIOD";

  let getBackendUri = (imgPath) => {
    const domain = config.backend.domain
      ? config.backend.domain
      : window.location.hostname;

    return `${config.backend.protocol}://${domain}:${config.backend.port}/${imgPath}`;
  };
  const [exportSheets] = useMutation(
    gql`
      ${EXPOERT_SHEETS.query}
    `,
    {
      onCompleted: (data) => {
        const path = data.exportSheets;
        window.open(getBackendUri(path));
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );
  const handelExportCustomer = () => {
    exportSheets({
      variables: {
        statementId: watch("statementId")
      },
    });
  };
  const searchParams = (sheetData) => {
    const queryParams = [];
    for (const i in sheetData) {
      if (i === "statementId") {
        queryParams.push(
          "statement=" + statement
        );
      }
      if (i !== "branchId") {
        queryParams.push(
          i + "=" + sheetData[i]
        );
      }
    }
    const queryString = queryParams.join("&");
    return queryString;
  };
  const onPrint = (param) => {
    const params = searchParams(sheetData)
    const domain = `${window.location.origin}`;
    window.open(`${domain}${param}?${params}`);
  };
  return (
    <Root>
      <Grid
        container
        justifyContent="center"
        className={clsx(classes.mainSearch)}
      >
        <Grid
          container
          justifyContent="flex-start"
          alignItems="center"
          // className={clsx(classes.mainGrid)}
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          spacing={2}
          sx={{ p: 2, width: "100%", m: 0 }}
        >
          <Paper container component={Grid} sx={{ flexGrow: 1 }}>
            <Grid sm={12} alignItems="flex-start">
              <Toolbar className={clsx(classes.toolbar)} variant="dense">
                <Typography className={classes.toolbarHeader} variant="h6">
                  {t("createSheets")}
                </Typography>
                <IconButton
                  aria-label="filter list"
                  size="large"
                  onClick={() => onPrint("/sheets")}
                  disabled={!(tableData.length > 0)}
                >
                  <Print />
                </IconButton>
                <IconButton
                  disabled={!(tableData.length > 0)}
                  onClick={() => handelExportCustomer()}
                  size="large"
                >
                  <Icon>sim_card_download</Icon>
                </IconButton>
              </Toolbar>
            </Grid>
            <Grid xs={12} sm={6} alignItems="flex-start">
              <ListBranches
                control={control}
                errors={errors}
                name={"branchId"}
              />
            </Grid>

            <Grid xs={12} sm={6}>
              <CustomAutocomplete
                control={control}
                errors={errors}
                name={"statementId"}
                label={t("manifestName")}
                rules={{ required: t("fieldIsRequired") }}
                parseData={(data) => parseData(data)}
                query={LIST_STATEMENTS_DROPDOWN.query}
                onChangeValue={(e) => {
                  setStatement(e.name);
                }}
              />
            </Grid>

            <Grid xs={12} sm={PERIOD ? 4 : 6} alignItems="flex-start">
              <LookupDropdown
                control={control}
                errors={errors}
                rules={{ required: t("fieldIsRequired") }}
                name={"typeCode"}
                label={t("balanceType")}
                variables={{ input: { code: "GL_SHEET_PERIOD_TYPE" } }}
                onCompleted={(data) => {
                  setValue(
                    "typeCode",
                    data.listLookupEntriesDropdown[0]["code"]
                  );
                }}
              />
            </Grid>

            {PERIOD && (
              <Grid xs={12} sm={4}>
                <MUIDate
                  name={"fromDate"}
                  label={t("fromDate")}
                  control={control}
                  value={dateRange.fromDate}
                  defaultValue={dateRange.fromDate}
                  onChange={(e) =>
                    setDateRange((prev) => ({ ...prev, fromDate: e }))
                  }
                />
              </Grid>
            )}
            {watch("typeCode") && (
              <Grid xs={12} sm={PERIOD ? 4 : 6}>
                <MUIDate
                  name={PERIOD ? "toDate" : "inDate"}
                  label={t(PERIOD ? "toDate" : "date")}
                  control={control}
                  value={dateRange.toDate}
                  defaultValue={dateRange.toDate}
                  onChange={(e) =>
                    setDateRange((prev) => ({ ...prev, toDate: e }))
                  }
                />
              </Grid>
            )}
            <Grid
              sx={{ display: "flex" }}
              justifyContent="flex-end"
              xs={12}
              className={classes.marginTop}
            >
              <FormButton disabled={loading}>
                {loading ? <ButtonLoading /> : t("save")}
              </FormButton>
            </Grid>
          </Paper>
        </Grid>

        {/* *******Table******* */}

        <Can permission={tableData.length > 0} showException>
          <Grid container className={classes.table}>
            <TableFixedHeaderWraper
              component={Paper}
              className={classes.tableContainer}
            >
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <FixedTableCell colSpan={2} allowPlaceholder={false} />

                    <FixedTableCell className={classes.balance} colSpan={2}>
                      {t("partial")}
                    </FixedTableCell>
                    <FixedTableCell className={classes.balance} colSpan={2}>
                      {t("totals")}
                    </FixedTableCell>
                  </TableRow>
                  <TableRow>
                    <FixedTableCell>{t("recordNumber")}</FixedTableCell>
                    <FixedTableCell>{t("description")}</FixedTableCell>
                    <FixedTableCell>{t("debit")}</FixedTableCell>
                    <FixedTableCell>{t("credit")}</FixedTableCell>
                    <FixedTableCell>{t("debit")}</FixedTableCell>
                    <FixedTableCell>{t("credit")}</FixedTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData.map((row, index) => {
                    return (
                      <TableRow key={row.id}>
                        <FixedTableCell>{row.code}</FixedTableCell>
                        <FixedTableCell>{row.name}</FixedTableCell>
                        <FixedTableCell>{row.debit.toFixed(2)}</FixedTableCell>
                        <FixedTableCell>{row.credit.toFixed(2)}</FixedTableCell>
                        <FixedTableCell>
                          {row.totalDebit.toFixed(2)}
                        </FixedTableCell>
                        <FixedTableCell>
                          {row.totalCredit.toFixed(2)}
                        </FixedTableCell>
                      </TableRow>
                    );
                  })}
                  <TableRow className={classes.balanceFont}>
                    <FixedTableCell allowPlaceholder={false}></FixedTableCell>
                    <FixedTableCell>{t("total")}</FixedTableCell>
                    <FixedTableCell>{sum.debit.toFixed(2)}</FixedTableCell>
                    <FixedTableCell>{sum.credit.toFixed(2)}</FixedTableCell>
                    <FixedTableCell>{sum.totalDebit.toFixed(2)}</FixedTableCell>
                    <FixedTableCell>
                      {sum.totalCredit.toFixed(2)}
                    </FixedTableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableFixedHeaderWraper>
          </Grid>
        </Can>
      </Grid>
    </Root>
  );
};
export default Sheets;
