import { gql, useQuery } from "@apollo/client";
import * as gqlb from "gql-query-builder";
import { urlParameters } from "../HOC/CustomFunctions/urlParameters";
import { manifestFields } from "../Manifests/Graphql";
import { manifestTransferFields } from "../ManifestsTransfer/Graphql";
import { paymentFields } from "../Payment/PaymentListQuary";
import { invoiceFields } from "../Invoices/InvoicesListQuary";
import { collectionFields } from "../Collection/CollectionListQuary";
// import { MANIFEST_BY_ID_AND_PRINT } from "../Manifests/Graphql";
// import { PAYMENT_PRINT } from "../Payment/PaymentListQuary";
// import { INVOICES_PRINT } from "../Invoices/InvoicesListQuary";
// import { COLLECTION_PRINT } from "../Collection/CollectionListQuary";
import useManifestDetails from "../Manifests/ManifestDetails";
import usePaymentsDetails from "../Payment/paymentDetails";
import usePaymentPickupsDetails from "../Payment/paymentPickupsDetails";
import useInvoicesDetails from "../Invoices/InvoicesDetails";
import useCollectionsDetails from "../Collection/CollectionDetails";
import useWarehouseManifestDetails from "../ManifestsProducts/WarehouseManifestDetails";
import { warehouseManifestFields } from "../ManifestsProducts/Graphql";
import useManifestTransferDetails from "../ManifestsTransfer/ManifestTransferDetails";
const usePagesCount = (props) => {
  const printType = props.match.params.type;
  const transferType = props.match.params.transfer;
  const manifestId = +props.match.params.id;
  const urlQuery = urlParameters(window.location.search);
  const trxType = props.match.params?.trxType;

  const floatValues = ["fromWeight", "toWeight"];
  
  floatValues.forEach((i) => {
    if (urlQuery[i]) urlQuery[i] = parseFloat(urlQuery[i]);
  });

  const queriesDetails = {
    manifest: {
      queryFields: transferType ? manifestTransferFields : manifestFields,
      withId: "manifest",
      withList: "listManifests",
      listFilters: "ListManifestFilterInput",
      variableType: "transactionTypeCode",
      printTableDetails: transferType ? useManifestTransferDetails : useManifestDetails,
    },
    payment: {
      queryFields: paymentFields,
      withId: "payment",
      withList: "listPayments",
      listFilters: "ListPaymentFilterInput",
      variableType: "type",
      printTableDetails: usePaymentsDetails,
      printTablePickupsDetails: usePaymentPickupsDetails,
    },
    invoice: {
      queryFields: invoiceFields,
      withId: "invoice",
      withList: "listInvoices",
      listFilters: "ListInvoicesFilterInput",
      variableType: "type",
      printTableDetails: useInvoicesDetails,
    },
    collection: {
      queryFields: collectionFields,
      withId: "collection",
      withList: "listCollections",
      listFilters: "ListCollectionFilterInput",
      variableType: null,
      printTableDetails: useCollectionsDetails,
    },
    warehousemanifest: {
      queryFields: warehouseManifestFields,
      withId: "warehouseManifest",
      withList: "listWarehouseManifests",
      listFilters: "ListWarehouseManifestFilterInput",
      variableType: "type",
      printTableDetails: useWarehouseManifestDetails,
    },
  };

  const listCondition = !manifestId;
  const queryType = listCondition ? "withList" : "withId";

  const {
    printTableDetails,
    printTablePickupsDetails,
    queryFields,
    listFilters,
    variableType,
    [queryType]: queryName,
  } = queriesDetails[printType];

  const PAGE_QUERY = gqlb.query({
    operation: queryName,
    fields: [
      {
        operation: "paginatorInfo",
        fields: ["lastPage"],
        variables: {},
      },
    ],
    variables: {
      first: {
        type: "Int",
      },
      input: {
        type: listFilters,
        required: true,
      },
    },
  });

  const { data: lastPage, loading: loadingLastPage } = useQuery(
    gql`
      ${PAGE_QUERY.query}
    `,
    {
      skip: !listCondition,
      nextFetchPolicy: "no-cache",
      fetchPolicy: "no-cache",
      variables: {
        first: 100,
        input: {
          ...urlQuery,
          ...(urlQuery.code && { code: urlQuery.code.toString() }),
          ...(variableType && { [variableType]: trxType }),
        },
      },
    }
  );

  const pagesCount = lastPage?.[queryName]?.paginatorInfo?.lastPage ?? 1;

  let manifestBody;

  if (listCondition) {
    const queryArray = [];
    for (let i = 0; i < pagesCount; i++) {
      const initialQuery = {
        operation: `${queryName}_${i}:${queryName}`,
        fields: [{ data: queryFields }],
        variables: {
          [`page_${i + 1}`]: {
            name: "page",
            type: "Int",
            value: i + 1,
          },
          input: {
            type: listFilters,
            required: true,
          },
          first: {
            type: "Int",
          },
        },
      };
      queryArray.push(initialQuery);
    }

    manifestBody = queryArray;
  }

  if (!listCondition) {
    manifestBody = [
      {
        operation: queryName,
        fields: queryFields,
        variables: {
          id: {
            type: "Int",
            required: true,
          },
        },
      },
    ];
  }

  const MANIFEST_QUERY = gqlb.query([
    ...manifestBody,
    {
      operation: "organization",
      fields: [
        "name",
        "phone",
        "email",
        "website",
        "reportHeader",
        "reportFooter",
      ],
      variables: {},
    },
  ]);

  const { data, loading } = useQuery(
    gql`
      ${MANIFEST_QUERY.query}
    `,
    {
      skip: listCondition && loadingLastPage,
      nextFetchPolicy: "no-cache",
      fetchPolicy: "no-cache",
      variables: {
        ...(listCondition && {
          ...MANIFEST_QUERY.variables,
          first: 100,
          input: {
            ...urlQuery,
            ...(urlQuery.code && { code: urlQuery.code.toString() }),
            ...(variableType && { [variableType]: trxType }),
          },
        }),
        ...(!listCondition && { id: manifestId }),
      },
    }
  );

  const organization = data?.organization;
  let reportDetails;

  if (listCondition) {
    if (data?.[`${queryName}_0`]) {
      let concatData = [];
      for (const key in data) {
        if (key.startsWith(queryName)) {
          const listData = data[key].data;
          concatData = concatData.concat(listData);
        }
      }
      reportDetails = concatData;
    }
  } else {
    reportDetails = data?.[queryName];
  }

  return {
    loading: loading || loadingLastPage,
    reportDetails,
    organization,
    printTableDetails,
    printTablePickupsDetails,
  };
};

export default usePagesCount;
