import React, { useEffect, useState } from "react";

import { styled } from "@mui/material/styles";

import { gql, useQuery } from "@apollo/client";
import { Add, Clear, FilterList, Search } from "@mui/icons-material";
import {
  Button,
  Drawer,
  Grid,
  Icon,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import clsx from "clsx";
import moment from "moment";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import {
  LIST_BRANCHES_DROPDOWN,
  LIST_PAYMENT_TYPES_DROPDOWN,
  LIST_PRICE_LIST_DROPDOWN,
  LSIT_ZONES_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import useWidth from "../../Hooks/useWidth";
import { Globals } from "../HOC/Classes/Globals";
import CellLink from "../HOC/CustomComponents/CellLink";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import { Can, SecuredNavLink } from "../HOC/CustomComponents/Secured";
import ShipmentRegion from "../HOC/CustomComponents/ShipmentRegion";
import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";
import { windowUrl } from "../HOC/CustomFunctions/pushUrl";
import { urlParameters } from "../HOC/CustomFunctions/urlParameters";
import EmptyTableMessage from "../HOC/FunctionComponents/EmptyTableMessage";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import MUIselect from "../HOC/MUI/MUIselect";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import { LIST_CUSTOMERS } from "./Graphql";
import MUIDateRangeCustom from "../HOC/MUI/MUIDateRangeCustom";
import { MultipleAutocomplete } from "../HOC/MUI/MultipleAutocomplete";
import ExportCustomerList from "./ExportCustomersList";
import { GetCustomerPermissionSlug, GetPaymentPermissionSlug } from "../../helpers/getManifestPermissionSlug";



const PREFIX = "CustomerList";

const classes = {
  button: `${PREFIX}-button`,
  iconColor: `${PREFIX}-iconColor`,
  btnMargin: `${PREFIX}-btnMargin`,
  mainSearch: `${PREFIX}-mainSearch`,
  titles: `${PREFIX}-titles`,
  toolbar: `${PREFIX}-toolbar`,
  searchForm: `${PREFIX}-searchForm`,
  searchField: `${PREFIX}-searchField`,
  overlay: `${PREFIX}-overlay`,
  searchPadding: `${PREFIX}-searchPadding`,
  drawer: `${PREFIX}-drawer`,
  drawerPaper: `${PREFIX}-drawerPaper`,
  drawerHeader: `${PREFIX}-drawerHeader`,
  content: `${PREFIX}-content`,
  contentShift: `${PREFIX}-contentShift`,
  tableRow: `${PREFIX}-tableRow`,
  shipmentTable_shipmentStatus: `${PREFIX}-shipmentTable_shipmentStatus`,
  shipmentTable_shipmentCode: `shipmentTable_shipmentCode`,
  tablePaper: `${PREFIX}-tablePaper`,
  table_status: `${PREFIX}-table_status`,
  table_code: `${PREFIX}-table_code`,
  toolbarHeader: `${PREFIX}-toolbarHeader`,
  tableBodyRow: `${PREFIX}-tableBodyRow`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.button}`]: {
    position: "sticky",
    bottom: 0,
    zIndex: 3,
    backgroundColor: "#fff",
    marginTop: 10,
    padding: 7,
  },

  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },

  [`& .${classes.btnMargin}`]: {
    width: "100%",
  },

  [`& .${classes.mainSearch}`]: {
    margin: theme.spacing(0),
    width: "100%",
  },

  [`& .${classes.titles}`]: {
    flex: "1 1 100%",
  },

  [`& .${classes.toolbar}`]: {
    borderBottom: "1px #ccd1d6 solid",
    backgroundColor: "#f5f7f9",
    width: "100%",
    // justifyContent: "space-between",
  },

  [`& .${classes.searchForm}`]: {
    overflowY: "auto",
    width: "100%",
    height: "100%",
    margin: 0,
  },

  [`& .${classes.searchField}`]: {
    padding: theme.spacing(1, 1, 0, 1),
    position: "relative",
    overflow: "hidden",
  },

  [`& .${classes.overlay}`]: {
    width: "100%",
    height: "100%",
    position: "absolute",
    backgroundColor: "#ffffffa8",
    zIndex: 2,
  },

  [`& .${classes.searchPadding}`]: {
    padding: theme.spacing(1),
  },

  //////////////////////Drawer///////////////////

  [`& .${classes.drawer}`]: {
    width: drawerWidth,
    flexShrink: 0,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },

  [`& .${classes.drawerPaper}`]: {
    width: drawerWidth,

    zIndex: "250",
    height: "100%",
    left: "initial",
    right: "initial",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },

  [`& .${classes.drawerHeader}`]: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },

  [`& .${classes.content}`]: {
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
  },

  [`& .${classes.contentShift}`]: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: "auto",
    width: "calc(100% - 240px)",
    [theme.breakpoints.down("sm")]: {
      margin: "auto",
      width: "auto",
    },
  },

  [`& .${classes.tableRow}`]: {
    "&:hover": {
      "& .MuiTableCell-root": {
        backgroundColor: "#f5f5f5",
      },
    },
  },

  [`& .${classes.shipmentTable_shipmentStatus}`]: {
    [theme.breakpoints.up("sm")]: {
      zIndex: "100",
      backgroundColor: "#fff",
      position: "sticky",
      top: "0",
      right: "0",
    },
  },

  [`& .${classes.shipmentTable_shipmentCode}`]: {
    [theme.breakpoints.up("sm")]: {
      zIndex: "100",
      backgroundColor: "#fff",
      position: "sticky",
      top: "0",
      left: "0em",
    },
  },

  [`& .${classes.tablePaper}`]: {
    borderRadius: 0,
    width: "100%",
    display: "grid",
  },

  [`& .${classes.table_status}`]: {
    [theme.breakpoints.up("sm")]: {
      zIndex: "100",
      backgroundColor: "#fff",
      position: "sticky",
      top: "0",
      right: "0",
    },
  },

  [`& .${classes.table_code}`]: {
    [theme.breakpoints.up("sm")]: {
      zIndex: "100",
      backgroundColor: "#fff",
      position: "sticky",
      top: "0",
      left: "0",
    },
  },

  [`& .${classes.toolbarHeader}`]: {
    flex: 1,
  },

  [`& .${classes.tableBodyRow}`]: {
    "&:hover": {
      "& .MuiTableCell-root": {
        backgroundColor: "#f5f5f5",
      },
    },
  },
}));

const drawerWidth = 240;

const CustomerList = (props) => {
  const listType = props.match?.params?.type.toUpperCase();
  const { t } = useTranslation();
  const theme = useTheme();
  const urlQuery = urlParameters(window.location.search);

  const listName = {
    B2C: t("customersList"),
    C2C: t("individualsList"),
  };

  const paymentPermission = [GetPaymentPermissionSlug('custm', 'create'), GetPaymentPermissionSlug('dlvby', 'create')]

  const initFromDate = urlQuery["createdFrom"]
    ? urlQuery["createdFrom"]
    : null;
  const initToDate = urlQuery["createdTo"]
    ? urlQuery["createdTo"]
    : null;
  const validUrlParameters = Object.keys(urlQuery).length !== 0;
  const [dateRange, setDateRange] = useState([initFromDate, initToDate]);

  const [autocompleteValues, setAutocompleteValues] = useState({
    branch: null,
    zone: null,
    subzone: null,
    paymentTypes: [],
  });
  const screenWidth = useWidth();

  const [drawerState, setDrawerState] = React.useState({
    top: true,
    left: screenWidth === "xs" ? false : true,
    bottom: screenWidth === "xs" ? false : true,
    right: screenWidth === "xs" ? false : true,
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm();
  const createdFrom = moment(dateRange[0]).locale("en").format("YYYY-MM-DD");
  const createdTo = moment(dateRange[1]).locale("en").format("YYYY-MM-DD");
  const [rowsPerPage, setRowsPerPage] = useState(urlQuery["rowsPerPage"] ?? 20);
  const initSearch = () => {
    let searchParameters = {
      page: 0,
      refetch: true,
      ...(dateRange[0] && { createdFrom }),
      ...(dateRange[1] && { createdTo }),
    };
    delete urlQuery["rowsPerPage"];
    if (validUrlParameters) {
      if (urlQuery["code"]) urlQuery["code"] = urlQuery["code"].toString();
      if (urlQuery["phone"]) urlQuery["phone"] = urlQuery["phone"].toString();
      if (urlQuery["name"]) urlQuery["name"] = urlQuery["name"].toString();
      if (urlQuery["email"]) urlQuery["email"] = urlQuery["email"].toString();
      if (urlQuery["paymentTypes"])
        urlQuery["paymentTypes"] = urlQuery["paymentTypes"].split(",");
      searchParameters = urlQuery;
    }
    return {
      ...searchParameters,
    };
  };
  const [search, setSearch] = useState(initSearch());

  useEffect(() => {
    urlQuery["active"] !== undefined && setValue("active", urlQuery["active"]);
    urlQuery["warehousing"] !== undefined && setValue("warehousing", urlQuery["warehousing"]);
    urlQuery["name"] && setValue("name", urlQuery["name"]);
    urlQuery["withDue"] && setValue("withDue", urlQuery["withDue"]);
    urlQuery["phone"] && setValue("phone", urlQuery["phone"]);
    urlQuery["email"] && setValue("email", urlQuery["email"]);
    urlQuery["code"] && setValue("code", urlQuery["code"]);
    setAutocompleteValues((prev) => ({
      ...prev,
      ...(urlQuery["paymentTypes"] && {
        paymentTypes: urlQuery["paymentTypes"],
      }),
    }));
    return () => { };
  }, []);

  const history = useHistory();

  const handelAutocompleteDefaultValue = (data, id, fieldName) => {
    const defaultValue = data[Object.keys(data)[0]].find(
      (i) => i.id === parseInt(id)
    );
    defaultValue &&
      setAutocompleteValues((prev) => ({
        ...prev,
        [fieldName]: defaultValue,
      }));
  };
  const pushUrlSearch = (param) => {
    const queryParams = [];
    for (const i in param) {
      encodeURIComponent(param[i]) &&
        queryParams.push(
          encodeURIComponent(i) + "=" + encodeURIComponent(param[i])
        );
    }
    const queryString = queryParams.join("&");

    const url = history.createHref({
      pathname: listType ? `/admin/customers/${listType.toLowerCase()}` : "/admin/customers",
      search: "?" + queryString,
    });
    windowUrl(url);
    //this will not effect on history.location.search
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      (event.type === "keydown" || event.type === "submit") &&
      (event.type === "submit" || event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerState({ ...drawerState, [anchor]: open });
  };
  const filterAnchor = screenWidth === "xs" ? "bottom" : "left";

  const onSubmit = (data) => {
    filterAnchor === "bottom" &&
      setDrawerState({ ...drawerState, [filterAnchor]: false });
    let handledData = {
      ...(dateRange[0] && {
        createdFrom,
      }),
      ...(dateRange[1] && {
        createdTo,
      }),
      active: data.active,
      warehousing: data.warehousing,
      zoneId: data.zoneId,
      subzoneId: data.subzoneId,
      paymentTypes: data.paymentTypes,
      branchId: data.branchId,
      priceListId: data.priceListId,
      name: data.name,
      phone: data.phone,
      email: data.email,
      withDue: data.withDue,
      code: data.code,
      // businessType: data.businessType,
      page: 0,
    };
    data["active"] === "" && delete handledData["active"];
    data["warehousing"] === "" && delete handledData["warehousing"];

    for (const key in handledData) {
      if (
        handledData[key] === undefined ||
        handledData[key] === "" ||
        handledData[key] === null ||
        handledData[key].length === 0
      ) {
        delete handledData[key];
      }
    }
    setSearch((prev) => ({
      ...handledData,
      refetch: !prev.refetch,
    }));
    for (const key in handledData) {
      if (handledData[key] === null) {
        delete handledData[key];
      }
    }
    pushUrlSearch({
      ...handledData,
      rowsPerPage: rowsPerPage,
    });
  };

  const parseData = (data) => {
    return data;
  };

  const handleChangePage = (event, newPage) => {
    pushUrlSearch({
      ...urlQuery,

      page: newPage,
      rowsPerPage: rowsPerPage,
    });

    setSearch((prev) => ({ ...prev, page: newPage }));
  };

  const handleChangeRowsPerPage = (event) => {
    pushUrlSearch({
      ...urlQuery,
      page: 0,
      rowsPerPage: +event.target.value,
    });

    setRowsPerPage(+event.target.value);
    setSearch((prev) => ({ ...prev, page: 0 }));
  };

  const resetDate = () => {
    setDateRange([null, null]);
  };

  const user = Globals.user;
  const codeValidation = Boolean(watch("code"));
  const paymentDue = user.hasPermission(GetPaymentPermissionSlug('dlvby', 'create')) ||
    user.hasPermission(GetPaymentPermissionSlug('custm', 'create'));

  const queryBody = LIST_CUSTOMERS(paymentDue).query;
  const { data, loading } = useQuery(
    gql`
      ${queryBody}
    `,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: {
        first: rowsPerPage,
        page: search?.page + 1,
        input: {
          active: search.active,
          warehousing: search.warehousing,
          ...(listType && { businessTypeCode: listType }),
          ...(search.createdFrom && { createdFrom: search.createdFrom }),
          ...(search.createdTo && { createdTo: search.createdTo }),
          ...(search.code && { code: search.code }),
          ...(search.name && { name: search.name }),
          ...(search.phone && { phone: search.phone }),
          ...(search.email && { email: search.email }),
          ...(search.zoneId && { zoneId: search.zoneId }),
          ...(search.subzoneId && { subzoneId: search.subzoneId }),
          ...(search.paymentTypes && { paymentTypes: search.paymentTypes }),
          ...(search.priceListId && { priceListId: search.priceListId }),
          ...(search.withDue && { withDue: search.withDue }),
          ...(search.branchId && {
            branchId: { value: search.branchId, includeNull: false },
          }),
        },
      },
      onCompleted: (data) => { },
    }
  );

  const customerList =
    data?.listCustomers?.data !== null ? data?.listCustomers?.data : null;

  const exportFilters = {
    active: search.active,
    warehousing: search.warehousing,
    ...(search.createdFrom && { createdFrom: search.createdFrom }),
    ...(search.createdTo && { createdTo: search.createdTo }),
    ...(search.code && { code: search.code }),
    ...(search.name && { name: search.name }),
    ...(search.phone && { phone: search.phone }),
    ...(search.email && { email: search.email }),
    ...(search.zoneId && { zoneId: search.zoneId }),
    ...(listType && { businessTypeCode: listType }),
    // ...(search.businessType && { businessType: search.businessType }),
    ...(search.subzoneId && { subzoneId: search.subzoneId }),
    ...(search.branchId && {
      branchId: { value: search.branchId, includeNull: false },
    }),
    ...(search.withDue && { withDue: search.withDue }),
  }

  const isWarehousing = Globals.settings.warehousing;

  const pagePermission = GetCustomerPermissionSlug("shipping", "customer", listType, "list")
  
  return (
    <Can permission={pagePermission}>
      <Root>
        <Drawer
          className={clsx(classes.drawer)}
          variant="persistent"
          classes={{
            paper: classes.drawerPaper,
          }}
          anchor={filterAnchor}
          open={drawerState[filterAnchor]}
          onClose={() => false}
          dir={theme.direction}
        >
          <div className={classes.drawerHeader} />
          <Toolbar
            variant="dense"
            className={classes.toolbar}
            sx={{ marginBottom: 1 }}
          >
            <Typography
              className={classes.titles}
              color="inherit"
              variant="h6"
              component="div"
            >
              {t("search")}
            </Typography>
            <IconButton
              aria-label="done"
              size="small"
              onClick={toggleDrawer(filterAnchor, false)}
              color={"primary"}
            >
              <Clear />
            </IconButton>
          </Toolbar>

          <Grid
            container
            item
            justifyContent="center"
            alignContent="space-between"
            component={"form"}
            onSubmit={handleSubmit(onSubmit)}
            className={classes.searchForm}
          >
            <Grid
              container
              item
              sm={12}
              justifyContent="flex-start"
              spacing={1}
              className={classes.searchField}
            >
              <Grid container item sm={12} alignItems="flex-start">
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  name={"name"}
                  label={t("name")}
                />
              </Grid>
              <Grid container item sm={12} alignItems="flex-start">
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  name={"code"}
                  label={t("code")}
                />
              </Grid>
              <Grid container item sm={12} alignItems="flex-start">
                <MUIDateRangeCustom
                  startText={t('registrationDate')}
                  value={dateRange}
                  onChangeValue={(value) => setDateRange(value)}
                  disabled={codeValidation}
                  resetDate={resetDate}
                />
              </Grid>
              <Grid container item sm={12} alignItems="flex-start">
                <MUIselect
                  name={"active"}
                  label={t("active")}
                  control={control}
                  errors={errors}
                  data={[
                    { key: t("notActive"), value: false },
                    { key: t("active"), value: true },
                  ]}
                />
              </Grid>
              {isWarehousing && listType === "B2C" && <Grid container item sm={12} alignItems="flex-start">
                <MUIselect
                  name={"warehousing"}
                  label={t("warehousing")}
                  control={control}
                  errors={errors}
                  data={[
                    { key: t("no"), value: false },
                    { key: t("yes"), value: true },
                  ]}
                />
              </Grid>}
              <Can permission={paymentPermission} showException>
                <Grid container item sm={12} alignItems="flex-start">
                  <MUIselect
                    name={"withDue"}
                    label={t("customerDueAmount")}
                    control={control}
                    errors={errors}
                    data={[{ key: t("withDue"), value: "PAY" }]}
                  />
                </Grid>
              </Can>
              <Grid container item sm={12} alignItems="flex-start">
                <MultipleAutocomplete
                  name={"paymentTypes"}
                  label={t("paymentType")}
                  control={control}
                  errors={errors}
                  // variables={}
                  query={LIST_PAYMENT_TYPES_DROPDOWN.query}
                  parseData={(data) =>
                    parseData(data)
                  }
                  multiple
                  valueKey="code"
                  defaultValue={autocompleteValues.paymentTypes}
                />
              </Grid>
              <Grid container item sm={12} alignItems="flex-start">
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  name={"phone"}
                  label={t("phone")}
                />
              </Grid>
              <Grid container item sm={12} alignItems="flex-start">
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  name={"email"}
                  label={t("email")}
                />
              </Grid>
              <Grid container item sm={12} alignItems="flex-start">
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  name={"zoneId"}
                  label={t("zone")}
                  onCompleted={(data) =>
                    handelAutocompleteDefaultValue(
                      data,
                      urlQuery["zoneId"],
                      "zone"
                    )
                  }
                  parseData={(data) => parseData(data)}
                  variables={{ input: { parentId: null } }}
                  query={LSIT_ZONES_DROPDOWN.query}
                  defaultValue={autocompleteValues.zone}
                  onChangeValue={(e) => {
                    setValue("subzoneId", "");
                  }}
                />
              </Grid>
              <Grid container item sm={12} alignItems="flex-start">
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  name={"subzoneId"}
                  label={t("subzone")}
                  onCompleted={(data) =>
                    handelAutocompleteDefaultValue(
                      data,
                      urlQuery["subzoneId"],
                      "subzone"
                    )
                  }
                  parseData={(data) => parseData(data)}
                  query={LSIT_ZONES_DROPDOWN.query}
                  variables={{ input: { parentId: watch("zoneId") } }}
                  skip={!watch("zoneId")}
                  disabled={!watch("zoneId")}
                  defaultValue={autocompleteValues.subzone}
                />
              </Grid>
              <Grid container item sm={12} alignItems="flex-start">
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  name={"branchId"}
                  label={t("branch")}
                  onCompleted={(data) =>
                    handelAutocompleteDefaultValue(
                      data,
                      urlQuery["branchId"],
                      "branch"
                    )
                  }
                  parseData={(data) => parseData(data)}
                  query={LIST_BRANCHES_DROPDOWN.query}
                  defaultValue={autocompleteValues.branch}
                />
              </Grid>
              <Can permission={GetCustomerPermissionSlug("shipping", "price_list", listType, "list")} showException>
                <Grid container item sm={12} alignItems="flex-start">
                  <CustomAutocomplete
                    control={control}
                    errors={errors}
                    name={"priceListId"}
                    label={t("listPriceList")}
                    onCompleted={(data) =>
                      handelAutocompleteDefaultValue(
                        data,
                        urlQuery["priceListId"],
                        "priceList"
                      )
                    }
                    variables={{ input: { businessTypeCode: listType } }}
                    parseData={(data) => parseData(data)}
                    query={LIST_PRICE_LIST_DROPDOWN.query}
                    defaultValue={autocompleteValues.priceList}
                  />
                </Grid>
              </Can>
            </Grid>
            <Grid
              container
              item
              sm={12}
              className={clsx(classes.searchPadding, classes.button)}
              alignItems="center"
              alignContent="flex-end"
              justifyContent="flex-end"
            >
              <Button
                className={clsx(classes.btnMargin)}
                variant="contained"
                color="primary"
                type="submit"
                size="large"
                startIcon={<Search />}
                disabled={loading}
              >
                {t("search")}
              </Button>
            </Grid>
          </Grid>
        </Drawer>

        <Grid
          container
          justifyContent="center"
          spacing={3}
          className={clsx(classes.content, classes.mainSearch, {
            [classes.contentShift]: drawerState[filterAnchor],
          })}
        >
          {/* *******Table******* */}

          <Toolbar className={clsx(classes.toolbar)} variant="dense">
            <Typography className={classes.toolbarHeader} variant="h6">
              {listName[listType]}
            </Typography>
            <IconButton onClick={toggleDrawer(filterAnchor, true)} size="large">
              <FilterList fontSize="inherit" />
            </IconButton>
            <SecuredNavLink
              permission={GetCustomerPermissionSlug("shipping", "customer", listType, "create")}
              to={{ pathname: `/admin/customers/create/${listType.toLowerCase()}` }}
            >
              <IconButton color={"primary"} size="large">
                <Add fontSize="inherit" />
              </IconButton>
            </SecuredNavLink>
            <ExportCustomerList
              disabled={Boolean(customerList?.length === 0)}
              filters={exportFilters}
            />
          </Toolbar>

          {loading || customerList?.length === 0 ? (
            <Grid container item justifyContent="center" className={classes.main}>
              {loading ? (
                <FullScreenLoading minHeight='15%' />
              ) : (
                <EmptyTableMessage
                  loading={false}
                  message={t("searchNoResult")}
                />
              )}
            </Grid>
          ) : (
            <Paper className={classes.tablePaper}>
              <TableFixedHeaderWraper>
                <Table aria-labelledby="tableTitle" aria-label="enhanced table">
                  <TableHead>
                    <TableRow>
                      {(listType === "B2C" || !listType) && <FixedTableCell>{t("storeName")}</FixedTableCell>}
                      <FixedTableCell>{t("name")}</FixedTableCell>
                      <FixedTableCell>{t("code")}</FixedTableCell>
                      <Can permission={paymentPermission} showException>
                        <FixedTableCell>{t("customerDueAmount")}</FixedTableCell>
                      </Can>
                      <FixedTableCell>{t("createdAt")}</FixedTableCell>
                      <FixedTableCell>{t("source")}</FixedTableCell>
                      <FixedTableCell>{t("branch")}</FixedTableCell>
                      <FixedTableCell>{t("phone")}</FixedTableCell>
                      <FixedTableCell>{t("mobile")}</FixedTableCell>
                      <FixedTableCell>{t("email")}</FixedTableCell>
                      <FixedTableCell>{t("active")}</FixedTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {customerList &&
                      customerList?.map((row, index) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.code}
                            className={classes.tableBodyRow}
                          >
                            <CellLink pathname={`/admin/customers/${row?.id}`}>
                              {row?.name}
                            </CellLink>
                            {(listType === "B2C" || !listType) && <FixedTableCell>{row.representative}</FixedTableCell>}
                            <FixedTableCell>{row.code}</FixedTableCell>
                            <Can
                              permission={paymentPermission}
                              showException
                            >
                              <FixedTableCell>{row.paymentDue}</FixedTableCell>
                            </Can>
                            <FixedTableCell>
                              {moment(row.createdAt).format("ll")}
                            </FixedTableCell>
                            <ShipmentRegion
                              pathname={`/admin/zone/${row?.zone?.id}`}
                              zone={row?.zone?.name}
                              subzone={row?.subzone?.name}
                            />
                            {row?.branch ? (
                              <CellLink
                                pathname={`/admin/branches/${row?.branch?.id}`}
                              >
                                {row?.branch?.name}
                              </CellLink>
                            ) : (
                              <FixedTableCell> </FixedTableCell>
                            )}
                            <FixedTableCell>{row?.phone}</FixedTableCell>
                            <FixedTableCell>{row?.mobile}</FixedTableCell>
                            <FixedTableCell>{row?.email}</FixedTableCell>
                            <FixedTableCell>
                              {row?.active ? (
                                <Icon className={classes.iconColor}>
                                  check_circle_outline
                                </Icon>
                              ) : (
                                <Icon color="error">highlight_off</Icon>
                              )}
                            </FixedTableCell>
                            {/* <FixedTableCell component="th" scope="row">
                            <IconButton
                              color="primary"
                              className={classes.tableIcon}
                              size="small"
                              disabled={row?.approved}
                              onClick={(e) => {
                                e.stopPropagation();
                                manifestId.current = row.id;
                                setDialog(true);
                              }}
                            >
                              <DeleteOutline />
                            </IconButton>
                          </FixedTableCell> */}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableFixedHeaderWraper>
              <MUITablePagination
                count={data?.listCustomers?.paginatorInfo?.total}
                rowsPerPage={rowsPerPage}
                page={!data?.listCustomers ? 0 : search?.page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          )}
        </Grid>
      </Root>
    </Can>
  );
};

export default CustomerList;
