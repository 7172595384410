import React, { useEffect } from "react";
import { Grid, Box, Typography } from "@mui/material";
import emptyList from "../../../assets/Image/received.png";
import FullScreenLoading from "./LoadingPages/FullScreenLoading";

const EmptyTableMessage = (props) => {
  const { loading, message } = props;

  return (
    <Grid
      container
      item
      justifyContent="center"
      alignItems="center"
      sx={{ minHeight: "25em" }}
    >
      {loading ? (
        <FullScreenLoading />
      ) : (
        message && (
          <Box textAlign="center">
            <img width="50%" src={emptyList} alt={"delivery box"} />
            <Box
              component={Typography}
              variant="h6"
              color="text.secondary"
              marginTop="0"
            >
              {message}
            </Box>
          </Box>
        )
      )}
    </Grid>
  );
};

export default EmptyTableMessage;
