import { styled } from "@mui/material/styles";

const PREFIX = "SHIPMENT_PRINT";

export const classes = {
  hideSenderPhone: `${PREFIX}-hideSenderPhone`,
  shipmentInvoiceContainer: `${PREFIX}-shipmentInvoiceContainer`,
  printSticky: `${PREFIX}-printSticky`,
  stickyCode: `${PREFIX}-stickyCode`,
  stickyRecipientName: `${PREFIX}-stickyRecipientName`,
  stickyDestination: `${PREFIX}-stickyDestination`,
  stickyRecipientAddress: `${PREFIX}-stickyRecipientAddress`,

  receipt: `${PREFIX}-receipt`,
  receiptSmall: `${PREFIX}-receiptSmall`,
  leftRadius: `${PREFIX}-leftRadius`,
  rightRadius: `${PREFIX}-rightRadius`,
  body: `${PREFIX}-body`,
  section: `${PREFIX}-section`,
  header: `${PREFIX}-header`,
  reportHeader: `${PREFIX}-reportHeader`,
  tableCell: `${PREFIX}-tableCell`,
  centerContent: `${PREFIX}-centerContent`,
  barcode: `${PREFIX}-barcode`,

  tableHeader: `${PREFIX}-tableHeader`,
  logo: `${PREFIX}-logo`,
  dFlex: `${PREFIX}-dFlex`,
  tableBorder: `${PREFIX}-tableBorder`,
  marginRight1: `${PREFIX}-marginRight1`,

  width21: `${PREFIX}-width21`,
  width28: `${PREFIX}-width28`,
  width30: `${PREFIX}-width30`,
  width50: `${PREFIX}-width50`,
  width72: `${PREFIX}-width72`,
  width79: `${PREFIX}-width79`,
  width90: `${PREFIX}-width90`,
  width100: `${PREFIX}-width100`,
  height9: `${PREFIX}-height9`,
  height_3_13: `${PREFIX}-height_3_13`,
  height35: `${PREFIX}-height35`,
  height65: `${PREFIX}-height65`,
  borderRadius: `${PREFIX}-borderRadius`,
  borderRight: `${PREFIX}-borderRight`,
  headerStyle: `${PREFIX}-headerStyle`,
  logoImg: `${PREFIX}-logoImg`,
  flexChild: `${PREFIX}-flexChild`,
  container: `${PREFIX}-container`,
  topHeaders: `${PREFIX}-topHeaders`,
  details: `${PREFIX}-details`,
  notes: `${PREFIX}-notes`,
  borderBottom: `${PREFIX}-borderBottom`,
  description: `${PREFIX}-description`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
export const Root = styled("div")(({ theme }) => ({
  [`& .${classes.hideSenderPhone}`]: {
    display: "none !important",
  },

  [`& .${classes.shipmentInvoiceContainer}`]: {
    pageBreakAfter: "always",
    pageBreakInside: "avoid",
    height: "272mm",
    fontSize: "75%",
    color: "black",
    "&:first-of-type": {
      "& $receipt": {
        "& :first-of-type": {
          borderTop: "none",
          // backgroundColor:"red"
        },
      },
    },
    WebkitPrintColorAdjust: "exact !important",
    // -webkit-print-color-adjust: exact !important,
  },

  [`& .${classes.printSticky}`]: {
    pageBreakAfter: "always",
    pageBreakInside: "avoid",
    maxWidth: "5cm",
    maxHeight: "4cm",
    overflow: "hidden",
    fontSize: 11,
    color: "black",
  },

  [`& .${classes.stickyCode}`]: {
    height: "calc(100% * (1/4))",
    overflow: "hidden",
    fontSize: "30px !important",
  },

  [`& .${classes.stickyRecipientName}`]: {
    height: "calc(100% * (0.75/4))",
    overflow: "hidden",
    whiteSpace: "pre",
  },

  [`& .${classes.stickyDestination}`]: {
    height: "calc(100% * (0.75/4))",
    overflow: "hidden",
    whiteSpace: "pre",
  },

  [`& .${classes.stickyRecipientAddress}`]: {
    height: "calc(100% * (1.5/4))",
    overflow: "hidden",
  },

  [`& .${classes.container}`]: {
    flexDirection: "column",
    gap: 8,
  },
  [`& .${classes.details}`]: {
    flexDirection: "column",
    flex: "1 1 auto",
    minHeight: 0,
  },
  [`& .${classes.notes}`]: {
    flex: "1 1 auto",
    minHeight: "calc(100% / 21 * 5)",
  },
  [`& .${classes.description}`]: {
    flex: "1 1 auto",
    minHeight: "calc(100% / 21 * 9)",
  },

  [`& .${classes.flexChild}`]: {
    display: "flex",
    flex: "1 1 auto",
    minHeight: 0,
  },
  [`& .${classes.borderBottom}`]: {
    borderBottom: "1px solid black !important",
  },
  [`& .${classes.topHeaders}`]: {
    flex: "1 1 auto",
    minHeight: "calc(100% / 21 * 2.5)",
  },
  [`& .${classes.receipt}`]: {
    fontSize: 12,
    height: "50%",
    padding: "8mm",
    boxSizing: "border-box",
    textAlign: "start",
    borderTop: "1px dashed black",
    "&:first-of-type": {
      "@media print": {
        borderTop: "none",
      },
    },
    "@media print": {
      padding: "5px 0px !important",
    },
  },

  [`& .${classes.receiptSmall}`]: {
    height: "calc(100%/3)",
    padding: "6mm",
  },

  [`& .${classes.leftRadius}`]: {
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
  },

  [`& .${classes.rightRadius}`]: {
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
  },

  [`& .${classes.body}`]: {
    height: "82%",
    display: "flex",
    lineHeight: 1.1,
  },

  [`& .${classes.section}`]: {
    height: "100%",
  },

  [`& .${classes.header}`]: {
    textAlign: "left",
    display: "flex",
    alignItems: "center",
    height: "18%",
  },

  [`& .${classes.reportHeader}`]: {
    whiteSpace: "pre-line",
    paddingLeft: 16,
    lineHeight: "115%",
    color: "black",
    fontSize: 11,
    margin: 0,
    height: 50,
    display: "flex !important",
    alignItems: "center",
  },

  [`& .${classes.tableCell}`]: {
    display: "flex",
    padding: '0 6px',
    // border: "1px solid black",
    // borderBlockEnd: "none",
    overflow: "hidden",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "stretch",
  },

  [`& .${classes.centerContent}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  [`& .${classes.barcode}`]: {
    fontFamily: "'Libre Barcode 39 Text'",
    fontSize: 48,
  },

  [`& .${classes.tableHeader}`]: {
    backgroundColor: "#ddd",
  },

  [`& .${classes.logo}`]: {
    height: "100%",
  },

  [`& .${classes.dFlex}`]: {
    display: "flex",
  },

  [`& .${classes.tableBorder}`]: {
    height: "inherit",
  },

  [`& .${classes.marginRight1}`]: {
    marginRight: "1%",
  },

  [`& .${classes.width21}`]: {
    width: "21%",
  },
  [`& .${classes.width28}`]: {
    width: "28%",
  },

  [`& .${classes.width30}`]: {
    width: "30%",
  },

  [`& .${classes.width50}`]: {
    width: "50%",
  },

  [`& .${classes.width72}`]: {
    width: "72%",
  },

  [`& .${classes.width79}`]: {
    width: "79%",
  },

  [`& .${classes.width90}`]: {
    width: "90%",
  },

  [`& .${classes.width100}`]: {
    width: "100%",
  },

  [`& .${classes.height9}`]: {
    height: "calc(100% / 21 * 9)",
  },

  [`& .${classes.height_3_13}`]: {
    height: "calc(100% * 3 / 13)",
  },

  [`& .${classes.height35}`]: {
    height: "35%",
  },

  [`& .${classes.height65}`]: {
    height: "65%",
  },

  [`& .${classes.borderRadius}`]: {
    borderRadius: 10,
    border: "1px solid black",
  },

  [`& .${classes.borderRight}`]: {
    borderRight: "1px solid black",
    textTransform: 'capitalize',
  },

  [`& .${classes.headerStyle}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  [`& .${classes.logoImg}`]: {
    maxWidth: "100%",
    maxHeight: "48px",
  },
}));
