import { styled } from '@mui/material/styles';
import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";
import "./style.css";
const PREFIX = 'CounterSection';

const classes = {
  background: `${PREFIX}-background`,
  headerColor: `${PREFIX}-headerColor`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.background}`]: {
    background: theme.palette.primary.main,
  },

  [`& .${classes.headerColor}`]: {
    "& h2": {
      "&::before": {
        background: theme.palette.primary.main,
      },
    },
  }
}));

const CounterSection = (props) => {
  const { t } = useTranslation("home");
  const sections = t("home:statisticSections", { returnObjects: true });


  return (
    <Root id="statistics" className={`wpo-counter-area ${props.subclass}`}>
      <div className="container">
        <div className="row">
          <div className="col-lg-7 col-md-6 col-sm-12">
            <div className={clsx(classes.background, "wpo-counter-content")}>
              <h2>{t("home:statisticsTitle")}</h2>
              <p className="lead">{t("home:statisticsBody")}</p>
            </div>
          </div>
          <div className="col-lg-5 col-md-6 col-sm-12">
            <div className={clsx(classes.headerColor, "wpo-counter-grids")}>
              {sections.map((section, i) => (
                <div className="grid" key={i}>
                  <div>
                    <h2>
                      <span>{section.number}</span>
                    </h2>
                  </div>
                  <p>{section.title}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Root>
  );
};

export default CounterSection;
