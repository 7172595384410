import { Alert, Button, Collapse, Grid, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import React, { Fragment, useEffect, useState } from "react";

import { useForm } from "react-hook-form";

import { gql, useMutation } from "@apollo/client";
import * as gqlb from "gql-query-builder";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { LIST_CANCELLATION_REASONS_DROPDOWN } from "../../GlobalsQuery/ListDropdown/ListDropdown";
import { dateFormat } from "../../helpers/dateFunctions";
import LookupDropdown from "../HOC/CustomComponents/LookupDropdown";
import { Can } from "../HOC/CustomComponents/Secured";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
// import MUIDateTime from "../HOC/MUI/MUIDateTime";
import { SEARCH } from "../ShipmentSearch/SearchTable";
import { updateShipmentStatusMutationBuilder } from "./Graphql";
import MUIDate from "../HOC/MUI/MUIDate";

const PREFIX = 'DeliveryActionForm';

const classes = {
  paper: `${PREFIX}-paper`,
  button: `${PREFIX}-button`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.paper}`]: {
    padding: theme.spacing(3),
  },

  [`& .${classes.button}`]: {
    marginBottom: theme.spacing(1),
  }
}));

//*********Table Function*********
const updateStatusQuery = {
  operation: "updateShipmentStatus",
  fields: ["id"],
  variables: {
    input: {
      type: "UpdateShipmentStatusInput",
      required: true,
    },
  },
};
const UPDATE_ONE_SHIPMENT_STATUS = gqlb.mutation(updateStatusQuery);

const DeliveryActionForm = (props) => {
  const { queryFields, shipment, shipmentsId, updateMultiple, listShipments, filterTransactionType, dexShipment } =
    props;

  const {
    handleSubmit: submit,
    control,
    setValue,
    formState: { errors },
    watch,
    setError,
  } = useForm();
  const [deliveryDate, setDeliveryDate] = useState(null);

  const { t } = useTranslation(["translation", "validation"]);
  const { enqueueSnackbar } = useSnackbar();

  const parseData = (data) => {
    return data;
  };

  const rtsShipment = shipment?.type?.code === "RTS";

  const UPDATE_SHIPMENT_STATUS = updateMultiple
    ? updateShipmentStatusMutationBuilder(shipmentsId)
    : UPDATE_ONE_SHIPMENT_STATUS;
  const [updateShipmentStatusMutation, { loading: updateSatusLoad, client }] =
    useMutation(
      gql`
        ${UPDATE_SHIPMENT_STATUS.query}
      `,
      {
        fetchPolicy: "no-cache",
        nextFetchPolicy: "no-cache",
      }
    );
  const onSubmits = (data) => {
    const products = []
    if (pickedProduct) {
      for (let i = 0; i < pickedProduct.length; i++) {
        const referenceBranch = {
          "id": parseInt(pickedProduct[i].product.id),
          "quantity": parseInt(pickedProduct[i].Newquantity),
        };
        products.push(referenceBranch)
      }
    }
    for (const key in data) {
      if (data[key] === "") {
        delete data[key];
      }
    }

    const deliveredAmount = parseFloat(data.deliveredAmount);
    const fees = parseFloat(data.fees);

    let variables = {
      input: {
        id: shipment?.id,
        statusCode: data.statusCode,
        ...(data.notes && { notes: data.notes }),
        ...(data.statusCode === "HTR" && data.deliveryDate && {
          deliveryDate: dateFormat(data.deliveryDate),
        }),
        ...(!isNaN(fees) && { fees: parseFloat(data.fees) }),
        ...(data.returnTypeCode && { returnTypeCode: data.returnTypeCode }),
        ...(data.deliveryTypeCode && {
          deliveryTypeCode: data.deliveryTypeCode,
        }),
        ...(products.length === 0 && data.deliveryTypeCode !== 'FD' && !['RJCT', 'PKD', 'PKH'].includes(data.statusCode) &&
          (!isNaN(deliveredAmount) || (rtsShipment && !["DEX", "HTR"].includes(data.statusCode))) && {
          deliveredAmount: rtsShipment
            ? shipment?.totalAmount
            : deliveredAmount,
        }),
        ...(data.cancellationReasonId && {
          cancellationReasonId: data.cancellationReasonId,
        }),
        ...(data.deliveryTypeCode === 'PD' && products.length !== 0 && { deliveredProducts: products })
      },
    };

    if (updateMultiple) {
      let queryInput = variables.input;
      for (const shipment of listShipments) {
        variables["input" + shipment.id] = {
          ...queryInput,
          id: shipment.id,
          // ...(shipment.type.code === "RTS" &&
          //   data.statusCode === "PKD" &&
          //   { deliveredAmount: shipment?.totalAmount }),
        };
      }
      delete variables["input"];
    }

    updateShipmentStatusMutation({
      variables,
    })
      .then((res) => {
        props.closeDialog();
        client.refetchQueries({
          include: [
            gql`
              ${SEARCH(queryFields).query}
            `,
          ],
        });
        enqueueSnackbar(t("saveSuccessful"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      })
      .catch(({ graphQLErrors }) => {
        setValidationError(graphQLErrors, setError);

        console.log(graphQLErrors);
        client.refetchQueries({
          include: [
            gql`
              ${SEARCH(queryFields).query}
            `,
          ],
        });
        enqueueSnackbar(graphQLErrors[0].message, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      });
  };
  const onChangeStatusCode = (e) => {
    const resetRts = () => {
      setValue("returnTypeCode", "");
      setValue("fees", "");
    };

    const resetDtr = () => {
      setValue("deliveredAmount", "");
      setValue("deliveryTypeCode", "");
      setValue("type", "");
      shipment?.returnPiecesCount && setValue("returnPiecesCount", "");
    };

    switch (e.target.value) {
      case "DTR":
        setValue("cancellationReasonId", "");
        !updateMultiple && setValue("deliveredAmount", shipment?.totalAmount);
        setValue("type", shipment?.type?.name);
        shipment?.returnPiecesCount &&
          setValue("returnPiecesCount", shipment?.returnPiecesCount);
        resetRts();
        break;

      case "RTS":
        resetDtr();
        break;
      default:
        e.target.value === "" && setValue("cancellationReasonId", "");
        resetRts();
        resetDtr();
        break;
    }
  };

  const conditionFPCAndWPDF =
    watch("returnTypeCode") !== "FPC" && watch("returnTypeCode") !== "WPDF";

  const shipmentStatus =
    shipment?.status?.code ?? listShipments[0]?.status?.code;

  const shipmentStatusOTD = rtsShipment && shipmentStatus === "OTD";

  const statusCodeFilters =
    shipmentStatus === "PKM" ? ["PKD", "PKH", "RJCT"] : ["DTR", "DEX", "HTR", "RTS"];
  const paymentTypeCash = shipment?.paymentType?.code === "CASH";
  const deliveryTypeFilters =
    updateMultiple || shipmentStatusOTD || paymentTypeCash
      ? ["FD"]
      : ["FD", "PD"];

  const returnTypeFilters = updateMultiple
    ? ["WODF", "WFDF"]
    : ["WODF", "WPDF", "FPC", "WFDF"];

  if (shipmentStatusOTD) {
    statusCodeFilters.pop();
  }

  // Product 
  const shipmentProductsLength = shipment?.shipmentProducts?.length === 0
  const products = shipment?.shipmentProducts
  const [pickedProduct, setPickedProduct] = useState(products);
  const [allProductPrice, setAllProductPrice] = useState(0);


  useEffect(() => {
    if (!shipmentProductsLength) {
      let totalPrice = 0;
      pickedProduct?.forEach((product) => {
        totalPrice += Number(product.price) * Number(product.quantity);
        setValue(`quantity${product?.product.id}`, product?.quantity);
        product.Newquantity = product.quantity
      })
      setAllProductPrice(totalPrice);
    }
  }, [watch('deliveryTypeCode')]);

  return (
    <Root>
      <Grid
        container
        item
        justifyContent="flex-start"
        alignContent="space-between"
        component={"form"}
        onSubmit={submit(onSubmits)}
        className={classes.searchForm}
        spacing={2}
      >
        <Grid container item sm={6} alignItems="flex-start">
          <Typography variant="h6" color="textSecondary">
            {t("shipmentCode")}
          </Typography>
          <Typography variant="h5" color="primary" sx={{ ml: 1 }}>
            {shipment?.code}
          </Typography>
        </Grid>
        <Can
          permission={
            (!shipment?.collected &&
              paymentTypeCash &&
              shipment?.status?.code === "PKM") ||
            (shipment?.status?.code === "PKM" && rtsShipment)
          }
          showException
        >
          <Grid container item xs={12} alignItems="flex-start">
            <Alert icon={false} severity="warning" sx={{ width: "100%" }}>
              <Typography
                component="span"
                variant="body1"
                color="textSecondary"
              >
                {t("deservedAmount")}
              </Typography>
              <Typography
                component="span"
                variant="body1"
                color="primary"
                sx={{ ml: 1 }}
              >
                {rtsShipment && shipment?.totalAmount}

                {paymentTypeCash && shipment?.allDueFees}
              </Typography>
            </Alert>
          </Grid>
        </Can>
        <Grid container item sm={12} alignItems="flex-start">
          <LookupDropdown
            control={control}
            errors={errors}
            name={"statusCode"}
            label={t("transactionType")}
            variables={{
              input: { code: "SHP_REQUEST_STATUS", active: true },
            }}
            onCompleted={() => {
              shipmentStatus === "OTD" && setValue("statusCode", "DTR");
            }}
            rules={{ required: t("fieldIsRequired") }}
            filters={filterTransactionType ?? statusCodeFilters}
            onChanges={onChangeStatusCode}
          />
        </Grid>
        <Can permission={watch("statusCode") === "HTR"} showException>
          <Grid container item sm={12} alignItems="flex-start">
            <MUIDate
              name="deliveryDate"
              label={t("redeliveryDate")}
              control={control}
              value={deliveryDate}
              onChange={(date) => setDeliveryDate(date)}
              readOnly={false}
              disablePast
              shouldDisableDate={(day) =>
                day.toDateString() === new Date().toDateString()
              }
              onError={(resone, value) => {
                setError("deliveryDate", { message: resone });
              }}
            />
          </Grid>
        </Can>

        {!dexShipment && (watch("statusCode") === "DEX" ||
          watch("statusCode") === "HTR" ||
          watch("statusCode") === "RTS" ||
          watch("statusCode") === "PKH" ||
          watch("statusCode") === "RJCT") && (
            <Grid container item sm={12} alignItems="flex-start">
              <CustomAutocomplete
                control={control}
                errors={errors}
                name={"cancellationReasonId"}
                label={t("reasonName")}
                query={LIST_CANCELLATION_REASONS_DROPDOWN.query}
                parseData={(data) => parseData(data)}
                rules={{ required: t("fieldIsRequired") }}
                variables={{
                  ...(shipmentStatus === "PKM" && { type: "RJCT" }),
                  ...(shipmentStatus === "OTD" && { type: "DEX" })
                }}
              />
            </Grid>
          )}

        {watch("statusCode") === "RTS" && (
          <>
            <Grid
              container
              item
              sm={
                watch("returnTypeCode") === "WODF" ||
                  watch("returnTypeCode") === "WFDF" ||
                  !watch("returnTypeCode")
                  ? 12
                  : 6
              }
              alignItems="flex-start"
            >
              <LookupDropdown
                control={control}
                errors={errors}
                name={"returnTypeCode"}
                label={t("returnType")}
                variables={{
                  input: { code: "SHP_RETURNING_TYPE", active: true },
                }}
                rules={{ required: t("fieldIsRequired") }}
                filters={returnTypeFilters}
                onChanges={(e) => {
                  if (e.target.value === "WODF") {
                    setValue("fees", 0);
                  } else {
                    setValue("fees", shipment?.returnFees);
                  }
                }}
              />
            </Grid>
            {watch("returnTypeCode") && watch("returnTypeCode") !== "WODF" &&
              watch("returnTypeCode") !== "WFDF" && (
                <Grid container item sm={6} alignItems="flex-start">
                  <ControlMUItextField
                    control={control}
                    errors={errors}
                    readOnly={conditionFPCAndWPDF}
                    name={"fees"}
                    type="number"
                    label={t("fees")}
                    rules={{
                      required: t("fieldIsRequired"),
                    }}
                  />
                </Grid>
              )}
          </>
        )}

        {watch("statusCode") === "DTR" && (
          <>
            <Grid container item sm={12} alignItems="flex-start">
              <LookupDropdown
                control={control}
                errors={errors}
                name={"deliveryTypeCode"}
                label={t("deliveryType")}
                variables={{
                  input: { code: "SHP_DELIVERY_TYPE", active: true },
                }}
                filters={deliveryTypeFilters}
                onCompleted={(data) => {
                  if (shipment?.type?.code === "PDP")
                    setValue("deliveryTypeCode", "PD");
                  if (
                    (shipment?.type?.code === "FDP" || rtsShipment) &&
                    !updateMultiple
                  ) {
                    setValue("deliveryTypeCode", "FD");

                    setValue("deliveredAmount", shipment?.totalAmount);
                  }
                }}
                rules={{ required: t("fieldIsRequired") }}
                onChanges={(e) => {
                  e.target.value === "FD" &&
                    !updateMultiple &&
                    setValue("deliveredAmount", shipment?.totalAmount);
                }}
              />
            </Grid>
            {!updateMultiple && !shipmentProductsLength && watch("deliveryTypeCode") === "PD" && (
              pickedProduct?.map((product, index) =>
                <Grid container key={index} item sm={12} alignItems="flex-start" justifyContent="space-between">
                  <Grid container item sm={7}>
                    <ControlMUItextField
                      control={control}
                      errors={errors}
                      readOnly={true}
                      name={"name"}
                      type="text"
                      label={t("productName")}
                      value={product?.product?.name}
                    />
                  </Grid>
                  <Grid container item sm={2}>
                    <ControlMUItextField
                      control={control}
                      errors={errors}
                      name={`quantity${product?.product.id}`}
                      type="number"
                      label={t("quantity")}
                      rules={{
                        required: t("fieldIsRequired"),
                        min: {
                          value: 0,
                          message: t("validation:min", {
                            field: t("quantity"),
                            number: "0",
                          }),
                        },
                        max: {
                          value: product?.quantity,
                          message: t("validation:max", {
                            field: t("quantity"),
                            number: product?.quantity,
                          }),
                        },
                      }}
                      onChange={(e) => {
                        const value = e.target.value;
                        setPickedProduct((prev) => {
                          prev[index].Newquantity = Number(value)
                          return prev
                        });
                        let totalPrice = 0
                        pickedProduct.forEach((product) => {
                          totalPrice += Number(product.price) * Number(product.Newquantity);
                        })
                        const diffPrice = allProductPrice - totalPrice;
                        setValue('deliveredAmount', shipment?.totalAmount - diffPrice)
                      }}
                    />
                  </Grid>
                  <Grid container item sm={2}>
                    <ControlMUItextField
                      control={control}
                      errors={errors}
                      readOnly={true}
                      name={"price"}
                      type="number"
                      label={t("price")}
                      value={product?.price}
                    />
                  </Grid>
                </Grid>
              )
            )}
            {!updateMultiple && (
              <Grid container item sm={12} alignItems="flex-start">
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  readOnly={
                    (watch("deliveryTypeCode") === "PD" &&
                      shipment?.paymentType?.code !== "CRDT"
                      ? false
                      : true) || !shipmentProductsLength
                  }
                  name={"deliveredAmount"}
                  type="number"
                  label={t("deliveredAmount")}
                  rules={{
                    required: t("fieldIsRequired"),
                    validate: {
                      lessThan: (value) => {
                        return (
                          !(value > shipment?.totalAmount) ||
                          watch("deliveryTypeCode") !== "PD" ||
                          t("validation:ltore", {
                            field1: t("deliveredAmount"),
                            field2: shipment?.totalAmount,
                          })
                        );
                      },
                    },
                  }}
                />
              </Grid>
            )}
            {/* {Boolean(shipment?.returnPiecesCount) && (
              <Grid container item sm={12} alignItems="flex-start">
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  readOnly
                  name={"returnPiecesCount"}
                  label={t("returnPiecesCount")}
                  rules={{ required: t("fieldIsRequired") }}
                />
              </Grid>
            )} */}
          </>
        )}
        <Grid container item sm={12} alignItems="flex-start">
          <ControlMUItextField
            control={control}
            errors={errors}
            name={"notes"}
            label={t("notes")}
            rules={{ required: false }}
          />
        </Grid>

        <Grid container item sm={12} justifyContent="flex-end">
          <Button
            className={classes.button}
            color={updateSatusLoad ? "inherit" : "primary"}
            variant="contained"
            type="submit"
            disabled={updateSatusLoad}
          >
            {updateSatusLoad ? <ButtonLoading /> : t("save")}
          </Button>
        </Grid>
      </Grid>
    </Root>
  );
};
export default DeliveryActionForm;
