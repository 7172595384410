import { useQuery, gql, useMutation } from "@apollo/client";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { PRICE_LIST, SAVE_PRICE_LIST } from "./Graphql";
import PriceListTable from "./PriceListTables";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormHelperText,
  Paper,
  Typography,
  Collapse,
  Box,
} from "@mui/material";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import FormButton from "../CustomComponents/Buttons/FormButton";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import { useSnackbar } from "notistack";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import {
  LIST_SHIPPING_SERVICES_DROPDOWN,
  LSIT_ZONES_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import MuiSwitch from "../HOC/MUI/MUIswitch";
import Grid from "@mui/material/Unstable_Grid2";
import { MultipleAutocomplete } from "../HOC/MUI/MultipleAutocomplete";
import { GetCustomerPermissionSlug } from "../../helpers/getManifestPermissionSlug";

const PREFIX = "PriceListForm";

const classes = {
  mainForm: `${PREFIX}-mainForm`,
  paper: `${PREFIX}-paper`,
  typography: `${PREFIX}-typography`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.mainForm}`]: {
    margin: 0,
    width: "100%",
  },

  [`& .${classes.paper}`]: {
    margin: theme.spacing(2),
    padding: theme.spacing(1),
  },

  [`& .${classes.typography}`]: {
    margin: theme.spacing(2),
  },
}));

const PriceListForm = (props) => {
  const { onSave, customerName, businessType } = props;

  const [pickupList, setPickupList] = useState();
  const [pickupIndex, setPickupIndex] = useState({
    index: 0,
    update: false,
    duplicate: false,
  });
  const [destinationIndex, setDestinationIndex] = useState({
    index: 0,
    update: false,
  });
  const [dialog, setDialog] = useState({
    dialog: false,
    pickup: false,
    destination: false,
  });
  const [pickupErrorMessage, setPickupErrorMessage] = useState(false);
  const [destinationErrorMessage, setDestinationErrorMessage] = useState(false);
  const [selectedNames, setSelectedNames] = useState({
    service: [],
    zone: "",
    subzone: "",
  });
  const formType = businessType ?? props.match?.params?.type?.toUpperCase();

  const priceListId = parseInt(props?.match?.params?.id);
  const priceListCopyId = parseInt(props?.match?.params?.copyId);
  const [priceListType, setPriceListType] = useState(formType ? formType : null)
  const { t } = useTranslation();

  const formName = {
    B2C: t("listPriceListsCustomers"),
    C2C: t("listPriceListsIndividuals"),
  };

  const {
    control,
    formState,
    handleSubmit,
    watch,
    setValue,
    reset,
    getValues,
  } = useForm({
    defaultValues: {
      service: "",
      zone: "",
      subzone: "",
      weightUpTo: "",
      weightStartFees: "",
      weightExtraUnit: "",
      weightExtraUnitFees: "",
      returnFees: "",
      collectionStartFees: "",
      collectionUpTo: "",
      collectionExtraUnitFees: "",
      collectionExtraUnit: "",
    },
  });
  const {
    control: mainControl,

    formState: { errors: mainErrors },
    handleSubmit: mainHandleSubmit,
    setValue: mainSetValue,
    setError: mainSetError,
  } = useForm({
    defaultValues: {
      name: customerName ?? "",
      active: true,
    },
  });
  const { errors } = formState;

  const { enqueueSnackbar } = useSnackbar();

  const [savePriceList, { loading: savePriceListLoad }] = useMutation(
    gql`
      ${SAVE_PRICE_LIST.query}
    `
  );
  const { loading: priceListLoading } = useQuery(
    gql`
      ${PRICE_LIST.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      skip:
        !priceListId &&
        priceListId !== 0 &&
        !priceListCopyId &&
        priceListCopyId !== 0,
      variables: { id: priceListId | priceListCopyId },
      onCompleted: (data) => {
        setPriceListType(data.priceList.businessType.code)
        data?.priceList?.pickups && setPickupList(data?.priceList.pickups);
        data?.priceList?.description &&
          mainSetValue("description", data?.priceList?.description);
        (priceListId || priceListId === 0) &&
          mainSetValue("code", data?.priceList.code);
        const nameValue =
          priceListCopyId || priceListCopyId === 0
            ? t("copy") + " " + data?.priceList.name
            : data?.priceList.name;
        mainSetValue("name", nameValue);
        mainSetValue("active", data.priceList.active);
      },
    }
  );

  const parseData = (data) => {
    return data;
  };

  const handelCloseDialog = () => {
    setDialog({
      dialog: false,
      pickup: false,
      destination: false,
    });
    setPickupErrorMessage(false);
    setDestinationErrorMessage(false);
    setPickupIndex((prev) => ({ ...prev, update: false, duplicate: false }));
    setDestinationIndex((prev) => ({ ...prev, update: false }));
    reset();
  };

  const onChangeNames = (e, parameter, remove) => {
    const name = e?.name;
    setSelectedNames((prev) => ({
      ...prev,
      [parameter]: name,
      ...(remove && { [remove]: "" }),
    }));
    setPickupErrorMessage(false);
    setDestinationErrorMessage(false);
  };
  const onChangeServiceNames = (e, parameter) => {
    const names = []
    e && e?.map(ele => names.push(ele.name))
    setSelectedNames((prev) => ({
      ...prev,
      [parameter]: names,
    }));
    setPickupErrorMessage(false);
  };

  const addPickupDialog = (index, duplicate) => {
    reset();
    if (duplicate) {
      setPickupIndex((prev) => ({
        ...prev,
        index,
        duplicate: true,
      }));
    } else if (index || index === 0) {
      setPickupIndex((prev) => ({
        ...prev,
        index,
        update: true,
      }));
    } else {
      setPickupIndex({
        index,
        update: false,
        duplicate: false,
      });
    }
    setDialog({
      dialog: true,
      pickup: true,
      destination: false,
    });
  };
  const addDestinationDialog = (pickupIndex, destinationIndex) => {
    reset();

    setPickupIndex({
      index: pickupIndex,
      update: false,
    });

    if (destinationIndex || destinationIndex === 0) {
      setDestinationIndex({
        index: destinationIndex,
        update: true,
      });
    }

    setDialog({
      dialog: true,
      destination: true,
      pickup: false,
    });
  };

  const onSubmitPickup = (data) => {
    const services = []
    for (let i = 0; i < data.service.length; i++) {
      services.push({ id: data.service[i], name: selectedNames.service[i] })
    }
    const newPickup = {
      services: services,
      zone: { id: data.zone, name: selectedNames.zone },
      subzone: data.subzone
        ? { id: data.subzone, name: selectedNames.subzone }
        : null,
      // destinations: [],
    };
    const updatePickups = pickupList ? [...pickupList] : [];
    const invalidPickup = updatePickups.some((i, index) =>
      pickupIndex.update && index === pickupIndex.index
        ? false
        : i.services.some(i => newPickup.services.some(x => i.id === x.id)) &&
        i.zone.id === newPickup.zone.id &&
        i.subzone?.id === newPickup.subzone?.id
    );

    if (invalidPickup) {
      setPickupErrorMessage(true);
      return;
    }
    if (pickupIndex.update || pickupIndex.duplicate) {
      const updatedPickup = {
        ...updatePickups[pickupIndex.index],
        ...newPickup,
      };
      if (pickupIndex.duplicate) {
        updatePickups.splice(pickupIndex.index + 1, 0, updatedPickup);
      } else {
        updatePickups[pickupIndex.index] = updatedPickup;
      }
      setPickupIndex({
        index: pickupIndex.duplicate ? pickupList.length : pickupIndex.index,
        update: false,
        duplicate: false,
      });
    } else {
      updatePickups.unshift(newPickup);
      setPickupIndex({
        index: 0,
        update: false,
        duplicate: false,
      });
    }
    setPickupList(updatePickups);
    handelCloseDialog();
  };

  const onSubmitDestination = (data) => {
    const newDestination = {
      zone: { id: data.zone, name: selectedNames.zone },
      subzone: data.subzone
        ? { id: data.subzone, name: selectedNames.subzone }
        : null,
      weightUpTo: parseFloat(data.weightUpTo),
      weightStartFees: parseFloat(data.weightStartFees),
      weightExtraUnit: parseFloat(data.weightExtraUnit),
      weightExtraUnitFees: parseFloat(data.weightExtraUnitFees),
      returnFees: parseFloat(data.returnFees),
      collectionStartFees: parseFloat(data.collectionStartFees),
      collectionUpTo: parseFloat(data.collectionUpTo),
      collectionExtraUnitFees: parseFloat(data.collectionExtraUnitFees),
      collectionExtraUnit: parseFloat(data.collectionExtraUnit),
    };
    const updatePickups = pickupList ? [...pickupList] : [];
    const destinations =
      updatePickups[pickupIndex.index]?.["destinations"] ?? [];
    const upadteDsitination = [...destinations];

    const invalidPickup = upadteDsitination.some((i, index) =>
      destinationIndex.update && index === destinationIndex.index
        ? false
        : i.zone.id === newDestination.zone.id &&
        i.subzone?.id === newDestination.subzone?.id
    );

    if (invalidPickup) {
      setDestinationErrorMessage(true);
    } else {
      if (destinationIndex.update) {
        upadteDsitination[destinationIndex.index] = {
          ...upadteDsitination[destinationIndex.index],
          ...newDestination,
        };
      } else {
        upadteDsitination.unshift(newDestination);
        setDestinationIndex({
          index: 0,
          update: false,
        });
      }
      updatePickups[pickupIndex.index] = {
        ...updatePickups[pickupIndex.index],
        destinations: upadteDsitination,
      };
      setPickupList(updatePickups);
      handelCloseDialog();
    }
  };

  const submitMutation = (data) => {
    const pickups = pickupList
      ? pickupList.map((i) => {
        const serviceIds = []
        i.services.map(ele => serviceIds.push(ele.id))
        const destinations = i?.destinations
          ? [
            ...i.destinations.map((i) => {
              const destination = {
                ...i,
                zoneId: i.zone.id,
                ...(i?.subzone?.id && { subzoneId: i.subzone.id }),
              };
              delete destination["zone"];
              delete destination["subzone"];
              return destination;
            }),
          ]
          : [];
        const pickup = {
          serviceIds: serviceIds,
          zoneId: i.zone.id,
          ...(i?.subzone?.id && { subzoneId: i.subzone.id }),
          ...(destinations.length >= 0 && { destinations }),
        };
        return pickup;
      })
      : [];
    savePriceList({
      variables: {
        input: {
          ...((priceListId || priceListId === 0) && { id: priceListId }),
          ...(data.code && { code: data.code }),
          ...(data.description && { description: data.description }),
          name: data.name,
          active: data.active,
          businessTypeCode: priceListType,
          pickups,
        },
      },
    })
      .then((data) => {
        if (Boolean(onSave)) {
          onSave(data);
        } else {
          pushUrl(props, `/admin/price-list/${data?.data?.savePriceList?.id}`);
        }
        enqueueSnackbar(t("saveSuccessful"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      })
      .catch(({ graphQLErrors }) => {
        setValidationError(graphQLErrors, mainSetError);

        console.log(graphQLErrors);
      });
  };

  const pickupForm = (
    <PickupForm
      handleSubmit={handleSubmit}
      onSubmitPickup={onSubmitPickup}
      control={control}
      errors={errors}
      parseData={parseData}
      onChangeNames={onChangeNames}
      onChangeServiceNames={onChangeServiceNames}
      pickupIndex={pickupIndex}
      pickupList={pickupList}
      setValue={setValue}
      watch={watch}
      pickupErrorMessage={pickupErrorMessage}
      handelCloseDialog={handelCloseDialog}
      setSelectedNames={setSelectedNames}
      priceListType={priceListType}
    />
  );
  const destinationForm = (
    <DestinationForm
      handleSubmit={handleSubmit}
      onSubmitDestination={onSubmitDestination}
      control={control}
      errors={errors}
      parseData={parseData}
      setValue={setValue}
      onChangeNames={onChangeNames}
      destinationIndex={destinationIndex}
      pickupList={pickupList}
      pickupIndex={pickupIndex}
      watch={watch}
      destinationErrorMessage={destinationErrorMessage}
      handelCloseDialog={handelCloseDialog}
      setSelectedNames={setSelectedNames}
      getValues={getValues}
    />
  );

  // const pagePermission = GetCustomerPermissionSlug("shipping", "price_list", listType, "list")

  return (
    <Root>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={dialog.dialog}
        onClose={handelCloseDialog}
      >
        {dialog.destination ? destinationForm : null}
        {dialog.pickup ? pickupForm : null}
      </Dialog>

      {(priceListId ||
        priceListId === 0 ||
        priceListCopyId ||
        priceListCopyId === 0) &&
        priceListLoading ? (
        <FullScreenLoading minHeight="10%" />
      ) : (
        <>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            sm={12}
          >
            <Typography variant="h6" className={classes.typography}>
              {formName[priceListType]}
            </Typography>
            <Box>
              <MuiSwitch
                edge="end"
                name="active"
                label={t("active")}
                control={mainControl}
              />
            </Box>
          </Grid>
          <Paper
            className={classes.paper}
            component="form"
            onSubmit={mainHandleSubmit(submitMutation)}
          >
            <Grid container spacing={2} className={classes.mainForm}>
              <Grid xs={12} sm={6}>
                <ControlMUItextField
                  control={mainControl}
                  errors={mainErrors}
                  name="code"
                  label={t("code")}
                />
              </Grid>
              <Grid xs={12} sm={6}>
                <ControlMUItextField
                  control={mainControl}
                  errors={mainErrors}
                  name="name"
                  label={t("name")}
                  rules={{ required: t("fieldIsRequired") }}
                />
              </Grid>
              <Grid xs={12}>
                <ControlMUItextField
                  control={mainControl}
                  errors={mainErrors}
                  name="description"
                  label={t("description")}
                />
              </Grid>
              <Grid xs={12} justifyContent="flex-end">
                <FormButton
                  className={classes.button}
                  disabled={savePriceListLoad}
                >
                  {savePriceListLoad ? <ButtonLoading /> : t("save")}
                </FormButton>
              </Grid>
            </Grid>
          </Paper>
          <PriceListTable
            pickups={pickupList}
            addPickup={addPickupDialog}
            addDestination={addDestinationDialog}
            setPickupList={setPickupList}
          />
        </>
      )}
    </Root>
  );
};

export default PriceListForm;

function DestinationForm({
  handleSubmit,
  onSubmitDestination,
  control,
  errors,
  parseData,
  setValue,
  onChangeNames,
  destinationIndex,
  pickupList,
  pickupIndex,
  destniationQueryWithIdComplete,
  watch,
  destinationErrorMessage,
  handelCloseDialog,
  setSelectedNames,
  getValues,
}) {
  const { t } = useTranslation();
  const [autocompleteValues, setAutocompleteValues] = useState({
    zone: null,
    subzone: null,
  });
  useEffect(() => {
    if (destinationIndex.update) {
      const update =
        pickupList[pickupIndex.index]["destinations"][destinationIndex.index];
      setAutocompleteValues({
        ...update,
      });
      setSelectedNames((prev) => ({
        ...prev,
        ...(update?.zone?.name && { zone: update?.zone?.name }),
        ...(update?.subzone?.name && { subzone: update?.subzone?.name }),
      }));
      for (const key in update) {
        if (key !== "zone" && key !== "subzone") {
          setValue(key, update[key]);
        }
      }
    } else {
      const update = pickupList[pickupIndex.index]?.["destinations"]?.[0];

      if (update) {
        for (const key in update) {
          if (key !== "zone" && key !== "subzone") {
            setValue(key, update[key]);
          }
        }
      } else {
        [
          "weightExtraUnitFees",
          "weightExtraUnit",
          "collectionStartFees",
          "collectionExtraUnitFees",
        ].forEach((i) => {
          setValue(i, 0);
        });
        setValue("collectionUpTo", 1000);
        setValue("collectionExtraUnit", 1000);
        setValue("weightUpTo", 1);
      }
      // setValue("collectionExtraUnit",1000)
    }
    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <form onSubmit={handleSubmit(onSubmitDestination)}>
      <DialogTitle>{t("addDestinationArea")}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid xs={12}>
            <CustomAutocomplete
              control={control}
              errors={errors}
              rules={{ required: t("fieldIsRequired") }}
              name={"zone"}
              label={t("zone")}
              parseData={(data) => parseData(data)}
              query={LSIT_ZONES_DROPDOWN.query}
              variables={{
                input: {
                  active: true,
                  parentId: null,
                },
              }}
              onChangeValue={(e) => {
                setValue("subzone", "");
                onChangeNames(e, "zone", "subzone");
              }}
              defaultValue={autocompleteValues.zone}
            />
          </Grid>
          <Grid xs={12}>
            <CustomAutocomplete
              control={control}
              errors={errors}
              name={"subzone"}
              label={t("subzone")}
              parseData={(data) => parseData(data)}
              query={LSIT_ZONES_DROPDOWN.query}
              skip={!watch("zone")}
              variables={{
                input: {
                  parentId: watch("zone"),
                  active: true,
                },
              }}
              onChangeValue={(e) => onChangeNames(e, "subzone")}
              defaultValue={autocompleteValues.subzone}
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"weightUpTo"}
              label={t("weightUpTo")}
              type="number"
              rules={{ required: t("fieldIsRequired") }}
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"weightStartFees"}
              label={t("deliveryFees")}
              type="number"
              rules={{ required: t("fieldIsRequired") }}
              onBlur={(e) => {
                Boolean(!getValues().returnFees) &&
                  setValue("returnFees", getValues().weightStartFees, {
                    shouldDirty: true,
                  });
              }}
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"weightExtraUnit"}
              label={t("extraWeight")}
              type="number"
              rules={{ required: t("fieldIsRequired") }}
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"weightExtraUnitFees"}
              label={t("extraWeightCost")}
              type="number"
              rules={{ required: t("fieldIsRequired") }}
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"returnFees"}
              label={t("returnFees")}
              type="number"
              rules={{ required: t("fieldIsRequired") }}
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"collectionStartFees"}
              label={t("collectionFees")}
              type="number"
              rules={{ required: t("fieldIsRequired") }}
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"collectionUpTo"}
              label={t("collectionUpTo")}
              type="number"
              rules={{ required: t("fieldIsRequired") }}
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"collectionExtraUnitFees"}
              label={t("collectionExtraUnitFees")}
              type="number"
              rules={{ required: t("fieldIsRequired") }}
            />
          </Grid>
          <Grid xs={12}>
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"collectionExtraUnit"}
              label={t("collectionExtraUnit")}
              type="number"
              rules={{ required: t("fieldIsRequired") }}
            />
          </Grid>
        </Grid>
        {destinationErrorMessage && (
          <FormHelperText error>{t("thisZoneAlreadyExists")}</FormHelperText>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handelCloseDialog}>{t("cancel")}</Button>
        <Button type="submit">{t("confirm")}</Button>
      </DialogActions>
    </form>
  );
}

function PickupForm({
  handleSubmit,
  onSubmitPickup,
  control,
  errors,
  parseData,
  onChangeNames,
  onChangeServiceNames,
  pickupIndex,
  pickupList,
  setValue,
  watch,
  pickupErrorMessage,
  handelCloseDialog,
  setSelectedNames,
  priceListType
}) {
  const { t } = useTranslation();
  const [autocompleteValues, setAutocompleteValues] = useState({
    service: [],
    zone: null,
    subzone: null,
  });
  useEffect(() => {
    if (pickupIndex.update) {
      const update = pickupList[pickupIndex.index];
      const serviceIds = []
      update.services.map(ele => serviceIds.push(ele.id))
      setAutocompleteValues({
        ...update,
        service: serviceIds,
      });
      setSelectedNames((prev) => ({
        ...prev,
        // ...(update?.service?.name && { service: update?.service?.name }),
        ...(update?.zone?.name && { zone: update?.zone?.name }),
        ...(update?.subzone?.name && { subzone: update?.subzone?.name }),
      }));
    }
    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmitPickup)}>
      <DialogTitle>{t("addPickupArea")}</DialogTitle>
      <DialogContent>
        <MultipleAutocomplete
          control={control}
          errors={errors}
          rules={{ required: t("fieldIsRequired") }}
          name={"service"}
          label={t("service")}
          variables={{
            input: {
              active: true,
              businessTypeCode: priceListType
            },
          }}
          limitTags={1000}
          parseData={(data) => parseData(data)}
          query={LIST_SHIPPING_SERVICES_DROPDOWN.query}
          onChangeValue={(e) => onChangeServiceNames(e, "service")}
          margin="normal"
          defaultValue={autocompleteValues.service}
          multiple
          valueKey="id"
        />
        <CustomAutocomplete
          control={control}
          errors={errors}
          rules={{ required: t("fieldIsRequired") }}
          name={"zone"}
          label={t("zone")}
          parseData={(data) => parseData(data)}
          query={LSIT_ZONES_DROPDOWN.query}
          variables={{
            input: {
              active: true,
              parentId: null,
            },
          }}
          onChangeValue={(e) => {
            setValue("subzone", "");
            onChangeNames(e, "zone", "subzone");
          }}
          margin="normal"
          defaultValue={autocompleteValues.zone}
        />
        <CustomAutocomplete
          control={control}
          errors={errors}
          name={"subzone"}
          label={t("subzone")}
          parseData={(data) => parseData(data)}
          query={LSIT_ZONES_DROPDOWN.query}
          skip={!watch("zone")}
          variables={{
            input: {
              parentId: watch("zone"),
              active: true,
            },
          }}
          onChangeValue={(e) => onChangeNames(e, "subzone")}
          margin="normal"
          defaultValue={autocompleteValues.subzone}
        />
        {pickupErrorMessage && (
          <FormHelperText error>{t("thisZoneAlreadyExists")}</FormHelperText>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handelCloseDialog}>{t("cancel")}</Button>
        <Button type="submit">{t("confirm")}</Button>
      </DialogActions>
    </form>
  );
}
