import {
  Box,
  Icon,
  IconButton,
  Paper,
  Toolbar,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { Fragment, useState } from "react";

import { gql, useQuery } from "@apollo/client";
import {
  DeleteOutline,
  Edit,
  LockOpenOutlined,
  Print,
} from "@mui/icons-material";
import clsx from "clsx";
import emptyList from "../../assets/Image/received.png";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";

import { useTranslation } from "react-i18next";
import NotFound from "../../Error/NotFound";
import { Globals } from "../HOC/Classes/Globals";
import { KeyValuePair } from "../HOC/CustomComponents/KeyValuePair";
import { Can, SecuredNavLink } from "../HOC/CustomComponents/Secured";
import ShipmentsTable from "../HOC/CustomComponents/ShipmentsTable";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import usePaymentsDetails from "./paymentDetails";
import {
  DELETE_PAYMENT_MUTATION,
  DISAPPROVE_PAYMENT_MUTATION,
  PAYMENT_VIEW,
} from "./PaymentListQuary";

import Grid from "@mui/material/Unstable_Grid2";
import MutationWithDialog from "../HOC/CustomComponents/MutationWithDialog";
import SpanLink from "../HOC/CustomComponents/SpanLink";
import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import PrintIcon from "../Shipments/PrintIcon";
import ExportFinanceShipments from "./ExportFinanceShipments";
import { dateFormatLL } from "../../helpers/dateFunctions";
import usePaymentPickupsDetails from "./paymentPickupsDetails";
import ExportPickupsList from "./ExportPickupsList";
import { GetPaymentPermissionSlug } from "../../helpers/getManifestPermissionSlug";
import PrintComponent from "../Shipments/PrintComponent";

const PREFIX = "PaymentView";

const classes = {
  mainTracking: `${PREFIX}-mainTracking`,
  paper: `${PREFIX}-paper`,
  viewPaper: `${PREFIX}-viewPaper`,
  title: `${PREFIX}-title`,
  textAlign: `${PREFIX}-textAlign`,
  divider: `${PREFIX}-divider`,
  table: `${PREFIX}-table`,
  filterAnchor: `${PREFIX}-filterAnchor`,
  picker: `${PREFIX}-picker`,
  button: `${PREFIX}-button`,
  toolbar: `${PREFIX}-toolbar`,
  selectPadding: `${PREFIX}-selectPadding`,
  margin: `${PREFIX}-margin`,
  overlay: `${PREFIX}-overlay`,
  field: `${PREFIX}-field`,
  toolbarIcons: `${PREFIX}-toolbarIcons`,
  iconColor: `${PREFIX}-iconColor`,
  totalPaper: `${PREFIX}-totalPaper`,
  totalTitle: `${PREFIX}-totalTitle`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.mainTracking}`]: {
    margin: theme.spacing(1, 0),
    width: "100%",
  },

  [`& .${classes.paper}`]: {
    marginBottom: theme.spacing(2),
    position: "relative",
    minHeight: "327px",
    gridAutoRows: "max-content",
  },

  [`& .${classes.viewPaper}`]: {
    padding: theme.spacing(3),
  },

  [`& .${classes.title}`]: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },

  [`& .${classes.textAlign}`]: {
    textAlign: "justify",
    padding: theme.spacing(1),
  },

  [`& .${classes.divider}`]: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
      height: "1px",
    },
  },

  [`& .${classes.table}`]: {
    display: "grid",
    width: "100%",
  },
  [`& .${classes.totalPaper}`]: {
    marginBottom: theme.spacing(2),
    position: "relative",
    // minHeight: "327px",
    gridAutoRows: "max-content",
  },
  [`& .${classes.totalTitle}`]: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    // fontSize:
  },

  [`& .${classes.filterAnchor}`]: {
    [theme.breakpoints.down("md")]: {
      width: "auto",
    },
    // overflow: "scroll",
    "& .MuiDrawer-paper": {
      [theme.breakpoints.up("sm")]: {
        // width: "50%"
      },
      top: "auto",
      width: "25%",
      border: "1px solid #0000001f",
      height: "100%",
      overflow: "hidden",
    },
  },

  [`& .${classes.picker}`]: {
    [theme.breakpoints.down("md")]: {
      width: "auto",
    },
    // overflow: "scroll",
    "& .MuiDrawer-paper": {
      [theme.breakpoints.up("sm")]: {
        width: "50%",
      },

      height: "100%",
      overflow: "hidden",
    },
  },

  [`& .${classes.button}`]: {
    margin: theme.spacing(1),
  },

  [`& .${classes.toolbar}`]: {
    borderBottom: "1px #ccd1d6 solid",
    backgroundColor: "#f5f7f9",
    width: "100%",
  },

  [`& .${classes.selectPadding}`]: {
    paddingTop: "6px",
    paddingBottom: "5px",
  },

  [`& .${classes.margin}`]: {
    margin: theme.spacing(1, 2),
  },

  [`& .${classes.overlay}`]: {
    backgroundColor: "#ffffffad",
    position: "absolute",
    zIndex: 2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    top: 0,
    right: 0,
  },

  [`& .${classes.field}`]: {
    width: 260,
    [theme.breakpoints.down("sm")]: {
      width: 170,
    },
  },

  [`& .${classes.toolbarIcons}`]: {
    flex: "1 1 100%",
    textAlign: "end",
  },

  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },
}));

export { };
const EnhancedTableToolbar = (props) => {
  const { selected, ids, paymentId, title, type } = props;

  return (
    <Toolbar className={clsx(classes.root)}>
      <Typography
        className={classes.title}
        color="inherit"
        variant="subtitle1"
        component="div"
      >
        {title + " " + selected}
      </Typography>
      {ids && <Can showException permission="shipping.shipment.print">
        <PrintComponent shipmentsId={ids} />
      </Can>}
      {type === 'payment' && <ExportFinanceShipments type="payment" id={paymentId} />}
      {type === 'pickup' && <ExportPickupsList id={paymentId} />}
    </Toolbar>
  );
};

const PaymentView = (props) => {
  const { t } = useTranslation();

  const [paymentType, setPaymentType] = useState(null);

  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);

  const [pickedShipment] = useState({ shipments: [] });
  const [pickedPickup] = useState({ pickups: [] });
  const paymentShipments = pickedShipment.shipments;
  const paymentPickups = pickedPickup.pickups;
  const [paymentsData, setPaymentsData] = useState();

  const { details: paymentShipmentsDetails } = usePaymentsDetails({
    type: paymentType,
    TotalData: paymentsData
  });

  const { details: paymentPickupsDetails } = usePaymentPickupsDetails({
    type: paymentType,
    TotalData: paymentsData
  });

  /******************************************* Start Pagination Functions ********************************************/

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  /******************************************* Start payment By Id ********************************************/
  const paymentId = props.match.params.id?.trim();

  const user = Globals.user;

  const journalEntryPermission = user.hasPermission(
    "accounting.journal_entry.list"
  );
  const paymentQueryBody = PAYMENT_VIEW(journalEntryPermission);

  const {
    data,
    loading: paymentByIdLoad,
    refetch,
  } = useQuery(
    gql`
      ${paymentQueryBody.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      skip: !paymentId,
      variables: {
        id: parseInt(paymentId),
      },
      onCompleted: (data) => {
        if (data?.payment === null) return;
        const payment = data?.payment;
        setPaymentsData(payment);
        setPaymentType(payment?.type.code);

        const shipments = data.payment.entries.filter((i) => i.shipment !== null)
        const pickups = data.payment.entries.filter((i) => i.pickup !== null)

        pickedPickup["pickups"] = pickups;
        pickedShipment["shipments"] = shipments;
      },
    }
  );

  const branchId = data?.payment?.branch?.id;
  const canAccessBranch = branchId ? user.canAccessBranch(branchId) : true;
  const canDelete =
    user.hasPermission(GetPaymentPermissionSlug(paymentType?.toLowerCase(), 'delete')) &&
    canAccessBranch &&
    !data?.payment?.approved &&
    !paymentByIdLoad;

  const canEdit =
    user.hasPermission(GetPaymentPermissionSlug(paymentType?.toLowerCase(), 'update')) &&
    canAccessBranch &&
    !data?.payment?.glApproved &&
    !paymentByIdLoad;

  const canDisapprove =
    !paymentByIdLoad &&
    user.hasPermission(GetPaymentPermissionSlug(paymentType?.toLowerCase(), 'disapprove')) &&
    canAccessBranch &&
    data?.payment?.approved;

  /******************************************* End payment List ********************************************/

  let tableBodyShipments = null;
  let tableBodyPickups = null;
  let viewBody = null;
  tableBodyShipments = (
    <Grid container xs={12} justifyContent="center" sx={{ width: "100%" }}>
      {/* *******Table******* */}
      <Paper className={clsx(classes.table, classes.paper)}>
        <EnhancedTableToolbar
          {...props}
          type={"payment"}
          ids={paymentShipments.map((i) => i.shipment.id)}
          selected={paymentShipments.length}
          paymentId={paymentId}
          title={t("totalShipments")}
        />

        {paymentShipmentsDetails && (
          <TableFixedHeaderWraper>
            <ShipmentsTable
              page={page}
              rowsPerPage={rowsPerPage}
              data={paymentShipments}
              headCells={paymentShipmentsDetails.tableHead}
              parseBodyCell={paymentShipmentsDetails.tableBody}
              total={paymentShipmentsDetails.tableTotal}
              sumRow={paymentShipmentsDetails?.sum}
              sumTableNumber={paymentShipmentsDetails?.sumTableNumber}
              sum={paymentType === 'DLVBY' ? false : true}
            />
          </TableFixedHeaderWraper>
        )}
        {paymentShipments.length > 4 && (
          <MUITablePagination
            count={paymentShipments.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}

        <Grid container justifyContent="center" alignItems="center">
          {paymentShipments.length === 0 && (
            <Box textAlign="center" marginTop="50px" marginBottom="50px">
              <img width="50%" src={emptyList} alt={"delivery box"} />
              <Box
                component={Typography}
                variant="h6"
                color="text.secondary"
                marginTop="0"
              >
                {t("noShipments")}
              </Box>
            </Box>
          )}
        </Grid>
      </Paper>
    </Grid>
  );
  tableBodyPickups = (
    <Grid container xs={12} justifyContent="center" sx={{ width: "100%" }}>
      {/* *******Table******* */}
      <Paper className={clsx(classes.table, classes.paper)}>
        <EnhancedTableToolbar
          {...props}
          type={"pickup"}
          selected={paymentPickups.length}
          paymentId={paymentId}
          title={t("totalPickups")}
        />

        {paymentPickupsDetails && (
          <TableFixedHeaderWraper>
            <ShipmentsTable
              page={page}
              rowsPerPage={rowsPerPage}
              data={paymentPickups}
              headCells={paymentPickupsDetails.tableHead}
              parseBodyCell={paymentPickupsDetails.tableBody}
              total={paymentPickupsDetails.tableTotal}
              sumRow={paymentPickupsDetails?.sum}
              sumTableNumber={paymentPickupsDetails?.sumTableNumber}
            />
          </TableFixedHeaderWraper>
        )}
        {paymentPickups.length > 4 && (
          <MUITablePagination
            count={paymentPickups.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}

        <Grid container justifyContent="center" alignItems="center">
          {paymentPickups.length === 0 && (
            <Box textAlign="center" marginTop="50px" marginBottom="50px">
              <img width="50%" src={emptyList} alt={"delivery box"} />
              <Box
                component={Typography}
                variant="h6"
                color="text.secondary"
                marginTop="0"
              >
                {t("noPickups")}
              </Box>
            </Box>
          )}
        </Grid>
      </Paper>
    </Grid>
  );

  viewBody = (
    <Grid
      container
      spacing={3}
      justifyContent="center"
      sx={{ margin: "0", width: "100%" }}
    >
      <Fragment>
        <Toolbar className={clsx(classes.toolbar)} variant="regular">
          <Grid
            container
            sx={{ flexWrap: "initial", width: "100%", flexGrow: 1 }}
            alignItems="center"
          >
            <Grid className={classes.field}>
              <Typography variant="h6">{paymentShipmentsDetails?.label}</Typography>
            </Grid>
            <Grid className={classes.toolbarIcons}>
              <IconButton
                disabled={paymentByIdLoad}
                onClick={() =>
                  window.open(
                    `${window.location.origin}/report/print/payment/${paymentId}/${paymentType}`
                  )
                }
                color="default"
                size="large"
              >
                <Print fontSize="inherit" />
              </IconButton>
              <SecuredNavLink
                permission={canEdit}
                to={{
                  pathname: `/admin/payments/${paymentId}/edit`,
                }}
              >
                <IconButton color={"primary"} size="large">
                  <Edit fontSize="inherit" />
                </IconButton>
              </SecuredNavLink>
              <MutationWithDialog
                mutaion={DELETE_PAYMENT_MUTATION.query}
                icon={DeleteOutline}
                permission={Boolean(canDelete)}
                iconTooltip={t("deleteRecord")}
                dialogTitle={t("deleteRecord")}
                dialogContent={t("deleteRecordMessage")}
                mutaionProps={{ variables: { id: parseInt(paymentId) } }}
                onCompleted={() =>
                  pushUrl(props, `/admin/payments/${paymentType.toLowerCase()}`)
                }
                onCompleteMessage={t("successfullyDeletedRecord")}
              />

              <MutationWithDialog
                mutaion={DISAPPROVE_PAYMENT_MUTATION.query}
                icon={LockOpenOutlined}
                permission={Boolean(canDisapprove)}
                iconTooltip={t("disapprove")}
                dialogTitle={t("disapprove")}
                dialogContent={t("disapprovedMessage")}
                mutaionProps={{ variables: { id: parseInt(paymentId) } }}
                onCompleted={() => refetch()}
                onCompleteMessage={t("successfullyDisapproved")}
              />
            </Grid>
          </Grid>
        </Toolbar>
      </Fragment>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ margin: "0", width: "100%" }}
      >
        {paymentByIdLoad ? (
          <FullScreenLoading minHeight="25%" />
        ) : (
          <Grid
            container
            spacing={3}
            justifyContent="center"
            sx={{ width: "100%" }}
          >
            <Paper
              container
              className={classes.viewPaper}
              sx={{ mt: 1, flexGrow: 1 }}
              component={Grid}
            >
              <KeyValuePair
                title={t("recordCode")}
                value={data?.payment?.code}
              />
              <KeyValuePair
                title={t("date")}
                value={dateFormatLL(data?.payment?.date)}
              />
              <KeyValuePair
                title={t("branch")}
                value={
                  data?.payment?.branch?.name && (
                    <SpanLink
                      pathname={`/admin/branches/${data?.payment?.branch?.id}`}
                    >
                      {data?.payment?.branch?.name}
                    </SpanLink>
                  )
                }
              />
              <KeyValuePair
                title={t("safe")}
                value={
                  data?.payment?.safe?.name && (
                    <SpanLink
                      pathname={`/admin/safes/${data?.payment?.safe?.id}`}
                    >
                      {data?.payment?.safe?.name}
                    </SpanLink>
                  )
                }
              />
              <KeyValuePair
                title={t("journalEntry")}
                value={
                  data?.payment?.journalEntry?.code && (
                    <SpanLink
                      pathname={`/admin/finance/journal-entry/${data?.payment?.journalEntry?.id}`}
                    >
                      {data?.payment?.journalEntry?.code}
                    </SpanLink>
                  )
                }
              />
              {data?.payment?.deliveryAgent ? (
                <KeyValuePair
                  title={t("shippingAgent")}
                  value={
                    data?.payment?.deliveryAgent?.name && (
                      <SpanLink
                        pathname={`/admin/delivery-agents/${data?.payment?.deliveryAgent?.id}`}
                      >
                        {data?.payment?.deliveryAgent?.name}
                      </SpanLink>
                    )
                  }
                />
              ) : null}
              <KeyValuePair
                title={t("transactionType")}
                value={
                  <SpanLink
                    pathname={`/admin/shipment-transaction-types/${data?.payment?.transactionType?.id}`}
                  >
                    {data?.payment?.transactionType?.name}
                  </SpanLink>
                }
              />
              {data?.payment?.customer ? (
                <KeyValuePair
                  title={t("customer")}
                  value={
                    data?.payment && (
                      <SpanLink
                        pathname={`/admin/customers/${data?.payment?.customer?.id}`}
                      >
                        {data?.payment?.customer?.name}
                      </SpanLink>
                    )
                  }
                />
              ) : null}

              <KeyValuePair title={t("notes")} value={data?.payment?.notes} />
              <KeyValuePair
                title={t("createdBy")}
                value={
                  data?.payment && (
                    <SpanLink
                      pathname={`/admin/users/${data?.payment?.createdBy?.id}`}
                    >
                      {data?.payment?.createdBy?.username}
                    </SpanLink>
                  )
                }
              />
              <KeyValuePair
                title={t("updatedBy")}
                value={
                  data?.payment && (
                    <SpanLink
                      pathname={`/admin/users/${data?.payment?.updatedBy?.id}`}
                    >
                      {data?.payment?.updatedBy?.username}
                    </SpanLink>
                  )
                }
              />

              <KeyValuePair
                title={t("approved")}
                value={
                  data?.payment?.approved ? (
                    <Icon className={classes.iconColor}>
                      check_circle_outline
                    </Icon>
                  ) : (
                    <Icon color="error">highlight_off</Icon>
                  )
                }
              />
              <KeyValuePair
                title={t("glApproved")}
                value={
                  data?.payment?.glApproved ? (
                    <Icon className={classes.iconColor}>
                      check_circle_outline
                    </Icon>
                  ) : (
                    <Icon color="error">highlight_off</Icon>
                  )
                }
              />
            </Paper>
          </Grid>
        )}
      </Grid>
    </Grid>
  );

  return data?.payment === null ? (
    <NotFound />
  ) : (
    <Root>
      {props.children}

      {viewBody}

      <Grid
        container
        spacing={3}
        justifyContent="center"
        className={classes.mainTracking}
      >
        {paymentShipmentsDetails && paymentType === 'DLVBY' && <Grid container xs={12} justifyContent="center" sx={{ width: "100%" }}>
          {/* *******Table******* */}
          <Paper component={Grid} className={clsx(classes.table, classes.totalPaper)}>
            <Grid container
              justifyContent="center">
              <Grid xs={9} md={6}>
                <Typography
                  className={clsx(classes.titles, classes.totalTitle)}
                  color="inherit"
                  variant="subtitle1"
                  component="div"
                  sx={{ textAlign: "end" }}
                >
                  {t("totalDeservedAmount")}
                </Typography>
              </Grid>
              <Grid xs={3} md={6}>
                <Typography
                  className={clsx(classes.titles)}
                  color="inherit"
                  variant="subtitle1"
                  component="div"
                  sx={{ textAlign: "start" }}
                >
                  {data?.payment?.paymentAmount}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>}
        {paymentShipmentsDetails && tableBodyShipments}
        {paymentShipmentsDetails && paymentType === 'DLVBY' && tableBodyPickups}
      </Grid>
    </Root>
  );
};

export default PaymentView;
