import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React from "react";

const CustomDialog = (props) => {
  const { title, content, actions, ...restProps } = props;

  return (
    <Dialog  {...restProps}>
      {title && <DialogTitle>{title}</DialogTitle>}
      {props.children}
      {content && <DialogContent>{content}</DialogContent>}
      {actions && <DialogActions>{actions}</DialogActions>}
    </Dialog>
  );
};

export default CustomDialog;
