import { gql, useMutation } from "@apollo/client";
import {
  Box,
  Button,
  Checkbox,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";
import * as React from "react";
import { useState } from "react";

import * as gqlb from "gql-query-builder";
import { Fragment, useEffect } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import config from "../../config.json";
// import { Globals } from "../HOC/Classes/Globals";
import CustomDialog from "../HOC/CustomComponents/CustomDialog";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";

export const EXPOERT_REGISTRATIONS = gqlb.mutation({
  operation: "exportRegistrations",
  fields: [],
  variables: {
    input: {
      type: "ExportRegistrationsInput",
      required: true,
    },
  },
});

export default function ExportRegistrationsList(props) {
  const { filters, disabled } = props;
  const [registrationsFieldsState, setRegistrationsFieldsState] = useState([]);
  const { t } = useTranslation();
  const registrationsFields = [
    { name: "MOBILE", label: t("mobile") },
    { name: "EMAIL", label: t("email") },
    { name: "NAME", label: t("name") },
    { name: "DATE", label: t("date") },
    { name: "USER_NAME", label: t("username") },
    { name: "REGISTRATION_ZONE", label: t("zone") },
    { name: "REGISTRATION_SUBZONE", label: t("subzone") },
    { name: "ADDRESS", label: t("address") },
    { name: "VERIFIED", label: t("verified") },
    { name: "EMAIL_VERIFIED", label: t("emailVerified") },
  ];

  useEffect(() => {
    if (!registrationsFields[0].id)
      registrationsFields.forEach((ele, index) => {
        ele["id"] = index + 1;
        ele["checked"] = true;
      });

    const localStorageExportRegistrations = localStorage.getItem("export-registrations");
    const parsedExportRegistrations = localStorageExportRegistrations
      ? JSON.parse(localStorageExportRegistrations)
      : null;

    const notCheckedFields = registrationsFields.filter((i) => {
      if (
        !parsedExportRegistrations?.includes(i.name) &&
        parsedExportRegistrations !== null
      ) {
        i.checked = false;
        return true;
      }
      return false;
    });

    const checkedFields = registrationsFields.filter((i) => {
      if (parsedExportRegistrations?.includes(i.name)) {
        i.checked = true;
        return true;
      }
      return false;
    });

    const sortedFields = checkedFields.sort((a, b) => {
      return parsedExportRegistrations?.indexOf(a.name) >
        parsedExportRegistrations?.indexOf(b.name)
        ? 1
        : -1;
    });

    setRegistrationsFieldsState(
      localStorageExportRegistrations
        ? [...sortedFields, ...notCheckedFields]
        : registrationsFields
    );
    return () => {};
  }, [t]);

  const [dialogState, setDialogState] = useState(false);

  const fieldCount = registrationsFieldsState.length;
  const fieldSelected = registrationsFieldsState.filter((i) => i.checked).length;
  const onSelectAllClick = (e) => {
    const fileds = [...registrationsFieldsState];
    fileds.forEach((i) => {
      if (e.target.checked) {
        i.checked = true;
      } else {
        i.checked = false;
      }
    });
    setRegistrationsFieldsState(fileds);
  };

  const onCloseDialog = () => {
    setDialogState(false);
  };

  const onOpenDialog = () => {
    setDialogState(true);
  };

  let getBackendUri = (imgPath) => {
    const domain = config.backend.domain
      ? config.backend.domain
      : window.location.hostname;

    return `${config.backend.protocol}://${domain}:${config.backend.port}/${imgPath}`;
  };
  const [exportRegistrations, { loading }] = useMutation(
    gql`
      ${EXPOERT_REGISTRATIONS.query}
    `,
    {
      onCompleted: (data) => {
        const path = data.exportRegistrations;
        onCloseDialog();
        window.open(getBackendUri(path));
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(registrationsFieldsState);
    const [reorderedItem] = items.splice(result.source.index, 1);
    const isNotChecked = items[result.destination.index - 1]?.checked;
    if (isNotChecked === false) return;
    items.splice(result.destination.index, 0, reorderedItem);

    setRegistrationsFieldsState(items);
  }

  const handelExportRegistrations = () => {
    const updatedFields = registrationsFieldsState.reduce((previous, current) => {
      if (current.checked) {
        previous.push(current.name);
      }
      return previous;
    }, []);
    localStorage.setItem("export-registrations", JSON.stringify(updatedFields));
    const fields = registrationsFieldsState
      .filter((i) => i.checked)
      .map((i) => ({ label: i.label, name: i.name }));
    exportRegistrations({
      variables: {
        input: {
          filters,
          fields,
        },
      },
    });
  };

  return (
    <>
      <Tooltip title={t("exportExcel")}>
        <Box component="span">
          <IconButton
            disabled={disabled}
            onClick={() => onOpenDialog()}
            size="large"
          >
            <Icon>sim_card_download</Icon>
          </IconButton>
        </Box>
      </Tooltip>
      <CustomDialog
        fullWidth
        maxWidth="xs"
        open={dialogState}
        onClose={onCloseDialog}
        title={
          <Fragment>
            <Checkbox
              edge="start"
              indeterminate={fieldSelected > 0 && fieldSelected < fieldCount}
              checked={fieldCount > 0 && fieldSelected === fieldCount}
              onChange={onSelectAllClick}
              inputProps={{ "aria-label": "select all desserts" }}
            />
            {t("exportExcel")}
          </Fragment>
        }
        actions={
          <>
            <Button color="primary" onClick={onCloseDialog}>
              {t("cancel")}
            </Button>
            <Button
              color="primary"
              disabled={loading || fieldSelected === 0}
              onClick={handelExportRegistrations}
            >
              {loading ? <ButtonLoading /> : t("confirm")}
            </Button>
          </>
        }
        content={
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="characters">
              {(provided) => (
                <List
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  component="ul"
                  sx={{
                    width: "100%",
                    bgcolor: "background.paper",
                  }}
                >
                  {/* <ListItem dense button>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      indeterminate={
                        fieldSelected > 0 && fieldSelected < fieldCount
                      }
                      checked={fieldCount > 0 && fieldSelected === fieldCount}
                      onChange={onSelectAllClick}
                      inputProps={{ "aria-label": "select all desserts" }}
                    />
                  
                  </ListItemIcon>
                  <ListItemText primary={t("selectAll")} />
                </ListItem> */}

                  {registrationsFieldsState.map((value, index) => {
                    const labelId = `checkbox-list-label-${value.id}`;
                    return (
                      <Draggable
                        isDragDisabled={!value.checked}
                        key={value.id}
                        draggableId={value.name}
                        index={index}
                      >
                        {(provided) => (
                          <CustomeItemList
                            innerRef={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            itemDetails={value}
                            labelId={labelId}
                            state={registrationsFieldsState}
                            setState={setRegistrationsFieldsState}
                          />
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.label}
                </List>
              )}
            </Droppable>
          </DragDropContext>
        }
      />
    </>
  );
}

const CustomeItemList = (props) => {
  const { itemDetails, labelId, state, setState, innerRef, ...restProps } =
    props;
  const { label, checked } = itemDetails;
  // const [checkboxState,setCheckboxState] = React.state(checked)
  const handleToggle = (e) => {
    const checked = e.target.checked;
    const update = [...state];
    const checkedItemLength = update.filter((i) => i.checked).length;
    const index = update.findIndex((i) => i.name === itemDetails.name);
    const [sortItem] = update.splice(index, 1);

    sortItem["checked"] = checked;
    update.splice(checkedItemLength, 0, sortItem);

    setState(update);
  };
  return (
    <ListItem {...restProps} ref={innerRef} dense button>
      <ListItemIcon>
        <Checkbox
          edge="start"
          checked={checked}
          tabIndex={-1}
          onChange={handleToggle}
          disableRipple
          inputProps={{ "aria-labelledby": labelId }}
        />
      </ListItemIcon>
      <ListItemText id={labelId} primary={label} />
    </ListItem>
  );
};
