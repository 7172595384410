/* eslint-disable no-useless-concat */
import React, { Fragment } from "react";
import { styled } from '@mui/material/styles';
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { SecuredNavLink } from "../HOC/CustomComponents/Secured";
import { Typography, Paper, Toolbar, IconButton, Icon } from "@mui/material";
import { DeleteOutline, Edit, LockOpenOutlined } from "@mui/icons-material";
import { gql, useQuery } from "@apollo/client";
import { KeyValuePair } from "../HOC/CustomComponents/KeyValuePair";
import {
  DELETE_JOURNAL_ENTRY,
  DISAPPROVE_JOURNAL_ENTRY_MUTATION,
  JOURNAL_ENTRY,
} from "./Graphql";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import RecordsTable from "./RecordsTable";
import NotFound from "../../Error/NotFound";
import { Globals } from "../HOC/Classes/Globals";
import MutationWithDialog from "../HOC/CustomComponents/MutationWithDialog";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import SpanLink from "../HOC/CustomComponents/SpanLink";
import Grid from "@mui/material/Unstable_Grid2";

const PREFIX = 'JournalEntryView';

const classes = {
  loading: `${PREFIX}-loading`,
  box: `${PREFIX}-box`,
  toolbar: `${PREFIX}-toolbar`,
  toolbarIcons: `${PREFIX}-toolbarIcons`,
  iconButton: `${PREFIX}-iconButton`,
  main: `${PREFIX}-main`,
  field: `${PREFIX}-field`,
  iconColor: `${PREFIX}-iconColor`
};

const StyledGrid = styled(Grid)((
  {
    theme
  }
) => ({
  [`& .${classes.loading}`]: {},

  [`& .${classes.box}`]: {
    padding: theme.spacing(3),
    flexGrow: 1,
  },

  [`& .${classes.toolbar}`]: {
    borderBottom: "1px #ccd1d6 solid",
    backgroundColor: "#f5f7f9",
    width: "100%",
  },

  [`& .${classes.toolbarIcons}`]: {
    flex: "1 1 100%",
    textAlign: "end",
  },

  [`& .${classes.iconButton}`]: {
    borderRadius: "0 4px 4px 0 ",
    boxShadow: "none",
    height: 45,
  },

  [`& .${classes.main}`]: {
    margin: 0,
    width: "100%",
  },

  [`& .${classes.field}`]: {
    width: 260,
    [theme.breakpoints.down("sm")]: {
      width: 170,
    },
  },

  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  }
}));

const JournalEntryView = (props) => {

  const { t } = useTranslation();
  const journalEntryId = props.match.params.id?.trim();

  const { loading, data, refetch } = useQuery(
    gql`
      ${JOURNAL_ENTRY.query}
    `,
    {
      fetchPolicy: "network-only",
      variables: { id: parseInt(journalEntryId) },
    }
  );

  const user = Globals.user;
  const branchId = data?.journalEntry?.branch?.id;
  const canAccessBranch = branchId ? user.canAccessBranch(branchId) : true;

  const canDisapprove =
    !loading &&
    data?.journalEntry?.approved &&
    data?.journalEntry?.sourceType?.code === "MANUAL" &&
    canAccessBranch &&
    user.hasPermission("accounting.journal_entry.disapprove");

  const canDelete =
    !loading &&
    user.hasPermission("accounting.journal_entry.delete") &&
    canAccessBranch &&
    !data?.journalEntry?.approved;

  const canEdit =
    !loading &&
    user.hasPermission("accounting.journal_entry.update") &&
    canAccessBranch &&
    !data?.journalEntry?.approved;

  return data?.journalEntry === null ? (
    <NotFound />
  ) : (
    <StyledGrid
      container
      justifyContent="center"
      className={classes.mainTracking}
      sx={{ margin: "0", width: "100%" }}
    >
      <Fragment>
        <Toolbar className={clsx(classes.toolbar)} variant="regular">
          <Grid
            container
            sx={{ flexWrap: "initial", flexGrow: 1 }}
            alignItems="center"
          >
            <Grid className={classes.field}>
              <Typography variant="h6">{t("journalEntries")}</Typography>
            </Grid>
            <Grid className={classes.toolbarIcons}>
              <SecuredNavLink
                permission={canEdit}
                to={{
                  pathname: `/admin/finance/journal-entry/${journalEntryId}/edit`,
                }}
              >
                <IconButton color={"primary"} size="large">
                  <Edit fontSize="inherit" />
                </IconButton>
              </SecuredNavLink>

              <MutationWithDialog
                mutaion={DELETE_JOURNAL_ENTRY.query}
                icon={DeleteOutline}
                permission={Boolean(canDelete)}
                iconTooltip={t("deleteJournalEntry")}
                dialogTitle={t("deleteJournalEntry")}
                dialogContent={t("deleteRecordMessage")}
                mutaionProps={{ variables: { id: parseInt(journalEntryId) } }}
                onCompleted={() =>
                  pushUrl(props, "/admin/finance/journal-entry")
                }
                onCompleteMessage={t("deleteMessage")}
              />

              <MutationWithDialog
                mutaion={DISAPPROVE_JOURNAL_ENTRY_MUTATION.query}
                icon={LockOpenOutlined}
                permission={Boolean(canDisapprove)}
                iconTooltip={t("disapprove")}
                dialogTitle={t("disapprove")}
                dialogContent={t("disapprovedMessage")}
                mutaionProps={{ variables: { id: parseInt(journalEntryId) } }}
                onCompleted={() => refetch()}
                onCompleteMessage={t("successfullyDisapproved")}
              />
            </Grid>
          </Grid>
        </Toolbar>
      </Fragment>
      {loading ? (
        <FullScreenLoading minHeight="25%" />
      ) : (
        <>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{ width: "100%", p: 2, gap: 2 }}
          >
            <Paper container className={classes.box} component={Grid}>
              <KeyValuePair
                title={t("date")}
                value={data?.journalEntry?.date}
              />
              <KeyValuePair
                title={t("code")}
                value={data?.journalEntry?.code}
              />
              <KeyValuePair
                title={t("branch")}
                value={data?.journalEntry.branch.name}
              />
              <KeyValuePair
                title={t("type")}
                value={data?.journalEntry.type.name}
              />
              <KeyValuePair
                title={t("subsidiary")}
                value={data?.journalEntry?.subsidiary?.name}
              />
              <KeyValuePair
                title={t("description")}
                value={data?.journalEntry?.description}
              />

              <KeyValuePair
                title={t("createdBy")}
                value={
                  data?.journalEntry && (
                    <SpanLink
                      pathname={`/admin/users/${data?.journalEntry?.createdBy?.id}`}
                    >
                      {data?.journalEntry?.createdBy?.username}
                    </SpanLink>
                  )
                }
              />
              <KeyValuePair
                title={t("updatedBy")}
                value={
                  data?.journalEntry && (
                    <SpanLink
                      pathname={`/admin/users/${data?.journalEntry?.updatedBy?.id}`}
                    >
                      {data?.journalEntry?.updatedBy?.username}
                    </SpanLink>
                  )
                }
              />

              <KeyValuePair
                title={t("approved")}
                value={
                  data?.journalEntry?.approved ? (
                    <Icon className={classes.iconColor}>
                      check_circle_outline
                    </Icon>
                  ) : (
                    <Icon color="error">highlight_off</Icon>
                  )
                }
              />
            </Paper>
          </Grid>
          <RecordsTable records={data?.journalEntry?.records} />
        </>
      )}
    </StyledGrid>
  );
};

export default JournalEntryView;
