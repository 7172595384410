import { useParams } from "react-router";

export const GetManifestPermissionSlugRoute = (manifestType) => {
    const { type } = useParams();
    type && GetManifestPermissionSlug(type, manifestType)
};
export const GetManifestPermissionSlug = (code, manifestType) => {
    return `shipping.manifest_${code}.${manifestType}`;
};
export const GetCollectionPermissionSlugRoute = (collectionType) => {
    const { type } = useParams();
    type && GetCollectionPermissionSlug(type, collectionType)
};
export const GetCollectionPermissionSlug = (code, collectionType) => {
    return `shipping.collection_${code}.${collectionType}`;
};
export const GetPaymentPermissionSlugRoute = (paymentType) => {
    const { type } = useParams();
    type && GetPaymentPermissionSlug(type, paymentType)
};
export const GetPaymentPermissionSlug = (code, paymentType) => {
    return `shipping.payment_${code}.${paymentType}`;
};
export const GetCustomerReqPermissionSlugRoute = (customerRequestType) => {
    const { type } = useParams();
    type && GetCustomerReqPermissionSlug(type, customerRequestType)
};
export const GetCustomerReqPermissionSlug = (code, customerRequestType) => {
    return `shipping.customer_request_${code}.${customerRequestType}`;
};
export const GetCustomerPermissionSlug = (module, name, code, Type) => {
    return `${module}.${name}_${code?.toLowerCase()}.${Type}`;
};