/* eslint-disable no-useless-concat */
import React, { useEffect, useState } from "react";

import { styled } from "@mui/material/styles";

import {
  Box,
  Button,
  Drawer,
  Grid,
  Icon,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";

import { gql, useQuery } from "@apollo/client";
import { Add, Clear, FilterList, Print, Search } from "@mui/icons-material";
import clsx from "clsx";
import moment from "moment";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import emptyList from "../../assets/Image/received.png";
import {
  LIST_BRANCHES_DROPDOWN,
  LIST_CUSTOMERS_DROPDOWN,
  LSIT_ZONES_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import useWidth from "../../Hooks/useWidth";
import CellLink from "../HOC/CustomComponents/CellLink";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import { SecuredNavLink } from "../HOC/CustomComponents/Secured";
import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";
import { windowUrl } from "../HOC/CustomFunctions/pushUrl";
import { urlParameters } from "../HOC/CustomFunctions/urlParameters";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import MUIDateRangeCustom from "../HOC/MUI/MUIDateRangeCustom";
import MUIselect from "../HOC/MUI/MUIselect";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import ExportInvoices from "./ExportInvoices";
import { LIST_INVOICES_QUERY } from "./InvoicesListQuary";

const PREFIX = "InvoicesList";

const classes = {
  track: `${PREFIX}-track`,
  typo: `${PREFIX}-typo`,
  mainTracking: `${PREFIX}-mainTracking`,
  appBar: `${PREFIX}-appBar`,
  title: `${PREFIX}-title`,
  textAlign: `${PREFIX}-textAlign`,
  divider: `${PREFIX}-divider`,
  paper: `${PREFIX}-paper`,
  filterAnchor: `${PREFIX}-filterAnchor`,
  picker: `${PREFIX}-picker`,
  button: `${PREFIX}-button`,
  toolbar: `${PREFIX}-toolbar`,
  selectPadding: `${PREFIX}-selectPadding`,
  margin: `${PREFIX}-margin`,
  titles: `${PREFIX}-titles`,
  filterForm: `${PREFIX}-filterForm`,
  filterField: `${PREFIX}-filterField`,
  tableIcon: `${PREFIX}-tableIcon`,
  drawer: `${PREFIX}-drawer`,
  drawerPaper: `${PREFIX}-drawerPaper`,
  drawerHeader: `${PREFIX}-drawerHeader`,
  content: `${PREFIX}-content`,
  contentShift: `${PREFIX}-contentShift`,
  iconColor: `${PREFIX}-iconColor`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.track}`]: {
    marginTop: theme.spacing(2),
  },

  [`& .${classes.typo}`]: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    fontWeight: 600,
  },

  [`& .${classes.mainTracking}`]: {
    margin: theme.spacing(0, 0),
    width: "100%",
  },

  [`& .${classes.appBar}`]: {
    position: "relative",
  },

  [`& .${classes.title}`]: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },

  [`& .${classes.textAlign}`]: {
    textAlign: "justify",
    padding: theme.spacing(1),
  },

  [`& .${classes.divider}`]: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
      height: "1px",
    },
  },

  [`& .${classes.paper}`]: {
    display: "grid",
    width: "100%",
    marginBottom: theme.spacing(2),
    borderRadius: 0,
  },

  [`& .${classes.filterAnchor}`]: {
    [theme.breakpoints.down("md")]: {
      width: "auto",
    },
    // overflowX: "scroll",
    "& .MuiDrawer-paper": {
      [theme.breakpoints.up("sm")]: {
        width: "30%",
      },
    },
  },

  [`& .${classes.picker}`]: {
    [theme.breakpoints.down("md")]: {
      width: "auto",
    },
    // overflow: "scroll",
    "& .MuiDrawer-paper": {
      [theme.breakpoints.up("sm")]: {
        width: "50%",
      },

      height: "100%",
      overflow: "hidden",
    },
  },

  [`& .${classes.button}`]: {
    position: "sticky",
    bottom: 0,
    zIndex: 1,
    backgroundColor: "#fff",
    marginTop: 10,
    padding: 7,
  },

  [`& .${classes.toolbar}`]: {
    borderBottom: "solid 1px",
    borderBottomColor: theme.palette.divider,
    // zIndex: "1300",
    // justify: "space-between"
  },

  [`& .${classes.selectPadding}`]: {
    paddingTop: "6px",
    paddingBottom: "5px",
  },

  [`& .${classes.margin}`]: {
    margin: theme.spacing(1, 2),
  },

  [`& .${classes.titles}`]: {
    flex: "1 1 100%",
  },

  [`& .${classes.filterForm}`]: {
    overflowY: "auto",
    width: "100%",
    margin: 0,
    position: "relative",
    height: "100vh",
  },

  [`& .${classes.filterField}`]: {
    padding: theme.spacing(1),
    width: "100%",
    margin: 0,
  },

  [`& .${classes.tableIcon}`]: {
    margin: theme.spacing(0, 1),
  },

  /////////Drawer///////////

  [`& .${classes.drawer}`]: {
    width: drawerWidth,
    flexShrink: 0,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      // height: "100%",
    },
  },

  [`& .${classes.drawerPaper}`]: {
    width: drawerWidth,

    zIndex: "250",

    left: "initial",
    right: "initial",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100%",
    },
  },

  [`& .${classes.drawerHeader}`]: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },

  [`& .${classes.content}`]: {
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
  },

  [`& .${classes.contentShift}`]: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: "auto",
    width: "calc(100% - 240px)",
    [theme.breakpoints.down("sm")]: {
      margin: "auto",
      width: "auto",
    },
  },

  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },
}));

///////////////////////////////////////////////////

//////////////////////////////////////////////////
const drawerWidth = 240;

function EnhancedTableHead(props) {
  //*********Table Function*********
  const { t } = useTranslation();
  const headCells = [
    { numeric: true, disablePadding: false, label: t("recordCode") },
    { numeric: true, disablePadding: false, label: t("date") },
    {
      numeric: true,
      disablePadding: false,
      label: t("customer"),
    },
    { numeric: true, disablePadding: false, label: t("shipmentsNumber") },
    { numeric: true, disablePadding: false, label: t("pieceCount") },
    { numeric: true, disablePadding: false, label: t("weight") },
    { numeric: true, disablePadding: false, label: t("deliveryFees") },
    { numeric: true, disablePadding: false, label: t("postFees") },
    { numeric: true, disablePadding: false, label: t("tax") },
    { numeric: true, disablePadding: false, label: t("value") },
    { numeric: true, disablePadding: false, label: t("approved") },
    { numeric: true, disablePadding: false, label: t("glApproved") },
  ];
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => {
          return (
            <FixedTableCell
              key={index}
              padding={headCell.disablePadding ? "none" : "normal"}
            >
              {headCell.label}
            </FixedTableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

/////////////////DIALOG///////////////

const initDate = moment(new Date()).locale("en").format("YYYY-MM-DD");

const InvoicesList = (props) => {
  const urlQuery = urlParameters(window.location.search);
  const screenWidth = useWidth();
  const listType = props.match.params.type;
  const history = useHistory();
  const { t } = useTranslation();
  const filterAnchor = screenWidth === "xs" ? "bottom" : "left";

  const validUrlParameters = Object.keys(urlQuery).length !== 0 && !Object.keys(urlQuery).includes("fromDate", "toDate");

  const initFromDate = urlQuery["fromDate"]
    ? urlQuery["fromDate"]
    : validUrlParameters ? null : initDate;
  const initToDate = urlQuery["toDate"]
    ? urlQuery["toDate"]
    : validUrlParameters ? null : initDate;
  const [dateRange, setDateRange] = useState([initFromDate, initToDate]);
  const fromDate = moment(dateRange[0]).locale("en").format("YYYY-MM-DD");
  const toDate = moment(dateRange[1]).locale("en").format("YYYY-MM-DD");



  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);
  const [queryVar, setQuaryVar] = useState(null);

  const [state, setState] = React.useState({
    top: true,
    left: screenWidth === "xs" ? false : true,
    bottom: screenWidth === "xs" ? false : true,
    right: screenWidth === "xs" ? false : true,
  });

  const [autocompleteValues, setAutocompleteValues] = useState({
    deliveryAgent: null,
    customer: null,
    toZone: null,
    toSubzone: null,
    fromZone: null,
    fromSubzone: null,
  });

  const handelAutocompleteDefaultValue = (data, id, fieldName) => {
    const defaultValue = data[Object.keys(data)[0]].find(
      (i) => i.id === parseInt(id)
    );
    defaultValue &&
      setAutocompleteValues((prev) => ({
        ...prev,
        [fieldName]: defaultValue,
      }));
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const resetDate = () => {
    setDateRange([null, null]);
  };
  const parseData = (data) => {
    return data;
  };

  const {
    formState: { errors },
    handleSubmit,
    control,
    watch,
    setValue,
  } = useForm();


  const searchParams = (param) => {
    const queryParams = [];
    for (const i in param) {
      encodeURIComponent(param[i]) &&
        queryParams.push(
          encodeURIComponent(i) + "=" + encodeURIComponent(param[i])
        );
    }
    const queryString = queryParams.join("&");
    return queryString;
  };

  const pushUrlSearch = (param) => {
    const params = searchParams(param);

    const url = history.createHref({
      pathname: `/admin/invoices`,
      search: "?" + params,
    });
    windowUrl(url);
  };

  useEffect(() => {
    const { first, page, ...input } = urlQuery;
    if (input["code"]) input["code"] = input["code"].toString();
    if (input["fromCode"]) input["fromCode"] = input["fromCode"].toString();
    if (input["toCode"]) input["toCode"] = input["toCode"].toString();
    if (input["toWeight"] !== undefined) input["toWeight"] = parseFloat(input["toWeight"])
    if (input["fromWeight"] !== undefined) input["fromWeight"] = parseFloat(input["fromWeight"])
    setQuaryVar((prev) => ({
      ...(dateRange[0] && { fromDate }),
      ...(dateRange[1] && { toDate }),
      ...prev,
      ...input,
      // type: listType.toUpperCase(),
    }));
    urlQuery["code"] && setValue("code", urlQuery["code"].toString());
    urlQuery["fromCode"] && setValue("fromCode", urlQuery["fromCode"].toString());
    urlQuery["toCode"] && setValue("toCode", urlQuery["toCode"].toString());
    urlQuery["toWeight"] !== undefined && setValue("toWeight", parseFloat(urlQuery["toWeight"]));
    urlQuery["fromWeight"] !== undefined && setValue("fromWeight", parseFloat(urlQuery["fromWeight"]));
    urlQuery["code"] && setValue("code", urlQuery["code"].toString());
    urlQuery["approved"] !== undefined &&
      setValue("approved", urlQuery["approved"]);
    urlQuery["glApproved"] !== undefined &&
      setValue("glApproved", urlQuery["glApproved"]);
    urlQuery["first"] && setRowsPerPage(parseInt(urlQuery["first"]));
    urlQuery["page"] && setPage(parseInt(urlQuery["page"] - 1));
    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    pushUrlSearch({ ...urlQuery, page: newPage + 1 });
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    pushUrlSearch({ ...urlQuery, page: 1, first: +event.target.value });
  };
  const onSubmit = (data) => {
    data["fromDate"] = dateRange[0] && fromDate;
    data["toDate"] = dateRange[1] && toDate;
    if (data["fromWeight"]) { data["fromWeight"] = parseFloat(data["fromWeight"]); }
    if (data["toWeight"]) { data["toWeight"] = parseFloat(data["toWeight"]); }
    filterAnchor === "bottom" &&
      setState((prev) => ({ ...prev, [filterAnchor]: false }));
    for (const key in data) {
      if (data[key] === "" || data[key] === null) {
        delete data[key];
      }
    }
    pushUrlSearch({
      ...data,
      first: rowsPerPage,
      page: 1
    });
    setQuaryVar({
      ...data,
      ...(dateRange[0] && {
        fromDate
      }),
      ...(dateRange[1] && {
        toDate
      }),
      // type: listType.toUpperCase(),
    });
    setPage(0);
  };

  // List Query
  const { data, loading } = useQuery(
    gql`
      ${LIST_INVOICES_QUERY.query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      skip: !queryVar,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: {
        first: rowsPerPage,
        page: page + 1,
        input: {
          ...queryVar,
        },
      },
      onCompleted: (data) => { },
    }
  );
  const invoicesData =
    data?.listInvoices?.data !== null ? data?.listInvoices?.data : null;
  const sumInvoices = data?.sumInvoices;

  const emptyInoiceList = data?.listInvoices?.data?.length === 0;

  const onPrint = (param) => {
    const params = searchParams(queryVar);
    const domain = `${window.location.origin}`;

    window.open(`${domain}${param}?${params}`);
  };

  const EnhancedTableToolbar = (props) => {
    const { exportFilters } = props;
    return (
      <Toolbar className={clsx(classes.toolbar)} variant="dense">
        <Typography
          className={classes.titles}
          color="inherit"
          variant="h6"
          component="div"
        >
          {t("postpaidInvoices")}
        </Typography>

        <Tooltip title="Filter list">
          <IconButton
            aria-label="filter list"
            onClick={() => props.filter()}
            size="large"
          >
            <FilterList />
          </IconButton>
        </Tooltip>

        <ExportInvoices
          disabled={Boolean(data?.listInvoices?.data?.length === 0)}
          filters={exportFilters}
        />
        {/* <ExportInvoices disabled={emptyInoiceList} filters={exportFilters} /> */}

        <Tooltip title="print invoices">
          <Box component="span">
            <IconButton
              aria-label="filter list"
              size="large"
              onClick={() => onPrint("/invoices/print")}
              disabled={emptyInoiceList}
            >
              <Print />
            </IconButton>
          </Box>
        </Tooltip>

        <Tooltip title="print details">
          <Box component="span">
            <IconButton
              aria-label="filter list"
              size="large"
              color="primary"
              onClick={() => onPrint(`/report/print/invoice/n`)}
              disabled={emptyInoiceList}
            >
              <Print />
            </IconButton>
          </Box>
        </Tooltip>

        <SecuredNavLink
          permission="shipping.invoice.create"
          to={{ pathname: `/admin/invoices/create` }}
        >
          <Tooltip title="create invoice">
            <IconButton
              aria-label="filter list"
              className={classes.addIcon}
              size="large"
            >
              <Add color="primary" />
            </IconButton>
          </Tooltip>
        </SecuredNavLink>
      </Toolbar>
    );
  };

  EnhancedTableToolbar.propTypes = {};

  let invoicesBody = null;
  invoicesBody = (
    <Grid container item xs={12} justifyContent="center" sx={{ width: "100%" }}>
      {/* *******Table******* */}
      <Grid container item>
        <Paper className={clsx(classes.paper)}>
          <EnhancedTableToolbar
            filter={toggleDrawer(filterAnchor, true)}
            exportFilters={queryVar}
            {...props}
          />

          {loading || emptyInoiceList ? (
            <Grid
              container
              item
              justifyContent="center"
              className={classes.track}
            >
              {loading ? (
                <FullScreenLoading minHeight='15%' />
              ) : (
                <Box textAlign="center">
                  <img width="50%" src={emptyList} alt={"delivery box"} />
                  <Box
                    component={Typography}
                    variant="h6"
                    color="text.secondary"
                    marginTop="0"
                  >
                    {t("searchNoResult")}
                  </Box>
                </Box>
              )}
            </Grid>
          ) : (
            <TableFixedHeaderWraper>
              <Table aria-labelledby="tableTitle" aria-label="enhanced table">
                <EnhancedTableHead />
                <TableBody>
                  {invoicesData &&
                    invoicesData?.map((row, index) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={index}
                        // onClick={(e) => {
                        //   e.stopPropagation();
                        //   pushUrl(props, `/admin/invoices/${row?.id}/edit`);
                        // }}
                        >
                          <CellLink
                            pathname={`/admin/invoices/${row?.id}`}
                            component="th"
                            scope="row"
                          >
                            {row.code}
                          </CellLink>
                          <FixedTableCell>
                            {moment(row?.date).format("DD-MM-YYYY")}
                          </FixedTableCell>
                          {row?.customer ? (
                            <CellLink
                              pathname={`/admin/customers/${row?.customer?.id}`}
                            >
                              <Box
                                component="span"
                                fontWeight="bold"
                              >{` (${row.customer.code}) `}</Box>
                              <Box component="span">{row.customer.name}</Box>
                            </CellLink>
                          ) : (
                            <FixedTableCell> </FixedTableCell>
                          )}

                          <FixedTableCell>{row?.shipmentsCount}</FixedTableCell>
                          <FixedTableCell>{row?.pieces}</FixedTableCell>
                          <FixedTableCell>{row?.weight}</FixedTableCell>
                          <FixedTableCell>{row?.deliveryFees}</FixedTableCell>
                          <FixedTableCell>{row?.postFees}</FixedTableCell>
                          <FixedTableCell>{row?.tax}</FixedTableCell>
                          <FixedTableCell>{row?.allFees}</FixedTableCell>
                          <FixedTableCell>
                            {row?.approved ? (
                              <Icon className={classes.iconColor}>
                                check_circle_outline
                              </Icon>
                            ) : (
                              <Icon color="error">highlight_off</Icon>
                            )}
                          </FixedTableCell>
                          <FixedTableCell>
                            {row?.glApproved ? (
                              <Icon className={classes.iconColor}>
                                check_circle_outline
                              </Icon>
                            ) : (
                              <Icon color="error">highlight_off</Icon>
                            )}
                          </FixedTableCell>
                        </TableRow>
                      );
                    })}
                  <TableRow>
                    <FixedTableCell colSpan={2} />
                    <FixedTableCell variant="head">{t("total")}</FixedTableCell>
                    <FixedTableCell variant="head">
                      {sumInvoices?.shipmentsCount}
                    </FixedTableCell>
                    <FixedTableCell variant="head">
                      {sumInvoices?.piecesCount}
                    </FixedTableCell>
                    <FixedTableCell variant="head">
                      {sumInvoices?.weight}
                    </FixedTableCell>
                    <FixedTableCell variant="head">
                      {sumInvoices?.deliveryFees}
                    </FixedTableCell>
                    <FixedTableCell variant="head">
                      {sumInvoices?.postFees}
                    </FixedTableCell>
                    <FixedTableCell variant="head">
                      {sumInvoices?.tax}
                    </FixedTableCell>
                    <FixedTableCell variant="head">
                      {sumInvoices?.allFees}
                    </FixedTableCell>
                    <FixedTableCell colSpan={3} />
                  </TableRow>
                </TableBody>
              </Table>
            </TableFixedHeaderWraper>
          )}
          <MUITablePagination
            count={data?.listInvoices?.paginatorInfo?.total}
            rowsPerPage={rowsPerPage}
            page={!data?.listInvoices ? 0 : page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Grid>
    </Grid>
  );

  return (
    <Root>
      {props.children}

      <Drawer
        className={clsx(classes.drawer)}
        variant="persistent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor={filterAnchor}
        open={state[filterAnchor]}
        onClose={() => false}
      >
        <div className={classes.drawerHeader} />

        <Toolbar className={clsx(classes.toolbar)} variant="dense">
          <Typography
            className={classes.titles}
            color="inherit"
            variant="h6"
            component="div"
          >
            {t("search")}
          </Typography>
          <IconButton
            aria-label="done"
            size="small"
            // disabled={Boolean(numSelected <= 0)}
            onClick={toggleDrawer(filterAnchor, false)}
            color={"primary"}
          >
            <Clear />
          </IconButton>
        </Toolbar>

        <Grid
          container
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          className={classes.filterForm}
        >
          <Grid
            container
            item
            sm={12}
            justifyContent="flex-start"
            spacing={1}
            className={classes.filterField}
          >
            <Grid item xs={12}>
              <MUIDateRangeCustom
                value={dateRange}
                onChangeValue={(value) => setDateRange(value)}
                resetDate={resetDate}
              />
            </Grid>
            <Grid item xs={12}>
              <ControlMUItextField
                control={control}
                errors={errors}
                name={"code"}
                label={t("code")}
              />
            </Grid>
            <Grid item xs={12}>
              <MUIselect
                control={control}
                errors={errors}
                name={"approved"}
                label={t("status")}
                data={[
                  { key: t("approved"), value: true },
                  { key: t("notApproved"), value: false },
                ]}
                defaultValue={urlQuery?.approved}
              />
            </Grid>
            <Grid container item>
              <CustomAutocomplete
                name={"branchId"}
                label={t("currentBranch")}
                control={control}
                errors={errors}
                parseData={(data) => parseData(data)}
                onCompleted={(data) =>
                  handelAutocompleteDefaultValue(
                    data,
                    urlQuery["branchId"],
                    "branch"
                  )
                }
                query={LIST_BRANCHES_DROPDOWN.query}
                defaultValue={autocompleteValues.branch}
              />
            </Grid>
            <Grid item xs={12}>
              <ControlMUItextField
                control={control}
                errors={errors}
                type={"number"}
                name={"fromWeight"}
                label={t("fromWeight")}
              />
            </Grid>
            <Grid item xs={12}>
              <ControlMUItextField
                control={control}
                errors={errors}
                type={"number"}
                name={"toWeight"}
                label={t("toWeight")}
              />
            </Grid>
            <Grid item xs={12}>
              <ControlMUItextField
                control={control}
                errors={errors}
                name={"fromCode"}
                label={t("fromCode")}
              />
            </Grid>
            <Grid item xs={12}>
              <ControlMUItextField
                control={control}
                errors={errors}
                name={"toCode"}
                label={t("toCode")}
              />
            </Grid>
            <Grid item xs={12}>
              <MUIselect
                control={control}
                errors={errors}
                name={"glApproved"}
                label={t("glApprove")}
                data={[
                  { key: t("glApproved"), value: true },
                  { key: t("notGlApproved"), value: false },
                ]}
                defaultValue={urlQuery?.approved}
              />
            </Grid>

            <Grid item xs={12}>
              <CustomAutocomplete
                control={control}
                errors={errors}
                name={"customerId"}
                label={t("customer")}
                query={LIST_CUSTOMERS_DROPDOWN.query}
                onCompleted={(data) =>
                  handelAutocompleteDefaultValue(
                    data,
                    urlQuery["customerId"],
                    "customer"
                  )
                }
                parseData={(data) => parseData(data)}
                variables={{
                  input: {
                    active: true,
                  },
                }}
                defaultValue={autocompleteValues.customer}
              />
            </Grid>

            <Grid item xs={12}>
              <CustomAutocomplete
                control={control}
                errors={errors}
                name={"fromZoneId"}
                label={t("fromZone")}
                onChangeValue={(e) => {
                  setValue("fromSubzoneId", "");
                  delete urlQuery["fromSubzoneId"];

                  pushUrlSearch({ ...urlQuery });
                }}
                onCompleted={(data) =>
                  handelAutocompleteDefaultValue(
                    data,
                    urlQuery["fromZoneId"],
                    "fromZone"
                  )
                }
                variables={{ input: { parentId: null } }}
                parseData={(data) => parseData(data)}
                query={LSIT_ZONES_DROPDOWN.query}
                defaultValue={autocompleteValues.fromZone}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomAutocomplete
                control={control}
                errors={errors}
                name={"fromSubzoneId"}
                label={t("fromSubzone")}
                skip={!watch("fromZoneId")}
                disabled={!watch("fromZoneId")}
                variables={{ input: { parentId: watch("fromZoneId") } }}
                onCompleted={(data) =>
                  handelAutocompleteDefaultValue(
                    data,
                    urlQuery["fromSubzoneId"],
                    "fromSubzone"
                  )
                }
                parseData={(data) => parseData(data)}
                query={LSIT_ZONES_DROPDOWN.query}
                defaultValue={autocompleteValues.fromSubzone}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomAutocomplete
                control={control}
                errors={errors}
                name={"toZoneId"}
                label={t("toZone")}
                onChangeValue={(e) => {
                  delete urlQuery["toSubzoneId"];
                  setValue("toSubzoneId", "");

                  pushUrlSearch({ ...urlQuery });
                }}
                onCompleted={(data) =>
                  handelAutocompleteDefaultValue(
                    data,
                    urlQuery["toZoneId"],
                    "toZone"
                  )
                }
                variables={{ input: { parentId: null } }}
                parseData={(data) => parseData(data)}
                query={LSIT_ZONES_DROPDOWN.query}
                defaultValue={autocompleteValues.toZone}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomAutocomplete
                control={control}
                errors={errors}
                name={"toSubzoneId"}
                label={t("toSubzone")}
                skip={!watch("toZoneId")}
                disabled={!watch("toZoneId")}
                onCompleted={(data) =>
                  handelAutocompleteDefaultValue(
                    data,
                    urlQuery["toSubzoneId"],
                    "toSubzone"
                  )
                }
                variables={{ input: { parentId: watch("toZoneId") } }}
                parseData={(data) => parseData(data)}
                query={LSIT_ZONES_DROPDOWN.query}
                defaultValue={autocompleteValues.toSubzone}
              />
            </Grid>
          </Grid>

          <Grid
            container
            item
            sm={12}
            className={classes.button}
            alignItems="center"
            justifyContent="flex-end"
          >
            <Button
              type="submit"
              fullWidth
              variant="contained"
              size="medium"
              color="primary"
              startIcon={<Search />}
            >
              {t("search")}
            </Button>
          </Grid>
        </Grid>
      </Drawer>

      <Grid
        container
        justifyContent="center"
        className={clsx(classes.content, classes.mainTracking, {
          [classes.contentShift]: state[filterAnchor],
        })}
      >
        {invoicesBody}
      </Grid>
    </Root>
  );
};

export default InvoicesList;
