import React, { useState } from "react";

import { styled } from '@mui/material/styles';

import { useQuery, gql } from "@apollo/client";
import {
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
  Toolbar,
  Box,
  Table,
  TableBody,
  TableRow,
  TableHead,
  Icon,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import clsx from "clsx";
import { SecuredNavLink } from "../HOC/CustomComponents/Secured";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import { LIST_ROLES } from "./Graphql";
import { useTranslation } from "react-i18next";
import emptyList from "../../assets/Image/received.png";
import CellLink from "../HOC/CustomComponents/CellLink";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";

const PREFIX = 'ListRoles';

const classes = {
  title: `${PREFIX}-title`,
  paper: `${PREFIX}-paper`,
  main: `${PREFIX}-main`,
  mainContainer: `${PREFIX}-mainContainer`,
  iconColor: `${PREFIX}-iconColor`,
  spanStyle: `${PREFIX}-spanStyle`
};

const StyledGrid = styled(Grid)((
  {
    theme
  }
) => ({
  [`& .${classes.title}`]: {
    flex: 1,
  },

  [`& .${classes.paper}`]: {
    display: "grid",
    width: "100%",
    borderRadius: 0,
  },

  [`& .${classes.main}`]: {
    marginTop: theme.spacing(2),
  },

  [`&.${classes.mainContainer}`]: {
    width: "100%",
  },

  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },

  [`& .${classes.spanStyle}`]: {
    display: "inline-block",
    margin: "5px",
  }
}));

const ListRoles = (props) => {
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);

  const { t } = useTranslation();
  const { data, loading } = useQuery(
    gql`
      ${LIST_ROLES.query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: {
        first: rowsPerPage,
        page: page + 1,
      },
      onCompleted: (data) => {},
    }
  );
  const listRoles =
    data?.listRoles?.data !== null ? data?.listRoles?.data : null;
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  let tableBody;

  tableBody = (
    <StyledGrid
      container
      item
      xs={12}
      justifyContent="center"
      className={classes.mainContainer}
    >
      {/* *******Table******* */}
      <Paper className={clsx(classes.paper)}>
        <Toolbar className={clsx(classes.toolbar)} variant="dense">
          <Typography className={classes.title} color="inherit" variant="h6">
            {t("roles")}
          </Typography>

          <SecuredNavLink
            permission="core.role.create"
            to={{ pathname: `/admin/roles/create` }}
          >
            <Tooltip title="Add role">
              <IconButton aria-label="Add role" size="large">
                <Add color="primary" />
              </IconButton>
            </Tooltip>
          </SecuredNavLink>
        </Toolbar>

        {loading || listRoles?.length === 0 ? (
          <Grid container item justifyContent="center" className={classes.main}>
            {loading ? (
              <FullScreenLoading minHeight={30} />
            ) : (
              <Box textAlign="center">
                <img width="50%" src={emptyList} alt={"delivery box"} />
                <Box
                  component={Typography}
                  variant="h6"
                  color="text.secondary"
                  marginTop="0"
                >
                  {t("searchNoResult")}
                </Box>
              </Box>
            )}
          </Grid>
        ) : (
          <TableFixedHeaderWraper>
            <Table aria-labelledby="tableTitle" aria-label="enhanced table">
              <TableHead>
                <TableRow>
                  <FixedTableCell>{t("name")}</FixedTableCell>
                  <FixedTableCell>{t("code")}</FixedTableCell>
                  <FixedTableCell>{t("users")}</FixedTableCell>
                  <FixedTableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {listRoles &&
                  listRoles?.map((row, index) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                        <CellLink pathname={`/admin/roles/${row?.id}`}>
                          {row?.name}
                        </CellLink>
                        <FixedTableCell>{row?.code}</FixedTableCell>
                        <FixedTableCell>{row?.usersCount}</FixedTableCell>
                        <FixedTableCell>
                          <SecuredNavLink
                            permission="core.role.create"
                            to={{
                              pathname: `roles/${row.id}/copy`,
                            }}
                          >
                            <Tooltip title="copy role">
                              <IconButton aria-label="copy role" size="large">
                                <Icon>content_copy</Icon>
                              </IconButton>
                            </Tooltip>
                          </SecuredNavLink>
                        </FixedTableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableFixedHeaderWraper>
        )}
        <MUITablePagination
          count={data?.listRoles?.paginatorInfo?.total}
          rowsPerPage={rowsPerPage}
          page={!data?.listRoles ? 0 : page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </StyledGrid>
  );
  return tableBody;
};

export default ListRoles;
