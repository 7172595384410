import { gql, useQuery } from "@apollo/client";
import { Notifications, Message } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { Globals } from "../../Component/HOC/Classes/Globals";
// import { NOTIFICATION_DATE } from "./DashBoardGraphql";
import NotificationBadge from "./NotificationBadge";
import NotificationBody from "./NotificationBody";
import * as gqlb from "gql-query-builder";
import MessagesBody from "./MessagesBody";
import { Can } from "../../Component/HOC/CustomComponents/Secured";

const NotificationContainer = (props) => {
  const user = Globals.user;

  const hasMessagesPermission = user.hasPermission(
    "shipping.shipment_message.list"
  );
  const customerRole = user.role.code === "CSTMR";

  const [shipmentsNotification, setShipmentsNotification] = useState({
    status: !customerRole,
    messages: !hasMessagesPermission,
  });
  const getNotification = () => {
    return Boolean(
      !shipmentsNotification["status"] || !shipmentsNotification["messages"]
    );
  };

  const handelNotification = (name, state) => {
    setShipmentsNotification((prev) => ({
      ...prev,
      [name]: state,
    }));
  };
  useEffect(() => {
    startPolling(10000);
    const docHidden = document.addEventListener("visibilitychange", () => {
      if (document.hidden) {
        stopPolling();
      }
      if (getNotification() && !document.hidden) {
        startPolling(10000);
      }
    });
    return () => {
      stopPolling();
      document.removeEventListener("visibilitychange", docHidden);
    };
  }, []);

  const haveNotificationAccess = hasMessagesPermission || customerRole;

  const queryBody = [];

  if (
    !shipmentsNotification["messages"] ||
    (shipmentsNotification["status"] && shipmentsNotification["messages"])
  ) {
    let messagesQuery = {
      operation: "listMessages",
      fields: [
        {
          operation: "data",
          fields: ["createdAt"],
          variables: {},
        },
      ],
      variables: {
        first: {
          type: "Int",
        },
        page: {
          type: "Int",
        },
        receivedOnly: {
          type: "Boolean",
        },
      },
    };
    queryBody.push(messagesQuery);
  }
  if (!shipmentsNotification["status"] && customerRole) {
    let notificationQuery = {
      operation: "listNotifications",
      fields: [
        {
          operation: "data",
          fields: ["createdAt"],
          variables: {},
        },
      ],
      variables: {
        first: {
          type: "Int",
        },
        page: {
          type: "Int",
        },
      },
    };
    queryBody.push(notificationQuery);
  }

  const NOTIFICATION_DATE = gqlb.query(queryBody);

  const onCompleteNotifications = ({
    data,
    quaryName,
    stateName,
    nameOnStorage,
    userId,
  }) => {
    if (data[quaryName]?.data?.length > 0) {
      const newNotificationDate = data?.[quaryName]?.data[0]?.createdAt;
      const firstOpen = localStorage.getItem("firstOpen");
      const storageDate = localStorage.getItem(nameOnStorage) || firstOpen;
      const isNotificationUnread =
        new Date(firstOpen) < new Date(newNotificationDate) &&
        new Date(storageDate) < new Date(newNotificationDate);
      if (isNotificationUnread) {
        stopPolling();
      }
      if (isNotificationUnread && shipmentsNotification[stateName] === false) {
        handelNotification(stateName, true);
      }
    }
  };

  const { startPolling, stopPolling } = useQuery(
    gql`
      ${NOTIFICATION_DATE.query}
    `,
    {
      variables: {
        first: 1,
        receivedOnly: true,
      },
      skip:
        shipmentsNotification["status"] && shipmentsNotification["messages"],
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      // nextFetchPolicy: "network-only",
      pollInterval: 10000,
      onCompleted: (data) => {
        onCompleteNotifications({
          data,
          quaryName: "listNotifications",
          stateName: "status",
          nameOnStorage: "notifications",
          userId: user.account?.id,
        });
        onCompleteNotifications({
          data,
          quaryName: "listMessages",
          stateName: "messages",
          nameOnStorage: "messagesNotifications",
          userId: user?.id,
        });
      },
      onError: ({ networkError }) => {
        console.log(networkError);
      },
    }
  );

  return haveNotificationAccess ? (
    <>
      <Can permission={customerRole} showException>
        <NotificationBadge
          startPolling={startPolling}
          stopPolling={stopPolling}
          hasNotification={shipmentsNotification["status"]}
          resetNotification={() => handelNotification("status", false)}
          icon={Notifications}
          body={NotificationBody}
        />
      </Can>
      <Can permission={hasMessagesPermission} showException>
        <NotificationBadge
          startPolling={startPolling}
          stopPolling={stopPolling}
          hasNotification={shipmentsNotification["messages"]}
          resetNotification={() => handelNotification("messages", false)}
          icon={Message}
          body={MessagesBody}
        />
      </Can>
    </>
  ) : null;
};
export default NotificationContainer;
