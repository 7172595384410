import * as gqlb from "gql-query-builder";

export const LIST_REGISTRATIONS = gqlb.query({
  operation: "listRegistrations",
  fields: [
    {
      operation: "paginatorInfo",
      fields: [
        "count",
        "currentPage",
        "firstItem",
        "hasMorePages",
        "lastItem",
        "lastPage",
        "perPage",
        "total",
      ],
      variables: {},
    },
    {
      operation: "data",
      fields: [
        "id",
        "businessName",
        "name",
        "address",
        "email",
        "mobile",
        "verified",
        "emailVerified",
        "createdAt",
        { zone: ["id", "name"] },
        { subzone: ["name"] },
        { user: ["id", "username"] },
      ],
      variables: {},
    },
  ],
  variables: {
    input: {
      type: "ListRegistrationsFilterInput",
    },
    page: {
      type: "Int",
    },
    first: {
      type: "Int",
    },
  },
});

export const APPROVE_REGISTRATION = gqlb.mutation({
  operation: "approveRegistration",
  fields: [
    "id",
    "name",
    "address",
    "email",
    "mobile",
    { zone: ["name"] },
    { subzone: ["name"] },
  ],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
