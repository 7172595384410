import * as gqlb from "gql-query-builder";
export const LIST_COMMISSION_LISTS = gqlb.query({
  operation: "listCommissionLists",
  fields: [
    {
      operation: "paginatorInfo",
      fields: [
        "count",
        "currentPage",
        "firstItem",
        "hasMorePages",
        "lastItem",
        "lastPage",
        "perPage",
        "total",
      ],
      variables: {},
    },
    {
      operation: "data",
      fields: [
        "id",
        "name",
        "code",
        "description",

        {
          destinations: [
            "id",
            "commission",
            "active",
            {
              zone: ["id", "name"],
            },
            {
              subzone: ["id", "name"],
            },
            {
              commissionType: ["code", "name"],
            },
          ],
        },
      ],
      variables: {},
    },
  ],
  variables: {
    page: {
      type: "Int",
    },
    first: {
      type: "Int",
    },
  },
});

export const SAVE_COMMISSION_LIST = gqlb.mutation({
  operation: "saveCommissionList",
  fields: ["id", "code","name"],
  variables: {
    input: {
      type: "CommissionListInput",
      required: true,
    },
  },
});

export const COMMISSION_LIST_ID = gqlb.query({
  operation: "commissionList",
  fields: [
    "id",
    "name",
    "code",
    "description",

    {
      destinations: [
        "id",
        "commission",
        "active",
        {
          zone: ["id", "name"],
        },
        {
          subzone: ["id", "name"],
        },
        {
          commissionType: ["code", "name"],
        },
      ],
    },
  ],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});


