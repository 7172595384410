import React, { useState } from "react";
import { styled } from '@mui/material/styles';
import { useQuery, gql } from "@apollo/client";
import {
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
  Toolbar,
  Box,
  Table,
  TableBody,
  TableRow,
  TableHead,
  Icon,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import clsx from "clsx";
import { SecuredNavLink } from "../HOC/CustomComponents/Secured";
// import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { useTranslation } from "react-i18next";
import emptyList from "../../assets/Image/received.png";
import CellLink from "../HOC/CustomComponents/CellLink";
import { LIST_ROUTES_PAGED } from "./Graphql";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";
const PREFIX = 'ListRoutes';

const classes = {
  appBar: `${PREFIX}-appBar`,
  title: `${PREFIX}-title`,
  paper: `${PREFIX}-paper`,
  main: `${PREFIX}-main`,
  mainContainer: `${PREFIX}-mainContainer`,
  span: `${PREFIX}-span`,
  iconColor: `${PREFIX}-iconColor`
};

const StyledGrid = styled(Grid)((
  {
    theme
  }
) => ({
  [`& .${classes.appBar}`]: {
    position: "relative",
  },

  [`& .${classes.title}`]: {
    flex: 1,
  },

  [`& .${classes.paper}`]: {
    display: "grid",
    width: "100%",
    borderRadius: 0,
  },

  [`& .${classes.main}`]: {
    marginTop: theme.spacing(2),
  },

  [`&.${classes.mainContainer}`]: {
    width: "100%",
  },

  [`& .${classes.span}`]: {
    margin: "5px",
  },

  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  }
}));

const ListRoutes = (props) => {
  const { t } = useTranslation();

  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);
  const { data, loading } = useQuery(
    gql`
      ${LIST_ROUTES_PAGED.query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: {
        first: rowsPerPage,
        page: page + 1,
      },
      onCompleted: (data) => {},
    }
  );
  const listRoutes = data?.listRoutes?.data;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  let tableBody;
  tableBody = (
    <StyledGrid
      container
      item
      xs={12}
      justifyContent="center"
      className={classes.mainContainer}
    >
      {/* *******Table******* */}
      <Paper className={clsx(classes.paper)}>
        <Toolbar className={clsx(classes.toolbar)} variant="dense">
          <Typography className={classes.title} color="inherit" variant="h6">
            {t("deliveryAgentRoutes")}
          </Typography>

          <SecuredNavLink
            permission="shipping.route.create"
            to={{ pathname: `/admin/routes/create` }}
          >
            <Tooltip title="Add Route">
              <IconButton aria-label="Add Route" size="large">
                <Add color="primary" />
              </IconButton>
            </Tooltip>
          </SecuredNavLink>
        </Toolbar>

        {loading || listRoutes?.length === 0 ? (
          <Grid container item justifyContent="center" className={classes.main}>
            {loading ? (
              <FullScreenLoading minHeight={30} />
            ) : (
              <Box textAlign="center">
                <img width="50%" src={emptyList} alt={"delivery box"} />
                <Box
                  component={Typography}
                  variant="h6"
                  color="text.secondary"
                  marginTop="0"
                >
                  {t("searchNoResult")}
                </Box>
              </Box>
            )}
          </Grid>
        ) : (
          <TableFixedHeaderWraper>
            <Table aria-labelledby="tableTitle" aria-label="enhanced table">
              <TableHead>
                <TableRow>
                  <FixedTableCell>{t("name")}</FixedTableCell>
                  <FixedTableCell>{t("code")}</FixedTableCell>
                  <FixedTableCell>{t("car")}</FixedTableCell>
                  <FixedTableCell>{t("driver")}</FixedTableCell>
                  <FixedTableCell>{t("active")}</FixedTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {listRoutes &&
                  listRoutes?.map((row, index) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                        <CellLink pathname={`/admin/routes/${row?.id}`}>
                          {row?.name}
                        </CellLink>

                        <FixedTableCell>{row?.code}</FixedTableCell>
                        <FixedTableCell
                          sx={{ display: "flex", alignItems: "center" }}
                        >
                          <span className={classes.span}>
                            {row?.vehicle?.series?.model?.make?.name}
                          </span>
                          {row?.vehicle?.series?.model?.make?.name ? (
                            <span>-</span>
                          ) : (
                            ""
                          )}
                          <span className={classes.span}>
                            {row?.vehicle?.series?.model?.name}
                          </span>
                          {row?.vehicle?.series?.name ? <span>-</span> : ""}
                          <span className={classes.span}>
                            {row?.vehicle?.series?.name}
                          </span>
                          {row?.vehicle?.plateNumber ? <span>(</span> : ""}
                          <span className={classes.span}>
                            {row?.vehicle?.plateNumber}
                          </span>
                          {row?.vehicle?.plateNumber ? <span>)</span> : ""}
                        </FixedTableCell>
                        <FixedTableCell>{row?.driver?.name}</FixedTableCell>
                        <FixedTableCell>
                          {row?.active ? (
                            <Icon className={classes.iconColor}>
                              check_circle_outline
                            </Icon>
                          ) : (
                            <Icon color="error">highlight_off</Icon>
                          )}
                        </FixedTableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableFixedHeaderWraper>
        )}
        <MUITablePagination
          count={data?.listRoutes?.paginatorInfo?.total}
          rowsPerPage={rowsPerPage}
          page={!data?.listRoutes ? 0 : page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </StyledGrid>
  );
  return tableBody;
};

export default ListRoutes;
