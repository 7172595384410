/* eslint-disable no-useless-concat */
import React, { Fragment } from "react";
import { styled } from '@mui/material/styles';
import { gql, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { SecuredNavLink } from "../HOC/CustomComponents/Secured";
import { Typography, Paper, Toolbar, IconButton, Icon } from "@mui/material";
import { Edit } from "@mui/icons-material";

import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { DELIVERY_AGENT_ID } from "./Graphql";
import { KeyValuePair } from "../HOC/CustomComponents/KeyValuePair";
import { Globals } from "../HOC/Classes/Globals";
import SpanLink from "../HOC/CustomComponents/SpanLink";
import Grid from "@mui/material/Unstable_Grid2";

const PREFIX = 'DeliveryAgentView';

const classes = {
  loading: `${PREFIX}-loading`,
  box: `${PREFIX}-box`,
  toolbar: `${PREFIX}-toolbar`,
  toolbarIcons: `${PREFIX}-toolbarIcons`,
  iconButton: `${PREFIX}-iconButton`,
  main: `${PREFIX}-main`,
  field: `${PREFIX}-field`,
  iconColor: `${PREFIX}-iconColor`,
  span: `${PREFIX}-span`,
};

const StyledGrid = styled(Grid)((
  {
    theme
  }
) => ({
  [`& .${classes.loading}`]: {},

  [`& .${classes.box}`]: {
    padding: theme.spacing(3),
    flexGrow: 1,
  },

  [`& .${classes.toolbar}`]: {
    borderBottom: "1px #ccd1d6 solid",
    backgroundColor: "#f5f7f9",
    width: "100%",
  },

  [`& .${classes.toolbarIcons}`]: {
    flex: "1 1 100%",
    textAlign: "end",
  },

  [`& .${classes.iconButton}`]: {
    borderRadius: "0 4px 4px 0 ",
    boxShadow: "none",
    height: 45,
  },

  [`& .${classes.main}`]: {
    margin: 0,
    width: "100%",
  },

  [`& .${classes.field}`]: {
    width: 260,
    [theme.breakpoints.down("sm")]: {
      width: 170,
    },
  },

  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },
  [`& .${classes.span}`]: {
    display: "inline"
  }
}));

const DeliveryAgentView = (props) => {

  const DeliveryAgentId = props.match.params.id?.trim();
  const { t } = useTranslation();
  const { loading, data } = useQuery(
    gql`
      ${DELIVERY_AGENT_ID.query}
    `,
    {
      fetchPolicy: "network-only",
      variables: { id: parseInt(DeliveryAgentId) },
    }
  );

  const user = Globals.user;
  const branchId = data?.deliveryAgent?.branch?.id;
  const canAccessBranch = branchId ? user.canAccessBranch(branchId) : true;

  const canEdit =
    user.hasPermission("shipping.delivery_agent.update") && canAccessBranch;

  return (
    <StyledGrid
      container
      justifyContent="center"
      className={classes.mainTracking}
      sx={{ margin: "0", width: "100%" }}
    >
      {loading ? (
        <FullScreenLoading minHeight="25%" />
      ) : (
        <>
          <Fragment>
            <Toolbar className={clsx(classes.toolbar)} variant="regular">
              <Grid
                container
                sx={{ flexWrap: "initial", flexGrow: 1 }}
                alignItems="center"
              >
                <Grid className={classes.field}>
                  <Typography variant="h6">
                    {t(`deliveryAgentDetails`)}
                  </Typography>
                </Grid>
                <Grid className={classes.toolbarIcons}>
                  <SecuredNavLink
                    permission={canEdit}
                    to={{
                      pathname: `/admin/delivery-agents/${DeliveryAgentId}/edit`,
                    }}
                  >
                    <IconButton color={"primary"} size="large">
                      <Edit fontSize="inherit" />
                    </IconButton>
                  </SecuredNavLink>
                </Grid>
              </Grid>
            </Toolbar>
          </Fragment>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{ width: "100%", p: 2, gap: 2 }}
          >
            <Paper container className={classes.box} component={Grid}>
              <KeyValuePair
                title={t("code")}
                value={data?.deliveryAgent?.code}
              />
              <KeyValuePair
                title={t("name")}
                value={data?.deliveryAgent?.name}
              />
              <KeyValuePair
                title={t("active")}
                value={
                  data?.deliveryAgent?.active ? (
                    <Icon className={classes.iconColor}>
                      check_circle_outline
                    </Icon>
                  ) : (
                    <Icon color="error">highlight_off</Icon>
                  )
                }
              />
              <KeyValuePair
                title={t("autoPayCommission")}
                value={
                  data?.deliveryAgent?.autoPayCommission ? (
                    <Icon className={classes.iconColor}>
                      check_circle_outline
                    </Icon>
                  ) : (
                    <Icon color="error">highlight_off</Icon>
                  )
                }
              />
              <KeyValuePair
                title={t("branch")}
                value={
                  data?.deliveryAgent?.branch?.name && (
                    <SpanLink
                      pathname={`/admin/branches/${data?.deliveryAgent?.branch?.id}`}
                    >
                      {data?.deliveryAgent?.branch?.name}
                    </SpanLink>
                  )
                }
              />
              <KeyValuePair
                title={t("glAccount")}
                value={data?.deliveryAgent?.glAccount?.name}
              />
              <KeyValuePair
                title={t("custodyAccount")}
                value={data?.deliveryAgent?.custodyAccount?.name}
              />
              <KeyValuePair
                title={t("route")}
                value={
                  data?.deliveryAgent?.route?.name && (
                    <SpanLink
                      pathname={`/admin/routes/${data?.deliveryAgent?.route?.id}`}
                    >
                      {data?.deliveryAgent?.route?.name}
                    </SpanLink>
                  )
                }
              />
              <KeyValuePair
                title={t("commissionType")}
                value={data?.deliveryAgent?.commissionType?.name}
              />
              <KeyValuePair
                title={t("commission")}
                value={data?.deliveryAgent?.commission}
              />
              <KeyValuePair
                title={t("commissionAccount")}
                value={
                  data?.deliveryAgent?.commissionList?.name && (
                    <SpanLink
                      pathname={`/admin/commission-list/${data?.deliveryAgent?.commissionList?.id}`}
                    >
                      {data?.deliveryAgent?.commissionList?.name}
                    </SpanLink>
                  )
                }
              />
              <KeyValuePair
                title={t("users")}
                value={
                  data?.deliveryAgent?.users && (
                    data?.deliveryAgent?.users.map((user, index) =>
                      <SpanLink className={classes.span}
                        pathname={`/admin/users/${user.id}`}
                      >
                        {user.username} {!(data?.deliveryAgent?.users.length - 1 === index) && ' , '}
                      </SpanLink>
                    )
                  )
                }
              />
            </Paper>
            <Paper container className={classes.box} component={Grid}>
              <KeyValuePair
                title={t("country")}
                value={data?.deliveryAgent?.country?.name}
              />
              <KeyValuePair
                title={t("state")}
                value={data?.deliveryAgent?.state?.name}
              />
              <KeyValuePair
                title={t("city")}
                value={data?.deliveryAgent?.city?.name}
              />
              <KeyValuePair
                title={t("subzone")}
                value={data?.deliveryAgent?.area?.name}
              />
              <KeyValuePair
                title={t("address")}
                value={data?.deliveryAgent?.address}
              />
              <KeyValuePair
                title={t("phone")}
                value={data?.deliveryAgent?.phone}
              />
              <KeyValuePair
                title={t("mobile")}
                value={data?.deliveryAgent?.mobile}
              />
              <KeyValuePair
                title={t("mailBox")}
                value={data?.deliveryAgent?.mailBox}
              />
              <KeyValuePair
                title={t("postalCode")}
                value={data?.deliveryAgent?.postalCode}
              />
              <KeyValuePair
                title={t("email")}
                value={data?.deliveryAgent?.email}
              />
            </Paper>
          </Grid>
        </>
      )}
    </StyledGrid>
  );
};

export default DeliveryAgentView;
