import React, { useEffect } from "react";
// import "./ShipmentPrint.css";
import { gql, useQuery } from "@apollo/client";
import * as gqlb from "gql-query-builder";
import { withRouter } from "react-router";
import { useTranslation } from "react-i18next";

import { urlParameters } from "../HOC/CustomFunctions/urlParameters";
// import "./ShipmentPrint.css";
import logo from "../../assets/Image/logo.png";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import clsx from "clsx";

// import { Box, Typography } from "@mui/material";
import { Root, classes } from "./ShipmentPrintStyle";
// import CheckCrossIcons from "../HOC/FunctionComponents/CheckCrossIcons";
import WaybillA4 from "./Waybill/WaybillA4";
const organizationQuery = {
  operation: "organization",
  fields: ["reportHeader"],
  variables: {},
};
const listShipmentFields = [
  {
    data: [
      "code",
      "recipientName",
      "recipientAddress",
      "recipientMobile",
      "recipientPhone",
      "description",
      "weight",
      "piecesCount",
      "totalAmount",
      "senderName",
      "senderPhone",
      "senderMobile",
      "amount",
      "refNumber",
      "allDueFees",
      "notes",
      "date",
      {
        type: ["name", "code"],
      },
      {
        originBranch: ["name"],
      },
      {
        customer: ["code", "hideWaybillMobile"],
      },
      {
        openable: ["code"],
      },

      {
        recipientZone: ["name"],
      },
      {
        recipientSubzone: ["name"],
      },
      {
        senderZone: ["name"],
      },
      {
        senderSubzone: ["name"],
      },
    ],
  },
];
const barcodeBatch = [
  "id",
  {
    barcodes: [
      "id",
      "code",
      {
        customer: ["code", "hideWaybillMobile"],
      },
    ],
  },
];
export const SHIPPING_SETTING = gqlb.query({
  operation: "shippingSettings",
  fields: [{ localCurrency: ["name"] }],
  variables: {},
});

const queryBuilder = (ids) => {
  const queryNumber = Math.ceil(ids.length / 100);
  const queryArray = [organizationQuery];
  for (let index = 0; index < queryNumber; index++) {
    const id = ids.slice(100 * index, 100 * (index + 1));
    const initialQuery = {
      operation: `list${index}:listShipments(first:100,input:{id:[${id}]})`,
      fields: listShipmentFields,
      variables: {},
    };
    queryArray.push(initialQuery);
  }
  return queryArray;
};

const queryBuilderCode = (ids) => {
  const queryArray = [organizationQuery];
  const initialQuery = {
    operation: `listCode:barcodeBatch(id:${ids[0]})`,
    fields: barcodeBatch,
    variables: {},
  };
  queryArray.push(initialQuery);
  return queryArray;
};

const ShipmentPrint = (props) => {
  const id = props.match.params.id.split(",").map(Number);
  const isBatch = props.location.pathname.startsWith("/batch");
  const threeCopies = props.match.params.size === "3c";
  const twoCopies = props.match.params.size === "2c";
  const threeDifferent = props.match.params.size === "3d";
  const oneCopy = props.match.params.size === "1c";
  const urlQuery = urlParameters(window.location.search);
  const { t, i18n } = useTranslation();

  const queryBody = isBatch ? queryBuilderCode(id) : queryBuilder(id);

  const SHIPMENT_QUERY = gqlb.query(queryBody);

  const token = urlQuery["token"];
  const lang = urlQuery["lang"];

  const { data: settings, loading: settingsLoad } = useQuery(
    gql`
      ${SHIPPING_SETTING.query}
    `,
    {
      nextFetchPolicy: "no-cache",
      fetchPolicy: "no-cache",
      ...(token && {
        context: {
          headers: {
            authorization: `Bearer ${token}`,
            ...(lang && { "Content-Language": lang }),
          },
        },
      }),
    }
  );

  const currency = ` ${settings?.shippingSettings?.localCurrency?.name} ` ?? "";

  useEffect(() => {
    if (lang) {
      i18n.changeLanguage(lang);
    }
    // if (settings) {
    //   const hideSenderPhoneState =
    //     settings?.shippingSettings?.hideWaybillSenderPhone;
    //   setHideSenderPhone(hideSenderPhoneState);
    // }
    return () => {};
  }, [i18n, lang, settings]);

  const { data, loading } = useQuery(
    gql`
      ${SHIPMENT_QUERY.query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      skip: !id,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      ...(token && {
        context: {
          headers: {
            authorization: `Bearer ${token}`,
            ...(lang && { "Content-Language": lang }),
          },
        },
      }),
      onCompleted: (data) => {
        document.fonts.onloadingdone = function (fontFaceSetEvent) {
          window.print();
          document.fonts.onloadingdone = null;
        };
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  let parsedData = [];
  if (data?.list0) {
    let concatData = [];
    for (const key in data) {
      if (key.startsWith("list")) {
        const listData = data[key].data;
        concatData = concatData.concat(listData);
      }
    }
    parsedData = concatData;
  }
  if (data?.listCode) {
    let concatData = [];
    for (const key in data) {
      if (key.startsWith("list")) {
        const listData = data[key].barcodes;
        concatData = concatData.concat(listData);
      }
    }
    parsedData = concatData;
  }
  let shipmentsArray = [];
  if (parsedData.length > 0) {
    parsedData.forEach((shipment, i) => {
      if (oneCopy) {
        const copyNumber = 1;
        let smallShipment = [];
        for (let i = 0; i < copyNumber; i++) {
          smallShipment.push(shipment);
        }
        shipmentsArray.push(smallShipment);
      } else if (threeCopies || twoCopies) {
        const copyNumber = twoCopies ? 2 : 3;
        let smallShipment = [];
        for (let i = 0; i < copyNumber; i++) {
          smallShipment.push(shipment);
        }
        shipmentsArray.push(smallShipment);
      } else {
        const differentNumber = threeDifferent ? 3 : 2;
        const index = shipmentsArray.findIndex(
          (i) => i.length < differentNumber
        );
        if (index !== -1) {
          shipmentsArray[index].push(shipment);
        } else {
          shipmentsArray.push([shipment]);
        }
      }
    });
  }

  const logoImg = <img src={logo} alt="logo" className={classes.logoImg} />;

  return (
    <Root>
      <meta name="viewport" content="width=no, user-scalable=no" />
      <link
        rel="stylesheet"
        type="text/css"
        href={process.env.PUBLIC_URL + "/print-portrait.css"}
      />
      {loading ? (
        <>
          <div style={{ visibility: "hidden", height: 0 }}>{logoImg}</div>
          <FullScreenLoading minHeight="30%" />
        </>
      ) : (
        shipmentsArray.map((page, index) => {
          return (
            <div key={index} className={clsx(classes.shipmentInvoiceContainer)}>
              {page.map((shipment, shipmentIndex) => {
                const rtsShipment = shipment?.type?.code === "RTS";
                console.log(shipment);

                return (
                  <WaybillA4
                    shipment={shipment}
                    classes={classes}
                    shipmentIndex={shipmentIndex}
                    threeCopies={threeCopies}
                    threeDifferent={threeDifferent}
                    oneCopy={oneCopy}
                    logoImg={logoImg}
                    data={data}
                    currency={currency}
                    rtsShipment={rtsShipment}
                    isBatch={isBatch}
                  />
                );
              })}
            </div>
          );
        })
      )}
    </Root>
  );
};

export default withRouter(ShipmentPrint);
