import React, { memo } from "react";

import { gql, useQuery } from "@apollo/client";
import Grid from "@mui/material/Grid";
import PieChart, {
  Animation,
  Connector,
  Font,
  Label,
  Legend,
  Series,
} from "devextreme-react/pie-chart";
import * as gqlb from "gql-query-builder";
import { useTranslation } from "react-i18next";
import CustomSpinner from "../HOC/FunctionComponents/CustomSpinner";
import TooltipTemplate from "./CenterTemplate";
import useWidth from "../../Hooks/useWidth";
import StatisticsFilterHeader from "./StatisticsFilterHeader";
import { ImStatsBars } from "react-icons/im";
import { useState } from "react";
import Card from "@mui/material/Card";
import { styled } from "@mui/material/styles";


export const STATISTICS = gqlb.query([
  {
    operation: "pendingPickup:listShipments",
    fields: [
      {
        operation: "paginatorInfo",
        fields: ["total"],
        variables: {},
      },
    ],
    variables: {
      first: {
        type: "Int",
      },
      pendingPickupInput: {
        name: "input",
        type: "ListShipmentsFilterInput",
      },
    },
  },
  {
    operation: "inWarehouse:listShipments",
    fields: [
      {
        operation: "paginatorInfo",
        fields: ["total"],
        variables: {},
      },
    ],
    variables: {
      first: {
        type: "Int",
      },
      inWarehouseInput: {
        name: "input",
        type: "ListShipmentsFilterInput",
      },
    },
  },
  {
    operation: "delivered:listShipments",
    fields: [
      {
        operation: "paginatorInfo",
        fields: ["total"],
        variables: {},
      },
    ],
    variables: {
      first: {
        type: "Int",
      },
      deliveredInput: {
        name: "input",
        type: "ListShipmentsFilterInput",
      },
    },
  },
  {
    operation: "deliveryInProgress:listShipments",
    fields: [
      {
        operation: "paginatorInfo",
        fields: ["total"],
        variables: {},
      },
    ],
    variables: {
      first: {
        type: "Int",
      },
      deliveryInProgressInput: {
        name: "input",
        type: "ListShipmentsFilterInput",
      },
    },
  },
  {
    operation: "notDelivered:listShipments",
    fields: [
      {
        operation: "paginatorInfo",
        fields: ["total"],
        variables: {},
      },
    ],
    variables: {
      first: {
        type: "Int",
      },
      notDeliveredInput: {
        name: "input",
        type: "ListShipmentsFilterInput",
      },
    },
  },
  {
    operation: "return:listShipments",
    fields: [
      {
        operation: "paginatorInfo",
        fields: ["total"],
        variables: {},
      },
    ],
    variables: {
      first: {
        type: "Int",
      },
      returnInput: {
        name: "input",
        type: "ListShipmentsFilterInput",
      },
    },
  },
]);

const StyledCard = styled(Card)(({ theme, ...props }) => ({
  width: "100%",
  minHeight: "150px"
}));

const PieCharts = (props) => {
  const { dateRange, inputs, onDateRangeAccept, handleDateRange, onFocus, classNamePieChart } = props;
  const { t } = useTranslation();

  const { data, loading } = useQuery(
    gql`
      ${STATISTICS.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: {
        first: 1,
        pendingPickupInput: {
          statusCode: ["PKR", "PKM", "PKH", "RJCT"],
          ...(dateRange && {
            ...dateRange,
          }),
          ...(inputs && { ...inputs }),
        },
        deliveredInput: {
          statusCode: "DTR",
          ...(dateRange && {
            ...dateRange,
          }),
          ...(inputs && { ...inputs }),
        },
        inWarehouseInput: {
          statusCode: ["PKD", "RITS", "BMT"],
          ...(dateRange && {
            ...dateRange,
          }),
          ...(inputs && { ...inputs })
        },
        deliveryInProgressInput: {
          statusCode: "OTD",
          ...(dateRange && {
            ...dateRange,
          }),
          ...(inputs && { ...inputs }),
        },
        notDeliveredInput: {
          statusCode: ["HTR", "DEX"],
          ...(dateRange && {
            ...dateRange,
          }),
          ...(inputs && { ...inputs }),
        },
        returnInput: {
          statusCode: ["RTS", "OTR", "RTRN"],
          ...(dateRange && {
            ...dateRange,
          }),
          ...(inputs && { ...inputs })
        },
      },
    }
  );
  const dataSource = [
    { lable: t("pendingPickup"), total: data?.pendingPickup?.paginatorInfo?.total, filterType: ["PKR", "PKM", "PKH", "RJCT"] },
    { lable: t("notDelivered"), total: data?.notDelivered?.paginatorInfo?.total, filterType: ["HTR", "DEX"] },
    { lable: t("delivered"), total: data?.delivered?.paginatorInfo?.total, filterType: "DTR" },
    { lable: t("returning"), total: data?.return?.paginatorInfo?.total, filterType: ["RTS", "OTR", "BMT", "RTRN"], delivered: false },
    { lable: t("inWarehouse"), total: data?.inWarehouse?.paginatorInfo?.total, filterType: ["PKD", "RITS", "BMT"], delivered: true },
    { lable: t("deliveryInProgress"), total: data?.deliveryInProgress?.paginatorInfo?.total, filterType: "OTD" },
  ];
  const screenWidth = useWidth();
  const [changePieChart, setChangePieChart] = useState(true)
  const legendClickHandler = (e) => {
    setChangePieChart(false)
    onFocus(e.points[0].data.filterType, e.points[0].data?.delivered)
  }
  const pointClickHandler = (e) => {
    setChangePieChart(false)
    onFocus(e.target.data.filterType, e.target.data?.delivered)
  }
  const width = { ...(screenWidth === "xs" && { width: 320 }) };
  let Dom = null;
  Dom = loading ? (
    <CustomSpinner name="PulseLoader" size={12} margin={2} />
  ) : (
    <PieChart
      id="pie"
      type="donut"
      palette={"Material"}
      dataSource={dataSource}
      resolveLabelOverlapping="shift"
      sizeGroup="piesGroup"
      innerRadius={0.65}
      centerRender={TooltipTemplate(t("total"))}
      size={width}
      onLegendClick={legendClickHandler}
      onPointClick={pointClickHandler}
      className={classNamePieChart}
    >
      {/* <Size width="30px" /> */}
      <Legend
        orientation="horizontal"
        itemTextPosition="right"
        horizontalAlignment="center"
        verticalAlignment="bottom"
        rowCount={2}
      // columnCount={4}
      >
        <Font family="Cairo" />
      </Legend>
      <Series argumentField="lable" valueField="total">
        <Label visible={true} position="columns" customizeText={customizeText}>
          <Font family="Cairo" size={16} />
          <Connector visible={true} width={0.5} />
        </Label>
      </Series>
      <Animation enabled={changePieChart} />
    </PieChart>
  );
  return (
    <StyledCard>
      <Grid
        container
        sx={{ height: "100%" }}
        alignItems="flex-start"
        justifyContent="center"
      >
        <Grid xs={12} item>
          <StatisticsFilterHeader
            section={'pieChart'}
            onDateRangeAccept={onDateRangeAccept}
            handleDateRange={handleDateRange}
            title={t("shipmentStatistics")}
            icon={ImStatsBars}
          />
        </Grid>
        <Grid item>{Dom}</Grid>
      </Grid>
    </StyledCard>
  );
};

function customizeText(arg) {
  return `${arg.valueText} (${arg.percentText})`;
}

export default memo(PieCharts);
