import React, { Fragment, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import {
  AppBar,
  Box,
  Button,
  Dialog,
  Drawer,
  Grid,
  Icon,
  IconButton,
  Paper,
  Slide,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

import {
  Add,
  Edit,
  Close,
  Search,
  Clear,
  FilterList,
} from "@mui/icons-material";
import Collapse from "@mui/material/Collapse";
import { useSpring, animated } from "react-spring"; // web.cjs is required for IE 11 support
import { useQuery, gql } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { LIST_GL_ACCOUNTS } from "./Graphql";
import CreateGlAccount from "./CreateGlAccount";
import { urlParameters } from "../HOC/CustomFunctions/urlParameters";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import clsx from "clsx";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import CellLink from "../HOC/CustomComponents/CellLink";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import {
  LIST_BRANCHES_DROPDOWN,
  LIST_GL_ACCOUNTS_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import EmptyTableMessage from "../HOC/FunctionComponents/EmptyTableMessage";
import LookupDropdown from "../HOC/CustomComponents/LookupDropdown";
import { lookupCodeIsValid } from "../HOC/CustomFunctions/lookupCodeIsValid";
import { windowUrl } from "../HOC/CustomFunctions/pushUrl";

const PREFIX = "GlAccountsTree";

const classes = {
  root: `${PREFIX}-root`,
  toolbarIcons: `${PREFIX}-toolbarIcons`,
  iconColor: `${PREFIX}-iconColor`,
  editIcon: `${PREFIX}-editIcon`,
  flex: `${PREFIX}-flex`,
  button: `${PREFIX}-button`,
  mainSearch: `${PREFIX}-mainSearch`,
  toolbar: `${PREFIX}-toolbar`,
  searchForm: `${PREFIX}-searchForm`,
  searchField: `${PREFIX}-searchField`,
  searchPadding: `${PREFIX}-searchPadding`,
  drawer: `${PREFIX}-drawer`,
  drawerPaper: `${PREFIX}-drawerPaper`,
  drawerHeader: `${PREFIX}-drawerHeader`,
  content: `${PREFIX}-content`,
  contentShift: `${PREFIX}-contentShift`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.root}`]: {
    height: "100%",
    flexGrow: 1,
  },

  [`& .${classes.toolbarIcons}`]: {
    flex: "1 1 100%",
    textAlign: "end",
  },

  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },

  [`& .${classes.editIcon}`]: {
    [theme.breakpoints.up("sm")]: {
      zIndex: "100",
      backgroundColor: "#fff",
      position: "sticky",
      top: "0",
      right: "0",
    },
  },

  /////// NavDrawer Style

  [`& .${classes.flex}`]: {
    flex: "1 1 100%",
  },

  [`& .${classes.button}`]: {
    position: "sticky",
    bottom: 0,
    zIndex: 3,
    backgroundColor: "#fff",
    marginTop: 10,
    padding: 7,
  },

  [`& .${classes.mainSearch}`]: {
    margin: theme.spacing(0),
    width: "100%",
  },

  [`& .${classes.toolbar}`]: {
    borderBottom: "1px #ccd1d6 solid",
    backgroundColor: "#f5f7f9",
    width: "100%",
    // justifyContent: "space-between",
  },

  [`& .${classes.searchForm}`]: {
    overflowY: "auto",
    width: "100%",
    height: "100%",
    margin: 0,
  },

  [`& .${classes.searchField}`]: {
    padding: theme.spacing(1, 1, 0, 1),
    position: "relative",
    overflow: "hidden",
  },

  [`& .${classes.searchPadding}`]: {
    padding: theme.spacing(1),
  },

  [`& .${classes.drawer}`]: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },

  [`& .${classes.drawerPaper}`]: {
    width: DRAWER_WIDTH,
    zIndex: "250",
    height: "100%",
    left: "initial",
    right: "initial",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },

  [`& .${classes.drawerHeader}`]: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },

  [`& .${classes.content}`]: {
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
  },

  [`& .${classes.contentShift}`]: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: "auto",
    width: "calc(100% - 240px)",
    [theme.breakpoints.down("sm")]: {
      margin: "auto",
      width: "auto",
    },
  },
}));

const DRAWER_WIDTH = 240;

function TransitionComponent(props) {
  const style = useSpring({
    from: { opacity: 0, transform: "translate3d(20px,0,0)" },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
    },
  });

  return (
    <animated.div sx={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

TransitionComponent.propTypes = {
  /**
   * Show the component; triggers the enter or exit states
   */
  in: PropTypes.bool,
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function GlAccountsTree(props) {
  const [autocompleteValues, setAutocompleteValues] = useState({
    glAccount: null,
    branch: null,
  });
  const [glAccountDialog, setGlAccountDialog] = useState(false);
  const [saveGlAccountState, setSaveGlAccountState] = useState({
    create: null,
    update: null,
  });

  //****************** Start NavDrawer Search ************************//

  const theme = useTheme();

  const urlQuery = urlParameters(window.location.search);

  const validUrlParameters = Object.keys(urlQuery).length !== 0;

  const [drawerState, setDrawerState] = React.useState({
    top: true,
    left: props.width === "xs" ? false : true,
    bottom: props.width === "xs" ? false : true,
    right: props.width === "xs" ? false : true,
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm();
  const [rowsPerPage, setRowsPerPage] = useState(urlQuery["rowsPerPage"] ?? 20);
  const initSearch = () => {
    let searchParameters = {
      refetch: true,
      page: 0,
      parentId: null,
    };
    delete urlQuery["rowsPerPage"];
    if (validUrlParameters) {
      if (urlQuery["name"]) urlQuery["name"] = urlQuery["name"].toString();
      if (urlQuery["code"]) urlQuery["code"] = urlQuery["code"].toString();
      searchParameters = urlQuery;
    }
    return {
      ...searchParameters,
    };
  };
  const [search, setSearch] = useState(initSearch());
  useEffect(() => {
    urlQuery["name"] && setValue("name", urlQuery["name"]);
    urlQuery["code"] && setValue("code", urlQuery["code"]);
    // urlQuery["parentId"] && setValue("parentId", urlQuery["parentId"]);

    return () => {};
  }, []);

  const history = useHistory();

  const pushUrlSearch = (param, push) => {
    const queryParams = [];
    for (const i in param) {
      encodeURIComponent(param[i]) &&
        queryParams.push(
          encodeURIComponent(i) + "=" + encodeURIComponent(param[i])
        );
    }
    const queryString = queryParams.join("&");

    if (push)
      return history.push({
        pathname: "/admin/finance/gl-account",
        search: "?" + queryString,
      });

    const url = history.createHref({
      pathname: "/admin/finance/gl-account",
      search: "?" + queryString,
    });
    windowUrl(url);
    //this will not effect on history.location.search
  };
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      (event.type === "keydown" || event.type === "submit") &&
      (event.type === "submit" || event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerState({ ...drawerState, [anchor]: open });
  };
  const filterAnchor = props.width === "xs" ? "bottom" : "left";

  const onSubmit = (data) => {
    filterAnchor === "bottom" &&
      setDrawerState({ ...drawerState, [filterAnchor]: false });
    let handledData = {
      name: data.name,
      code: data.code,
      branchId: data.branchId,
      typeCode: data.typeCode,
      parentId: data.parentId,
      page: 0,
    };
    if (data.code) handledData = { code: data.code, page: 0 };

    for (const key in handledData) {
      if (
        handledData[key] === undefined ||
        handledData[key] === "" ||
        handledData[key] === null
      ) {
        delete handledData[key];
      }
    }
    setSearch((prev) => ({
      ...handledData,
      refetch: !prev.refetch,
    }));
    for (const key in handledData) {
      if (handledData[key] === null) {
        delete handledData[key];
      }
    }
    if (Object.keys(handledData).length < 2) handledData = {};
    pushUrlSearch({
      ...handledData,
    });
  };

  const openChild = (id) => {
    setSearch((prev) => ({
      parentId: id,
      refetch: !prev.refetch,
      page: 0,
    }));
    pushUrlSearch(
      {
        parentId: id,
        page: 0,
      },
      true
    );
  };

  //****************** end NavDrawer Search ************************//

  const {
    data,
    loading: searchLoad,
    refetch,
  } = useQuery(
    gql`
      ${LIST_GL_ACCOUNTS.query}
    `,
    {
      // notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: {
        first: rowsPerPage,
        page: search?.page + 1,
        input: {
          ...((search.parentId || search.parentId === null) &&
            !search.name && {
              parentId: search.parentId,
            }),
          ...(search.name && { name: search.name }),
          ...(search.code && !search.name && { code: search.code }),
          ...(search.branchId &&
            !search.name && { branchId: { value: search.branchId } }),
          ...(search.typeCode && !search.name && { typeCode: search.typeCode }),
        },
      },
      onCompleted: (data) => {},
    }
  );

  const glAccountsData = data?.listGLAccounts?.data;

  const { t } = useTranslation();

  const openGlAccountDialog = () => {
    setGlAccountDialog(true);
  };
  const updateGl = (id) => {
    openGlAccountDialog();
    setSaveGlAccountState({
      create: null,
      update: id,
    });
  };

  const handelAutocompleteDefaultValue = (data, id, fieldName) => {
    const defaultValue = data[Object.keys(data)[0]].find(
      (i) => i.id === parseInt(id)
    );
    defaultValue &&
      setAutocompleteValues((prev) => ({
        ...prev,
        [fieldName]: defaultValue,
      }));
  };

  const lookupsComplete = (data, updateValue, name) => {
    if (updateValue && lookupCodeIsValid(data, updateValue)) {
      setValue(name, updateValue);
    }
  };

  const parseData = (data) => {
    return data;
  };

  const handleChangePage = (event, newPage) => {
    pushUrlSearch({
      ...urlQuery,

      page: newPage,
      rowsPerPage: rowsPerPage,
    });

    setSearch((prev) => ({ ...prev, page: newPage }));
  };

  const handleChangeRowsPerPage = (event) => {
    pushUrlSearch({
      ...urlQuery,
      page: 0,
      rowsPerPage: +event.target.value,
    });

    setRowsPerPage(+event.target.value);
    setSearch((prev) => ({ ...prev, page: 0 }));
  };

  return (
    <Root>
      <Drawer
        className={clsx(classes.drawer)}
        variant="persistent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor={filterAnchor}
        open={drawerState[filterAnchor]}
        onClose={() => false}
        dir={theme.direction}
      >
        <div className={classes.drawerHeader} />
        <Toolbar
          variant="dense"
          className={classes.toolbar}
          sx={{ marginBottom: 1 }}
        >
          <Typography
            className={classes.flex}
            color="inherit"
            variant="h6"
            component="div"
          >
            {t("search")}
          </Typography>
          <IconButton
            aria-label="done"
            size="small"
            onClick={toggleDrawer(filterAnchor, false)}
            color={"primary"}
          >
            <Clear />
          </IconButton>
        </Toolbar>

        <Grid
          container
          item
          justifyContent="center"
          alignContent="space-between"
          component={"form"}
          onSubmit={handleSubmit(onSubmit)}
          className={classes.searchForm}
        >
          <Grid
            container
            item
            sm={12}
            justifyContent="flex-start"
            spacing={1}
            className={classes.searchField}
          >
            <Grid container item sm={12} alignItems="flex-start">
              <ControlMUItextField
                control={control}
                errors={errors}
                name={"name"}
                label={t("name")}
              />
            </Grid>
            <Grid container item sm={12} alignItems="flex-start">
              <ControlMUItextField
                control={control}
                errors={errors}
                name={"code"}
                label={t("code")}
                disabled={Boolean(watch("name"))}
              />
            </Grid>
            <Grid container item sm={12} alignItems="flex-start">
              <CustomAutocomplete
                control={control}
                errors={errors}
                name={"parentId"}
                label={t("parentAccount")}
                disabled={Boolean(watch("name"))}
                parseData={(data) => parseData(data)}
                query={LIST_GL_ACCOUNTS_DROPDOWN.query}
                variables={{
                  input: {
                    typeCode: "MAIN",
                  },
                }}
                onCompleted={(data) =>
                  handelAutocompleteDefaultValue(
                    data,
                    urlQuery["parentId"],
                    "glAccount"
                  )
                }
                defaultValue={autocompleteValues.glAccount}
              />
            </Grid>
            <Grid container item sm={12} alignItems="flex-start">
              <CustomAutocomplete
                name={"branchId"}
                label={t("currentBranch")}
                control={control}
                errors={errors}
                disabled={Boolean(watch("name"))}
                parseData={(data) => parseData(data)}
                onCompleted={(data) =>
                  handelAutocompleteDefaultValue(
                    data,
                    urlQuery["branchId"],
                    "branch"
                  )
                }
                query={LIST_BRANCHES_DROPDOWN.query}
                defaultValue={autocompleteValues.branch}
              />
            </Grid>
            <Grid container item sm={12} alignItems="flex-start">
              <LookupDropdown
                control={control}
                errors={errors}
                name="typeCode"
                disabled={Boolean(watch("name"))}
                label={t("accountType")}
                variables={{ input: { code: "ACCOUNT_TYPE" } }}
                onCompleted={(data) =>
                  lookupsComplete(data, urlQuery["typeCode"], "typeCode")
                }
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            sm={12}
            className={clsx(classes.searchPadding, classes.button)}
            alignItems="center"
            alignContent="flex-end"
            justifyContent="flex-end"
          >
            <Button
              sx={{ width: "100%" }}
              variant="contained"
              color="primary"
              type="submit"
              size="large"
              startIcon={<Search />}
              disabled={searchLoad}
            >
              {t("search")}
            </Button>
          </Grid>
        </Grid>
      </Drawer>

      <Grid
        container
        justifyContent="center"
        spacing={3}
        className={clsx(classes.content, classes.mainSearch, {
          [classes.contentShift]: drawerState[filterAnchor],
        })}
      >
        <Toolbar className={classes.toolbar} variant="dense">
          <Grid container sx={{ flexWrap: "initial" }} alignItems="center">
            <Grid item container>
              <Typography variant="h6">{t(`accountIndex`)}</Typography>
            </Grid>
            <Grid className={classes.toolbarIcons} item>
              <IconButton
                onClick={toggleDrawer(filterAnchor, true)}
                size="large"
              >
                <FilterList fontSize="inherit" />
              </IconButton>
              <IconButton
                onClick={() => setGlAccountDialog(true)}
                color={"primary"}
                size="large"
              >
                <Add fontSize="inherit" />
              </IconButton>
              {/* <IconButton
              onClick={() =>
                setExpanded(expanded.length > 0 ? [] : openAll(glAccounts))
              }
              color={"primary"}
            >
              <AccountTreeOutlined fontSize="inherit" />
            </IconButton> */}
            </Grid>
          </Grid>
        </Toolbar>
        <Dialog
          fullScreen
          open={glAccountDialog}
          onClose={() => setGlAccountDialog(false)}
          TransitionComponent={Transition}
        >
          <AppBar position="relative">
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => setGlAccountDialog(false)}
                aria-label="close"
                size="large"
              >
                <Close />
              </IconButton>
              <Typography color="inherit" variant="h6">
                {t("account")}
              </Typography>
            </Toolbar>
          </AppBar>
          <CreateGlAccount
            updateGlId={saveGlAccountState.update}
            createGlWithParent={saveGlAccountState.create}
            saveGlAccountResponse={(data) => {
              refetch();
              setGlAccountDialog(false);
              setSaveGlAccountState({
                create: null,
                update: null,
              });
            }}
          />
        </Dialog>

        <Grid container>
          <Paper sx={{ width: "100%" }}>
            <Grid item container sx={{ display: "grid" }}>
              {searchLoad || glAccountsData?.length === 0 ? (
                <EmptyTableMessage
                  loading={searchLoad}
                  message={t("searchNoResult")}
                />
              ) : (
                <TableFixedHeaderWraper>
                  <Table
                    aria-labelledby="tableTitle"
                    aria-label="enhanced table"
                  >
                    <TableHead>
                      <TableRow>
                        <FixedTableCell>{t("name")}</FixedTableCell>
                        <FixedTableCell>{t("code")}</FixedTableCell>
                        <FixedTableCell>{t("branch")}</FixedTableCell>
                        <FixedTableCell>{t("parentAccount")}</FixedTableCell>
                        <FixedTableCell>{t("closingAccount")}</FixedTableCell>
                        <FixedTableCell>{t("accountNature")}</FixedTableCell>
                        <FixedTableCell>{t("accountType")}</FixedTableCell>
                        <FixedTableCell>{t("accountClass")}</FixedTableCell>
                        <FixedTableCell>{t("active")}</FixedTableCell>
                        <FixedTableCell className={classes.editIcon}>
                          <span />
                        </FixedTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {glAccountsData?.map((row, index) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.id}
                            className={classes.tableBodyRow}
                          >
                            {row.type.code === "SUB" ? (
                              <FixedTableCell>{row.name}</FixedTableCell>
                            ) : (
                              <CellLink onClick={() => openChild(row.id)}>
                                {row.name}
                              </CellLink>
                            )}
                            <FixedTableCell>{row.code}</FixedTableCell>
                            <CellLink
                              pathname={`/admin/branches/${row?.branch?.id}`}
                            >
                              {row?.branch?.name}
                            </CellLink>
                            <CellLink
                              onClick={() =>
                                row.parent?.id && openChild(row.parent?.id)
                              }
                            >
                              {row?.parent?.name}
                            </CellLink>
                            <FixedTableCell>
                              {row?.closingAccount?.name}
                            </FixedTableCell>
                            <FixedTableCell>{row?.nature?.name}</FixedTableCell>
                            <FixedTableCell>{row?.type?.name}</FixedTableCell>
                            <FixedTableCell>{row?.class?.name}</FixedTableCell>
                            <FixedTableCell>
                              {row?.active ? (
                                <Icon className={classes.iconColor}>
                                  check_circle_outline
                                </Icon>
                              ) : (
                                <Icon color="error">highlight_off</Icon>
                              )}
                            </FixedTableCell>
                            <FixedTableCell className={classes.editIcon}>
                              <IconButton
                                color="primary"
                                onClick={() => updateGl(row.id)}
                                size="large"
                              >
                                <Edit />
                              </IconButton>
                            </FixedTableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableFixedHeaderWraper>
              )}
            </Grid>
            <MUITablePagination
              count={data?.listGLAccounts?.paginatorInfo?.total}
              rowsPerPage={rowsPerPage}
              page={!glAccountsData ? 0 : search?.page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
      </Grid>
    </Root>
  );
}
