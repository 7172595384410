import { Icon, IconButton, TableRow } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Delete } from "@mui/icons-material";
import React from "react";
import { useTranslation } from "react-i18next";
import CellLink from "../HOC/CustomComponents/CellLink";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import ShipmentRegion from "../HOC/CustomComponents/ShipmentRegion";
import CustomSpinner from "../HOC/FunctionComponents/CustomSpinner";

const PREFIX = "useManifestDetails";

const classes = {
  iconColor: `${PREFIX}-iconColor`,
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },
}));

const useManifestDetails = ({
  type,
  approved,
  pickedShipment,
  setPickedShipment: removeShipment,
  saveManifestLoad,
}) => {
  const { t } = useTranslation();

  const deleteShipment = (manifestShipment) => (
    <FixedTableCell component="th" scope="row">
      <IconButton
        size="small"
        disabled={approved || saveManifestLoad}
        onClick={() => {
          pickedShipment.shipments.forEach((i) => {
            if (i.shipment.id === manifestShipment.shipment.id) {
              i["pendingDelete"] = true;
            }
          });
          removeShipment((prev) => ({
            shipments: pickedShipment.shipments,
          }));
        }}
      >
        {manifestShipment["pendingDelete"] ? (
          <CustomSpinner name="ClockLoader" size={20} />
        ) : (
          <Delete />
        )}
      </IconButton>
    </FixedTableCell>
  );

  /* --------------------------------- PKM --------------------------------- */

  const pkmTableHead = [
    "#",
    "code",
    "senderName",
    "source",
    "senderAddress",
    "packageType",
    "packageDescription",
    "packagePrice",
    "pieceCount",
    "weight",
    "amountMustBeCollected",
    removeShipment ? "" : null,
  ];

  const pkmTableBody = (manifestShipment, index) => {
    const toBeCollected =
      manifestShipment?.shipment?.paymentType?.code === "CASH" ||
      manifestShipment?.shipment?.type?.code === "RTS"
        ? manifestShipment?.shipment?.type?.code === "RTS"
          ? manifestShipment?.shipment?.totalAmount
          : manifestShipment?.shipment?.allDueFees
        : 0;
    return (
      <StyledTableRow
        hover
        role="checkbox"
        tabIndex={-1}
        key={manifestShipment?.shipment.id}
      >
        <FixedTableCell>{index + 1}</FixedTableCell>
        <CellLink
          pathname={`/admin/shipments/${manifestShipment?.shipment.id}`}
        >
          {manifestShipment?.shipment.code}
        </CellLink>
        <FixedTableCell>
          <span style={{ display: "block" }}>
            {manifestShipment?.shipment?.senderName}
          </span>
          <span style={{ display: "block" }}>
            {manifestShipment?.shipment?.senderMobile}
          </span>
          <span>{manifestShipment?.shipment?.senderPhone}</span>
        </FixedTableCell>
        <ShipmentRegion
          pathname={`/admin/zone/${manifestShipment?.shipment?.senderZone?.id}`}
          zone={manifestShipment?.shipment.senderZone?.name}
          subzone={manifestShipment?.shipment?.senderSubzone.name}
        />
        <FixedTableCell>
          {manifestShipment?.shipment?.senderAddress}
        </FixedTableCell>

        <FixedTableCell>
          {manifestShipment?.shipment?.type?.name}
        </FixedTableCell>
        <FixedTableCell>
          {manifestShipment?.shipment?.description}
        </FixedTableCell>
        <FixedTableCell>{manifestShipment?.shipment?.price}</FixedTableCell>
        <FixedTableCell>
          {manifestShipment?.shipment?.piecesCount}
        </FixedTableCell>
        <FixedTableCell>{manifestShipment?.shipment?.weight}</FixedTableCell>
        <FixedTableCell>{toBeCollected}</FixedTableCell>
        {removeShipment && deleteShipment(manifestShipment)}
      </StyledTableRow>
    );
  };

  const pkmTableTotal = {
    price: 0,
    piecesCount: 0,
    weight: 0,
    allDueFees: 0,
  };

  /* --------------------------------- BTM --------------------------------- */
  const bmtTableHead = [
    "#",
    "requestCode",
    "senderName",
    "source",
    "recipient",
    "destination",
    "packageDescription",
    "pieceCount",
    "weight",
    "packagePrice",
    removeShipment ? "" : null,
  ];

  const bmtTableBody = (manifestShipment, index) => {
    return (
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        key={manifestShipment?.shipment.id}
      >
        <FixedTableCell>{index + 1}</FixedTableCell>
        <CellLink
          pathname={`/admin/shipments/${manifestShipment?.shipment.id}`}
        >
          {manifestShipment?.shipment.code}
        </CellLink>
        <FixedTableCell>
          {manifestShipment?.shipment?.senderName}
        </FixedTableCell>
        <ShipmentRegion
          pathname={`/admin/zone/${manifestShipment?.shipment.senderZone.id}`}
          zone={manifestShipment?.shipment?.senderZone.name}
          subzone={manifestShipment?.shipment?.senderSubzone.name}
        />
        <FixedTableCell>
          {manifestShipment?.shipment?.recipientName}
        </FixedTableCell>
        <ShipmentRegion
          pathname={`/admin/zone/${manifestShipment?.shipment.recipientZone.id}`}
          zone={manifestShipment?.shipment.recipientZone?.name}
          subzone={manifestShipment?.shipment?.recipientSubzone.name}
        />
        <FixedTableCell>
          {manifestShipment?.shipment?.description}
        </FixedTableCell>
        <FixedTableCell>
          {manifestShipment?.shipment?.piecesCount}
        </FixedTableCell>
        <FixedTableCell>{manifestShipment?.shipment?.weight}</FixedTableCell>
        <FixedTableCell>{manifestShipment?.shipment?.price}</FixedTableCell>
        {removeShipment && deleteShipment(manifestShipment)}
      </TableRow>
    );
  };

  const bmtTableTotal = {
    piecesCount: 0,
    weight: 0,
    price: 0,
  };

  /* ---------------------------------  RITS  --------------------------------- */
  const ritsTableHead = [
    "#",
    "requestCode",
    "senderName",
    "recipient",
    "destination",
    "paymentType",
    "packageDescription",
    "pieceCount",
    "weight",
    "packagePrice",
    "returningDueFees",
    "customerDueAmount",
    removeShipment ? "" : null,
  ];

  const ritsTableBody = (manifestShipment, index) => {
    return (
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        key={manifestShipment?.shipment.id}
      >
        <FixedTableCell>{index + 1}</FixedTableCell>
        <CellLink
          pathname={`/admin/shipments/${manifestShipment?.shipment.id}`}
        >
          {manifestShipment?.shipment.code}
        </CellLink>
        <FixedTableCell>
          {manifestShipment?.shipment?.senderName}
        </FixedTableCell>
        <FixedTableCell>
          <span style={{ display: "block" }}>
            {manifestShipment?.shipment?.recipientName}
          </span>
          <span style={{ display: "block" }}>
            {manifestShipment?.shipment?.recipientMobile}
          </span>
          <span>{manifestShipment?.shipment?.recipientPhone}</span>
        </FixedTableCell>
        <ShipmentRegion
          pathname={`/admin/zone/${manifestShipment?.shipment.recipientZone.id}`}
          zone={manifestShipment?.shipment.recipientZone?.name}
          subzone={manifestShipment?.shipment?.recipientSubzone.name}
        />
        <FixedTableCell>
          {manifestShipment?.shipment?.paymentType?.name}
        </FixedTableCell>
        <FixedTableCell>
          {manifestShipment?.shipment?.description}
        </FixedTableCell>
        <FixedTableCell>
          {manifestShipment?.shipment?.piecesCount}
        </FixedTableCell>
        <FixedTableCell>{manifestShipment?.shipment?.weight}</FixedTableCell>
        <FixedTableCell>{manifestShipment?.shipment?.price}</FixedTableCell>
        <FixedTableCell>
          {manifestShipment?.shipment?.returningDueFees}
        </FixedTableCell>
        <FixedTableCell>{manifestShipment?.shipment?.amount}</FixedTableCell>
        {removeShipment && deleteShipment(manifestShipment)}
      </TableRow>
    );
  };

  const ritsTableTotal = {
    piecesCount: 0,
    weight: 0,
    price: 0,
    returningDueFees: 0,
    amount: 0,
  };

  /* ---------------------------------  RTS  --------------------------------- */
  const rtsTableHead = [
    "#",
    "code",
    "senderName",
    "recipient",
    "destination",
    "reasonName",
    "returnType",
    "feesAmount",
    removeShipment ? "" : null,
  ];

  const rtsTableBody = (manifestShipment, index) => {
    return (
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        key={manifestShipment?.shipment.id}
      >
        <FixedTableCell>{index + 1}</FixedTableCell>
        <CellLink
          pathname={`/admin/shipments/${manifestShipment?.shipment.id}`}
        >
          {manifestShipment?.shipment.code}
        </CellLink>
        <FixedTableCell>
          {manifestShipment?.shipment?.senderName}
        </FixedTableCell>
        <FixedTableCell>
          {manifestShipment?.shipment?.recipientName}
        </FixedTableCell>
        <ShipmentRegion
          pathname={`/admin/zone/${manifestShipment?.shipment.recipientZone.id}`}
          zone={manifestShipment?.shipment.recipientZone?.name}
          subzone={manifestShipment?.shipment?.recipientSubzone.name}
        />
        <FixedTableCell>
          {manifestShipment?.cancellationReason.name}
        </FixedTableCell>
        <FixedTableCell>{manifestShipment?.returnType.name}</FixedTableCell>

        <FixedTableCell>{manifestShipment?.fees}</FixedTableCell>
        {removeShipment && deleteShipment(manifestShipment)}
      </TableRow>
    );
  };

  const rtsTableTotal = {
    fees: 0,
  };

  /* --------------------------------- OTR --------------------------------- */
  const otrTableHead = [
    "#",
    "code",
    "wareHouse",
    "senderName",
    "source",
    "senderAddress",
    "recipient",
    "destination",
    "packageDescription",
    "returnType",
    removeShipment ? "" : null,
  ];

  const otrTableBody = (manifestShipment, index) => {
    const returnType = manifestShipment?.shipment?.transactions?.find(
      (i) => i.returnType !== null
    );
    return (
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        key={manifestShipment?.shipment.id}
      >
        <FixedTableCell>{index + 1}</FixedTableCell>
        <CellLink
          pathname={`/admin/shipments/${manifestShipment?.shipment.id}`}
        >
          {manifestShipment?.shipment.code}
        </CellLink>
        <CellLink
          pathname={`/admin/warehouse/${manifestShipment?.shipment?.warehouseSection?.id}`}
        >
          {manifestShipment?.shipment?.warehouseSection?.name}
        </CellLink>
        <FixedTableCell>
          {manifestShipment?.shipment?.senderName}
        </FixedTableCell>
        <ShipmentRegion
          pathname={`/admin/zone/${manifestShipment?.shipment?.senderZone?.id}`}
          zone={manifestShipment?.shipment.senderZone?.name}
          subzone={manifestShipment?.shipment?.senderSubzone.name}
        />
        <FixedTableCell>
          {manifestShipment?.shipment?.senderAddress}
        </FixedTableCell>
        <FixedTableCell>
          {manifestShipment?.shipment?.recipientName}
        </FixedTableCell>
        <ShipmentRegion
          pathname={`/admin/zone/${manifestShipment?.shipment.recipientZone.id}`}
          zone={manifestShipment?.shipment.recipientZone?.name}
          subzone={manifestShipment?.shipment?.recipientSubzone.name}
        />
        <FixedTableCell>
          {manifestShipment?.shipment?.description}
        </FixedTableCell>
        <FixedTableCell>{returnType?.returnType?.name}</FixedTableCell>
        {removeShipment && deleteShipment(manifestShipment)}
      </TableRow>
    );
  };

  const otrTableTotal = {};
  /* --------------------------------- RTRN --------------------------------- */

  let rtrnTableHead = [
    "#",
    "code",
    "customer",
    "recipient",
    "destination",
    "packageDescription",
    "returnType",
    "weight",
    "returnedValue",
    "totalPackagePrice",
    removeShipment ? "" : null,
  ];

  const rtrnTableBody = (manifestShipment, index) => {
    return (
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        key={manifestShipment?.shipment.id}
      >
        <FixedTableCell>{index + 1}</FixedTableCell>
        <CellLink
          pathname={`/admin/shipments/${manifestShipment?.shipment.id}`}
        >
          {manifestShipment?.shipment.code}
        </CellLink>
        {/* <FixedTableCell>{manifestShipment?.shipment?.customer?.name}</FixedTableCell> */}
        {manifestShipment?.shipment.customer ? (
          <CellLink
            pathname={`/admin/customers/${manifestShipment?.shipment.customer.id}`}
          >
            {manifestShipment?.shipment.customer?.name}
          </CellLink>
        ) : (
          <FixedTableCell> </FixedTableCell>
        )}
        <FixedTableCell>
          {manifestShipment?.shipment?.recipientName}
        </FixedTableCell>
        <ShipmentRegion
          pathname={`/admin/zone/${manifestShipment?.shipment.recipientZone.id}`}
          zone={manifestShipment?.shipment.recipientZone?.name}
          subzone={manifestShipment?.shipment?.recipientSubzone.name}
        />
        <FixedTableCell>
          {manifestShipment?.shipment?.description}
        </FixedTableCell>
        <FixedTableCell>
          {manifestShipment?.shipment?.returnType?.name}
        </FixedTableCell>
        <FixedTableCell>{manifestShipment?.shipment?.weight}</FixedTableCell>
        <FixedTableCell>
          {manifestShipment?.shipment?.returnedValue}
        </FixedTableCell>
        <FixedTableCell>{manifestShipment?.shipment?.amount}</FixedTableCell>
        {removeShipment && deleteShipment(manifestShipment)}
      </TableRow>
    );
  };

  const rtrnTableTotal = {
    weight: 0,
    returnedValue: 0,
    amount: 0,
  };
  /* --------------------------------- BMR --------------------------------- */

  const bmrTableHead = [
    "#",
    "code",
    "senderName",
    "source",
    "recipient",
    "destination",
    "packageDescription",
    "weight",
    removeShipment ? "" : null,
  ];

  const bmrTableBody = (manifestShipment, index) => {
    return (
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        key={manifestShipment?.shipment.id}
      >
        <FixedTableCell>{index + 1}</FixedTableCell>
        <CellLink
          pathname={`/admin/shipments/${manifestShipment?.shipment.id}`}
        >
          {manifestShipment?.shipment.code}
        </CellLink>
        <FixedTableCell>
          {manifestShipment?.shipment?.senderName}
        </FixedTableCell>
        <ShipmentRegion
          pathname={`/admin/zone/${manifestShipment?.shipment.senderZone.id}`}
          zone={manifestShipment?.shipment.senderZone?.name}
          subzone={manifestShipment?.shipment?.senderSubzone.name}
        />
        <FixedTableCell>
          {manifestShipment?.shipment?.recipientName}
        </FixedTableCell>
        <ShipmentRegion
          pathname={`/admin/zone/${manifestShipment?.shipment.recipientZone.id}`}
          zone={manifestShipment?.shipment.recipientZone?.name}
          subzone={manifestShipment?.shipment?.recipientSubzone.name}
        />
        <FixedTableCell>
          {manifestShipment?.shipment?.description}
        </FixedTableCell>
        <FixedTableCell>{manifestShipment?.shipment?.weight}</FixedTableCell>
        {removeShipment && deleteShipment(manifestShipment)}
      </TableRow>
    );
  };

  const bmrTableTotal = {
    weight: 0,
  };
  /* --------------------------------- HTR --------------------------------- */

  const htrTableHead = [
    "#",
    "code",
    "senderName",
    "source",
    "recipient",
    "destination",
    "packageDescription",
    "redeliveryDate",
    "notes",
    "weight",
    removeShipment ? "" : null,
  ];

  const htrTableBody = (manifestShipment, index) => {
    return (
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        key={manifestShipment?.shipment.id}
      >
        <FixedTableCell>{index + 1}</FixedTableCell>
        <CellLink
          pathname={`/admin/shipments/${manifestShipment?.shipment.id}`}
        >
          {manifestShipment?.shipment.code}
        </CellLink>
        <FixedTableCell>
          {manifestShipment?.shipment?.senderName}
        </FixedTableCell>
        <ShipmentRegion
          pathname={`/admin/zone/${manifestShipment?.shipment.senderZone.id}`}
          zone={manifestShipment?.shipment.senderZone?.name}
          subzone={manifestShipment?.shipment?.senderSubzone.name}
        />
        <FixedTableCell>
          {manifestShipment?.shipment?.recipientName}
        </FixedTableCell>
        <ShipmentRegion
          pathname={`/admin/zone/${manifestShipment?.shipment.recipientZone.id}`}
          zone={manifestShipment?.shipment.recipientZone?.name}
          subzone={manifestShipment?.shipment?.recipientSubzone.name}
        />
        <FixedTableCell>
          {manifestShipment?.shipment?.description}
        </FixedTableCell>
        <FixedTableCell>{manifestShipment?.deliveryDate}</FixedTableCell>
        <FixedTableCell>{manifestShipment?.notes}</FixedTableCell>
        <FixedTableCell>{manifestShipment?.shipment?.weight}</FixedTableCell>
        {removeShipment && deleteShipment(manifestShipment)}
      </TableRow>
    );
  };

  const htrTableTotal = {
    weight: 0,
  };

  /* --------------------------------- OTD --------------------------------- */

  const otdTableHead = [
    "#",
    "code",
    "recipient",
    "destination",
    "recipientAddress",
    "sender",
    "packageType",
    "packageDescription",
    "packageOpen",
    "notes",
    "total",
    removeShipment ? "" : null,
  ];

  const otdTableBody = (manifestShipment, index) => {
    const totalAmount =
      manifestShipment?.shipment?.type?.code === "RTS"
        ? 0
        : manifestShipment?.shipment?.totalAmount;

    return (
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        key={manifestShipment?.shipment.id}
      >
        <FixedTableCell>{index + 1}</FixedTableCell>

        <CellLink
          pathname={`/admin/shipments/${manifestShipment?.shipment.id}`}
        >
          {manifestShipment?.shipment.code}
        </CellLink>

        <FixedTableCell>
          <span style={{ display: "block" }}>
            {manifestShipment?.shipment?.recipientName}
          </span>
          <span style={{ display: "block" }}>
            {manifestShipment?.shipment?.recipientMobile}
          </span>
          <span>{manifestShipment?.shipment?.recipientPhone}</span>
        </FixedTableCell>
        <ShipmentRegion
          pathname={`/admin/zone/${manifestShipment?.shipment.recipientZone.id}`}
          zone={manifestShipment?.shipment.recipientZone?.name}
          subzone={manifestShipment?.shipment?.recipientSubzone.name}
        />
        <FixedTableCell>
          {manifestShipment?.shipment?.recipientAddress}
        </FixedTableCell>

        <FixedTableCell>
          <span style={{ display: "block" }}>
            {manifestShipment?.shipment?.senderName}
          </span>
          <span style={{ display: "block" }}>
            {manifestShipment?.shipment?.senderMobile}
          </span>
          <span>{manifestShipment?.shipment?.senderPhone}</span>
        </FixedTableCell>

        <FixedTableCell>
          {manifestShipment?.shipment?.type?.name}
        </FixedTableCell>
        <FixedTableCell>
          {manifestShipment?.shipment?.description}
        </FixedTableCell>
        <FixedTableCell>
          {manifestShipment?.shipment?.openable?.code === "Y" ? (
            <Icon className={classes.iconColor}>done</Icon>
          ) : (
            <Icon color="error">close</Icon>
          )}
        </FixedTableCell>

        <FixedTableCell>{manifestShipment?.shipment?.notes}</FixedTableCell>
        <FixedTableCell>{totalAmount}</FixedTableCell>
        {removeShipment && deleteShipment(manifestShipment)}
      </TableRow>
    );
  };

  const otdTableTotal = {
    totalAmount: 0,
  };
  /* --------------------------------- Manifests Object --------------------------------- */

  const manifestsObject = {
    PKM: {
      id: "1",
      customer: true,
      label: t("pickupLists"),
      tableHead: pkmTableHead,
      tableBody: pkmTableBody,
      tableTotal: pkmTableTotal,
      sumTableNumber: (shipment, tableTotal) => {
        Object.entries(tableTotal).forEach(([k, v]) => {
          if (
            shipment.shipment?.paymentType?.code !== "CASH" &&
            shipment.shipment?.type?.code !== "RTS" &&
            k === "allDueFees"
          ) {
            shipment.shipment[k] = 0;
          }

          if (shipment.shipment?.type?.code === "RTS" && k === "allDueFees") {
            tableTotal[k] += shipment.shipment["totalAmount"];
          } else {
            tableTotal[k] += shipment.shipment?.[k] ?? 0;
          }
        });
      },
    },
    RITS: {
      id: "3",
      sumTableNumber: null,
      customer: false,
      label: t("receivedInStore"),
      tableHead: ritsTableHead,
      tableBody: ritsTableBody,
      tableTotal: ritsTableTotal,
    },
    BMT: {
      id: "4",
      sumTableNumber: null,
      customer: false,
      label: t("branchTransfareTo"),
      tableHead: bmtTableHead,
      tableBody: bmtTableBody,
      tableTotal: bmtTableTotal,
    },
    BMR: {
      id: "5",
      sumTableNumber: null,
      customer: false,
      label: t("branchTransfareFrom"),
      tableHead: bmrTableHead,
      tableBody: bmrTableBody,
      tableTotal: bmrTableTotal,
    },
    OTD: {
      id: "6",
      customer: false,
      label: t("outForDeliveryLists"),
      tableHead: otdTableHead,
      tableBody: otdTableBody,
      tableTotal: otdTableTotal,
      sumTableNumber: (shipment, tableTotal) => {
        Object.entries(tableTotal).forEach(([k, v]) => {
          if (shipment.shipment?.type?.code === "RTS" && k === "totalAmount") {
            shipment.shipment[k] = 0;
          }

          tableTotal[k] += shipment.shipment[k] ?? 0;
        });
      },
    },

    DEX: {
      id: "8",
      sumTableNumber: null,
      customer: false,
      label: t("deliveryException"),
    },
    HTR: {
      id: "9",
      sumTableNumber: null,
      customer: false,
      label: t("retryDeliveryLists"),
      tableHead: htrTableHead,
      tableBody: htrTableBody,
      tableTotal: htrTableTotal,
    },
    RTS: {
      id: "10",
      sumTableNumber: (shipment, tableTotal) => {
        Object.entries(tableTotal).forEach(([k, v]) => {
          tableTotal[k] += shipment[k] ?? 0;
        });
      },
      customer: false,
      label: t("returnToCustomer"),
      tableHead: rtsTableHead,
      tableBody: rtsTableBody,
      tableTotal: rtsTableTotal,
    },
    OTR: {
      id: "11",
      sumTableNumber: null,
      customer: true,
      label: t("returnDelivery"),
      tableHead: otrTableHead,
      tableBody: otrTableBody,
      tableTotal: otrTableTotal,
      sum: false,
    },
    RTRN: {
      id: "12",
      sumTableNumber: null,
      customer: true,
      label: t("returnHandlingLists"),
      tableHead: rtrnTableHead,
      tableBody: rtrnTableBody,
      tableTotal: rtrnTableTotal,
    },
  };

  return {
    details: manifestsObject[type],
  };
};

export default useManifestDetails;
