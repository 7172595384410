import {
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import {
  ACCOUNTING_SETTINGS,
  APPROVE_JOURNAL_ENTRY,
  JOURNAL_ENTRY,
  SAVE_JOURNAL_ENTRY,
} from "./Graphql";

import { gql, useMutation, useQuery } from "@apollo/client";
import { Done, DoneAll } from "@mui/icons-material";
import Grid from "@mui/material/Unstable_Grid2";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import NotFound from "../../Error/NotFound";
import {
  LIST_GL_ACCOUNTS_DROPDOWN,
  LIST_JOURNAL_TYPES_DROPDOWN,
  LIST_SUBSIDIARIES_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import { sumArrayOfObjectValue } from "../../helpers/helpersFunction";
import ListBranches from "../HOC/ComponentWithSpecificQuery/ListBranches";
import CustomDialog from "../HOC/CustomComponents/CustomDialog";
import { windowReplaceUrl } from "../HOC/CustomFunctions/pushUrl";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import CustomButton from "../HOC/MUI/CustomButton";
import MUIDate from "../HOC/MUI/MUIDate";
import RecordsTable from "./RecordsTable";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";

const PREFIX = "JornalEntryForm";

const classes = {
  mainForm: `${PREFIX}-mainForm`,
  paper: `${PREFIX}-paper`,
  typography: `${PREFIX}-typography`,
  button: `${PREFIX}-button`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.mainForm}`]: {
    margin: 0,
    width: "100%",
  },

  [`& .${classes.paper}`]: {
    margin: theme.spacing(2),
    padding: theme.spacing(1),
  },

  [`& .${classes.typography}`]: {
    margin: theme.spacing(2),
  },

  [`& .${classes.button}`]: {
    marginLeft: theme.spacing(1),
  },
}));

const dateFormat = (date) => moment(date).locale("en").format("YYYY-MM-DD");

const JornalEntryForm = (props) => {
  const [recordsList, setRecordsList] = useState([]);
  const [journalDetails, setJournalDetails] = useState();
  const [dialogDetails, setDialogDetails] = useState({
    state: false,
    function: null,
    title: "",
    content: "",
  });
  const [recordIndex, setRecordIndex] = useState({
    index: 0,
    update: false,
  });
  const [dialog, setDialog] = useState(false);
  const [fieldsState, setFieldsState] = useState({
    createdAt: new Date(),
  });
  const [autocompleteValues, setAutocompleteValues] = useState({
    branch: null,
    type: null,
    subsidiary: null,
  });
  const [selectedNames, setSelectedNames] = useState({
    glAccount: "",
  });

  const theme = useTheme();
  const history = useHistory();
  const { t } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();
  const { control, formState, handleSubmit, setValue, watch, setError } =
    useForm();
  const {
    control: recordsControl,
    formState: { errors: recordsErrors },
    handleSubmit: recordsHandleSubmit,
    setValue: recordsSetValue,
    watch: recordsWatch,
    reset: recordsReset,
  } = useForm({
    defaultValues: {
      glAccountId: "",
      credit: "",
      debit: "",
      description: "",
    },
  });
  const { errors } = formState;
  const [saveJournalEntry, { loading: saveJournalLoading }] = useMutation(
    gql`
      ${SAVE_JOURNAL_ENTRY.query}
    `
  );
  const [approveJournalEntry, { loading: approveJournalLoading }] = useMutation(
    gql`
      ${APPROVE_JOURNAL_ENTRY.query}
    `
  );

  const journalId = parseInt(props?.match?.params?.id) || watch("id");

  const deviationValidation =
    sumArrayOfObjectValue(recordsList, "debit") -
      sumArrayOfObjectValue(recordsList, "credit") ===
    0;
  const approveValidation = Boolean(deviationValidation && journalId);
  const parseData = (data) => {
    return data;
  };

  const closeFormDialog = () => {
    setDialog(false);
    setRecordIndex((prev) => ({ ...prev, update: false }));
    recordsReset();
  };
  const closeConfirmationDialog = () => {
    setDialogDetails((prev) => ({
      ...prev,
      state: false,
    }));
  };

  const addRecordDialog = (index) => {
    recordsReset();
    if (index || index === 0) {
      setRecordIndex({
        index,
        update: true,
      });
    } else {
      setRecordIndex({
        index,
        update: false,
      });
    }
    setDialog(true);
  };

  const onChangeNames = (e, parameter, remove) => {
    const name = e?.name;
    setSelectedNames((prev) => ({
      ...prev,
      [parameter]: name,
      ...(remove && { [remove]: "" }),
    }));
  };

  const onSubmitRecords = (data) => {
    const newRecord = {
      debit: parseFloat(data.debit),
      credit: parseFloat(data.credit),
      description: data.description ? data.description : null,
      glAccount: { id: data.glAccountId, name: selectedNames.glAccount },
    };
    const updateRecords = [...recordsList];

    if (recordIndex.update) {
      updateRecords[recordIndex.index] = {
        ...updateRecords[recordIndex.index],
        ...newRecord,
      };
      setRecordIndex({ index: recordIndex.index, update: false });
    } else {
      updateRecords.push(newRecord);
      setRecordIndex({
        index: 0,
        update: false,
      });
    }
    setRecordsList(updateRecords);
    closeFormDialog();
  };

  const { loading: journalEntryLoading, data } = useQuery(
    gql`
      ${JOURNAL_ENTRY.query}
    `,
    {
      skip: !props?.match?.params?.id,
      variables: { id: parseInt(props?.match?.params?.id) },
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        const journalEntryData = data?.journalEntry;
        if (journalEntryData === null) return;

        setFieldsState((prev) => ({
          ...prev,
          createdAt: journalEntryData.date,
        }));
        setJournalDetails(journalEntryData);
        const saveJournalParams = ["id", "code", "date", "description"];

        setRecordsList(journalEntryData.records);
        saveJournalParams.forEach((i) => {
          journalEntryData[i] && setValue(i, journalEntryData[i]);
        });
        setAutocompleteValues({
          type: journalEntryData?.type,
          branch: journalEntryData?.branch,
          ...(journalEntryData?.subsidiary && {
            subsidiary: journalEntryData?.subsidiary,
          }),
        });
      },
    }
  );

  const onSubmit = (data) => {
    if (!deviationValidation) {
      enqueueSnackbar(t("deviationValidation"), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
        TransitionComponent: Collapse,
      });
      return;
    }
    for (const key in data) {
      if (data[key] === "") {
        delete data[key];
      }
    }
    data["date"] = dateFormat(data["date"]);
    const records = recordsList
      ? recordsList.map((i) => {
          const record = {
            ...(parseFloat(i.debit) !== 0 && { debit: parseFloat(i.debit) }),
            ...(parseFloat(i.credit) !== 0 && { credit: parseFloat(i.credit) }),
            ...(i.description && { description: i.description }),
            glAccountId: i.glAccount.id,
          };
          return record;
        })
      : [];
    return saveJournalEntry({
      variables: {
        input: {
          ...data,
          records,
        },
      },
    })
      .then((data) => {
        const saveJournalEntry = data?.data?.saveJournalEntry;
        setValue("id", saveJournalEntry.id);
        setValue("code", saveJournalEntry.code);
        const url = history.createHref({
          pathname: `/admin/finance/journal-entry/${saveJournalEntry?.id}/edit`,
        });
        windowReplaceUrl(url);
        setJournalDetails(saveJournalEntry);
        enqueueSnackbar(t("saveSuccessful"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      })
      .catch(({ graphQLErrors }) => {
        console.log(graphQLErrors);
        setValidationError(graphQLErrors, setError);
      });
  };

  const approve = () => {
    approveJournalEntry({ variables: { id: journalId } })
      .then((data) => {
        enqueueSnackbar(t("successfullyApproved"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
        closeConfirmationDialog();
        setJournalDetails(data.data.approveJournalEntry);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const approveJournal = () => {
    onSubmit(watch()).then((data) => {
      approve();
    });
  };

  const openApproveDialog = () => {
    setDialogDetails((prev) => ({
      state: true,
      title: t("approveJournalEntry"),
      content: t("approveRecordConfirmationMessage"),
      function: approveJournal,
    }));
  };

  const notApproved = !journalDetails?.approved;

  const recordForm = (
    <RecordsForm
      handleSubmit={recordsHandleSubmit}
      onSubmitRecord={onSubmitRecords}
      control={recordsControl}
      errors={recordsErrors}
      setValue={recordsSetValue}
      watch={recordsWatch}
      onChangeNames={onChangeNames}
      recordIndex={recordIndex}
      recordList={recordsList}
      closeFormDialog={closeFormDialog}
      setSelectedNames={setSelectedNames}
      branchId={watch("branchId")}
    />
  );

  return data?.journalEntry === null ? (
    <NotFound />
  ) : (
    <Root>
      <Dialog fullWidth maxWidth="xs" open={dialog} onClose={closeFormDialog}>
        {recordForm}
      </Dialog>
      <CustomDialog
        title={dialogDetails.title}
        fullWidth
        maxWidth="sm"
        onClose={closeConfirmationDialog}
        content={dialogDetails.content}
        open={dialogDetails.state}
        actions={
          <>
            <Button color="primary" onClick={closeConfirmationDialog}>
              {t("cancel")}
            </Button>
            <Button
              color="primary"
              disabled={approveJournalLoading}
              onClick={dialogDetails.function}
            >
              {approveJournalLoading ? <ButtonLoading /> : t("confirm")}
            </Button>
          </>
        }
      />

      {(journalId || journalId === 0) && journalEntryLoading ? (
        <FullScreenLoading minHeight="10%" />
      ) : (
        <>
          <Paper
            className={classes.paper}
            component="form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Grid container xs={12} justifyContent="space-between">
              <Typography variant="h6" className={classes.typography}>
                {t("journalEntries")}
              </Typography>
            </Grid>
            <Grid container spacing={2} className={classes.mainForm}>
              <Grid xs={12} sm={6} alignItems="flex-start">
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  name={"code"}
                  label={t("code")}
                />
              </Grid>
              <Grid xs={12} sm={6} alignItems="flex-start">
                <MUIDate
                  name="date"
                  label={t("date")}
                  control={control}
                  value={fieldsState.createdAt}
                  onChange={(e) =>
                    setFieldsState((prev) => ({ ...prev, createdAt: e }))
                  }
                />
              </Grid>
              <Grid xs={12} sm={6} alignItems="flex-start">
                <ListBranches
                  control={control}
                  errors={errors}
                  name={"branchId"}
                  rules={{ required: t("fieldIsRequired") }}
                  defaultValue={autocompleteValues.branch}
                  skipDefaultBranch={journalId}
                  onChangeValue={() => setValue("typeId", "")}
                />
              </Grid>
              <Grid xs={12} sm={6} alignItems="flex-start">
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  name={"typeId"}
                  label={t("journalType")}
                  rules={{ required: t("fieldIsRequired") }}
                  parseData={(data) => parseData(data)}
                  variables={{
                    input: {
                      referenceCode: "GL_MNL",
                      ...(watch("branchId") && {
                        branchId: {
                          value: watch("branchId"),
                          includeNull: true,
                        },
                      }),
                    },
                  }}
                  query={LIST_JOURNAL_TYPES_DROPDOWN.query}
                  defaultValue={autocompleteValues.type}
                />
              </Grid>
              <Grid xs={12} sm={6} alignItems="flex-start">
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  name={"subsidiaryId"}
                  label={t("subsidiary")}
                  parseData={(data) => parseData(data)}
                  query={LIST_SUBSIDIARIES_DROPDOWN.query}
                  defaultValue={autocompleteValues.subsidiary}
                />
              </Grid>
              <Grid xs={12} sm={6} alignItems="flex-start">
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  name={"description"}
                  label={t("description")}
                />
              </Grid>
              <Grid xs={12} container justifyContent="flex-end">
                {notApproved && (
                  <CustomButton
                    customcolor={theme.palette.success.main}
                    type="submit"
                    className={classes.button}
                    disabled={saveJournalLoading}
                    variant="contained"
                    size="medium"
                    loading={saveJournalLoading}
                    // className={classes.button}
                    startIcon={!saveJournalLoading && <Done />}
                  >
                    {!saveJournalLoading && t("save")}
                  </CustomButton>
                )}
                {approveValidation && (
                  <Button
                    disabled={
                      journalDetails?.approved ||
                      saveJournalLoading ||
                      journalDetails?.records?.length === 0
                    }
                    className={classes.button}
                    variant="contained"
                    size="medium"
                    color="primary"
                    onClick={openApproveDialog}
                    name="approved"
                    startIcon={<DoneAll />}
                  >
                    {t("approve")}
                  </Button>
                )}
              </Grid>
            </Grid>
          </Paper>
          <RecordsTable
            records={recordsList}
            addRecord={addRecordDialog}
            setRecordsList={setRecordsList}
            notApproved={notApproved}
            branchId={watch("branchId")}
          />
        </>
      )}
    </Root>
  );
};

export default JornalEntryForm;

function RecordsForm({
  handleSubmit,
  onSubmitRecord,
  control,
  errors,
  onChangeNames,
  recordIndex,
  recordList,
  setValue,
  watch,
  closeFormDialog,
  setSelectedNames,
  branchId,
}) {
  const { t } = useTranslation(["translation", "validation"]);
  const [autocompleteValues, setAutocompleteValues] = useState({
    glAccount: null,
  });
  useEffect(() => {
    if (recordIndex.update) {
      const update = recordList[recordIndex.index];
      setValue("credit", update.credit);
      setValue("debit", update.debit);
      setValue("description", update.description);
      setSelectedNames({
        ...(update?.glAccount?.name && { glAccount: update?.glAccount?.name }),
      });
      setAutocompleteValues({
        ...update,
      });
    } else {
      setValue("credit", 0);
      setValue("debit", 0);
    }
    return () => {};
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmitRecord)}>
      <DialogTitle>{t("addJournal")}</DialogTitle>
      <DialogContent>
        <ControlMUItextField
          control={control}
          errors={errors}
          name="debit"
          type="number"
          label={t("debit")}
          rules={{
            required: t("fieldIsRequired"),
            min: {
              value: 0,
              message: t("validation:min", {
                field: t("debit"),
                number: 0,
              }),
            },
          }}
          readOnly={parseFloat(watch("credit")) > 0}
          margin="normal"
        />
        <ControlMUItextField
          control={control}
          errors={errors}
          name="credit"
          type="number"
          label={t("credit")}
          rules={{
            required: t("fieldIsRequired"),
            min: {
              value: 0,
              message: t("validation:min", {
                field: t("credit"),
                number: 0,
              }),
            },
          }}
          readOnly={parseFloat(watch("debit")) > 0}
          margin="normal"
        />
        <CustomAutocomplete
          control={control}
          errors={errors}
          rules={{ required: t("fieldIsRequired") }}
          name={"glAccountId"}
          label={t("glAccount")}
          parseData={(data) => data}
          query={LIST_GL_ACCOUNTS_DROPDOWN.query}
          margin="normal"
          defaultValue={autocompleteValues.glAccount}
          onChangeValue={(e) => onChangeNames(e, "glAccount")}
          variables={{
            input: {
              typeCode: "SUB",
              branchId: { value: branchId, includeNull: true },
            },
          }}
        />
        <ControlMUItextField
          control={control}
          errors={errors}
          name="description"
          type="number"
          rows={3}
          label={t("description")}
          margin="normal"
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={closeFormDialog}>
          {t("cancel")}
        </Button>
        <Button color="primary" type="submit">
          {t("confirm")}
        </Button>
      </DialogActions>
    </form>
  );
}
