/* eslint-disable no-useless-concat */
import React, { useCallback, useState } from "react";

import { styled } from "@mui/material/styles";

import {
  CircularProgress,
  Grid,
  IconButton,
  lighten,
  Table,
  TableBody,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import useWidth, { isWidthDown } from "../../../Hooks/useWidth";

import { gql, useQuery } from "@apollo/client";
import { Cancel, CheckCircle } from "@mui/icons-material";
import clsx from "clsx";
import * as gqlb from "gql-query-builder";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import TableFixedHeaderWraper from "../../HOC/CustomComponents/TableWithFixedHeader";
import EmptyTableMessage from "../../HOC/FunctionComponents/EmptyTableMessage";
import MUITablePagination from "../../HOC/MUI/TablePagination/MUITablePagination";
import CustomTableRow from "./CustomTableRow";
import { EnhancedTableHead } from "./ShipmentListDetails";
import ManifestProductsProvider from "../../ManifestsProducts/ManifestProducts/ManifestProductsProvider";
import SearchFilter from "../../../Hooks/SearchFilter";

const TOOLBAR_PREFIX = "styledToolbar";

const toolbarClasses = {
  highlight: `${TOOLBAR_PREFIX}-highlight`,
  title: `${TOOLBAR_PREFIX}-title`,
  filterButton: `${TOOLBAR_PREFIX}-filterButton`,
};

const StyledToolbar = styled("div")(({ theme }) => ({
  width: "100%",
  zIndex: 1,

  [`& .${toolbarClasses.highlight}`]:
    theme.palette.mode === "light"
      ? {
        color: theme.palette.info.main,
        backgroundColor: lighten(theme.palette.info.main, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.info.main,
      },

  [`& .${toolbarClasses.title}`]: {
    flex: "1 1 15%",
  },

  [`& .${toolbarClasses.filterButton}`]: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
}));

const PREFIX = "ProductList";

const classes = {
  track: `${PREFIX}-track`,
  paper: `${PREFIX}-paper`,
  header: `${PREFIX}-header`,
};

const Root = styled(Grid)(({ theme }) => ({
  margin: theme.spacing(0, 0),
  width: "100%",
  backgroundColor: "#fff",

  [`& .${classes.track}`]: {
    marginTop: theme.spacing(2),
  },

  [`& .${classes.header}`]: {
    [theme.breakpoints.down("md")]: {
      flexWrap: "wrap",
    },
  },

  [`& .${classes.paper}`]: {
    width: "100%",
    backgroundColor: "#fff",
    overflowX: "auto",
    maxHeight: `calc(100vh - 120px)`,
  },
}));

const EnhancedTableToolbar = (props) => {
  const { t } = useTranslation();
  const screenWidth = useWidth();
  const screenIsSmall = isWidthDown("sm", screenWidth);
  const { numSelected, done, clear, disabled, filter } = props;
  return (
    <StyledToolbar>
      <Toolbar
        className={clsx(toolbarClasses.root, {
          [toolbarClasses.highlight]: numSelected > 0,
        }, classes.header)}
      >
        {numSelected > 0 ? (
          <Typography
            className={toolbarClasses.title}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {t("productCount", { count: numSelected })}
          </Typography>
        ) : (
          <Typography
            className={toolbarClasses.title}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            {t("products")}
          </Typography>
        )}
        {!screenIsSmall && filter}
        <IconButton
          aria-label="done"
          disabled={disabled}
          onClick={done}
          sx={{ color: "success.main" }}
          size="large"
        >
          <CheckCircle />
        </IconButton>
        {/* </Tooltip> */}

        <IconButton
          aria-label="close"
          onClick={() => clear()}
          color={"primary"}
          size="large"
        >
          <Cancel />
        </IconButton>
        {screenIsSmall && filter}
      </Toolbar>
    </StyledToolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  done: PropTypes.func.isRequired,
};

// *********Table Function*********

const PRODUCT_QUERY = gqlb.query({
  operation: "listProducts",
  fields: [
    {
      operation: "data",
      fields: [
        "id",
        "code",
        "createdAt",
        "name",
        "active",
        "price",
        "weight",
        "availableQuantity",
        {
          customer: [
            "id",
            "name",
            "mobile",
            { zone: ["id", "name"] },
            { subzone: ["name"] },
          ],
        },
      ],
      variables: {},
    },
    {
      operation: "paginatorInfo",
      fields: ["total"],
      variables: {},
    },
  ],
  variables: {
    first: {
      type: "Int",
      required: true,
    },
    page: {
      type: "Int",
    },
    input: {
      type: "ListProductsFilterInput",
    },
  },
});
const ProductList = (props) => {
  const { variables, done } = props;

  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [drawerState, setDrawerState] = useState(false);
  const [queryVariables, setQueryVariables] = useState({ refetch: true });
  const { refetch, ...restVariables } = queryVariables;

  const { t } = useTranslation();

  const { data, loading } = useQuery(
    gql`
      ${PRODUCT_QUERY.query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      skip: !variables,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: {
        first: rowsPerPage,
        page: page + 1,
        input: {
          forPickupId: variables.id,
          active: true,
          customerId: variables.customerId,
          ...restVariables,
        },
      },
      onCompleted: (data) => {
        const handledData =
          data?.listProducts?.data !== null ? data?.listProducts?.data : [];

        let updatedDate = handledData.map((i) => {
          const selectedProduct = selectedProducts.find(
            (ele) => ele.product.id === i.id
          );
          if (selectedProduct) {
            return selectedProduct;
          } else {
            return {
              product: i,
              select: false,
              valid: false
            };
          }
        });

        setProducts(updatedDate);
      },
    }
  );

  const isProductSelected = (addProducts, deleteProducts) => {
    const mainProducts = addProducts
      ? [...addProducts]
      : [...selectedProducts];

    const filterProducts = deleteProducts
      ? [...deleteProducts]
      : [...selectedProducts];

    return mainProducts.filter((newProduct) => {
      return !filterProducts.some(
        (current) => current.product.id === newProduct.product.id
      );
    });
  };

  const updateSelectedProductByForm = useCallback(
    ({ id, checkIsEmpty, name, value }) => {
      const update = [...products];
      const productIndex = update.findIndex((i) => {
        return i.product.id === id;
      });
      if (checkIsEmpty !== null || checkIsEmpty !== undefined)
        update[productIndex]["valid"] = checkIsEmpty;
      update[productIndex][name] = value;

      setProducts(update);
    },
    [products]
  );


  const handleSelectAllClick = (event, rows) => {
    const selected = products?.map((i) => {
      if (event.target.checked) {
        return {
          ...i,
          select: true,
        };
      } else {
        return {
          ...i,
          select: false,
        };
      }
    });

    if (event.target.checked) {
      const newProducts = isProductSelected(selected);
      setSelectedProducts((prev) => [...prev, ...newProducts]);
    } else {
      const filteredProducts = isProductSelected(null, selected);
      setSelectedProducts(filteredProducts);
    }
    setProducts(selected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const toggleFilterDrawer = () => setDrawerState(!drawerState);


  const resetPage = () => setPage(0);

  const filtersForm = (
    <SearchFilter
      {...{ resetPage, loading }}
      preventPushUrl
      queryVariables={(variables) => setQueryVariables(variables)}
      onSubmitFunc={() => setDrawerState(false)}
    />
  );
  /////////////////////////////////////////////////////////////////////////////////////////////////////

  const selectedProductLength = selectedProducts.length;
  let tableBody = null;

  let progress = (
    <Grid container item justifyContent="center" className={classes.track} marginBottom='50px'>
      <CircularProgress />
    </Grid>
  );
  const selectedProductParPage = () => {
    return products.filter((i) => i.select === true).length;
  };
  const validProducts = () => {
    return selectedProductLength <= 0 ||
      selectedProducts.some((i) => i.valid === false)
  };
  if (products && !loading) {
    tableBody = (
      <Grid container justifyContent="center" width={'100%'}>
        {/* *******Table******* */}
        <div className={classes.paper}>
          <TableFixedHeaderWraper>
            <Table aria-labelledby="tableTitle" aria-label="enhanced table">
              <EnhancedTableHead
                numSelected={selectedProductParPage()}
                onSelectAllClick={(e) => handleSelectAllClick(e, products)}
                rowCount={products?.length}
              />
              <TableBody>
                {products?.map((product, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <CustomTableRow
                      key={product.product.id}
                      product={product}
                      onChangeCheck={(check, selectedProduct) => {
                        setProducts((prev) => {
                          let updated = [...prev];
                          updated[index] = {
                            ...updated[index],
                            select: !updated[index].select,
                          };

                          if (check.target.checked) {
                            setSelectedProducts((prev) => [
                              ...prev,
                              updated[index],
                            ]);
                          } else {
                            setSelectedProducts((prev) => {
                              return prev.filter(
                                (i) => i.product.id !== product.product.id
                              );
                            });
                          }

                          return updated;
                        });
                      }}
                      labelId={labelId}
                    />
                  );
                })}
              </TableBody>
            </Table>
          </TableFixedHeaderWraper>
          <MUITablePagination
            count={data?.listProducts?.paginatorInfo?.total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </Grid>
    );
  }
  return (
    <ManifestProductsProvider
      value={{ updateSelectedProductByForm: updateSelectedProductByForm, branchId: variables.branchId }}
    >
      <Root container justifyContent="center">
        <Grid item container sm={12} alignContent="flex-start">
          <EnhancedTableToolbar
            numSelected={selectedProductLength}
            clear={done}
            toggleFilterDrawer={toggleFilterDrawer}
            done={() => {
              props.done();
              props.setSelected(selectedProducts);
            }}
            disabled={validProducts()}
            filter={filtersForm}
          />

          {loading
            ? progress
            : products && products?.length !== 0
              ? tableBody
              : !loading && (
                <EmptyTableMessage loading={false} message={t("noProducts")} />
              )}
        </Grid>
      </Root>
    </ManifestProductsProvider>
  );
};

export default ProductList;
