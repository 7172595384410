import * as gqlb from "gql-query-builder";
export const SAVE_BRANCH = gqlb.mutation({
  operation: "saveBranch",
  fields: ["id", "code", "active", { country: ["name"] }],
  variables: {
    input: {
      type: "BranchInput",
      required: true,
    },
  },
});

export const LIST_BRANCHES = gqlb.query({
  operation: "listBranches",
  fields: [
    {
      operation: "paginatorInfo",
      fields: [
        "count",
        "currentPage",
        "firstItem",
        "hasMorePages",
        "lastItem",
        "lastPage",
        "perPage",
        "total",
      ],
      variables: {},
    },
    {
      operation: "data",
      fields: [
        "id",
        "code",
        "name",
        "active",
        "phone",
        "address",
        { country: ["name"] },
      ],
      variables: {},
    },
  ],
  variables: {
    page: {
      type: "Int",
    },
    first: {
      type: "Int",
    },
    input: {
      type: "ListBranchesFilterInput",
    },
  },
});

export const BRANCH = gqlb.query({
  operation: "branch",
  fields: [
    "id",
    "code",
    "name",
    "address",
    "phone",
    "fax",
    "active",
    "main",

    {
      country: ["id", "name"],
    },
    {
      state: ["id", "name"],
    },
    {
      city: ["id", "name"],
    },
    {
      area: ["id", "name"],
    },
    {
      warehouseSections: ["id", "name", "active"]
    },
  ],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
