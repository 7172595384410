const useTotalTableDetails = ({
    printType,
    reportDetails,
    manifestTransactionType
}) => {
    let custmTableTotal
    if (printType === 'invoice') {
        custmTableTotal = {
            weight: reportDetails?.weight,
            piecesCount: reportDetails?.pieces,
            deliveryFees: reportDetails?.deliveryFees,
            postFees: reportDetails?.postFees,
            tax: reportDetails?.tax,
            returningDueFees: reportDetails?.allFees,
        };
    }
    else if (printType === 'collection') {
        const autoPayCommissionDlvby = reportDetails?.deliveryAgent?.autoPayCommission
        if (manifestTransactionType === 'DLVBY') {
            custmTableTotal = {
                totalAmount: reportDetails?.totalAmount,
                deliveredAmount: reportDetails?.deliveredAmount,
                collectedFees: reportDetails?.collectedFees,
                returningDueFees: reportDetails?.returningDueFees,
                collectedAmount: reportDetails?.collectedAmount,
            }
            if (autoPayCommissionDlvby) {
                custmTableTotal.totalCommission = reportDetails?.totalCommission
                custmTableTotal.total = reportDetails?.collectedAmount - reportDetails?.totalCommission
            }
        } else {
            custmTableTotal = {
                collectedAmount: reportDetails?.collectedAmount,
            }
        }
    }
    else if (printType === 'payment') {
        if (manifestTransactionType !== 'DLVBY') {
            custmTableTotal = {
                weight: reportDetails?.weight,
                piecesCount: reportDetails?.piecesCount,
                deliveredAmount: reportDetails?.deliveredAmount,
                collectedFees: reportDetails?.collectedFees,
                returningDueFees: reportDetails?.dueFees,
                paidAmount: reportDetails?.paymentAmount,
            }
        }
    }
    return {
        custmTableTotal,
    };
}

export default useTotalTableDetails;