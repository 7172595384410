import { Dialog } from "@mui/material";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import useWidth, { isWidthDown } from "../../Hooks/useWidth";
import Renewal from "./Renewal";

const RenewalDialog = (props) => {
    const { open, onClose, removeMsg } = props;


    const screenWidth = useWidth();
    const isScreenXSmall = isWidthDown("xs", screenWidth);
    const { t } = useTranslation();
    const closeDialog = () => {
        onClose();
    };
    return (
        <Fragment>
            <Dialog
                fullScreen={isScreenXSmall}
                fullWidth
                maxWidth={"xs"}
                open={open}
                onClose={closeDialog}
            >
                <Renewal
                    removeMsg={removeMsg}
                    closeDialog={closeDialog}
                />
            </Dialog>
        </Fragment>
    );
};

export default RenewalDialog;
