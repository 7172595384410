import React, { Fragment } from "react";
import { styled } from '@mui/material/styles';
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { SecuredNavLink } from "../HOC/CustomComponents/Secured";
import { Typography, Paper, Toolbar, IconButton, Icon } from "@mui/material";
import { Edit } from "@mui/icons-material";
import { gql, useQuery } from "@apollo/client";
import { KeyValuePair } from "../HOC/CustomComponents/KeyValuePair";
import { USER_ID } from "./Graphql";
import SpanLink from "../HOC/CustomComponents/SpanLink";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { Globals } from "../HOC/Classes/Globals";
import BranchesTable from "./BranchesTable";
import Grid from "@mui/material/Unstable_Grid2";

const PREFIX = 'UserView';

const classes = {
  loading: `${PREFIX}-loading`,
  paper: `${PREFIX}-paper`,
  toolbar: `${PREFIX}-toolbar`,
  toolbarIcons: `${PREFIX}-toolbarIcons`,
  iconButton: `${PREFIX}-iconButton`,
  iconColor: `${PREFIX}-iconColor`,
  main: `${PREFIX}-main`,
  field: `${PREFIX}-field`
};

const StyledGrid = styled(Grid)((
  {
    theme
  }
) => ({
  [`& .${classes.loading}`]: {},

  [`& .${classes.paper}`]: {
    padding: theme.spacing(3),
    flexGrow: 1,
  },

  [`& .${classes.toolbar}`]: {
    borderBottom: "1px #ccd1d6 solid",
    backgroundColor: "#f5f7f9",
    width: "100%",
  },

  [`& .${classes.toolbarIcons}`]: {
    flex: "1 1 100%",
    textAlign: "end",
  },

  [`& .${classes.iconButton}`]: {
    borderRadius: "0 4px 4px 0 ",
    boxShadow: "none",
    height: 45,
  },

  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },

  [`& .${classes.main}`]: {
    margin: 0,
    width: "100%",
  },

  [`& .${classes.field}`]: {
    width: 260,
    [theme.breakpoints.down("sm")]: {
      width: 170,
    },
  }
}));

const UserView = (props) => {

  const { t } = useTranslation();
  const userId = props.match.params.id?.trim();
  const { loading, data } = useQuery(
    gql`
      ${USER_ID.query}
    `,

    {
      skip: !userId,
      variables: { id: parseInt(userId) },
      fetchPolicy: "no-cache",
      onCompleted: (data) => {},
    }
  );
  const userBranches = data?.user?.userBranches?.map((i) => ({
    ...i,
    isDefault: i.default,
  }));

  const canUpdateAdmin =
    data?.user?.role?.code === "ADMN" ? Globals.user.isSuper : true;
  return (
    <StyledGrid
      container
      justifyContent="center"
      className={classes.mainTracking}
      sx={{ margin: "0", width: "100%" }}
    >
      {loading ? (
        <FullScreenLoading minHeight="25%" />
      ) : (
        <Fragment>
          <Toolbar className={clsx(classes.toolbar)} variant="regular">
            <Grid
              container
              sx={{ flexWrap: "initial", flexGrow: 1 }}
              alignItems="center"
            >
              <Grid className={classes.field}>
                <Typography variant="h6">{t(`userDetails`)}</Typography>
              </Grid>
              <Grid className={classes.toolbarIcons}>
                {canUpdateAdmin && (
                  <SecuredNavLink
                    permission="core.user.update"
                    to={{
                      pathname: `/admin/users/${userId}/edit`,
                    }}
                  >
                    <IconButton color={"primary"} size="large">
                      <Edit fontSize="inherit" />
                    </IconButton>
                  </SecuredNavLink>
                )}
              </Grid>
            </Grid>
          </Toolbar>
          <Grid
            container
            justifyContent="center"
            sx={{ width: "100%", p: 2, gap: 2 }}
          >
            <Paper container className={classes.paper} component={Grid}>
              <KeyValuePair
                title={t("username")}
                value={data?.user?.username}
              />
              <KeyValuePair
                title={t("active")}
                value={
                  data?.user?.active ? (
                    <Icon className={classes.iconColor}>
                      check_circle_outline
                    </Icon>
                  ) : (
                    <Icon color="error">highlight_off</Icon>
                  )
                }
              />
              <KeyValuePair
                title={t("role")}
                value={
                  <SpanLink pathname={`/admin/roles/${data?.user?.role?.id}`}>
                    {data?.user?.role?.name}
                  </SpanLink>
                }
              />

              <KeyValuePair
                title={t("account")}
                // value={data?.user?.account?.name}
                value={
                  data?.user?.account ? (
                    <SpanLink
                      pathname={
                        data?.user?.role.code === "CSTMR"
                          ? `/admin/customers/${data?.user?.account?.id}`
                          : `/admin/delivery-agents/${data?.user?.account?.id}`
                      }
                    >
                      {data?.user?.account?.name}
                    </SpanLink>
                  ) : (
                    "--"
                  )
                }
              />
            </Paper>
          </Grid>
          <BranchesTable branches={userBranches} />
        </Fragment>
      )}
    </StyledGrid>
  );
};

export default UserView;
