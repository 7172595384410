import React, { Fragment, useEffect, useRef, useState } from "react";
import Popover from "@mui/material/Popover";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import {
  LIST_CONSIGNEES_DROPDOWN,
  LIST_CUSTOMERS_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import * as gqlb from "gql-query-builder";
import { IconButton, List, ListItem, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { matchSorter } from "match-sorter";
import { PersonAddOutlined } from "@mui/icons-material";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import CustomTab from "../HOC/FunctionComponents/CustomTab";
import { Globals } from "../HOC/Classes/Globals";

const CUSTOMERID = gqlb.query({
  operation: "customer",
  fields: [
    "id",
    "name",
    "address",
    "phone",
    "mobile",
    {
      zone: ["id", "name"],
    },
    {
      subzone: ["id", "name"],
    },
  ],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

const CONSIGNEE = gqlb.query({
  operation: "consignee",
  fields: [
    "id",
    "name",
    "address",
    "phone",
    "mobile",
    {
      zone: ["id", "name"],
    },
    {
      subzone: ["id", "name"],
    },
  ],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
export default function RecipientListPopover(props) {
  const {
    id,
    anchorEl,
    handleClose,
    onCompleted,
    disabled,
    handleOpenPopover,
    consigneeVariables,
  } = props;
  const [customers, setCustomers] = useState([]);
  const [recipients, setRecipients] = useState([]);
  const popoverAction = useRef();
  const open = Boolean(anchorEl);
  const { t } = useTranslation();
  const user = Globals.user;
  const customerPermission = user.hasPermission(
    "shipping.shipment.choose_customer"
  );

  const { data } = useQuery(
    gql`
      ${LIST_CUSTOMERS_DROPDOWN.query}
    `,
    {
      nextFetchPolicy: "no-cache",
      fetchPolicy: "no-cache",
      skip: !customerPermission,
      onCompleted: (data) => {
        setCustomers(data?.listCustomersDropdown);
      },
    }
  );

  const { data: recipientData } = useQuery(
    gql`
      ${LIST_CONSIGNEES_DROPDOWN.query}
    `,
    {
      nextFetchPolicy: "no-cache",
      fetchPolicy: "no-cache",
      skip: customerPermission && !consigneeVariables.customerId,
      variables: {
        input: {
          ...(customerPermission && {
            customerId: consigneeVariables.customerId,
          }),
          ...(consigneeVariables?.countryId && {
            countryId: consigneeVariables.countryId,
          }),
        },
      },
      onCompleted: (data) => {
        setRecipients(data?.listConsigneesDropdown);
      },
    }
  );

  const [getCustomer, { loading }] = useLazyQuery(
    gql`
      ${CUSTOMERID.query}
    `,
    {
      nextFetchPolicy: "no-cache",
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        onCompleted(data);
      },
    }
  );

  const [getConsignee, { loading: consigneeLoading }] = useLazyQuery(
    gql`
      ${CONSIGNEE.query}
    `,
    {
      nextFetchPolicy: "no-cache",
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        onCompleted(data);
      },
    }
  );

  const customerTap = (
    <List>
      <ListItem>
        <TextField
          variant="standard"
          autoFocus
          label={t("customer")}
          fullWidth
          onChange={(e) => {
            const value = e.target.value;
            const filterdCustomer = matchSorter(
              data?.listCustomersDropdown,
              value,
              {
                keys: ["name", "code"],
              }
            );
            popoverAction.current.updatePosition();
            setCustomers(filterdCustomer);
          }}
        />
      </ListItem>

      {customers.slice(0, 6).map((item, index) => {
        return (
          <ListItem
            button
            onClick={() => {
              getCustomer({ variables: { id: item.id } });

              handleClose();
            }}
            key={index}
          >
            <Fragment>
              <span>{item.code}</span>
              <span style={{ padding: "0 5px" }}> {" | "} </span>
              <span>{item.name}</span>
            </Fragment>
          </ListItem>
        );
      })}
    </List>
  );
  const recipientTap = (
    <List>
      <ListItem>
        <TextField
          variant="standard"
          autoFocus
          label={t("recipient")}
          fullWidth
          onChange={(e) => {
            const value = e.target.value;
            const filterdCustomer = matchSorter(
              recipientData?.listConsigneesDropdown,
              value,
              {
                keys: ["name", "code"],
              }
            );
            popoverAction.current.updatePosition();
            setRecipients(filterdCustomer);
          }}
        />
      </ListItem>

      {recipients &&
        recipients.slice(0, 6).map((item, index) => {
          return (
            <ListItem
              button
              onClick={() => {
                getConsignee({ variables: { id: item.id } });

                handleClose();
              }}
              key={index}
            >
              <Fragment>
                <span>{item.name}</span>
              </Fragment>
            </ListItem>
          );
        })}
    </List>
  );

  const tapDetails = [
    {
      tabHead: t("customer"),
      panel: customerTap,
    },
    {
      tabHead: t("recipient"),
      panel: recipientTap,
    },
  ];

  return (
    <Fragment>
      <IconButton
        disabled={disabled}
        onClick={(e) => {
          handleOpenPopover(e);
          customerPermission && setCustomers(data?.listCustomersDropdown);
          setRecipients(recipientData?.listConsigneesDropdown);
        }}
        size="large"
      >
        {loading || consigneeLoading ? (
          <ButtonLoading />
        ) : (
          <PersonAddOutlined />
        )}
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        action={popoverAction}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {customerPermission ? (
          <CustomTab tapDetails={tapDetails} />
        ) : (
          recipientTap
        )}
      </Popover>
    </Fragment>
  );
}
