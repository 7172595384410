import React, { useEffect, useRef, useState } from "react";

import { styled } from "@mui/material/styles";

import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Icon,
  IconButton,
  Paper,
  SwipeableDrawer,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";

import { gql, useMutation, useQuery } from "@apollo/client";
import {
  AddCircleOutline,
  AttachMoney,
  Done,
  DoneAll,
  Print,
} from "@mui/icons-material";
import { Alert } from "@mui/material";
import clsx from "clsx";
import moment from "moment";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import emptyList from "../../assets/Image/received.png";
import {
  APPROVE_INVOICES_MUTATION,
  GL_APPROVE_INVOICES_MUTATION,
  INVOICES_BY_ID_QUERY,
  SAVE_INVOICES_MUTATION,
} from "./InvoicesListQuary";
import ShipmentList from "./InvoicesShipments/ShipmentList";
// import ControlMUItextField from '../HOC/MUI/ControlMUItextField';
import Grid from "@mui/material/Unstable_Grid2";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useHistory, withRouter } from "react-router";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import CustomButton from "../HOC/MUI/CustomButton";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";

import NotFound from "../../Error/NotFound";
import {
  LIST_CUSTOMERS_DROPDOWN,
  LIST_TRANSACTION_TYPES_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import useWidth from "../../Hooks/useWidth";
import ListBranches from "../HOC/ComponentWithSpecificQuery/ListBranches";
import ShipmentsTable from "../HOC/CustomComponents/ShipmentsTable";
import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";
import { pushUrl, windowReplaceUrl } from "../HOC/CustomFunctions/pushUrl";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import MUIDate from "../HOC/MUI/MUIDate";
import useInvoicesDetails from "./InvoicesDetails";
import BarcodeSelector from "./InvoicesShipments/BarcodeSelector";

const PREFIX = "InvoicesForm";

const classes = {
  track: `${PREFIX}-track`,
  typo: `${PREFIX}-typo`,
  mainTracking: `${PREFIX}-mainTracking`,
  paper: `${PREFIX}-paper`,
  appBar: `${PREFIX}-appBar`,
  title: `${PREFIX}-title`,
  textAlign: `${PREFIX}-textAlign`,
  divider: `${PREFIX}-divider`,
  table: `${PREFIX}-table`,
  filterAnchor: `${PREFIX}-filterAnchor`,
  shipmentList: `${PREFIX}-shipmentList`,
  button: `${PREFIX}-button`,
  glApprovedButton: `${PREFIX}-glApprovedButton`,
  toolbar: `${PREFIX}-toolbar`,
  selectPadding: `${PREFIX}-selectPadding`,
  margin: `${PREFIX}-margin`,
  titles: `${PREFIX}-titles`,
  root: `${PREFIX}-root`,
  addIcon: `${PREFIX}-addIcon`,
  errorColor: `${PREFIX}-errorColor`,
  overlay: `${PREFIX}-overlay`,
  pagination: `${PREFIX}-pagination`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.track}`]: {
    marginTop: theme.spacing(2),
  },

  [`& .${classes.typo}`]: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    fontWeight: 600,
  },

  [`& .${classes.mainTracking}`]: {
    margin: theme.spacing(1, 0),
    width: "100%",
  },

  [`& .${classes.paper}`]: {
    marginBottom: theme.spacing(2),
    position: "relative",
    minHeight: "327px",
    gridAutoRows: "max-content",
  },

  [`& .${classes.appBar}`]: {
    position: "relative",
  },

  [`& .${classes.title}`]: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },

  [`& .${classes.textAlign}`]: {
    textAlign: "justify",
    padding: theme.spacing(1),
  },

  [`& .${classes.divider}`]: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
      height: "1px",
    },
  },

  [`& .${classes.table}`]: {
    display: "grid",
    width: "100%",
  },

  [`& .${classes.filterAnchor}`]: {
    [theme.breakpoints.down("md")]: {
      width: "auto",
    },
    // overflow: "scroll",
    "& .MuiDrawer-paper": {
      [theme.breakpoints.up("sm")]: {
        // width: "50%"
      },
      top: "auto",
      width: "25%",
      border: "1px solid #0000001f",
      height: "100%",
      overflow: "hidden",
    },
  },

  [`& .${classes.shipmentList}`]: {
    width: "100%",
    height: "100%",
    overflow: "hidden",
  },

  [`& .${classes.button}`]: {
    margin: theme.spacing(1),
  },

  [`& .${classes.glApprovedButton}`]: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.info.main,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.info.dark,
    },
  },

  [`& .${classes.toolbar}`]: {
    backgroundColor: "#fff",
    border: "solid 1px #eee",
    zIndex: "1300",
    justify: "space-between",
  },

  [`& .${classes.selectPadding}`]: {
    paddingTop: "6px",
    paddingBottom: "5px",
  },

  [`& .${classes.margin}`]: {
    margin: theme.spacing(1, 2),
  },

  [`& .${classes.titles}`]: {
    flex: "1 1 100%",
  },

  [`& .${classes.root}`]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },

  [`& .${classes.addIcon}`]: {
    color: theme.palette.success.main,
  },

  [`& .${classes.errorColor}`]: {
    color: theme.palette.error.main,
  },

  [`& .${classes.overlay}`]: {
    backgroundColor: "#ffffffad",
    position: "absolute",
    zIndex: 2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    top: 0,
    right: 0,
  },

  [`& .${classes.pagination}`]: {
    position: "absolute",
    bottom: 0,
    right: 0,
  },
}));

const EnhancedTableToolbar = (props) => {
  const { t } = useTranslation();
  const { selectedShipment, addShipment, approved, barcode } = props;

  return (
    <Toolbar className={clsx(classes.root)}>
      <Typography
        className={classes.titles}
        color="inherit"
        variant="subtitle1"
        component="div"
      >
        {t("shipmentsNumber")} {selectedShipment.length}
      </Typography>

      <IconButton
        aria-label="Add"
        className={classes.addIcon}
        onClick={addShipment}
        disabled={approved}
        size="large"
      >
        <AddCircleOutline />
      </IconButton>
      {barcode ? (
        <IconButton
          aria-label="Add"
          onClick={barcode}
          disabled={approved}
          size="large"
        >
          <Icon>qr_code_scanner</Icon>
        </IconButton>
      ) : null}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  selectedShipment: PropTypes.array.isRequired,
};

/////////////////DIALOG///////////////

const dateFormat = (date) => moment(date).locale("en").format("YYYY-MM-DD");

const InvoicesForm = (props) => {
  const { t } = useTranslation();

  const [autocompleteValues, setAutocompleteValues] = useState({
    branch: null,
    customer: null,
    transactionType: null,
  });

  const theme = useTheme();
  const [disableForm, setDisableForm] = useState(false);
  const [openDialog, setOpenDialog] = useState({
    open: false,
    edit: false,
    approve: false,
    glApprove: false,
  });
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);
  const [pickedShipment, setPickedShipment] = useState({ shipments: [] });
  const invoiceShipments = pickedShipment.shipments;
  const [invoicesData, setInvoicesData] = useState();

  const [fieldsState, setfieldsState] = useState({
    createdAt: new Date(),
  });
  const { enqueueSnackbar } = useSnackbar();

  const disabledFirstRender = useRef(false);

  const {
    formState: { errors },

    handleSubmit,
    setError,
    control,
    setValue,
    watch,
  } = useForm();

  const invoicesId = props.match.params.id;

  /////////////////////////////////////////////////////////////////////////////////////////////////////

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const [saveInvoiceMutation, { loading: saveInvoiceLoad }] = useMutation(
    gql`
      ${SAVE_INVOICES_MUTATION.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      onCompleted: (data) => {
        pickedShipment["shipments"] = invoiceShipments
          .filter((i) => !i["pendingDelete"])
          .map((i) => {
            // const incoivceData = data?.saveInvoice.find(
            //   (ele) => ele.shipment.id === i.shipment.id)
            const response = data?.saveInvoice.invoiceShipments.find(
              (ele) => ele.shipment.id === i.shipment.id
            );
            if (response) {
              return {
                ...i,
                deliveryFees: response.deliveryFees,
                postFees: response.postFees,
                tax: response.tax,
                allFees: response.allFees,
                // piecesTotal: incoivceData.pieces,
                // weightTotal: incoivceData.weight,
                // allFeesTotal: incoivceData.allFees,
                // postFeesTotal: incoivceData.postFees,
                // deliveryFeesTotal: incoivceData.deliveryFees,
                // taxTotal: incoivceData.tax,
              };
            } else {
              return i;
            }
          });
        setInvoicesData(data["saveInvoice"]);
      },
    }
  );
  const { details: invoicesDetails } = useInvoicesDetails({
    TotalData: invoicesData,
    approved: !invoicesData?.approved,
    invoiceShipments,
    setPickedShipment,
    saveInvoiceLoad,
  });

  const [approveInvoiceMutation, { loading: approveLoad }] = useMutation(
    gql`
      ${APPROVE_INVOICES_MUTATION.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      onCompleted: (data) => setInvoicesData(data["approveInvoice"]),
    }
  );

  const [glApproveInvoiceMutation, { loading: glApproveLoad }] = useMutation(
    gql`
      ${GL_APPROVE_INVOICES_MUTATION.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      onCompleted: (data) => setInvoicesData(data["glApproveInvoice"]),
    }
  );

  useEffect(() => {
    if (disabledFirstRender.current) {
      const shipments = invoiceShipments
        .filter((item) => {
          return !item.pendingDelete;
        })
        .map((item) => {
          return parseInt(item.shipment.id);
        });
      const variables = watch();
      variables["date"] = dateFormat(variables["date"]);

      saveInvoiceMutation({
        variables: {
          input: {
            ...variables,
            shipmentIds: shipments,
          },
        },
      })
        .then((data) => { })
        .catch(({ graphQLErrors }) => {
          setValidationError(graphQLErrors, setError);

          console.log(graphQLErrors);
        });
    }
    disabledFirstRender.current = true;
  }, [pickedShipment]);

  const { data: invoicesByIdQuery, loading: invoicesByIdLoad } = useQuery(
    gql`
      ${INVOICES_BY_ID_QUERY.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      skip: !invoicesId,
      variables: {
        id: parseInt(invoicesId),
      },
      onCompleted: (data) => {
        if (data?.invoice === null) return;
        const invoices = data?.invoice;

        setInvoicesData(invoices);
        setValue("id", parseInt(invoices?.id));
        setValue("code", invoices?.code);
        setValue("date", invoices?.date);

        invoices?.notes && setValue("notes", invoices?.notes);

        setfieldsState((prev) => ({
          ...prev,
          createdAt: invoices?.date,
        }));

        setAutocompleteValues({
          branch: invoices.branch,
          ...(invoices?.customer && {
            customer: invoices?.customer,
          }),

          transactionType: invoices.transactionType,
        });

        pickedShipment["shipments"] = data.invoice.invoiceShipments;
        setDisableForm(true);
      },
    }
  );

  const setSelect = (obj) => {
    setPickedShipment((prev) => {
      const shipments = [...prev.shipments, ...obj];
      // fireMutaion(shipments)
      return { shipments };
    });
  };

  const [state, setState] = useState({
    shipmentList: {
      left: false,
      bottom: false,
      right: false,
    },
  });

  const [barcodeDarwerState, setBarcodeDarwerState] = useState({
    left: false,
    bottom: false,
    right: false,
  });

  const shipmentDrawerHandler = (anchor, open) => {
    setState((prev) => ({
      ...prev,
      shipmentList: { ...prev.shipmentList, [anchor]: open },
    }));
  };

  const barcodeDrawerHandler = (anchor, open) => {
    setBarcodeDarwerState((prev) => ({
      ...prev,
      [anchor]: open,
    }));
  };
  const screenWidth = useWidth();

  const anchor = screenWidth === "xs" ? "bottom" : "right";
  const shipLength = invoiceShipments.length === 0;

  ////////////////////END DRAWER////////////////
  const history = useHistory();

  const onSubmit = (data) => {
    const formChange =
      invoicesData &&
      ((data?.customerId &&
        data?.customerId !== parseInt(invoicesData?.customer?.id)) ||
        (data?.branchId &&
          data?.branchId !== parseInt(invoicesData?.branch?.id)));

    if (invoiceShipments.length !== 0 && formChange) {
      setOpenDialog((prev) => ({ ...prev, open: true, edit: true }));
    } else {
      const shipments = invoiceShipments.map((item) =>
        parseInt(item.shipment.id)
      );

      data["date"] = dateFormat(data["date"]);

      saveInvoiceMutation({
        variables: {
          input: {
            ...data,
            shipmentIds: shipments,
          },
        },
      })
        .then((data) => {
          setDisableForm(true);
          setValue("code", data?.data?.saveInvoice?.code);
          setValue("id", parseInt(data?.data?.saveInvoice?.id));
          const url = history.createHref({
            pathname: `/admin/invoices/${data?.data?.saveInvoice?.id}/edit`,
          });
          windowReplaceUrl(url);
        })
        .catch(({ graphQLErrors }) => {
          console.log(graphQLErrors);
          setValidationError(graphQLErrors, setError);
        });
    }
  };

  const handleDialogClose = () => {
    setOpenDialog({ edit: false, open: false, approve: false });
  };

  const approveInvoice = () => {
    approveInvoiceMutation({
      variables: {
        id: parseInt(watch("id")),
      },
    })
      .then((data) => {
        handleDialogClose();
        enqueueSnackbar(t("approvedErrorMessage"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
        pushUrl(props, `/admin/invoices/${watch("id")}`);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const glApproveInvoice = () => {
    glApproveInvoiceMutation({
      variables: {
        id: parseInt(watch("id")),
      },
    })
      .then((data) => {
        handleDialogClose();
        enqueueSnackbar(t("successfullyGlApproved"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      })
      .catch(({ graphQLErrors }) => {
        console.log(graphQLErrors);
        handleDialogClose();

        enqueueSnackbar(graphQLErrors[0].message, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      });
  };
  const onApproved = () => {
    setOpenDialog((prev) => ({ ...prev, open: true, approve: true }));
  };
  const onGlApproved = () => {
    setOpenDialog((prev) => ({ ...prev, open: true, glApprove: true }));
  };
  // const disabledAddIcon = () => {
  //   const value = manifestType === "PKM" ? ["customerId", "branchId"] : ["deliveryAgentId", "branchId"]
  //   return !value.every(val => Object.keys(queryVariables.invoice).includes(val))
  // }

  const parseData = (data) => {
    return data;
  };

  let formBody = null;
  let tableBody = null;
  /////////////////////// Customer Shipments Table /////////////////////////////

  tableBody = (
    <Grid container xs={12} justifyContent="center" sx={{ width: "100%" }}>
      {/* *******Table******* */}
      <Paper className={clsx(classes.table, classes.paper)}>
        <EnhancedTableToolbar
          {...props}
          selectedShipment={invoiceShipments}
          addShipment={() => shipmentDrawerHandler(anchor, true)}
          barcode={() => barcodeDrawerHandler(anchor, true)}
          approved={invoicesData?.approved}
        />

        <TableFixedHeaderWraper>
          {invoicesDetails && (
            <ShipmentsTable
              page={page}
              rowsPerPage={rowsPerPage}
              data={invoiceShipments}
              headCells={invoicesDetails.tableHead}
              parseBodyCell={invoicesDetails.tableBody}
              total={invoicesDetails.tableTotal}
              sumTableNumber={invoicesDetails?.sumTableNumber}
              sum={true}
            />
          )}
        </TableFixedHeaderWraper>
        {invoiceShipments.length > 4 && (
          <MUITablePagination
            // classeName={classes.pagination}
            count={invoiceShipments.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}

        {(!watch("id") || !disableForm) && (
          <Box className={classes.overlay}>
            <Typography color="textPrimary" variant="body1">
              {t("saveManifestFirst")}
            </Typography>
          </Box>
        )}
        <Grid container justifyContent="center" alignItems="center">
          {invoiceShipments.length === 0 && watch("id") && disableForm && (
            <Box textAlign="center" marginTop="50px" marginBottom="50px">
              <img width="50%" src={emptyList} alt={"delivery box"} />
              <Box
                component={Typography}
                variant="h6"
                color="text.secondary"
                marginTop="0"
              >
                {t("noShipments")}
              </Box>
            </Box>
          )}
        </Grid>
      </Paper>
    </Grid>
  );

  formBody = (
    <Grid
      container
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      spacing={2}
      sx={{ padding: "24px", width: "100%", margin: "0" }}
    >
      {!invoicesByIdLoad ? (
        <Paper container component={Grid} sx={{ padding: 1.25, flexGrow: 1 }}>
          <Grid container sm={12} xs={12} justifyContent="space-between">
            <Typography color="inherit" variant="h6" component="div">
              {invoicesDetails.label}
            </Typography>
            {!shipLength && (
              <IconButton
                disabled={invoicesByIdLoad}
                onClick={() =>
                  window.open(
                    `${window.location.origin}/report/print/invoice/${watch(
                      "id"
                    )}`
                  )
                }
                color="default"
                size="large"
              >
                <Print fontSize="inherit" />
              </IconButton>
            )}
          </Grid>
          {watch("id") && disableForm && (
            <Grid sm={12} xs={12}>
              <Alert
                severity="warning"
                action={
                  !invoicesData?.approved && (
                    <Button
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setDisableForm(false);
                        // fireMutaion([])
                      }}
                    >
                      {t("update")}
                    </Button>
                  )
                }
              >
                {invoicesData?.approved
                  ? t("updateRecordsForbidden")
                  : t("updateRecord")}
              </Alert>
            </Grid>
          )}

          <Grid
            spacing={2}
            container
            sx={{ position: "relative", flexGrow: 1 }}
          >
            {(invoicesData?.approved || disableForm) && (
              <Box
                className={classes.overlay}
                sx={{ backgroundColor: "rgb(255 255 255 / 45%)" }}
              >
                {/* <Typography color="textPrimary" variant="body1" >لا يمكن التعديل</Typography> */}
              </Box>
            )}
            <Grid sm={4} xs={12}>
              <ControlMUItextField
                name="code"
                control={control}
                label={t("recordCode")}
                rules={{ required: watch("id") ? t("fieldIsRequired") : false }}

              // defaultValue={queryVariables?.invoice?.code}
              // disabled={invoicesByIdQuery?.invoice?.approved || disableForm}
              />
            </Grid>
            <Grid sm={4} xs={12}>
              <MUIDate
                name="date"
                label={t("date")}
                control={control}
                defaultValue={invoicesByIdQuery?.invoice?.date}
                value={fieldsState.createdAt}
                onChange={(e) =>
                  setfieldsState((prev) => ({ ...prev, createdAt: e }))
                }
              />
            </Grid>
            <Grid sm={4} xs={12}>
              <ListBranches
                control={control}
                errors={errors}
                name={"branchId"}
                rules={{ required: t("fieldIsRequired") }}
                defaultValue={autocompleteValues.branch}
                onChangeValue={(e) => {
                  setValue("customerId", "");
                  // setPickedShipment({ shipments: [] })
                }}
                skipDefaultBranch={Boolean(invoicesId)}
              />
            </Grid>

            <Grid sm={6} xs={12}>
              <CustomAutocomplete
                control={control}
                name={"customerId"}
                label={t("customer")}
                rules={{ required: t("fieldIsRequired") }}
                parseData={(data) => parseData(data)}
                query={LIST_CUSTOMERS_DROPDOWN.query}
                skip={!watch("branchId")}
                variables={{
                  input: {
                    withDue: "INVOICE",
                    branchId: {
                      value: watch("branchId"),
                      includeNull: true,
                    },
                  },
                }}
                defaultValue={autocompleteValues.customer}
              />
            </Grid>

            <Grid sm={6} xs={12}>
              <CustomAutocomplete
                control={control}
                errors={errors}
                name={"transactionTypeId"}
                label={t("transactionType")}
                selectFirst={true}
                rules={{ required: t("fieldIsRequired") }}
                parseData={(data) => parseData(data)}
                query={LIST_TRANSACTION_TYPES_DROPDOWN.query}
                variables={{
                  input: {
                    type: "SHPINV",
                  },
                }}
                defaultValue={autocompleteValues.transactionType}
              />
            </Grid>

            <Grid sm={12} xs={12}>
              <ControlMUItextField
                name="notes"
                control={control}
                label={t("notes")}
                rows={2}
              />
            </Grid>
          </Grid>

          <Grid sm={12} xs={12} container justifyContent="flex-end">
            {!disableForm && (
              <CustomButton
                customcolor={theme.palette.success.main}
                type="submit"
                className={classes.button}
                disabled={
                  invoicesData?.approved || disableForm || saveInvoiceLoad
                }
                variant="contained"
                size="medium"
                loading={saveInvoiceLoad}
                // className={classes.button}
                startIcon={!saveInvoiceLoad && <Done />}
              >
                {!saveInvoiceLoad && t("save")}
              </CustomButton>
            )}
            {disableForm && (
              <Button
                disabled={
                  invoiceShipments.length === 0 ||
                  invoicesData?.approved ||
                  saveInvoiceLoad
                }
                className={classes.button}
                variant="contained"
                size="medium"
                color="primary"
                onClick={onApproved}
                name="approved"
                startIcon={<DoneAll />}
              >
                {t("approve")}
              </Button>
            )}
            {invoicesData?.approved && (
              <Button
                disabled={invoicesData?.glApproved}
                className={classes.glApprovedButton}
                variant="contained"
                size="medium"
                onClick={onGlApproved}
                name="glApproved"
                startIcon={<AttachMoney />}
              >
                {t("glApprove")}
              </Button>
            )}
          </Grid>
        </Paper>
      ) : (
        <FullScreenLoading />
      )}
    </Grid>
  );
  return invoicesByIdQuery?.invoice === null ? (
    <NotFound />
  ) : (
    <Root>
      {props.children}

      {formBody}

      <Dialog
        open={openDialog.open}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"xs"}
      >
        <DialogTitle id="alert-dialog-title">
          {openDialog.edit && t("updateRecord")}
          {openDialog.approve && t("approveRecord")}
          {openDialog.glApprove && t("glApprove")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {openDialog.edit && t("updateRecordMessage")}
            {openDialog.approve && t("approveRecordConfirmationMessage")}
            {openDialog.glApprove && t("glApproveRecordMessage")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            {t("cancel")}
          </Button>
          <Button
            disabled={approveLoad || glApproveLoad}
            onClick={() => {
              openDialog.edit && handleDialogClose();
              openDialog.edit && setDisableForm(true);
              openDialog.edit && setPickedShipment({ shipments: [] });
              openDialog.approve && approveInvoice();
              openDialog.glApprove && glApproveInvoice();
            }}
            color="primary"
            autoFocus
          >
            {openDialog.edit && t("update")}
            {openDialog.approve && !approveLoad && t("approve")}
            {openDialog.glApprove && !glApproveLoad && t("glApprove")}
            {(approveLoad || glApproveLoad) && <ButtonLoading />}
          </Button>
        </DialogActions>
      </Dialog>

      <SwipeableDrawer
        variant="persistent"
        disableDiscovery={true}
        disableSwipeToOpen={true}
        anchor={anchor}
        open={state.shipmentList[anchor]}
        onClose={() => false}
        onOpen={() => shipmentDrawerHandler(anchor, true)}
        classes={{ paper: classes.shipmentList }}
        dir={theme.direction}
      >
        {state.shipmentList[anchor] && (
          <ShipmentList
            setSelected={(obj) => {
              setSelect(obj);
            }}
            selected={invoiceShipments}
            done={() => shipmentDrawerHandler(anchor, false)}
            variables={watch()}
          />
        )}
      </SwipeableDrawer>

      <SwipeableDrawer
        disableDiscovery={true}
        disableSwipeToOpen={true}
        anchor={anchor}
        open={barcodeDarwerState[anchor]}
        onClose={() => false}
        onOpen={() => barcodeDrawerHandler(anchor, true)}
        sx={{
          width: "auto",
          "& .MuiDrawer-paper": {
            width: { md: "50%" },

            height: "100%",
            overflow: "hidden",
          },
        }}
        dir={theme.direction}
      >
        {barcodeDarwerState[anchor] && <BarcodeSelector
          setSelected={(obj) => {
            setSelect(obj);
          }}
          selected={invoiceShipments}
          done={() => barcodeDrawerHandler(anchor, false)}
          variables={watch()}
        />}
      </SwipeableDrawer>

      <Grid
        container
        spacing={3}
        justifyContent="center"
        className={classes.mainTracking}
      >
        {!invoicesByIdLoad && tableBody}
      </Grid>
    </Root>
  );
};

export default withRouter(InvoicesForm);
