import { Chip } from "@mui/material";
import * as colors from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import React from "react";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";

const StyledChip = styled(Chip)(({ theme, colorcode }) => ({
  color: theme.palette.getContrastText(colorcode),
  backgroundColor: colorcode + "!important",
}));

export const ManifestStatusColor = (props) => {
  const color = {
    preparing: colors["amber"]["500"],
    inTransit: colors["orange"]["500"],
    receiving: colors["blue"]["500"],
    received: colors["green"]["500"],
  };

  return (
    <FixedTableCell className={props.className}>
      {props.code ? <StyledChip
        colorcode={color[props.code]}
        size="small"
        label={props.children}
      /> : null}
    </FixedTableCell>
  );
};
