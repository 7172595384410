import { IconButton, TableRow } from "@mui/material";
import { Delete } from "@mui/icons-material";
import React from "react";
import { useTranslation } from "react-i18next";
import CellLink from "../HOC/CustomComponents/CellLink";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import ShipmentRegion from "../HOC/CustomComponents/ShipmentRegion";
import CustomSpinner from "../HOC/FunctionComponents/CustomSpinner";

const useInvoicesDetails = ({
  approved,
  invoiceShipments,
  setPickedShipment: removeShipment,
  saveInvoiceLoad,
  TotalData,
}) => {
  const { t } = useTranslation();
  const deleteShipment = (invoiceShipment) => (
    <FixedTableCell component="th" scope="row">
      <IconButton
        size="small"
        disabled={!approved || saveInvoiceLoad}
        onClick={() => {
          invoiceShipments.forEach((i) => {
            if (i.shipment.id === invoiceShipment.shipment.id) {
              i["pendingDelete"] = true;
            }
          });

          removeShipment((prev) => ({
            shipments: invoiceShipments,
          }));
          // fireMutaion(filtered)
        }}
      >
        {invoiceShipment["pendingDelete"] ? (
          <CustomSpinner name="ClockLoader" size={20} />
        ) : (
          <Delete />
        )}
      </IconButton>
    </FixedTableCell>
  );

  /* --------------------------------- CUSTM --------------------------------- */
  const custmTableHead = [
    "#",
    "requestCode",
    "date",
    "recipient",
    "destination",
    "senderName",
    "source",
    "weight",
    "pieceCount",
    "deliveryFees",
    "postFees",
    "tax",
    "returningDueFees",
    removeShipment ? "" : null,
  ];

  const custmTableBody = (invoiceShipment, index) => {
    return (
      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
        <FixedTableCell>{index + 1}</FixedTableCell>
        <CellLink pathname={`/admin/shipments/${invoiceShipment.shipment.id}`}>
          {invoiceShipment.shipment.code}
        </CellLink>
        <FixedTableCell>{invoiceShipment.shipment?.date}</FixedTableCell>

        <FixedTableCell>
          <span style={{ display: "block" }}>
            {invoiceShipment?.shipment?.recipientName}
          </span>
          <span>{invoiceShipment?.shipment?.recipientMobile}</span>
        </FixedTableCell>

        <ShipmentRegion
          pathname={`/admin/zone/${invoiceShipment?.shipment.recipientZone.id}`}
          zone={invoiceShipment?.shipment.recipientZone?.name}
          subzone={invoiceShipment?.shipment?.recipientSubzone.name}
        />
        <FixedTableCell>
          <span style={{ display: "block" }}>
            {invoiceShipment?.shipment?.senderName}
          </span>
          <span>{invoiceShipment?.shipment?.senderMobile}</span>
        </FixedTableCell>

        <ShipmentRegion
          pathname={`/admin/zone/${invoiceShipment?.shipment.senderZone.id}`}
          zone={invoiceShipment?.shipment.senderZone?.name}
          subzone={invoiceShipment?.shipment?.senderSubzone.name}
        />

        <FixedTableCell>{invoiceShipment.shipment?.weight}</FixedTableCell>
        <FixedTableCell>{invoiceShipment.shipment?.piecesCount}</FixedTableCell>
        <FixedTableCell>{invoiceShipment?.deliveryFees}</FixedTableCell>
        <FixedTableCell>{invoiceShipment?.postFees}</FixedTableCell>
        <FixedTableCell>{invoiceShipment?.tax}</FixedTableCell>
        <FixedTableCell>
          {invoiceShipment?.allFees}
        </FixedTableCell>
        {removeShipment && deleteShipment(invoiceShipment)}
      </TableRow>
    );
  };

  // const totalTest = (invoice, index) => {
  //   return (
  //     <TableRow role="checkbox" tabIndex={-1}>
  //       <FixedTableCell
  //         colSpan={4}
  //       />
  //       <FixedTableCell variant="head">{t("total")}</FixedTableCell>
  //       <FixedTableCell variant="head">{invoice?.weight }</FixedTableCell>
  //       <FixedTableCell variant="head">{invoice?.pieces }</FixedTableCell>
  //       <FixedTableCell variant="head">{invoice?.deliveryFees }</FixedTableCell>
  //       <FixedTableCell variant="head">{invoice?.postFees }</FixedTableCell>
  //       <FixedTableCell variant="head">{invoice?.tax }</FixedTableCell>
  //       <FixedTableCell variant="head">{invoice?.allFees }</FixedTableCell>
  //     </TableRow>
  //   );
  // };
  const custmTableTotal = {
    weight: TotalData?.weight,
    piecesCount: TotalData?.pieces,
    deliveryFees: TotalData?.deliveryFees,
    postFees: TotalData?.postFees,
    tax: TotalData?.tax,
    returningDueFees: TotalData?.allFees,
  };
  /* --------------------------------- DLVBY --------------------------------- */

  const sumTableNumber = (shipment, tableTotal) => {
    Object.entries(tableTotal).forEach(([k, v]) => {
      k === "deliveryFees" || k === "postFees" || k === "tax"
        ? (tableTotal[k] += shipment[k] ?? 0)
        : (tableTotal[k] += shipment.shipment[k] ?? 0);
    });
  };

  /* --------------------------------- Manifests Object --------------------------------- */

  const details = {
    id: "1",
    customer: true,
    label: t("manifestDetails"),
    tableHead: custmTableHead,
    tableBody: custmTableBody,
    tableTotal: custmTableTotal,
    sumTableNumber,
    // totalTest:totalTest
  };

  return {
    details,
  };
};

export default useInvoicesDetails;
