import { AppBar, Grid, Toolbar } from "@mui/material";
import { styled } from '@mui/material/styles';
import React, { memo } from "react";

import { Fragment } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/Image/logo.png";
import LanguageIcons from "../Component/HOC/CustomComponents/LanguageIcons";
import GlobalSearch from "../Component/GlobalSearch/GlobalSearch";
import useWidth, { isWidthDown } from "../Hooks/useWidth";

const PREFIX = 'Header';

const classes = {
  root: `${PREFIX}-root`,
  appBar: `${PREFIX}-appBar`,
  drawerHeader: `${PREFIX}-drawerHeader`,
  lang: `${PREFIX}-lang`,
  centerItems: `${PREFIX}-centerItems`,
  logoImg: `${PREFIX}-logoImg`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    display: "flex",
  },

  [`& .${classes.appBar}`]: {
    boxShadow: "none",
    borderBottom: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.default,
  },

  [`& .${classes.drawerHeader}`]: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },

  [`& .${classes.lang}`]: {
    marginLeft: theme.spacing(1),
  },

  [`& .${classes.centerItems}`]: {
    display: "flex",
    alignItems: "center",
  },

  [`& .${classes.logoImg}`]: {
    maxHeight: 48,
    marginLeft: theme.spacing(1),
  }
}));

const Header = (props) => {
  const { MenuButton, Notification } = props;
  const screenWidth = useWidth();

  const isLogin = localStorage.getItem("token")
  const screenIsSmall = isWidthDown("sm", screenWidth);

  return (
    <Root className={classes.root}>
      <Fragment>
        <AppBar position="fixed" className={classes.appBar} sx={{ top: props.top }}>
          <Toolbar>
            <Grid container justifyContent="space-between">
              <Grid item xs={6} className={classes.centerItems}>
                {MenuButton && <MenuButton />}
                <Link to="/">
                  <img src={logo} alt="logo" className={classes.logoImg} />
                </Link>
              </Grid>

              <Grid
                item
                xs={6}
                container
                justifyContent="flex-end"
                className={classes.centerItems}
              >
                <Grid>
                  {isLogin && <GlobalSearch props={props.props} />}
                  <LanguageIcons />
                  {Notification && <Notification />}
                </Grid>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>

        {/* <div className={classes.drawerHeader} /> */}
      </Fragment>
    </Root>
  );
};

export default memo(Header);
