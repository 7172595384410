import * as gqlb from "gql-query-builder";
export const LISTMANIFESTS_QUERY = gqlb.query({
  operation: "listWarehouseManifests",
  fields: [
    {
      operation: "paginatorInfo",
      fields: [
        "count",
        "currentPage",
        "total",
        "firstItem",
        "hasMorePages",
        "lastItem",
        "lastPage",
        "perPage",
      ],
      variables: {},
    },
    {
      operation: "data",
      fields: [
        "code",
        "id",
        "notes",
        "date",
        "approved",
        // "shipmentsCount",
        {
          branch: ["id", "name"],
        },
        {
          referenceBranch: ["id", "name"],
        },
        {
          transactionType: ["id", "name"],
        },
        {
          customer: ["name", "id"],
        },
      ],
      variables: {},
    },
  ],
  variables: {
    input: {
      type: "ListWarehouseManifestFilterInput",
      required: true,
    },
    first: {
      type: "Int",
      required: true,
      value: 15,
    },
    page: {
      type: "Int",
      value: 1,
    },
  },
});

export const warehouseManifestFields = [
  "id",
  "date",
  "code",
  "approved",
  "notes",
  {
    fromManifest: ["id", "code"]
  },
  {
    referenceBranch: ["id", "name"],
  },
  {
    branch: ["id", "name"],
  },
  {
    updatedBy: ["id", "username"],
  },
  {
    createdBy: ["id", "username"],
  },
  {
    transactionType: [
      "id",
      "code",
      "name",
      {
        type: ["code", "name"],
      },
    ],
  },
  {
    customer: [
      "id",
      "name",
      "phone",
      "address",
      "code",
      {
        zone: ["name"],
      },
      {
        subzone: ["name"],
      },
    ],
  },
  {
    operation: "manifestProducts",
    fields: [
      "quantity",
      {
        section: ["id", "name"]
      },
      {
        operation: "product",
        fields: [
          "id",
          "code",
          "name",
        ],
        variables: {},
      },
    ],
    variables: {},
  },
];
export const MANIFEST_BY_ID_QUERY = gqlb.query({
  operation: "warehouseManifest",
  fields: warehouseManifestFields,
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
export const MANIFEST_BY_ID_AND_PRINT = gqlb.query([
  {
    operation: "warehouseManifest",
    fields: warehouseManifestFields,
    variables: {
      id: {
        type: "Int",
        required: true,
      },
    },
  },
  {
    operation: "organization",
    fields: [
      "name",
      "phone",
      "email",
      "website",
      "reportHeader",
      "reportFooter",
    ],
    variables: {},
  },
]);

export const SAVE_MANIFEST_MUTATION = gqlb.mutation({
  operation: "saveWarehouseManifest",
  fields: warehouseManifestFields,
  variables: {
    input: {
      type: "WarehouseManifestInput",
      required: true,
    },
  },
});
export const DELETE_MANIFEST_MUTATION = gqlb.mutation({
  operation: "deleteWarehouseManifest",
  fields: [],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
export const APPROVE_MANIFEST_MUTATION = gqlb.mutation({
  operation: "approveWarehouseManifest",
  fields: warehouseManifestFields,
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const DELIVERY_AGENT_ID = gqlb.query({
  operation: "deliveryAgent",
  fields: [
    {
      route: ["name", "id"],
    },
  ],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
