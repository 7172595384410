import React, { useRef, useState } from "react";

import { styled } from "@mui/material/styles";

import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Unstable_Grid2";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import { useTranslation } from "react-i18next";
import CellLink from "../HOC/CustomComponents/CellLink";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import ShipmentRegion from "../HOC/CustomComponents/ShipmentRegion";
import { TableCellColor } from "../HOC/CustomComponents/TableCellColor";
import SearchTable from "../ShipmentSearch/SearchTable";
import PieCharts from "./PieCharts";
// import Statistics from "./Statistics";
// import StatisticsFilterHeader from "./StatisticsFilterHeader";
import useWidth from "../../Hooks/useWidth";
import { CustomerServices, PendingApproval } from "./StatisticsCard";
import { Globals } from "../HOC/Classes/Globals";
import { BiSupport } from "react-icons/bi";

const PREFIX = "Home";

const classes = {
  card: `${PREFIX}-card`,
  cardContent: `${PREFIX}-cardContent`,
  statistics: `${PREFIX}-statistics`,
  select: `${PREFIX}-select`,
  tableRow: `${PREFIX}-tableRow`,
  shipmentTable_code: `shipmentTable_code`,
  shipmentTable_shipmentStatus: `shipmentTable_shipmentStatus`,
  container: `${PREFIX}-container`,
  pieChart: `${PREFIX}-pieChart`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.card}`]: {
    width: "inherit",
    borderRadius: 12,
    boxShadow:
      "0px 3px 5px -1px rgb(0 0 0 / 5%), 0px 6px 10px 0px rgb(0 0 0 / 5%), 0px 1px 18px 0px rgb(0 0 0 / 5%)",
    minHeight: "150px"
  },

  [`& .${classes.cardContent}`]: {
    padding: "3px 16px",
  },

  [`& .${classes.statistics}`]: {
    flex: "1 1 100%",
    fontWeight: "600",
  },

  [`& .${classes.select}`]: {
    color: "#00000099",
  },

  [`& .${classes.tableRow}`]: {
    "&:hover": {
      "& .MuiTableCell-root": {
        backgroundColor: "#f5f5f5 !important",
      },
    },
  },


  [`& .${classes.shipmentTable_code}`]: {
    [theme.breakpoints.up("sm")]: {
      zIndex: "100",
      backgroundColor: theme.palette.background.paper,
      position: "sticky",
      top: "0",
      left: "0 !important",
    },
  },

  [`& .${classes.shipmentTable_shipmentStatus}`]: {
    [theme.breakpoints.up("sm")]: {
      zIndex: "100",
      backgroundColor: theme.palette.background.paper,
      position: "sticky",
      top: "0",
      right: "0 !important",
    },
  },

  [`& .${classes.container}`]: {
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.pieChart}`]: {
    "& .dxc-item": {
      "& g": {
        cursor: "pointer",
      },
    },
    "& .dxc-labels-group": {
      "& g g g text": {
        cursor: "pointer !important",
      },
    },
    "& .dxc-series-group": {
      "& g g path": {
        cursor: "pointer !important",
      },
    },
  },
}));

const formatDate = (date) => moment(date).locale("en").format("YYYY-MM-DD");
const initToDate = moment(new Date()).locale("en").format("YYYY-MM-DD");

const Home = (props) => {
  const week = useRef(
    moment(new Date()).locale("en").subtract(7, "day").format("YYYY-MM-DD")
  );
  const month = useRef(
    moment(new Date()).locale("en").subtract(30, "day").format("YYYY-MM-DD")
  );

  const [queryVariablesStatistics, setQueryVariablesStatistics] = useState({
    fromDate: month.current,
    toDate: moment(new Date()).locale("en").format("YYYY-MM-DD"),
    statusCode: null,
  });
  const [queryVariablesPieCharts, setQueryVariablesPieCharts] = useState({
    fromDate: month.current,
    toDate: moment(new Date()).locale("en").format("YYYY-MM-DD"),
    statusCode: null,
  });
  const [page, setPage] = useState(0);
  const [focused, setFocused] = useState({
    total: true,
  });

  const { t } = useTranslation();

  const onDateRangeAccept = (date, closeDateRangeDialog, section) => {
    section === 'pieChart' && setQueryVariablesPieCharts((prev) => ({
      ...prev,
      fromDate: formatDate(date[0]),
      toDate: formatDate(date[1]),
    }));
    section === 'Statistics' && setQueryVariablesStatistics((prev) => ({
      ...prev,
      fromDate: formatDate(date[0]),
      toDate: formatDate(date[1]),
    }));
    setPage(0);
    closeDateRangeDialog();
  };

  const handleDateRange = (value, setSelectValue, setDateRangeDialog, section) => {
    let defaultParamter = {
      toDate: initToDate,
    };

    switch (value) {
      case "month":
        setSelectValue("month");
        defaultParamter.fromDate = month.current;
        break;
      case "week":
        setSelectValue("week");
        defaultParamter.fromDate = week.current;
        break;
      case "all":
        setSelectValue("all");
        break;

      case "period":
      default:
        setSelectValue("period");

        return setDateRangeDialog(true);
    }

    section === 'pieChart' && setQueryVariablesPieCharts((prev) => ({
      statusCode: prev.statusCode,
      ...defaultParamter,
    }));
    section === 'Statistics' && setQueryVariablesStatistics((prev) => ({
      statusCode: prev.statusCode,
      ...defaultParamter,
    }));
    setPage(0);
  };

  const handleStatus = (value, delivered) => {
    delete queryVariablesPieCharts.delivered
    console.log(delivered);
    setQueryVariablesPieCharts((prev) => ({
      ...prev,
      statusCode: value
    }
    ));
    setPage(0);
  };

  //////////Table Function////////////

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  let DOM = null;
  /////////////////////////
  const screenWidth = useWidth();

  const width = Boolean(
    screenWidth === "md" || screenWidth === "xs" || screenWidth === "sm"
  );
  const queryFields = [
    "id",
    "code",
    "date",
    "description",
    "senderName",
    "recipientName",
    "recipientMobile",
    "refNumber",
    {
      lastDeliveryAgent: ["id", "name"],
    },
    {
      customer: ["id", "name"],
    },
    {
      senderZone: ["id", "name"],
    },
    {
      senderSubzone: ["name"],
    },
    {
      status: ["name", "code"],
    },
  ];
  const brifeCells = [
    "code",
    "date",
    "packageDescription",
    "customer",
    "senderName",
    "recipient",
    "source",
    "phone",
    "shippingAgent",
    "shipmentStatus",
  ];
  const tableBody = (shipment, index) => (
    <TableRow className={classes.tableRow} key={index}>
      <CellLink
        className={classes.shipmentTable_code}
        pathname={`/admin/shipments/${shipment.id}`}
      >
        {shipment.code}
      </CellLink>
      <FixedTableCell>{moment(shipment.date).format("ll")}</FixedTableCell>
      <FixedTableCell>{shipment.description}</FixedTableCell>
      <CellLink pathname={`/admin/customers/${shipment.customer.id}`}>
        {shipment.customer.name}
      </CellLink>
      <FixedTableCell>{shipment.senderName}</FixedTableCell>
      <FixedTableCell>{shipment.recipientName}</FixedTableCell>
      <ShipmentRegion
        pathname={`/admin/zone/${shipment?.senderZone?.id}`}
        zone={shipment.senderZone?.name}
        subzone={shipment.senderSubzone?.name}
      />

      <FixedTableCell>{shipment.recipientMobile}</FixedTableCell>

      <CellLink
        pathname={`/admin/delivery-agents/${shipment.lastDeliveryAgent?.id}`}
      >
        {shipment.lastDeliveryAgent?.name}
      </CellLink>
      <TableCellColor
        code={shipment.status.code}
        className={classes.shipmentTable_shipmentStatus}
        sx={{ backgroundColor: "white" }}
      >
        {shipment.status.name}
      </TableCellColor>
    </TableRow>
  );

  const user = Globals.user
  const support = Globals.settings.support

  const colBig = [
    {
      id: 0,
      order: 1,
      component:
        <PieCharts
          onDateRangeAccept={onDateRangeAccept}
          handleDateRange={handleDateRange}
          dateRange={{
            fromDate: queryVariablesPieCharts?.fromDate,
            toDate: queryVariablesPieCharts?.toDate.toString(),
          }}
          onFocus={(filterType, delivered) => {
            handleStatus(filterType, delivered);
            // setFocused(filterType);
          }}
          classNamePieChart={classes.pieChart}
        />
    },
    {
      id: 1,
      order: 4,
      component:
        <SearchTable
          {...props}
          className={classes}
          onPageChange={handleChangePage}
          page={page}
          queryVariables={queryVariablesPieCharts}
          rowsPerPage={10}
          brife={true}
          sum={false}
          tableHeadCell={brifeCells}
          tableBody={tableBody}
          queryFields={queryFields}
        />
    },
  ]
  const colSmall = [
    {
      id: 0,
      order: 2,
      component:
        <PendingApproval
          {...props}
        />
    },
  ]

  if (support) {
    const newElement = {
      id: 1,
      order: 3,
      component:
        <CustomerServices
          {...props}
        />
    }
    colSmall.splice(1, 0, newElement);
  }

  let arr = colBig.concat(colSmall);
  arr.sort((a, b) => a.order - b.order)

  DOM = (
    <Grid
      container
      justifyContent={width ? "center" : "space-between"}
      alignContent="space-between"
      className={classes.container}
      spacing={2}
      paddingTop={2}
    >
      {!["md", "sm", "xs"].includes(screenWidth) && <Grid
        item
        container
        md={12}
        lg={9}
        // spacing={width ? 2 : 0}
        alignItems="stretch"
      >
        {colBig.map((item) =>
          <Grid item xs={12} key={item.id}>
            {item.component && item.component}
          </Grid>
        )}
      </Grid>}
      {!["md", "sm", "xs"].includes(screenWidth) && <Grid
        item
        container
        md={12}
        lg={3}
        alignItems="stretch"
        alignContent={"flex-start"}
      >
        {colSmall.map((item) =>
          <Grid item xs={12} key={item.id}>
            {item.component && item.component}
          </Grid>
        )}
      </Grid>
      }
      {["md", "sm", "xs"].includes(screenWidth) && <Grid
        item
        container
        md={12}
        alignItems="stretch"
        alignContent={"flex-start"}
      >
        {arr.map((item, index) =>
          <Grid item xs={12} key={index}>
            {item.component && item.component}
          </Grid>
        )}
      </Grid>}
    </Grid>
  );

  return (
    <Root>
      {props.children}

      <Container component="main" maxWidth="xl">
        {DOM}
      </Container>
    </Root>
  );
};

export default Home;
