import { IconButton, TableRow } from "@mui/material";
import { Delete } from "@mui/icons-material";
import React from "react";
import { useTranslation } from "react-i18next";
import CellLink from "../HOC/CustomComponents/CellLink";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import ShipmentRegion from "../HOC/CustomComponents/ShipmentRegion";
import CustomSpinner from "../HOC/FunctionComponents/CustomSpinner";
import { dateFormatLL } from "../../helpers/dateFunctions";

const usePaymentsDetails = ({
  type,
  approved,
  paymentShipments,
  setPickedShipment: removeShipment,
  savePaymentLoad,
  TotalData,
}) => {
  const { t } = useTranslation();
  const deleteShipment = (paymentShipment) => (
    <FixedTableCell component="th" scope="row">
      <IconButton
        size="small"
        disabled={!approved || savePaymentLoad}
        onClick={() => {
          paymentShipments.forEach((i) => {
            if (i.shipment.id === paymentShipment.shipment.id) {
              i["pendingDelete"] = true;
            }
          });

          removeShipment((prev) => ({
            shipments: paymentShipments,
          }));
          // fireMutaion(filtered)
        }}
      >
        {paymentShipment["pendingDelete"] ? (
          <CustomSpinner name="ClockLoader" size={20} />
        ) : (
          <Delete />
        )}
      </IconButton>
    </FixedTableCell>
  );

  /* --------------------------------- CUSTM --------------------------------- */
  const custmTableHead = [
    "#",
    "requestCode",
    "date",
    "deliveryCanclingDate",
    "recipient",
    "recipientMobile",
    "destination",
    "paymentType",
    "shipmentStatus",
    "weight",
    "pieceCount",
    "deliveredAmount",
    "collectedFees",
    "returningDueFees",
    "customerDueAmount",
    removeShipment ? "" : null,
  ];

  const custmTableBody = (paymentShipment, index) => {
    return (
      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
        <FixedTableCell>{index + 1}</FixedTableCell>
        <CellLink pathname={`/admin/shipments/${paymentShipment.shipment.id}`}>
          {paymentShipment.shipment.code}
        </CellLink>
        <FixedTableCell>
          {dateFormatLL(paymentShipment.shipment?.date)}
        </FixedTableCell>
        <FixedTableCell>
          {paymentShipment.shipment?.deliveredCanceledDate}
        </FixedTableCell>
        <FixedTableCell>
          <span style={{ display: "block" }}>
            {paymentShipment?.shipment?.recipientName}
          </span>
        </FixedTableCell>
        <FixedTableCell>
          <span>{paymentShipment?.shipment?.recipientMobile}</span>
        </FixedTableCell>

        <ShipmentRegion
          pathname={`/admin/zone/${paymentShipment?.shipment.recipientZone.id}`}
          zone={paymentShipment?.shipment.recipientZone?.name}
          subzone={paymentShipment?.shipment?.recipientSubzone.name}
        />
        <FixedTableCell>
          {paymentShipment.shipment?.paymentType?.name}
        </FixedTableCell>
        <FixedTableCell>{paymentShipment.shipment?.status.name}</FixedTableCell>
        <FixedTableCell>{paymentShipment.shipment?.weight}</FixedTableCell>
        <FixedTableCell>{paymentShipment.shipment?.piecesCount}</FixedTableCell>
        <FixedTableCell>
          {paymentShipment.shipment?.deliveredAmount}
        </FixedTableCell>
        <FixedTableCell>
          {paymentShipment.shipment?.collectedFees}
        </FixedTableCell>
        <FixedTableCell>
          {paymentShipment.shipment?.returningDueFees}
        </FixedTableCell>
        <FixedTableCell>{paymentShipment?.paidAmount}</FixedTableCell>
        {removeShipment && deleteShipment(paymentShipment)}
      </TableRow>
    );
  };

  const custmTableTotal = {
    weight: TotalData?.weight,
    piecesCount: TotalData?.piecesCount,
    deliveredAmount: TotalData?.deliveredAmount,
    collectedFees: TotalData?.collectedFees,
    returningDueFees: TotalData?.dueFees,
    paidAmount: TotalData?.paymentAmount,
  };
  /* --------------------------------- DLVBY --------------------------------- */
  const dlvbyTableHead = [
    "#",
    "requestCode",
    "deliveryCanclingDate",
    "senderName",
    "recipient",
    "destination",
    "address",
    "shipmentStatus",
    "deliveryType",
    "deservedAmount",
    removeShipment ? "" : null,
  ];
  const dlvbyTableBody = (paymentShipment, index) => {
    return (
      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
        <FixedTableCell>{index + 1}</FixedTableCell>
        <CellLink pathname={`/admin/shipments/${paymentShipment.shipment.id}`}>
          {paymentShipment.shipment.code}
        </CellLink>
        <FixedTableCell>
          {paymentShipment.shipment?.deliveredCanceledDate}
        </FixedTableCell>
        <FixedTableCell>{paymentShipment.shipment?.senderName}</FixedTableCell>
        <FixedTableCell>
          {paymentShipment.shipment?.recipientName}
        </FixedTableCell>

        <ShipmentRegion
          pathname={`/admin/zone/${paymentShipment.shipment.recipientZone.id}`}
          zone={paymentShipment.shipment.recipientZone?.name}
          subzone={paymentShipment.shipment.recipientSubzone.name}
        />
        <FixedTableCell>
          {paymentShipment.shipment?.recipientAddress}
        </FixedTableCell>
        <FixedTableCell>{paymentShipment.shipment?.status.name}</FixedTableCell>
        <FixedTableCell>
          {paymentShipment.shipment?.deliveryType?.name ??
            paymentShipment.shipment?.status.name}
        </FixedTableCell>
        <FixedTableCell>{paymentShipment.paidAmount}</FixedTableCell>
        {removeShipment && deleteShipment(paymentShipment)}
      </TableRow>
    );
  };
  const dlvbyTableTotal = {
    paidAmount: 0,
  };

  const sumTableNumber = (shipment, tableTotal) => {
    Object.entries(tableTotal).forEach(([k, v]) => {
      k === "paidAmount"
        ? (tableTotal[k] += shipment[k] ?? 0)
        : (tableTotal[k] += shipment.shipment[k] ?? 0);
    });
  };

  /* --------------------------------- Manifests Object --------------------------------- */

  const paymentsObject = {
    CUSTM: {
      id: "1",
      customer: true,
      label: t("manifestDetails"),
      tableHead: custmTableHead,
      tableBody: custmTableBody,
      tableTotal: custmTableTotal,
      sumTableNumber,
    },
    DLVBY: {
      id: "2",
      customer: true,
      label: t("manifestDetails"),
      tableHead: dlvbyTableHead,
      tableBody: dlvbyTableBody,
      tableTotal: dlvbyTableTotal,
      sumTableNumber,
    },
  };

  return {
    details: paymentsObject[type],
  };
};

export default usePaymentsDetails;
