import { IconButton, TableRow } from "@mui/material";
import { Delete } from "@mui/icons-material";
import React from "react";
import { useTranslation } from "react-i18next";
import CellLink from "../HOC/CustomComponents/CellLink";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import CustomSpinner from "../HOC/FunctionComponents/CustomSpinner";
import { dateFormatLL } from "../../helpers/dateFunctions";

const usePaymentPickupsDetails = ({
  type,
  approved,
  paymentPickups,
  setPickedPickups: removePickup,
  savePaymentLoad,
  TotalData,
}) => {
  const { t } = useTranslation();
  const deletePickup = (paymentPickup) => {
    return (
      <FixedTableCell component="th" scope="row">
        <IconButton
          size="small"
          disabled={!approved || savePaymentLoad}
          onClick={() => {
            paymentPickups.forEach((i) => {
              if (i.pickup.id === paymentPickup.pickup.id) {
                i["pendingDelete"] = true;
              }
            });

            removePickup((prev) => ({
              pickups: paymentPickups,
            }));
            // fireMutaion(filtered)
          }}
        >
          {paymentPickup["pendingDelete"] ? (
            <CustomSpinner name="ClockLoader" size={20} />
          ) : (
            <Delete />
          )}
        </IconButton>
      </FixedTableCell>
    )
  };
  /* --------------------------------- DLVBY --------------------------------- */
  const pickupTableHead = [
    "#",
    "requestCode",
    "date",
    "customer",
    "notes",
    "deservedAmount",
    removePickup ? "" : null,
  ];
  const pickupTableBody = (paymentPickup, index) => {
    return (
      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
        <FixedTableCell>{index + 1}</FixedTableCell>
        <CellLink pathname={`/admin/pickups/${paymentPickup.pickup.id}`}>
          {paymentPickup.pickup.code}
        </CellLink>
        <FixedTableCell>
          {dateFormatLL(paymentPickup?.pickup?.date)}
        </FixedTableCell>
        <CellLink pathname={`/admin/customers/${paymentPickup.pickup.customer.id}`}>
          {paymentPickup.pickup.customer.name}
        </CellLink>
        <FixedTableCell>
          {paymentPickup.pickup?.notes}
        </FixedTableCell>
        <FixedTableCell>{paymentPickup.paidAmount}</FixedTableCell>
        {removePickup && deletePickup(paymentPickup)}
      </TableRow>
    );
  };
  const pickupTableTotal = {
    paidAmount: 0,
  };

  const sumTableNumber = (pickup, tableTotal) => {
    Object.entries(tableTotal).forEach(([k, v]) => {
      k === "paidAmount"
        ? (tableTotal[k] += pickup[k] ?? 0)
        : (tableTotal[k] += pickup.pickup[k] ?? 0);
    });
  };

  /* --------------------------------- Manifests Object --------------------------------- */

  const paymentsObject = {
    DLVBY: {
      id: "2",
      customer: true,
      label: t("manifestDetails"),
      tableHead: pickupTableHead,
      tableBody: pickupTableBody,
      tableTotal: pickupTableTotal,
      sumTableNumber,
    },
  };

  return {
    details: paymentsObject[type],
  };
};

export default usePaymentPickupsDetails;
