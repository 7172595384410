import { TablePagination } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import TablePaginationActions from "./TablePaginationActions";

const MUITablePagination = (props) => {
  const {
    count,
    rowsPerPage,
    page,
    onPageChange,
    onRowsPerPageChange,
    rowsPerPageOptions,
    disableLastPage,
  } = props;

  const lang = localStorage.getItem("i18nextLng");
  return (
    <TablePagination
      sx={{
        "& .MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows":
          {
            margin: 0,
          },
      }}
      labelDisplayedRows={
        lang === "en"
          ? undefined
          : ({ from, to, count }) =>
              `${from}-${to} من ${count !== -1 ? count : ` أكثر من${to}`}`
      }
      labelRowsPerPage={lang === "en" ? undefined : "عدد الصفوف في الصفحة"}
      rowsPerPageOptions={rowsPerPageOptions ?? [20, 50, 100]}
      component="div"
      count={count ? count : 20}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={onPageChange}
      onRowsPerPageChange={onRowsPerPageChange}
      ActionsComponent={disableLastPage ? undefined : TablePaginationActions}
    />
  );
};

MUITablePagination.propTypes = {
  count: PropTypes.number,
  rowsPerPage: PropTypes.number,
  page: PropTypes.number,
  onPageChange: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
};

export default MUITablePagination;
