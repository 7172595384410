import React, { useEffect, useState } from "react";

import { styled } from "@mui/material/styles";

import { gql, useQuery } from "@apollo/client";
import { Add, Clear, FilterList, Search } from "@mui/icons-material";
import {
  Box,
  Button,
  Drawer,
  Grid,
  Icon,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import clsx from "clsx";
// import moment from "moment";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import {
  LIST_BRANCHES_DROPDOWN,
  LIST_CUSTOMERS_DROPDOWN,
  // LIST_PRICE_LIST_DROPDOWN,
  // LSIT_ZONES_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import useWidth from "../../Hooks/useWidth";
import { Globals } from "../HOC/Classes/Globals";
import CellLink from "../HOC/CustomComponents/CellLink";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import { SecuredNavLink } from "../HOC/CustomComponents/Secured";
// import ShipmentRegion from "../HOC/CustomComponents/ShipmentRegion";
import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";
import { windowUrl } from "../HOC/CustomFunctions/pushUrl";
import { urlParameters } from "../HOC/CustomFunctions/urlParameters";
import EmptyTableMessage from "../HOC/FunctionComponents/EmptyTableMessage";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import MUIselect from "../HOC/MUI/MUIselect";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import { LIST_PRODUCTS } from "./Graphql";

const PREFIX = "ProductList";

const classes = {
  button: `${PREFIX}-button`,
  iconColor: `${PREFIX}-iconColor`,
  btnMargin: `${PREFIX}-btnMargin`,
  mainSearch: `${PREFIX}-mainSearch`,
  titles: `${PREFIX}-titles`,
  toolbar: `${PREFIX}-toolbar`,
  searchForm: `${PREFIX}-searchForm`,
  searchField: `${PREFIX}-searchField`,
  overlay: `${PREFIX}-overlay`,
  searchPadding: `${PREFIX}-searchPadding`,
  drawer: `${PREFIX}-drawer`,
  drawerPaper: `${PREFIX}-drawerPaper`,
  drawerHeader: `${PREFIX}-drawerHeader`,
  content: `${PREFIX}-content`,
  contentShift: `${PREFIX}-contentShift`,
  tableRow: `${PREFIX}-tableRow`,
  shipmentTable_shipmentStatus: `${PREFIX}-shipmentTable_shipmentStatus`,
  shipmentTable_shipmentCode: `shipmentTable_shipmentCode`,
  tablePaper: `${PREFIX}-tablePaper`,
  table_status: `${PREFIX}-table_status`,
  table_code: `${PREFIX}-table_code`,
  toolbarHeader: `${PREFIX}-toolbarHeader`,
  tableBodyRow: `${PREFIX}-tableBodyRow`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.button}`]: {
    position: "sticky",
    bottom: 0,
    zIndex: 3,
    backgroundColor: "#fff",
    marginTop: 10,
    padding: 7,
  },

  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },

  [`& .${classes.btnMargin}`]: {
    width: "100%",
  },

  [`& .${classes.mainSearch}`]: {
    margin: theme.spacing(0),
    width: "100%",
  },

  [`& .${classes.titles}`]: {
    flex: "1 1 100%",
  },

  [`& .${classes.toolbar}`]: {
    borderBottom: "1px #ccd1d6 solid",
    backgroundColor: "#f5f7f9",
    width: "100%",
    // justifyContent: "space-between",
  },

  [`& .${classes.searchForm}`]: {
    overflowY: "auto",
    width: "100%",
    height: "100%",
    margin: 0,
  },

  [`& .${classes.searchField}`]: {
    padding: theme.spacing(1, 1, 0, 1),
    position: "relative",
    overflow: "hidden",
  },

  [`& .${classes.overlay}`]: {
    width: "100%",
    height: "100%",
    position: "absolute",
    backgroundColor: "#ffffffa8",
    zIndex: 2,
  },

  [`& .${classes.searchPadding}`]: {
    padding: theme.spacing(1),
  },

  //////////////////////Drawer///////////////////

  [`& .${classes.drawer}`]: {
    width: drawerWidth,
    flexShrink: 0,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },

  [`& .${classes.drawerPaper}`]: {
    width: drawerWidth,

    zIndex: "250",
    height: "100%",
    left: "initial",
    right: "initial",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },

  [`& .${classes.drawerHeader}`]: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },

  [`& .${classes.content}`]: {
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
  },

  [`& .${classes.contentShift}`]: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: "auto",
    width: "calc(100% - 240px)",
    [theme.breakpoints.down("sm")]: {
      margin: "auto",
      width: "auto",
    },
  },

  [`& .${classes.tableRow}`]: {
    "&:hover": {
      "& .MuiTableCell-root": {
        backgroundColor: "#f5f5f5",
      },
    },
  },

  [`& .${classes.shipmentTable_shipmentStatus}`]: {
    [theme.breakpoints.up("sm")]: {
      zIndex: "100",
      backgroundColor: "#fff",
      position: "sticky",
      top: "0",
      right: "0",
    },
  },

  [`& .${classes.shipmentTable_shipmentCode}`]: {
    [theme.breakpoints.up("sm")]: {
      zIndex: "100",
      backgroundColor: "#fff",
      position: "sticky",
      top: "0",
      left: "0em",
    },
  },

  [`& .${classes.tablePaper}`]: {
    borderRadius: 0,
    width: "100%",
    display: "grid",
  },

  [`& .${classes.table_status}`]: {
    [theme.breakpoints.up("sm")]: {
      zIndex: "100",
      backgroundColor: "#fff",
      position: "sticky",
      top: "0",
      right: "0",
    },
  },

  [`& .${classes.table_code}`]: {
    [theme.breakpoints.up("sm")]: {
      zIndex: "100",
      backgroundColor: "#fff",
      position: "sticky",
      top: "0",
      left: "0",
    },
  },

  [`& .${classes.toolbarHeader}`]: {
    flex: 1,
  },

  [`& .${classes.tableBodyRow}`]: {
    "&:hover": {
      "& .MuiTableCell-root": {
        backgroundColor: "#f5f5f5",
      },
    },
  },
}));

const drawerWidth = 240;

const ProductList = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const urlQuery = urlParameters(window.location.search);

  const validUrlParameters = Object.keys(urlQuery).length !== 0;

  const [showBranch, setShowBranch] = useState(null);

  const [autocompleteValues, setAutocompleteValues] = useState({
    branch: null,
    zone: null,
    subzone: null,
    customer: null
  });
  const screenWidth = useWidth();

  const [drawerState, setDrawerState] = React.useState({
    top: true,
    left: screenWidth === "xs" ? false : true,
    bottom: screenWidth === "xs" ? false : true,
    right: screenWidth === "xs" ? false : true,
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    // watch,
  } = useForm();

  const [rowsPerPage, setRowsPerPage] = useState(urlQuery["rowsPerPage"] ?? 20);
  const initSearch = () => {
    let searchParameters = {
      page: 0,
      refetch: true,
    };
    delete urlQuery["rowsPerPage"];
    if (validUrlParameters) {
      if (urlQuery["code"]) urlQuery["code"] = urlQuery["code"].toString();
      if (urlQuery["name"]) urlQuery["name"] = urlQuery["name"].toString();
      searchParameters = urlQuery;
    }
    return {
      ...searchParameters,
    };
  };
  const [search, setSearch] = useState(initSearch());

  useEffect(() => {
    urlQuery["active"] !== undefined && setValue("active", urlQuery["active"]);
    urlQuery["withAvailableStock"] !== undefined && setValue("withAvailableStock", urlQuery["withAvailableStock"]);
    urlQuery["withAvailableStock"] !== undefined && setShowBranch(urlQuery["withAvailableStock"]);
    urlQuery["name"] && setValue("name", urlQuery["name"]);
    urlQuery["code"] && setValue("code", urlQuery["code"]);

    return () => { };
  }, []);

  const history = useHistory();

  const handelAutocompleteDefaultValue = (data, id, fieldName) => {
    const defaultValue = data[Object.keys(data)[0]].find(
      (i) => i.id === parseInt(id)
    );
    defaultValue &&
      setAutocompleteValues((prev) => ({
        ...prev,
        [fieldName]: defaultValue,
      }));
  };
  const pushUrlSearch = (param) => {
    const queryParams = [];
    for (const i in param) {
      encodeURIComponent(param[i]) &&
        queryParams.push(
          encodeURIComponent(i) + "=" + encodeURIComponent(param[i])
        );
    }
    const queryString = queryParams.join("&");

    const url = history.createHref({
      pathname: "/admin/products",
      search: "?" + queryString,
    });
    windowUrl(url);
    //this will not effect on history.location.search
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      (event.type === "keydown" || event.type === "submit") &&
      (event.type === "submit" || event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerState({ ...drawerState, [anchor]: open });
  };
  const filterAnchor = screenWidth === "xs" ? "bottom" : "left";

  const onSubmit = (data) => {
    filterAnchor === "bottom" &&
      setDrawerState({ ...drawerState, [filterAnchor]: false });
    let handledData = {
      active: data.active,
      withAvailableStock: data.withAvailableStock,
      branchId: data.branchId,
      customerId: data.customerId,
      name: data.name,
      code: data.code,
      page: 0,
    };
    data["active"] === "" && delete handledData["active"];
    data["withAvailableStock"] === "" && delete handledData["withAvailableStock"];

    for (const key in handledData) {
      if (
        handledData[key] === undefined ||
        handledData[key] === "" ||
        handledData[key] === null
      ) {
        delete handledData[key];
      }
    }
    setSearch((prev) => ({
      ...handledData,
      refetch: !prev.refetch,
    }));
    for (const key in handledData) {
      if (handledData[key] === null) {
        delete handledData[key];
      }
    }
    pushUrlSearch({
      ...handledData,
      rowsPerPage: rowsPerPage,
    });
  };

  const parseData = (data) => {
    return data;
  };

  const handleChangePage = (event, newPage) => {
    pushUrlSearch({
      ...urlQuery,

      page: newPage,
      rowsPerPage: rowsPerPage,
    });

    setSearch((prev) => ({ ...prev, page: newPage }));
  };

  const handleChangeRowsPerPage = (event) => {
    pushUrlSearch({
      ...urlQuery,
      page: 0,
      rowsPerPage: +event.target.value,
    });

    setRowsPerPage(+event.target.value);
    setSearch((prev) => ({ ...prev, page: 0 }));
  };
  const customer = Globals.user.account;
  // const user = Globals.user;

  // const paymentDue = user.hasPermission("shipping.payment.create");
  // const queryBody = LIST_CUSTOMERS(paymentDue).query;
  const { data, loading } = useQuery(
    gql`
      ${LIST_PRODUCTS.query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: {
        first: rowsPerPage,
        page: search?.page + 1,
        ...(search.branchId && {
          branchId: search.branchId,
        }),
        input: {
          active: search.active,
          withAvailableStock: search.withAvailableStock,
          ...(search.code && { code: search.code }),
          ...(search.name && { name: search.name }),
          ...(search.customerId && { customerId: search.customerId, }),
          // ...(search.subzoneId && { subzoneId: search.subzoneId }),
          // ...(search.priceListId && { priceListId: search.priceListId }),
          // ...(search.withDue && { withDue: search.withDue }),
          ...(search.branchId && {
            branchId: search.branchId,
          }),
        },
      },
      onCompleted: (data) => { },
    }
  );
  const productList =
    data?.listProducts?.data !== null ? data?.listProducts?.data : null;
  return (
    <Root>
      <Drawer
        className={clsx(classes.drawer)}
        variant="persistent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor={filterAnchor}
        open={drawerState[filterAnchor]}
        onClose={() => false}
        dir={theme.direction}
      >
        <div className={classes.drawerHeader} />
        <Toolbar
          variant="dense"
          className={classes.toolbar}
          sx={{ marginBottom: 1 }}
        >
          <Typography
            className={classes.titles}
            color="inherit"
            variant="h6"
            component="div"
          >
            {t("search")}
          </Typography>
          <IconButton
            aria-label="done"
            size="small"
            onClick={toggleDrawer(filterAnchor, false)}
            color={"primary"}
          >
            <Clear />
          </IconButton>
        </Toolbar>

        <Grid
          container
          item
          justifyContent="center"
          alignContent="space-between"
          component={"form"}
          onSubmit={handleSubmit(onSubmit)}
          className={classes.searchForm}
        >
          <Grid
            container
            item
            sm={12}
            justifyContent="flex-start"
            spacing={1}
            className={classes.searchField}
          >
            <Grid container item sm={12} alignItems="flex-start">
              <ControlMUItextField
                control={control}
                errors={errors}
                name={"code"}
                label={t("code")}
              />
            </Grid>
            <Grid container item sm={12} alignItems="flex-start">
              <ControlMUItextField
                control={control}
                errors={errors}
                name={"name"}
                label={t("name")}
              />
            </Grid>
            <Grid container item sm={12} alignItems="flex-start">
              <MUIselect
                name={"active"}
                label={t("active")}
                control={control}
                errors={errors}
                data={[
                  { key: t("notActive"), value: false },
                  { key: t("active"), value: true },
                ]}
              />
            </Grid>
            <Grid container item sm={12} alignItems="flex-start">
              <MUIselect
                name={"withAvailableStock"}
                label={t("withAvailableStock")}
                control={control}
                errors={errors}
                data={[
                  { key: t("notAvailable"), value: false },
                  { key: t("available"), value: true },
                ]}
                onChanges={(e) => {
                  const value = e.target.value === '' ? null : e.target.value
                  e.target.value === '' && setValue("branchId", "")
                  setShowBranch(value)
                }}
              />
            </Grid>
            {showBranch !== null && (<Grid container item sm={12} alignItems="flex-start">
              <CustomAutocomplete
                control={control}
                errors={errors}
                name={"branchId"}
                label={t("branch")}
                onCompleted={(data) =>
                  handelAutocompleteDefaultValue(
                    data,
                    urlQuery["branchId"],
                    "branch"
                  )
                }
                parseData={(data) => parseData(data)}
                query={LIST_BRANCHES_DROPDOWN.query}
                defaultValue={autocompleteValues.branch}
              />
            </Grid>)}
            {!customer && (
              <Grid container item sm={12} alignItems="flex-start">
                <CustomAutocomplete
                  name={"customerId"}
                  label={t("customer")}
                  control={control}
                  errors={errors}
                  parseData={(data) => parseData(data)}
                  onCompleted={(data) =>
                    handelAutocompleteDefaultValue(
                      data,
                      urlQuery["customerId"],
                      "customer"
                    )
                  }
                  query={LIST_CUSTOMERS_DROPDOWN.query}
                  variables={(inputValue) => ({
                    input: {
                      active: true,
                    },
                  })}
                  defaultValue={autocompleteValues.customer}
                />
              </Grid>
            )}
          </Grid>
          <Grid
            container
            item
            sm={12}
            className={clsx(classes.searchPadding, classes.button)}
            alignItems="center"
            alignContent="flex-end"
            justifyContent="flex-end"
          >
            <Button
              className={clsx(classes.btnMargin)}
              variant="contained"
              color="primary"
              type="submit"
              size="large"
              startIcon={<Search />}
              disabled={loading}
            >
              {t("search")}
            </Button>
          </Grid>
        </Grid>
      </Drawer>

      <Grid
        container
        justifyContent="center"
        spacing={3}
        className={clsx(classes.content, classes.mainSearch, {
          [classes.contentShift]: drawerState[filterAnchor],
        })}
      >
        {/* *******Table******* */}

        <Toolbar className={clsx(classes.toolbar)} variant="dense">
          <Typography className={classes.toolbarHeader} variant="h6">
            {t("productList")}
          </Typography>
          <IconButton onClick={toggleDrawer(filterAnchor, true)} size="large">
            <FilterList fontSize="inherit" />
          </IconButton>
          <SecuredNavLink
            permission='shipping.product.create'
            to={{ pathname: "/admin/products/create" }}
          >
            <IconButton color={"primary"} size="large">
              <Add fontSize="inherit" />
            </IconButton>
          </SecuredNavLink>
        </Toolbar>

        {loading || productList?.length === 0 ? (
          <Grid container item justifyContent="center" className={classes.main}>
            {loading ? (
              <FullScreenLoading minHeight={30} />
            ) : (
              <EmptyTableMessage
                loading={false}
                message={t("searchNoResult")}
              />
            )}
          </Grid>
        ) : (
          <Paper className={classes.tablePaper}>
            <TableFixedHeaderWraper>
              <Table aria-labelledby="tableTitle" aria-label="enhanced table">
                <TableHead>
                  <TableRow>
                    <FixedTableCell>{t("name")}</FixedTableCell>
                    <FixedTableCell>{t("code")}</FixedTableCell>
                    <FixedTableCell>{t("quantity")}</FixedTableCell>
                    <FixedTableCell>{t("weight")}</FixedTableCell>
                    <FixedTableCell>{t("price")}</FixedTableCell>
                    {!customer && (<FixedTableCell>{t("customer")}</FixedTableCell>)}
                    <FixedTableCell>{t("active")}</FixedTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {productList &&
                    productList?.map((row, index) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.code}
                          className={classes.tableBodyRow}
                        >
                          <CellLink pathname={`/admin/products/${row?.id}`}>
                            {row?.name}
                          </CellLink>
                          <FixedTableCell>{row.code}</FixedTableCell>
                          <FixedTableCell>{row.availableQuantity}</FixedTableCell>
                          <FixedTableCell>{row.weight}</FixedTableCell>
                          <FixedTableCell>{row.price}</FixedTableCell>
                          {!customer && (<CellLink pathname={`/admin/customers/${row?.customer?.id}`}>
                            <Box
                              component="span"
                              fontWeight="bold"
                            >{` (${row?.customer?.code}) `}</Box>
                            <Box component="span">{row?.customer?.name}</Box>
                          </CellLink>)}
                          <FixedTableCell>
                            {row?.active ? (
                              <Icon className={classes.iconColor}>
                                check_circle_outline
                              </Icon>
                            ) : (
                              <Icon color="error">highlight_off</Icon>
                            )}
                          </FixedTableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableFixedHeaderWraper>
            <MUITablePagination
              count={data?.listProducts?.paginatorInfo?.total}
              rowsPerPage={rowsPerPage}
              page={!data?.listProducts ? 0 : search?.page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        )}
      </Grid>
    </Root>
  );
};

export default ProductList;
