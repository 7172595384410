import React from "react";
import { styled } from "@mui/material/styles";
import { gql, useQuery } from "@apollo/client";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { Chip, Grid, List, ListItemButton, ListItemIcon, ListItemText, Toolbar } from "@mui/material";
import * as gqlb from "gql-query-builder";
// import PropTypes from "prop-types";
import CountUp from "react-countup";
import CustomSpinner from "../HOC/FunctionComponents/CustomSpinner";
import clsx from "clsx";
import { Globals } from "../HOC/Classes/Globals";
import { VscPackage } from "react-icons/vsc";
import { BiPhoneCall, BiSupport } from "react-icons/bi";
import { FiUsers } from "react-icons/fi";
import { useTranslation } from "react-i18next";
import {
  RiMoneyDollarCircleLine,
} from "react-icons/ri";
import SpanLinkNoLine from "../HOC/CustomComponents/SpanLinkNoLine";
import { GoPackageDependencies } from "react-icons/go";
import { MdOutlineReportProblem } from "react-icons/md";
import StatisticsFilterHeader from "./StatisticsFilterHeader";
import { GetCustomerPermissionSlug } from "../../helpers/getManifestPermissionSlug";
import { MdOutlinePendingActions } from "react-icons/md";

const PREFIX = "StatisticsCard";

const classes = {
  root: `${PREFIX}-root`,
  focus: `${PREFIX}-focus`,
  card: `${PREFIX}-card`,
  cardContent: `${PREFIX}-cardContent`,
  typography: `${PREFIX}-typography`,
  outline: `${PREFIX}-outline`,
  listItem: `${PREFIX}-listItem`,
  numbers: `${PREFIX}-numbers`,
  fontSize: `${PREFIX}-fontSize`,
  text: `${PREFIX}-text`,
};

const StyledCard = styled(Card)(({ theme, ...props }) => ({
  [`& .${classes.root}`]: {
    display: "flex",
    flexWrap: "wrap",
  },

  [`&.${classes.focus}`]: {
    color: "black",
  },

  // [`&.${classes.card}`]: {
  //   width: "inherit",
  //   padding: 0,
  //   borderRadius: 12,
  //   boxShadow:
  //     "0px 3px 5px -1px rgb(0 0 0 / 5%), 0px 6px 10px 0px rgb(0 0 0 / 5%), 0px 1px 18px 0px rgb(0 0 0 / 5%)",
  //   // height: "100%",
  //   display: "flex",
  //   flexWrap: "wrap",
  //   flexDirection: "column",
  //   justifyContent: "flex-start",
  //   position: "relative",
  // },

  [`& .${classes.typography}`]: {
    fontFamily: "sans-serif",
    fontWeight: "600",
  },

  [`& .${classes.outline}`]: {
    fontFamily: "Material Icons Outlined",
  },
}));

const LisItemStyled = styled('div')(({ theme, ...props }) => ({
  [`& .${classes.numbers}`]: {
    "& > span": {
      padding: theme.spacing(0, 0.5),
      "& span": {
        fontWeight: 600,
      },
    },
    width: theme.spacing(6)
  },
  [`& .${classes.fontSize}`]: {
    fontWeight: 600,
    fontSize: theme.spacing(2.2),
    "& span::first-letter": {
      textTransform: "capitalize",
    }
  },
  [`& .${classes.text}`]: {
    "&::first-letter": {
      textTransform: "capitalize",
    }
  },
}));



export const lishShipments = {
  operation: "listShipments",
  fields: [
    {
      operation: "paginatorInfo",
      fields: ["total"],
      variables: {},
    },
  ],
  variables: {
    first: {
      type: "Int",
    },
    shipmentsInput: {
      name: "input",
      type: "ListShipmentsFilterInput"
    }
  },
}
export const listRegistrationsB2C = {
  operation: "listRegistrationsB2C:listRegistrations",
  fields: [
    {
      operation: "paginatorInfo",
      fields: ["total"],
      variables: {},
    },
  ],
  variables: {
    first: {
      type: "Int",
    },
    registrationsB2CInput: {
      name: "input",
      type: "ListRegistrationsFilterInput"
    }
  },
}
export const listRegistrationsC2C = {
  operation: "listRegistrationsC2C:listRegistrations",
  fields: [
    {
      operation: "paginatorInfo",
      fields: ["total"],
      variables: {},
    },
  ],
  variables: {
    first: {
      type: "Int",
    },
    registrationsC2CInput: {
      name: "input",
      type: "ListRegistrationsFilterInput"
    }
  },
}
export const listPickups = {
  operation: "listPickups",
  fields: [
    {
      operation: "paginatorInfo",
      fields: ["total"],
      variables: {},
    },
  ],
  variables: {
    first: {
      type: "Int",
    },
    pickupsInput: {
      name: "input",
      type: "ListPickupsFilterInput"
    }
  },
}
export const listTickets = {
  operation: "listTickets",
  fields: [
    {
      operation: "paginatorInfo",
      fields: ["total"],
      variables: {},
    },
  ],
  variables: {
    first: {
      type: "Int",
    },
    ticketsInput: {
      name: "input",
      type: "ListTicketsFilterInput"
    }
  },
}
export const returnRequests = {
  operation: "listReturnRequests:listCustomerRequests",
  fields: [
    {
      operation: "paginatorInfo",
      fields: ["total"],
      variables: {},
    },
  ],
  variables: {
    first: {
      type: "Int",
    },
    returnRequestsInput: {
      name: "input",
      type: "ListCustomerRequestFilterInput!"
    }
  },
}
export const paymentRequests = {
  operation: "listPaymentRequests:listCustomerRequests",
  fields: [
    {
      operation: "paginatorInfo",
      fields: ["total"],
      variables: {},
    },
  ],
  variables: {
    first: {
      type: "Int",
    },
    paymentRequestsInput: {
      name: "input",
      type: "ListCustomerRequestFilterInput!"
    }
  },
}
export const materialRequests = {
  operation: "listMaterialRequests:listCustomerRequests",
  fields: [
    {
      operation: "paginatorInfo",
      fields: ["total"],
      variables: {},
    },
  ],
  variables: {
    first: {
      type: "Int",
    },
    materialRequestsInput: {
      name: "input",
      type: "ListCustomerRequestFilterInput!"
    }
  },
}


export const PendingApproval = (props) => {
  const { t } = useTranslation();
  const title = t("pendingApproval")
  const icon = MdOutlinePendingActions

  const user = Globals.user

  const listShipmentPermission = user.hasPermission('shipping.shipment.list')
  const listShipmentCustomerPermission = user.hasPermission('shipping.customer_b2c.list_with_new_shipments')
  const listRegistrationsB2CPermission = user.hasPermission(GetCustomerPermissionSlug("shipping", "registration", "B2C", "list"))
  const listRegistrationsC2CPermission = user.hasPermission(GetCustomerPermissionSlug("shipping", "registration", "C2C", "list"))
  const listPickupsPermission = user.hasPermission('shipping.pickup.list')

  // const queryBody = queryBuilder();
  const queries = [];
  (listShipmentPermission || listShipmentCustomerPermission) && queries.push(lishShipments);
  listRegistrationsB2CPermission && queries.push(listRegistrationsB2C);
  listRegistrationsC2CPermission && queries.push(listRegistrationsC2C);
  listPickupsPermission && queries.push(listPickups);
  const LIST_ALL = gqlb.query(queries);
  const { data, loading } = useQuery(
    gql`
      ${LIST_ALL.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      skip: queries.length === 0,
      variables: {
        first: 1,
        shipmentsInput: {

          new: true
        },
        ...(listRegistrationsC2CPermission && {
          registrationsC2CInput: {

            new: true,
            businessTypeCode: "C2C"
          }
        }),
        ...(listRegistrationsB2CPermission && {
          registrationsB2CInput: {

            new: true,
            businessTypeCode: "B2C"
          }
        }),
        pickupsInput: {

          new: true
        },
      },
      onCompleted: (data) => {

      }
    }
  );

  const registrationsC2CTotal = data?.listRegistrationsC2C?.paginatorInfo?.total ?? 0
  const registrationsB2CTotal = data?.listRegistrationsB2C?.paginatorInfo?.total ?? 0
  const listData = []

  if (listShipmentPermission || listShipmentCustomerPermission) {
    const newElement = {
      id: 1,
      name: t('shipments'),
      total: data?.listShipments?.paginatorInfo?.total,
      icon: VscPackage,
      path: listShipmentCustomerPermission ? '/admin/customer-new-shipment' : '/admin/shipments',
      search: listShipmentCustomerPermission
        ? ``
        : `new=true`
    }
    listData.splice(0, 0, newElement);
  }
  if (listPickupsPermission) {
    const newElement = {
      id: 2,
      name: t('pickupRequests'),
      total: data?.listPickups?.paginatorInfo?.total,
      icon: BiPhoneCall,
      path: "/admin/pickups",
      search: `new=true&statusCode=NEW&page=0&rowsPerPage=20`
    }
    listData.splice(1, 0, newElement);
  }
  if (listRegistrationsC2CPermission) {
    const newElement = {
      id: 3,
      name: t('individualsAccounts'),
      total: registrationsC2CTotal,
      icon: FiUsers,
      path: "/admin/registrations/c2c",
      search: `new=true&verified=false`
    }
    listData.push(newElement)
  }
  if (listRegistrationsB2CPermission) {
    const newElement = {
      id: 3,
      name: t('customersAccounts'),
      total: registrationsB2CTotal,
      icon: FiUsers,
      path: "/admin/registrations/b2c",
      search: `new=true&verified=false`
    }
    listData.push(newElement)
  }

  return (
    <StatisticsCard {...props} listData={listData} loading={loading} title={title} icon={icon} />
  )
};
export const CustomerServices = (props) => {
  const { t } = useTranslation();
  const title = t("support")
  const icon = BiSupport

  const user = Globals.user
  const support = Globals.settings.support

  const complaintsPermission = support &&  (Globals.user.member.teams.length > 0 || user.hasPermission('crm.ticket.list'))
  const returnRequestsPermission = support && user.hasPermission('shipping.customer_request_rtrn.list')
  const paymentRequestsPermission = support && user.hasPermission('shipping.customer_request_pmnt.list')
  const materialRequestsPermission = support && user.hasPermission('shipping.customer_request_mtrl.list')

  const per = (complaintsPermission || returnRequestsPermission || paymentRequestsPermission || materialRequestsPermission)
  // const queryBody = queryBuilder();
  const queries = [];
  complaintsPermission && queries.push(listTickets);
  returnRequestsPermission && queries.push(returnRequests)
  paymentRequestsPermission && queries.push(paymentRequests)
  materialRequestsPermission && queries.push(materialRequests)

  !per && queries.push(listTickets);

  const LIST_ALL = gqlb.query(queries);
  const { data, loading } = useQuery(
    gql`
      ${LIST_ALL.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      skip: !per,
      variables: {
        first: 1,
        ticketsInput: {
          statusCode: "NEW"
        },
        ...(returnRequestsPermission && {
          returnRequestsInput: {
            statusCode: "NEW",
            typeCode: "RTRN"
          }
        }),
        ...(paymentRequestsPermission && {
          paymentRequestsInput: {
            statusCode: "NEW",
            typeCode: "PMNT"
          }
        }),
        ...(materialRequestsPermission && {
          materialRequestsInput: {
            statusCode: "NEW",
            typeCode: "MTRL"
          }
        }),

      },
      onCompleted: (data) => {

      }
    }
  );

  const returnRequestsTotal = data?.listReturnRequests?.paginatorInfo?.total ?? 0
  const paymentRequestsTotal = data?.listPaymentRequests?.paginatorInfo?.total ?? 0
  const materialRequestsTotal = data?.listMaterialRequests?.paginatorInfo?.total ?? 0
  const listData = []
  if (paymentRequestsPermission) {
    const newElement = {
      id: 5,
      name: t('paymentRequests'),
      total: paymentRequestsTotal,
      icon: RiMoneyDollarCircleLine,
      path: "/admin/customer-requests/pmnt",
      search: `statusCode=NEW`
    }
    listData.splice(1, 0, newElement);
  }
  if (returnRequestsPermission) {
    const newElement = {
      id: 5,
      name: t('returnRequests'),
      total: returnRequestsTotal,
      icon: GoPackageDependencies,
      path: "/admin/customer-requests/rtrn",
      search: `statusCode=NEW`
    }
    listData.splice(2, 0, newElement);
  }
  if (materialRequestsPermission) {
    const newElement = {
      id: 5,
      name: t('materialRequests'),
      total: materialRequestsTotal,
      icon: VscPackage,
      path: "/admin/customer-requests/mtrl",
      search: `statusCode=NEW`
    }
    listData.splice(3, 0, newElement);
  }
  if (complaintsPermission) {
    const newElement = {
      id: 4,
      name: t('complaints'),
      total: data?.listTickets?.paginatorInfo?.total,
      path: "/admin/crm/tickets",
      search: `statusCode=NEW&page=0&rowsPerPage=20`,
      icon: MdOutlineReportProblem,
    }
    listData.splice(4, 0, newElement);
  }

  return per ? (
    <StatisticsCard {...props} listData={listData} loading={loading} title={title} icon={icon} />
  ) : ""
};

const StatisticsCard = (props) => {
  const {
    avatarColor,
    listData,
    title,
    icon,
    loading
  } = props;
  const { t } = useTranslation();
  return (
    <StyledCard

    >
      <Grid
        item
        container
        md={12}
        lg={12}
      >
        <StatisticsFilterHeader
          section={"Statistics"}
          title={title}
          icon={icon}
        />
      </Grid>
      <Grid item container justifyContent={"flex-start"} lg={12} md={12}>
        <List
          sx={{ width: '100%' }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          <LisItemStyled avatarcolor={avatarColor} >
            {listData.length > 0 &&
              listData?.map((item, index) => {
                return (
                  <SpanLinkNoLine
                    key={index}
                    pathname={`${item?.path}`}
                    search={`${item?.search ?? ""}`}
                  >
                    <ListItemButton sx={{ px: 2, pb: 1 }}>
                      <ListItemIcon sx={{ minWidth: 25 }} className={classes.fontSize}>
                        <item.icon />
                      </ListItemIcon>
                      <ListItemText
                        disableTypography={true}
                        className={classes.text}
                        primary={item.name} />
                      {Boolean(loading) ? (
                        <CustomSpinner name="PulseLoader" size={10} />
                      ) : (
                        <Chip
                          className={classes.numbers}
                          size="small"
                          color="primary"
                          label={<CountUp end={item.total} duration={1} />}
                        />
                        // <Typography variant="body1" className={classes.numbers} paddingX={1}>
                        //   <CountUp end={item.total} duration={1} />
                        // </Typography>
                      )}
                    </ListItemButton>
                  </SpanLinkNoLine>
                )
              }
              )}
          </LisItemStyled>
        </List>
      </Grid>
    </StyledCard>
  );
}