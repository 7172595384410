/* eslint-disable no-useless-concat */
import React, { useState, Fragment } from "react";

import { styled } from "@mui/material/styles";

import {
  Grid,
  Typography,
  Table,
  TableBody,
  Toolbar,
  IconButton,
  lighten,
  CircularProgress,
  SwipeableDrawer,
  useTheme,
} from "@mui/material";
import useWidth, { isWidthDown } from "../../../Hooks/useWidth";

import { Cancel, CheckCircle, FilterList } from "@mui/icons-material";
import { EnhancedTableHead } from "./ShipmentListDetails";
import clsx from "clsx";
import PropTypes from "prop-types";
import { gql, useQuery } from "@apollo/client";
import * as gqlb from "gql-query-builder";
import MUITablePagination from "../../HOC/MUI/TablePagination/MUITablePagination";
import { useTranslation } from "react-i18next";
import CustomTableRow from "./CustomTableRow";
import EmptyTableMessage from "../../HOC/FunctionComponents/EmptyTableMessage";
import FiltersForm from "../../../Hooks/shipmentListFilters";
import TableFixedHeaderWraper from "../../HOC/CustomComponents/TableWithFixedHeader";

const TOOLBAR_PREFIX = "styledToolbar";

const toolbarClasses = {
  highlight: `${TOOLBAR_PREFIX}-highlight`,
  title: `${TOOLBAR_PREFIX}-title`,
  filterButton: `${TOOLBAR_PREFIX}-filterButton`,
};

const StyledToolbar = styled("div")(({ theme }) => ({
  width: "100%",
  zIndex: 1,

  [`& .${toolbarClasses.highlight}`]:
    theme.palette.mode === "light"
      ? {
        color: theme.palette.info.main,
        backgroundColor: lighten(theme.palette.info.main, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.info.main,
      },

  [`& .${toolbarClasses.title}`]: {
    flex: "1 1 100%",
  },

  [`& .${toolbarClasses.filterButton}`]: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
}));

const PREFIX = "ShipmentList";

const classes = {
  track: `${PREFIX}-track`,
  paper: `${PREFIX}-paper`,
  filters: `${PREFIX}-filters`,
};

const Root = styled(Grid)(({ theme }) => ({
  margin: theme.spacing(0, 0),
  width: "100%",
  backgroundColor: "#fff",

  [`& .${classes.track}`]: {
    marginTop: theme.spacing(2),
  },

  [`& .${classes.paper}`]: {
    width: "100%",
    backgroundColor: "#fff",
    overflowX: "auto",
    maxHeight: `calc(100vh - ${theme.mixins.toolbar["minHeight"]}px)`,
  },

  [`& .${classes.filters}`]: {
    [theme.breakpoints.down("md")]: {
      width: "calc(100% - 72px)",
    },
    [theme.breakpoints.up("sm")]: {
      width: "360px",
    },

    height: "100%",
    overflow: "hidden",
  },
}));

const EnhancedTableToolbar = (props) => {
  const { t } = useTranslation();
  const { numSelected, done, clear, disabled, toggleFilterDrawer } = props;
  return (
    <StyledToolbar>
      <Toolbar
        className={clsx({
          [toolbarClasses.highlight]: numSelected > 0,
        })}
      >
        {numSelected > 0 ? (
          <Typography
            className={toolbarClasses.title}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {t("shipment", { count: numSelected })}
          </Typography>
        ) : (
          <Typography
            className={toolbarClasses.title}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            {t("shipments")}
          </Typography>
        )}
        {/* <Tooltip title="تم" > */}
        <IconButton
          onClick={toggleFilterDrawer}
          className={toolbarClasses.filterButton}
          size="large"
        >
          <FilterList />
        </IconButton>

        <IconButton
          aria-label="done"
          disabled={disabled}
          onClick={done}
          sx={{ color: "success.main" }}
          size="large"
        >
          <CheckCircle />
        </IconButton>
        {/* </Tooltip> */}

        <IconButton
          aria-label="close"
          onClick={() => clear()}
          color={"primary"}
          size="large"
        >
          <Cancel />
        </IconButton>
      </Toolbar>
    </StyledToolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  done: PropTypes.func.isRequired,
};

//////////////////////////////////////////////////

//*********Table Function*********

const SHIPMENT_QUERY = gqlb.query({
  operation: "listShipments",
  fields: [
    {
      operation: "data",
      fields: [
        "id",
        "code",
        "date",
        "recipientName",
        "recipientAddress",
        "recipientMobile",
        "description",
        "weight",
        "price",
        "piecesCount",
        "totalAmount",
        "senderName",
        "returningDueFees",
        "customerDue",
        "amount",
        "deliveredAmount",
        "collectedFees",
        "allDueFees",
        "returnFees",
        "deliveredCanceledDate",
        {
          status: ["name"],
        },
        {
          deliveryType: ["name"],
        },
        {
          paymentType: ["name", "code"],
        },
        {
          returnType: ["name"],
        },
        {
          type: ["name"],
        },
        {
          recipientZone: ["id", "name"],
        },
        {
          recipientSubzone: ["name"],
        },
        {
          senderZone: ["id", "name"],
        },
        {
          senderSubzone: ["name"],
        },
        {
          operation: "calculatePendingPayment",
          fields: [],
          variables: {
            id: {
              type: "Int",
              required: true,
            },
          },
        },
        {
          customer: [
            "id",
            "name",
            "mobile",
            { zone: ["id", "name"] },
            { subzone: ["name"] },
          ],
        },
        {
          lastDeliveryAgent: ["id", "name", "commission"],
        },
      ],
      variables: {},
    },
    {
      operation: "paginatorInfo",
      fields: ["total"],
      variables: {},
    },
  ],
  variables: {
    first: {
      type: "Int",
      required: true,
    },
    page: {
      type: "Int",
    },
    input: {
      type: "ListShipmentsFilterInput",
    },
  },
});

const ShipmentList = (props) => {
  const { type, variables } = props;

  const [shipments, setShipments] = useState([]);
  const [selectedShipments, setSelectedShipments] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [drawerState, setDrawerState] = useState(false);
  const [queryVariables, setQueryVariables] = useState({ refetch: true });
  const { refetch, ...restVariables } = queryVariables;

  const screenWidth = useWidth();

  const isScreenSmall = isWidthDown("xs", screenWidth);

  const { t } = useTranslation();
  const theme = useTheme();

  const { data, loading } = useQuery(
    gql`
      ${SHIPMENT_QUERY.query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      skip: !variables.id,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: {
        first: rowsPerPage,
        page: page + 1,
        id: variables.id,
        input: {
          forPaymentId: variables.id,
          ...restVariables,
        },
      },
      onCompleted: (data) => {
        const handledData =
          data?.listShipments?.data !== null ? data?.listShipments?.data : [];

        let updatedDate = handledData.map((i) => {
          const selectedShipment = selectedShipments.find(
            (ele) => ele.shipment.id === i.id
          );
          if (selectedShipment) {
            return selectedShipment;
          } else {
            return {
              shipment: i,
              select: false,
            };
          }
        });

        setShipments(updatedDate);
      },
    }
  );

  const isShipmentSelected = (addShipments, deleteShipments) => {
    const mainShipments = addShipments
      ? [...addShipments]
      : [...selectedShipments];

    const filterShipments = deleteShipments
      ? [...deleteShipments]
      : [...selectedShipments];

    return mainShipments.filter((newShipment) => {
      return !filterShipments.some(
        (current) => current.shipment.id === newShipment.shipment.id
      );
    });
  };

  const handleSelectAllClick = (event, rows) => {
    const selected = shipments?.map((i) => {
      if (event.target.checked) {
        return {
          ...i,
          select: true,
        };
      } else {
        return {
          ...i,
          select: false,
        };
      }
    });

    if (event.target.checked) {
      const newShipments = isShipmentSelected(selected);
      setSelectedShipments((prev) => [...prev, ...newShipments]);
    } else {
      const filteredShipments = isShipmentSelected(null, selected);
      setSelectedShipments(filteredShipments);
    }
    setShipments(selected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  const resetPage = () => setPage(0);

  const toggleFilterDrawer = () => setDrawerState(!drawerState);
  const filtersFields = {
    DLVBY: [
      "date",
      "dlvAtemp",
      "status",
      "branch",
      "originBranch",
      "businessType",
      "custm",
      "zone",
      "subZone",
      "refNumber",
      "service",
      "deliveryType",
      "returnType",
      "packageType",
      "paymentType",
    ],
    CUSTM: [
      "date",
      "dlvAtemp",
      "status",
      "branch",
      "originBranch",
      "zone",
      "subZone",
      "refNumber",
      "service",
      "deliveryType",
      "returnType",
      "packageType",
      "paymentType",
    ],
  };

  const filtersForm = (
    <FiltersForm
      {...{ resetPage, loading }}
      preventPushUrl
      queryVariables={(variables) => setQueryVariables(variables)}
      onSubmitFunc={() => setDrawerState(false)}
      filtersAllowed={filtersFields[type]}
    />
  );

  /////////////////////////////////////////////////////////////////////////////////////////////////////
  const selectedShipmentLength = selectedShipments.length;

  let tableBody = null;

  let progress = (
    <Grid container item justifyContent="center" className={classes.track}>
      <CircularProgress />
    </Grid>
  );
  const selectedShipmentParPage = () => {
    return shipments.filter((i) => i.select === true).length;
  };
  const validShipments = () => {
    return selectedShipmentLength <= 0;
  };
  if (shipments && !loading) {
    tableBody = (
      <Grid container justifyContent="center" sx={{ width: "100%" }}>
        {/* *******Table******* */}
        <div className={classes.paper}>
          <TableFixedHeaderWraper containerScroll>
            <Table aria-labelledby="tableTitle" aria-label="enhanced table">
              <EnhancedTableHead
                numSelected={selectedShipmentParPage()}
                onSelectAllClick={(e) => handleSelectAllClick(e, shipments)}
                rowCount={shipments?.length}
                type={type}
              />
              <TableBody>
                {shipments?.map((shipment, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <CustomTableRow
                      key={shipment.shipment.id}
                      shipment={shipment}
                      type={type}
                      onChangeCheck={(check, selectedShipment) => {
                        setShipments((prev) => {
                          let updated = [...prev];
                          updated[index] = {
                            ...updated[index],
                            select: !updated[index].select,
                          };

                          if (check.target.checked) {
                            setSelectedShipments((prev) => [
                              ...prev,
                              updated[index],
                            ]);
                          } else {
                            setSelectedShipments((prev) => {
                              return prev.filter(
                                (i) => i.shipment.id !== shipment.shipment.id
                              );
                            });
                          }

                          return updated;
                        });
                      }}
                      labelId={labelId}
                    />
                  );
                })}
              </TableBody>
            </Table>
          </TableFixedHeaderWraper>
          <MUITablePagination
            count={data?.listShipments?.paginatorInfo?.total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </Grid>
    );
  }
  return (
    <Root container justifyContent="center">
      {isScreenSmall ? (
        <SwipeableDrawer
          disableDiscovery={true}
          disableSwipeToOpen={true}
          anchor="left"
          open={drawerState}
          onClose={() => setDrawerState(false)}
          onOpen={() => setDrawerState(true)}
          classes={{ paper: classes.filters }}
          dir={theme.direction}
        >
          {filtersForm}
        </SwipeableDrawer>
      ) : (
        <Grid item sm={2}>
          {filtersForm}
        </Grid>
      )}
      <Grid item container sm={10} alignContent="flex-start">
        <EnhancedTableToolbar
          numSelected={selectedShipmentLength}
          clear={props.done}
          toggleFilterDrawer={toggleFilterDrawer}
          done={() => {
            props.done();
            props.setSelected(selectedShipments);
          }}
          disabled={validShipments()}
        />
        {loading
          ? progress
          : shipments && shipments?.length !== 0
            ? tableBody
            : !loading && (
              <EmptyTableMessage loading={false} message={t("noShipments")} />
            )}
      </Grid>
    </Root>
  );
};

export default ShipmentList;
