import React, { Fragment } from "react";
import { styled } from "@mui/material/styles";
import { Checkbox, TableRow } from "@mui/material";
import CellLink from "../../HOC/CustomComponents/CellLink";
import { ManifestProductsCells } from "./ProductListDetails";
import { FixedTableCell } from "../../HOC/CustomComponents/FixedTableCell";

const PREFIX = "CustomTableRow";

const classes = {
  productTable_checkbox: `${PREFIX}-productTable_checkbox`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled(TableRow)(({ theme }) => ({
  [`& .${classes.productTable_checkbox}`]: {
    zIndex: "100",
    backgroundColor: "#fff",
    position: "sticky",
    top: 0,
    left: 0,
  },
}));

const CustomTableRow = (props) => {
  const { product, onChangeCheck, labelId, type } = props;

  const productDetails = product?.product;

  // useEffect(() => {
  //   const initialCheck = selectedObject.some((i) => i?.id === shipment.id);
  //   setCheck(initialCheck);
  //   return () => {};
  // }, [selectedObject]);

  // const handleChecked = (e) => {
  //   const checked = e.target.checked;
  //   setCheck(checked);
  //   setSelectedShipment(checked, shipment);
  // };

  return (
    <Fragment>
      <Root
        hover
        role="checkbox"
        aria-checked={product.select}
        tabIndex={-1}
        selected={product.select}
      >
        <FixedTableCell
          className={classes.productTable_checkbox}
          padding="checkbox"
        >
          <Checkbox
            onChange={(event) => onChangeCheck(event)}
            checked={product.select}
            inputProps={{ "aria-labelledby": labelId }}
          />
        </FixedTableCell>

        <CellLink
          component="th"
          scope="product"
          pathname={`/admin/products/${productDetails.id}`}
        >
          {productDetails?.code}
        </CellLink>
        <FixedTableCell>{productDetails?.name}</FixedTableCell>
        <ManifestProductsCells productDetails={productDetails} product={product} type={type} />
      </Root>
    </Fragment>
  );
};

export default CustomTableRow;
