import React, { useEffect } from "react";

// components
import SimpleSlider from "./Slider";
import AboutSection from "./about";
import ServiceArea from "./ServiceArea";
import WpoFeatures from "./wpofeatures";
import CounterSection from "./CounterSection";
import FooterSection from "./Footer";
import TrackArea from "./Track";
import HomePageHeader from "./Navbar/header/HomePageHeader";
import "./css/font-awesome.min.css";
import "./css/responsive.css";
import "./css/flaticon.css";
import "bootstrap/dist/css/bootstrap.min.css";
import ScrollTop from "./ScrollToTop";
import config from "../../config.json";
import { replaceUrl } from "../HOC/CustomFunctions/pushUrl";
import { useHistory } from "react-router";

export default function Login(props) {
  const history = useHistory();

  useEffect(() => {
    if (localStorage.getItem("subscriptionExpired")) {
      history.push({ pathname: "/renewal" });
      return;
    }
    return () => {
      // setLoadingFinshed(false);
    };
  }, []);

  useEffect(() => {
    if (!config.app.landingPage) replaceUrl(props, "/admin");
    return () => {};
  }, []);

  return (
    <div>
      <HomePageHeader />

      {config.homeSections?.map((field) => {
        switch (field) {
          case "banner":
            return <SimpleSlider key={field} />;

          case "about":
            return <AboutSection key={field} />;

          case "services":
            return <ServiceArea key={field} />;

          case "track":
            return <TrackArea key={field} />;

          case "features":
            return <WpoFeatures key={field} />;

          case "statistics":
            return <CounterSection key={field} />;

          default:
            return "";
        }
      })}

      <FooterSection />
      <ScrollTop />
    </div>
  );
}
