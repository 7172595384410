import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FixedTableCell } from "../../HOC/CustomComponents/FixedTableCell";
import { useForm } from "react-hook-form";
import ControlMUItextField from "../../HOC/MUI/ControlMUItextField";
import { useManifestProductsContext } from "../../ManifestsProducts/ManifestProducts/ManifestProductsProvider";

export function QuantityTableCell(props) {
  const { productDetails, product, checked } = props;
  const {
    manifestProductsContext: { updateSelectedProductByForm },
  } = useManifestProductsContext();
  const { t } = useTranslation(["translation", "validation"]);

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      quantity: '1',
    },
  });

  useEffect(() => {
    checked && updateProduct("quantity", 1, true);
    return () => { };
  }, [product]);


  const updateProduct = (name, value, checkIsEmpty) => {
    /************* Validation Funcs *******************/
    let allField = watch();
    for (const key in allField) {
      if (key.startsWith(name)) {
        delete allField[key];
      }
    }
    /*************************************************/
    updateSelectedProductByForm({ id: productDetails.id, checkIsEmpty, name, value });
  };

  const onChangeQuantity = (e) => {
    let checkIsEmpty;
    if (e.target.value < 1 || e.target.value === '') {
      checkIsEmpty = false;
    } else {
      checkIsEmpty = true
    }
    const value = parseFloat(e.target.value);
    updateProduct("quantity", value, checkIsEmpty);
  };

  return (
    <FixedTableCell>
      <form style={{ width: 180 }} onSubmit={handleSubmit(() => { })}>
        <ControlMUItextField
          control={control}
          errors={errors}
          name={"quantity"}
          disabled={Boolean(!checked)}
          type="number"
          variant="outlined"
          label={t("quantity")}
          onChange={onChangeQuantity}
          inputProps={{ min: 1 }}
          rules={{
            min: {
              value: 1,
              message: t("validation:min", {
                field: t("quantity"),
                number: "1",
              }),
            },
            required: t("fieldIsRequired"),
          }}
        />
      </form>
    </FixedTableCell>
  );
}
