import React, { Fragment, useState } from "react";
import { styled } from '@mui/material/styles';
import {
  Paper,
  Collapse,
  Typography,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { SAVE_SHIPPING_SERVICE, SHIPPING_SERVICE_ID } from "./Graphql";
import clsx from "clsx";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { useMutation, gql, useQuery } from "@apollo/client";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import { useSnackbar } from "notistack";
import FormButton from "../CustomComponents/Buttons/FormButton";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import {
  LIST_GL_ACCOUNTS_DROPDOWN,
  LIST_SHIPMENT_TYPES_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import ListBranches from "../HOC/ComponentWithSpecificQuery/ListBranches";
import { MultipleAutocomplete } from "../HOC/MUI/MultipleAutocomplete";
import Grid from "@mui/material/Unstable_Grid2";

const PREFIX = 'ShippingServiceForm';

const classes = {
  spacing: `${PREFIX}-spacing`,
  mainGrid: `${PREFIX}-mainGrid`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.spacing}`]: {
    width: "100%",
    margin: theme.spacing(2, 0, 0, 0),
  },

  [`& .${classes.mainGrid}`]: {
    width: "100%",
    margin: theme.spacing(0),
    padding: theme.spacing(2),
  }
}));

const ShippingServiceForm = (props) => {
  const [activeUser, setActiveUser] = useState(true);
  const [autocompleteValues, setAutocompleteValues] = useState({
    branch: null,
    deliveryAccount: null,
    collectionAccount: null,
    commissionAccount: null,
    shipmentTypes: [],
  });


  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { handleSubmit, control, formState, setValue, setError, watch } =
    useForm();
  const { errors } = formState;

  const [saveShippingService, { loading: saveShippingServiceLoading }] =
    useMutation(
      gql`
        ${SAVE_SHIPPING_SERVICE.query}
      `
    );

  const handelActivate = (e) => {
    const active = e.target.checked;
    setActiveUser(active);
  };

  // update
  const shippingServiceId = parseInt(props.match.params.id);

  const { data: updateShippingService } = useQuery(
    gql`
      ${SHIPPING_SERVICE_ID.query}
    `,

    {
      skip: !shippingServiceId,
      variables: { id: shippingServiceId },
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        const shippingServiceData = data.shippingService;
        setActiveUser(shippingServiceData.active);
        const saveShippingServiceParams = ["id", "code", "name", "days"];
        saveShippingServiceParams.forEach((i) => {
          shippingServiceData[i] && setValue(i, shippingServiceData[i]);
        });
        setValue("active", shippingServiceData["active"]);

        const shipmentTypes = shippingServiceData?.shipmentTypes?.map(
          (i) => i.code
        );
        setAutocompleteValues({
          branch: shippingServiceData?.branch,
          collectionAccount: shippingServiceData?.collectionAccount,
          commissionAccount: shippingServiceData?.commissionAccount,
          deliveryAccount: shippingServiceData?.deliveryAccount,
          shipmentTypes: shipmentTypes,
        });
      },
    }
  );

  const shippingServiceData = updateShippingService?.shippingService;

  const selectDefaultValue = (data) => {
    return data;
  };

  const onSubmit = (data) => {
    for (const key in data) {
      if (data[key] === "") {
        data[key] = null;
      }
    }
    if (data["shipmentTypes"] === null) data["shipmentTypes"] = [];
    saveShippingService({
      variables: {
        input: {
          ...data,
          active: activeUser,
          days: parseInt(data.days),
        },
      },
    })
      .then((data) => {
        pushUrl(
          props,
          `/admin/shipping-services/${data?.data?.saveShippingService?.id}`
        );
        enqueueSnackbar(t("saveSuccessful"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      })
      .catch(({ graphQLErrors }) => {
        setValidationError(graphQLErrors, setError);
      });
  };

  const body = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid
        container
        justifyContent="flex-start"
        alignItems="center"
        className={clsx(classes.mainGrid)}
      >
        <Paper
          container
          component={Grid}
          spacing={2}
          className={clsx(classes.spacing)}
          sx={{ p: 1 }}
        >
          <Grid container sx={{ p: 1 }} justifyContent="space-between" xs={12}>
            <Typography variant="h6">{t("shippingService")}</Typography>
            <FormControlLabel
              checked={activeUser}
              control={<Switch color="primary" />}
              label={t("active")}
              labelPlacement="start"
              onChange={handelActivate}
            />
          </Grid>
          <Grid xs={12} sm={6} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"name"}
              label={t("name")}
              rules={{ required: t("fieldIsRequired") }}
            />
          </Grid>
          <Grid xs={12} sm={6} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"code"}
              label={t("code")}
            />
          </Grid>
          <Grid xs={12} sm={6} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"days"}
              label={t("shippingDays")}
              rules={{ required: t("fieldIsRequired") }}
              type="number"
            />
          </Grid>
          <Grid xs={12} sm={6} alignItems="flex-start">
            <ListBranches
              control={control}
              errors={errors}
              name={"branchId"}
              defaultValue={autocompleteValues.branch}
              disabled={shippingServiceId ? true : false}
              onChangeValue={() => {
                setValue("deliveryAccountId", "");
                setValue("collectionAccountId", "");
                setValue("commissionAccountId", "");
              }}
              skipDefaultBranch={Boolean(shippingServiceId)}
            />
          </Grid>
          <Grid xs={12} sm={6} alignItems="flex-start">
            <CustomAutocomplete
              control={control}
              errors={errors}
              name={"deliveryAccountId"}
              label={t("deliveryAccount")}
              disabled={shippingServiceData?.deliveryAccount ? true : false}
              parseData={(data) => selectDefaultValue(data)}
              query={LIST_GL_ACCOUNTS_DROPDOWN.query}
              variables={{
                input: {
                  typeCode: "SUB",
                  ...(watch("branchId") && {
                    branchId: { value: watch("branchId"), includeNull: true },
                  }),
                },
              }}
              defaultValue={autocompleteValues.deliveryAccount}
            />
          </Grid>
          <Grid xs={12} sm={6} alignItems="flex-start">
            <CustomAutocomplete
              control={control}
              errors={errors}
              name={"collectionAccountId"}
              label={t("collectionAccount")}
              disabled={shippingServiceData?.collectionAccount ? true : false}
              parseData={(data) => selectDefaultValue(data)}
              query={LIST_GL_ACCOUNTS_DROPDOWN.query}
              variables={{
                input: {
                  typeCode: "SUB",
                  ...(watch("branchId") && {
                    branchId: { value: watch("branchId"), includeNull: true },
                  }),
                },
              }}
              defaultValue={autocompleteValues.collectionAccount}
            />
          </Grid>
          <Grid xs={12} sm={6} alignItems="flex-start">
            <CustomAutocomplete
              control={control}
              errors={errors}
              name={"commissionAccountId"}
              label={t("commissionAccount")}
              disabled={shippingServiceData?.commissionAccount ? true : false}
              parseData={(data) => selectDefaultValue(data)}
              query={LIST_GL_ACCOUNTS_DROPDOWN.query}
              variables={{
                input: {
                  typeCode: "SUB",
                  ...(watch("branchId") && {
                    branchId: { value: watch("branchId"), includeNull: true },
                  }),
                },
              }}
              defaultValue={autocompleteValues.commissionAccount}
            />
          </Grid>
          <Grid xs={12} sm={6} alignItems="flex-start">
            <MultipleAutocomplete
              control={control}
              errors={errors}
              variables={{ code: "SHP_REQUEST_STATUS" }}
              name={"shipmentTypes"}
              label={t("shipmentTypes")}
              parseData={(data) => data}
              query={LIST_SHIPMENT_TYPES_DROPDOWN.query}
              defaultValue={autocompleteValues.shipmentTypes}
              multiple
              valueKey="code"
            />
          </Grid>
        </Paper>
        <Grid container justifyContent="flex-end" className={classes.spacing}>
          <FormButton disabled={saveShippingServiceLoading}>
            {saveShippingServiceLoading ? <ButtonLoading /> : t("save")}
          </FormButton>
        </Grid>
      </Grid>
    </form>
  );
  let DOM;

  if (shippingServiceId) {
    DOM = updateShippingService ? body : <FullScreenLoading minHeight="10%" />;
  } else {
    DOM = body;
  }

  return <Root>{DOM}</Root>;
};

export default ShippingServiceForm;
