import {
  Popover,
  List,
  ListItem,
  IconButton,
  Tooltip,
  Box,
  Typography,
  Divider,
  FormControlLabel,
  Radio,
} from "@mui/material";
import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { LANGUAGES_DETAILS } from "../../../helpers/LanguagesVariables";
import config from "../../../config.json";
import eg from "../../../assets/Image/eg-flag.png";
import usa from "../../../assets/Image/usa-flag.png";

import { useApolloClient } from "@apollo/client";
import useWidth, { isWidthDown } from "../../../Hooks/useWidth";
import LanguageIcon from '@mui/icons-material/Language';
import { styled } from '@mui/material/styles';

const PREFIX = 'language';

const classes = {
  list: `${PREFIX}-list`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.list}`]: {
    // padding: theme.spacing(1),
  },
}));

const LanguageIcons = (props) => {
  const { onClick, className, asLink } = props;
  const { t, i18n } = useTranslation();
  const client = useApolloClient();

  const firstLang = config.app.languages[0];
  const secondLang = config.app.languages[1];
  const lang = i18n.language === firstLang ? secondLang : firstLang;
  const changeLanguage = (includeStandby) => {
    i18n.changeLanguage(lang);
    document.getElementsByTagName("html")[0].setAttribute("dir", i18n.dir());
    client.reFetchObservableQueries(includeStandby);
    handleCloseLang()
  };

  const screenWidth = useWidth();

  const screenIsSmall = isWidthDown("sm", screenWidth);

  const [anchorElLang, setAnchorElLang] = useState(null);
  const openLang = Boolean(anchorElLang);
  const langId = openLang ? "popover" : undefined;

  const handleOpenLang = (event, id) => {
    setAnchorElLang(event.currentTarget);
  };

  const handleCloseLang = () => {
    setAnchorElLang(null);
  };
  return secondLang ? (
    screenIsSmall && asLink ? (
      <Box
        className={className}
        onClick={() => {
          changeLanguage(true);
          onClick && onClick();
        }}
      >
        {LANGUAGES_DETAILS[lang].nativeName}
      </Box>
    ) : (
      // <IconButton
      //   sx={{ ml: 1 }}
      //   color="default"
      //   onClick={(e) => {
      //     changeLanguage();
      //     onClick && onClick();
      //   }}
      //   size="large"
      // >
      //   <Fragment>
      //     {i18n.language === 'ar' ? (
      //       <img style={{ width: "1em" }} src={usa} alt="usa-flag" />
      //     ) : (
      //       <img style={{ width: "1em" }} src={eg} alt="eg-flag" />
      //     )}
      //   </Fragment>
      // </IconButton>
      <Fragment>
        <Tooltip title={t("chooseLang")}>
          <Box component="span">
            <IconButton
              sx={{ p: 1 }}
              aria-label="Language"
              onClick={handleOpenLang}
              aria-describedby={langId}
              // disabled={shipmentsId?.length === 0}
              size="large">
              <LanguageIcon fontSize="inherit" />
            </IconButton>
          </Box>
        </Tooltip>
        <Popover
          id={langId}
          open={openLang}
          anchorEl={anchorElLang}
          onClose={handleCloseLang}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <List>
            <Box>
              <Root>
                {config.app.languages.map((lang, index) => {
                  return (
                    <ListItem className={classes.list} key={index}>
                      <FormControlLabel
                        onClick={(e) => {
                          if (lang !== i18n.language) {
                            changeLanguage();
                            onClick && onClick();
                          }
                        }}
                        checked={lang === i18n.language}
                        value={lang}
                        control={<Radio />}
                        label={LANGUAGES_DETAILS[lang].nativeName} />
                    </ListItem>
                  )
                })}
              </Root>
            </Box>
          </List>
        </Popover>
      </Fragment>
    )
  ) : null;
};

export default LanguageIcons;
