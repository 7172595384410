import React, { useEffect, useState } from "react";

import { styled } from "@mui/material/styles";

import { useQuery, gql } from "@apollo/client";
import {
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
  Toolbar,
  Box,
  Table,
  TableBody,
  TableRow,
  TableHead,
  Icon,
  Button,
  Drawer,
  useTheme,
} from "@mui/material";
import { Add, Clear, FilterList, Search } from "@mui/icons-material";
import clsx from "clsx";
import { SecuredNavLink } from "../HOC/CustomComponents/Secured";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import { LIST_JOURNAL_ENTRY } from "./Graphql";
import { useTranslation } from "react-i18next";
import emptyList from "../../assets/Image/received.png";
import CellLink from "../HOC/CustomComponents/CellLink";
import MUIselect from "../HOC/MUI/MUIselect";
import { urlParameters } from "../HOC/CustomFunctions/urlParameters";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { windowUrl } from "../HOC/CustomFunctions/pushUrl";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import {
  LIST_BRANCHES_DROPDOWN,
  LIST_GL_ACCOUNTS_DROPDOWN,
  LIST_JOURNAL_TYPES_DROPDOWN,
  LIST_SUBSIDIARIES_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import moment from "moment";
import MUIDateRangeCustom from "../HOC/MUI/MUIDateRangeCustom";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";

const PREFIX = "ListJournalEntry";

const classes = {
  button: `${PREFIX}-button`,
  btnMargin: `${PREFIX}-btnMargin`,
  mainSearch: `${PREFIX}-mainSearch`,
  titles: `${PREFIX}-titles`,
  toolbar: `${PREFIX}-toolbar`,
  searchForm: `${PREFIX}-searchForm`,
  searchField: `${PREFIX}-searchField`,
  overlay: `${PREFIX}-overlay`,
  searchPadding: `${PREFIX}-searchPadding`,
  drawer: `${PREFIX}-drawer`,
  drawerPaper: `${PREFIX}-drawerPaper`,
  drawerHeader: `${PREFIX}-drawerHeader`,
  content: `${PREFIX}-content`,
  contentShift: `${PREFIX}-contentShift`,
  tableRow: `${PREFIX}-tableRow`,
  shipmentTable_shipmentStatus: `${PREFIX}-shipmentTable_shipmentStatus`,
  shipmentTable_shipmentCode: `shipmentTable_shipmentCode`,
  tablePaper: `${PREFIX}-tablePaper`,
  table_status: `${PREFIX}-table_status`,
  table_code: `${PREFIX}-table_code`,
  toolbarHeader: `${PREFIX}-toolbarHeader`,
  tableBodyRow: `${PREFIX}-tableBodyRow`,
  iconColor: `${PREFIX}-iconColor`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.button}`]: {
    position: "sticky",
    bottom: 0,
    zIndex: 3,
    backgroundColor: "#fff",
    marginTop: 10,
    padding: 7,
  },

  [`& .${classes.btnMargin}`]: {
    width: "100%",
  },

  [`& .${classes.mainSearch}`]: {
    margin: theme.spacing(0),
    width: "100%",
  },

  [`& .${classes.titles}`]: {
    flex: "1 1 100%",
  },

  [`& .${classes.toolbar}`]: {
    borderBottom: "1px #ccd1d6 solid",
    backgroundColor: "#f5f7f9",
    width: "100%",
    // justifyContent: "space-between",
  },

  [`& .${classes.searchForm}`]: {
    overflowY: "auto",
    width: "100%",
    height: "100%",
    margin: 0,
  },

  [`& .${classes.searchField}`]: {
    padding: theme.spacing(1, 1, 0, 1),
    position: "relative",
    overflow: "hidden",
  },

  [`& .${classes.overlay}`]: {
    width: "100%",
    height: "100%",
    position: "absolute",
    backgroundColor: "#ffffffa8",
    zIndex: 2,
  },

  [`& .${classes.searchPadding}`]: {
    padding: theme.spacing(1),
  },

  //////////////////////Drawer///////////////////

  [`& .${classes.drawer}`]: {
    width: drawerWidth,
    flexShrink: 0,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },

  [`& .${classes.drawerPaper}`]: {
    width: drawerWidth,

    zIndex: "250",

    left: "initial",
    right: "initial",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },

  [`& .${classes.drawerHeader}`]: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },

  [`& .${classes.content}`]: {
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
  },

  [`& .${classes.contentShift}`]: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: "auto",
    width: "calc(100% - 240px)",
    [theme.breakpoints.down("sm")]: {
      margin: "auto",
      width: "auto",
    },
  },

  [`& .${classes.tableRow}`]: {
    "&:hover": {
      "& .MuiTableCell-root": {
        backgroundColor: "#f5f5f5",
      },
    },
  },

  [`& .${classes.shipmentTable_shipmentStatus}`]: {
    [theme.breakpoints.up("sm")]: {
      zIndex: "100",
      backgroundColor: "#fff",
      position: "sticky",
      top: "0",
      right: "0",
    },
  },

  [`& .${classes.shipmentTable_shipmentCode}`]: {
    [theme.breakpoints.up("sm")]: {
      zIndex: "100",
      backgroundColor: "#fff",
      position: "sticky",
      top: "0",
      left: "0em",
    },
  },

  [`& .${classes.tablePaper}`]: {
    borderRadius: 0,
    width: "100%",
    display: "grid",
  },

  [`& .${classes.table_status}`]: {
    [theme.breakpoints.up("sm")]: {
      zIndex: "100",
      backgroundColor: "#fff",
      position: "sticky",
      top: "0",
      right: "0",
    },
  },

  [`& .${classes.table_code}`]: {
    [theme.breakpoints.up("sm")]: {
      zIndex: "100",
      backgroundColor: "#fff",
      position: "sticky",
      top: "0",
      left: "0",
    },
  },

  [`& .${classes.toolbarHeader}`]: {
    flex: 1,
  },

  [`& .${classes.tableBodyRow}`]: {
    "&:hover": {
      "& .MuiTableCell-root": {
        backgroundColor: "#f5f5f5",
      },
    },
  },

  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },
}));

const drawerWidth = 240;

const ListJournalEntry = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const urlQuery = urlParameters(window.location.search);
  const initFromDate = urlQuery["fromDate"]
    ? urlQuery["fromDate"]
    : moment(new Date())
        .locale("en")
        .subtract(1, "month")
        .add(1, "day")
        .format("YYYY-MM-DD");
  const initToDate = urlQuery["toDate"]
    ? urlQuery["toDate"]
    : moment(new Date()).locale("en").format("YYYY-MM-DD");
  const [dateRange, setDateRange] = useState([initFromDate, initToDate]);

  const validUrlParameters = Object.keys(urlQuery).length !== 0;
  const [autocompleteValues, setAutocompleteValues] = useState({
    branch: null,
    type: null,
    glAccount: null,
    subsidiary: null,
  });
  const [drawerState, setDrawerState] = React.useState({
    top: true,
    left: props.width === "xs" ? false : true,
    bottom: props.width === "xs" ? false : true,
    right: props.width === "xs" ? false : true,
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm();

  const [rowsPerPage, setRowsPerPage] = useState(urlQuery["rowsPerPage"] ?? 20);
  const initSearch = () => {
    let searchParameters = {
      page: 0,
      refetch: true,
      fromDate: dateRange[0],
      toDate: dateRange[1],
    };
    delete urlQuery["rowsPerPage"];
    if (urlQuery["code"]) urlQuery["code"] = urlQuery["code"].toString();

    if (validUrlParameters) {
      searchParameters = urlQuery;
    }
    return {
      ...searchParameters,
    };
  };
  const [search, setSearch] = useState(initSearch());

  useEffect(() => {
    urlQuery["code"] && setValue("code", urlQuery["code"].toString());

    urlQuery["approved"] !== undefined &&
      setValue("approved", urlQuery["approved"]);

    return () => {};
  }, []);

  const history = useHistory();

  const pushUrlSearch = (param) => {
    const queryParams = [];
    for (const i in param) {
      encodeURIComponent(param[i]) &&
        queryParams.push(
          encodeURIComponent(i) + "=" + encodeURIComponent(param[i])
        );
    }
    const queryString = queryParams.join("&");

    const url = history.createHref({
      pathname: "/admin/finance/journal-entry",
      search: "?" + queryString,
    });
    windowUrl(url);
    //this will not effect on history.location.search
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      (event.type === "keydown" || event.type === "submit") &&
      (event.type === "submit" || event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerState({ ...drawerState, [anchor]: open });
  };
  const filterAnchor = props.width === "xs" ? "bottom" : "left";

  const onSubmit = (data) => {
    filterAnchor === "bottom" &&
      setDrawerState({ ...drawerState, [filterAnchor]: false });
    let handledData = {
      fromDate: moment(dateRange[0]).locale("en").format("YYYY-MM-DD"),
      toDate: moment(dateRange[1]).locale("en").format("YYYY-MM-DD"),
      approved: data.approved,
      code: data.code,
      branchId: data.branchId,
      typeId: data.typeId,
      glAccountId: data.glAccountId,
      subsidiaryId: data.subsidiaryId,
      page: 0,
    };
    data["approved"] === "" && delete handledData["approved"];

    for (const key in handledData) {
      if (
        handledData[key] === undefined ||
        handledData[key] === "" ||
        handledData[key] === null
      ) {
        delete handledData[key];
      }
    }
    setSearch((prev) => ({
      ...handledData,
      refetch: !prev.refetch,
    }));
    for (const key in handledData) {
      if (handledData[key] === null) {
        delete handledData[key];
      }
    }
    pushUrlSearch({
      ...handledData,
      rowsPerPage: rowsPerPage,
    });
  };

  const handleChangePage = (event, newPage) => {
    pushUrlSearch({
      ...urlQuery,

      page: newPage,
      rowsPerPage: rowsPerPage,
    });

    setSearch((prev) => ({ ...prev, page: newPage }));
  };

  const handleChangeRowsPerPage = (event) => {
    pushUrlSearch({
      ...urlQuery,
      page: 0,
      rowsPerPage: +event.target.value,
    });

    setRowsPerPage(+event.target.value);
    setSearch((prev) => ({ ...prev, page: 0 }));
  };

  const handelAutocompleteDefaultValue = (data, id, fieldName) => {
    const defaultValue = data[Object.keys(data)[0]].find(
      (i) => i.id === parseInt(id)
    );
    defaultValue &&
      setAutocompleteValues((prev) => ({
        ...prev,
        [fieldName]: defaultValue,
      }));
  };
  const parseData = (data) => {
    return data;
  };

  const { data, loading } = useQuery(
    gql`
      ${LIST_JOURNAL_ENTRY.query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: {
        first: rowsPerPage,
        page: search?.page + 1,
        input: {
          fromDate: search.fromDate,
          toDate: search.toDate,
          ...(search.approved !== null && { approved: search.approved }),
          ...(search.branchId && { branchId: search.branchId }),
          ...(search.typeId && { typeId: search.typeId }),
          ...(search.glAccountId && { glAccountId: search.glAccountId }),
          ...(search.subsidiaryId && { subsidiaryId: search.subsidiaryId }),
          ...(search.code && { code: search.code }),
        },
      },
      onCompleted: (data) => {},
    }
  );

  const listJournalEntries =
    data?.listJournalEntries?.data !== null
      ? data?.listJournalEntries?.data
      : null;

  let tableBody;
  tableBody = (
    <Grid
      container
      justifyContent="center"
      spacing={3}
      className={clsx(classes.content, classes.mainSearch, {
        [classes.contentShift]: drawerState[filterAnchor],
      })}
    >
      {/* *******Table******* */}
      <Toolbar className={clsx(classes.toolbar)} variant="dense">
        <Typography
          className={classes.toolbarHeader}
          color="inherit"
          variant="h6"
        >
          {t("journalEntries")}
        </Typography>
        <IconButton onClick={toggleDrawer(filterAnchor, true)} size="large">
          <FilterList fontSize="inherit" />
        </IconButton>
        <SecuredNavLink
          permission="accounting.journal_entry.create"
          to={{ pathname: `/admin/finance/journal-entry/create` }}
        >
          <Tooltip title={t("addJournal")}>
            <IconButton aria-label={t("addJournal")} size="large">
              <Add color="primary" />
            </IconButton>
          </Tooltip>
        </SecuredNavLink>
      </Toolbar>

      {loading || listJournalEntries?.length === 0 ? (
        <Grid container item justifyContent="center" className={classes.main}>
          {loading ? (
            <FullScreenLoading minHeight={30} />
          ) : (
            <Box textAlign="center">
              <img width="50%" src={emptyList} alt={"delivery box"} />
              <Box
                component={Typography}
                variant="h6"
                color="text.secondary"
                marginTop="0"
              >
                {t("searchNoResult")}
              </Box>
            </Box>
          )}
        </Grid>
      ) : (
        <Paper className={classes.tablePaper}>
          <TableFixedHeaderWraper>
            <Table aria-labelledby="tableTitle" aria-label="enhanced table">
              <TableHead>
                <TableRow>
                  <FixedTableCell>{t("code")}</FixedTableCell>
                  <FixedTableCell>{t("date")}</FixedTableCell>
                  <FixedTableCell>{t("branch")}</FixedTableCell>
                  <FixedTableCell>{t("type")}</FixedTableCell>
                  <FixedTableCell>{t("subsidiary")}</FixedTableCell>
                  <FixedTableCell>{t("description")}</FixedTableCell>
                  <FixedTableCell>{t("approved")}</FixedTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {listJournalEntries &&
                  listJournalEntries?.map((row, index) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                        <CellLink
                          pathname={`/admin/finance/journal-entry/${row?.id}`}
                        >
                          {row?.code}
                        </CellLink>
                        <FixedTableCell>{row?.date}</FixedTableCell>
                        <FixedTableCell>{row?.branch.name}</FixedTableCell>
                        <FixedTableCell>{row?.type.name}</FixedTableCell>
                        <FixedTableCell>
                          {row?.subsidiary?.name ?? t("placeholder")}
                        </FixedTableCell>
                        <FixedTableCell>{row?.description}</FixedTableCell>
                        <FixedTableCell>
                          {row?.approved ? (
                            <Icon className={classes.iconColor}>
                              check_circle_outline
                            </Icon>
                          ) : (
                            <Icon color="error">highlight_off</Icon>
                          )}
                        </FixedTableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableFixedHeaderWraper>
          <MUITablePagination
            count={data?.listJournalEntries?.paginatorInfo?.total}
            rowsPerPage={rowsPerPage}
            page={!data?.listJournalEntries ? 0 : search?.page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      )}
    </Grid>
  );

  return (
    <Root>
      <Drawer
        className={clsx(classes.drawer)}
        variant="persistent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor={filterAnchor}
        open={drawerState[filterAnchor]}
        onClose={() => false}
        dir={theme.direction}
      >
        <div className={classes.drawerHeader} />
        <Toolbar
          variant="dense"
          className={classes.toolbar}
          sx={{ marginBottom: 1 }}
        >
          <Typography
            className={classes.titles}
            color="inherit"
            variant="h6"
            component="div"
          >
            {t("search")}
          </Typography>
          <IconButton
            aria-label="done"
            size="small"
            onClick={toggleDrawer(filterAnchor, false)}
            color={"primary"}
          >
            <Clear />
          </IconButton>
        </Toolbar>

        <Grid
          container
          item
          justifyContent="center"
          alignContent="space-between"
          component={"form"}
          onSubmit={handleSubmit(onSubmit)}
          className={classes.searchForm}
        >
          <Grid
            container
            item
            sm={12}
            justifyContent="flex-start"
            spacing={1}
            className={classes.searchField}
          >
            <Grid container item sm={12} alignItems="flex-start">
              <MUIDateRangeCustom
                value={dateRange}
                onChangeValue={(value) => setDateRange(value)}
              />
            </Grid>
            <Grid container item sm={12} alignItems="flex-start">
              <ControlMUItextField
                control={control}
                errors={errors}
                name={"code"}
                label={t("code")}
              />
            </Grid>
            <Grid container item sm={12} alignItems="flex-start">
              <MUIselect
                name={"approved"}
                label={t("approved")}
                control={control}
                errors={errors}
                data={[
                  { key: t("notApproved"), value: false },
                  { key: t("approved"), value: true },
                ]}
              />
            </Grid>

            <Grid container item sm={12} alignItems="flex-start">
              <CustomAutocomplete
                control={control}
                errors={errors}
                name={"branchId"}
                label={t("branch")}
                onCompleted={(data) =>
                  handelAutocompleteDefaultValue(
                    data,
                    urlQuery["branchId"],
                    "branch"
                  )
                }
                parseData={(data) => parseData(data)}
                query={LIST_BRANCHES_DROPDOWN.query}
                defaultValue={autocompleteValues.branch}
              />
            </Grid>
            <Grid container item sm={12} alignItems="flex-start">
              <CustomAutocomplete
                control={control}
                errors={errors}
                name={"typeId"}
                label={t("journalType")}
                parseData={(data) => parseData(data)}
                onCompleted={(data) =>
                  handelAutocompleteDefaultValue(
                    data,
                    urlQuery["typeId"],
                    "type"
                  )
                }
                query={LIST_JOURNAL_TYPES_DROPDOWN.query}
                defaultValue={autocompleteValues.type}
              />
            </Grid>
            <Grid container item sm={12} alignItems="flex-start">
              <CustomAutocomplete
                control={control}
                errors={errors}
                name={"glAccountId"}
                label={t("glAccount")}
                onCompleted={(data) =>
                  handelAutocompleteDefaultValue(
                    data,
                    urlQuery["glAccountId"],
                    "glAccount"
                  )
                }
                variables={{
                  input: {
                    typeCode: "SUB",
                  },
                }}
                parseData={(data) => parseData(data)}
                query={LIST_GL_ACCOUNTS_DROPDOWN.query}
                defaultValue={autocompleteValues.glAccount}
              />
            </Grid>
            <Grid container item sm={12} alignItems="flex-start">
              <CustomAutocomplete
                control={control}
                errors={errors}
                name={"subsidiaryId"}
                label={t("subsidiary")}
                onCompleted={(data) =>
                  handelAutocompleteDefaultValue(
                    data,
                    urlQuery["subsidiaryId"],
                    "subsidiary"
                  )
                }
                parseData={(data) => parseData(data)}
                query={LIST_SUBSIDIARIES_DROPDOWN.query}
                defaultValue={autocompleteValues.subsidiary}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            sm={12}
            className={clsx(classes.searchPadding, classes.button)}
            alignItems="center"
            alignContent="flex-end"
            justifyContent="flex-end"
          >
            <Button
              className={clsx(classes.btnMargin)}
              variant="contained"
              color="primary"
              type="submit"
              size="large"
              startIcon={<Search />}
              disabled={loading}
            >
              {t("search")}
            </Button>
          </Grid>
        </Grid>
      </Drawer>
      {tableBody}
    </Root>
  );
};

export default ListJournalEntry;
