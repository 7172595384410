import React, { useState } from "react";
import { styled } from '@mui/material/styles';
import clsx from "clsx";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  Collapse,
  TableHead,
  TableCell,
  TableRow,
  ListItem,
  ListItemText,
  List,
  Typography,
  Toolbar,
  Box,
} from "@mui/material";

import { gql, useQuery } from "@apollo/client";

import * as gqlb from "gql-query-builder";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { Fragment } from "react";
import MUItextField from "../HOC/MUI/MUItextField";
import emptyList from "../../assets/Image/received.png";
import { useTranslation } from "react-i18next";
import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";

const PREFIX = 'LogList';

const classes = {
  table: `${PREFIX}-table`,
  date: `${PREFIX}-date`,
  message: `${PREFIX}-message`,
  stack: `${PREFIX}-stack`,
  toolbar: `${PREFIX}-toolbar`,
  listItemActive: `${PREFIX}-listItemActive`,
  list: `${PREFIX}-list`,
  listItem: `${PREFIX}-listItem`
};

const StyledTableRow = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.table}`]: {
    display: "grid",
    padding: theme.spacing(1),
    alignContent: 'flex-start',

    "& .MuiTableCell-head": {
      lineHeight: "2.5rem",
    },
    "& .MuiTableCell-root": {
      fontSize: 13,
      verticalAlign: "top",
      padding: theme.spacing(1),
    },

    minHeight: "250px",
  },

  [`& .${classes.date}`]: {
    minWidth: 75,
  },

  [`& .${classes.message}`]: {
    whiteSpace: "pre-wrap",
    wordBreak: "break-all",
  },

  [`& .${classes.stack}`]: {
    fontSize: "0.85em",
  },

  [`& .${classes.toolbar}`]: {
    padding: theme.spacing(0, 0, 1, 0),
    width: "100%",
  },

  [`& .${classes.listItemActive}`]: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.action.hover,
  },

  [`& .${classes.list}`]: {
    width: "100%",
    borderRight: "1px solid " + theme.palette.divider,
  },

  [`& .${classes.listItem}`]: {
    "& span": {
      fontSize: 14,
    },
  }
}));

export const LISTLOGFILES = gqlb.query({
  operation: "listLogFiles",
  fields: [],
  variables: {},
});

export const LISTLOGENTRIES = gqlb.query({
  operation: "listLogEntries",
  fields: ["level", "context", "date", "message", "stack"],
  variables: {
    file: {
      type: "String",
      required: true,
    },
  },
});

const LogList = (props) => {

  const { t } = useTranslation();

  const [logs, setLogs] = React.useState();
  const [entries, setEntries] = React.useState();
  const [entrieName, setEntrieName] = React.useState();

  const { loading: logsLoading } = useQuery(
    gql`
      ${LISTLOGFILES.query}
    `,
    {
      notifyOnNetworkStatusChange: true,

      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      onCompleted: (data) => {
        setLogs(data.listLogFiles);
        setEntrieName(data["listLogFiles"][0]);
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );
  const { data: entriesData, loading: entriesLoading } = useQuery(
    gql`
      ${LISTLOGENTRIES.query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      skip: !entrieName,
      variables: { file: entrieName },
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      onCompleted: (data) => {
        setEntries(data.listLogEntries);
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  const noResult = (
    <Box textAlign="center">
      <img width="50%" src={emptyList} alt={"delivery box"} />
      <Box
        component={Typography}
        variant="h6"
        color="text.secondary"
        marginTop="0"
      >
        {t("searchNoResult")}
      </Box>
    </Box>
  );

  return (
    <Fragment>
      <StyledTableRow>
        <Grid container justifyContent="flex-start">
          {!logs ? (
            <Grid
              container
              item
              justifyContent="center"
              alignItems="center"
              sx={{ margin: "10% 0" }}
            >
              {!logs && !logsLoading ? noResult : <FullScreenLoading />}
            </Grid>
          ) : (
            <>
              <Grid container item sm={2}>
                <List className={classes.list}>
                  {logs.map((i, index) => (
                    <ListItem
                      key={index}
                      className={clsx({
                        [classes.listItemActive]: i === entrieName,
                      })}
                      button
                      onClick={() => {
                        setEntrieName(i);
                        i !== entrieName && setEntries(null);
                      }}
                    >
                      <ListItemText className={classes.listItem} primary={i} />
                    </ListItem>
                  ))}
                </List>
              </Grid>
              <Grid
                container
                alignItems="flex-start"
                item
                sm={10}
                className={classes.table}
              >
                <Toolbar variant="dense" className={classes.toolbar}>
                  <MUItextField
                    label="Search"
                    name="search"

                    onChange={(e) => {
                      const value = e.target.value.toLowerCase();
                      const filter =
                        value !== ""
                          ? entriesData?.listLogEntries?.filter(
                            (i) =>
                              i.message.toLowerCase().includes(value) ||
                              i.stack.toLowerCase().includes(value)
                          )
                          : entriesData?.listLogEntries;
                      setEntries(filter);
                    }}
                  />
                </Toolbar>
                <TableFixedHeaderWraper component={Paper}>
                  {!entriesLoading && entries ? (
                    <Table aria-label="collapsible table">
                      <TableHead>
                        <TableRow>
                          <TableCell>message</TableCell>
                          <TableCell>level</TableCell>
                          <TableCell>context</TableCell>
                          <TableCell>date</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {entries.map((i, index) => (
                          <LogRow key={index} logData={i} />
                        ))}
                      </TableBody>
                    </Table>
                  ) : (
                    <Grid
                      container
                      item
                      justifyContent="center"
                      alignItems="center"
                      sx={{ margin: "10% 0" }}
                    >
                      {!entriesLoading && logs?.length === 0 ? (
                        noResult
                      ) : (
                        <FullScreenLoading />
                      )}
                    </Grid>
                  )}
                </TableFixedHeaderWraper>
              </Grid>
            </>
          )}
        </Grid>
      </StyledTableRow>
    </Fragment>
  );
};

export default LogList;

const LogRow = (props) => {

  const { logData } = props;
  const [open, setopen] = useState(false);
  return (
    <TableRow hover>
      <TableCell
        className={classes.message}
        onClick={() => setopen((prev) => !prev)}
      >
        {logData.message}
        <Collapse in={open} timeout="auto" unmountOnExit>
          <div className={classes.stack}>{logData.stack}</div>
        </Collapse>
      </TableCell>
      <TableCell>{logData.level}</TableCell>
      <TableCell>{logData.context}</TableCell>
      <TableCell className={classes.date}>{logData.date}</TableCell>
    </TableRow>
  );
};
