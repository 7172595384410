/* eslint-disable no-useless-concat */
import React, { Fragment } from "react";
import { gql, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { Can, SecuredNavLink } from "../HOC/CustomComponents/Secured";
import {
  Typography,
  Paper,
  Toolbar,
  IconButton,
} from "@mui/material";
import { DeleteOutline, Edit } from "@mui/icons-material";
import { BARCODEBATCH, DELETE_BARCODEBATCH_MUTATION } from "./Graphql";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { KeyValuePair } from "../HOC/CustomComponents/KeyValuePair";
import Grid from "@mui/material/Unstable_Grid2";
import styled from "@emotion/styled";
import MutationWithDialog from "../HOC/CustomComponents/MutationWithDialog";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import SpanLink from "../HOC/CustomComponents/SpanLink";
import PrintIcon from "../Shipments/PrintIcon";
import PrintComponent from "../Shipments/PrintComponent";

const PREFIX = "GenrateBarcodesView";

const classes = {
  loading: `${PREFIX}-loading`,
  paper: `${PREFIX}-paper`,
  toolbar: `${PREFIX}-toolbar`,
  toolbarIcons: `${PREFIX}-toolbarIcons`,
  iconButton: `${PREFIX}-iconButton`,
  main: `${PREFIX}-main`,
  field: `${PREFIX}-field`,
  iconColor: `${PREFIX}-iconColor`,
  toolbarTable: `${PREFIX}-toolbarTable`,
  table: `${PREFIX}-table`,
  title: `${PREFIX}-title`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.loading}`]: {},

  [`& .${classes.paper}`]: {
    padding: theme.spacing(3),
    flexGrow: 1,
  },

  [`& .${classes.toolbar}`]: {
    borderBottom: "1px #ccd1d6 solid",
    backgroundColor: "#f5f7f9",
    width: "100%",
  },

  [`& .${classes.toolbarIcons}`]: {
    flex: "1 1 100%",
    textAlign: "end",
  },

  [`& .${classes.toolbarTable}`]: {
    width: "100%",
    backgroundColor: theme.palette.grey[50],
    borderBottom: "1px solid " + theme.palette.divider,
    borderRight: "1px solid " + theme.palette.divider,
  },
  [`& .${classes.table}`]: {
    display: "grid",
    minWidth: "100%",
  },
  [`& .${classes.title}`]: {
    flex: 1,
  },
  [`& .${classes.iconButton}`]: {
    borderRadius: "0 4px 4px 0 ",
    boxShadow: "none",
    height: 45,
  },

  [`& .${classes.main}`]: {
    margin: 0,
    width: "100%",
  },

  [`& .${classes.field}`]: {
    width: 260,
    [theme.breakpoints.down("sm")]: {
      width: 170,
    },
  },

  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },
}));

const GenrateBarcodesView = (props) => {
  const GenrateBarcodesId = props.id ?? props.match?.params?.id?.trim();
  const { t } = useTranslation();
  const { loading, data } = useQuery(
    gql`
      ${BARCODEBATCH.query}
    `,
    {
      fetchPolicy: "network-only",
      variables: { id: parseInt(GenrateBarcodesId) },
    }
  );
  const barcodeBatch = data?.barcodeBatch
  const barcodes = data?.barcodeBatch?.barcodes
    ?.map((i) => i.code)
    .toString()
    .replace(/,/g, " , ");

  return (
    <StyledGrid
      container
      justifyContent="center"
      sx={{ margin: "0", width: "100%" }}
    >
      {!props.id ? (
        <Fragment>
          <Toolbar className={clsx(classes.toolbar)} variant="regular">
            <Grid
              container
              sx={{ flexWrap: "initial", flexGrow: 1 }}
              alignItems="center"
            >
              <Grid className={classes.field}>
                <Typography variant="h6">{t("barcodeBatchDetails")}</Typography>
              </Grid>
              <Grid className={classes.toolbarIcons}>
                <Can showException permission={!Boolean(barcodeBatch?.editable)}>
                  <PrintComponent shipmentsId={GenrateBarcodesId} batch='batch' />
                </Can>
                <SecuredNavLink
                  permission={Boolean(barcodeBatch?.editable)}
                  to={{ pathname: `/admin/barcode-batches/${GenrateBarcodesId}/edit` }}
                >
                  <IconButton color={"primary"} size="large">
                    <Edit fontSize="inherit" />
                  </IconButton>
                </SecuredNavLink>
                <MutationWithDialog
                  mutaion={DELETE_BARCODEBATCH_MUTATION.query}
                  icon={DeleteOutline}
                  permission={Boolean(barcodeBatch?.deletable)}
                  iconTooltip={t("deleteBarcode")}
                  dialogTitle={t("deleteBarcode")}
                  dialogContent={t("deleteBarcodeMessage")}
                  mutaionProps={{ variables: { id: parseInt(GenrateBarcodesId) } }}
                  onCompleted={() => pushUrl(props, `/admin/barcode-batches`)}
                  onCompleteMessage={t("deleteMessage")}
                />
              </Grid>
            </Grid>
          </Toolbar>
        </Fragment>
      ) : null}

      <Grid
        container
        justifyContent="center"
        sx={{ width: "100%", p: 2, gap: 2 }}
      >
        {loading ? (
          <FullScreenLoading minHeight="25%" />
        ) : (
          <Fragment>
            <Paper container xs={12} className={classes.paper} component={Grid}>
              <KeyValuePair
                title={t("branch")}
                value={
                  barcodeBatch?.branch ? (
                    <SpanLink
                      pathname={`/admin/branches/${barcodeBatch?.branch?.id}`}
                    >
                      {barcodeBatch?.branch?.name}
                    </SpanLink>
                  ) : (
                    "--"
                  )
                }
              />
              <KeyValuePair
                title={t("transactionTypeName")}
                value={
                  barcodeBatch?.transactionType ? (
                    <SpanLink
                      pathname={`/admin/shipment-transaction-types/${barcodeBatch?.transactionType?.id}`}
                    >
                      {barcodeBatch?.transactionType?.name}
                    </SpanLink>
                  ) : (
                    "--"
                  )
                }
              />
              <KeyValuePair
                title={t("customer")}
                value={
                  barcodeBatch?.customer ? (
                    <SpanLink
                      pathname={`/admin/customers/${barcodeBatch?.customer?.id}`}
                    >
                      {barcodeBatch?.customer?.name}
                    </SpanLink>
                  ) : (
                    "--"
                  )
                }
              />
              <KeyValuePair
                title={t("createdBy")}
                value={
                  barcodeBatch?.createdBy ? (
                    <SpanLink
                      pathname={`/admin/users/${barcodeBatch?.createdBy?.id}`}
                    >
                      {barcodeBatch?.createdBy?.username}
                    </SpanLink>
                  ) : (
                    "--"
                  )
                }
              />
              <KeyValuePair
                title={t("updatedBy")}
                value={
                  barcodeBatch?.updatedBy ? (
                    <SpanLink
                      pathname={`/admin/users/${barcodeBatch?.updatedBy?.id}`}
                    >
                      {barcodeBatch?.updatedBy?.username}
                    </SpanLink>
                  ) : (
                    "--"
                  )
                }
              />
              <KeyValuePair
                title={t("barcode")}
                value={barcodes}
              />
            </Paper>
          </Fragment>
        )}
      </Grid>
    </StyledGrid>
  );
};

export default GenrateBarcodesView;
