import React from "react";
import { styled } from '@mui/material/styles';
import clsx from "clsx";
import {
  Paper,
  Typography,
  Collapse,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { UPDATE_ORGANIZATION, ORGANIZATION } from "./Graphql";
import { useMutation, gql, useQuery } from "@apollo/client";
import FormButton from "../CustomComponents/Buttons/FormButton";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import { useSnackbar } from "notistack";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import Grid from "@mui/material/Unstable_Grid2";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";

const PREFIX = 'UpdateOrganizationData';

const classes = {
  spacing: `${PREFIX}-spacing`,
  mainGrid: `${PREFIX}-mainGrid`,
  appBar: `${PREFIX}-appBar`,
  priceList: `${PREFIX}-priceList`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.spacing}`]: {
    width: "100%",
    margin: theme.spacing(2, 0, 0, 0),
  },

  [`& .${classes.mainGrid}`]: {
    width: "100%",
    margin: theme.spacing(0),
    padding: theme.spacing(2),
  },

  [`& .${classes.appBar}`]: {
    position: "relative",
  },

  [`& .${classes.priceList}`]: {
    flexWrap: "nowrap",
  }
}));

const UpdateOrganizationData = (props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const {
    handleSubmit,
    control,
    formState,
    setValue,
    setError,
  } = useForm();
  const { errors } = formState;

  const { data, loading: dataLoading } = useQuery(
    gql`
      ${ORGANIZATION.query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      variables: {},
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        const organizationData = data.organization;
        const OrganizationParams = [
          "name",
          "owner",
          "activity",
          "registrationNumber",
          "taxArea",
          "taxNumber",
          "phone",
          "email",
          "website",
          "reportHeader",
          "reportFooter",
        ];
        OrganizationParams.forEach((i) => {
          organizationData[i] && setValue(i, organizationData[i]);
        });
      },
    }
  );


  const [updateOrganization, { loading }] = useMutation(
    gql`
      ${UPDATE_ORGANIZATION.query}
    `
  );

  const onSubmit = (data) => {
    updateOrganization({
      variables: {
        input: {
          ...data,
        },
      },
    })
      .then((data) => {
        pushUrl(props, `/admin/organization`);

        enqueueSnackbar(t("saveSuccessful"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      })
      .catch(({ graphQLErrors }) => {
        setValidationError(graphQLErrors, setError);
        console.log(graphQLErrors);
      });
  };

  const body = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid
        container
        justifyContent="flex-start"
        alignItems="center"
        className={clsx(classes.mainGrid)}
        spacing={2}
      >
        <Paper container component={Grid} className={clsx(classes.spacing)}>
          <Grid
            justifyContent="space-between"
            xs={12}
            sx={{ display: "flex", flexWrap: "wrap" }}
          >
            <Typography variant="h6">{t("organizationData")}</Typography>
          </Grid>
          <Grid sm={6} xs={12} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"name"}
              label={t("organizationName")}
              rules={{ required: t("fieldIsRequired") }}
            />
          </Grid>
          <Grid sm={6} xs={12} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"phone"}
              label={t("mobile")}
            />
          </Grid>
          <Grid sm={6} xs={12} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"owner"}
              label={t("ownerName")}
              rules={{ required: t("fieldIsRequired") }}
            />
          </Grid>
          <Grid sm={6} xs={12} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"email"}
              label={t("email")}
            />
          </Grid>
          <Grid sm={6} xs={12} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"activity"}
              label={t("activity")}
            />
          </Grid>
          <Grid sm={6} xs={12} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"website"}
              label={t("website")}
            />
          </Grid>
          <Grid sm={6} xs={12} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"registrationNumber"}
              label={t("registrationNumber")}
            />
          </Grid>
          <Grid sm={6} xs={12} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"taxArea"}
              label={t("taxArea")}
            />
          </Grid>
          <Grid sm={6} xs={12} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"taxNumber"}
              label={t("taxNumber")}
            />
          </Grid>
          <Grid sm={6} xs={12} alignItems="flex-start">
            
          </Grid>
          <Grid sm={6} xs={12} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              rows={5}
              name={"reportHeader"}
              label={t("reportHeader")}
            />
          </Grid>
          <Grid sm={6} xs={12} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              rows={5}
              name={"reportFooter"}
              label={t("reportFooter")}
            />
          </Grid>
        </Paper>

        <Grid container justifyContent="flex-end" className={classes.spacing}>
          <FormButton disabled={loading}>
            {loading ? <ButtonLoading /> : t("save")}
          </FormButton>
        </Grid>
      </Grid>
    </form>
  );

  return (
    <Root>
      {
        dataLoading ? (<FullScreenLoading minHeight="25%" />) : (
          body
        )}
    </Root >
  );
};

export default UpdateOrganizationData;
