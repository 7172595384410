import React, { Fragment, useEffect } from "react";
import { styled } from '@mui/material/styles';
import clsx from "clsx";
import { Paper, Typography, Collapse, Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { SAVE_WARE_HOUSE, WARE_HOUSE_ID } from "./Graphql";
import { useMutation, gql, useQuery } from "@apollo/client";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { useState } from "react";
import FormButton from "../CustomComponents/Buttons/FormButton";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import { useSnackbar } from "notistack";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import {
  LIST_BRANCHES_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import MuiSwitch from "../HOC/MUI/MUIswitch";
import Grid from "@mui/material/Unstable_Grid2";

const PREFIX = 'WarehouseForm';

const classes = {
  spacing: `${PREFIX}-spacing`,
  mainGrid: `${PREFIX}-mainGrid`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.spacing}`]: {
    width: "100%",
    margin: theme.spacing(2, 0, 0, 0),
  },

  [`& .${classes.mainGrid}`]: {
    width: "100%",
    margin: theme.spacing(0),
    padding: theme.spacing(2),
  }
}));

const WarehouseForm = (props) => {
  const [autocompleteValues, setAutocompleteValues] = useState({
    branch: null,
  });


  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { handleSubmit, control, formState, setValue, setError } =
    useForm();
  const { errors } = formState;
  const warehouseId = parseInt(props.match.params.id);

  useEffect(() => {
    if (!warehouseId) {
      setValue("active", true);
    }

    return () => { };
  }, []);

  const selectDefaultValue = (data) => {
    return data;
  };

  const { data: updateWareHouse } = useQuery(
    gql`
      ${WARE_HOUSE_ID.query}
    `,
    {
      skip: !warehouseId,
      notifyOnNetworkStatusChange: true,
      variables: { id: warehouseId },
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        const wareHouseData = data.warehouseSection;
        const wareHouseParams = [
          "id",
          "name",
        ];
        wareHouseParams.forEach((i) => {
          wareHouseData[i] && setValue(i, wareHouseData[i]);
        });
        setValue("active", wareHouseData["active"]);

        setAutocompleteValues({
          branch: wareHouseData?.branch,
        });
      },
    }
  );

  const [saveWareHouse, { loading: saveWareHouseLoading }] = useMutation(
    gql`
      ${SAVE_WARE_HOUSE.query}
    `
  );

  const onSubmit = (data) => {
    for (const key in data) {
      if (data[key] === "") {
        data[key] = null;
      }
    }
    saveWareHouse({
      variables: {
        input: {
          ...data,
        },
      },
    })
      .then((data) => {
        pushUrl(props, `/admin/warehouse/${data?.data?.saveWarehouseSection?.id}`);
        enqueueSnackbar(t("saveSuccessful"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      })
      .catch(({ graphQLErrors }) => {
        setValidationError(graphQLErrors, setError);
        console.log(graphQLErrors);
      });
  };

  const body = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid
        container
        justifyContent="flex-start"
        aligns="center"
        className={clsx(classes.mainGrid)}
        spacing={2}
      >
        <Paper container component={Grid} className={clsx(classes.spacing)}>
          <Grid container justifyContent="space-between" xs={12}>
            <Typography variant="h6">{t("wareHouse")}</Typography>
            <Box>
              <MuiSwitch
                edge="end"
                name="active"
                label={t("active")}
                control={control}
              />
            </Box>
          </Grid>

          <Grid xs={12} sm={6} aligns="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"name"}
              label={t("name")}
              rules={{ required: t("fieldIsRequired") }}
            />
          </Grid>

          <Grid xs={12} sm={6} aligns="flex-start">
            <CustomAutocomplete
              control={control}
              errors={errors}
              name={"branchId"}
              label={t("branch")}
              parseData={(data) => selectDefaultValue(data)}
              query={LIST_BRANCHES_DROPDOWN.query}
              defaultValue={autocompleteValues.branch}
              rules={{ required: t("fieldIsRequired") }}
            />
          </Grid>


        </Paper>
        <Grid container justifyContent="flex-end" className={classes.spacing}>
          <FormButton disabled={saveWareHouseLoading}>
            {saveWareHouseLoading ? <ButtonLoading /> : t("save")}
          </FormButton>
        </Grid>
      </Grid>
    </form>
  );
  let DOM;

  if (warehouseId) {
    DOM = updateWareHouse ? body : <FullScreenLoading minHeight="10%" />;
  } else {
    DOM = body;
  }

  return <Root>{DOM}</Root>;
};

export default WarehouseForm;
