/* eslint-disable no-useless-concat */
import { gql, useQuery } from "@apollo/client";
import { styled } from '@mui/material/styles';
import { Edit } from "@mui/icons-material";
import { IconButton, Paper, Toolbar, Typography } from "@mui/material";
import clsx from "clsx";
// import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { Globals } from "../HOC/Classes/Globals";
import { KeyValuePair } from "../HOC/CustomComponents/KeyValuePair";
import { SecuredNavLink } from "../HOC/CustomComponents/Secured";
// import SpanLink from "../HOC/CustomComponents/SpanLink";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
// import PieCharts from "../Home/PieCharts";
// import StatisticsFilterHeader from "../Home/StatisticsFilterHeader";
import { ORGANIZATION } from "./Graphql";
import Grid from "@mui/material/Unstable_Grid2";

const PREFIX = 'OrganizationDataView';

const classes = {
  box: `${PREFIX}-box`,
  toolbar: `${PREFIX}-toolbar`,
  toolbarIcons: `${PREFIX}-toolbarIcons`,
  iconButton: `${PREFIX}-iconButton`,
  main: `${PREFIX}-main`,
  field: `${PREFIX}-field`,
  iconColor: `${PREFIX}-iconColor`
};

const StyledGrid = styled(Grid)((
  {
    theme
  }
) => ({
  [`& .${classes.box}`]: {
    padding: theme.spacing(3),
    flexGrow: 1,
  },

  [`& .${classes.toolbar}`]: {
    borderBottom: "1px #ccd1d6 solid",
    backgroundColor: "#f5f7f9",
    width: "100%",
  },

  [`& .${classes.toolbarIcons}`]: {
    flex: "1 1 100%",
    textAlign: "end",
  },

  [`& .${classes.iconButton}`]: {
    borderRadius: "0 4px 4px 0 ",
    boxShadow: "none",
    height: 45,
  },

  [`& .${classes.main}`]: {
    margin: 0,
    width: "100%",
  },

  [`& .${classes.field}`]: {
    width: 260,
    [theme.breakpoints.down("sm")]: {
      width: 170,
    },
  },

  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  }
}));
const OrganizationDataView = (props) => {
  const { t } = useTranslation();
  const { loading, data } = useQuery(
    gql`
      ${ORGANIZATION.query}
    `,
    {
      fetchPolicy: "network-only",
      variables: {},
    }
  );


  const user = Globals.user;

  const canEdit =
    user.hasPermission("core.organization.update");

  return (
    <StyledGrid container justifyContent="center" sx={{ margin: "0", width: "100%" }}>
      {loading ? (
        <FullScreenLoading minHeight="25%" />
      ) : (
        <>
          <Toolbar className={clsx(classes.toolbar)} variant="regular">
            <Grid
              container
              sx={{ flexWrap: "initial", flexGrow: 1 }}
              alignItems="center"
            >
              <Grid className={classes.field}>
                <Typography variant="h6">{t("organizationData")}</Typography>
              </Grid>
              <Grid className={classes.toolbarIcons}>
                <SecuredNavLink
                  permission={canEdit}
                  to={{ pathname: `/admin/organization/edit` }}
                >
                  <IconButton color={"primary"} size="large">
                    <Edit fontSize="inherit" />
                  </IconButton>
                </SecuredNavLink>
              </Grid>
            </Grid>
          </Toolbar>

          <Grid
            container
            justifyContent="center"
            sx={{ width: "100%", p: 2, gap: 2 }}
          >
            <Paper container className={classes.box} component={Grid}>
              <KeyValuePair title={t("organizationName")} value={data?.organization.name} />
              <KeyValuePair title={t("ownerName")} value={data?.organization.owner} />
              <KeyValuePair title={t("activity")} value={data?.organization.activity} />
              <KeyValuePair title={t("registrationNumber")} value={data?.organization.registrationNumber} />
              <KeyValuePair title={t("mobile")} value={data?.organization.phone} />
              <KeyValuePair title={t("email")} value={data?.organization.email} />
              <KeyValuePair title={t("website")} value={data?.organization.website} />
              <KeyValuePair title={t("taxArea")} value={data?.organization.taxArea} />
              <KeyValuePair title={t("taxNumber")} value={data?.organization.taxNumber} />
              <KeyValuePair title={t("reportHeader")} value={data?.organization.reportHeader} />
              <KeyValuePair title={t("reportFooter")} value={data?.organization.reportFooter} />
            </Paper>
          </Grid>
        </>
      )}
    </StyledGrid>
  );
};

export default OrganizationDataView;
