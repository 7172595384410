import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Typography,
  Paper,
  Grid,
  Table,
  TableRow,
  TableBody,
  TableHead,
  IconButton,
  Toolbar,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import { Add, Delete, Edit } from "@mui/icons-material";
import clsx from "clsx";
import EmptyTableMessage from "../HOC/FunctionComponents/EmptyTableMessage";
import ShipmentRegion from "../HOC/CustomComponents/ShipmentRegion";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";

const PREFIX = "StopsTable";

const classes = {
  p: `${PREFIX}-p`,
  tap: `${PREFIX}-tap`,
  table: `${PREFIX}-table`,
  tableContainer: `${PREFIX}-tableContainer`,
  title: `${PREFIX}-title`,
  toolbar: `${PREFIX}-toolbar`,
  focus: `${PREFIX}-focus`,
  destinations: `${PREFIX}-destinations`,
  deleteIcon: `${PREFIX}-deleteIcon`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.p}`]: {
    fontSize: "14px",
    margin: "2px 1px",
    width: 100,
  },

  [`& .${classes.tap}`]: {
    margin: 0,
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    // flexDirection: "column",
    textTransform: "capitalize",
  },

  [`& .${classes.table}`]: {
    display: "grid",
  },

  [`& .${classes.tableContainer}`]: {
    minHeight: "250px",
  },

  [`& .${classes.title}`]: {
    flex: 1,
  },

  [`& .${classes.toolbar}`]: {
    width: "100%",
    backgroundColor: theme.palette.grey[50],
    borderBottom: "1px solid " + theme.palette.divider,
    borderRight: "1px solid " + theme.palette.divider,
  },

  [`& .${classes.focus}`]: {
    backgroundColor: theme.palette.action.hover,
  },

  [`& .${classes.destinations}`]: {
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(2),
    },
  },

  [`& .${classes.deleteIcon}`]: {
    marginLeft: theme.spacing(1),
  },
}));

const StopsTable = (props) => {
  const { stops = [], addStop, setStopsList } = props;
  const { t } = useTranslation();
  const [stopIndex, setStopIndex] = useState(0);
  const [stopsPage, setStopPage] = useState(0);
  const [stopRowsPerPage, setstopRowsPerPage] = useState(20);

  const handleStopsPage = (event, newPage) => {
    setStopPage(newPage);
  };
  const handleStopsRowsPerPage = (event) => {
    setstopRowsPerPage(parseInt(event.target.value));
    setStopPage(0);
  };

  return (
    <StyledGrid container alignItems="flex-start">
      <Grid container item sm={12} component={Paper}>
        <Toolbar className={classes.toolbar} variant="dense">
          <Typography className={classes.title} color="inherit" variant="h6">
            {t("stops")}
          </Typography>

          {addStop ? (
            <IconButton
              color="primary"
              onClick={() => {
                addStop();
                setStopIndex(0);
              }}
              aria-label="Add stop"
              size="large"
            >
              <Add />
            </IconButton>
          ) : null}
        </Toolbar>
        <Grid container item className={classes.table}>
          {stops.length <= 0 ? (
            <EmptyTableMessage loading={false} message={t("searchNoResult")} />
          ) : (
            <TableFixedHeaderWraper className={classes.tableContainer}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <FixedTableCell>{t("order")}</FixedTableCell>
                    <FixedTableCell>{t("destination")}</FixedTableCell>
                    {addStop ? <FixedTableCell></FixedTableCell> : null}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {stops.length > 0 &&
                    (stopRowsPerPage > 0
                      ? stops.slice(
                          stopsPage * stopRowsPerPage,
                          stopsPage * stopRowsPerPage + stopRowsPerPage
                        )
                      : stops
                    ).map((row, index) => (
                      <TableRow
                        className={clsx({
                          [classes.focus]:
                            stopsPage * stopRowsPerPage + index === stopIndex,
                        })}
                        hover
                        key={index}
                        onClick={() => {
                          setStopPage(0);
                          setStopIndex(stopsPage * stopRowsPerPage + index);
                        }}
                      >
                        <FixedTableCell>{row.order}</FixedTableCell>
                        <ShipmentRegion
                          pathname={`/admin/zone/${row?.zone?.id}`}
                          zone={row?.zone?.name}
                          subzone={row?.subzone?.name}
                        />
                        {addStop ? (
                          <FixedTableCell>
                            {/* <IconButton
                              size="small"
                              onClick={() =>
                                addStop(stopsPage * stopRowsPerPage + index)
                              }
                            >
                              <Edit />
                            </IconButton> */}
                            <IconButton
                              size="small"
                              className={classes.deleteIcon}
                              onClick={() =>
                                setStopsList((prev) => {
                                  const filterd = prev.filter(
                                    (i, deleteIndex) => deleteIndex !== index
                                  );
                                  return filterd;
                                })
                              }
                            >
                              <Delete />
                            </IconButton>
                          </FixedTableCell>
                        ) : null}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableFixedHeaderWraper>
          )}
          <MUITablePagination
            count={stops?.length ?? 0}
            rowsPerPage={stopRowsPerPage}
            page={stopsPage}
            onPageChange={handleStopsPage}
            onRowsPerPageChange={handleStopsRowsPerPage}
          />
        </Grid>
      </Grid>
    </StyledGrid>
  );
};

export default StopsTable;
