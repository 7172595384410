import * as gqlb from "gql-query-builder";

export const LIST_PAYMENT_QUERY = gqlb.query([
  {
    operation: "listPayments",
    fields: [
      {
        operation: "paginatorInfo",
        fields: [
          "count",
          "currentPage",
          "total",
          "firstItem",
          "hasMorePages",
          "lastItem",
          "lastPage",
          "perPage",
        ],
        variables: {},
      },
      {
        operation: "data",
        fields: [
          "code",
          "id",
          "date",
          "approved",
          "glApproved",
          "paymentAmount",
          "dueFees",
          "notes",
          "shipmentCount",
          "pickupCount",
          "collectedFees",
          "deliveredAmount",
          "weight",
          "piecesCount",
          {
            operation: "transactionType",
            fields: ["id", "name"],
            variables: {},
          },
          {
            operation: "safe",
            fields: ["id", "name"],
            variables: {},
          },
          {
            operation: "deliveryAgent",
            fields: ["id", "name"],
            variables: {},
          },
          {
            customer: ["id", "name"],
          },
        ],
        variables: {},
      },
    ],
    variables: {
      input: {
        type: "ListPaymentFilterInput",
        required: true,
      },
      first: {
        type: "Int",
        required: true,
        value: 15,
      },
      page: {
        type: "Int",
        value: 1,
      },
    },
  },
  {
    operation: "sumPayments",
    fields: ["paymentAmount", "dueFees", "shipmentCount","pickupCount"],
    variables: {
      input: {
        type: "ListPaymentFilterInput",
        required: true,
      },
    },
  },
]);

export const paymentFields = [
  "id",
  "date",
  "code",
  "approved",
  "glApproved",
  "notes",
  "collectedFees",
  "deliveredAmount",
  "dueFees",
  "weight",
  "piecesCount",
  "paymentAmount",
  {
    type: ["code"],
  },
  {
    createdBy: ["username", "id"],
  },
  {
    updatedBy: ["username", "id"],
  },
  {
    customer: [
      "id",
      "code",
      "name",
      "mobile",
      "phone",
      "address",
      { zone: ["name"] },
      { subzone: ["name"] },
    ],
  },
  {
    operation: "branch",
    fields: ["id", "name"],
    variables: {},
  },
  {
    transactionType: ["id", "code", "name", { type: ["name", "code"] }],
  },
  {
    operation: "deliveryAgent",
    fields: ["id", "name", "code"],
    variables: {},
  },
  {
    operation: "safe",
    fields: ["id", "name"],
    variables: {},
  },

  {
    operation: "entries",
    fields: [
      "paidAmount",
      {
        operation: "shipment",
        fields: [
          "id",
          "code",
          "recipientName",
          "recipientMobile",
          "description",
          "recipientAddress",
          "deliveredAmount",
          "collectedFees",
          "returningDueFees",
          "piecesCount",
          "weight",
          "date",
          "senderName",
          "deliveredCanceledDate",
          {
            recipientZone: ["id", "name"],
          },
          {
            recipientSubzone: ["name"],
          },
          {
            deliveryType: ["name"],
          },
          {
            paymentType: ["name"],
          },
          {
            status: ["name"],
          },
        ],
        variables: {},
      },
      {
        operation: "pickup",
        fields: [
          "id",
          "code",
          "date",
          "notes",
          {
            customer: [
              "id",
              "code",
              "name",
            ],
          },
        ],
        variables: {},
      },

    ],
    variables: {},
  },
];

export const PAYMENT_BY_ID_QUERY = gqlb.query({
  operation: "payment",
  fields: paymentFields,
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const PAYMENT_PRINT = gqlb.query([
  {
    operation: "payment",
    fields: paymentFields,
    variables: {
      id: {
        type: "Int",
        required: true,
      },
    },
  },
  {
    operation: "organization",
    fields: [
      "name",
      "phone",
      "email",
      "website",
      "reportHeader",
      "reportFooter",
    ],
    variables: {},
  },
]);

export const PAYMENT_VIEW = (permission) =>
  gqlb.query([
    {
      operation: "payment",
      fields: [
        "id",
        "date",
        "code",
        "approved",
        "glApproved",
        "notes",
        "collectedFees",
        "deliveredAmount",
        "dueFees",
        "weight",
        "piecesCount",
        "paymentAmount",
        {
          type: ["code"],
        },
        {
          createdBy: ["username", "id"],
        },
        {
          updatedBy: ["username", "id"],
        },
        {
          operation: `journalEntry @include(if: ${permission})`,
          fields: ["code", "id"],
          variables: {},
        },
        {
          customer: [
            "id",
            "code",
            "name",
            "mobile",
            "phone",
            "address",
            { zone: ["name"] },
            { subzone: ["name"] },
          ],
        },
        {
          operation: "branch",
          fields: ["id", "name"],
          variables: {},
        },
        {
          transactionType: ["id", "code", "name", { type: ["name", "code"] }],
        },
        {
          operation: "deliveryAgent",
          fields: ["id", "name", "code"],
          variables: {},
        },
        {
          operation: "safe",
          fields: ["id", "name"],
          variables: {},
        },

        {
          operation: "entries",
          fields: [
            "paidAmount",
            {
              operation: "shipment",
              fields: [
                "id",
                "code",
                "recipientName",
                "recipientMobile",
                "description",
                "recipientAddress",
                "deliveredAmount",
                "collectedFees",
                "returningDueFees",
                "piecesCount",
                "weight",
                "date",
                "senderName",
                "deliveredCanceledDate",
                {
                  recipientZone: ["id", "name"],
                },
                {
                  recipientSubzone: ["name"],
                },
                {
                  deliveryType: ["name"],
                },
                {
                  paymentType: ["name"],
                },
                {
                  status: ["name"],
                },
              ],
              variables: {},
            },
            {
              operation: "pickup",
              fields: [
                "id",
                "code",
                "date",
                "notes",
                {
                  customer: [
                    "id",
                    "code",
                    "name",
                  ],
                },
              ],
              variables: {},
            },
          ],
          variables: {},
        },
      ],
      variables: {
        id: {
          type: "Int",
          required: true,
        },
      },
    },
    {
      operation: "organization",
      fields: [
        "name",
        "phone",
        "email",
        "website",
        "reportHeader",
        "reportFooter",
      ],
      variables: {},
    },
  ]);

export const SAVE_PAYMENT_MUTATION = gqlb.mutation({
  operation: "savePayment",
  fields: paymentFields,
  variables: {
    input: {
      type: "PaymentInput",
      required: true,
    },
  },
});

export const DELETE_PAYMENT_MUTATION = gqlb.mutation({
  operation: "deletePayment",
  fields: [],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
export const APPROVE_PAYMENT_MUTATION = gqlb.mutation({
  operation: "approvePayment",
  fields: paymentFields,
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
export const DISAPPROVE_PAYMENT_MUTATION = gqlb.mutation({
  operation: "disapprovePayment",
  fields: ["id"],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
export const GL_APPROVE_PAYMENT_MUTATION = gqlb.mutation({
  operation: "glApprovePayment",
  fields: paymentFields,
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
