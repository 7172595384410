import React, { useEffect, useState } from "react";
import { Button, Collapse, FormControlLabel, Grid, Switch } from "@mui/material";
import { SAVE_SUBSIDIARY } from "./Graphql";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { useMutation, gql } from "@apollo/client";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import { useSnackbar } from "notistack";
import { DialogActions } from "@mui/material";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import CustomDialog from "../HOC/CustomComponents/CustomDialog";

export default function SubsidiaryForm(props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { handleSubmit, control, formState, setValue, setError } = useForm();
  const { errors } = formState;
  const [activeUser, setActiveUser] = useState(true);

  const [saveSubsidiary, { loading: saveSubsidiaryLoading }] = useMutation(
    gql`
      ${SAVE_SUBSIDIARY.query}
    `
  );

  useEffect(() => {
    if (props.subsidiaryObject?.id) {
      const subsidiaryParams = ["id", "code", "name"];
      subsidiaryParams.forEach((i) => {
        props.subsidiaryObject[i] && setValue(i, props.subsidiaryObject[i]);
      });
      setActiveUser(props.subsidiaryObject["active"]);
    }
    return () => {};
  }, []);

  const handelActivate = (e) => {
    const active = e.target.checked;
    setActiveUser(active);
  };

  const onSubmit = (data) => {
    for (const key in data) {
      if (data[key] === "") {
        data[key] = null;
      }
    }
    saveSubsidiary({
      variables: {
        input: {
          active: activeUser,
          ...data,
        },
      },
    })
      .then((data) => {
        props.handleClose();
        props.refetch();
        enqueueSnackbar(t("saveSuccessful"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      })
      .catch(({ graphQLErrors }) => {
        setValidationError(graphQLErrors, setError);
      });
  };

  const body = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ControlMUItextField
        control={control}
        errors={errors}
        name={"code"}
        label={t("code")}
        margin="normal"
      />

      <ControlMUItextField
        control={control}
        errors={errors}
        name={"name"}
        label={t("name")}
        rules={{ required: t("fieldIsRequired") }}
        margin="normal"
      />

      <DialogActions>
        <Button disabled={saveSubsidiaryLoading} type="submit">
          {saveSubsidiaryLoading ? <ButtonLoading /> : t("save")}
        </Button>
        <Button disabled={saveSubsidiaryLoading} onClick={props.handleClose}>
          {t("cancel")}
        </Button>
      </DialogActions>
    </form>
  );

  return (
    <div>
      <CustomDialog
        open={props.open}
        onClose={props.handleClose}
        title={
          <Grid container justifyContent="space-between">
            {props.subsidiaryObject?.id
              ? t("updateSubsidiary")
              : t("createNewSubsidiary")}
            <FormControlLabel
              checked={activeUser}
              control={<Switch color="primary" />}
              label={t("active")}
              labelPlacement="start"
              onChange={handelActivate}
            />
          </Grid>
        }
        content={body}
      />
    </div>
  );
}
