import {
  AppBar,
  Box,
  Drawer,
  Grid,
  IconButton,
  Toolbar,
  useTheme,
} from "@mui/material";
import React, { memo, useEffect, useState } from "react";

import { Menu } from "@mui/icons-material";
import clsx from "clsx";
import { Fragment } from "react";
import { FadeIn } from "react-slide-fade-in";
import { useTranslation } from "react-i18next";
import { Link, withRouter } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import logo from "../../../../assets/Image/logo.png";
import config from "../../../../config.json";
import useWidth, { isWidthUp } from "../../../../Hooks/useWidth";
import LanguageIcons from "../../../HOC/CustomComponents/LanguageIcons";
import { pushUrl } from "../../../HOC/CustomFunctions/pushUrl";
import { styled } from "@mui/material/styles";
const PREFIX = "Header";

const classes = {
  appBar: `${PREFIX}-appBar`,
  stickyHeader: `${PREFIX}-stickyHeader`,
  logo: `${PREFIX}-logo`,
  link: `${PREFIX}-link`,
  trackingPopover: `${PREFIX}-trackingPopover`,
  tracking: `${PREFIX}-tracking`,
  trackTypography: `${PREFIX}-trackTypography`,
  activeLink: `${PREFIX}-activeLink`,
  img: `${PREFIX}-img`,
  loginButton: `${PREFIX}-loginButton`,
  overridesLoginButton: `${PREFIX}-overridesLoginButton`,
  lang: `${PREFIX}-lang`,
};

const Root = styled("div")(({ theme }) => ({
  display: "flex",
  [`& .${classes.activeLink}`]: {
    color: theme.palette.primary.main,
  },
  [`& .${classes.appBar}`]: {
    boxShadow: "none",
    backgroundColor: "transparent",
  },

  [`& .${classes.stickyHeader}`]: {
    position: "fixed",
    left: 0,
    right: 0,
    top: 0,
    zIndex: 1,
    backgroundColor: "#fff",
    borderBottom: `1px solid ${theme.palette.divider}`,
    animationName: "$animationFade",
    animationDuration: "1s",
    animationFillMode: "both",
  },

  [`& .${classes.logo}`]: {
    marginLeft: theme.spacing(1),
    display: "flex",
    alignItems: "center",
  },
  [`& .${classes.link}`]: {
    display: "inline-block",
    textDecoration: "none",
    textTransform: "uppercase",
    fontSize: 13,
    fontWeight: 500,
    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(0, 1.5),
    },
    color: theme.palette.text.primary,
    "&:hover": {
      color: theme.palette.primary.main,
      cursor: "pointer",
    },
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
      padding: theme.spacing(3, 0),
      borderBottom: `1px solid ${theme.palette.action.hover}`,
      width: "100%",
      color: theme.palette.text.secondary,
    },
  },
  [`& .${classes.trackingPopover}`]: {
    padding: theme.spacing(2),
    maxWidth: 300,
  },
  [`& .${classes.tracking}`]: {
    color: theme.palette.text.primary,
    fontWeight: "600",
    [theme.breakpoints.down("lg")]: {
      color: theme.palette.text.secondary,
    },
  },
  [`& .${classes.trackTypography}`]: {
    fontWeight: "600",
    margin: theme.spacing(2),
  },

  [`& .${classes.img}`]: {
    height: 48,
  },

  [`& .${classes.loginButton}`]: {
    borderRadius: 30,
    border: "2px solid",
    borderColor: theme.palette.primary.main,
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: theme.spacing(4),
    minWidth: theme.spacing(14),
    color: theme.palette.primary.main,
    "&:hover": {
      background: theme.palette.primary.main,
      color: theme.palette.common.white,
      cursor: "pointer",
    },
  },
  [`& .${classes.overridesLoginButton}`]: {
    border: "none",
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    borderBottom: `1px solid ${theme.palette.action.hover}`,
    "&:hover": {
      borderBottom: `1px solid ${theme.palette.action.hover}`,
      border: "none",
      background: theme.palette.common.white,
      color: theme.palette.primary.main,
      cursor: "pointer",
    },
  },
  [`& .${classes.lang}`]: {
    [theme.breakpoints.up("md")]: {
      marginLeft: theme.spacing(1),
    },
  },

  "@keyframes animationFade": {
    "0%": {
      opacity: 0,
      WebkitTransform: "translate3d(0, -100%, 0)",
      transform: "translate3d(0, -100%, 0)",
    },
    "100%": {
      opacity: 1,
      WebkitTransform: "none",
      transform: "none",
    },
  },
}));

const linkStyle = ({ theme }) => ({
  display: "inline-block",
  textDecoration: "none",
  textTransform: "uppercase",
  fontSize: 13,
  fontWeight: 500,
  [theme.breakpoints.up("md")]: {
    margin: theme.spacing(0, 1.5),
  },
  color: theme.palette.text.primary,
  "&:hover": {
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
  [theme.breakpoints.down("md")]: {
    textAlign: "center",
    padding: theme.spacing(3, 0),
    borderBottom: `1px solid ${theme.palette.action.hover}`,
    width: "100%",
    color: theme.palette.text.secondary,
  },
});

const StyledLink = styled(Link)(linkStyle);
const StyledLang = styled(LanguageIcons)(linkStyle);

const Header = (props) => {
  const { t } = useTranslation("home");

  const [shouldShowHeader, setShouldShowHeader] = useState(false);
  const listenToScroll = () => {
    setShouldShowHeader(window.pageYOffset > 300);
  };
  useEffect(() => {
    window.addEventListener("scroll", listenToScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", listenToScroll);
    };
  }, []);

  const screenWidth = useWidth();

  const screenIsMedium = isWidthUp("md", screenWidth);
  const [drawerState, setDrawerState] = React.useState(false);

  //################ Drawer ################
  const closeDrawer = (id) => {
    setDrawerState(false);
    if (props.closeDrawer && id) {
      window.location.href = `/#${id}`;
      // pushUrl(props, `/#${id}`);
    }
  };

  const sectionsLinks = (config.homeSections ?? []).map((field) => {
    switch (field) {
      case "about":
        return (
          <HeaderLink onClick={() => closeDrawer("about")} to="about">
            {t("home:about")}
          </HeaderLink>
        );

      case "services":
        return (
          <HeaderLink onClick={() => closeDrawer("service")} to="service">
            {t("home:services")}
          </HeaderLink>
        );

      case "track":
        return (
          <HeaderLink onClick={() => closeDrawer("track")} to="track">
            {t("home:trackTheShipment")}
          </HeaderLink>
        );

      case "features":
        return (
          <HeaderLink onClick={() => closeDrawer("features")} to="features">
            {t("home:features")}
          </HeaderLink>
        );

      case "statistics":
        return (
          <HeaderLink onClick={() => closeDrawer("statistics")} to="statistics">
            {t("home:statistics")}
          </HeaderLink>
        );

      default:
        return "";
    }
  });

  const links = sectionsLinks.concat([
    <StyledLink
      className={clsx(classes.link, {
        [classes.loginButton]: screenIsMedium,
      })}
      // sx={{ ...style, ...(screenIsMedium && classes.loginButton) }}
      onClick={closeDrawer}
      to={localStorage.getItem("token") ? "/admin" : "/login"}
    >
      {localStorage.getItem("token") ? t("home:dashboard") : t("home:login")}
    </StyledLink>,
    <StyledLang
      // sx={style}
      className={classes.link}
      onClick={() => {
        closeDrawer();
      }}
      asLink
    />,
  ]);
  const NavLinks = () => {
    return screenIsMedium ? (
      <Fragment>
        {links.map((l, i) => (
          <Fragment key={i}>{l}</Fragment>
        ))}
      </Fragment>
    ) : (
      <Drawer
        variant="temporary"
        anchor={"top"}
        open={drawerState}
        onClose={closeDrawer}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        {links.map((l, i) => (
          <FadeIn
            key={i}
            from="top"
            positionOffset={400}
            triggerOffset={1000}
            delayInMilliseconds={i * 5}
          >
            {l}
          </FadeIn>
        ))}
      </Drawer>
    );
  };

  return (
    <Root>
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, {
          [classes.stickyHeader]: shouldShowHeader,
        })}
      >
        <Toolbar variant="regular">
          <Grid container justifyContent="space-between">
            <Grid className={classes.logo}>
              <Link to="/">
                <img src={logo} alt="logo" className={classes.img} />
              </Link>
            </Grid>
            <Grid>
              {NavLinks()}

              {!screenIsMedium && (
                <IconButton
                  color="primary"
                  onClick={() => {
                    setDrawerState(true);
                  }}
                  size="large"
                >
                  <Menu color="primary" />
                </IconButton>
              )}
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Root>
  );
};

export default memo(withRouter(Header));

const StyledHeaderLink = styled(ScrollLink)(linkStyle);
const HeaderLink = (props) => {
  const { onClick, to } = props;
  const theme = useTheme();

  return (
    <StyledHeaderLink
      onClick={onClick}
      activeClass={classes.activeLink}
      spy={true}
      hashSpy={true}
      smooth={true}
      duration={0}
      offset={-theme.mixins.toolbar.minHeight}
      to={to}
    >
      {props.children}
    </StyledHeaderLink>
  );
};
