import { IconButton, Paper, Toolbar, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { Fragment } from "react";

import { gql, useQuery } from "@apollo/client";
import {
  DeleteOutline,
  Edit,
  LockOpenOutlined,
  Print,
} from "@mui/icons-material";
import clsx from "clsx";

import { useTranslation } from "react-i18next";
import NotFound from "../../Error/NotFound";
import { Globals } from "../HOC/Classes/Globals";
import { KeyValuePair } from "../HOC/CustomComponents/KeyValuePair";
import { SecuredNavLink } from "../HOC/CustomComponents/Secured";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import {
  DELETE_VOUCHER_MUTATION,
  DISAPPROVE_VOUCHER_MUTATION,
  VOUCHER_ID_AND_PRINT,
} from "./VouchersListQuary";

import Grid from "@mui/material/Unstable_Grid2";
import MutationWithDialog from "../HOC/CustomComponents/MutationWithDialog";
import SpanLink from "../HOC/CustomComponents/SpanLink";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import CheckCrossIcons from "../HOC/FunctionComponents/CheckCrossIcons";
import { dateFormatLL } from "../../helpers/dateFunctions";

const PREFIX = "VoucherView";

const classes = {
  mainGrid: `${PREFIX}-mainGrid`,
  viewPaper: `${PREFIX}-viewPaper`,
  toolbar: `${PREFIX}-toolbar`,
  field: `${PREFIX}-field`,
  toolbarIcons: `${PREFIX}-toolbarIcons`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.mainGrid}`]: {
    margin: theme.spacing(1, 0),
    width: "100%",
  },

  [`& .${classes.viewPaper}`]: {
    padding: theme.spacing(3),
  },

  [`& .${classes.toolbar}`]: {
    borderBottom: "1px #ccd1d6 solid",
    backgroundColor: "#f5f7f9",
    width: "100%",
  },

  [`& .${classes.field}`]: {
    width: 260,
    [theme.breakpoints.down("sm")]: {
      width: 170,
    },
  },

  [`& .${classes.toolbarIcons}`]: {
    flex: "1 1 100%",
    textAlign: "end",
  },
}));

export {};

const VoucherView = (props) => {
  const { t } = useTranslation();

  /******************************************* Start Pagination Functions ********************************************/

  /******************************************* Start voucher By Id ********************************************/
  const voucherId = props.match.params.id?.trim();

  const user = Globals.user;
  const journalEntryPermission = user.hasPermission(
    "accounting.journal_entry.list"
  );
  const voucherQueryBody = VOUCHER_ID_AND_PRINT(journalEntryPermission);
  const {
    data,
    loading: voucherByIdLoad,
    refetch,
  } = useQuery(
    gql`
      ${voucherQueryBody.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      skip: !voucherId,
      variables: {
        id: parseInt(voucherId),
      },
      onCompleted: (data) => {
        if (data?.voucher === null) return;
      },
    }
  );

  const voucher = data?.voucher;

  const branchId = data?.voucher?.branch?.id;
  const canAccessBranch = branchId ? user.canAccessBranch(branchId) : true;
  const canDelete =
    user.hasPermission("cash.voucher.delete") &&
    canAccessBranch &&
    !data?.voucher?.approved &&
    !voucherByIdLoad;

  const canEdit =
    user.hasPermission("cash.voucher.update") &&
    canAccessBranch &&
    !data?.voucher?.glApproved &&
    !voucherByIdLoad;

  const canDisapprove =
    !voucherByIdLoad &&
    user.hasPermission("cash.voucher.disapprove") &&
    canAccessBranch &&
    data?.voucher?.approved;

  /******************************************* End voucher List ********************************************/

  let viewBody = null;

  const voucherDetails = [
    { key: "code", value: voucher?.code },
    { key: "date", value: dateFormatLL(voucher?.date) },
    {
      key: "branch",
      value: voucher?.branch?.name,
      link: `/admin/branches/${voucher?.branch?.id}`,
    },
    { key: "payee", value: voucher?.payeeType?.name },
    {
      key: "customer",
      value: voucher?.customer?.name,
      link: `/admin/customers/${voucher?.customer?.id}`,
    },
    {
      key: "shippingAgent",
      value: voucher?.shippingAgent?.name,
      link: `/admin/delivery-agents/${voucher?.shippingAgent?.id}`,
    },
    { key: "glAccount", value: voucher?.glAccount?.name },
    {
      key: "journalEntry",
      value: voucher?.journalEntry?.code,
      link: `/admin/finance/journal-entry/${voucher?.journalEntry?.id}`,
    },
    {
      key: "safe",
      value: voucher?.safe?.name,
      link: `/admin/safes/${voucher?.safe?.id}`,
    },
    { key: "amount", value: voucher?.amount },
    { key: "notes", value: voucher?.notes },
    { key: "approved", value: <CheckCrossIcons active={voucher?.approved} /> },
    {
      key: "glApproved",
      value: <CheckCrossIcons active={voucher?.glApproved} />,
    },
  ];

  viewBody = (
    <Grid
      container
      spacing={3}
      justifyContent="center"
      sx={{ margin: "0", width: "100%" }}
    >
      <Toolbar className={clsx(classes.toolbar)} variant="regular">
        <Grid
          container
          sx={{ flexWrap: "initial", width: "100%", flexGrow: 1, p: 0 }}
          alignItems="center"
        >
          <Grid className={classes.field}>
            <Typography variant="h6">{voucher?.type?.name}</Typography>
          </Grid>
          <Grid className={classes.toolbarIcons}>
            <IconButton
              disabled={voucherByIdLoad}
              onClick={() =>
                window.open(
                  `${window.location.origin}/voucher/print/${voucherId}`
                )
              }
              color="default"
              size="large"
            >
              <Print fontSize="inherit" />
            </IconButton>
            <SecuredNavLink
              permission={canEdit}
              to={{
                pathname: `/admin/vouchers/${voucherId}/edit`,
              }}
            >
              <IconButton color={"primary"} size="large">
                <Edit fontSize="inherit" />
              </IconButton>
            </SecuredNavLink>
            <MutationWithDialog
              mutaion={DELETE_VOUCHER_MUTATION.query}
              icon={DeleteOutline}
              permission={Boolean(canDelete)}
              iconTooltip={t("deleteRecord")}
              dialogTitle={t("deleteRecord")}
              dialogContent={t("deleteRecordMessage")}
              mutaionProps={{ variables: { id: parseInt(voucherId) } }}
              onCompleted={() => pushUrl(props, `/admin/vouchers`)}
              onCompleteMessage={t("successfullyDeletedRecord")}
            />

            <MutationWithDialog
              mutaion={DISAPPROVE_VOUCHER_MUTATION.query}
              icon={LockOpenOutlined}
              permission={Boolean(canDisapprove)}
              iconTooltip={t("disapprove")}
              dialogTitle={t("disapprove")}
              dialogContent={t("disapprovedMessage")}
              mutaionProps={{ variables: { id: parseInt(voucherId) } }}
              onCompleted={() => refetch()}
              onCompleteMessage={t("successfullyDisapproved")}
            />
          </Grid>
        </Grid>
      </Toolbar>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ margin: "0", width: "100%" }}
      >
        {voucherByIdLoad ? (
          <FullScreenLoading minHeight="25%" />
        ) : (
          <Grid
            container
            spacing={3}
            justifyContent="center"
            sx={{ width: "100%" }}
          >
            <Paper
              container
              className={classes.viewPaper}
              sx={{ mt: 1, flexGrow: 1 }}
              component={Grid}
            >
              {voucherDetails.map((props) =>
                props.value ? (
                  <KeyValuePair
                    key={props.key}
                    title={t(props.key)}
                    value={
                      props.link && props.value ? (
                        <SpanLink pathname={props.link}>{props.value}</SpanLink>
                      ) : (
                        props.value
                      )
                    }
                  />
                ) : null
              )}
            </Paper>
          </Grid>
        )}
      </Grid>
    </Grid>
  );

  return data?.voucher === null ? (
    <NotFound />
  ) : (
    <Root>
      {props.children}

      {viewBody}

      <Grid
        container
        spacing={3}
        justifyContent="center"
        className={classes.mainGrid}
      ></Grid>
    </Root>
  );
};

export default VoucherView;
