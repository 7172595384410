import { gql, useQuery } from "@apollo/client";
import { styled } from '@mui/material/styles';
import {
  Icon,
  IconButton,
  Paper,
  TableRow,
  Toolbar,
  Typography,
  colors,
} from "@mui/material";
import { DeleteOutline, Edit } from "@mui/icons-material";
import clsx from "clsx";
import moment from "moment";
import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import NotFound from "../../Error/NotFound";
import CellLink from "../HOC/CustomComponents/CellLink";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import { KeyValuePair } from "../HOC/CustomComponents/KeyValuePair";
import MutationWithDialog from "../HOC/CustomComponents/MutationWithDialog";
import { Can, SecuredNavLink } from "../HOC/CustomComponents/Secured";
import ShipmentRegion from "../HOC/CustomComponents/ShipmentRegion";
import ShipmentsTable from "../HOC/CustomComponents/ShipmentsTable";
import SpanLink from "../HOC/CustomComponents/SpanLink";
import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import EnhancedTableToolbar from "./EnhancedTableToolbar";
import { DELETE_PICKUP_MUTATION, PICKUP_VIEW } from "./Graphql";
import Grid from "@mui/material/Unstable_Grid2";
import { GetPaymentPermissionSlug } from "../../helpers/getManifestPermissionSlug";
import { Globals } from "../HOC/Classes/Globals";

const PREFIX = 'PickupView';

const classes = {
  loading: `${PREFIX}-loading`,
  paper: `${PREFIX}-paper`,
  toolbar: `${PREFIX}-toolbar`,
  toolbarIcons: `${PREFIX}-toolbarIcons`,
  iconButton: `${PREFIX}-iconButton`,
  iconColor: `${PREFIX}-iconColor`,
  main: `${PREFIX}-main`,
  field: `${PREFIX}-field`,
  table: `${PREFIX}-table`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.loading}`]: {},

  [`& .${classes.paper}`]: {
    padding: theme.spacing(3),
  },

  [`& .${classes.toolbar}`]: {
    borderBottom: "1px #ccd1d6 solid",
    backgroundColor: "#f5f7f9",
    width: "100%",
  },

  [`& .${classes.toolbarIcons}`]: {
    flex: "1 1 100%",
    textAlign: "end",
  },

  [`& .${classes.iconButton}`]: {
    borderRadius: "0 4px 4px 0 ",
    boxShadow: "none",
    height: 45,
  },

  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },

  [`& .${classes.main}`]: {
    margin: 0,
    width: "100%",
  },

  [`& .${classes.field}`]: {
    width: 260,
    [theme.breakpoints.down("sm")]: {
      width: 170,
    },
  },

  [`& .${classes.table}`]: {
    display: "grid",
    padding: 0,
    marginTop: theme.spacing(2),
    borderRadius: 0,
    width: "100%",
  },
}));

const formatTime = (time) => moment(time, "hh:mm").format("hh:mm A");

const PickupView = (props) => {

  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);
  const { t } = useTranslation();
  const pickupId = props.match.params.id?.trim();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  const paymentPermission = Globals.user.hasPermission(GetPaymentPermissionSlug('dlvby', 'list'))
  const pickupQueryBody = PICKUP_VIEW(paymentPermission);

  const { loading, data } = useQuery(
    gql`
      ${pickupQueryBody.query}
    `,

    {
      skip: !pickupId,
      variables: { id: parseInt(pickupId) },
      fetchPolicy: "no-cache",
    }
  );

  const pickup = data?.pickup;
  const shipmentTableHead = [
    "requestCode",
    "recipient",
    "destination",
    "recipientAddress",
    "description",
    "pieceCount",
    "value",
    "returningDueFees",
    "totalCost",
    "",
  ];

  const total = {
    piecesCount: 0,
    amount: 0,
    allDueFees: 0,
    totalAmount: 0,
  };

  const sumTableNumber = (shipment, tableTotal) => {
    Object.entries(tableTotal).forEach(([k, v]) => {
      tableTotal[k] += shipment[k] ?? 0;
    });
  };

  const shipmentTableBody = (shipment, index) => {
    return (
      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
        {shipment.__typename === "Barcode" ? <FixedTableCell>{shipment?.code}</FixedTableCell>
          : <CellLink pathname={`/admin/shipments/${shipment.id}`}>
            {shipment.code}
          </CellLink>}
        <FixedTableCell>{shipment?.recipientName}</FixedTableCell>
        <ShipmentRegion
          pathname={`/admin/zone/${shipment?.recipientZone?.id}`}
          zone={shipment?.recipientZone?.name}
          subzone={shipment?.recipientSubzone?.name}
        />
        <FixedTableCell>{shipment?.recipientAddress}</FixedTableCell>
        <FixedTableCell>{shipment?.description}</FixedTableCell>
        <FixedTableCell>{shipment?.piecesCount}</FixedTableCell>
        <FixedTableCell>{shipment?.amount}</FixedTableCell>
        <FixedTableCell>{shipment?.allDueFees}</FixedTableCell>
        <FixedTableCell>{shipment?.totalAmount}</FixedTableCell>
        <FixedTableCell> </FixedTableCell>
      </TableRow>
    );
  };

  return pickup === null ? (
    <NotFound />
  ) : (
    <Root>
      <Grid
        container
        spacing={3}
        justifyContent="center"
        className={classes.mainTracking}
        sx={{ margin: "0", width: "100%" }}
      >
        {loading ? (
          <FullScreenLoading minHeight="25%" />
        ) : (
          <>
            <Fragment>
              <Toolbar className={clsx(classes.toolbar)} variant="regular">
                <Grid
                  container
                  sx={{ flexWrap: "initial", flexGrow: 1 }}
                  alignItems="center"
                >
                  <Grid className={classes.field}>
                    <Typography variant="h6">{t(`pickupDetails`)}</Typography>
                  </Grid>
                  <Grid className={classes.toolbarIcons}>
                    {pickup?.editable && (
                      <SecuredNavLink
                        to={{
                          pathname: `/admin/pickups/${pickupId}/edit`,
                        }}
                      >
                        <IconButton color={"primary"} size="large">
                          <Edit fontSize="inherit" />
                        </IconButton>
                      </SecuredNavLink>
                    )}
                    <MutationWithDialog
                      mutaion={DELETE_PICKUP_MUTATION.query}
                      icon={DeleteOutline}
                      permission={Boolean(pickup?.deletable)}
                      iconTooltip={t("deletePickup")}
                      dialogTitle={t("deletePickup")}
                      dialogContent={t("deletePickupMessage")}
                      mutaionProps={{ variables: { id: parseInt(pickupId) } }}
                      onCompleted={() => pushUrl(props, `/admin/pickups`)}
                      onCompleteMessage={t("deleteMessage")}
                    />
                  </Grid>
                </Grid>
              </Toolbar>
            </Fragment>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              sx={{ margin: "16px 0 0 0", width: "100%" }}
            >
              <Grid
                container
                spacing={3}
                justifyContent="center"
                sx={{ width: "100%" }}
              >
                <Grid container justifyContent="center">
                  <Paper container className={classes.paper} component={Grid}>
                    <KeyValuePair
                      title={t("requestCode")}
                      value={pickup?.code}
                    />
                    <KeyValuePair title={t("date")} value={pickup?.date} />
                    <KeyValuePair
                      title={t("branch")}
                      value={
                        pickup?.branch ? (
                          <SpanLink
                            pathname={`/admin/branches/${pickup?.branch?.id}`}
                          >
                            {pickup?.branch?.name}
                          </SpanLink>
                        ) : (
                          "--"
                        )
                      }
                    />
                    <KeyValuePair
                      title={t("fromHour")}
                      value={
                        pickup?.timeFrom ? formatTime(pickup?.timeFrom) : null
                      }
                    />
                    <KeyValuePair
                      title={t("toHour")}
                      value={pickup?.timeTo ? formatTime(pickup?.timeTo) : null}
                    />
                    <KeyValuePair
                      title={t("shippingAgent")}
                      value={
                        pickup?.deliveryAgent ? (
                          <SpanLink
                            pathname={`/admin/delivery-agents/${pickup?.deliveryAgent?.id}`}
                          >
                            {pickup?.deliveryAgent?.name}
                          </SpanLink>
                        ) : (
                          "--"
                        )
                      }
                    />

                    <KeyValuePair
                      title={t("transactionTypeName")}
                      value={
                        pickup?.transactionType ? (
                          <SpanLink
                            pathname={`/admin/shipment-transaction-types/${pickup?.transactionType?.id}`}
                          >
                            {pickup?.transactionType?.name}
                          </SpanLink>
                        ) : (
                          "--"
                        )
                      }
                    />
                    <KeyValuePair
                      title={t("customer")}
                      value={
                        pickup?.customer ? (
                          <SpanLink
                            pathname={`/admin/customers/${pickup?.customer?.id}`}
                          >
                            {pickup?.customer?.name}
                          </SpanLink>
                        ) : (
                          "--"
                        )
                      }
                    />

                    <KeyValuePair
                      title={t("source")}
                      value={
                        pickup?.customer?.zone ? (
                          <SpanLink
                            pathname={`/admin/zone/${pickup?.customer?.zone?.id}`}
                          >
                            {pickup?.customer?.zone?.name +
                              " - " +
                              pickup?.customer?.subzone?.name}
                          </SpanLink>
                        ) : (
                          "--"
                        )
                      }
                    />

                    <KeyValuePair
                      title={t("address")}
                      value={pickup?.customer?.address}
                    />

                    <KeyValuePair
                      title={t("expectedShipments")}
                      value={pickup?.shipmentsCount}
                    />
                    <KeyValuePair title={t("notes")} value={pickup?.notes} />
                    <KeyValuePair
                      title={t("deliveryAgentNotes")}
                      value={pickup?.deliveryAgentNotes}
                    />
                    <KeyValuePair
                      title={t("vehicle")}
                      value={pickup?.vehicle?.name}
                    />
                    <KeyValuePair
                      title={t("pickedShipments")}
                      value={pickup?.pickedCount}
                    />
                    <KeyValuePair
                      title={t("status")}
                      value={pickup?.status?.name}
                    />
                    <KeyValuePair
                      title={t("deliveryAgentAccepted")}
                      value={pickup?.acceptance?.name}
                    />
                    <Can
                      permission={GetPaymentPermissionSlug('dlvby', 'list')}
                      showException
                    >
                      <KeyValuePair
                        title={t("payment")}
                        value={pickup?.payment ? (
                          <SpanLink
                            pathname={`/admin/payments/${pickup?.payment?.id}`}
                          >
                            {pickup?.payment?.code}
                          </SpanLink>
                        ) : (
                          "--"
                        )}
                      />
                    </Can>
                    <Can
                      permission="shipping.shipment.choose_customer"
                      showException
                    >
                      <KeyValuePair
                        title={t("createdBy")}
                        value={
                          pickup?.createdBy ? (
                            <SpanLink
                              pathname={`/admin/users/${pickup?.createdBy?.id}`}
                            >
                              {pickup?.createdBy?.username}
                            </SpanLink>
                          ) : (
                            "--"
                          )
                        }
                      />
                      <KeyValuePair
                        title={t("updatedBy")}
                        value={
                          pickup?.updatedBy ? (
                            <SpanLink
                              pathname={`/admin/users/${pickup?.updatedBy?.id}`}
                            >
                              {pickup?.updatedBy?.username}
                            </SpanLink>
                          ) : (
                            "--"
                          )
                        }
                      />
                    </Can>
                    <KeyValuePair title={t("createdAt")} value={pickup?.createdAt} />

                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
      {pickup?.entries.length > 0 && (
        <Grid container xs={12} justifyContent="center" sx={{ width: "100%" }}>
          {/* *******Table******* */}
          <Grid container sx={{ width: "100%" }}>
            <Paper className={clsx(classes.table)}>
              <EnhancedTableToolbar
                {...props}
                selectedShipment={pickup?.entries}
              />
              <TableFixedHeaderWraper>
                <ShipmentsTable
                  page={page}
                  rowsPerPage={rowsPerPage}
                  data={pickup?.entries}
                  headCells={shipmentTableHead}
                  parseBodyCell={shipmentTableBody}
                  total={total}
                  sumTableNumber={sumTableNumber}
                />
              </TableFixedHeaderWraper>
              {pickup?.entries.length > 4 && (
                <MUITablePagination
                  // classeName={classes.pagination}
                  count={pickup?.entries.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              )}
            </Paper>
          </Grid>
        </Grid>
      )}
    </Root>
  );
};

export default PickupView;
