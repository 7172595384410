import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import {
  Done,
  LocalShippingOutlined,
  PriorityHighSharp,
} from "@mui/icons-material";
import { Step, StepLabel, Stepper, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";

const ColorlibConnector = styled(StepConnector)(({ theme, ownerState }) => ({
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: theme.palette.info.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: theme.palette.info.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
}));

const PREFIX_STEP_ICON = "ColorlibStepIcon";

const stepIconClasses = {
  root: `${PREFIX_STEP_ICON}-root`,
  button: `${PREFIX_STEP_ICON}-button`,
  instructions: `${PREFIX_STEP_ICON}-instructions`,
};

const StyledStepIcon = styled("div")(({ theme, ownerState }) => ({
  backgroundColor: "#ccc",
  color: "#fff",
  width: 30,
  height: 30,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundColor: theme.palette.info.main,
    width: 50,
    height: 50,
    position: "absolute",
    top: "-11px",
    right: "calc(50% + -25px)",
    outline: "thick solid",
    zIndex: 1,
  }),
  ...(ownerState.completed && {
    backgroundColor: theme.palette.info.main,
  }),

  ...(ownerState.error && {
    backgroundColor: theme.palette.error.main,
    outline: "thick solid",
  }),
}));

function ColorlibStepIcon(props) {
  const theme = useTheme();
  const { active, completed, error, icon } = props;

  const icons = {
    1: (
      <LocalShippingOutlined
        sx={{ ...(theme.direction === "rtl" && { transform: "scaleX(-1)" }) }}
      />
    ),
    3: <PriorityHighSharp />,
    4: <Done />,
  };

  return (
    <StyledStepIcon ownerState={{ active, completed, error }}>
      {error
        ? icons[String(3)]
        : icon === 4 && active
        ? icons[String(4)]
        : active
        ? icons[String(1)]
        : completed
        ? icons[String(4)]
        : icon}
    </StyledStepIcon>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,

  completed: PropTypes.bool,

  icon: PropTypes.node,
};

const PREFIX = "CustomizedSteppers";

const classes = {
  root: `${PREFIX}-root`,
  button: `${PREFIX}-button`,
  instructions: `${PREFIX}-instructions`,
};

const Root = styled("div")(({ theme }) => ({
  width: "100%",
  "& .MuiStepLabel-label.Mui-active": {
    paddingTop: "30px",
  },
  [`& .${classes.button}`]: {
    marginRight: theme.spacing(1),
  },
  [`& .${classes.instructions}`]: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export default function CustomizedSteppers(props) {
  const { inWareHouse, statusCode } = props;
  const [activeStep, setActiveStep] = React.useState(-1);
  const { t } = useTranslation();

  const step1 = useRef([
    t("created"),
    t("shipped"),
    t("outForDelivery"),
    t("delivered"),
  ]);
  const steps = useRef(step1.current);

  const step = (code) => {
    switch (code) {
      case "PKM":
      case "PKR":
      case "PKH":
        setActiveStep(0);
        break;
      case "PKD":
      case "RITS":
      case "BMR":
      case "BMT":
      case "RJCT":
      case "HTR":
      case "DEX":
        if (code === "RJCT") steps.current[1] = t("rejected");
        if (code !== "HTR" && code !== "DEX") {
          setActiveStep(1);
        } else {
          inWareHouse && setActiveStep(1);
          !inWareHouse && setActiveStep(2);
        }
        break;
      case "OTD":
        setActiveStep(2);

        break;

      default:
        steps.current = [...step1.current];
        if (code !== "DTR") steps.current[3] = t("cancelled");
        setActiveStep(3);
        break;
    }
  };
  useEffect(() => {
    steps.current = [...step1.current];
    step(statusCode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusCode]);

  return (
    <Root>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<ColorlibConnector />}
      >
        {steps.current?.map((label, index) => {
          const rejectedShip =
            label === t("rejected") || label === t("cancelled");
          const stepProps = {};
          const labelProps = {};
          if (rejectedShip) {
            labelProps.error = true;
            stepProps.completed = true;
            // stepProps.active = false;
          }
          return (
            <Step {...stepProps} key={index}>
              <StepLabel {...labelProps} StepIconComponent={ColorlibStepIcon}>
                {label}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Root>
  );
}
