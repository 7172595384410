/* eslint-disable no-useless-concat */
import React, { Fragment } from "react";
import { styled } from '@mui/material/styles';
import { gql, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { SecuredNavLink } from "../HOC/CustomComponents/Secured";
import {
  Typography,
  Paper,
  Toolbar,
  IconButton,
  Icon,
  // Table,
  // TableRow,
  // TableHead,
  // TableBody,
} from "@mui/material";
import { Edit } from "@mui/icons-material";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { WARE_HOUSE_ID } from "./Graphql";
import { KeyValuePair } from "../HOC/CustomComponents/KeyValuePair";
// import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
// import EmptyTableMessage from "../HOC/FunctionComponents/EmptyTableMessage";
import { Globals } from "../HOC/Classes/Globals";
// import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
// import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";
import Grid from "@mui/material/Unstable_Grid2";
import SpanLink from "../HOC/CustomComponents/SpanLink";
const PREFIX = 'WareHouseView';

const classes = {
  loading: `${PREFIX}-loading`,
  paper: `${PREFIX}-paper`,
  toolbar: `${PREFIX}-toolbar`,
  toolbarTable: `${PREFIX}-toolbarTable`,
  iconColor: `${PREFIX}-iconColor`,
  toolbarIcons: `${PREFIX}-toolbarIcons`,
  iconButton: `${PREFIX}-iconButton`,
  main: `${PREFIX}-main`,
  field: `${PREFIX}-field`,
  table: `${PREFIX}-table`,
  title: `${PREFIX}-title`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.loading}`]: {},

  [`& .${classes.paper}`]: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    flexGrow: 1,
  },

  [`& .${classes.toolbar}`]: {
    borderBottom: "1px #ccd1d6 solid",
    backgroundColor: "#f5f7f9",
    width: "100%",
  },

  [`& .${classes.toolbarTable}`]: {
    width: "100%",
    backgroundColor: theme.palette.grey[50],
    borderBottom: "1px solid " + theme.palette.divider,
    borderRight: "1px solid " + theme.palette.divider,
  },

  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },

  [`& .${classes.toolbarIcons}`]: {
    flex: "1 1 100%",
    textAlign: "end",
  },

  [`& .${classes.iconButton}`]: {
    borderRadius: "0 4px 4px 0 ",
    boxShadow: "none",
    height: 45,
  },

  [`& .${classes.main}`]: {
    margin: 0,
    width: "100%",
  },

  [`& .${classes.field}`]: {
    width: 260,
    [theme.breakpoints.down("sm")]: {
      width: 170,
    },
  },

  [`& .${classes.table}`]: {
    display: "grid",
    minWidth: "100%",
  },

  [`& .${classes.title}`]: {
    flex: 1,
  }
}));

const WareHouseView = (props) => {

  const warehouseId = props.match.params.id?.trim();
  const { t } = useTranslation();

  const { loading, data } = useQuery(
    gql`
      ${WARE_HOUSE_ID.query}
    `,
    {
      fetchPolicy: "network-only",
      variables: { id: parseInt(warehouseId) },
    }
  );
  const user = Globals.user;
  const branchId = data?.warehouseSection?.branch?.id;
  const canAccessBranch = branchId ? user.canAccessBranch(branchId) : true;

  const canEdit = user.hasPermission("shipping.warehouse_section.update")
    ? canAccessBranch
    : false;

  return (
    <Root>
      <Grid
        container
        justifyContent="center"
        sx={{ margin: "0", width: "100%" }}
      >
        {loading ? (
          <FullScreenLoading minHeight="15%" />
        ) : (
          <>
            <Toolbar className={clsx(classes.toolbar)} variant="regular">
              <Grid
                container
                sx={{ flexWrap: "initial", flexGrow: 1 }}
                alignItems="center"
              >
                <Grid className={classes.field}>
                  <Typography variant="h6">{t(`wareHouseDetails`)}</Typography>
                </Grid>
                <Grid className={classes.toolbarIcons}>
                  <SecuredNavLink
                    permission={canEdit}
                    to={{
                      pathname: `/admin/warehouse/${warehouseId}/edit`,
                    }}
                  >
                    <IconButton color={"primary"} size="large">
                      <Edit fontSize="inherit" />
                    </IconButton>
                  </SecuredNavLink>
                </Grid>
              </Grid>
            </Toolbar>
            <Paper container className={classes.paper} component={Grid}>
              <KeyValuePair title={t("name")} value={data?.warehouseSection?.name} />
              <KeyValuePair
                title={t("active")}
                value={
                  data?.warehouseSection?.active ? (
                    <Icon className={classes.iconColor}>
                      check_circle_outline
                    </Icon>
                  ) : (
                    <Icon color="error">highlight_off</Icon>
                  )
                }
              />
              <KeyValuePair
                title={t("branch")}
                value={
                  data?.warehouseSection?.branch ? (
                    <SpanLink
                      pathname={`/admin/branches/${data?.warehouseSection?.branch?.id}`}
                    >
                      {data?.warehouseSection?.branch?.name}
                    </SpanLink>
                  ) : (
                    "--"
                  )
                }
              />
            </Paper>
          </>
        )}
      </Grid>
    </Root>
  );
};

export default WareHouseView;
