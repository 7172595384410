import * as gqlb from "gql-query-builder";

export const USER_SETTINGS = gqlb.query([
  {
    operation: "me",
    fields: [
      "id",
      "username",
      "isSuper",
      {
        userBranches: ["default", { branch: ["id", "name", "active"] }],
      },
      {
        role: ["code"],
      },
      {
        operation: "account",
        fields: [
          {
            operation: "...on Customer",
            fields: [
              "id",
              "name",
              "email",
              "specifyShipmentCode",
              "warehousing",
              { glAccount: ["id"] },
            ],
            variables: {},
          },
          {
            operation: "...on DeliveryAgent",
            fields: ["id", "name", "code", { glAccount: ["id"] }],
            variables: {},
          },
        ],
        variables: {},
      },
      {
        role: [
          "id",
          "name",
          "code",
          {
            permissions: ["id", "name", "slug"],
          },
        ],
      },
    ],
    variables: {},
  },
  {
    operation: "shippingSettings",
    fields: [
      "warehousing",
      "renewalDate",
      "support",
      "allowC2C",
      "waybillCode",
      "multiCountries",
    ],
    variables: {}
  },
  {
    operation: "member",
    fields: [
      {
        teams: [
          "id",
          {
            categories: [
              "id"
            ]
          }
        ]
      },
    ],
    variables: {}
  }
]);
