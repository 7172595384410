import * as gqlb from "gql-query-builder";

const journalEntryFields = [
  "id",
  "date",
  "code",
  "description",
  "approved",
  {
    branch: ["id", "name"],
  },
  {
    type: ["id", "name"],
  },
  {
    createdBy: ["username", "id"],
  },
  {
    updatedBy: ["username", "id"],
  },
  {
    subsidiary: ["id", "name"],
  },
  {
    sourceType: ["code"],
  },

  {
    records: [
      "id",
      "debit",
      "credit",
      "description",
      {
        glAccount: ["id", "name"],
      },
    ],
  },
];
export const LIST_JOURNAL_ENTRY = gqlb.query({
  operation: "listJournalEntries",
  fields: [
    {
      operation: "paginatorInfo",
      fields: [
        "count",
        "currentPage",
        "firstItem",
        "hasMorePages",
        "lastItem",
        "lastPage",
        "perPage",
        "total",
      ],
      variables: {},
    },
    {
      operation: "data",
      fields: [
        "id",
        "date",
        "code",
        "description",
        "approved",
        {
          branch: ["id", "name"],
        },
        {
          type: ["id", "name"],
        },

        {
          subsidiary: ["id", "name"],
        },
      ],
      variables: {},
    },
  ],
  variables: {
    input: {
      type: "ListJournalEntriesInput",
    },
    page: {
      type: "Int",
    },
    first: {
      type: "Int",
    },
  },
});

export const SAVE_JOURNAL_ENTRY = gqlb.mutation({
  operation: "saveJournalEntry",
  fields: journalEntryFields,
  variables: {
    input: {
      type: "JournalEntryInput",
      required: true,
    },
  },
});

export const APPROVE_JOURNAL_ENTRY = gqlb.mutation({
  operation: "approveJournalEntry",
  fields: journalEntryFields,
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
export const DELETE_JOURNAL_ENTRY = gqlb.mutation({
  operation: "deleteJournalEntry",
  fields: [],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const JOURNAL_ENTRY = gqlb.query({
  operation: "journalEntry",
  fields: journalEntryFields,
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const DISAPPROVE_JOURNAL_ENTRY_MUTATION = gqlb.mutation({
  operation: "disapproveJournalEntry",
  fields: ["id"],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
