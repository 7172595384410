/* eslint-disable no-useless-concat */
import React, { useEffect, useState } from "react";

import { styled } from "@mui/material/styles";

import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Toolbar,
  IconButton,
  Tooltip,
  Button,
  Typography,
  Drawer,
  Box,
  Icon,
} from "@mui/material";

import { useForm } from "react-hook-form";
import { FilterList, Clear, Add, Search } from "@mui/icons-material";
import { gql, useQuery } from "@apollo/client";
import clsx from "clsx";
import MUIselect from "../HOC/MUI/MUIselect";
import { LISTMANIFESTS_QUERY, SUM_MANIFEST_QUERY } from "./Graphql";
import moment from "moment";
import { urlParameters } from "../HOC/CustomFunctions/urlParameters";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import MUIDateRangeCustom from "../HOC/MUI/MUIDateRangeCustom";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { Can, SecuredNavLink } from "../HOC/CustomComponents/Secured";
import emptyList from "../../assets/Image/received.png";
import { windowUrl } from "../HOC/CustomFunctions/pushUrl";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import CellLink from "../HOC/CustomComponents/CellLink";
import {
  LIST_BRANCHES_DROPDOWN,
  LIST_CUSTOMERS_DROPDOWN,
  LIST_DELIVERY_AGENTS_DROPDOWN,
  LSIT_ZONES_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";
import useWidth from "../../Hooks/useWidth";
import { dateTimeFormatA } from "../../helpers/dateFunctions";
import { GetManifestPermissionSlug } from "../../helpers/getManifestPermissionSlug";
import LookupDropdown from "../HOC/CustomComponents/LookupDropdown";
import { lookupCodeIsValid } from "../HOC/CustomFunctions/lookupCodeIsValid";
import { Globals } from "../HOC/Classes/Globals";

const PREFIX = "ManifestList";

const classes = {
  track: `${PREFIX}-track`,
  typo: `${PREFIX}-typo`,
  mainTracking: `${PREFIX}-mainTracking`,
  appBar: `${PREFIX}-appBar`,
  title: `${PREFIX}-title`,
  textAlign: `${PREFIX}-textAlign`,
  divider: `${PREFIX}-divider`,
  paper: `${PREFIX}-paper`,
  filterAnchor: `${PREFIX}-filterAnchor`,
  picker: `${PREFIX}-picker`,
  button: `${PREFIX}-button`,
  toolbar: `${PREFIX}-toolbar`,
  selectPadding: `${PREFIX}-selectPadding`,
  margin: `${PREFIX}-margin`,
  titles: `${PREFIX}-titles`,
  filterForm: `${PREFIX}-filterForm`,
  filterField: `${PREFIX}-filterField`,
  tableIcon: `${PREFIX}-tableIcon`,
  drawer: `${PREFIX}-drawer`,
  drawerPaper: `${PREFIX}-drawerPaper`,
  drawerHeader: `${PREFIX}-drawerHeader`,
  content: `${PREFIX}-content`,
  contentShift: `${PREFIX}-contentShift`,
  iconColor: `${PREFIX}-iconColor`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.track}`]: {
    marginTop: theme.spacing(2),
  },

  [`& .${classes.typo}`]: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    fontWeight: 600,
  },

  [`& .${classes.mainTracking}`]: {
    margin: theme.spacing(0, 0),
    width: "100%",
  },

  [`& .${classes.appBar}`]: {
    position: "relative",
  },

  [`& .${classes.title}`]: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },

  [`& .${classes.textAlign}`]: {
    textAlign: "justify",
    padding: theme.spacing(1),
  },

  [`& .${classes.divider}`]: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
      height: "1px",
    },
  },

  [`& .${classes.paper}`]: {
    display: "grid",
    width: "100%",
    marginBottom: theme.spacing(2),
    borderRadius: 0,
  },

  [`& .${classes.filterAnchor}`]: {
    [theme.breakpoints.down("md")]: {
      width: "auto",
    },
    // overflowX: "scroll",
    "& .MuiDrawer-paper": {
      [theme.breakpoints.up("sm")]: {
        width: "30%",
      },
    },
  },

  [`& .${classes.picker}`]: {
    [theme.breakpoints.down("md")]: {
      width: "auto",
    },
    // overflow: "scroll",
    "& .MuiDrawer-paper": {
      [theme.breakpoints.up("sm")]: {
        width: "50%",
      },

      height: "100%",
      overflow: "hidden",
    },
  },

  [`& .${classes.button}`]: {
    position: "sticky",
    bottom: 0,
    zIndex: 1,
    backgroundColor: "#fff",
    marginTop: 10,
    padding: 7,
  },

  [`& .${classes.toolbar}`]: {
    borderBottom: "solid 1px",
    borderBottomColor: theme.palette.divider,
    // zIndex: "1300",
    // justify: "space-between"
  },

  [`& .${classes.selectPadding}`]: {
    paddingTop: "6px",
    paddingBottom: "5px",
  },

  [`& .${classes.margin}`]: {
    margin: theme.spacing(1, 2),
  },

  [`& .${classes.titles}`]: {
    flex: "1 1 100%",
  },

  [`& .${classes.filterForm}`]: {
    overflowY: "auto",
    width: "100%",
    margin: 0,
    position: "relative",
    height: "100vh",
  },

  [`& .${classes.filterField}`]: {
    padding: theme.spacing(1),
    width: "100%",
    margin: 0,
  },

  [`& .${classes.tableIcon}`]: {
    margin: theme.spacing(0, 1),
  },

  /////////Drawer///////////

  [`& .${classes.drawer}`]: {
    width: drawerWidth,
    flexShrink: 0,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      // height: "100%",
    },
  },

  [`& .${classes.drawerPaper}`]: {
    width: drawerWidth,

    zIndex: "250",

    left: "initial",
    right: "initial",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100%",
    },
  },

  [`& .${classes.drawerHeader}`]: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },

  [`& .${classes.content}`]: {
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
  },

  [`& .${classes.contentShift}`]: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: "auto",
    width: "calc(100% - 240px)",
    [theme.breakpoints.down("sm")]: {
      margin: "auto",
      width: "auto",
    },
  },

  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },
}));

///////////////////////////////////////////////////

//////////////////////////////////////////////////
const drawerWidth = 240;

//*********Table Function*********

function EnhancedTableHead(props) {
  // const {  numSelected, rowCount, } = props;
  const { type } = props;
  const { t } = useTranslation();
  const headCells = [
    { id: "1", numeric: true, disablePadding: false, label: t("manifestCode") },
    { id: "2", numeric: true, disablePadding: false, label: t("date") },
    {
      id: "3",
      numeric: true,
      disablePadding: false,
      label: t("branch"),
    },
    {
      id: "4",
      numeric: true,
      disablePadding: false,
      label: t("shippingAgent"),
    },
    {
      id: "5",
      numeric: true,
      disablePadding: false,
      label: t("transactionTypeName"),
    },
    { id: "6", numeric: true, disablePadding: false, label: t("shipments") },
    { id: "7", numeric: true, disablePadding: false, label: t("approved") },
  ];
  if (type === "rits") {
    delete headCells[3];
  }
  if (type === "bmt" || type === "bmr") {
    const referenceBranch = {
      id: "8",
      numeric: true,
      disablePadding: false,
      label: type === "bmt" ? t("toBranch") : t("fromBranch"),
    };
    headCells.splice(3, 0, referenceBranch);
  }
  if (type === "pkm" || type === "otr" || type === "rtrn") {
    const customer = {
      id: "9",
      numeric: true,
      disablePadding: false,
      label: t("customer"),
    };
    headCells.splice(3, 0, customer);
  }
  if (type === "rtrn") {
    const customer = {
      id: "10",
      numeric: true,
      disablePadding: false,
      label: t("returnedValue"),
    };
    headCells.splice(7, 0, customer);
  }
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <FixedTableCell
            key={headCell.id}
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            {headCell.label}
          </FixedTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

/////////////////DIALOG///////////////

const initDate = moment(new Date()).locale("en").format("YYYY-MM-DD");

const ManifestList = (props) => {
  const urlQuery = urlParameters(window.location.search);
  const { t } = useTranslation();

  let trackingBody = null;

  const manifestName = {
    pkm: t("pickupManifestLists"),
    rits: t("receivedInStore"),
    bmt: t("branchTransfareTo"),
    bmr: t("branchTransfareFrom"),
    otd: t("outForDeliveryLists"),
    htr: t("retryDeliveryLists"),
    rts: t("returnToCustomerLists"),
    otr: t("returnDeliveryLists"),
    rtrn: t("returnHandlingLists"),
  };

  const validUrlParameters = Object.keys(urlQuery).length !== 0;
  const initFromDate = urlQuery["fromDate"]
    ? urlQuery["fromDate"]
    : validUrlParameters ? null : initDate;
  const initToDate = urlQuery["toDate"]
    ? urlQuery["toDate"]
    : validUrlParameters ? null : initDate;

  const [dateRange, setDateRange] = useState([initFromDate, initToDate]);

  const [page, setPage] = useState(0);

  const [autocompleteValues, setAutocompleteValues] = useState({
    deliveryAgent: null,
    customer: null,
    toZone: null,
    toSubzone: null,
    fromZone: null,
    fromSubzone: null,
  });

  const screenWidth = useWidth();

  const [state, setState] = React.useState({
    top: true,
    left: screenWidth === "xs" ? false : true,
    bottom: screenWidth === "xs" ? false : true,
    right: screenWidth === "xs" ? false : true,
  });
  const listType = props.match.params.type;

  const {
    formState: { errors },
    handleSubmit,
    control,
    setValue,
    watch,
  } = useForm();

  const fromDate = moment(dateRange[0]).locale("en").format("YYYY-MM-DD");
  const toDate = moment(dateRange[1]).locale("en").format("YYYY-MM-DD");
  const [rowsPerPage, setRowsPerPage] = useState(urlQuery["rowsPerPage"] ?? 20);
  const initSearch = () => {
    let searchParameters = {
      page: 0,
      refetch: true,
      ...(dateRange[0] && { fromDate }),
      ...(dateRange[1] && { toDate }),
    };
    delete urlQuery["rowsPerPage"];
    if (validUrlParameters) {
      if (urlQuery["code"]) urlQuery["code"] = urlQuery["code"].toString();
      // if (urlQuery["phone"]) urlQuery["phone"] = urlQuery["phone"].toString();
      // if (urlQuery["name"]) urlQuery["name"] = urlQuery["name"].toString();
      // if (urlQuery["email"]) urlQuery["email"] = urlQuery["email"].toString();
      // if (urlQuery["paymentTypes"])
      //   urlQuery["paymentTypes"] = urlQuery["paymentTypes"].split(",");
      searchParameters = urlQuery;
    }
    return {
      ...searchParameters,
    };
  };
  const [search, setSearch] = useState(initSearch());

  useEffect(() => {
    urlQuery["code"] && setValue("code", urlQuery["code"]);
    return () => { };
  }, []);

  const history = useHistory();

  const handelAutocompleteDefaultValue = (data, id, fieldName) => {
    const defaultValue = data[Object.keys(data)[0]].find(
      (i) => i.id === parseInt(id)
    );
    defaultValue &&
      setAutocompleteValues((prev) => ({
        ...prev,
        [fieldName]: defaultValue,
      }));
  };

  const pushUrlSearch = (param) => {
    const queryParams = [];
    for (const i in param) {
      encodeURIComponent(param[i]) &&
        queryParams.push(
          encodeURIComponent(i) + "=" + encodeURIComponent(param[i])
        );
    }
    const queryString = queryParams.join("&");
    const url = history.createHref({
      pathname: `/admin/manifests/${listType}`,
      search: "?" + queryString,
    });
    windowUrl(url);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };
  const filterAnchor = screenWidth === "xs" ? "bottom" : "left";

  const { data, loading } = useQuery(
    gql`
      ${LISTMANIFESTS_QUERY.query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: {
        first: rowsPerPage,
        page: page + 1,
        input: {
          transactionTypeCode: listType.toUpperCase(),
          approved: search.approved,
          ...(search.fromDate && { fromDate: search.fromDate }),
          ...(search.toDate && { toDate: search.toDate }),
          ...(search.code && { code: search.code }),
          ...(search.withAvailableShipments && { withAvailableShipments: search.withAvailableShipments }),
          ...(search.customerId && { customerId: search.customerId }),
          ...(search.deliveryAgentId && { deliveryAgentId: search.deliveryAgentId }),
          ...(search.fromZoneId && { fromZoneId: search.fromZoneId }),
          ...(search.fromSubzoneId && { fromSubzoneId: search.fromSubzoneId }),
          ...(search.toZoneId && { toZoneId: search.toZoneId }),
          ...(search.priceListId && { priceListId: search.priceListId }),
          ...(search.withDue && { withDue: search.withDue }),
          ...(search.branchId && { branchId: search.branchId }),
        },
      },
    }
  );

  const { data: sum, loading: sumLoading } = useQuery(
    gql`
      ${SUM_MANIFEST_QUERY.query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: {
        first: rowsPerPage,
        page: page + 1,
        input: {
          transactionTypeCode: listType.toUpperCase(),
          approved: search.approved,
          ...(search.fromDate && { fromDate: search.fromDate }),
          ...(search.toDate && { toDate: search.toDate }),
          ...(search.code && { code: search.code }),
          ...(search.transactionTypeCode && { transactionTypeCode: search.transactionTypeCode }),
          ...(search.withAvailableShipments && { withAvailableShipments: search.withAvailableShipments }),
          ...(search.customerId && { customerId: search.customerId }),
          ...(search.deliveryAgentId && { deliveryAgentId: search.deliveryAgentId }),
          ...(search.fromZoneId && { fromZoneId: search.fromZoneId }),
          ...(search.fromSubzoneId && { fromSubzoneId: search.fromSubzoneId }),
          ...(search.toZoneId && { toZoneId: search.toZoneId }),
          ...(search.toSubzoneId && { toSubzoneId: search.toSubzoneId }),
          ...(search.priceListId && { priceListId: search.priceListId }),
          ...(search.withDue && { withDue: search.withDue }),
          ...(search.branchId && { branchId: search.branchId }),
        },
      },
    }
  );

  const manifestListData =
    data?.listManifests?.data !== null ? data?.listManifests?.data : null;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    pushUrlSearch({ ...urlQuery, page: newPage, rowsPerPage: rowsPerPage, });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setSearch((prev) => ({ ...prev, page: 0 }));
    pushUrlSearch({ ...urlQuery, page: 0, rowsPerPage: +event.target.value });
  };
  const resetDate = () => {
    setDateRange([null, null]);
  };

  const lookupsComplete = (data, updateValue, name) => {
    if (updateValue && lookupCodeIsValid(data, updateValue)) {
      setValue(name, updateValue);
    }
  };

  const parseData = (data) => {
    return data;
  };
  const onSubmit = (data) => {
    filterAnchor === "bottom" &&
      setState((prev) => ({ ...prev, [filterAnchor]: false }));
    let handledData = {
      ...(dateRange[0] && {
        fromDate,
      }),
      ...(dateRange[1] && {
        toDate,
      }),
      code: data.code,
      ...data,
      page: 0,
    };

    for (const key in handledData) {
      if (
        handledData[key] === undefined ||
        handledData[key] === "" ||
        handledData[key] === null ||
        handledData[key].length === 0
      ) {
        delete handledData[key];
      }
    }
    setSearch((prev) => ({
      ...handledData,
      refetch: !prev.refetch,
    }));
    for (const key in handledData) {
      if (handledData[key] === null) {
        delete handledData[key];
      }
    }
    pushUrlSearch({
      ...handledData,
      rowsPerPage: rowsPerPage,
    });
  };

  const EnhancedTableToolbar = (props) => {
    return (
      <Toolbar className={clsx(classes.toolbar)} variant="dense">
        <Typography
          className={classes.titles}
          color="inherit"
          variant="h6"
          component="div"
        >
          {manifestName[`${listType}`]}
        </Typography>

        <Tooltip title="Filter list">
          <IconButton
            aria-label="filter list"
            onClick={() => props.filter()}
            size="large"
          >
            <FilterList />
          </IconButton>
        </Tooltip>

        <SecuredNavLink
          permission={GetManifestPermissionSlug(listType, 'create')}
          to={{ pathname: `/admin/manifests/create/${listType}` }}
        >
          <Tooltip title="Add shipment">
            <IconButton
              aria-label="filter list"
              className={classes.addIcon}
              size="large"
            >
              <Add color="primary" />
            </IconButton>
          </Tooltip>
        </SecuredNavLink>
      </Toolbar>
    );
  };

  const branchTransferCondition = listType === "bmt" || listType === "bmr";
  const customerCondition = listType === "pkm" || listType === "otr" || listType === "rtrn";

  EnhancedTableToolbar.propTypes = {};

  trackingBody = (
    <Grid container item xs={12} justifyContent="center" sx={{ width: "100%" }}>
      {/* *******Table******* */}
      <Grid container item>
        <Paper className={clsx(classes.paper)}>
          <EnhancedTableToolbar
            filter={toggleDrawer(filterAnchor, true)}
            {...props}
          />
          {loading || manifestListData?.length === 0 ? (
            <Grid
              container
              item
              justifyContent="center"
              className={classes.track}
            >
              {loading ? (
                <FullScreenLoading minHeight={30} />
              ) : (
                <Box textAlign="center">
                  <img width="50%" src={emptyList} alt={"delivery box"} />
                  <Box
                    component={Typography}
                    variant="h6"
                    color="text.secondary"
                    marginTop="0"
                  >
                    {t("searchNoResult")}
                  </Box>
                </Box>
              )}
            </Grid>
          ) : (
            <TableFixedHeaderWraper>
              <Table aria-labelledby="tableTitle" aria-label="enhanced table">
                <EnhancedTableHead
                  type={listType}
                  rowCount={manifestListData?.length}
                />
                <TableBody>
                  {manifestListData &&
                    manifestListData?.map((row, index) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={index}
                        // onClick={(e) => {
                        //   e.stopPropagation();
                        //   pushUrl(props, `/admin/manifests/${row?.id}/edit`);
                        // }}
                        >
                          <CellLink
                            component="th"
                            scope="row"
                            pathname={`/admin/manifests/${row?.id}`}
                          >
                            {row.code}
                          </CellLink>
                          <FixedTableCell>
                            {dateTimeFormatA(row?.date)}
                          </FixedTableCell>
                          <CellLink
                            pathname={`/admin/branches/${row?.branch?.id}`}
                          >
                            {row?.branch?.name}
                          </CellLink>

                          {branchTransferCondition ? (
                            <CellLink
                              pathname={`/admin/branches/${row?.referenceBranch?.id}`}
                            >
                              {row?.referenceBranch?.name}
                            </CellLink>
                          ) : null}
                          {customerCondition ? (
                            <CellLink
                              pathname={`/admin/cutomers/${row?.customer?.id}`}
                            >
                              {row?.customer?.name}
                            </CellLink>
                          ) : null}

                          {listType !== "rits" ? (
                            <CellLink
                              pathname={`/admin/delivery-agents/${row?.deliveryAgent?.id}`}
                            >
                              {row?.deliveryAgent?.name}
                            </CellLink>
                          ) : null}

                          {row?.transactionType ? (
                            <CellLink
                              pathname={`/admin/shipment-transaction-types/${row?.transactionType?.id}`}
                            >
                              {row?.transactionType?.name}
                            </CellLink>
                          ) : (
                            <FixedTableCell> </FixedTableCell>
                          )}

                          <FixedTableCell>{row?.shipmentsCount}</FixedTableCell>
                          {listType === "rtrn" ? (
                            <FixedTableCell>{row?.returnedValue}</FixedTableCell>
                          ) : null}
                          <FixedTableCell>
                            {row?.approved ? (
                              <Icon className={classes.iconColor}>
                                check_circle_outline
                              </Icon>
                            ) : (
                              <Icon color="error">highlight_off</Icon>
                            )}
                          </FixedTableCell>
                        </TableRow>
                      );
                    })}
                  {sum?.sumManifests && (
                    <TableRow hover>
                      <FixedTableCell
                        colSpan={
                          (listType === "rits" && 3) ||
                          (branchTransferCondition || customerCondition
                            ? 5
                            : 4)
                        }
                      />
                      <FixedTableCell>{t("total")}</FixedTableCell>
                      <FixedTableCell>
                        {sum?.sumManifests?.shipmentsCount}
                      </FixedTableCell>
                      {listType === "rtrn" && <FixedTableCell>
                        {sum?.sumManifests?.returnedValue}
                      </FixedTableCell>}
                      <FixedTableCell />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableFixedHeaderWraper>
          )}
          <MUITablePagination
            count={data?.listManifests?.paginatorInfo?.total}
            rowsPerPage={rowsPerPage}
            page={!data?.listManifests ? 0 : page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Grid>
    </Grid>
  );

  return (
    <Root>
      {props.children}

      <Drawer
        className={clsx(classes.drawer)}
        variant="persistent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor={filterAnchor}
        open={state[filterAnchor]}
        onClose={() => false}
      >
        <div className={classes.drawerHeader} />

        <Toolbar className={clsx(classes.toolbar)} variant="dense">
          <Typography
            className={classes.titles}
            color="inherit"
            variant="h6"
            component="div"
          >
            {t("search")}
          </Typography>
          <IconButton
            aria-label="done"
            size="small"
            // disabled={Boolean(numSelected <= 0)}
            onClick={toggleDrawer(filterAnchor, false)}
            color={"primary"}
          >
            <Clear />
          </IconButton>
        </Toolbar>

        <Grid
          container
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          className={classes.filterForm}
        >
          <Grid
            container
            item
            sm={12}
            justifyContent="flex-start"
            spacing={1}
            className={classes.filterField}
          >
            <Grid item sm={12} xs={12}>
              <MUIDateRangeCustom
                value={dateRange}
                onChangeValue={(value) => setDateRange(value)}
                resetDate={resetDate}
              />
            </Grid>

            <Grid item sm={12} xs={12}>
              <ControlMUItextField
                control={control}
                errors={errors}
                name={"code"}
                label={t("code")}
              />
            </Grid>
            <Grid container item sm={12} alignItems="flex-start">
              <CustomAutocomplete
                name={"branchId"}
                label={t("currentBranch")}
                control={control}
                errors={errors}
                parseData={(data) => parseData(data)}
                onCompleted={(data) =>
                  handelAutocompleteDefaultValue(
                    data,
                    urlQuery["branchId"],
                    "branch"
                  )
                }
                query={LIST_BRANCHES_DROPDOWN.query}
                defaultValue={autocompleteValues.branch}
              />
            </Grid>
            <Grid item sm={12} xs={12}>
              <MUIselect
                control={control}
                errors={errors}
                name={"approved"}
                label={t("status")}
                data={[
                  { key: t("approved"), value: true },
                  { key: t("notApproved"), value: false },
                ]}
                defaultValue={urlQuery?.approved}
              />
            </Grid>
            <Grid item sm={12} xs={12}>
              <CustomAutocomplete
                control={control}
                errors={errors}
                name={"deliveryAgentId"}
                label={t("shippingAgent")}
                onCompleted={(data) =>
                  handelAutocompleteDefaultValue(
                    data,
                    urlQuery["deliveryAgentId"],
                    "deliveryAgent"
                  )
                }
                parseData={(data) => parseData(data)}
                query={LIST_DELIVERY_AGENTS_DROPDOWN.query}
                variables={{
                  input: {
                    active: true,
                  },
                }}
                defaultValue={autocompleteValues.deliveryAgent}
              />
            </Grid>
            <Can showException permission={customerCondition}>
              <Grid item sm={12} xs={12}>
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  name={"customerId"}
                  label={t("customer")}
                  onCompleted={(data) =>
                    handelAutocompleteDefaultValue(
                      data,
                      urlQuery["customerId"],
                      "customer"
                    )
                  }
                  parseData={(data) => parseData(data)}
                  query={LIST_CUSTOMERS_DROPDOWN.query}
                  variables={{
                    input: {
                      active: true,
                    },
                  }}
                  defaultValue={autocompleteValues.customer}
                />
              </Grid>
            </Can>
            <Grid item sm={12} xs={12}>
              <CustomAutocomplete
                control={control}
                errors={errors}
                name={"fromZoneId"}
                label={t("fromZone")}
                onChangeValue={(e) => {
                  setValue("fromSubzoneId", "");
                  delete urlQuery["fromSubzoneId"];

                  pushUrlSearch({ ...urlQuery });
                }}
                onCompleted={(data) =>
                  handelAutocompleteDefaultValue(
                    data,
                    urlQuery["fromZoneId"],
                    "fromZone"
                  )
                }
                variables={{ input: { parentId: null } }}
                parseData={(data) => parseData(data)}
                query={LSIT_ZONES_DROPDOWN.query}
                defaultValue={autocompleteValues.fromZone}
              />
            </Grid>
            <Grid item sm={12} xs={12}>
              <CustomAutocomplete
                control={control}
                errors={errors}
                name={"fromSubzoneId"}
                label={t("fromSubzone")}
                skip={!watch("fromZoneId")}
                disabled={!watch("fromZoneId")}
                variables={{ input: { parentId: watch("fromZoneId") } }}
                onCompleted={(data) =>
                  handelAutocompleteDefaultValue(
                    data,
                    urlQuery["fromSubzoneId"],
                    "fromSubzone"
                  )
                }
                parseData={(data) => parseData(data)}
                query={LSIT_ZONES_DROPDOWN.query}
                defaultValue={autocompleteValues.fromSubzone}
              />
            </Grid>
            <Grid item sm={12} xs={12}>
              <CustomAutocomplete
                control={control}
                errors={errors}
                name={"toZoneId"}
                label={t("toZone")}
                onChangeValue={(e) => {
                  delete urlQuery["toSubzoneId"];
                  setValue("toSubzoneId", "");

                  pushUrlSearch({ ...urlQuery });
                }}
                onCompleted={(data) =>
                  handelAutocompleteDefaultValue(
                    data,
                    urlQuery["toZoneId"],
                    "toZone"
                  )
                }
                variables={{ input: { parentId: null } }}
                parseData={(data) => parseData(data)}
                query={LSIT_ZONES_DROPDOWN.query}
                defaultValue={autocompleteValues.toZone}
              />
            </Grid>
            <Grid item sm={12} xs={12}>
              <CustomAutocomplete
                control={control}
                errors={errors}
                name={"toSubzoneId"}
                label={t("toSubzone")}
                skip={!watch("toZoneId")}
                disabled={!watch("toZoneId")}
                variables={{ input: { parentId: watch("toZoneId") } }}
                onCompleted={(data) =>
                  handelAutocompleteDefaultValue(
                    data,
                    urlQuery["toSubzoneId"],
                    "toSubzone"
                  )
                }
                parseData={(data) => parseData(data)}
                query={LSIT_ZONES_DROPDOWN.query}
                defaultValue={autocompleteValues.toSubzone}
              />
            </Grid>
          </Grid>

          <Grid
            container
            item
            sm={12}
            className={classes.button}
            alignItems="center"
            justifyContent="flex-end"
          >
            <Button
              type="submit"
              fullWidth
              variant="contained"
              size="medium"
              color="primary"
              startIcon={<Search />}
            >
              {t("search")}
            </Button>
          </Grid>
        </Grid>
      </Drawer>

      <Grid
        container
        justifyContent="center"
        className={clsx(classes.content, classes.mainTracking, {
          [classes.contentShift]: state[filterAnchor],
        })}
      >
        {trackingBody}
      </Grid>
    </Root>
  );
};

export default ManifestList;
