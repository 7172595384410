import React, { Fragment, useState } from "react";
import {
  List,
  ListItem,
  IconButton,
  Tooltip,
  Dialog,
  DialogContent,
  DialogTitle,
  ListItemIcon,
  Typography,
} from "@mui/material";
import { Print } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { Globals } from "../HOC/Classes/Globals";
import RemoveIcon from '@mui/icons-material/Remove';


const PrintComponent = (props) => {
  const { shipmentsId, hidden = [], oneShipment, batch, noIcon, openPrintDialogAfterSave, afterSave, type } = props;
  const { t, i18n } = useTranslation();
  const [openPrintDialog, setOpenPrintDialog] = useState(false)

  const printUrl = batch ? `${window.location.origin}/batch` : `${window.location.origin}/shipment`;


  const defaultPrint = Globals.settings.waybillCode;

  const handleOpenPrint = () => {
    !defaultPrint && setOpenPrintDialog(true);
    defaultPrint && printLink(defaultPrint);
    defaultPrint && type && afterSave()
  };

  useEffect(() => {
    openPrintDialogAfterSave && handleOpenPrint()
  }, [openPrintDialogAfterSave])

  const handleClosePrint = () => {
    type && afterSave()
    setOpenPrintDialog(false);
  };

  const printLink = (code) => {
    if (code === "SHEET_SAME_1") {
      return window.open(`${printUrl}/print/${shipmentsId.toString()}/1c`);
    }
    if (code === "copy" || code === "SHEET_DIFF_2") {
      return window.open(`${printUrl}/print/${shipmentsId.toString()}/2d`);
    }
    if (code === "SHEET_SAME_2") {
      return window.open(`${printUrl}/print/${shipmentsId.toString()}/2c`);
    }
    if (code === "SHEET_SAME_3") {
      return window.open(`${printUrl}/print/${shipmentsId.toString()}/3c`);
    }
    if (code === "SHEET_DIFF_3") {
      return window.open(`${printUrl}/print/${shipmentsId.toString()}/3d`);
    }
    if (code === "STICKER_4X5") {
      return window.open(
        `${printUrl}/sticky-print/${shipmentsId.toString()}`
      );
    }
    if (code === "STICKER_10X15") {
      return window.open(
        `${printUrl}/sticky10X15-print/${shipmentsId.toString()}`
      );
    }
    if (code === "STICKER_7X9") {
      return window.open(
        `${printUrl}/sticky7X9-print/${shipmentsId.toString()}`
      );
    }
  };

  const hideItem = (name) => hidden.includes(name);

  const printTypes = [
    {
      id: 1,
      hide: "1c",
      oneShipment: !oneShipment,
      printLink: "SHEET_SAME_1",
      title: t("OneShipment")
    },
    {
      id: 2,
      oneShipment: oneShipment ?? false,
      hide: "2d",
      printLink: "copy",
      title: i18n.language === "en" ? t("copy", { count: 1 }) : t("copy")
    },
    {
      id: 3,
      hide: "2c",
      oneShipment: oneShipment,
      printLink: "SHEET_SAME_2",
      title: oneShipment
        ? t("copy", { count: 2 })
        : t("twoCopyForOneShipment")
    },
    {
      id: 4,
      hide: "3c",
      printLink: "SHEET_SAME_3",
      title: oneShipment
        ? t("copy", { count: 3 })
        : t("threeCopyForOneShipment")
    },
    {
      id: 5,
      hide: "2d",
      oneShipment: !oneShipment,
      printLink: "SHEET_DIFF_2",
      title: t("twoDifferentShipments")
    },
    {
      id: 6,
      hide: "3d",
      printLink: "SHEET_DIFF_3",
      title: t("threeDifferentShipments")
    },
    {
      id: 7,
      hide: "sticky",
      printLink: "STICKER_4X5",
      title: t("barcodeSticker4x5")
    },
    {
      id: 8,
      hide: "sticky7X9",
      printLink: "STICKER_7X9",
      title: t("barcodeSticker7x9")
    },
    {
      id: 9,
      hide: "sticky10X15",
      printLink: "STICKER_10X15",
      title: t("barcodeSticker10X15")
    },
  ]

  return (
    <Fragment>
      {!noIcon &&
        <IconButton
          aria-label="print"
          onClick={handleOpenPrint}
          disabled={shipmentsId?.length === 0}
          size="large">
          <Print fontSize="inherit" />
        </IconButton>
      }
      <Dialog
        open={openPrintDialog}
        onClose={handleClosePrint}
      >
        <DialogTitle sx={{ display: "flex", alignItems: "center", pb: 1 }}>
          <ListItemIcon sx={{ minWidth: 35 }}>
            <Print fontSize="medium" />
          </ListItemIcon>
          {t("print")}
        </DialogTitle>
        <DialogContent sx={{ p: 0, pb: 2 }}>
          <List>
            {
              printTypes.map((e) =>
                !hideItem(e.hide) && (e?.oneShipment || e.oneShipment === undefined) && (
                  <ListItem
                    sx={{ px: 5 }}
                    key={e.id}
                    button
                    onClick={() => {
                      printLink(e.printLink)
                      handleClosePrint();
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: 30 }}>
                      <RemoveIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="body1">
                      {e.title}
                    </Typography>
                  </ListItem>
                )
              )
            }
          </List>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default PrintComponent;


// {!hideItem("2d") && oneShipment && (
//   <ListItem
//     className={classes.listItem}
//     button
//     onClick={() => {
//       printLink("copy")
//       handleClosePrint();
//     }}
//   >
//     {i18n.language === "en" ? t("copy", { count: 1 }) : t("copy")}
//   </ListItem>
// )}
// {!hideItem("2c") && (
//   <ListItem
//     className={classes.listItem}
//     button
//     onClick={() => {
//       printLink("SHEET_SAME_2")
//       handleClosePrint();
//     }}
//   >
//     {oneShipment
//       ? t("copy", { count: 2 })
//       : t("twoCopyForOneShipment")}
//   </ListItem>
// )}
// {!hideItem("3c") && (
//   <ListItem
//     className={classes.listItem}
//     button
//     onClick={() => {
//       printLink("SHEET_SAME_3")
//       handleClosePrint();
//     }}
//   >
//     {oneShipment
//       ? t("copy", { count: 3 })
//       : t("threeCopyForOneShipment")}
//   </ListItem>
// )}
// {!hideItem("2d") && !oneShipment && (
//   <ListItem

//     button
//     onClick={() => {
//       printLink("SHEET_DIFF_2")
//       handleClosePrint();
//     }}
//   >
//     {t("twoDifferentShipments")}
//   </ListItem>
// )}
// {!hideItem("3d") && (
//   <ListItem
//     button
//     onClick={() => {
//       printLink("SHEET_DIFF_3")
//       handleClosePrint();
//     }}
//   >
//     {t("threeDifferentShipments")}
//   </ListItem>
// )}
// {!hideItem("sticky") && (
//   <ListItem
//     button
//     onClick={() => {
//       printLink("STICKER_4X5")
//       handleClosePrint();
//     }}
//   >
//     {t("barcodeSticker4x5")}
//   </ListItem>
// )}
// {!hideItem("sticky7X9") && (
//   <ListItem
//     button
//     onClick={() => {
//       printLink("STICKER_7X9")
//       handleClosePrint();
//     }}
//   >
//     {t("barcodeSticker7x9")}
//   </ListItem>
// )}
// {!hideItem("sticky10X15") && (
//   <ListItem
//     button
//     onClick={() => {
//       printLink("STICKER_10X15")
//       handleClosePrint();
//     }}
//   >
//     {t("barcodeSticker10X15")}
//   </ListItem>
// )}
