import React, { useEffect, useRef, useState } from "react";

import { styled } from "@mui/material/styles";

import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Icon,
  IconButton,
  Paper,
  SwipeableDrawer,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";

import { gql, useMutation, useQuery } from "@apollo/client";
import {
  AddCircleOutline,
  AttachMoney,
  Done,
  DoneAll,
  Print,
} from "@mui/icons-material";
import { Alert } from "@mui/material";
import clsx from "clsx";
import moment from "moment";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import emptyList from "../../assets/Image/received.png";
import {
  APPROVE_PAYMENT_MUTATION,
  GL_APPROVE_PAYMENT_MUTATION,
  PAYMENT_BY_ID_QUERY,
  SAVE_PAYMENT_MUTATION,
} from "./PaymentListQuary";
import ShipmentList from "./PaymentShipments/ShipmentList";
// import ControlMUItextField from '../HOC/MUI/ControlMUItextField';
import Grid from "@mui/material/Unstable_Grid2";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useHistory, withRouter } from "react-router";
import NotFound from "../../Error/NotFound";
import {
  LIST_CUSTOMERS_DROPDOWN,
  LIST_DELIVERY_AGENTS_DROPDOWN,
  LIST_SAFES_DROPDOWN,
  LIST_TRANSACTION_TYPES_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import useWidth from "../../Hooks/useWidth";
import ListBranches from "../HOC/ComponentWithSpecificQuery/ListBranches";
import ShipmentsTable from "../HOC/CustomComponents/ShipmentsTable";
import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";
import { pushUrl, windowReplaceUrl } from "../HOC/CustomFunctions/pushUrl";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import CustomButton from "../HOC/MUI/CustomButton";
// import MUIDate from "../HOC/MUI/MUIDate";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import BarcodeSelector from "./PaymentShipments/BarcodeSelector";
import usePaymentsDetails from "./paymentDetails";
import MUIDateTime from "../HOC/MUI/MUIDateTime";
import usePaymentPickupsDetails from "./paymentPickupsDetails";
import PickupList from "./PaymentPickups/PickupList";
import BarcodePickupSelector from "./PaymentPickups/BarcodeSelector";

const PREFIX = "PaymentForm";

const classes = {
  track: `${PREFIX}-track`,
  typo: `${PREFIX}-typo`,
  mainTracking: `${PREFIX}-mainTracking`,
  paper: `${PREFIX}-paper`,
  appBar: `${PREFIX}-appBar`,
  title: `${PREFIX}-title`,
  textAlign: `${PREFIX}-textAlign`,
  divider: `${PREFIX}-divider`,
  table: `${PREFIX}-table`,
  filterAnchor: `${PREFIX}-filterAnchor`,
  shipmentList: `${PREFIX}-shipmentList`,
  button: `${PREFIX}-button`,
  glApprovedButton: `${PREFIX}-glApprovedButton`,
  toolbar: `${PREFIX}-toolbar`,
  selectPadding: `${PREFIX}-selectPadding`,
  margin: `${PREFIX}-margin`,
  titles: `${PREFIX}-titles`,
  root: `${PREFIX}-root`,
  addIcon: `${PREFIX}-addIcon`,
  errorColor: `${PREFIX}-errorColor`,
  overlay: `${PREFIX}-overlay`,
  pagination: `${PREFIX}-pagination`,
  totalPaper: `${PREFIX}-totalPaper`,
  totalTitle: `${PREFIX}-totalTitle`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.track}`]: {
    marginTop: theme.spacing(2),
  },

  [`& .${classes.typo}`]: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    fontWeight: 600,
  },

  [`& .${classes.mainTracking}`]: {
    margin: theme.spacing(1, 0),
    width: "100%",
  },

  [`& .${classes.paper}`]: {
    marginBottom: theme.spacing(2),
    position: "relative",
    minHeight: "327px",
    gridAutoRows: "max-content",
  },
  [`& .${classes.totalPaper}`]: {
    marginBottom: theme.spacing(2),
    position: "relative",
    // minHeight: "327px",
    gridAutoRows: "max-content",
  },
  [`& .${classes.totalTitle}`]: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    // fontSize:
  },

  [`& .${classes.appBar}`]: {
    position: "relative",
  },

  [`& .${classes.title}`]: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },

  [`& .${classes.textAlign}`]: {
    textAlign: "justify",
    padding: theme.spacing(1),
  },

  [`& .${classes.divider}`]: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
      height: "1px",
    },
  },

  [`& .${classes.table}`]: {
    display: "grid",
    width: "100%",
  },

  [`& .${classes.filterAnchor}`]: {
    [theme.breakpoints.down("md")]: {
      width: "auto",
    },
    // overflow: "scroll",
    "& .MuiDrawer-paper": {
      [theme.breakpoints.up("sm")]: {
        // width: "50%"
      },
      top: "auto",
      width: "25%",
      border: "1px solid #0000001f",
      height: "100%",
      overflow: "hidden",
    },
  },

  [`& .${classes.shipmentList}`]: {
    width: "100%",
    height: "100%",
    overflow: "hidden",
  },

  [`& .${classes.button}`]: {
    margin: theme.spacing(1),
  },

  [`& .${classes.glApprovedButton}`]: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.info.main,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.info.dark,
    },
  },

  [`& .${classes.toolbar}`]: {
    backgroundColor: "#fff",
    border: "solid 1px #eee",
    zIndex: "1300",
    justify: "space-between",
  },

  [`& .${classes.selectPadding}`]: {
    paddingTop: "6px",
    paddingBottom: "5px",
  },

  [`& .${classes.margin}`]: {
    margin: theme.spacing(1, 2),
  },

  [`& .${classes.titles}`]: {
    flex: "1 1 100%",
  },

  [`& .${classes.root}`]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },

  [`& .${classes.addIcon}`]: {
    color: theme.palette.success.main,
  },

  [`& .${classes.errorColor}`]: {
    color: theme.palette.error.main,
  },

  [`& .${classes.overlay}`]: {
    backgroundColor: "#ffffffad",
    position: "absolute",
    zIndex: 2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    top: 0,
    right: 0,
  },

  [`& .${classes.pagination}`]: {
    position: "absolute",
    bottom: 0,
    right: 0,
  },
}));

const EnhancedTableToolbar = (props) => {
  const { selectedShipment, addShipment, approved, barcode, title } = props;

  return (
    <Toolbar className={clsx(classes.root)}>
      <Typography
        className={classes.titles}
        color="inherit"
        variant="subtitle1"
        component="div"
      >
        {title} {selectedShipment.length}
      </Typography>

      <IconButton
        aria-label="Add"
        className={classes.addIcon}
        onClick={addShipment}
        disabled={approved}
        size="large"
      >
        <AddCircleOutline />
      </IconButton>
      {barcode ? (
        <IconButton
          aria-label="Add"
          onClick={barcode}
          disabled={approved}
          size="large"
        >
          <Icon>qr_code_scanner</Icon>
        </IconButton>
      ) : null}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  selectedShipment: PropTypes.array.isRequired,
};

/////////////////DIALOG///////////////
const dateFormat = (date) => moment(date).locale("en").format("YYYY-MM-DD HH:mm:ss");

const PaymentForm = (props) => {
  const { t } = useTranslation();

  const formType = props.match.params.type?.toUpperCase();

  const [paymentType, setPaymentType] = useState(formType ? formType : null);

  const theme = useTheme();
  const [autocompleteValues, setAutocompleteValues] = useState({
    branch: null,
    customer: null,
    safe: null,
    deliveryAgent: null,
    transactionType: null,
  });
  const [disableForm, setDisableForm] = useState(false);
  const [openDialog, setOpenDialog] = useState({
    open: false,
    edit: false,
    approve: false,
    glApprove: false,
  });
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);
  const [pickedShipment, setPickedShipment] = useState({ shipments: [] });
  const [pickedPickups, setPickedPickups] = useState({ pickups: [] });
  const paymentShipments = pickedShipment.shipments;
  const paymentPickups = pickedPickups.pickups;
  const [paymentData, setPaymentData] = useState();

  const [fieldsState, setfieldsState] = useState({
    createdAt: new Date(),
  });
  const { enqueueSnackbar } = useSnackbar();

  const disabledFirstRender = useRef(false);

  const {
    formState: { errors },
    handleSubmit,
    setError,
    control,
    setValue,
    watch,
    clearErrors,
  } = useForm();

  const paymentId = props.match.params.id;

  /////////////////////////////////////////////////////////////////////////////////////////////////////

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const [savePaymentMutation, { loading: savePaymentLoad }] = useMutation(
    gql`
      ${SAVE_PAYMENT_MUTATION.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      onCompleted: (data) => {
        pickedShipment["shipments"] = paymentShipments
          .filter((i) => !i["pendingDelete"])
          .map((i) => {
            const response = data?.savePayment.entries
              .filter((i) => i.shipment !== null)
              .find(
                (ele) => ele.shipment.id === i.shipment.id
              );
            if (response) {
              return { ...i, paidAmount: response.paidAmount };
            } else {
              return i;
            }
          });
        pickedPickups["pickups"] = paymentPickups
          .filter((i) => !i["pendingDelete"])
          .map((i) => {
            const response = data?.savePayment.entries
              .filter((i) => i.pickup !== null)
              .find(
                (ele) => ele.pickup.id === i.pickup.id
              );
            if (response) {
              return { ...i, paidAmount: response?.paidAmount };
            } else {
              return i;
            }
          });
        setPaymentData(data["savePayment"]);
      },
    }
  );

  const { details: paymentDetails } = usePaymentsDetails({
    TotalData: paymentData,
    type: paymentType,
    approved: !paymentData?.approved,
    paymentShipments,
    setPickedShipment,
    savePaymentLoad,
  });
  const { details: paymentPickupsDetails } = usePaymentPickupsDetails({
    TotalData: paymentData,
    type: paymentType,
    approved: !paymentData?.approved,
    paymentPickups,
    setPickedPickups,
    savePaymentLoad,
  });

  const [approvePaymentMutation, { loading: approveLoad }] = useMutation(
    gql`
      ${APPROVE_PAYMENT_MUTATION.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      onCompleted: (data) => setPaymentData(data["approvePayment"]),
    }
  );

  const [glApprovePaymentMutation, { loading: glApproveLoad }] = useMutation(
    gql`
      ${GL_APPROVE_PAYMENT_MUTATION.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      onCompleted: (data) => setPaymentData(data["glApprovePayment"]),
    }
  );

  useEffect(() => {
    if (disabledFirstRender.current) {
      const shipments = paymentShipments
        .filter((item) => {
          return !item.pendingDelete;
        })
        .map((item) => {
          return parseInt(item.shipment.id);
        });
      const pickups = paymentPickups
        .filter((item) => {
          return !item.pendingDelete;
        })
        .map((item) => {
          return parseInt(item.pickup.id);
        });
      const variables = watch();
      variables["date"] = dateFormat(variables["date"]);

      savePaymentMutation({
        variables: {
          input: {
            ...variables,
            type: paymentType,
            shipmentIds: shipments,
            pickupIds: pickups,
          },
        },
      })
        .then((data) => { })
        .catch(({ graphQLErrors }) => {
          setValidationError(graphQLErrors, setError);

          console.log(graphQLErrors);
        });
    }
    disabledFirstRender.current = true;
  }, [pickedShipment, pickedPickups]);

  const { data: paymentByIdQuery, loading: paymentByIdLoad } = useQuery(
    gql`
      ${PAYMENT_BY_ID_QUERY.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      skip: !paymentId,
      variables: {
        id: parseInt(paymentId),
      },
      onCompleted: (data) => {
        if (data?.payment === null) return;
        const payment = data?.payment;
        setPaymentType(payment?.type?.code);

        setPaymentData(payment);
        setValue("id", parseInt(payment?.id));
        setValue("code", payment?.code);
        setValue("date", payment?.date);

        payment?.notes && setValue("notes", payment?.notes);

        setfieldsState((prev) => ({
          ...prev,
          createdAt: payment?.date,
        }));

        setAutocompleteValues({
          branch: payment.branch,
          ...(payment?.customer && {
            customer: payment?.customer,
          }),
          ...(payment?.deliveryAgent && {
            deliveryAgent: payment?.deliveryAgent,
          }),
          safe: payment.safe,
          transactionType: payment.transactionType,
        });
        const shipments = data.payment.entries.filter((i) => i.shipment !== null)
        const pickups = data.payment.entries.filter((i) => i.pickup !== null)
        pickedPickups["pickups"] = pickups;
        pickedShipment["shipments"] = shipments;
        setDisableForm(true);
      },
    }
  );

  const setSelect = (obj) => {
    setPickedShipment((prev) => {
      const shipments = [...prev.shipments, ...obj];
      // fireMutaion(shipments)
      return { shipments };
    });
  };
  const setSelectPickup = (obj) => {
    setPickedPickups((prev) => {
      const pickups = [...prev.pickups, ...obj];
      // fireMutaion(shipments)
      return { pickups };
    });
  };

  const [state, setState] = useState({
    shipmentList: {
      left: false,
      bottom: false,
      right: false,
    },
    pickupList: {
      left: false,
      bottom: false,
      right: false,
    },
  });

  const [barcodeDarwerState, setBarcodeDarwerState] = useState({
    left: false,
    bottom: false,
    right: false,
  });
  const [barcodePickupDarwerState, setBarcodePickupDarwerState] = useState({
    left: false,
    bottom: false,
    right: false,
  });

  const shipmentDrawerHandler = (anchor, open) => {
    setState((prev) => ({
      ...prev,
      shipmentList: { ...prev.shipmentList, [anchor]: open },
    }));
  };
  const pickupDrawerHandler = (anchor, open) => {
    setState((prev) => ({
      ...prev,
      pickupList: { ...prev.pickupList, [anchor]: open },
    }));
  };

  const barcodeDrawerHandler = (anchor, open) => {
    setBarcodeDarwerState((prev) => ({
      ...prev,
      [anchor]: open,
    }));
  };
  const barcodePickupDrawerHandler = (anchor, open) => {
    setBarcodePickupDarwerState((prev) => ({
      ...prev,
      [anchor]: open,
    }));
  };

  const screenWidth = useWidth();

  const anchor = screenWidth === "xs" ? "bottom" : "right";
  const shipLength = paymentShipments.length === 0;
  const pickupLength = paymentPickups.length === 0;

  ////////////////////END DRAWER////////////////
  const history = useHistory();

  const onSubmit = (data) => {
    const formChange =
      paymentData &&
      ((data?.deliveryAgentId &&
        data?.deliveryAgentId !== parseInt(paymentData?.deliveryAgent?.id)) ||
        (data?.customerId &&
          data?.customerId !== parseInt(paymentData?.customer?.id)) ||
        (data?.branchId &&
          data?.branchId !== parseInt(paymentData?.branch?.id)));

    if (paymentShipments.length !== 0 && formChange) {
      setOpenDialog((prev) => ({ ...prev, open: true, edit: true }));
    } else {
      const shipments = paymentShipments.map((item) =>
        parseInt(item.shipment.id)
      );
      const pickups = paymentPickups.map((item) =>
        parseInt(item.pickup.id)
      );

      data["date"] = dateFormat(data["date"]);

      savePaymentMutation({
        variables: {
          input: {
            ...data,
            type: paymentType,
            shipmentIds: shipments,
            pickupIds: pickups,
          },
        },
      })
        .then((data) => {
          setDisableForm(true);
          setValue("code", data?.data?.savePayment?.code);
          setValue("id", parseInt(data?.data?.savePayment?.id));
          const url = history.createHref({
            pathname: `/admin/payments/${data?.data?.savePayment?.id}/edit`,
          });
          windowReplaceUrl(url);
        })
        .catch(({ graphQLErrors }) => {
          console.log(graphQLErrors);
          setValidationError(graphQLErrors, setError);
        });
    }
  };

  const handleDialogClose = () => {
    setOpenDialog({ edit: false, open: false, approve: false });
  };

  const approvePayment = () => {
    approvePaymentMutation({
      variables: {
        id: parseInt(watch("id")),
      },
    })
      .then((data) => {
        handleDialogClose();
        enqueueSnackbar(t("successfullyApproved"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
        pushUrl(props, `/admin/payments/${watch("id")}`);
      })
      .catch(({ graphQLErrors }) => {
        console.log(graphQLErrors);
        handleDialogClose();

        enqueueSnackbar(graphQLErrors[0].message, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      });
  };

  const glApprovePayment = () => {
    glApprovePaymentMutation({
      variables: {
        id: parseInt(watch("id")),
      },
    })
      .then((data) => {
        handleDialogClose();
        enqueueSnackbar(t("successfullyGlApproved"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      })
      .catch(({ graphQLErrors }) => {
        console.log(graphQLErrors);
        handleDialogClose();

        enqueueSnackbar(graphQLErrors[0].message, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      });
  };
  const onApproved = () => {
    setOpenDialog((prev) => ({ ...prev, open: true, approve: true }));
  };
  const onGlApproved = () => {
    setOpenDialog((prev) => ({ ...prev, open: true, glApprove: true }));
  };
  // const disabledAddIcon = () => {
  //   const value = manifestType === "PKM" ? ["customerId", "branchId"] : ["deliveryAgentId", "branchId"]
  //   return !value.every(val => Object.keys(queryVariables.payment).includes(val))
  // }

  const parseData = (data) => {
    return data;
  };

  const customerField = (type) => {
    if (type === "CUSTM") {
      return (
        <Grid sm={4} xs={12}>
          <CustomAutocomplete
            control={control}
            name={"customerId"}
            label={t("customer")}
            rules={{ required: t("fieldIsRequired") }}
            parseData={(data) => parseData(data)}
            query={LIST_CUSTOMERS_DROPDOWN.query}
            skip={!watch("branchId")}
            variables={{
              input: {
                withDue: "PAY",
                branchId: {
                  value: watch("branchId"),
                  includeNull: true,
                },
              },
            }}
            defaultValue={autocompleteValues.customer}
          />
        </Grid>
      );
    } else if (type === "DLVBY") {
      return (
        <Grid sm={4} xs={12}>
          <CustomAutocomplete
            control={control}
            errors={errors}
            name={"deliveryAgentId"}
            label={t("shippingAgent")}
            rules={{ required: t("fieldIsRequired") }}
            disabled={!watch("branchId")}
            onChangeValue={(e) => {
              if (e?.id !== "") {
                clearErrors("deliveryAgentId");
              }
            }}
            parseData={(data) => parseData(data)}
            query={LIST_DELIVERY_AGENTS_DROPDOWN.query}
            skip={!watch("branchId")}
            variables={{
              input: {
                withDue: "PAY",
                branchId: {
                  value: watch("branchId"),
                  includeNull: true,
                },
              },
            }}
            defaultValue={autocompleteValues.deliveryAgent}
          />
        </Grid>
      );
    }
  };

  let formBody = null;
  let tableBodyShipments = null;
  let tableBodyPickups = null;
  /////////////////////// Customer Shipments Table /////////////////////////////

  tableBodyShipments = (
    <Grid container xs={12} justifyContent="center" sx={{ width: "100%" }}>
      {/* *******Table******* */}
      <Paper className={clsx(classes.table, classes.paper)}>
        <EnhancedTableToolbar
          {...props}
          title={t("shipmentsNumber")}
          selectedShipment={paymentShipments}
          addShipment={() => shipmentDrawerHandler(anchor, true)}
          barcode={() => barcodeDrawerHandler(anchor, true)}
          approved={paymentData?.approved}
        />

        <TableFixedHeaderWraper>
          {paymentDetails && (
            <ShipmentsTable
              page={page}
              rowsPerPage={rowsPerPage}
              data={paymentShipments}
              headCells={paymentDetails.tableHead}
              parseBodyCell={paymentDetails.tableBody}
              total={paymentDetails.tableTotal}
              sumRow={paymentDetails?.sum}
              sumTableNumber={paymentDetails?.sumTableNumber}
              sum={paymentType === 'DLVBY' ? false : true}
            />
          )}
        </TableFixedHeaderWraper>
        {paymentShipments.length > 4 && (
          <MUITablePagination
            // classeName={classes.pagination}
            count={paymentShipments.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}

        {(!watch("id") || !disableForm) && (
          <Box className={classes.overlay}>
            <Typography color="textPrimary" variant="body1">
              {t("saveManifestFirst")}
            </Typography>
          </Box>
        )}
        <Grid container justifyContent="center" alignItems="center">
          {shipLength && watch("id") && disableForm && (
            <Box textAlign="center" marginTop="50px" marginBottom="50px">
              <img width="50%" src={emptyList} alt={"delivery box"} />
              <Box
                component={Typography}
                variant="h6"
                color="text.secondary"
                marginTop="0"
              >
                {t("noResult")}
              </Box>
            </Box>
          )}
        </Grid>
      </Paper>
    </Grid>
  );
  tableBodyPickups = (
    <Grid container xs={12} justifyContent="center" sx={{ width: "100%" }}>
      {/* *******Table******* */}
      <Paper className={clsx(classes.table, classes.paper)}>
        <EnhancedTableToolbar
          {...props}
          title={t("pickupsNumber")}
          selectedShipment={paymentPickups}
          addShipment={() => pickupDrawerHandler(anchor, true)}
          barcode={() => barcodePickupDrawerHandler(anchor, true)}
          approved={paymentData?.approved}
        />

        <TableFixedHeaderWraper>
          {paymentPickupsDetails && (
            <ShipmentsTable
              page={page}
              rowsPerPage={rowsPerPage}
              data={paymentPickups}
              headCells={paymentPickupsDetails.tableHead}
              parseBodyCell={paymentPickupsDetails.tableBody}
              total={paymentPickupsDetails.tableTotal}
              sumRow={paymentPickupsDetails?.sum}
              sumTableNumber={paymentPickupsDetails?.sumTableNumber}
            // sum={true}
            />
          )}
        </TableFixedHeaderWraper>
        {paymentPickups.length > 4 && (
          <MUITablePagination
            // classeName={classes.pagination}
            count={paymentPickups.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}

        {(!watch("id") || !disableForm) && (
          <Box className={classes.overlay}>
            <Typography color="textPrimary" variant="body1">
              {t("saveManifestPickupFirst")}
            </Typography>
          </Box>
        )}
        <Grid container justifyContent="center" alignItems="center">
          {pickupLength && watch("id") && disableForm && (
            <Box textAlign="center" marginTop="50px" marginBottom="50px">
              <img width="50%" src={emptyList} alt={"delivery box"} />
              <Box
                component={Typography}
                variant="h6"
                color="text.secondary"
                marginTop="0"
              >
                {t("noResult")}
              </Box>
            </Box>
          )}
        </Grid>
      </Paper>
    </Grid>
  );

  const formCondition = !formType
    ? Boolean(watch("id")) && !paymentByIdLoad
    : !paymentByIdLoad;
  formBody = (
    <Grid
      container
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      spacing={2}
      sx={{ padding: "24px", width: "100%", margin: "0" }}
    >
      {formCondition ? (
        <Paper container component={Grid} sx={{ padding: 1.25, flexGrow: 1 }}>
          <Grid container sm={12} xs={12} justifyContent="space-between">
            <Typography color="inherit" variant="h6" component="div">
              {paymentDetails.label}
            </Typography>
            {(!shipLength || !pickupLength) && (
              <IconButton
                disabled={paymentByIdLoad}
                onClick={() =>
                  window.open(
                    `${window.location.origin}/report/print/payment/${watch(
                      "id"
                    )}/${paymentType}`
                  )
                }
                color="default"
                size="large"
              >
                <Print fontSize="inherit" />
              </IconButton>
            )}
          </Grid>
          {watch("id") && disableForm && (
            <Grid sm={12} xs={12}>
              <Alert
                severity="warning"
                action={
                  !paymentData?.approved && (
                    <Button
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setDisableForm(false);
                        // fireMutaion([])
                      }}
                    >
                      {t("update")}
                    </Button>
                  )
                }
              >
                {paymentData?.approved
                  ? t("updateRecordsForbidden")
                  : t("updateRecord")}
              </Alert>
            </Grid>
          )}

          <Grid
            spacing={2}
            container
            sx={{ position: "relative", flexGrow: 1 }}
          >
            {(paymentData?.approved || disableForm) && (
              <Box
                className={classes.overlay}
                sx={{ backgroundColor: "rgb(255 255 255 / 45%)" }}
              >
                {/* <Typography color="textPrimary" variant="body1" >لا يمكن التعديل</Typography> */}
              </Box>
            )}
            <Grid sm={4} xs={12}>
              <ControlMUItextField
                name="code"
                control={control}
                label={t("recordCode")}
                rules={{ required: watch("id") ? t("fieldIsRequired") : false }}

              // defaultValue={queryVariables?.payment?.code}
              // disabled={paymentByIdQuery?.payment?.approved || disableForm}
              />
            </Grid>
            <Grid sm={4} xs={12}>
              <MUIDateTime
                name="date"
                label={t("date")}
                control={control}
                defaultValue={paymentByIdQuery?.payment?.date}
                value={fieldsState.createdAt}
                onChange={(e) =>
                  setfieldsState((prev) => ({ ...prev, createdAt: e }))
                }
              />
            </Grid>
            <Grid sm={4} xs={12}>
              <ListBranches
                control={control}
                errors={errors}
                name={"branchId"}
                rules={{ required: t("fieldIsRequired") }}
                skip={paymentType === null}
                defaultValue={autocompleteValues.branch}
                onChangeValue={(e) => {
                  paymentType === "DLVBY" && setValue("deliveryAgentId", "");
                  paymentType === "CUSTM" && setValue("customerId", "");
                  setValue("safeId", "");
                  // setPickedShipment({ shipments: [] })
                }}
                skipDefaultBranch={Boolean(paymentId)}
              />
            </Grid>
            {customerField(paymentType)}
            <Grid sm={4} xs={12}>
              <CustomAutocomplete
                control={control}
                errors={errors}
                name={"transactionTypeId"}
                label={t("transactionType")}
                selectFirst={true}
                rules={{ required: t("fieldIsRequired") }}
                parseData={(data) => parseData(data)}
                query={LIST_TRANSACTION_TYPES_DROPDOWN.query}
                skip={paymentType === null}
                variables={{
                  input: {
                    type: "CSHP",
                  },
                }}
                defaultValue={autocompleteValues.transactionType}
              />
            </Grid>
            <Grid sm={4} xs={12}>
              <CustomAutocomplete
                control={control}
                errors={errors}
                name={"safeId"}
                disabled={!watch("branchId")}
                label={t("safe")}
                rules={{ required: t("fieldIsRequired") }}
                parseData={(data) => parseData(data)}
                query={LIST_SAFES_DROPDOWN.query}
                skip={!watch("branchId")}
                variables={{
                  input: {
                    branchId: watch("branchId"),
                  },
                }}
                defaultValue={autocompleteValues.safe}
              />
            </Grid>
            <Grid sm={12} xs={12}>
              <ControlMUItextField
                name="notes"
                control={control}
                label={t("notes")}
                rows={2}
              />
            </Grid>
          </Grid>

          <Grid sm={12} xs={12} container justifyContent="flex-end">
            {!disableForm && (
              <CustomButton
                customcolor={theme.palette.success.main}
                type="submit"
                className={classes.button}
                disabled={
                  paymentData?.approved || disableForm || savePaymentLoad
                }
                variant="contained"
                size="medium"
                loading={savePaymentLoad}
                // className={classes.button}
                startIcon={!savePaymentLoad && <Done />}
              >
                {!savePaymentLoad && t("save")}
              </CustomButton>
            )}
            {disableForm && (
              <Button
                disabled={
                  (shipLength && pickupLength) || paymentData?.approved || savePaymentLoad
                }
                className={classes.button}
                variant="contained"
                size="medium"
                color="primary"
                onClick={onApproved}
                name="approved"
                startIcon={<DoneAll />}
              >
                {t("approve")}
              </Button>
            )}
            {paymentData?.approved && (
              <Button
                disabled={paymentData?.glApproved}
                className={classes.glApprovedButton}
                variant="contained"
                size="medium"
                onClick={onGlApproved}
                name="glApproved"
                startIcon={<AttachMoney />}
              >
                {t("glApprove")}
              </Button>
            )}
          </Grid>
        </Paper>
      ) : (
        <FullScreenLoading />
      )}
    </Grid>
  );

  return paymentByIdQuery?.payment === null ? (
    <NotFound />
  ) : (
    <Root>
      {props.children}

      {formBody}

      <Dialog
        open={openDialog.open}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"xs"}
      >
        <DialogTitle id="alert-dialog-title">
          {openDialog.edit && t("updateRecord")}
          {openDialog.approve && t("approveRecord")}
          {openDialog.glApprove && t("glApprove")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {openDialog.edit && t("updateRecordMessage")}
            {openDialog.approve && t("approveRecordConfirmationMessage")}
            {openDialog.glApprove && t("glApproveRecordMessage")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            {t("cancel")}
          </Button>
          <Button
            disabled={approveLoad || glApproveLoad}
            onClick={() => {
              openDialog.edit && handleDialogClose();
              openDialog.edit && setDisableForm(true);
              openDialog.edit && setPickedShipment({ shipments: [] });
              openDialog.approve && approvePayment();
              openDialog.glApprove && glApprovePayment();
            }}
            color="primary"
            autoFocus
          >
            {openDialog.edit && t("update")}
            {openDialog.approve && !approveLoad && t("approve")}
            {openDialog.glApprove && !glApproveLoad && t("glApprove")}
            {(approveLoad || glApproveLoad) && <ButtonLoading />}
          </Button>
        </DialogActions>
      </Dialog>

      <SwipeableDrawer
        variant="persistent"
        disableDiscovery={true}
        disableSwipeToOpen={true}
        anchor={anchor}
        open={state.shipmentList[anchor]}
        onClose={() => false}
        onOpen={() => shipmentDrawerHandler(anchor, true)}
        classes={{ paper: classes.shipmentList }}
        dir={theme.direction}
      >
        {state.shipmentList[anchor] && (
          <ShipmentList
            setSelected={(obj) => {
              setSelect(obj);
            }}
            type={paymentType}
            selected={paymentShipments}
            done={() => shipmentDrawerHandler(anchor, false)}
            variables={watch()}
          />
        )}
      </SwipeableDrawer>
      <SwipeableDrawer
        variant="persistent"
        disableDiscovery={true}
        disableSwipeToOpen={true}
        anchor={anchor}
        open={state.pickupList[anchor]}
        onClose={() => false}
        onOpen={() => pickupDrawerHandler(anchor, true)}
        classes={{ paper: classes.shipmentList }}
        dir={theme.direction}
      >
        {state.pickupList[anchor] && (
          <PickupList
            setSelected={(obj) => {
              setSelectPickup(obj);
            }}
            type={paymentType}
            selected={paymentPickups}
            done={() => pickupDrawerHandler(anchor, false)}
            variables={watch()}
          />
        )}
      </SwipeableDrawer>

      <SwipeableDrawer
        disableDiscovery={true}
        disableSwipeToOpen={true}
        anchor={anchor}
        open={barcodeDarwerState[anchor]}
        onClose={() => false}
        onOpen={() => barcodeDrawerHandler(anchor, true)}
        sx={{
          width: "auto",
          "& .MuiDrawer-paper": {
            width: { md: "50%" },

            height: "100%",
            overflow: "hidden",
          },
        }}
        dir={theme.direction}
      >
        {barcodeDarwerState[anchor] && <BarcodeSelector
          setSelected={(obj) => {
            setSelect(obj);
          }}
          type={paymentType}
          selected={paymentShipments}
          done={() => barcodeDrawerHandler(anchor, false)}
          variables={watch()}
        />}
      </SwipeableDrawer>

      <SwipeableDrawer
        disableDiscovery={true}
        disableSwipeToOpen={true}
        anchor={anchor}
        open={barcodePickupDarwerState[anchor]}
        onClose={() => false}
        onOpen={() => barcodePickupDrawerHandler(anchor, true)}
        sx={{
          width: "auto",
          "& .MuiDrawer-paper": {
            width: { md: "50%" },

            height: "100%",
            overflow: "hidden",
          },
        }}
        dir={theme.direction}
      >
        {barcodePickupDarwerState[anchor] && <BarcodePickupSelector
          setSelected={(obj) => {
            setSelectPickup(obj);
          }}
          type={paymentType}
          selected={paymentPickups}
          done={() => barcodePickupDrawerHandler(anchor, false)}
          variables={watch()}
        />}
      </SwipeableDrawer>

      <Grid
        container
        spacing={3}
        justifyContent="center"
        className={classes.mainTracking}
      >
        {formCondition && paymentType === 'DLVBY' && <Grid container xs={12} justifyContent="center" sx={{ width: "100%" }}>
          {/* *******Table******* */}
          <Paper component={Grid} className={clsx(classes.table, classes.totalPaper)}>
            <Grid container
              justifyContent="center">
              <Grid xs={9} md={6}>
                <Typography
                  className={clsx(classes.titles, classes.totalTitle)}
                  color="inherit"
                  variant="subtitle1"
                  component="div"
                  sx={{ textAlign: "end" }}
                >
                  {t("totalDeservedAmount")}
                </Typography>
              </Grid>
              <Grid xs={3} md={6}>
                <Typography
                  className={clsx(classes.titles)}
                  color="inherit"
                  variant="subtitle1"
                  component="div"
                  sx={{ textAlign: "start" }}
                >
                  {paymentData?.paymentAmount}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>}
        {formCondition && tableBodyShipments}
        {formCondition && paymentType === 'DLVBY' && tableBodyPickups}
      </Grid>
    </Root>
  );
};

export default withRouter(PaymentForm);
