import React, { Fragment, useState } from "react";

import { styled } from "@mui/material/styles";

import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  SwipeableDrawer,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";

import { gql, useMutation, useQuery } from "@apollo/client";
import {
  CancelOutlined,
  Clear,
  Delete,
  Done,
  DoneAll,
} from "@mui/icons-material";
import clsx from "clsx";
import moment from "moment";
import { useForm } from "react-hook-form";
import emptyList from "../../assets/Image/received.png";
import MUItextField from "../HOC/MUI/MUItextField";
import { PICKUP, SAVE_PICKUP_MUTATION, UPDATE_PICKUP_STATUS } from "./Graphql";
// import ControlMUItextField from '../HOC/MUI/ControlMUItextField';
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import CustomButton from "../HOC/MUI/CustomButton";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";

import { useTranslation } from "react-i18next";
import { useHistory, withRouter } from "react-router";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";

import Grid from "@mui/material/Unstable_Grid2";
// import * as gqlb from "gql-query-builder";
import { useSnackbar } from "notistack";
import NotFound from "../../Error/NotFound";
import {
  LIST_CUSTOMERS_DROPDOWN,
  LIST_DELIVERY_AGENTS_DROPDOWN,
  LIST_TRANSACTION_TYPES_DROPDOWN,
  LIST_WAREHOUSE_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import useWidth from "../../Hooks/useWidth";
import { Globals } from "../HOC/Classes/Globals";
import ListBranches from "../HOC/ComponentWithSpecificQuery/ListBranches";
import CellLink from "../HOC/CustomComponents/CellLink";
import CustomDialog from "../HOC/CustomComponents/CustomDialog";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import LookupDropdown from "../HOC/CustomComponents/LookupDropdown";
import { Can } from "../HOC/CustomComponents/Secured";
// import ShipmentRegion from "../HOC/CustomComponents/ShipmentRegion";
// import ShipmentsTable from "../HOC/CustomComponents/ShipmentsTable";
import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";
import { lookupCodeIsValid } from "../HOC/CustomFunctions/lookupCodeIsValid";
import { pushUrl, windowReplaceUrl } from "../HOC/CustomFunctions/pushUrl";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import CustomSpinner from "../HOC/FunctionComponents/CustomSpinner";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import MUITimePicker from "../HOC/MUI/MUITimePicker";
import EnhancedTableToolbar from "./EnhancedTableToolbar";
import ProductList from "./PickupShipments/ProductList";
import BarcodeSelector from "./PickupShipments/BarcodeSelector";
import MUIDate from "../HOC/MUI/MUIDate";
import ProductsTable from "../HOC/CustomComponents/ProductsTable";
import { KeyValuePair } from "../HOC/CustomComponents/KeyValuePair";
import SpanLink from "../HOC/CustomComponents/SpanLink";
import { selectedIdIsValid } from "../HOC/CustomFunctions/selectedIsValid";
import MUIDateTime from "../HOC/MUI/MUIDateTime";


const PREFIX = "PickupForm";

const classes = {
  typo: `${PREFIX}-typo`,
  mainTable: `${PREFIX}-mainTable`,
  paper: `${PREFIX}-paper`,
  appBar: `${PREFIX}-appBar`,
  title: `${PREFIX}-title`,
  textAlign: `${PREFIX}-textAlign`,
  divider: `${PREFIX}-divider`,
  table: `${PREFIX}-table`,
  filterAnchor: `${PREFIX}-filterAnchor`,
  shipmentList: `${PREFIX}-shipmentList`,
  picker: `${PREFIX}-picker`,
  button: `${PREFIX}-button`,
  toolbar: `${PREFIX}-toolbar`,
  selectPadding: `${PREFIX}-selectPadding`,
  margin: `${PREFIX}-margin`,

  errorColor: `${PREFIX}-errorColor`,
  overlay: `${PREFIX}-overlay`,
  pagination: `${PREFIX}-pagination`,
  editIcon: `${PREFIX}-editIcon`,
  barcode: `${PREFIX}-barcode`,
  dialog: `${PREFIX}-dialog`,
  overlayPoduct: `${PREFIX}-overlayPoduct`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.typo}`]: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    fontWeight: 600,
  },

  [`& .${classes.mainTable}`]: {
    margin: theme.spacing(1, 0),
    width: "100%",
  },

  [`& .${classes.paper}`]: {
    marginBottom: theme.spacing(2),
    position: "relative",
    minHeight: "327px",
    gridAutoRows: "max-content",
  },

  [`& .${classes.appBar}`]: {
    position: "relative",
  },

  [`& .${classes.title}`]: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },

  [`& .${classes.textAlign}`]: {
    textAlign: "justify",
    padding: theme.spacing(1),
  },

  [`& .${classes.divider}`]: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
      height: "1px",
    },
  },

  [`& .${classes.table}`]: {
    display: "grid",
    width: "100%",
  },

  [`& .${classes.filterAnchor}`]: {
    [theme.breakpoints.down("md")]: {
      width: "auto",
    },
    // overflow: "scroll",
    "& .MuiDrawer-paper": {
      [theme.breakpoints.up("sm")]: {
        // width: "50%"
      },
      top: "auto",
      width: "25%",
      border: "1px solid #0000001f",
      height: "100%",
      overflow: "hidden",
    },
  },

  [`& .${classes.shipmentList}`]: {
    width: "100%",
    height: "100%",
    overflow: "hidden",
  },

  [`& .${classes.picker}`]: {
    [theme.breakpoints.down("md")]: {
      width: "auto",
    },
    // overflow: "scroll",
    "& .MuiDrawer-paper": {
      [theme.breakpoints.up("sm")]: {
        width: "50%",
      },

      height: "100%",
      overflow: "hidden",
    },
  },

  [`& .${classes.button}`]: {
    margin: theme.spacing(1),
  },

  [`& .${classes.toolbar}`]: {
    backgroundColor: "#fff",
    border: "solid 1px #eee",
    zIndex: "1300",
    justify: "space-between",
  },

  [`& .${classes.selectPadding}`]: {
    paddingTop: "6px",
    paddingBottom: "5px",
  },

  [`& .${classes.margin}`]: {
    margin: theme.spacing(1, 2),
  },

  [`& .${classes.errorColor}`]: {
    color: theme.palette.error.main,
  },

  [`& .${classes.overlay}`]: {
    backgroundColor: "#ffffffad",
    position: "absolute",
    zIndex: 2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    top: 0,
    right: 0,
  },

  [`& .${classes.pagination}`]: {
    position: "absolute",
    bottom: 0,
    right: 0,
  },

  [`& .${classes.editIcon}`]: {
    fontSize: theme.spacing(5),
    fontFamily: "Material Icons Outlined",
    color: theme.palette.text.primary,
  },

  [`& .${classes.overlayPoduct}`]: {
    backgroundColor: "#ffffffad",
    position: "absolute",
    zIndex: 2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    top: 0,
    right: 0,
  },
}));
//////////////////////////////////////////////////

export { };

/////////////////DIALOG///////////////

const timeFormat = (date) => moment(date).locale("en").format("HH:mm");
const stringFormatToDate = (date) => moment(date, "HH:mm").toDate();
const dateFormat = (date) => moment(date).locale("en").format("YYYY-MM-DD HH:mm:ss");

const WarehousePickupForm = (props) => {
  const pickupId = props.match.params.id;

  const [autocompleteValues, setAutocompleteValues] = useState({
    branch: null,
    customer: null,
    deliveryAgent: null,
    transactionType: null,
  });

  const theme = useTheme();

  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);
  const [pickupData, setPickupData] = useState({ pickupProducts: [] });
  const [CancelPickupDialog, setCancelPickupDialog] = useState(false);
  const [receivedInStoreDialog, setReceivedInStoreDialog] = useState(false);
  const [infoInProgress, setInfoInProgress] = useState(false);
  const [unEditable, setUnEditable] = useState(false);
  const [drawerState, setDrawerState] = useState({
    left: false,
    bottom: false,
    right: false,
  });

  const [date, setDate] = useState({
    date: new Date(),
    timeFrom: null,
    timeTo: null,
  });

  const pickupStatus = pickupData?.status?.code;
  const pickupProducts = pickupData.pickupProducts;

  const {
    formState: { errors },
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    clearErrors,
    setError,
  } = useForm();
  const { t } = useTranslation(["translation", "validation"]);

  const { enqueueSnackbar } = useSnackbar();

  /////////////////////////////////////////////////////////////////////////////////////////////////////

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  // const SETTINGS = gqlb.query({
  //   operation: "shippingSettings",
  //   fields: [{ defaultPKBKTransactionType: ["id", "name"] }],
  //   variables: {},
  // });

  // const chooseCustomerPermission = Globals.user.hasPermission(
  //   "shipping.shipment.choose_customer"
  // );
  // const { data: settings } = useQuery(
  //   gql`
  //     ${SETTINGS.query}
  //   `,
  //   {
  //     fetchPolicy: "no-cache",
  //     skip: !chooseCustomerPermission || pickupId,
  //     onCompleted: (data) => {
  //       const defaultTransactionType =
  //         data?.shippingSettings?.["defaultPKBKTransactionType"];
  //       setAutocompleteValues((prev) => ({
  //         ...prev,
  //         transactionType: defaultTransactionType,
  //       }));
  //     },
  //   }
  // );
  const user = Globals.user;
  const updatePickup = user.hasPermission(
    "shipping.warehouse_pickup.update_status"
  );

  const customerId = watch("customerId") ?? user?.account?.id;

  const [savePickupMutation, { loading: savePickupLoad }] = useMutation(
    gql`
      ${SAVE_PICKUP_MUTATION.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      onCompleted: (data) => setPickupData(data.saveWarehousePickup),
      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      },
    }
  );

  const [updatePickupStatusMutation, { loading: updateStatusLoad }] =
    useMutation(
      gql`
        ${UPDATE_PICKUP_STATUS.query}
      `,
      {
        fetchPolicy: "no-cache",
        nextFetchPolicy: "no-cache",
        onError: (error) => {
          enqueueSnackbar(error.message, {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            TransitionComponent: Collapse,
          });
        },
      }
    );

  const { data: pickupByIdQuery, loading: pickupByIdLoad } = useQuery(
    gql`
      ${PICKUP.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      skip: !pickupId,
      variables: {
        id: parseInt(pickupId),
      },
      onCompleted: (data) => {
        if (data?.warehousePickup === null) return;
        const pickup = data?.warehousePickup;

        setUnEditable(!pickup.editable);

        setPickupData(pickup);
        setValue("id", parseInt(pickup?.id));
        setValue("code", pickup?.code);
        setValue("date", pickup?.date);
        pickup?.timeFrom && setValue("timeFrom", pickup?.timeFrom);
        pickup?.timeTo && setValue("timeTo", pickup?.timeTo);
        pickup?.notes && setValue("notes", pickup?.notes);
        pickup?.shipmentsCount &&
          setValue("shipmentsCount", pickup?.shipmentsCount);
        setDate((prev) => ({
          ...prev,
          date: pickup?.date,
          ...(pickup?.timeFrom && {
            timeFrom: stringFormatToDate(pickup?.timeFrom),
          }),
          ...(pickup?.timeTo && { timeTo: stringFormatToDate(pickup?.timeTo) }),
        }));

        setAutocompleteValues({
          branch: pickup.branch,
          customer: pickup?.customer,
          deliveryAgent: pickup?.deliveryAgent,
          transactionType: pickup.transactionType,
        });

        // pickedShipment["shipments"] = data.pickup.shipments;
      },
    }
  );

  ////////////////////END DRAWER////////////////

  /////////////// Start Barcode /////////////////

  const [barcodeDarwerState, setBarcodeDarwerState] = useState({
    left: false,
    bottom: false,
    right: false,
  });
  const barcodeDrawerHandler = (anchor, open) => {
    setBarcodeDarwerState((prev) => ({
      ...prev,
      [anchor]: open,
    }));
  };

  /////////////// End Barcode /////////////////

  const canChooseTime = Globals.user.hasPermission(
    "shipping.warehouse_pickup.choose_time"
  );

  const history = useHistory();
  const handelTime = (data, name) => {
    if (data[name]) {
      data[name] =
        typeof data[name] === "string" ? data[name] : timeFormat(data[name]);
    }
  };

  const onSubmit = (data) => {
    let products = (pickupProducts).map((ele) => {
      const id = ele.product.id,
        quantity = ele.quantity;
      return { productId: id, quantity: quantity }
    });
    data["pickupProducts"] = products;

    for (const key in data) {
      if (data[key] === "") {
        data[key] = null;
      }
      if (!canChooseTime) {
        delete data["timeFrom"]
        delete data["timeTo"]
      }
    }


    handelTime(data, "timeFrom");
    handelTime(data, "timeTo");
    data["date"] = dateFormat(data["date"]);

    if (data["shipmentsCount"])
      data["shipmentsCount"] = parseInt(data["shipmentsCount"]);

    savePickupMutation({
      variables: {
        input: {
          ...data,
        },
      },
    })
      .then((data) => {
        setValue("code", data?.data?.saveWarehousePickup?.code);
        setValue("id", parseInt(data?.data?.saveWarehousePickup?.id));
        const url = history.createHref({
          pathname: `/admin/warehouse-pickups/${data?.data?.saveWarehousePickup?.id}/edit`,
        });
        windowReplaceUrl(url);
        enqueueSnackbar(t("saveSuccessful"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      })
      .catch(({ graphQLErrors }) => {
        setValidationError(graphQLErrors, setError);
        console.log(graphQLErrors);
      });
  };
  const handelPickupData = () => {
    let pickupDetails = watch();
    let products = (pickupProducts).map((ele) => {
      const id = ele.product.id,
        quantity = ele.quantity;
      return { productId: id, quantity: quantity }
    });
    pickupDetails["pickupProducts"] = products;
    handelTime(pickupDetails, "timeFrom");
    handelTime(pickupDetails, "timeTo");

    pickupDetails["date"] = dateFormat(pickupDetails["date"]);
    for (const key in pickupDetails) {
      if (pickupDetails[key] === "") {
        delete pickupDetails[key];
      }
    }
    if (pickupDetails["shipmentsCount"])
      pickupDetails["shipmentsCount"] = parseInt(
        pickupDetails["shipmentsCount"]
      );

    return pickupDetails;
  };

  const updateShipments = (newShipments, remove) => {
    const pickupDetails = handelPickupData();
    let handledShipments = [];
    if (remove) {
      pickupProducts
        .filter((i) => i.product.id !== newShipments.product.id)
        .map((i) =>
          handledShipments.push({ productId: i.product.id, quantity: i.quantity, })
        );
    } else {
      let updatedShipments = newShipments.map((i) => ({
        productId: i.product.id,
        quantity: i.quantity,
        ...(i?.warehouseSection?.id && {
          sectionId: i?.warehouseSection?.id,
        }),
      }));
      handledShipments = [...updatedShipments];
    }

    savePickupMutation({
      variables: {
        input: {
          ...pickupDetails,
          pickupProducts: handledShipments,
        },
      },
    })
      .then((data) => {
        !(data.data) && window.location.reload(true);
        setValue("code", data?.data?.saveWarehousePickup?.code);
        setValue("id", parseInt(data?.data?.saveWarehousePickup?.id));
        const url = history.createHref({
          pathname: data.data ? `/admin/warehouse-pickups/${data?.data?.saveWarehousePickup?.id}/edit` :
            `/admin/warehouse-pickups/${pickupId}/edit`,
        });
        windowReplaceUrl(url);
        enqueueSnackbar(t("saveSuccessful"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      })
      .catch(({ graphQLErrors }) => {
        setValidationError(graphQLErrors, setError);
        console.log(graphQLErrors);
      });
  };

  const closeCancelPickupDialog = () => {
    setCancelPickupDialog(false);
  };
  const openCancelPickupDialog = () => {
    setCancelPickupDialog(true);
  };
  const closeReceivedInStoreDialog = () => {
    setReceivedInStoreDialog(false);
  };
  const openReceivedInStoreDialog = () => {
    setReceivedInStoreDialog(true);
  };

  const updatePickupStatus = (status) => {
    let products = (pickupProducts).map((ele) => {
      const id = ele.product.id,
        sectionId = watch(`sectionId-${ele.product.id}`);
      return { productId: id, sectionId: sectionId }
    });
    updatePickupStatusMutation({
      variables: {
        input: {
          id: parseInt(watch("id")),
          statusCode: status !== "CXL" ? "DON" : "CXL",
          notes: watch("cancelPickupReasone"),
          ...(status !== "CXL" && { products: products })
        },
      },
    })
      .then((data) => {
        setPickupData(data.data.updateWarehousePickupStatus);
        closeCancelPickupDialog();
        status !== "CXL" && pushUrl(props, `/admin/warehouse-pickups/${watch("id")}`);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const informDeliveryAgent = (status) => {
    setInfoInProgress(true);
    const pickupDetails = handelPickupData();

    savePickupMutation({
      variables: {
        input: {
          ...pickupDetails,
        },
      },
    })
      .then((data) => {
        updatePickupStatusMutation({
          variables: {
            input: {
              id: parseInt(watch("id")),
              statusCode: "WRK",
            },
          },
        })
          .then((data) => {
            setPickupData(data.data.updateWarehousePickupStatus);
          })
          .catch((error) => {
            setInfoInProgress(false);

            console.log(error);
          });
      })
      .catch(({ graphQLErrors }) => {
        setInfoInProgress(false);
        setValidationError(graphQLErrors, setError);
        console.log(graphQLErrors);
      });
  };

  const parseData = (data) => {
    return data;
  };

  const onCustomerComplete = (data) => {
    const currentValue = watch("customerId");

    const isValueNotValid = !selectedIdIsValid(data, currentValue);
    if (isValueNotValid && currentValue) {
      setValue("customerId", "");
      setPickupData({ pickupProducts: [] })
      setAutocompleteValues((prev) => ({
        ...prev,
        customer: null,
      }));
    }
  };

  const lookupsComplete = (data, updateValue, name) => {
    if (updateValue && lookupCodeIsValid(data, updateValue)) {
      setValue(name, updateValue);
    }
  };

  const addShipmentToManifest = (data) => {
    const seen = new Set();
    const duplicateId = []
    const products = [...data, ...pickupProducts];
    const filteredProduct = products.filter(el => {
      const duplicate = seen.has(el.product.id);
      if (duplicate) {
        duplicateId.push(el.product.id)
      }
      seen.add(el.product.id);
      return !duplicate;
    });
    setPickupData((prev) => ({
      ...prev,
      pickupProducts: [...filteredProduct],
    }));
    updateShipments(filteredProduct);
  };

  let formBody = null;
  let tableBody = null;
  /////////////////////// Customer Shipments Table /////////////////////////////

  const customerTableHead = [
    "code",
    "name",
    "quantity",
    "",
  ];

  const customerTableBody = (product, index) => {
    return (
      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
        <CellLink pathname={`/admin/products/${product.product.id}`}>
          {product?.product.code}
        </CellLink>
        <FixedTableCell>{product?.product.name}</FixedTableCell>
        <FixedTableCell>{product?.quantity}</FixedTableCell>
        <FixedTableCell component="th" scope="row">
          <IconButton
            size="small"
            disabled={savePickupLoad || pickupStatus === "DON" || unEditable}
            onClick={() => {
              pickupProducts.forEach((i) => {
                if (i.product.id === product.product.id) {
                  i["pendingDelete"] = true;
                }
              });
              setPickupData((prev) => ({
                ...prev,
                products: pickupProducts,
              }));
              updateShipments(product, true);
              // fireMutaion(filtered)
            }}
          >
            {product["pendingDelete"] ? (
              <CustomSpinner name="ClockLoader" size={20} />
            ) : (
              <Delete />
            )}
          </IconButton>
        </FixedTableCell>
      </TableRow>
    );
  };

  const total = {
    quantity: 0,
  };

  const sumTableNumber = (shipment, tableTotal) => {
    Object.entries(tableTotal).forEach(([k, v]) => {
      tableTotal[k] += shipment[k] ?? 0;
    });
  };

  tableBody = (pickupStatus !== "CXL") && (
    <Grid container xs={12} justifyContent="center" sx={{ width: "100%" }}>
      {/* *******Table******* */}
      <Paper className={clsx(classes.table, classes.paper)}>
        <EnhancedTableToolbar
          {...props}
          classes={classes}
          addShipment={() => productDrawerHandler(anchor, true)}
          barcode={() => barcodeDrawerHandler(anchor, true)}
          disabledAddIcon={pickupStatus === "CXL" || pickupStatus === "DON" || unEditable}
          selectedProduct={pickupProducts && pickupProducts}
        />

        <TableFixedHeaderWraper>
          <ProductsTable
            page={page}
            rowsPerPage={rowsPerPage}
            data={pickupProducts.sort((a, b) => b.id - a.id)}
            headCells={customerTableHead}
            parseBodyCell={customerTableBody}
            total={total}
            sumTableNumber={sumTableNumber}
          />
        </TableFixedHeaderWraper>
        {pickupProducts?.length > 4 && (
          <MUITablePagination
            count={pickupProducts?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
        {(!customerId) && (
          <Box className={classes.overlayPoduct}>
            <Typography color="textPrimary" variant="body1">
              {t("chooseCustomerRequired")}
            </Typography>
          </Box>
        )}
        <Grid container justifyContent="center" alignItems="center">
          {customerId && pickupProducts?.length === 0 && (
            <Box textAlign="center" marginTop="50px" marginBottom="50px">
              <img width="50%" src={emptyList} alt={"delivery box"} />
              <Box
                component={Typography}
                variant="h6"
                color="text.secondary"
                marginTop="0"
              >
                {t("noProducts")}
              </Box>
            </Box>
          )}
        </Grid>
      </Paper>
    </Grid>
  );

  const statusButton = (status) => {
    const statusCode = status === "NEW" ? "WRK" : "DON";
    const disabled = pickupProducts?.length === 0;
    const buttonCondition = status && status !== "CXL" && status !== "DON";
    const informDeliveryAgentLoading = infoInProgress && savePickupLoad;
    return (
      buttonCondition &&
      pickupData["deliveryAgent"] && (
        <CustomButton
          className={classes.button}
          disabled={disabled || updateStatusLoad || informDeliveryAgentLoading}
          variant="contained"
          size="medium"
          color="primary"
          onClick={() =>
            status === "NEW"
              ? informDeliveryAgent()
              : openReceivedInStoreDialog()
          }
          name="updateStatus"
          customcolor={theme.palette.info.main}
          loading={updateStatusLoad || informDeliveryAgentLoading}
          startIcon={
            !updateStatusLoad && pickupProducts?.length > 0 && <DoneAll />
          }
        >
          {status === "NEW" ? t("informDeliveryAgent") : t("receivedInStore")}
        </CustomButton>
      )
    );
  };

  const screenWidth = useWidth();

  const anchor = screenWidth === "xs" ? "bottom" : "right";
  const shipmentDrawerHandler = (open) => {
    setDrawerState((prev) => ({
      ...prev,
      [anchor]: open,
    }));
  };
  const formCondition = pickupId ? !pickupByIdLoad : true;
  formBody = (
    <Grid
      container
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      spacing={2}
      sx={{ padding: "24px", width: "100%", margin: "0" }}
    >
      {formCondition ? (
        <Paper container component={Grid} sx={{ padding: 1, flexGrow: 1 }}>
          <Grid container sm={12} xs={12} justifyContent="space-between">
            <Typography color="inherit" variant="h6" component="div">
              {t("createPickupRequest")}
            </Typography>
          </Grid>

          <Grid container sx={{ position: "relative" }} sm={12} xs={12}>
            {pickupStatus && pickupStatus !== "NEW" && (
              <Box
                className={classes.overlay}
                sx={{ backgroundColor: "rgb(255 255 255 / 45%)" }}
              ></Box>
            )}

            <Grid xs={12} sm={6} md={4}>
              <ControlMUItextField
                name="code"
                control={control}
                label={t("recordCode")}
                rules={{ required: watch("id") ? t("fieldIsRequired") : false }}

              // defaultValue={queryVariables?.pickup?.code}
              // disabled={pickupByIdQuery?.pickup?.approved || disableForm}
              />
            </Grid>
            <Grid xs={12} sm={6} md={4}>
              <MUIDateTime
                name="date"
                label={t("date")}
                control={control}
                value={date.date}
                onChange={(e) => setDate((prev) => ({ ...prev, date: e }))}
              />
            </Grid>

            <Can showException permission="shipping.warehouse_pickup.choose_branch">
              <Grid xs={12} sm={6} md={4}>
                <ListBranches
                  control={control}
                  errors={errors}
                  name={"branchId"}
                  rules={{ required: t("fieldIsRequired") }}
                  defaultValue={autocompleteValues.branch}
                  onChangeValue={(e) => {
                    setValue("deliveryAgentId", "");
                    // setValue("customerId", "");
                    // setPickedShipment({ shipments: [] })
                  }}
                  skipDefaultBranch={Boolean(pickupId)}
                />
              </Grid>
            </Can>
            <Can
              showException
              permission="shipping.warehouse_pickup.choose_transaction_type"
            >
              <Grid xs={12} sm={6} md={4}>
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  selectFirst={true}
                  name={"transactionTypeId"}
                  label={t("transactionType")}
                  rules={{ required: t("fieldIsRequired") }}
                  parseData={(data) => parseData(data)}
                  query={LIST_TRANSACTION_TYPES_DROPDOWN.query}
                  variables={{
                    input: {
                      type: "PRQST",
                    },
                  }}
                  defaultValue={autocompleteValues.transactionType}
                />
              </Grid>
            </Can>
            <Can showException permission="shipping.warehouse_pickup.choose_branch">
              <Grid xs={12} sm={6} md={4}>
                <CustomAutocomplete
                  control={control}
                  name={"customerId"}
                  label={t("customer")}
                  rules={{ required: t("fieldIsRequired") }}
                  parseData={(data) => parseData(data)}
                  query={LIST_CUSTOMERS_DROPDOWN.query}
                  onCompleted={onCustomerComplete}
                  disabled={!watch("branchId")}
                  skip={!watch("branchId")}
                  onChangeValue={(e) => {
                    setPickupData({ pickupProducts: [] })
                  }}
                  variables={{
                    input: {
                      branchId: {
                        value: watch("branchId"),
                        includeNull: true,
                      },
                      active: true,
                    },
                  }}
                  defaultValue={autocompleteValues.customer}
                />
              </Grid>
            </Can>
            <Can
              showException
              permission="shipping.warehouse_pickup.choose_delivery_agent"
            >
              <Grid xs={12} sm={6} md={4}>
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  name={"deliveryAgentId"}
                  label={t("shippingAgent")}
                  // rules={{ required: t("fieldIsRequired") }}
                  disabled={!watch("branchId")}
                  onChangeValue={(e) => {
                    if (e?.id !== "") {
                      clearErrors("deliveryAgentId");
                    }
                  }}
                  parseData={(data) => parseData(data)}
                  query={LIST_DELIVERY_AGENTS_DROPDOWN.query}
                  skip={!watch("branchId")}
                  variables={{
                    input: {
                      active: true,
                      branchId: {
                        value: watch("branchId"),
                        includeNull: true,
                      },
                    },
                  }}
                  defaultValue={autocompleteValues.deliveryAgent}
                />
              </Grid>
            </Can>
            <Grid xs={12} sm={6} md={4}>
              <LookupDropdown
                control={control}
                errors={errors}
                name={"vehicleCode"}
                label={t("vehicle")}
                variables={{ input: { code: "SHP_VEHICLES_TYPE" } }}
                onCompleted={(data) =>
                  lookupsComplete(
                    data,
                    pickupByIdQuery?.warehousePickup?.vehicle?.code,
                    "vehicleCode"
                  )
                }
              />
            </Grid>
            <Can showException permission="shipping.warehouse_pickup.choose_time">
              <Grid xs={12} sm={6} md={4}>
                <MUITimePicker
                  rules={{
                    validate: {
                      max: (value) =>
                        value > stringFormatToDate(watch("timeTo"))
                          ? t("validation:lt", {
                            field1: t("fromHour"),
                            field2: t("toHour"),
                          })
                          : true,
                    },
                  }}
                  InputProps={{
                    ...(date.timeFrom && {
                      endAdornment: (
                        <IconButton
                          size="small"
                          key={"timeFrom"}
                          onClick={() => {
                            setDate((prev) => ({ ...prev, timeFrom: null }));
                            setValue("timeFrom", "");
                          }}
                        >
                          <Clear size="small" />
                        </IconButton>
                      ),
                    }),
                  }}
                  name="timeFrom"
                  label={t("fromHour")}
                  control={control}
                  value={date.timeFrom}
                  onChange={(e) =>
                    setDate((prev) => ({ ...prev, timeFrom: e }))
                  }
                />
              </Grid>
            </Can>
            <Can showException permission="shipping.warehouse_pickup.choose_time">
              <Grid xs={12} sm={6} md={4}>
                <MUITimePicker
                  rules={{
                    validate: {
                      max: (value) =>
                        value < stringFormatToDate(watch("timeFrom"))
                          ? t("validation:mt", {
                            field1: t("toHour"),
                            field2: t("fromHour"),
                          })
                          : true,
                    },
                  }}
                  InputProps={{
                    ...(date.timeTo && {
                      endAdornment: (
                        <IconButton
                          size="small"
                          key={"timeTo"}
                          onClick={() => {
                            setDate((prev) => ({ ...prev, timeTo: null }));
                            setValue("timeTo", "");
                          }}
                        >
                          <Clear size="small" />
                        </IconButton>
                      ),
                    }),
                  }}
                  name="timeTo"
                  label={t("toHour")}
                  control={control}
                  value={date.timeTo}
                  onChange={(e) => setDate((prev) => ({ ...prev, timeTo: e }))}
                />
              </Grid>
            </Can>
            <Grid xs={12} sm={6} md={4}>
              <MUItextField
                name="notes"
                errors={errors}
                label={t("notes")}
                register={register}
                formType="requireFalse"
              />
            </Grid>
          </Grid>

          <Grid sm={12} xs={12} container justifyContent="flex-end">
            {(!pickupStatus || pickupStatus === "NEW") && !infoInProgress && (
              <CustomButton
                customcolor={theme.palette.success.main}
                type="submit"
                className={classes.button}
                variant="contained"
                size="medium"
                loading={savePickupLoad}
                // className={classes.button}
                disabled={savePickupLoad}
                startIcon={!savePickupLoad && <Done />}
              >
                {!savePickupLoad && t("save")}
              </CustomButton>
            )}
            {updatePickup && statusButton(pickupStatus)}
            {pickupStatus === "WRK" && (
              <CustomButton
                className={classes.button}
                customcolor={theme.palette.error.main}
                variant="contained"
                size="medium"
                color="inherit"
                onClick={openCancelPickupDialog}
                name="cancel"
                disabled={updateStatusLoad}
                loading={updateStatusLoad}
                startIcon={!updateStatusLoad && <CancelOutlined />}
              >
                {t("cancel")}
              </CustomButton>
            )}
          </Grid>
        </Paper>
      ) : (
        <FullScreenLoading />
      )}
    </Grid>
  );

  const cancelPickup = (
    <Box component="form" onSubmit={handleSubmit(() => { })}>
      <Typography>{t("cancelPickupMessage")}</Typography>
      <ControlMUItextField
        name="cancelPickupReasone"
        control={control}
        label={t("notes")}
        margin="normal"
        onChange={() => clearErrors("cancelPickupReasone")}
      />
    </Box>
  );

  const receivedInStore = (
    <Grid
      container
      item
      justifyContent="flex-start"
      alignContent="space-between"
      spacing={2}
    >
      {pickupProducts.map((product, index) => (
        <Grid container key={index} item sm={12} xs={12} alignItems="center" justifyContent="space-between">
          <Grid xs={12} md={2} alignItems="center">
            <Box component="span"
              fontWeight="bold">
              <SpanLink pathname={`/admin/products/${product?.product?.id}`}>
                {product?.product?.code}
              </SpanLink>
            </Box>
          </Grid>
          <KeyValuePair
            md
            value={product?.product?.name}
          />
          <Grid xs={12} md={4}>
            <CustomAutocomplete
              control={control}
              errors={errors}
              name={`sectionId-${product?.product?.id}`}
              label={t("wareHouse")}
              query={LIST_WAREHOUSE_DROPDOWN.query}
              rules={{
                required: t("fieldIsRequired"),
              }}
              parseData={(data) => data}
              variables={{
                input: {
                  branchId: watch("branchId"),
                },
              }}
            />
          </Grid>
        </Grid>
      ))}
    </Grid >
  );
  const [state, setState] = useState({
    productList: {
      left: false,
      bottom: false,
      right: false,
    },
    manifestList: {
      left: false,
      bottom: false,
      right: false,
    },
  });


  const productDrawerHandler = (anchor, open) => {
    setState((prev) => ({
      ...prev,
      productList: { ...prev.productList, [anchor]: open },
    }));
  };

  const viewIconEdit = pickupStatus === 'DON' || pickupStatus === 'CXL';

  return pickupByIdQuery?.pickup === null ? (
    <NotFound />
  ) : unEditable && (!user.hasPermission("shipping.warehouse_pickup.update_status") || viewIconEdit) ? (
    <Can permission="uneEditable" />
  ) : (
    <Root>
      {props.children}
      <SwipeableDrawer
        variant="persistent"
        disableDiscovery={true}
        disableSwipeToOpen={true}
        anchor={anchor}
        open={drawerState[anchor]}
        onClose={() => false}
        onOpen={() => shipmentDrawerHandler(anchor, true)}
        classes={{ paper: classes.shipmentList }}
        dir={theme.direction}
      >
        {drawerState[anchor] && (
          <ProductList
            headCell={customerTableHead}
            bodyRow={customerTableBody}
            pickupShipments={pickupProducts}
            done={() => shipmentDrawerHandler(false)}
            setSelected={addShipmentToManifest}
            variables={watch()}
          />
        )}
      </SwipeableDrawer>

      <SwipeableDrawer
        disableDiscovery={true}
        disableSwipeToOpen={true}
        anchor={anchor}
        open={barcodeDarwerState[anchor]}
        onClose={() => false}
        onOpen={() => barcodeDrawerHandler(anchor, true)}
        sx={{
          width: "auto",
          "& .MuiDrawer-paper": {
            width: { md: "50%" },

            height: "100%",
            overflow: "hidden",
          },
        }}
        dir={theme.direction}
      >
        <BarcodeSelector
          setSelected={addShipmentToManifest}
          done={() => barcodeDrawerHandler(anchor, false)}
          variables={watch()}
        />
      </SwipeableDrawer>
      <Dialog
        anchor={anchor}
        fullWidth
        maxWidth="md"
        open={state.productList[anchor]}
        onClose={() => productDrawerHandler(anchor, false)}
        className={classes.dialog}
      >
        <DialogContent style={{ overflow: "hidden", padding: 0 }}>
          <ProductList
            headCell={customerTableHead}
            bodyRow={customerTableBody}
            pickupShipments={pickupProducts}
            done={() => productDrawerHandler(anchor, false)}
            setSelected={addShipmentToManifest}
            variables={watch()}
            id={customerId}
          />
        </DialogContent>

      </Dialog>
      <CustomDialog
        open={CancelPickupDialog}
        title={t("cancelPickup")}
        content={cancelPickup}
        fullWidth
        maxWidth="xs"
        actions={
          <>
            <Button color="primary" onClick={closeCancelPickupDialog}>
              {t("cancel")}
            </Button>
            <Button
              color="primary"
              disabled={updateStatusLoad}
              type="submit"
              onClick={() => {
                if (watch("cancelPickupReasone")) {
                  updatePickupStatus("CXL");
                } else {
                  setError("cancelPickupReasone", {
                    type: "required",
                    message: t("fieldIsRequired"),
                  });
                }
              }}
            >
              {updateStatusLoad ? <ButtonLoading /> : t("confirm")}
            </Button>
          </>
        }
      />
      {/* <CustomDialog
        open={receivedInStoreDialog}
        title={t("receivedInStore")}
        content={receivedInStore}
        fullWidth
        maxWidth="sm"
      actions={
        <>
          <Button color="primary" onClick={closeReceivedInStoreDialog}>
            {t("cancel")}
          </Button>
          <Button
            color="primary"
            disabled={updateStatusLoad || inStoreCheck}
            type="submit"
            onClick={() => {
              if (watch("cancelPickupReasone")) {
                updatePickupStatus("CXL");
              } else {
                setError("cancelPickupReasone", {
                  type: "required",
                  message: t("fieldIsRequired"),
                });
              }
            }}
          >
            {updateStatusLoad ? <ButtonLoading /> : t("confirm")}
          </Button>
        </>
      }
      /> */}
      <Dialog
        maxWidth={"sm"}
        fullWidth={true}
        open={receivedInStoreDialog}
        onClose={closeReceivedInStoreDialog}
        component={"form"}
        onSubmit={handleSubmit(updatePickupStatus)}
      >
        <DialogTitle>{t("receivedInStore")}</DialogTitle>
        <DialogContent>
          {receivedInStore}
        </DialogContent>
        <DialogActions>
          <Grid container item sm={12} justifyContent="flex-end">
            <Button
              className={classes.button}
              color={updateStatusLoad ? "inherit" : "primary"}
              variant="contained"
              type="submit"
              disabled={updateStatusLoad}
            >
              {updateStatusLoad ? <ButtonLoading /> : t("save")}
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>

      {formBody}

      <Grid
        container
        spacing={3}
        justifyContent="center"
        className={classes.mainTable}
      >
        {tableBody}
      </Grid>
    </Root>
  );
};

export default withRouter(WarehousePickupForm);
