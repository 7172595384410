import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect } from "react";

import { gql, useQuery } from "@apollo/client";
import clsx from "clsx";
import moment from "moment";
import { useTranslation } from "react-i18next";
import logo from "../../assets/Image/logo.png";
import NotFound from "../../Error/NotFound";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";

import * as gqlb from "gql-query-builder";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import { urlParameters } from "../HOC/CustomFunctions/urlParameters";
import "../ManifestPrint/print.css";
import { Globals } from "../HOC/Classes/Globals";
import { useRef } from "react";
import { useState } from "react";
import useLocalCurrency from "../../Hooks/LocalCurrency";

const PREFIX = "AccountStatementPrint";

const classes = {
    headerCells: `${PREFIX}-headerCells`,
    title: `${PREFIX}-title`,
    notes: `${PREFIX}-notes`,
    noteCell: `${PREFIX}-noteCell`,
    barcodeFont: `${PREFIX}-barcodeFont`,
    cairoFont: `${PREFIX}-cairoFont`,
    barcode: `${PREFIX}-barcode`,
    headerTableCell: `${PREFIX}-headerTableCell`,
    mainCellBorder: `${PREFIX}-mainCellBorder`,
    signature: `${PREFIX}-signature`,
    reportHeader: `${PREFIX}-reportHeader`,
    hiddenFooter: `${PREFIX}-hiddenFooter`,
    pageFooter: `${PREFIX}-pageFooter`,
    logoSection: `${PREFIX}-logoSection`,
    headerDate: `${PREFIX}-headerDate`,
    whiteSpace: `${PREFIX}-whiteSpace`,
    approve: `${PREFIX}-approve`,
    rowWithoutBorder: `${PREFIX}-rowWithoutBorder`,
    mainDetailsCell: `${PREFIX}-mainDetailsCell`,
    tableHeadCell: `${PREFIX}-tableHeadCell`,
    tableWidth: `${PREFIX}-tableWidth`,
    shipmentsTable: `${PREFIX}-shipmentsTable`,
    signatureBlock: `${PREFIX}-signatureBlock`,
};

const Root = styled("div")(({ theme }) => ({
    fontFamily: ['"Cairo"', "sans-serif"].join(","),

    [`& .${classes.headerCells}`]: {
        paddingBottom: theme.spacing(1),
    },

    [`& .${classes.title}`]: {
        color: theme.palette.info.main,
        textTransform: "capitalize"
        // whiteS;pace: "break-spaces",
    },

    [`& .${classes.notes}`]: {
        padding: theme.spacing(1),
        border: "1px solid " + theme.palette.action.disabled,
        wordBreak: "break-all",
        width: "100%",
        minHeight: 80,
    },

    [`& .${classes.noteCell}`]: {
        width: "30%",
        overflow: "hidden",
    },

    [`& .${classes.barcodeFont}`]: {
        fontFamily: '"Libre Barcode 39 Text"',
    },

    [`& .${classes.cairoFont}`]: {
        fontFamily: ['"Cairo"', "sans-serif"].join(","),
    },

    [`& .${classes.barcode}`]: {
        fontSize: 40,
        textAlign: "center",
    },

    [`& .${classes.headerTableCell}`]: {
        border: "none",
        padding: 0,
    },

    [`& .${classes.mainCellBorder}`]: {
        border: "none",
        padding: 0,
    },

    [`& .${classes.signature}`]: {
        textAlign: "center",
        padding: theme.spacing(2, 1, 1, 1),
        border: "none",
        textTransform: "capitalize"
    },

    [`& .${classes.reportHeader}`]: {
        whiteSpace: "pre-line",
        padding: theme.spacing(0, 2),
        lineHeight: "120%",
        fontSize: 12,
    },

    [`& .${classes.hiddenFooter}`]: {
        visibility: "hidden",
    },

    [`& .${classes.pageFooter}`]: {
        //   "&::after": {
        //    " content:  counter(page)  of  counter(pages)"
        //   },
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        borderTop: "1px solid " + theme.palette.text.secondary,
        display: "flex",
        alignItems: "flex-end",
    },

    [`& .${classes.logoSection}`]: {
        display: "flex",
        alignItems: "center",
    },

    [`& .${classes.headerDate}`]: {
        textAlign: "end",
    },

    [`& .${classes.whiteSpace}`]: {
        "& tr": {
            "& td": {
                whiteSpace: "initial",
            },
        },
    },

    [`& .${classes.approve}`]: {
        textAlign: "end",
    },

    [`& .${classes.rowWithoutBorder}`]: {
        "& td": {
            border: "none",
            padding: 0,
        },
    },

    [`& .${classes.mainDetailsCell}`]: {
        "& td": { border: "none", verticalAlign: "top" },
        "& td:first-of-type": { paddingLeft: 0 },
        "& td:last-of-type": { paddingRight: 0 },

        border: "none",
        padding: theme.spacing(1, 0),
    },

    [`& .${classes.tableHeadCell}`]: {
        padding: 0,
    },

    [`& .${classes.tableWidth}`]: {
        width: "100vw",
    },

    [`& .${classes.shipmentsTable}`]: {
        "& tr": {
            "&:hover": {
                backgroundColor: "#ffffff00 !important",
            },
        },
        "& td , & th": {
            padding: theme.spacing(1),
        },
    },

    [`& .${classes.signatureBlock}`]: {
        breakInside: "avoid",
    },
}));

export { };

const org = {
    operation: "organization",
    fields: [
        "name",
        "phone",
        "email",
        "website",
        "reportHeader",
        "reportFooter",
        "registrationNumber",
        "taxNumber",
    ],
    variables: {},
};
const glAccount = {
    operation: "glAccount",
    fields: [
        "name",
        "code",
        {
            branch: ["name"]
        },
        {
            operation: "startBalance:balance",
            fields: [],
            variables: {
                startBalanceDate: { name: "date", type: "Date", required: true },
            },
        },
        {
            operation: "endBalance:balance",
            fields: [],
            variables: {
                endBalanceDate: { name: "date", type: "Date", required: true },
                endDayEdge: {
                    name: "dayEdge",
                    type: "DayStartEnd",
                },
            },
        },
    ],
    variables: {
        glAccountId: {
            name: "id",
            type: "Int",
            required: true,
        },
    },
};

const JournalEntryRecordssum = {
    operation: "sumJournalEntryRecords",
    fields: [
        "credit", "debit"
    ],
    variables: {
        input: {
            type: "ListJournalEntryRecordsInput",
        },
    },
};
const listJournalEntryRecords = [
    {
        data: [
            "id",
            "debit",
            "credit",
            "description",
            { entry: ["id", "date", "code"] },
        ],
    },
];

const PAGE_COUNT = gqlb.query({
    operation: "listJournalEntryRecords",
    fields: [{ paginatorInfo: ["lastPage"] }],
    variables: {
        input: {
            type: "ListJournalEntryRecordsInput",
            required: true,
        },
        first: {
            type: "Int",
        },
    },
});

const AccountStatementPrint = (props) => {
    const urlQuery = urlParameters(window.location.search);
    const userGlAccountId = urlQuery?.["glAccountId"];
    const customer = urlQuery?.["customer"];

    for (const key in urlQuery) {
        if(customer && key === "glAccountId"){
            delete urlQuery[key];
        }
        if (key === "customer") {
            delete urlQuery[key];
        }
    }

    const { data: pageCount, loading: pageLoad } = useQuery(
        gql`
            ${PAGE_COUNT.query}
        `,
        {
            first: 100,
            variables: {
                input: urlQuery,
                first: 100,
            },
        }
    );
    const pages = pageCount?.listJournalEntryRecords?.paginatorInfo?.lastPage;

    const initial = useRef(0);
    const totalDebit = useRef(0);
    const totalCredit = useRef(0);
    const [tableData, setTableData] = useState([]);

    const queryBuilder = () => {
        const queryNumber = Math.ceil(pages);
        const queryArray = [org, JournalEntryRecordssum, glAccount];
        for (let index = 0; index < queryNumber; index++) {
            const initialQuery = {
                operation: `listJournalEntryRecords${index}:listJournalEntryRecords`,
                fields: listJournalEntryRecords,
                variables: {
                    input: {
                        type: "ListJournalEntryRecordsInput",
                    },
                    first: {
                        type: "Int",
                    },
                    ["page" + index]: {
                        type: "Int",
                        name: "page",
                    },
                },
            };
            queryArray.push(initialQuery);
        }
        return queryArray;
    };

    const queryBody = queryBuilder();
    const INVOICES_PRINT = gqlb.query(queryBody);

    const { t } = useTranslation();

    let variables = {
        input: {
            fromDate: urlQuery?.["fromDate"],
            toDate: urlQuery?.["toDate"],
            ...(!customer && {glAccountId: urlQuery?.["glAccountId"]}),
            approved: true,
        },
        glAccountId: userGlAccountId,
        startBalanceDate: urlQuery?.["fromDate"],
        endBalanceDate: urlQuery?.["toDate"],
        endDayEdge: "END_OF_DAY",
        first: 100,
    };
    for (let index = 0; index < pages; index++) {
        variables["page" + index] = index + 1;
    }


    const { data: queryData, loading } = useQuery(
        gql`
            ${INVOICES_PRINT.query}
        `,
        {
            fetchPolicy: "no-cache",
            nextFetchPolicy: "no-cache",
            skip: !pages,
            variables,
            onError: () => {
                return;
            },
        }
    );

    const reportDetails =
        queryData?.listJournalEntryRecords?.data !== null
            ? queryData?.listJournalEntryRecords?.data
            : null;

    let parsedData = [];
    if (queryData?.listJournalEntryRecords0) {
        let concatData = [];
        for (const key in queryData) {
            if (key.startsWith("list")) {
                const listData = queryData[key].data;
                concatData = concatData.concat(listData);
            }
        }
        parsedData = concatData;
    }

    useEffect(() => {
        if (parsedData.length > 0) {
            if (initial.current === 0)
                initial.current = queryData?.glAccount?.startBalance;

            const records = parsedData.map((row) => {
                const journal = { ...row };
                totalCredit.current = totalCredit.current + journal.credit;
                totalDebit.current = totalDebit.current + journal.debit;
                const balance = journal.credit - journal.debit + initial.current;
                if (journal.credit !== 0) {
                    initial.current = journal.credit + initial.current;
                } else if (journal.debit !== 0) {
                    initial.current = -journal.debit + initial.current;
                } else if (balance === 0) {
                    initial.current = 0;
                }
                if (balance <= 0) {
                    journal["totalDebit"] = Math.abs(balance);
                    journal["totalCredit"] = 0;
                } else if (balance >= 0) {
                    journal["totalCredit"] = Math.abs(balance);
                    journal["totalDebit"] = 0;
                }
                return journal;
            });
            let allRecords = [...tableData, ...records].reduce((acc, current) => {
                const x = acc.find((item) => item.id === current.id);
                if (!x) {
                    return acc.concat([current]);
                } else {
                    return acc;
                }
            }, []);

            setTableData(allRecords);
        }
        return () => { };
    }, [queryData]);

    useEffect(() => {
        if (parsedData.length > 0) {
            window.print();
        }
        return () => { };
    }, [tableData]);

    const { currency } = useLocalCurrency();
    const header = !loading && !pageLoad && queryData !== null && (
        <Table>
            <TableBody>
                <TableRow>
                    <TableCell padding="none">
                        <Table>
                            <TableBody>
                                <TableRow className={classes.rowWithoutBorder}>
                                    <TableCell>
                                        <Box className={classes.logoSection}>
                                            <img src={logo} alt="logo" height={30} />
                                            <Typography
                                                sx={{
                                                    whiteSpace: "pre-line !important",
                                                    paddingLeft: '10px',
                                                    lineHeight: "120%",
                                                    fontSize: 12,
                                                }}
                                                className={clsx(classes.reportHeader, "landscape-zoom")}
                                            >
                                                {queryData?.organization?.reportHeader}
                                            </Typography>
                                        </Box>
                                    </TableCell>

                                    <TableCell className={clsx(classes.headerDate)}>
                                        <Typography variant="h6">
                                            {moment(new Date()).locale("en").format("ll")}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableCell>
                </TableRow>

                <TableRow>
                    <TableCell className={classes.headerTableCell}>
                        <Table className={classes.whiteSpace}>
                            <TableBody>
                                <TableRow className={classes.rowWithoutBorder}>
                                    <TableCell>
                                        <Typography variant="h5" sx={{ textTransform: "capitalize" }}>
                                            {t("accountStatement")}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableCell>
                </TableRow>

                <TableRow>
                    <TableCell
                        className={clsx(classes.mainDetailsCell, "landscape-zoom")}
                    >
                        <Table className={classes.whiteSpace}>
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        <TypographyKeyValue
                                            title={t("accountCode")}
                                            value={queryData?.glAccount?.code}
                                        />
                                        <TypographyKeyValue
                                            title={t("accountName")}
                                            value={queryData?.glAccount?.name}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TypographyKeyValue
                                            title={t("branch")}
                                            value={queryData?.glAccount?.branch?.name}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TypographyKeyValue
                                            title={t("fromDate")}
                                            value={moment(urlQuery?.fromDate).format("ll")}
                                        />
                                        <TypographyKeyValue
                                            title={t("toDate")}
                                            value={moment(urlQuery?.toDate).format("ll")}
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );

    const table = !loading && !pageLoad && queryData !== null && (
        <Table id="manifestPrintTable">
            <TableHead>
                <TableRow>
                    <FixedTableCell colSpan={5} />
                    <FixedTableCell
                        className={classes.balance}
                        colSpan={2}
                    >
                        {t("balance")}
                    </FixedTableCell>
                </TableRow>
                <TableRow>
                    <FixedTableCell>{t("date")}</FixedTableCell>
                    <FixedTableCell>{t("recordNumber")}</FixedTableCell>
                    <FixedTableCell>
                        {t("transactionDescription")}
                    </FixedTableCell>
                    <FixedTableCell>{t("debit")}</FixedTableCell>
                    <FixedTableCell>{t("credit")}</FixedTableCell>
                    <FixedTableCell>{t("debit")}</FixedTableCell>
                    <FixedTableCell>{t("credit")}</FixedTableCell>
                </TableRow>
            </TableHead>

            <TableBody>
                <TableRow className={classes.balanceFont}>
                    <FixedTableCell>{t("startBalance")}</FixedTableCell>
                    <FixedTableCell></FixedTableCell>
                    <FixedTableCell></FixedTableCell>
                    <FixedTableCell></FixedTableCell>
                    <FixedTableCell></FixedTableCell>
                    <FixedTableCell>
                        {queryData?.glAccount?.startBalance >= 0
                            ? "00.00"
                            : Math.abs(queryData?.glAccount?.startBalance) ||
                            "00.00"}
                    </FixedTableCell>
                    <FixedTableCell>
                        {queryData?.glAccount?.startBalance <= 0
                            ? "00.00"
                            : Math.abs(queryData?.glAccount?.startBalance) ||
                            "00.00"}
                    </FixedTableCell>
                </TableRow>

                {/* {data?.financeStatement?.records.map((row) => { */}
                {tableData
                    .map((row, index) => {
                        return (
                            <TableRow key={Math.random()}>
                                <FixedTableCell component="th" scope="row">
                                    {row.entry.date}
                                </FixedTableCell>
                                <FixedTableCell
                                    pathname={`/admin/finance/journal-entry/${row.entry.id}`}
                                >
                                    {row.entry.code}
                                </FixedTableCell>
                                <FixedTableCell>
                                    {row.description}
                                </FixedTableCell>
                                <FixedTableCell>
                                    {row.debit.toFixed(2)}
                                </FixedTableCell>
                                <FixedTableCell>
                                    {row.credit.toFixed(2)}
                                </FixedTableCell>
                                <FixedTableCell>
                                    {row.totalDebit.toFixed(2)}
                                </FixedTableCell>
                                <FixedTableCell>
                                    {row.totalCredit.toFixed(2)}
                                </FixedTableCell>
                            </TableRow>
                        );
                    })}
                <TableRow className={classes.balanceFont}>
                    <FixedTableCell>{t("endBalance")}</FixedTableCell>
                    <FixedTableCell></FixedTableCell>
                    <FixedTableCell></FixedTableCell>
                    <FixedTableCell>
                        {queryData?.sumJournalEntryRecords?.debit + currency}
                    </FixedTableCell>
                    <FixedTableCell>
                        {queryData?.sumJournalEntryRecords?.credit + currency}
                    </FixedTableCell>
                    <FixedTableCell>
                        {queryData?.glAccount?.endBalance >= 0
                            ? "00.00"
                            : Math.abs(queryData?.glAccount?.endBalance) ||
                            "00.00"}
                        {currency}
                    </FixedTableCell>
                    <FixedTableCell>
                        {queryData?.glAccount?.endBalance <= 0
                            ? "00.00"
                            : Math.abs(queryData?.glAccount?.endBalance) ||
                            "00.00"}
                        {currency}
                    </FixedTableCell>
                </TableRow>
            </TableBody>
        </Table >
    );

    return (
        <Root>
            {loading || pageLoad ? (
                <>
                    <FullScreenLoading minHeight="20%" />
                    {/* this spans for download font before print */}
                    <span className={clsx(classes.barcodeFont)} />
                    <span style={{ opacity: 0 }} className={classes.cairoFont}>
                        lا
                    </span>
                </>
            ) : reportDetails === null ? (
                <NotFound />
            ) : (
                <>
                    <div>
                        <div className="page-header">{header}</div>

                        <div className="page-footer">
                            {queryData?.organization?.reportFooter}
                        </div>

                        <table style={{ width: "100%" }}>
                            <thead>
                                <tr>
                                    <td>
                                        <div className="page-header-space">{header}</div>
                                    </td>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <td>
                                        <div
                                            className={clsx(classes.shipmentsTable, {
                                                // [classes.tableWidth]: tableZoom,
                                            })}
                                        >
                                            {table}
                                        </div>
                                        <div className={classes.signatureBlock}>

                                            <Table className={classes.whiteSpace}>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell className={classes.signature}>
                                                            <Typography color="primary">
                                                                {t("accountant")}
                                                            </Typography>
                                                            <Typography>____________________</Typography>
                                                        </TableCell>
                                                        <TableCell className={classes.signature}>
                                                            <Typography color="primary">
                                                                {t("chiefAccountant")}
                                                            </Typography>
                                                            <Typography>____________________</Typography>
                                                        </TableCell>
                                                        <TableCell className={classes.signature}>
                                                            <Typography color="primary">
                                                                {t("generalManager")}
                                                            </Typography>
                                                            <Typography>____________________</Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>

                            <tfoot>
                                <tr>
                                    <td>
                                        <div className="page-footer-space">
                                            {queryData?.organization?.reportFooter}
                                        </div>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </>
            )}
        </Root>
    );
};

export default AccountStatementPrint;

function TypographyKeyValue(props) {
    const { title, value, hidden, ...restProps } = props;
    return hidden || !value ? null : (
        <Typography
            variant="body2"
            className={clsx(classes.keyValue)}
            {...restProps}
        >
            <span className={classes.title}>{title}:</span> <span>{value}</span>
        </Typography>
    );
}



