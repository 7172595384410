import React, { memo } from "react";
import { styled } from "@mui/material/styles";
import { Chip } from "@mui/material";
import clsx from "clsx";
import * as colors from "@mui/material/colors";
import { FixedTableCell } from "./FixedTableCell";

const StyledFixedTableCell = styled(Chip)(({ theme, colorcode }) => ({
  color: theme.palette.getContrastText(colorcode),
  backgroundColor: colorcode + "!important",
}));

export const TableCellColor = memo((props) => {
  const color = {
    PKR: colors["blueGrey"]["500"],
    PKM: colors["lightBlue"]["500"],
    PKD: colors["blue"]["500"],
    RJCT: colors["red"]["500"],
    RITS: colors["grey"]["500"],
    RTRN: colors["brown"]["500"],
    OTD: colors["teal"]["500"],
    OTR: colors["purple"]["500"],
    DTR: colors["green"]["500"],
    DEX: colors["orange"]["500"],
    HTR: colors["amber"]["500"],
    PKH: colors["amber"]["500"],
    RTS: colors["pink"]["500"],
    BMT: colors["indigo"]["500"],
    BMR: colors["deepPurple"]["500"],
  };

  return (
    <FixedTableCell sx={props.style} className={clsx(props.className)}>
      <StyledFixedTableCell
        colorcode={color[props.code]}
        size="small"
        label={props.children}
      />
    </FixedTableCell>
  );
});
