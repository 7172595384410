import React, { useState, Fragment, useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import clsx from "clsx";
import {
  Button,
  IconButton,
  Typography,
  Dialog,
  AppBar,
  Toolbar,
  Slide,
  Paper,
  Collapse,
  Box,
  InputAdornment,
  Table,
  TableHead,
  TableRow,
  TableBody,
  DialogContent,
  ButtonGroup,
  Popper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Grow,
} from "@mui/material";
import { LocationOn, Close, AddCircleOutline, Delete } from "@mui/icons-material";
import emptyList from "../../assets/Image/received.png";

import { useForm } from "react-hook-form";

import { useQuery, useMutation, gql, useApolloClient } from "@apollo/client";

import {
  SAVE_SHIPMENT_QUERY,
  CALCULATE_FEES_QUERY,
  SHIPMENT_UPDATE_QUERY,
  CUSTOMERID,
  BACODE,
} from "./Graphql";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import Maps from "../HOC/GoogleMap/Maps";
// import MUIselect from '../HOC/MUI/MUIselect';
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { useTranslation } from "react-i18next";
import { Can } from "../HOC/CustomComponents/Secured";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import LookupDropdown from "../HOC/CustomComponents/LookupDropdown";
import { lookupCodeIsValid } from "../HOC/CustomFunctions/lookupCodeIsValid";
import FormButton from "../CustomComponents/Buttons/FormButton";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import { Globals } from "../HOC/Classes/Globals";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import { useSnackbar } from "notistack";
import ListBranches from "../HOC/ComponentWithSpecificQuery/ListBranches";
import {
  LIST_BRANCHES_DROPDOWN,
  LIST_COUNTRIES_DROPDOWN,
  LIST_CUSTOMERS_DROPDOWN,
  LIST_PAYMENT_TYPES_DROPDOWN,
  LIST_SHIPMENT_TYPES_DROPDOWN,
  LIST_SHIPPING_SERVICES_DROPDOWN,
  LIST_TRANSACTION_TYPES_DROPDOWN,
  LSIT_ZONES_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import { selectedIdIsValid } from "../HOC/CustomFunctions/selectedIsValid";
import EmptyTableMessage from "../HOC/FunctionComponents/EmptyTableMessage";
import CustomSpinner from "../HOC/FunctionComponents/CustomSpinner";
import RecipientListPopover from "./RecipientListPopover";
import Grid from "@mui/material/Unstable_Grid2";
// import config from "../../config.json";
import * as gqlb from "gql-query-builder";
import { withRouter } from "react-router";
import { defaultQueryFields, SEARCH } from "../ShipmentSearch/SearchTable";
// import MUIDate from "../HOC/MUI/MUIDate";
import moment from "moment";
import ProductList from "./ProductsList/ProductList";
import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import useWidth, { isWidthDown } from "../../Hooks/useWidth";
import MUIDateTime from "../HOC/MUI/MUIDateTime";
import MuiSwitch from "../HOC/MUI/MUIswitch";
import { GetManifestPermissionSlug } from "../../helpers/getManifestPermissionSlug";
import SearchIcon from '@mui/icons-material/Search';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PrintDialog from "./PrintDialog";
import PrintComponent from "./PrintComponent";

const PREFIX = "SaveShipment";

const classes = {
  shipmentRoot: `${PREFIX}-shipmentRoot`,
  margin: `${PREFIX}-margin`,
  divider: `${PREFIX}-divider`,
  noPadding: `${PREFIX}-noPadding`,
  footer: `${PREFIX}-footer`,
  calcValue: `${PREFIX}-calcValue`,
  calcTitle: `${PREFIX}-calcTitle`,
  totalCalc: `${PREFIX}-totalCalc`,
  gridWidth: `${PREFIX}-gridWidth`,
  marginTop: `${PREFIX}-marginTop`,
  sectionMargin: `${PREFIX}-sectionMargin`,
  sectionPadding: `${PREFIX}-sectionPadding`,
  appBar: `${PREFIX}-appBar`,
  title: `${PREFIX}-title`,
  typography: `${PREFIX}-typography`,
  button: `${PREFIX}-button`,
  recipient: `${PREFIX}-recipient`,
  feesField: `${PREFIX}-feesField`,
  loadingFooter: `${PREFIX}-loadingFooter`,
  addIcon: `${PREFIX}-addIcon`,
  tableTitle: `${PREFIX}-tableTitle`,
  toolbar: `${PREFIX}-toolbar`,
  overlay: `${PREFIX}-overlay`,
  tableField: `${PREFIX}-tableField`,
  saveToolbar: `${PREFIX}-saveToolbar`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.shipmentRoot}`]: {
    position: "relative",
  },

  [`& .${classes.margin}`]: {
    margin: theme.spacing(2),
  },

  [`& .${classes.divider}`]: {
    minWidth: "92%",
  },

  [`& .${classes.noPadding}`]: {
    [theme.breakpoints.down("sm")]: {
      padding: "0px !important",
    },
  },

  [`& .${classes.footer}`]: {
    width: "100%",
    fontSize: "15px",
    padding: theme.spacing(1),
    zIndex: 10,
    bottom: "0",
    textAlign: "center",
    position: "sticky",
    backgroundColor: "white",
    borderWidth: "2px 0 0",
    borderStyle: "solid",
    borderColor: theme.palette.primary.main,
  },

  [`& .${classes.calcValue}`]: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
    marginBottom: theme.spacing(0.5),
  },

  [`& .${classes.calcTitle}`]: {
    marginBottom: 0,
  },

  [`& .${classes.totalCalc}`]: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "25px",
      marginTop: theme.spacing(2),
    },
  },

  [`& .${classes.gridWidth}`]: {
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(1),
    },

    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(0),
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginTop: theme.spacing(1),
    },
  },

  [`& .${classes.marginTop}`]: {
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(2),
      // padding: "0 !important",
    },
  },

  [`& .${classes.sectionMargin}`]: {
    padding: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(0),
      width: "100%",
    },
  },

  [`& .${classes.sectionPadding}`]: {
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(2),
    },
  },

  [`& .${classes.appBar}`]: {
    position: "relative",
  },

  [`& .${classes.title}`]: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },

  [`& .${classes.typography}`]: {
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(2),
    },
    marginBottom: 0,
  },

  [`& .${classes.button}`]: {
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(2),
    },
  },

  [`& .${classes.feesField}`]: {
    padding: theme.spacing(0, 5),
    "& input": {
      color: theme.palette.primary.main,
      fontWeight: "bold",
      textAlign: "center",
      fontSize: 15,
    },
  },

  [`& .${classes.loadingFooter}`]: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: 1000,
    backgroundColor: "#ffffff99",
    top: 0,
    left: 0,
    "& .MuiGrid-container": {
      height: "100%",
    },
  },
  [`& .${classes.toolbar}`]: {
    width: "100%"
  },
  [`& .${classes.saveToolbar}`]: {
    flexDirection: "column"
  },
  [`& .${classes.tableTitle}`]: {
    flex: "1 1 100%",
  },

  [`& .${classes.addIcon}`]: {
    color: theme.palette.success.main,
  },
  [`& .${classes.tableField}`]: {
    width: '100px',
    margin: theme.spacing(1, 0)
  },
  // [`& .${classes.overlay}`]: {
  //   backgroundColor: "#ffffffde",
  //   position: "absolute",
  //   zIndex: 2,
  //   display: "flex",
  //   justifyContent: "center",
  //   alignItems: "center",
  //   width: "100%",
  //   height: "100%",
  //   top: 0,
  //   right: 0,
  // },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const parseBool = (bool) => bool === "true"


const dateFormat = (date) => moment(date).locale("en").format("YYYY-MM-DD HH:mm:ss");

const SaveShipment = (props) => {
  const {
    closeDialog,
    defaultCustomer,
    anotherShipment,
    anotherShipmentSwitch,
  } = props;

  const [calcFeesState, setCalcFeesState] = useState({
    recipientSubzoneId: null,
    recipientZoneId: null,
    senderSubzoneId: null,
    senderZoneId: null,
    priceType: null,
    paymentType: null,
    price: null,
    weight: null,
    serviceId: null,
    customerId: null,
    deliveryFees: null,
    extraWeightFees: null,
    collectionFees: null,
    returnFees: null,
    size: {
      height: null,
      width: null,
      length: null,
    },
    render: false,
  });

  const [calcFeesValue, setCalcFeesValue] = useState({
    amount: 0,
    tax: 0,
    post: 0,
    total: 0,
  });
  const [calculationChecker, setCalculationChecker] = useState({});

  const [createdAtState, setCreatedAtState] = useState(new Date());
  const [unEditable, setUnEditable] = useState(false);
  const [customerWarehousing, setCustomerWarehousing] = useState(false);

  const [autocompleteValues, setAutocompleteValues] = useState({
    branch: null,
    originBranch: null,
    transactionType: null,
    customer: null,
    service: null,
    recipientZone: null,
    recipientSubzone: null,
    senderZone: null,
    senderSubzone: null,
    type: null,
    country: null,
    paymentType: null,
  });

  // const multiCountry = config.app.multiCountry;

  const [recipientZones, setRecipientZones] = useState();
  const [recipientZonesList, setRecipientZonesList] = useState({
    zonesList: null,
    subzonesList: null,
  });
  const [openMapDialog, setOpenMapDialog] = useState(false);

  const client = useApolloClient();
  const user = Globals.user;
  const adminNotesPermission = user.hasPermission(
    "shipping.shipment.view_admin_note"
  );

  const settingsPermission = user.hasPermission(
    "shipping.shipment.choose_transaction_type"
  );
  const adminWarehousingPermission = user.hasPermission(
    "shipping.product.list"
  );

  const warehousingSystem = Globals.user.warehousing

  const SETTINGS = gqlb.query({
    operation: "shippingSettings",
    fields: settingsPermission
      ? [
        { defaultShippingService: ["id", "name"] },
        { defaultTransactionType: ["id", "name"] },
        // { defaultPaymentType: ["code", "name"] },
        { localCurrency: ["name"] },
        "forcePostalCode",
        "multiCountries"
      ]
      : [{ localCurrency: ["name"] }, { defaultShippingService: ["id", "name"] }, "forcePostalCode", "multiCountries"],
    variables: {},
  });

  const { data: settings, loading: settingsLoading } = useQuery(
    gql`
      ${SETTINGS.query}
    `,
    {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        if (settingsPermission) {
          // const defaultPayment = data.shippingSettings.defaultPaymentType.code;
          if (!urlUpdateShipmentId) {
            // setValue("shipment.paymentType", defaultPayment, {
            //   shouldDirty: true,
            //   shouldValidate: true,
            // });
            // if (defaultPayment === "CASH" || defaultPayment === "CRDT") {
            //   calcFeesHandler({ value: 0, name: "price" });
            //   setValue("shipment.price", 0);
            // }

            // calcFeesHandler({ value: defaultPayment, name: "paymentType" });

            setAutocompleteValues((prev) => ({
              ...prev,
              transactionType: data.shippingSettings.defaultTransactionType,
            }));
          }
        }
      },
    }
  );
  const multiCountry = settings?.shippingSettings?.multiCountries;
  const forcePostalCode = !!settings?.shippingSettings?.forcePostalCode;
  const customerPermission = user.hasPermission(
    "shipping.shipment.choose_customer"
  );
  const currencyCode = settings?.shippingSettings?.localCurrency?.name ?? "";

  const listConsigneePermission = user.hasPermission("shipping.consignee.list");

  let inWarehouseLocalStorage = false
  if (localStorage.getItem("receiveInWarehouse")) {
    inWarehouseLocalStorage = parseBool(localStorage.getItem("receiveInWarehouse"))
    localStorage.setItem("receiveInWarehouse", inWarehouseLocalStorage)
  }
  const {
    handleSubmit,
    unregister,
    setValue,
    control,
    setError,
    getValues,
    formState,
    watch,
    clearErrors,
  } = useForm({
    defaultValues: {
      shipment: {
        receiveInWarehouse: inWarehouseLocalStorage
      }
    },
  });
  const { enqueueSnackbar } = useSnackbar();

  const customerId = watch("shipment.customerId") ?? user?.account?.id;

  const { errors } = formState;

  const { t } = useTranslation(["translation", "validation"]);
  const options = [t('save'), t('saveAndPrint')];

  const urlUpdateShipmentId = !closeDialog && props?.match?.params?.id;

  const handleClickOpenMap = () => {
    setOpenMapDialog(true);
  };

  const handleCloseMap = () => {
    setOpenMapDialog(false);
  };

  let weightTimer;
  let priceTimer;
  let heightTimer;
  let widthTimer;
  let lengthTimer;

  useEffect(() => {
    if (defaultCustomer) {
      setAutocompleteValues((prev) => ({
        ...prev,
        customer: defaultCustomer,
      }));
      setCalcFeesState((prev) => ({
        ...prev,
        ...(customerPermission && {
          customerId: defaultCustomer.id,
        }),
      }));
    }
    if (!urlUpdateShipmentId) {
      setValue(
        "shipment",
        {
          weight: 1,
          length: 0,
          width: 0,
          height: 0,
          piecesCount: 1,
          ...(user.account?.name && {
            senderName: user.account?.name,
          }),
        },
        { shouldDirty: true, shouldValidate: true }
      );
      setCalcFeesState((prev) => ({
        ...prev,
        weight: 1,
        size: { length: 0, width: 0, height: 0 },
      }));
    }

    return () => {
      clearTimeout(weightTimer);
      clearTimeout(priceTimer);
      clearTimeout(heightTimer);
      clearTimeout(widthTimer);
      clearTimeout(lengthTimer);
    };
  }, []);

  const resetFeesValue = () => {
    setValue("shipment.deliveryFees", "");
    setValue("shipment.extraWeightFees", "");
    setValue("shipment.collectionFees", "");
    setValue("shipment.returnFees", "");
  };

  const firstCalcUpdate = useRef(true);
  const overwriteFees = user.hasPermission("shipping.shipment.overwrite_fees");
  const watchFees = (name) =>
    watch(`shipment.${name}`) || watch(`shipment.${name}`) === 0;
  const validFees =
    watchFees("returnFees") ||
    watchFees("deliveryFees") ||
    watchFees("extraWeightFees") ||
    watchFees("collectionFees");
  const feesPermission = overwriteFees ? !validFees : true;

  const calcFeesHandler = ({ value, name, feesAvailable }) => {
    let deleteFees = {
      deliveryFees: null,
      extraWeightFees: null,
      collectionFees: null,
      returnFees: null,
    };

    if (feesAvailable) {
      deleteFees = {};
    } else {
      resetFeesValue();
    }
    let fieldValue = {
      // eslint-disable-next-line eqeqeq
      [name]: value || value === 0 ? value : null,
    };

    if (name === "width" || name === "length" || name === "height") {
      fieldValue = {
        size: {
          ...calcFeesState.size,
          [name]: value,
        },
      };
    }
    firstCalcUpdate.current = false;
    setCalcFeesState((prev) => ({
      ...prev,
      ...deleteFees,
      ...fieldValue,
      render: !calcFeesState.render,
    }));
  };

  const updateShipment = SHIPMENT_UPDATE_QUERY();

  const { data: shipmentDataForUpdate, loading: shipmentDataForUpdateLoading } =
    useQuery(
      gql`
        ${updateShipment.query}
      `,
      {
        fetchPolicy: "no-cache",
        nextFetchPolicy: "no-cache",
        skip: Boolean(!urlUpdateShipmentId),
        variables: {
          id: parseInt(urlUpdateShipmentId),
          excludeShipmentId: parseInt(urlUpdateShipmentId),
        },
        onCompleted: (data) => {
          const shipment = data?.shipment;
          if (shipment?.id) {
            if (!data.shipment?.editable) {
              setUnEditable(true);
            }
            shipment.customer.warehousing && setPickedProduct({ products: data?.shipment?.shipmentProducts })
            setChange((prev) => !prev)
            setCustomerWarehousing(shipment.customer.warehousing)
            setBusinessType(shipment.customer.businessType.code)
            setValue(
              "shipment",
              {
                ...(datePermission && { date: shipment?.date }),
                code: shipment?.code,
                recipientName: shipment?.recipientName === null ? "" : shipment?.recipientName,
                recipientPhone: shipment?.recipientPhone,
                recipientMobile: shipment?.recipientMobile,
                recipientAddress: shipment?.recipientAddress,
                recipientPostalCode: shipment?.recipientPostalCode,
                senderName: shipment?.senderName,
                senderPhone: shipment?.senderPhone,
                senderMobile: shipment?.senderMobile,
                senderAddress: shipment?.senderAddress,
                senderPostalCode: shipment?.senderPostalCode,
                packageDescription: shipment?.description === null ? "" : shipment?.description,
                weight: shipment?.weight,
                piecesCount: shipment?.piecesCount,
                price: shipment?.price,
                ...(shipment?.refNumber && {
                  refNumber: shipment?.refNumber,
                }),
                ...(shipment?.returnPiecesCount && {
                  returnPiecesCount: shipment?.returnPiecesCount,
                }),
                height: shipment.size.height,
                length: shipment.size.length,
                width: shipment.size.width,
                notes: shipment?.notes === null ? "" : shipment?.notes,

                deliveryFees: shipment.deliveryFees?.toFixed(2),
                extraWeightFees: shipment.extraWeightFees?.toFixed(2),
                collectionFees: shipment.collectionFees?.toFixed(2),
                returnFees: shipment.returnFees?.toFixed(2),
              },
              { shouldDirty: true, shouldValidate: true }
            );

            setCreatedAtState(shipment.date);

            const country = shipment?.recipientZone?.branch?.country;
            const recipientZone = {
              id: shipment?.recipientZone?.id,
              name: shipment?.recipientZone?.name,
            };
            const senderZone = shipment?.senderZone;
            setAutocompleteValues({
              service: shipment?.service,
              recipientZone,
              recipientSubzone: shipment?.recipientSubzone,
              senderZone,
              senderSubzone: shipment?.senderSubzone,
              customer: shipment?.customer,
              branch: shipment?.branch,
              originBranch: shipment?.originBranch,
              transactionType: shipment?.transactionType,
              type: shipment?.type,
              paymentType: shipment?.paymentType,
              ...(multiCountry && { country }),
            });

            setCalcFeesValue({
              amount: shipment.amount,
              post: shipment.postFees,
              tax: shipment.tax,
              total: shipment.totalAmount,
            });
            setCalculationChecker((prev) => ({
              customerId: true,
              serviceId: true,
              senderZoneId: true,
              senderSubzoneId: true,
              recipientZoneId: true,
              recipientSubzoneId: true,
            }));
            setCalcFeesState((prev) => ({
              ...prev,
              weight: shipment?.weight,
              price: shipment?.price,
              paymentType: shipment?.paymentType.code,
              priceType: shipment?.priceType.code,
              serviceId: parseInt(shipment.service.id),
              recipientZoneId: parseInt(shipment?.recipientZone.id),
              recipientSubzoneId: parseInt(shipment?.recipientSubzone.id),
              senderZoneId: parseInt(shipment?.senderZone.id),
              senderSubzoneId: parseInt(shipment?.senderSubzone.id),
              size: shipment?.size,
              deliveryFees: shipment.deliveryFees,
              extraWeightFees: shipment.extraWeightFees,
              collectionFees: shipment.collectionFees,
              returnFees: shipment.returnFees,
              ...(customerPermission && {
                customerId: parseInt(shipment?.customer.id),
              }),
            }));
          } else {
            setUnEditable("invalidId");
          }
        },
      }
    );

  const updateShipmentDetails = shipmentDataForUpdate?.shipment;
  const updateBranchConditionAndPaymentType = updateShipmentDetails?.status?.code === "PKR";
  const invalidCustomer =
    urlUpdateShipmentId &&
    (customerPermission
      ? false
      : updateShipmentDetails?.customer.id !== customerId);

  const [zoneName, setZoneName] = useState({
    zone: "",
    subzone: "",
  });

  const [location, setLocation] = useState({
    latitude: null,
    longitude: null,
  });

  const shipmentTypeValidation = Boolean(
    watch("shipment.type") === "PDP" || watch("shipment.type") === "PTP"
  );

  const [saveShipment, { loading: saveLoading }] = useMutation(
    gql`
      ${SAVE_SHIPMENT_QUERY.query}
    `,
    {
      fetchPolicy: "no-cache",
    }
  );

  const firstUpdate = useRef(true);

  const skipCustomerQuery = customerPermission
    ? urlUpdateShipmentId
      ? !watch("shipment.customerId") || firstUpdate.current
      : !watch("shipment.customerId")
    : urlUpdateShipmentId;

  const [businessType, setBusinessType] = useState("")
  const { data: customerData } = useQuery(
    gql`
      ${CUSTOMERID.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      skip: skipCustomerQuery,
      variables: {
        id: customerPermission
          ? parseInt(watch("shipment.customerId"))
          : customerId,
        input: {
          customerId: customerPermission
            ? parseInt(watch("shipment.customerId"))
            : customerId,
          active: true,
        },
      },
      onCompleted: (data) => {
        const customer = data.customer;
        setBusinessType(customer.businessType.code)
        setCustomerWarehousing(customer.warehousing)
        setValue("shipment.senderMobile", customer.mobile, {
          shouldValidate: true,
        });
        setValue("shipment.senderPhone", customer.phone, {
          shouldValidate: true,
        });
        customer.address &&
          setValue("shipment.senderAddress", customer.address, {
            shouldValidate: true,
          });
        setValue("shipment.senderName", customer.name, {
          shouldValidate: true,
        });
        // customer.defaultPaymentType &&
        //   setValue("shipment.paymentType", customer.paymentTypes.code, {
        //     shouldValidate: true,
        //   });
        customer.defaultOpenable &&
          setValue("shipment.openable", customer.defaultOpenable.code, {
            shouldValidate: true,
          });
        customer.defaultPriceType &&
          setValue("shipment.priceType", customer.defaultPriceType.code, {
            shouldValidate: true,
          });
        customer.activityName &&
          setValue("shipment.packageDescription", customer.activityName, {
            shouldValidate: true,
          });
        customer.postalCode &&
          setValue("shipment.senderPostalCode", customer.postalCode, {
            shouldValidate: true,
          });

        const paymentTypeValidation = Boolean(
          customer.paymentTypes?.code === "CASH" ||
          customer.paymentTypes?.code === "CRDT"
        );

        setCalcFeesState((prev) => ({
          ...prev,
          ...(customer.paymentTypes[0]?.code && {
            paymentType: customer.paymentTypes[0]?.code,
          }),
          ...(customer.defaultPriceType?.code && {
            priceType: customer.defaultPriceType?.code,
          }),
          ...(paymentTypeValidation && {
            price: 0,
          }),
        }));

        if (paymentTypeValidation) {
          setValue("shipment.price", 0);
        }
      },
    }
  );

  const countryId =
    customerData?.customer?.zone?.branch?.country?.id ??
    updateShipmentDetails?.senderZone?.branch?.country?.id;

  const codePermission =
    user.hasPermission("shipping.shipment.specify_code") &&
    (!user.account || user.account.specifyShipmentCode);

  const datePermission = user.hasPermission("shipping.shipment.specify_Date");
  const CRDTShipment = Boolean(watch("shipment.paymentType") === 'CRDT')

  const allowWarehousing = warehousingSystem && customerWarehousing


  const newShipment = urlUpdateShipmentId ? updateBranchConditionAndPaymentType : true
  const ShowReciveInWareHouse = newShipment && (watch('shipment.type') === 'FDP' || watch('shipment.type') === 'PDP' ||
    watch('shipment.type') === 'PTP') && watch("shipment.paymentType") !== "CASH" &&
    user.hasPermission(GetManifestPermissionSlug('RITS', 'create').toLowerCase())


  const [shipmentId, setShipmentId] = React.useState(0);
  const [customerAfterSave, setCustomerAfterSave] = React.useState();

  const afterSaveAndPrint = () => {
    if (updateShipmentDetails) {
      pushUrl(props, `/admin/shipments/${shipmentId}`)
      return;
    }
    props.reset(customerAfterSave);
    !anotherShipment && closeDialog();
  }
  const handleOnSubmitForm = (data) => {
    const shipment = data.shipment;

    const pickedProducts = pickedProduct.products
    const product = []
    for (let i = 0; i < pickedProducts.length; i++) {
      const referenceBranch = {
        "productId": parseInt(pickedProducts[i].product.id),
        "quantity": parseInt(pickedProducts[i].quantity),
        "price": CRDTShipment ? 0 : parseFloat(pickedProducts[i].price)
      };
      product.push(referenceBranch)
    }

    const branches = {
      branchId:
        shipment.type === "RTS" || Boolean(allowWarehousing && watch("shipment.type") === "FDP") ? shipment.branchId : shipment.originBranchId,
      originBranchId: shipment.originBranchId,
    };

    const date = dateFormat(shipment["date"]);

    saveShipment({
      variables: {
        input: {
          ...(datePermission && { date }),
          ...(updateShipmentDetails && { id: updateShipmentDetails?.id }),
          // businessTypeCode: "B2C",
          ...(codePermission && { code: shipment.code?.trim() }),

          ...(updateShipmentDetails
            ? updateBranchConditionAndPaymentType && branches
            : branches),

          transactionTypeId: shipment.transactionTypeId,
          ...(forcePostalCode && {
            senderPostalCode: shipment.senderPostalCode,
          }),
          ...(forcePostalCode && {
            recipientPostalCode: shipment.recipientPostalCode,
          }),

          recipientName: shipment.recipientName,
          ...(ShowReciveInWareHouse && {
            receiveInWarehouse: shipment.receiveInWarehouse,
          }),
          paymentTypeCode: shipment.paymentType,
          priceTypeCode: shipment.priceType,
          recipientZoneId: shipment.recipientZoneId,
          recipientSubzoneId: shipment.recipientSubzoneId,
          recipientPhone: shipment.recipientPhone,
          recipientMobile: shipment.recipientMobile,
          recipientAddress: shipment.recipientAddress,
          senderName: shipment.senderName,
          senderPhone: shipment.senderPhone,
          senderMobile: shipment.senderMobile,
          senderZoneId: shipment.senderZoneId,
          senderSubzoneId: shipment.senderSubzoneId,
          senderAddress: shipment.senderAddress,
          description: shipment.packageDescription,
          ...(warehousingSystem ? productsSelectedLength && { weight: parseFloat(shipment.weight) } : { weight: parseFloat(shipment.weight) }),
          ...(warehousingSystem ? productsSelectedLength && { piecesCount: parseFloat(shipment.piecesCount) } : { piecesCount: parseFloat(shipment.piecesCount) }),
          ...(warehousingSystem ? productsSelectedLength && { price: parseFloat(shipment.price) } : { price: parseFloat(shipment.price) }),
          ...(overwriteFees && {
            deliveryFees: parseFloat(shipment.deliveryFees),
            extraWeightFees: parseFloat(shipment.extraWeightFees),
            returnFees: parseFloat(shipment.returnFees),
            collectionFees: parseFloat(shipment.collectionFees),
          }),
          notes: shipment.notes,
          refNumber: shipment.refNumber,
          typeCode: shipment.type,
          ...(shipment.returnPiecesCount &&
            shipmentTypeValidation && {
            returnPiecesCount: parseFloat(shipment.returnPiecesCount),
          }),
          openableCode: shipment.openable,
          serviceId: parseInt(shipment.serviceId),
          ...(shipment.customerId && {
            customerId: parseInt(shipment.customerId),
          }),
          size: {
            length: parseInt(shipment.length),
            height: parseInt(shipment.height),
            width: parseInt(shipment.width),
          },
          ...(location.latitude && { latitude: location.latitude }),
          ...(location.longitude && { longitude: location.longitude }),
          ...(allowWarehousing && !productsSelectedLength && { shipmentProducts: product })
        },
      },
    })
      .then((res) => {
        const action = () => (
          <Button
            href={`/admin/shipments/${res?.data?.saveShipment?.id}`}
            sx={[
              { color: "white", fontWeight: "bold" },
              {
                "&:hover": {
                  color: "white",
                },
              },
            ]}
            onClick={(e) => {
              e.preventDefault();
              pushUrl(props, `/admin/shipments/${res?.data?.saveShipment?.id}`);
            }}
          >
            {t("view")}
          </Button>
        );

        enqueueSnackbar(
          t("shipmentSaved", {
            code: res?.data?.saveShipment?.code,
          }),
          {
            variant: "success",
            action,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            TransitionComponent: Collapse,
          }
        );
        if (document.location.pathname.endsWith("shipments")) {
          client.refetchQueries({
            include: [
              gql`
                ${SEARCH(defaultQueryFields(adminNotesPermission)).query}
              `,
            ],
          });
        }
        if (updateShipmentDetails) {
          parseInt(saveType) === 1 && setShipmentId(res?.data?.saveShipment?.id)
          parseInt(saveType) !== 1 && pushUrl(props, `/admin/shipments/${res?.data?.saveShipment?.id}`);
          return;
        }
        parseInt(saveType) !== 1 && props.reset({ ...res?.data?.saveShipment?.customer });
        if (parseInt(saveType) === 1) {
          setShipmentId(res?.data?.saveShipment?.id)
          setCustomerAfterSave({ ...res?.data?.saveShipment?.customer })
        }
        if (!anotherShipment) {
          parseInt(saveType) !== 1 && closeDialog();
        }
      })
      .catch(({ graphQLErrors }) => {
        setValidationError(graphQLErrors, setError, "shipment.");
        if (!graphQLErrors[0]["extensions"]?.validation)
          enqueueSnackbar(graphQLErrors[0].message, {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            TransitionComponent: Collapse,
          });
      });
  };

  // const saveShipmentFun = (saveShipmentData, printUrl, printShape, printType) => {
  //   saveShipment({
  //     variables: {
  //       input: { ...saveShipmentData }
  //     },
  //   })
  //     .then((res) => {
  //       const action = () => (
  //         <Button
  //           href={`/admin/shipments/${res?.data?.saveShipment?.id}`}
  //           sx={[
  //             { color: "white", fontWeight: "bold" },
  //             {
  //               "&:hover": {
  //                 color: "white",
  //               },
  //             },
  //           ]}
  //           onClick={(e) => {
  //             e.preventDefault();
  //             pushUrl(props, `/admin/shipments/${res?.data?.saveShipment?.id}`);
  //           }}
  //         >
  //           {t("view")}
  //         </Button>
  //       );

  //       enqueueSnackbar(
  //         t("shipmentSaved", {
  //           code: res?.data?.saveShipment?.code,
  //         }),
  //         {
  //           variant: "success",
  //           action,
  //           anchorOrigin: {
  //             vertical: "bottom",
  //             horizontal: "right",
  //           },
  //           TransitionComponent: Collapse,
  //         }
  //       );
  //       saveType === 1 && window.open(`${printUrl}/${printShape}/${res?.data?.saveShipment?.id.toString()}/${printType ?? ''}`)
  //       if (document.location.pathname.endsWith("shipments")) {
  //         client.refetchQueries({
  //           include: [
  //             gql`
  //               ${SEARCH(defaultQueryFields(adminNotesPermission)).query}
  //             `,
  //           ],
  //         });
  //       }
  //       if (updateShipmentDetails) {
  //         pushUrl(props, `/admin/shipments/${res?.data?.saveShipment?.id}`);
  //         return;
  //       }
  //       props.reset({ ...res?.data?.saveShipment?.customer }, res?.data?.saveShipment?.inWarehouse, saveType);

  //       if (!anotherShipment) {
  //         closeDialog();
  //       }
  //     })
  //     .catch(({ graphQLErrors }) => {
  //       setValidationError(graphQLErrors, setError, "shipment.");
  //       if (!graphQLErrors[0]["extensions"]?.validation)
  //         enqueueSnackbar(graphQLErrors[0].message, {
  //           variant: "error",
  //           anchorOrigin: {
  //             vertical: "bottom",
  //             horizontal: "right",
  //           },
  //           TransitionComponent: Collapse,
  //         });
  //     });
  // }
  const validateCalcFeesField = (name) => calcFeesState[name] !== null;
  const canCalculate = () =>
    validateCalcFeesField("recipientSubzoneId") &&
    validateCalcFeesField("recipientZoneId") &&
    validateCalcFeesField("senderSubzoneId") &&
    validateCalcFeesField("senderZoneId") &&
    validateCalcFeesField("priceType") &&
    validateCalcFeesField("paymentType") &&
    validateCalcFeesField("price") &&
    validateCalcFeesField("weight") &&
    calculationChecker?.["recipientSubzoneId"] &&
    calculationChecker?.["recipientZoneId"] &&
    calculationChecker?.["senderSubzoneId"] &&
    calculationChecker?.["senderZoneId"] &&
    calculationChecker?.["serviceId"] &&
    calcFeesState["size"]["width"] !== null &&
    calcFeesState["size"]["height"] !== null &&
    calcFeesState["size"]["length"] !== null &&
    (customerPermission
      ? calculationChecker?.["customerId"] &&
      validateCalcFeesField("customerId")
      : true);

  //////////////////////// QUERY CALCULATE FEES //////////////////////////////
  const { loading: claculateFeesLoading, data: calcFeesData } = useQuery(
    gql`
      ${CALCULATE_FEES_QUERY.query}
    `,
    {
      nextFetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: calcFeesState.render,
      fetchPolicy: "no-cache",
      skip: !canCalculate() || invalidCustomer || firstCalcUpdate.current,
      variables: {
        input: {
          // businessTypeCode: "B2C",
          weight: calcFeesState && parseFloat(calcFeesState?.weight),
          price: calcFeesState && parseFloat(calcFeesState?.price),
          priceTypeCode: calcFeesState?.priceType,
          paymentTypeCode: calcFeesState?.paymentType,
          serviceId: calcFeesState?.serviceId,
          recipientZoneId: calcFeesState?.recipientZoneId,
          recipientSubzoneId: calcFeesState?.recipientSubzoneId,
          senderZoneId: calcFeesState?.senderZoneId,
          senderSubzoneId: calcFeesState?.senderSubzoneId,
          ...(calcFeesState?.size && { size: calcFeesState?.size }),

          ...(calcFeesState?.deliveryFees !== null && {
            deliveryFees: calcFeesState?.deliveryFees,
          }),
          ...(calcFeesState?.extraWeightFees !== null && {
            extraWeightFees: calcFeesState?.extraWeightFees,
          }),
          ...(calcFeesState?.returnFees !== null && {
            returnFees: calcFeesState?.returnFees,
          }),
          ...(calcFeesState?.collectionFees !== null && {
            collectionFees: calcFeesState?.collectionFees,
          }),
          ...(customerPermission && { customerId: calcFeesState?.customerId }),
        },
      },
      onCompleted: (data) => {
        const shippingFees = data.calculateShippingFees;
        setValue(
          "shipment.deliveryFees",
          parseFloat(shippingFees.delivery?.toFixed(2))
        );
        setValue(
          "shipment.extraWeightFees",
          parseFloat(shippingFees.weight?.toFixed(2))
        );
        setValue(
          "shipment.returnFees",
          parseFloat(shippingFees.return?.toFixed(2))
        );
        setValue(
          "shipment.collectionFees",
          parseFloat(shippingFees.collection?.toFixed(2))
        );
        setCalcFeesValue({
          amount: parseFloat(shippingFees.amount?.toFixed(2)),
          post: parseFloat(shippingFees.post?.toFixed(2)),
          tax: parseFloat(shippingFees.tax?.toFixed(2)),
          total: parseFloat(shippingFees.total?.toFixed(2)),
        });
      },
    }
  );

  function currentValueMatchingNewValues(data, fieldName) {
    const currentValue = watch(`shipment.${fieldName}`);

    if (typeof currentValue === "number") {
      const isValueValid = selectedIdIsValid(data, currentValue);

      if (isValueValid) {
        setCalculationChecker((prev) => ({ ...prev, [fieldName]: true }));
      } else {
        calcFeesHandler({ value: null, name: fieldName });

        setValue(`shipment.${fieldName}`, "");
      }
    }
  }

  // const onCompletePaymentType = (data) => {
  //   if (updateShipmentDetails) {
  //     lookupCodeIsValid(data, updateShipmentDetails?.paymentType?.code) &&
  //       setValue(
  //         "shipment.paymentType",
  //         updateShipmentDetails?.paymentType?.code,
  //         {
  //           shouldDirty: true,
  //           shouldValidate: true,
  //         }
  //       );
  //   } else {
  //     console.log('jgjgjg');
  //     const defaultPayment = customerData?.customer?.paymentTypes?.code;

  //     console.log(defaultPayment);

  //     const valid = lookupCodeIsValid(data, defaultPayment);

  //     if (valid) {
  //       setValue("shipment.paymentType", defaultPayment, {
  //         shouldDirty: true,
  //         shouldValidate: true,
  //       });

  //       calcFeesHandler({ value: defaultPayment, name: "paymentType" });
  //     }
  //   }
  // };
  const [disPaymentType, setDisPaymentType] = useState(true)
  const onCompletePaymentType = (data) => {
    const currentValue = watch(`shipment.paymentType`);
    const typeData = data["listPaymentTypesDropdown"];
    const isValid = (list, code) => list.some((i) => i.code === code);

    if (typeData.length > 0) {
      const isValueNotValid = !isValid(typeData, currentValue);

      if (isValueNotValid) {
        setValue(`shipment.paymentType`, "");
      }
    }

    const shipmentTypes = data[Object.keys(data)[0]];
    if (!updateShipmentDetails) {
      const defaultType = data.listPaymentTypesDropdown[0];

      const value = isValid(shipmentTypes, defaultType?.code)
        ? defaultType
        : shipmentTypes[0];
      setValue("shipment.paymentType", value.code, {
        shouldDirty: true,
        shouldValidate: true,
      });
      if (value.code === "CASH" || value.code === "CRDT") {
        setValue("shipment.price", 0);
        setValue("shipment.priceType", "INCLD");
        calcFeesHandler({ value: 0, name: "price" });
        calcFeesHandler({
          value: "INCLD",
          name: "priceType",
        });
      }
      calcFeesHandler({ value: value.code, name: "paymentType" });
      setAutocompleteValues((prev) => ({
        ...prev,
        paymentType: { ...value },
      }));
    }
  };
  const onCompletePriceType = (data) => {
    setDisPaymentType(false)
    if (updateShipmentDetails) {
      lookupCodeIsValid(data, updateShipmentDetails?.priceType?.code) &&
        setValue("shipment.priceType", updateShipmentDetails?.priceType?.code, {
          shouldDirty: true,
          shouldValidate: true,
        });
    } else {
      const defaultPrice = customerData?.customer?.defaultPriceType?.code;

      const value = lookupCodeIsValid(data, defaultPrice)
        ? defaultPrice
        : data?.listLookupEntriesDropdown[0].code;
      setValue("shipment.priceType", value, {
        shouldDirty: true,
        shouldValidate: true,
      });

      calcFeesHandler({ value: value, name: "priceType" });
    }
  };

  const onOpenable = (data) => {
    if (updateShipmentDetails) {
      setValue("shipment.openable", updateShipmentDetails?.openable?.code, {
        shouldDirty: true,
        shouldValidate: true,
      });
    } else {
      setValue("shipment.openable", "N", {
        shouldDirty: true,
        shouldValidate: true,
      });
    }
  };

  const parseData = (data) => {
    return data;
  };

  const onCompleteShipmentType = (data) => {
    const currentValue = watch(`shipment.type`);
    const typeData = data["listShipmentTypesDropdown"];
    const isValid = (list, code) => list.some((i) => i.code === code);

    if (typeData.length > 0) {
      const isValueNotValid = !isValid(typeData, currentValue);

      if (isValueNotValid) {
        setValue(`shipment.type`, "");
      }
    }

    const shipmentTypes = data[Object.keys(data)[0]];
    if (!updateShipmentDetails) {
      const defaultType = customerData?.customer?.defaultShipmentType;

      const value = isValid(shipmentTypes, defaultType?.code)
        ? defaultType
        : shipmentTypes[0];
      setAutocompleteValues((prev) => ({
        ...prev,
        type: { ...value },
      }));
    }
  };

  const onCompleteService = (data, name) => {
    currentValueMatchingNewValues(data, "serviceId");

    const defaultService =
      settings?.shippingSettings?.["defaultShippingService"] ??
      data.listShippingServicesDropdown[0];

    const valid = selectedIdIsValid(data, defaultService?.id);

    if (valid && !updateShipmentDetails) {
      setAutocompleteValues((prev) => ({
        ...prev,
        //this spreed object to render field when change customer
        service: { ...defaultService },
      }));

      setCalculationChecker((prev) => ({
        customerId: prev.customerId,
        serviceId: true,
      }));

      calcFeesHandler({ value: defaultService?.id, name: "serviceId" });
    }
  };

  const onCompleteZones = (data, name) => {
    currentValueMatchingNewValues(data, "senderZoneId");

    const defaultZone = customerData?.customer?.zone;

    const valid = selectedIdIsValid(data, defaultZone?.id);
    if (valid && !updateShipmentDetails) {
      setAutocompleteValues((prev) => ({
        ...prev,
        //this spreed object to render field when change customer
        senderZone: { ...defaultZone },
      }));

      setCalculationChecker((prev) => ({
        customerId: prev.customerId,
        serviceId: prev.serviceId,
        senderZoneId: true,
      }));
      calcFeesHandler({ value: defaultZone?.id, name: "senderZoneId" });
    }
  };

  const onCompleteSubzones = (data, name) => {
    currentValueMatchingNewValues(data, "senderSubzoneId");

    const defaultSubZone = customerData?.customer?.subzone;

    const valid = selectedIdIsValid(data, defaultSubZone?.id);
    if (valid && !updateShipmentDetails) {
      setAutocompleteValues((prev) => ({
        ...prev,
        //this spreed object to render field when change customer
        senderSubzone: { ...defaultSubZone },
      }));

      setCalculationChecker((prev) => ({
        customerId: prev.customerId,
        serviceId: prev.serviceId,
        senderZoneId: prev.senderZoneId,
        senderSubzoneId: true,
      }));
      calcFeesHandler({ value: defaultSubZone?.id, name: "senderSubzoneId" });
    }
  };

  const onChangeSenderSubzone = (e) => {
    const value = e?.id;
    setRecipientZones(null);
    // const fieldsName = ["recipientZoneId", "recipientSubzoneId"];
    // fieldsName.forEach((i) => {
    //   setValue(`shipment.${i}`, "");
    // });
    setCalculationChecker((prev) => ({
      customerId: prev.customerId,
      serviceId: prev.serviceId,
      senderZoneId: prev.senderZoneId,
      senderSubzoneId: true,
    }));

    calcFeesHandler({ value: value, name: "senderSubzoneId" });
  };

  const onCompleteRecipientZones = (data, name) => {
    currentValueMatchingNewValues(data, "recipientZoneId");

    const defaultZone = recipientZones?.zone;

    setRecipientZonesList((prev) => ({
      ...prev,
      zonesList: data.listZonesDropdown,
    }));

    const valid = selectedIdIsValid(data, defaultZone?.id);
    if (valid && !updateShipmentDetails) {
      setAutocompleteValues((prev) => ({
        ...prev,
        //this spreed object to render field when change customer
        recipientZone: { ...defaultZone },
      }));

      setCalculationChecker((prev) => ({
        customerId: prev.customerId,
        serviceId: prev.serviceId,
        senderZoneId: prev.senderZoneId,
        senderSubzoneId: prev.senderSubzoneId,
        recipientZoneId: true,
      }));
      calcFeesHandler({ value: defaultZone?.id, name: "recipientZoneId" });
    }
  };

  const onCompleteRecipientSubzones = (data, name) => {
    currentValueMatchingNewValues(data, "recipientSubzoneId");

    const defaultSubZone = recipientZones?.subzone;

    setRecipientZonesList((prev) => ({
      ...prev,
      subzonesList: data.listZonesDropdown,
    }));

    const valid = selectedIdIsValid(data, defaultSubZone?.id);
    if (valid) {
      setAutocompleteValues((prev) => ({
        ...prev,
        //this spreed object to render field when change customer
        recipientSubzone: { ...defaultSubZone },
      }));

      setCalculationChecker((prev) => ({
        customerId: prev.customerId,
        serviceId: prev.serviceId,
        senderZoneId: prev.senderZoneId,
        senderSubzoneId: prev.senderSubzoneId,
        recipientZoneId: prev.recipientZoneId,
        recipientSubzoneId: true,
      }));
      calcFeesHandler({
        value: defaultSubZone?.id,
        name: "recipientSubzoneId",
      });
    }

    // if (urlUpdateShipmentId && valid) {
    //   setAutocompleteValues((prev) => ({
    //     ...prev,
    //     //this spreed object to render field when change customer
    //     recipientSubzone: { ...defaultSubZone },
    //   }));

    //   calcFeesHandler({
    //     value: defaultSubZone?.id,
    //     name: "recipientSubzoneId",
    //   });
    // }
  };

  ///////////////// recipient popover functions ///////////////////////
  const [anchorElPopover, setAnchorElPopover] = useState(null);

  const handleOpenPopover = (event) => {
    setAnchorElPopover(event.currentTarget);
    setRecipientZones(null);
    setCalculationChecker((prev) => ({
      customerId: prev.customerId,
      serviceId: prev.serviceId,
      senderZoneId: prev.senderZoneId,
      senderSubzoneId: prev.senderSubzoneId,
      recipientZoneId: prev.recipientZoneId,
      recipientSubzoneId: false,
    }));
    calcFeesHandler({
      value: "",
      name: "recipientSubzoneId",
      ...(updateShipmentDetails && { feesAvailable: true }),
    });
    setValue("shipment.recipientSubzoneId", "");
  };

  const handleCloseRecipentPopover = () => {
    setAnchorElPopover(null);
  };
  const openPopover = Boolean(anchorElPopover);

  const recipientPopoverId = openPopover ? "recipient-popover-id" : undefined;

  const checkValidity = (list, value) => list?.some((i) => i.id === value);

  const onComleteRecipientDetails = (data) => {
    const recipientData = data?.customer ?? data?.consignee;
    setValue("shipment.recipientMobile", recipientData.mobile, {
      shouldValidate: true,
    });
    setValue("shipment.recipientPhone", recipientData.phone, {
      shouldValidate: true,
    });
    recipientData.address &&
      setValue("shipment.recipientAddress", recipientData.address, {
        shouldValidate: true,
      });
    setValue("shipment.recipientName", recipientData.name ?? "", {
      shouldValidate: true,
    });
    setRecipientZones({
      zone: recipientData.zone,
      subzone: recipientData.subzone,
    });

    const zoneValid = checkValidity(
      recipientZonesList.zonesList,
      recipientData.zone.id
    );

    const subzoneValid = checkValidity(
      recipientZonesList.subzonesList,
      recipientData.subzone.id
    );

    if (zoneValid) {
      setAutocompleteValues((prev) => ({
        ...prev,
        //this spreed object to render field when change customer
        recipientZone: { ...recipientData.zone },
      }));
      setCalculationChecker((prev) => ({
        customerId: prev.customerId,
        serviceId: prev.serviceId,
        senderZoneId: prev.senderZoneId,
        senderSubzoneId: prev.senderSubzoneId,
        recipientZoneId: true,
      }));
      calcFeesHandler({
        value: recipientData.zone.id,
        name: "recipientZoneId",
        ...(updateShipmentDetails && { feesAvailable: true }),
      });
    }

    if (subzoneValid) {
      setAutocompleteValues((prev) => ({
        ...prev,
        //this spreed object to render field when change customer
        recipientSubzone: { ...recipientData.subzone },
      }));
      setCalculationChecker((prev) => ({
        customerId: prev.customerId,
        serviceId: prev.serviceId,
        senderZoneId: prev.senderZoneId,
        senderSubzoneId: prev.senderSubzoneId,
        recipientZoneId: prev.recipientZoneId,
        recipientSubzoneId: true,
      }));
      calcFeesHandler({
        value: recipientData.subzone.id,
        name: "recipientSubzoneId",
        ...(updateShipmentDetails && { feesAvailable: true }),
      });
    }
  };
  ///////////////// End recipient popover functions ///////////////////////

  const invalidId = (
    <Grid container justifyContent="center" className={classes.track}>
      <EmptyTableMessage
        loading={shipmentDataForUpdateLoading}
        message={t("noShipmentWithCode")}
      />
    </Grid>
  );

  const showPrice = Boolean(
    watch("shipment.paymentType") !== "CRDT" &&
    watch("shipment.paymentType") !== "CASH"
  );
  const disableSubmitButton =
    unEditable ||
    saveLoading ||
    claculateFeesLoading ||
    (!calcFeesData && !updateShipmentDetails);

  // product

  const showProductsTable =
    allowWarehousing && watch('shipment.type') === 'FDP' && ['COLC', 'VISA', 'CRDT'].includes(watch("shipment.paymentType"))
  const [pickedProduct, setPickedProduct] = useState({ products: [] });
  const [change, setChange] = useState(false);
  const [changeDes, setChangeDes] = useState(false);
  const productsSelected = pickedProduct.products;
  const productsSelectedLength = productsSelected.length === 0
  const setSelect = (obj) => {
    setPickedProduct((prev) => {
      const seen = new Set();
      const duplicateId = []
      const products = [...prev.products, ...obj];
      const filteredProduct = products.filter(el => {
        if (!el.quantity) {
          el.product.quantity = 1
          el.quantity = 1
          el.price = el.product.price
        }
        const duplicate = seen.has(el.product.id);
        if (duplicate) {
          duplicateId.push(el.product.id)
        }
        seen.add(el.product.id);
        return !duplicate;
      });
      for (let i = 0; i < duplicateId.length; i++) {
        for (let j = 0; j < filteredProduct.length; j++) {
          if (duplicateId[i] === filteredProduct[j].product.id) {
            filteredProduct[j].quantity = Number(filteredProduct[j].quantity) + 1
          }
        }
      }
      return { products: filteredProduct };
    });
    setChangeDes(true)
    setChange((prev) => !prev);
  };

  const [state, setState] = useState({
    productList: {
      left: false,
      bottom: false,
      right: false,
    },
    manifestList: {
      left: false,
      bottom: false,
      right: false,
    },
  });

  const productDrawerHandler = (anchor, open) => {
    setState((prev) => ({
      ...prev,
      productList: { ...prev.productList, [anchor]: open },
    }));
  };

  const screenWidth = useWidth();

  const anchor = screenWidth === "xs" ? "bottom" : "right";
  useEffect(() => {
    if (!productsSelectedLength) {
      let totalPrice = 0
      let totalQuantity = 0
      let totalWeight = 0
      productsSelected.forEach((product) => {
        setValue(`shipment.price${product.product.id}`, product.price);
        setValue(`shipment.quantity${product.product.id}`, product.quantity);
        totalPrice += Number(watch(`shipment.price${product.product.id}`)) * Number(watch(`shipment.quantity${product.product.id}`));
        totalQuantity += Number(watch(`shipment.quantity${product.product.id}`));
        totalWeight += Number(product.product.weight) * Number(watch(`shipment.quantity${product.product.id}`));
      })
      !CRDTShipment ? setValue('shipment.price', totalPrice) : setValue('shipment.price', 0)
      setValue('shipment.weight', totalWeight)
      setValue('shipment.piecesCount', totalQuantity)
      setTotalprice(CRDTShipment ? 0 : totalPrice, totalWeight)
      changeDes && setDesciption()
    }
  }, [change]);

  const setTotalprice = (totalPrice, totalWeight) => {
    clearTimeout(priceTimer);
    priceTimer = setTimeout(() => {
      calcFeesHandler({
        value: totalPrice,
        name: "price",
      });
    }, 1000);
    clearTimeout(weightTimer);
    weightTimer = setTimeout(() => {
      calcFeesHandler({
        value: parseFloat(totalWeight),
        name: "weight",
      });
    }, 1000);
  }

  const setDesciption = () => {
    let des = ''
    productsSelected.forEach((product) => {
      des += product.quantity + ' ' + product.product.name + '\n'
    })
    setValue('shipment.packageDescription', des)
  }

  const EmptyTextFields = () => {
    setValue('shipment.price', 0)
    setValue('shipment.weight', 1)
    calcFeesHandler({ value: 1, name: "weight" });
    setValue('shipment.piecesCount', 1)
    setValue('shipment.packageDescription', '')
    setPickedProduct({ products: [] })
  }
  //
  const screenIsSmall = isWidthDown("xs", screenWidth);

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [saveType, setSaveType] = React.useState(localStorage.getItem("saveType") ?? 0);

  const handleMenuItemClick = (event, index) => {
    localStorage.setItem("saveType", index);
    setSaveType(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  const savButton = (
    // <Button
    //   color="primary"
    //   disabled={disableSubmitButton}
    //   variant="contained"
    //   type="submit"
    // >
    //   {saveLoading ? <ButtonLoading /> : t("save")}
    // </Button>
    <>
      <ButtonGroup variant="contained" ref={anchorRef} aria-label="split button" disabled={disableSubmitButton}>
        <Button type="submit">
          {saveLoading ? <ButtonLoading /> : options[saveType]}
        </Button>
        <Button
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 20,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      selected={index === saveType}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )

  const [code, setCode] = useState('')
  const { data: codeData } = useQuery(
    gql`
      ${BACODE.query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      skip: !code,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: {
        code: code,
      },
      onCompleted: (data) => {
        if (!data.barcode) {
          enqueueSnackbar(
            t("noBarcode"),
            {
              variant: "error",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
              },
              TransitionComponent: Collapse,
            }
          );
          return false
        }
        const change = watch("shipment.customerId") && parseInt(watch("shipment.customerId")) === data.barcode.customer.id
        !change && setAutocompleteValues((prev) => ({
          ...prev,
          //this spreed object to render field when change customer
          customer: data.barcode.customer
        }));
        !change && customerChange(data.barcode.customer)
        setCode("")
      },
    }
  );
  const getCustomer = () => {
    setCode(watch('shipment.code'))
  }
  const customerChange = (e) => {
    const value = e?.id;
    firstUpdate.current = false;
    setCustomerWarehousing(false)
    setRecipientZones(null);
    setCalculationChecker({ customerId: true });
    calcFeesHandler({
      value: value,
      name: "customerId",
    });
    allowWarehousing && EmptyTextFields()

    const fieldsName = [
      "senderMobile",
      "senderPhone",
      "senderAddress",
      "senderName",
    ];
    fieldsName.forEach((i) => {
      setValue(`shipment.${i}`, "");
    });
  }
  return (
    <Root>
      {shipmentDataForUpdateLoading || unEditable === "invalidId" ? (
        invalidId
      ) : unEditable ? (
        <Can permission="uneEditable" />
      ) : (
        <div className={classes.shipmentRoot}>
          {props.children}


          <PrintComponent
            shipmentsId={shipmentId}
            oneShipment
            hidden={["3d"]}
            noIcon={true}
            openPrintDialogAfterSave={shipmentId}
            type={"saveShipment"}
            afterSave={afterSaveAndPrint}
            anotherShipment={anotherShipment}
          />

          <Dialog
            fullScreen
            open={openMapDialog}
            onClose={handleCloseMap}
            TransitionComponent={Transition}
          >
            <AppBar className={classes.appBar}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleCloseMap}
                  aria-label="close"
                  size="large"
                >
                  <Close />
                </IconButton>
                <Typography
                  color="inherit"
                  variant="h6"
                  className={classes.title}
                >
                  {t("map")}
                </Typography>
                <Button autoFocus color="inherit" onClick={handleCloseMap}>
                  {t("save")}
                </Button>
              </Toolbar>
            </AppBar>
            <Maps
              zoneName={zoneName}
              location={(latitude, longitude) => {
                setLocation({
                  ...location,
                  latitude: latitude,
                  longitude: longitude,
                });
              }}
            />
          </Dialog>
          <Dialog
            anchor={anchor}
            fullWidth
            maxWidth="md"
            open={state.productList[anchor]}
            onClose={() => productDrawerHandler(anchor, false)}
            className={classes.dialog}
          >
            <DialogContent style={{ overflow: "hidden", padding: 0 }}>
              <ProductList
                setSelected={(obj) => {
                  setSelect(obj);
                }}
                type={"PADD"}
                selected={productsSelected}
                done={() => productDrawerHandler(anchor, false)}
                variables={customerId}
              />
            </DialogContent>
          </Dialog>

          <form
            // style={{ padding: 24 }}
            onSubmit={handleSubmit(handleOnSubmitForm)}
          >
            {closeDialog && (
              <Fragment>
                <AppBar color="default" position="sticky">
                  <Toolbar className={classes.saveToolbar} sx={{ padding: 1, flexDirection: { sm: "row !important" } }}>
                    <Grid container
                      justifyContent="space-between"
                      sx={{ flexGrow: 1, width: "100%" }}
                    >
                      <Grid container alignItems={"center"}>
                        <IconButton
                          edge="start"
                          color="inherit"
                          onClick={closeDialog}
                          aria-label="close"
                          size="large"
                        >
                          <Close />
                        </IconButton>
                        <Typography color="inherit" variant="h6">
                          {t("createShipment")}
                        </Typography>
                      </Grid>
                      {screenIsSmall && savButton}
                    </Grid>

                    <Grid
                      container
                      justifyContent="flex-end"
                      sx={{ flexGrow: 1, width: "100%" }}
                    >
                      {ShowReciveInWareHouse &&
                        <MuiSwitch
                          edge="end"
                          name="shipment.receiveInWarehouse"
                          label={t("reciveInWarehouse")}
                          control={control}
                          onChange={
                            localStorage.setItem("receiveInWarehouse", watch("shipment.receiveInWarehouse"))}
                        />
                      }
                      {anotherShipmentSwitch}
                      {!screenIsSmall && savButton}
                    </Grid>
                  </Toolbar>
                </AppBar>
              </Fragment>
            )}
            <Box sx={{ p: 3 }}>
              <Grid
                // component="form"
                container
                spacing={2}
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid
                  container
                  xs={12}
                  className={classes.noPadding}
                  justifyContent={
                    "space-between"
                    // isWidthUp("md", props.width) ? "space-between" : "center"
                  }
                >
                  {!closeDialog && (<Grid
                    justifyContent="space-between"
                    xs={12}
                    sx={{ display: "flex", flexWrap: "wrap" }}
                  >
                    <Typography variant="h6">{t("theShipment")}</Typography>
                    <Box>
                      {ShowReciveInWareHouse &&
                        <MuiSwitch
                          edge="end"
                          name="shipment.receiveInWarehouse"
                          label={t("reciveInWarehouse")}
                          control={control}
                          onChange={
                            localStorage.setItem("receiveInWarehouse", watch("shipment.receiveInWarehouse"))}
                        />}
                    </Box>
                  </Grid>)}
                  <Grid
                    xs={12}
                    md={6}
                    p={3}
                    container
                  // className={classes.sectionMargin}
                  // component={Grid}
                  >
                    <Paper
                      xs={12}
                      // spacing={2}
                      container
                      // className={classes.sectionMargin}
                      component={Grid}
                    >
                      <Grid xs={12} alignItems="flex-start">
                        <Typography variant="h6" gutterBottom>
                          {t("shipmentDetails")}
                        </Typography>
                      </Grid>
                      <Can showException permission={datePermission}>
                        <Grid xs={12} alignItems="flex-start">
                          <MUIDateTime
                            name="shipment.date"
                            label={t("date")}
                            control={control}
                            value={createdAtState}
                            onChange={(e) => setCreatedAtState(e)}
                          />
                        </Grid>
                      </Can>
                      <Can showException permission={codePermission}>
                        <Grid xs={12} alignItems="flex-start">
                          <ControlMUItextField
                            control={control}
                            disabled={unEditable}
                            name={"shipment.code"}
                            label={t("shipmentCode")}
                            errors={errors}
                            rules={
                              urlUpdateShipmentId && {
                                required: t("fieldIsRequired"),
                              }
                            }
                            onKeyPress={(event) => {
                              if (event.key === "Enter" && user.hasPermission("shipping.barcode.list")) {
                                getCustomer()
                              }
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end"
                                  sx={{ display: user.hasPermission("shipping.barcode.list") ? "flex" : "none", cursor: "pointer" }}>
                                  <SearchIcon onClick={getCustomer} />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                      </Can>
                      <Can
                        showException
                        permission="shipping.shipment.choose_branch"
                      >
                        <Grid xs={12} alignItems="flex-start">
                          <ListBranches
                            defaultValue={autocompleteValues.originBranch}
                            control={control}
                            errors={errors}
                            disabled={
                              unEditable ||
                              Boolean(
                                urlUpdateShipmentId && !updateBranchConditionAndPaymentType
                              )
                            }
                            rules={{ required: t("fieldIsRequired") }}
                            name={"shipment.originBranchId"}
                            label={t("originBranch")}
                            skip={
                              urlUpdateShipmentId && !updateShipmentDetails?.id
                            }
                            skipDefaultBranch={Boolean(urlUpdateShipmentId)}
                            onChangeValue={(e) => {
                              const fieldsName = ["transactionTypeId"];
                              fieldsName.forEach((i) => {
                                setValue(`shipment.${i}`, "");
                              });
                              setCalculationChecker({});
                            }}
                          />
                        </Grid>
                      </Can>
                      <Can
                        showException
                        permission={
                          user.hasPermission(
                            "shipping.shipment.choose_branch"
                          ) && (watch("shipment.type") === "RTS" || Boolean(allowWarehousing && watch("shipment.type") === "FDP"))
                        }
                      >
                        <Grid xs={12} alignItems="flex-start">
                          <ListBranches
                            control={control}
                            errors={errors}
                            parseData={(data) => parseData(data)}
                            query={LIST_BRANCHES_DROPDOWN.query}
                            disabled={
                              unEditable ||
                              Boolean(
                                urlUpdateShipmentId && !updateBranchConditionAndPaymentType
                              )
                            }
                            rules={{ required: t("fieldIsRequired") }}
                            name={"shipment.branchId"}
                            label={t("currentBranch")}
                            skip={
                              urlUpdateShipmentId && !updateShipmentDetails?.id
                            }
                            defaultValue={autocompleteValues.branch}
                          />
                        </Grid>
                      </Can>

                      <Can
                        showException
                        permission="shipping.shipment.choose_transaction_type"
                      >
                        <Grid xs={12} alignItems="flex-start">
                          <CustomAutocomplete
                            defaultValue={autocompleteValues.transactionType}
                            control={control}
                            errors={errors}
                            disabled={
                              unEditable ||
                              Boolean(!watch("shipment.originBranchId"))
                            }
                            parseData={(data) => parseData(data)}
                            name={"shipment.transactionTypeId"}
                            label={t("transactionType")}
                            rules={{ required: t("fieldIsRequired") }}
                            query={LIST_TRANSACTION_TYPES_DROPDOWN.query}
                            skip={
                              (urlUpdateShipmentId &&
                                !updateShipmentDetails?.id) ||
                              invalidCustomer ||
                              Boolean(!watch("shipment.originBranchId"))
                            }
                            variables={{
                              input: {
                                type: "PKR",
                                branchId: {
                                  value: watch("shipment.originBranchId"),
                                  includeNull: true,
                                },
                              },
                            }}
                          // onCompleted={onTransactionType}
                          />
                        </Grid>
                      </Can>

                      <Grid xs={12} alignItems="flex-start">
                        <CustomAutocomplete
                          control={control}
                          errors={errors}
                          disabled={
                            unEditable || Boolean(!watch("shipment.serviceId")) ||
                            (warehousingSystem && !productsSelectedLength)
                          }
                          rules={{ required: t("fieldIsRequired") }}
                          name={"shipment.type"}
                          label={t("packageType")}
                          skip={
                            (urlUpdateShipmentId &&
                              !updateShipmentDetails?.id) ||
                            invalidCustomer ||
                            !watch("shipment.serviceId")
                          }
                          onCompleted={onCompleteShipmentType}
                          defaultValue={autocompleteValues.type}
                          variables={{ serviceId: watch("shipment.serviceId") }}
                          parseData={(data) => parseData(data)}
                          query={LIST_SHIPMENT_TYPES_DROPDOWN.query}
                          valueKey="code"
                          onChangeValue={(e) => {
                            clearErrors("shipment.piecesCount");
                            clearErrors("shipment.returnPiecesCount");
                            if (e.code === "PDP" || e.code === "PTP") {
                              setValue("shipment.returnPiecesCount", 1);

                              e.code === "PDP"
                                ? setValue("shipment.piecesCount", 2)
                                : setValue("shipment.piecesCount", 1);
                            } else {
                              setValue("shipment.piecesCount", 1);
                              clearErrors("shipment.piecesCount");
                              unregister("shipment.returnPiecesCount");
                            }
                          }}
                        />
                      </Grid>

                      <Grid xs={12} alignItems="flex-start">
                        <ControlMUItextField
                          control={control}
                          errors={errors}
                          disabled={unEditable}
                          name={"shipment.packageDescription"}
                          label={t("packageDescription")}
                          rows={(allowWarehousing && !productsSelectedLength) ? 4 : 1}
                        />
                      </Grid>

                      <Grid xs={12} alignItems="flex-start">
                        <ControlMUItextField
                          control={control}
                          errors={errors}
                          disabled={unEditable}
                          readOnly={allowWarehousing && !productsSelectedLength}
                          type={"number"}
                          name={"shipment.weight"}
                          label={t("packageWeight")}
                          rules={{
                            required: t("fieldIsRequired"),
                            min: {
                              value: 0,
                              message: t("weightValidation"),
                            },
                          }}
                          onChange={(e) => {
                            const value = e.target.value;
                            clearTimeout(weightTimer);
                            weightTimer = setTimeout(() => {
                              calcFeesHandler({
                                value: parseFloat(value),
                                name: "weight",
                              });
                            }, 1000);
                          }}
                        />
                      </Grid>

                      <Grid xs={4} alignItems="flex-start">
                        <ControlMUItextField
                          control={control}
                          errors={errors}
                          disabled={unEditable}
                          type={"number"}
                          name={"shipment.length"}
                          label={t("length")}
                          rules={{
                            required: t("fieldIsRequired"),
                            min: {
                              value: 0,
                              message: t("validation:min", {
                                field: t("length"),
                                number: "0",
                              }),
                            },
                          }}
                          onChange={(e) => {
                            const value = e.target.value;
                            clearTimeout(lengthTimer);
                            lengthTimer = setTimeout(() => {
                              if (value && value >= 0) {
                                calcFeesHandler({
                                  value: parseFloat(value),
                                  name: "length",
                                });
                              }
                            }, 1000);
                          }}
                        />
                      </Grid>
                      <Grid xs={4} alignItems="flex-start">
                        <ControlMUItextField
                          control={control}
                          errors={errors}
                          disabled={unEditable}
                          type={"number"}
                          name={"shipment.width"}
                          label={t("width")}
                          rules={{
                            required: t("fieldIsRequired"),
                            min: {
                              value: 0,
                              message: t("validation:min", {
                                field: t("width"),
                                number: "0",
                              }),
                            },
                          }}
                          onChange={(e) => {
                            const value = e.target.value;
                            clearTimeout(widthTimer);
                            widthTimer = setTimeout(() => {
                              if (value && value >= 0) {
                                calcFeesHandler({
                                  value: parseFloat(value),
                                  name: "width",
                                });
                              }
                            }, 1000);
                          }}
                        />
                      </Grid>
                      <Grid xs={4} alignItems="flex-start">
                        <ControlMUItextField
                          control={control}
                          errors={errors}
                          disabled={unEditable}
                          type={"number"}
                          name={"shipment.height"}
                          label={t("height")}
                          rules={{
                            required: t("fieldIsRequired"),
                            min: {
                              value: 0,
                              message: t("validation:min", {
                                field: t("height"),
                                number: "0",
                              }),
                            },
                          }}
                          onChange={(e) => {
                            const value = e.target.value;
                            clearTimeout(heightTimer);
                            heightTimer = setTimeout(() => {
                              if (value && value >= 0) {
                                calcFeesHandler({
                                  value: parseFloat(value),
                                  name: "height",
                                });
                              }
                            }, 1000);
                          }}
                        />
                      </Grid>

                      <Grid
                        xs={shipmentTypeValidation ? 6 : 12}
                        alignItems="flex-start"
                      >
                        <ControlMUItextField
                          control={control}
                          errors={errors}
                          disabled={unEditable}
                          readOnly={allowWarehousing && !productsSelectedLength}
                          type={"number"}
                          name={"shipment.piecesCount"}
                          label={t("pieceCount")}
                          rules={{
                            required: t("fieldIsRequired"),
                            min: {
                              ...(watch("shipment.type") === "PDP"
                                ? {
                                  value: 2,
                                  message: t("validation:min", {
                                    field: t("pieceCount"),
                                    number: "2",
                                  }),
                                }
                                : {
                                  value: 1,
                                  message: t("validation:min", {
                                    field: t("pieceCount"),
                                    number: "1",
                                  }),
                                }),
                            },
                          }}
                        />
                      </Grid>

                      {shipmentTypeValidation && (
                        <Grid xs={6} alignItems="flex-start">
                          <ControlMUItextField
                            control={control}
                            errors={errors}
                            disabled={unEditable}
                            type="number"
                            name={"shipment.returnPiecesCount"}
                            label={t("returnPiecesCount")}
                            rules={{
                              required: t("fieldIsRequired"),
                              min: {
                                value: 1,
                                message: t("validation:min", {
                                  field: t("pieceCount"),
                                  number: "1",
                                }),
                              },
                              validate: {
                                ...(watch("shipment.type") === "PDP" && {
                                  max: (value) =>
                                    +value >= +watch("shipment.piecesCount")
                                      ? t("validation:lt", {
                                        field1: t("returnPiecesCount"),
                                        field2: t("pieceCount"),
                                      })
                                      : true,
                                }),
                              },
                            }}
                          />
                        </Grid>
                      )}

                      <Grid xs={12} sm={12} alignItems="flex-start">
                        <CustomAutocomplete
                          control={control}
                          errors={errors}
                          disabled={!Boolean(customerId) || unEditable ||
                            (urlUpdateShipmentId && !updateBranchConditionAndPaymentType)
                          }
                          rules={{ required: t("fieldIsRequired") }}
                          name={"shipment.paymentType"}
                          label={t("paymentType")}
                          skip={!Boolean(customerId) || disPaymentType}
                          onCompleted={onCompletePaymentType}
                          defaultValue={autocompleteValues.paymentType}
                          variables={{
                            ...(watch('shipment.customerId') && ({
                              input: { customerId: watch('shipment.customerId') }
                            }))
                          }}
                          parseData={(data) => parseData(data)}
                          query={LIST_PAYMENT_TYPES_DROPDOWN.query}
                          valueKey="code"
                          onChangeValue={(e) => {
                            calcFeesHandler({
                              value: e.code,
                              name: "paymentType",
                            });
                            // if (e.code === "CASH") {
                            //   setValue("shipment.weight", 1);
                            //   calcFeesHandler({ value: 1, name: "weight" });
                            // }
                            if (
                              e.code === "CASH" ||
                              e.code === "CRDT"
                            ) {
                              setValue("shipment.price", 0);
                              setValue("shipment.priceType", "INCLD");
                              calcFeesHandler({ value: 0, name: "price" });
                              calcFeesHandler({
                                value: "INCLD",
                                name: "priceType",
                              });
                            }
                            if (
                              e.code !== "COLC" &&
                              e.code !== "VISA" &&
                              e.code !== "CRDT"
                            ) {
                              allowWarehousing && !productsSelectedLength && EmptyTextFields()
                            }
                            if (
                              e.code !== "CRDT"
                            ) {
                              allowWarehousing && !productsSelectedLength && setChange((prev) => !prev);
                            }
                          }}
                        />
                      </Grid>
                      <Can showException permission={showPrice}>
                        <Grid xs={12} sm={12} alignItems="flex-start">
                          <ControlMUItextField
                            control={control}
                            errors={errors}
                            disabled={unEditable}
                            readOnly={Boolean(
                              watch("shipment.paymentType") === "CASH" ||
                              watch("shipment.paymentType") === "CRDT" ||
                              (allowWarehousing && !productsSelectedLength)
                            )}
                            type={"number"}
                            name={"shipment.price"}
                            label={t("packagePrice")}
                            InputProps={{
                              ...(currencyCode && {
                                endAdornment: (
                                  <InputAdornment position="end">
                                    {currencyCode}
                                  </InputAdornment>
                                ),
                              }),
                            }}
                            rules={{
                              required: t("fieldIsRequired"),
                            //   max: {
                            //     ...(watch("shipment.type") === "RTS"
                            //       && {
                            //       value: 0,
                            //       message: t("validation:max", {
                            //         field: t("priceType"),
                            //         number: "0",
                            //       }),
                            //     }),
                            //   },
                            }}
                            onChange={(e) => {
                              const value = e.target.value;
                              clearTimeout(priceTimer);
                              priceTimer = setTimeout(() => {
                                calcFeesHandler({
                                  value: value,
                                  name: "price",
                                });
                              }, 1000);
                            }}
                          />
                        </Grid>
                      </Can>

                      <Grid xs={12} sm={12} alignItems="flex-start">
                        <LookupDropdown
                          control={control}
                          errors={errors}
                          disabled={unEditable}
                          rules={{
                            required: t("fieldIsRequired"),
                          }}
                          name={"shipment.priceType"}
                          label={t("priceType")}
                          readOnly={Boolean(
                            watch("shipment.paymentType") === "CASH" ||
                            watch("shipment.paymentType") === "CRDT"
                          )}
                          onChanges={(e) => {
                            calcFeesHandler({
                              value: e.target.value,
                              name: "priceType",
                            });
                          }}
                          skip={
                            (urlUpdateShipmentId &&
                              !updateShipmentDetails?.id) ||
                            invalidCustomer
                          }
                          variables={{ input: { code: "SHP_PRICE_TYPE" } }}
                          onCompleted={onCompletePriceType}
                        />
                      </Grid>

                      <Grid xs={12} sm={12} alignItems="flex-start">
                        <ControlMUItextField
                          control={control}
                          errors={errors}
                          disabled={unEditable}
                          name={"shipment.refNumber"}
                          label={t("refNumber")}
                        />
                      </Grid>

                      <Grid xs={12} sm={12} alignItems="flex-start">
                        <LookupDropdown
                          control={control}
                          errors={errors}
                          disabled={unEditable}
                          rules={{ required: t("fieldIsRequired") }}
                          name={"shipment.openable"}
                          label={t("packageOpenPossibility")}
                          skip={
                            (urlUpdateShipmentId &&
                              !updateShipmentDetails?.id) ||
                            invalidCustomer
                          }
                          variables={{ input: { code: "SHP_OPEN_PACKAGE" } }}
                          onCompleted={onOpenable}
                        />
                      </Grid>

                      <Grid xs={12} sm={12} alignItems="flex-start">
                        <ControlMUItextField
                          control={control}
                          errors={errors}
                          disabled={unEditable}
                          name={"shipment.notes"}
                          label={t("notes")}
                          rows={3}
                        />
                      </Grid>
                    </Paper>
                  </Grid>

                  <Grid
                    container
                    xs={12}
                    md={6}
                    // spacing={2}
                    alignContent="flex-start"
                    justifyContent="center"
                    className={clsx(classes.gridWidth)}
                  >
                    <Grid
                      container
                      xs={12}
                      p={3}
                      // component={Grid}
                      alignContent="flex-start"
                    >
                      <Paper
                        container
                        xs={12}
                        // spacing={2}
                        component={Grid}
                        alignContent="flex-start"
                      >
                        <Grid xs={12} alignItems="flex-start">
                          <Typography variant="h6" gutterBottom>
                            {t("senderDetails")}
                          </Typography>
                        </Grid>

                        <Can
                          showException
                          permission="shipping.shipment.choose_customer"
                        >
                          <Grid xs={12} sm={12} alignItems="flex-start">
                            <CustomAutocomplete
                              defaultValue={autocompleteValues.customer}
                              control={control}
                              errors={errors}
                              rules={{ required: t("fieldIsRequired") }}
                              name={"shipment.customerId"}
                              label={t("customer")}
                              disabled={
                                unEditable || !watch("shipment.originBranchId")
                              }
                              parseData={(data) => parseData(data)}
                              query={LIST_CUSTOMERS_DROPDOWN.query}
                              skip={
                                (urlUpdateShipmentId &&
                                  !updateShipmentDetails?.id) ||
                                invalidCustomer ||
                                !watch("shipment.originBranchId")
                              }
                              variables={{
                                input: {
                                  branchId: {
                                    value: watch("shipment.originBranchId"),
                                    includeNull: true,
                                  },
                                  active: true,
                                },
                              }}
                              onCompleted={(data) => {
                                currentValueMatchingNewValues(
                                  data,
                                  "customerId"
                                );
                              }}
                              onChangeValue={(e) => { customerChange(e) }}
                            />
                          </Grid>
                        </Can>

                        <Grid xs={12} alignItems="flex-start">
                          <ControlMUItextField
                            control={control}
                            errors={errors}
                            disabled={unEditable}
                            name={"shipment.senderName"}
                            label={t("senderName")}
                            rules={{ required: t("fieldIsRequired") }}
                          />
                        </Grid>
                        <Grid xs={12} alignItems="flex-start">
                          <CustomAutocomplete
                            control={control}
                            errors={errors}
                            rules={{ required: t("fieldIsRequired") }}
                            disabled={
                              (customerPermission
                                ? !watch("shipment.customerId")
                                : false) || unEditable
                            }
                            name={"shipment.serviceId"}
                            label={t("service")}
                            onCompleted={onCompleteService}
                            onChangeValue={(e) => {
                              const value = e?.id;

                              // const fieldsName = [
                              //   "senderZoneId",
                              //   "senderSubzoneId",
                              //   "recipientZoneId",
                              //   "recipientSubzoneId",
                              // ];

                              // setRecipientZones(null);
                              setCalculationChecker((prev) => ({
                                customerId: prev.customerId,
                                serviceId: true,
                              }));
                              calcFeesHandler({
                                value: value,
                                name: "serviceId",
                              });

                              // fieldsName.forEach((i) => {
                              //   setValue(`shipment.${i}`, "");
                              // });
                              // setValue("shipment.type", "");
                            }}
                            query={LIST_SHIPPING_SERVICES_DROPDOWN.query}
                            parseData={(data) => parseData(data)}
                            skip={
                              (customerPermission
                                ? !calculationChecker?.["customerId"] ||
                                !watch("shipment.customerId")
                                : false) ||
                              (urlUpdateShipmentId &&
                                !updateShipmentDetails?.id) ||
                              invalidCustomer || settingsLoading || !businessType
                            }
                            variables={{
                              input: {
                                customerId: watch("shipment.customerId"),
                                active: true,
                                businessTypeCode: businessType
                              },
                            }}
                            defaultValue={autocompleteValues.service}
                          />
                        </Grid>
                        <Grid xs={6} alignItems="flex-start">
                          <CustomAutocomplete
                            control={control}
                            errors={errors}
                            rules={{ required: t("fieldIsRequired") }}
                            disabled={
                              unEditable ||
                              Boolean(!watch("shipment.serviceId"))
                            }
                            label={t("zone")}
                            name={"shipment.senderZoneId"}
                            onCompleted={(data) =>
                              onCompleteZones(data, "senderZone")
                            }
                            onChangeValue={(e) => {
                              const value = e?.id;
                              setRecipientZones(null);
                              // const fieldsName = [
                              //   // "senderSubzoneId",
                              //   "recipientZoneId",
                              //   "recipientSubzoneId",
                              // ];
                              // fieldsName.forEach((i) => {
                              //   setValue(`shipment.${i}`, "");
                              // });
                              setCalculationChecker((prev) => ({
                                customerId: prev.customerId,
                                serviceId: prev.serviceId,
                                senderZoneId: true,
                              }));

                              calcFeesHandler({
                                value: value,
                                name: "senderZoneId",
                              });
                            }}
                            query={LSIT_ZONES_DROPDOWN.query}
                            parseData={(data) => parseData(data)}
                            skip={
                              !watch("shipment.serviceId") ||
                              !calculationChecker?.["serviceId"] ||
                              !customerId ||
                              (urlUpdateShipmentId &&
                                !updateShipmentDetails?.id) ||
                              invalidCustomer
                            }
                            variables={{
                              input: {
                                active: true,
                                parentId: null,
                                ...(countryId && { countryId }),
                                service: {
                                  serviceId: watch("shipment.serviceId"),
                                  customerId: customerId,
                                },
                              },
                            }}
                            defaultValue={autocompleteValues.senderZone}
                          />
                        </Grid>

                        <Grid xs={6} alignItems="flex-start">
                          <CustomAutocomplete
                            control={control}
                            errors={errors}
                            rules={{ required: t("fieldIsRequired") }}
                            disabled={
                              unEditable ||
                              Boolean(!watch("shipment.senderZoneId"))
                            }
                            name={"shipment.senderSubzoneId"}
                            label={t("subzone")}
                            onCompleted={onCompleteSubzones}
                            onChangeValue={onChangeSenderSubzone}
                            query={LSIT_ZONES_DROPDOWN.query}
                            parseData={(data) => parseData(data)}
                            skip={
                              !watch("shipment.serviceId") ||
                              !calculationChecker?.["senderZoneId"] ||
                              !watch("shipment.senderZoneId") ||
                              !customerId ||
                              (urlUpdateShipmentId &&
                                !updateShipmentDetails?.id) ||
                              invalidCustomer
                            }
                            variables={{
                              input: {
                                active: true,
                                parentId: watch("shipment.senderZoneId"),
                                service: {
                                  serviceId: watch("shipment.serviceId"),
                                  customerId: customerId,
                                },
                              },
                            }}
                            defaultValue={autocompleteValues.senderSubzone}
                          />
                        </Grid>

                        <Grid xs={6} alignItems="flex-start">
                          <ControlMUItextField
                            control={control}
                            errors={errors}
                            disabled={unEditable}
                            rules={{
                              required: t("fieldIsRequired"),
                            }}
                            name={"shipment.senderPhone"}
                            label={t("phone")}
                            onBlur={(e) => {
                              Boolean(!getValues().shipment.senderMobile) &&
                                setValue(
                                  "shipment.senderMobile",
                                  getValues().shipment.senderPhone,
                                  { shouldDirty: true }
                                );
                            }}
                          />
                        </Grid>
                        <Grid xs={6} alignItems="flex-start">
                          <ControlMUItextField
                            control={control}
                            errors={errors}
                            disabled={unEditable}
                            rules={{
                              required: t("fieldIsRequired"),
                            }}
                            name={"shipment.senderMobile"}
                            label={t("mobile")}
                          />
                        </Grid>
                        <Can permission={forcePostalCode} showException>
                          <Grid xs={12} alignItems="flex-start">
                            <ControlMUItextField
                              control={control}
                              errors={errors}
                              disabled={unEditable}
                              rules={{
                                required: t("fieldIsRequired"),
                              }}
                              name={"shipment.senderPostalCode"}
                              label={t("postalCode")}
                            />
                          </Grid>
                        </Can>
                        <Grid xs={12} alignItems="flex-start">
                          <ControlMUItextField
                            control={control}
                            errors={errors}
                            disabled={unEditable}
                            rules={{
                              required: t("fieldIsRequired"),
                            }}
                            name={"shipment.senderAddress"}
                            label={t("address")}
                            InputProps={{
                              endAdornment: (
                                <IconButton
                                  disabled={unEditable}
                                  onClick={() => {
                                    handleClickOpenMap();
                                  }}
                                  size="large"
                                >
                                  <LocationOn />
                                </IconButton>
                              ),
                            }}
                          />
                        </Grid>
                      </Paper>
                    </Grid>

                    <Grid
                      container
                      xs={12}
                      p={3}
                      // component={Grid}

                      alignContent="flex-start"
                    >
                      <Paper
                        container
                        xs={12}
                        // spacing={2}
                        component={Grid}
                        alignContent="flex-start"
                      >
                        <Grid xs={12} alignItems="flex-start">
                          <Typography variant="h6" gutterBottom>
                            {t("recipientDetails")}
                          </Typography>
                        </Grid>

                        <Can permission={multiCountry} showException>
                          <Grid xs={12} alignItems="flex-start">
                            <CustomAutocomplete
                              control={control}
                              errors={errors}
                              rules={{ required: t("fieldIsRequired") }}
                              name={"shipment.countryId"}
                              label={t("country")}
                              disabled={
                                unEditable ||
                                Boolean(!watch("shipment.senderSubzoneId"))
                              }
                              onChangeValue={(e) => {
                                setRecipientZones(null);
                                // const fieldsName = ["recipientZoneId", "recipientSubzoneId"];
                                // fieldsName.forEach((i) => {
                                //   setValue(`shipment.${i}`, "");
                                // });
                                setCalculationChecker((prev) => ({
                                  customerId: prev.customerId,
                                  serviceId: prev.serviceId,
                                  senderZoneId: prev.senderZoneId,
                                  senderSubzoneId: prev.senderSubzoneId,
                                }));
                              }}
                              query={LIST_COUNTRIES_DROPDOWN.query}
                              parseData={(data) => parseData(data)}
                              defaultValue={autocompleteValues.country}
                            />
                          </Grid>
                        </Can>

                        <Grid xs={12} alignItems="flex-start">
                          <ControlMUItextField
                            control={control}
                            errors={errors}
                            disabled={unEditable}
                            name={"shipment.recipientName"}
                            label={t("recipient")}
                            aria-describedby={recipientPopoverId}
                            // aria-owns={
                            //   openPopover ? "recipient-popover-id" : undefined
                            // }
                            aria-haspopup="true"
                            InputProps={{
                              ...(listConsigneePermission && {
                                endAdornment: (
                                  <Fragment>
                                    <RecipientListPopover
                                      disabled={
                                        unEditable ||
                                        //this to make sure that is all fields ready to open popover
                                        !Boolean(
                                          recipientZonesList.zonesList
                                        ) ||
                                        (multiCountry &&
                                          watch("shipment.countryId") === "")
                                      }
                                      id={recipientPopoverId}
                                      anchorEl={anchorElPopover}
                                      handleClose={handleCloseRecipentPopover}
                                      onCompleted={onComleteRecipientDetails}
                                      handleOpenPopover={handleOpenPopover}
                                      consigneeVariables={{
                                        customerId: customerId,
                                        ...(multiCountry && {
                                          countryId:
                                            watch("shipment.countryId"),
                                        }),
                                      }}
                                    />
                                  </Fragment>
                                ),
                              }),
                            }}
                          />
                        </Grid>

                        <Grid xs={6} alignItems="flex-start">
                          <CustomAutocomplete
                            defaultValue={autocompleteValues.recipientZone}
                            control={control}
                            errors={errors}
                            rules={{ required: t("fieldIsRequired") }}
                            disabled={
                              unEditable ||
                              Boolean(watch("shipment.serviceId") === "") ||
                              watch("shipment.senderZoneId") === "" ||
                              watch("shipment.senderSubzoneId") === "" ||
                              (multiCountry &&
                                watch("shipment.countryId") === "")
                            }
                            name={"shipment.recipientZoneId"}
                            label={t("zone")}
                            onChangeValue={(e) => {
                              const value = e?.id;
                              setRecipientZones(null);
                              // setValue("shipment.recipientSubzoneId", "");
                              setCalculationChecker((prev) => ({
                                customerId: prev.customerId,
                                serviceId: prev.serviceId,
                                senderZoneId: prev.senderZoneId,
                                senderSubzoneId: prev.senderSubzoneId,
                                recipientZoneId: true,
                              }));
                              calcFeesHandler({
                                value: value,
                                name: "recipientZoneId",
                              });

                              setZoneName((prevState) => {
                                return {
                                  ...prevState,
                                  zone: e?.name,
                                  subzone: "",
                                };
                              });
                            }}
                            query={LSIT_ZONES_DROPDOWN.query}
                            parseData={(data) => parseData(data)}
                            skip={
                              !watch("shipment.serviceId") ||
                              !customerId ||
                              (urlUpdateShipmentId &&
                                !updateShipmentDetails?.id) ||
                              invalidCustomer ||
                              watch("shipment.senderZoneId") === "" ||
                              !calculationChecker?.["senderSubzoneId"] ||
                              watch("shipment.senderSubzoneId") === "" ||
                              (multiCountry &&
                                watch("shipment.countryId") === "")
                            }
                            variables={{
                              input: {
                                active: true,
                                countryId: watch("shipment.countryId"),
                                parentId: null,
                                service: {
                                  serviceId: watch("shipment.serviceId"),
                                  customerId: customerId,
                                  fromZoneId: watch("shipment.senderZoneId"),
                                  fromSubzoneId: watch(
                                    "shipment.senderSubzoneId"
                                  ),
                                },
                              },
                            }}
                            onCompleted={onCompleteRecipientZones}
                          />
                        </Grid>

                        <Grid xs={6} alignItems="flex-start">
                          <CustomAutocomplete
                            defaultValue={autocompleteValues.recipientSubzone}
                            control={control}
                            errors={errors}
                            rules={{ required: t("fieldIsRequired") }}
                            disabled={
                              unEditable ||
                              Boolean(
                                getValues("shipment.recipientZoneId") === ""
                              )
                            }
                            name={"shipment.recipientSubzoneId"}
                            label={t("subzone")}
                            onChangeValue={(e) => {
                              const value = e?.id;
                              setCalculationChecker((prev) => ({
                                customerId: prev.customerId,
                                serviceId: prev.serviceId,
                                senderZoneId: prev.senderZoneId,
                                senderSubzoneId: prev.senderSubzoneId,
                                recipientZoneId: prev.recipientZoneId,
                                recipientSubzoneId: true,
                              }));
                              calcFeesHandler({
                                value: value,
                                name: "recipientSubzoneId",
                              });

                              setZoneName((prevState) => ({
                                ...prevState,
                                subzone: e?.name,
                              }));
                            }}
                            query={LSIT_ZONES_DROPDOWN.query}
                            parseData={(data) => parseData(data)}
                            skip={
                              !watch("shipment.serviceId") ||
                              !customerId ||
                              !watch("shipment.recipientZoneId") ||
                              (urlUpdateShipmentId &&
                                !updateShipmentDetails?.id) ||
                              invalidCustomer ||
                              watch("shipment.senderZoneId") === "" ||
                              !calculationChecker?.["recipientZoneId"] ||
                              watch("shipment.senderSubzoneId") === ""
                            }
                            variables={{
                              input: {
                                active: true,
                                parentId: watch("shipment.recipientZoneId"),
                                service: {
                                  serviceId: watch("shipment.serviceId"),
                                  customerId: customerId,
                                  fromZoneId: watch("shipment.senderZoneId"),
                                  fromSubzoneId: watch(
                                    "shipment.senderSubzoneId"
                                  ),
                                },
                              },
                            }}
                            onCompleted={onCompleteRecipientSubzones}
                          />
                        </Grid>

                        <Grid xs={6} alignItems="flex-start">
                          <ControlMUItextField
                            control={control}
                            errors={errors}
                            disabled={unEditable}
                            rules={{
                              required: t("fieldIsRequired"),
                            }}
                            name={"shipment.recipientPhone"}
                            label={t("phone")}
                            onBlur={(e) => {
                              Boolean(!getValues().shipment.recipientMobile) &&
                                setValue(
                                  "shipment.recipientMobile",
                                  getValues().shipment.recipientPhone,
                                  { shouldDirty: true }
                                );
                            }}
                          />
                        </Grid>
                        <Grid xs={6} alignItems="flex-start">
                          <ControlMUItextField
                            control={control}
                            errors={errors}
                            disabled={unEditable}
                            rules={{
                              required: t("fieldIsRequired"),
                            }}
                            name={"shipment.recipientMobile"}
                            label={t("mobile")}
                          />
                        </Grid>
                        <Can permission={forcePostalCode} showException>
                          <Grid xs={12} alignItems="flex-start">
                            <ControlMUItextField
                              control={control}
                              errors={errors}
                              disabled={unEditable}
                              rules={{
                                required: t("fieldIsRequired"),
                              }}
                              name={"shipment.recipientPostalCode"}
                              label={t("postalCode")}
                            />
                          </Grid>
                        </Can>
                        <Grid xs={12} alignItems="flex-start">
                          <ControlMUItextField
                            control={control}
                            errors={errors}
                            disabled={unEditable}
                            rules={{
                              required: t("fieldIsRequired"),
                            }}
                            name={"shipment.recipientAddress"}
                            label={t("address")}
                            InputProps={{
                              endAdornment: (
                                <IconButton
                                  disabled={unEditable}
                                  onClick={() => {
                                    handleClickOpenMap();
                                  }}
                                  size="large"
                                >
                                  <LocationOn />
                                </IconButton>
                              ),
                            }}
                          />
                        </Grid>
                      </Paper>

                    </Grid>
                  </Grid>
                  {showProductsTable && (
                    <Can showException permission={adminWarehousingPermission}>
                      <Grid
                        container
                        xs={12}
                        p={3}
                        // component={Grid}
                        alignContent="flex-start"
                      >
                        <Paper
                          container
                          xs={12}
                          // spacing={2}
                          component={Grid}
                          alignContent="flex-start"
                          position='relative'
                          display='grid'
                        >
                          {/* {(watch('shipment.type') !== 'FDP' || watch("shipment.paymentType") !== 'COLC') && (
                          <Box className={classes.overlay}>
                            <Typography color="textPrimary" variant="body1">
                              {t("chooseCustomerRequired")}
                            </Typography>
                          </Box>
                        )} */}
                          <Toolbar className={clsx(classes.toolbar)} variant="dense">
                            <Typography className={classes.tableTitle} variant="h6">
                              {t("productList")}
                            </Typography>
                            <IconButton
                              aria-label="Add"
                              className={classes.addIcon}
                              onClick={() => productDrawerHandler(anchor, true)}
                              size="large"
                            >
                              <AddCircleOutline />
                            </IconButton>
                          </Toolbar>
                          <TableFixedHeaderWraper>
                            <Table aria-labelledby="tableTitle" aria-label="enhanced table">
                              <TableHead>
                                <TableRow>
                                  <FixedTableCell>#</FixedTableCell>
                                  <FixedTableCell>{t("name")}</FixedTableCell>
                                  <FixedTableCell>{t("availableQuantity")}</FixedTableCell>
                                  <FixedTableCell>{t("quantity")}</FixedTableCell>
                                  <FixedTableCell>{t("price")}</FixedTableCell>
                                  <FixedTableCell>{t("weight")}</FixedTableCell>
                                  <FixedTableCell>{t("delete")}</FixedTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {productsSelected.map((product, index) => {
                                  return (
                                    <TableRow
                                      hover
                                      role="checkbox"
                                      tabIndex={-1}
                                      key={index}
                                    // className={classes.tableBodyRow}
                                    >
                                      <FixedTableCell>{index + 1}</FixedTableCell>
                                      <FixedTableCell>{product.product.name}</FixedTableCell>
                                      <FixedTableCell>{product.product.availableQuantity}</FixedTableCell>
                                      <FixedTableCell>
                                        <ControlMUItextField
                                          className={classes.tableField}
                                          control={control}
                                          errors={errors}
                                          disabled={unEditable}
                                          variant="outlined"
                                          type={"number"}
                                          name={`shipment.quantity${product.product.id}`}
                                          label={t("quantity")}
                                          rules={{
                                            required: t("fieldIsRequired"),
                                            min: {
                                              value: 1,
                                              message: t("validation:min", {
                                                field: t("quantity"),
                                                number: "1",
                                              }),
                                            },
                                            max: {
                                              value: product.product.availableQuantity,
                                              message: t("validation:max", {
                                                field: t("quantity"),
                                                number: product.product.availableQuantity,
                                              }),
                                            },
                                          }}
                                          onChange={(e) => {
                                            const value = e.target.value;
                                            setPickedProduct((prev) => {
                                              prev.products[index].quantity = value
                                              return prev
                                            });
                                            setChange((prev) => !prev);
                                            setChangeDes(true)
                                            // let totalQuantity = 0
                                            // let totalPrice = 0
                                            // productsSelected.forEach((product) => {
                                            //   totalPrice += Number(watch(`shipment.price${product.product.id}`)) * Number(watch(`shipment.quantity${product.product.id}`));
                                            //   totalQuantity += Number(watch(`shipment.quantity${product.product.id}`));
                                            // })
                                            // setValue('shipment.piecesCount', totalQuantity)
                                            // setValue('shipment.price', totalPrice)
                                            // setTotalprice(totalPrice)
                                            // setDesciption()
                                          }}
                                        />
                                      </FixedTableCell>
                                      {CRDTShipment ? <FixedTableCell>0</FixedTableCell> : (
                                        <FixedTableCell>
                                          <ControlMUItextField
                                            control={control}
                                            errors={errors}
                                            disabled={unEditable}
                                            variant="outlined"
                                            type={"number"}
                                            name={`shipment.price${product.product.id}`}
                                            label={t("price")}
                                            className={classes.tableField}
                                            rules={{
                                              required: t("fieldIsRequired"),
                                              min: {
                                                value: 0,
                                                message: t("validation:min", {
                                                  field: t("price"),
                                                  number: "0",
                                                }),
                                              },
                                            }}
                                            onChange={(e) => {
                                              const value = e.target.value;
                                              setPickedProduct((prev) => {
                                                prev.products[index].price = value
                                                return prev
                                              });
                                              setChange((prev) => !prev);
                                              // let totalPrice = 0
                                              // productsSelected.forEach((product) => {
                                              //   totalPrice += Number(watch(`shipment.price${product.product.id}`)) * Number(watch(`shipment.quantity${product.product.id}`));
                                              // })
                                              // setValue('shipment.price', totalPrice)
                                              // setTotalprice(totalPrice)
                                            }}
                                          />
                                        </FixedTableCell>
                                      )
                                      }
                                      <FixedTableCell>{product.product.weight}</FixedTableCell>
                                      <FixedTableCell component="th" scope="row">
                                        <IconButton
                                          size="small"
                                          onClick={() => {
                                            const targetId = product.product.id
                                            setPickedProduct((current) => {
                                              const currentProducts = current.products
                                              currentProducts.length === 1 && EmptyTextFields()
                                              const filterProducts = currentProducts.filter((ele) => ele.product.id !== targetId)
                                              return { products: filterProducts }
                                            });
                                            setChange((prev) => !prev);
                                          }}
                                        >
                                          <Delete />
                                        </IconButton>
                                      </FixedTableCell>
                                    </TableRow>
                                  )
                                })}
                              </TableBody>
                            </Table>
                          </TableFixedHeaderWraper>
                          <Grid container justifyContent="center" alignItems="center" width='100%'>
                            {productsSelectedLength && (
                              <Box textAlign="center" marginTop="50px" marginBottom="50px">
                                <img width="50%" src={emptyList} alt={"delivery box"} />
                                <Box
                                  component={Typography}
                                  variant="h6"
                                  color="text.secondary"
                                  marginTop="0"
                                >
                                  {t("noProducts")}
                                </Box>
                              </Box>
                            )}
                          </Grid>
                        </Paper>
                      </Grid>
                    </Can>
                  )}
                  {!closeDialog && (
                    <Grid container xs={12} justifyContent="flex-end">
                      {savButton}
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Box>
          </form>
          {/* <div className={classes.pushContent} /> */}
          <Grid
            container
            className={classes.footer}
            justifyContent="flex-start"
          >
            {claculateFeesLoading && (
              <Box className={classes.loadingFooter}>
                <CustomSpinner name="PulseLoader" size={10} margin={3} />
              </Box>
            )}
            <Grid container component="span" xs={8} sm={10}>
              <Grid component="span" xs={6} sm>
                <CalculationResult
                  title={t("amount")}
                  currencyCode={currencyCode}
                  value={calcFeesValue.amount}
                />
              </Grid>
              <Grid component="span" xs={6} sm>
                <CalculationResult
                  title={t("deliveryFees")}
                  currencyCode={currencyCode}
                  control={control}
                  calcFeesHandler={calcFeesHandler}
                  editable={unEditable}
                  name={"shipment.deliveryFees"}
                  readOnly={feesPermission || claculateFeesLoading}
                />
              </Grid>
              <Grid component="span" xs={6} sm>
                <CalculationResult
                  title={t("extraWeightCost")}
                  currencyCode={currencyCode}
                  control={control}
                  calcFeesHandler={calcFeesHandler}
                  editable={unEditable}
                  name={"shipment.extraWeightFees"}
                  readOnly={feesPermission || claculateFeesLoading}
                />
              </Grid>
              <Grid component="span" xs={6} sm>
                <CalculationResult
                  title={t("collectionFees")}
                  currencyCode={currencyCode}
                  control={control}
                  calcFeesHandler={calcFeesHandler}
                  editable={unEditable}
                  name={"shipment.collectionFees"}
                  readOnly={feesPermission || claculateFeesLoading}
                />
              </Grid>
              <Grid component="span" xs={6} sm>
                <CalculationResult
                  title={t("returnFees")}
                  currencyCode={currencyCode}
                  control={control}
                  calcFeesHandler={calcFeesHandler}
                  editable={unEditable}
                  name={"shipment.returnFees"}
                  readOnly={feesPermission || claculateFeesLoading}
                />
              </Grid>
              {watch("shipment.paymentType") === "CRDT" && (
                <Grid component="span" xs={6} sm>
                  <CalculationResult
                    title={t("tax")}
                    currencyCode={currencyCode}
                    value={calcFeesValue.tax}
                  />
                </Grid>
              )}

              {watch("shipment.paymentType") === "CRDT" && (
                <Grid component="span" xs={6} sm>
                  <CalculationResult
                    title={t("postFees")}
                    currencyCode={currencyCode}
                    value={calcFeesValue.post}
                  />
                </Grid>
              )}
            </Grid>

            <Grid component="span" xs={4} sm={2}>
              <CalculationResult
                title={t("totalCost")}
                currencyCode={currencyCode}
                valuesx={classes.totalCalc}
                value={calcFeesValue.total}
              />
            </Grid>
          </Grid>
        </div>
      )
      }
    </Root >
  );
};

export default withRouter(SaveShipment);
let timeout = {};

const CalculationResult = (props) => {
  const {
    title,
    valueStyle,
    value,
    control,
    editable,
    name,
    calcFeesHandler,
    readOnly,
    currencyCode,
  } = props;

  useEffect(() => {
    return () => {
      clearTimeout(timeout[name]);
    };
  }, []);

  const feesField = control && (
    <ControlMUItextField
      control={control}
      name={name}
      InputProps={{
        ...(currencyCode && {
          endAdornment: (
            <InputAdornment position="end">{currencyCode}</InputAdornment>
          ),
        }),
      }}
      onChange={(e) => {
        const fieldValue = e.target.value;
        const objName = name.split(".")[1];
        clearTimeout(timeout[name]);

        timeout[name] = setTimeout(() => {
          calcFeesHandler({
            value: parseFloat(fieldValue),
            name: objName,
            feesAvailable: true,
          });
        }, 1000);
      }}
      disabled={editable}
      className={classes.feesField}
      variant="standard"
      readOnly={readOnly}
    />
  );
  return (
    <Fragment>
      <p className={classes.calcTitle}>{title}</p>
      {!control && (
        <p className={clsx(classes.calcValue, valueStyle)}>
          {value?.toFixed(2) ?? "--"}
          {" " + currencyCode}
        </p>
      )}
      {feesField}
    </Fragment>
  );
};
