/* eslint-disable no-useless-concat */
import React, { useCallback, useState } from "react";

import { styled } from "@mui/material/styles";

import {
  CircularProgress,
  Grid,
  IconButton,
  lighten,
  SwipeableDrawer,
  Table,
  TableBody,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import useWidth, { isWidthDown } from "../../../Hooks/useWidth";

import { gql, useQuery } from "@apollo/client";
import { Cancel, CheckCircle, FilterList } from "@mui/icons-material";
import clsx from "clsx";
import * as gqlb from "gql-query-builder";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import ProductListFilter from "../../../Hooks/ProductListFilters";
import TableFixedHeaderWraper from "../../HOC/CustomComponents/TableWithFixedHeader";
import EmptyTableMessage from "../../HOC/FunctionComponents/EmptyTableMessage";
import MUITablePagination from "../../HOC/MUI/TablePagination/MUITablePagination";
import CustomTableRow from "./CustomTableRow";
import { EnhancedTableHead, removeFiltersFields } from "./ProductListDetails";
import ManifestProductsProvider from "./ManifestProductsProvider";
import { MANIFEST_BY_ID_QUERY } from "../Graphql";

const TOOLBAR_PREFIX = "styledToolbar";

const toolbarClasses = {
  highlight: `${TOOLBAR_PREFIX}-highlight`,
  title: `${TOOLBAR_PREFIX}-title`,
  filterButton: `${TOOLBAR_PREFIX}-filterButton`,
};

const StyledToolbar = styled("div")(({ theme }) => ({
  width: "100%",
  zIndex: 1,

  [`& .${toolbarClasses.highlight}`]:
    theme.palette.mode === "light"
      ? {
        color: theme.palette.info.main,
        backgroundColor: lighten(theme.palette.info.main, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.info.main,
      },

  [`& .${toolbarClasses.title}`]: {
    flex: "1 1 100%",
  },

  [`& .${toolbarClasses.filterButton}`]: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
}));

const PREFIX = "ProductList";

const classes = {
  track: `${PREFIX}-track`,
  paper: `${PREFIX}-paper`,
  filters: `${PREFIX}-filters`,
  noScroll: `${PREFIX}-noScroll`,
};

const Root = styled(Grid)(({ theme }) => ({
  margin: theme.spacing(0, 0),
  width: "100%",
  backgroundColor: "#fff",

  [`& .${classes.track}`]: {
    marginTop: theme.spacing(2),
  },

  [`& .${classes.paper}`]: {
    width: "100%",
    backgroundColor: "#fff",
    overflowX: "auto",
    maxHeight: `calc(100vh - ${theme.mixins.toolbar["minHeight"]}px)`,
  },
  [`& .${classes.noScroll}`]: {
    overflowY: "clip",
  },

  [`& .${classes.filters}`]: {
    [theme.breakpoints.down("md")]: {
      width: "calc(100% - 72px)",
    },
    [theme.breakpoints.up("sm")]: {
      width: "360px",
    },

    height: "100%",
    overflow: "hidden",
  },
}));

const EnhancedTableToolbar = (props) => {
  const { t } = useTranslation();
  const { numSelected, done, clear, disabled, toggleFilterDrawer } = props;
  return (
    <StyledToolbar>
      <Toolbar
        className={clsx(toolbarClasses.root, {
          [toolbarClasses.highlight]: numSelected > 0,
        })}
      >
        {numSelected > 0 ? (
          <Typography
            className={toolbarClasses.title}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {t("productCount", { count: numSelected })}
          </Typography>
        ) : (
          <Typography
            className={toolbarClasses.title}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            {t("products")}
          </Typography>
        )}
        {/* <Tooltip title="تم" > */}
        <IconButton
          onClick={toggleFilterDrawer}
          className={toolbarClasses.filterButton}
          size="large"
        >
          <FilterList />
        </IconButton>

        <IconButton
          aria-label="done"
          sx={{ color: "success.main" }}
          disabled={disabled}
          onClick={done}
          size="large"
        >
          <CheckCircle />
        </IconButton>
        {/* </Tooltip> */}

        <IconButton
          aria-label="close"
          onClick={() => clear()}
          color={"primary"}
          size="large"
        >
          <Cancel />
        </IconButton>
      </Toolbar>
    </StyledToolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  done: PropTypes.func.isRequired,
};

//*********Table Function*********

const PRODUCT_QUERY = gqlb.query({
  operation: "listProducts",
  fields: [
    {
      operation: "data",
      fields: [
        "id",
        "code",
        "createdAt",
        "name",
        "active",
        "price",
        "weight",
        {
          customer: [
            "id",
            "name",
            "mobile",
            { zone: ["id", "name"] },
            { subzone: ["name"] },
          ],
        },
        {
          operation: "availableQuantity",
          fields: [],
          variables: {
            branchId: {
              type: "Int",
              required: true,
            },
          },
        },
      ],
      variables: {},
    },
    {
      operation: "paginatorInfo",
      fields: ["total"],
      variables: {},
    },
  ],
  variables: {
    first: {
      type: "Int",
      required: true,
    },
    page: {
      type: "Int",
    },
    input: {
      type: "ListProductsFilterInput",
    },
  },
});

const ProductList = (props) => {
  const { type, variables, done, setSelected } = props;

  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const [drawerState, setDrawerState] = useState(false);

  const [queryVariables, setQueryVariables] = useState({
    refetch: true,
  });

  const { refetch, ...restVariables } = queryVariables;

  const screenWidth = useWidth();

  const isScreenSmall = isWidthDown("xs", screenWidth);

  const { t } = useTranslation();
  const theme = useTheme();

  const { data: reciveQuantity } = useQuery(
    gql`
      ${MANIFEST_BY_ID_QUERY.query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      skip: !variables.fromManifestId || products.length === 0,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: {
        id: variables.fromManifestId
      },
      onCompleted: (data) => {
        const reciveQuantityProduct = data?.warehouseManifest?.manifestProducts
        products.map((x, index) => {
          reciveQuantityProduct.map((i, index) => {
            if (x.product.id === i.product.id) {
              x.quantity = i.quantity;
            }
            return ('')
          })
          return ('')
        })
      },
    }
  );

  const { data, loading } = useQuery(
    gql`
      ${PRODUCT_QUERY.query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      skip: !variables.id,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: {
        branchId: variables.branchId,
        first: rowsPerPage,
        page: page + 1,
        input: {
          forManifestId: variables.id,
          ...restVariables,
        },
      },
      onCompleted: (data) => {
        const handledData =
          data?.listProducts?.data !== null ? data?.listProducts?.data : [];

        let updatedDate = handledData.map((i) => {
          const selectedProduct = selectedProducts.find(
            (ele) => ele.product.id === i.id
          );
          if (selectedProduct) {
            return selectedProduct;
          } else {
            return {
              product: i,
              select: false,
              ...(type === "PADD" && { valid: false }),
            };
          }
        });
        setProducts(updatedDate);
      },
    }
  );

  const isProductSelected = (addProducts, deleteProducts) => {
    const mainProducts = addProducts
      ? [...addProducts]
      : [...selectedProducts];

    const filterProducts = deleteProducts
      ? [...deleteProducts]
      : [...selectedProducts];

    return mainProducts.filter((newProduct) => {
      return !filterProducts.some(
        (current) => current.product.id === newProduct.product.id
      );
    });
  };

  const updateSelectedProductByForm = useCallback(
    ({ id, checkIsEmpty, name, value }) => {
      const update = [...products];
      const productIndex = update.findIndex((i) => {
        return i.product.id === id;
      });
      if (checkIsEmpty !== null || checkIsEmpty !== undefined)
        update[productIndex]["valid"] = checkIsEmpty;
      update[productIndex][name] = value;
      setProducts(update);
    },
    [products]
  );

  const handleSelectAllClick = (event, rows) => {
    const selected = products?.map((i) => {
      if (event.target.checked) {
        return {
          ...i,
          select: true,
        };
      } else {
        return {
          ...i,
          select: false,
        };
      }
    });
    if (event.target.checked) {
      const newProducts = isProductSelected(selected);
      setSelectedProducts((prev) => [...prev, ...newProducts]);
    } else {
      const filteredProducts = isProductSelected(null, selected);
      setSelectedProducts(filteredProducts);
    }
    setProducts(selected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  const resetPage = () => setPage(0);

  const toggleFilterDrawer = () => setDrawerState(!drawerState);

  const filtersForm = (
    <ProductListFilter
      {...{ resetPage, loading }}
      preventPushUrl
      queryVariables={(variables) => setQueryVariables(variables)}
      onSubmitFunc={() => setDrawerState(false)}
      removeFiltersFields={removeFiltersFields[type]}
    // addFiltersFields={OTD && ["dlvryBefore"]}
    />
  );

  /////////////////////////////////////////////////////////////////////////////////////////////////////

  // const selectedShipment = shipments.filter((i) => i.select === true);
  const selectedProductLength = selectedProducts.length;

  let tableBody = null;

  let progress = (
    <Grid container item justifyContent="center" className={classes.track}>
      <CircularProgress />
    </Grid>
  );

  const selectedProductParPage = () => {
    return products.filter((i) => i.select === true).length;
  };
  const validProducts = () => {
    return type === "PADD" || "PRTRN"
      ? selectedProductLength <= 0 ||
      selectedProducts.some((i) => i.valid === false)
      : selectedProductLength <= 0;
  };

  if (products && !loading) {
    tableBody = (
      <Grid container justifyContent="center" sx={{ width: "100%" }}>
        {/* *******Table******* */}
        <div className={classes.paper}>
          <TableFixedHeaderWraper containerScroll className={classes.noScroll}>
            <Table aria-labelledby="tableTitle" aria-label="enhanced table">
              <EnhancedTableHead
                numSelected={selectedProductParPage()}
                onSelectAllClick={(e) => handleSelectAllClick(e, products)}
                rowCount={products?.length}
                type={type}
              />
              <TableBody>
                {products?.map((product, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <CustomTableRow
                      key={product.product.id}
                      product={product}
                      type={type}
                      onChangeCheck={(check) => {
                        setProducts((prev) => {
                          let updated = [...prev];
                          updated[index] = {
                            ...updated[index],
                            select: !updated[index].select,
                          };

                          if (check.target.checked) {
                            setSelectedProducts((prev) => [
                              ...prev,
                              updated[index],
                            ]);
                          } else {
                            setSelectedProducts((prev) => {
                              return prev.filter(
                                (i) => i.product.id !== product.product.id
                              );
                            });
                          }

                          return updated;
                        });
                      }}
                      labelId={labelId}
                    />
                  );
                })}
              </TableBody>
            </Table>
          </TableFixedHeaderWraper>
          <MUITablePagination
            count={data?.listProducts?.paginatorInfo?.total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </Grid>
    );
  }
  return (
    <ManifestProductsProvider
      value={{ updateSelectedProductByForm, branchId: variables.branchId }}
    >
      <Root container justifyContent="center">
        {isScreenSmall ? (
          <SwipeableDrawer
            disableDiscovery={true}
            disableSwipeToOpen={true}
            anchor="left"
            open={drawerState}
            onClose={() => setDrawerState(false)}
            onOpen={() => setDrawerState(true)}
            classes={{ paper: classes.filters }}
            dir={theme.direction}
          >
            {filtersForm}
          </SwipeableDrawer>
        ) : (
          <Grid item sm={2}>
            {filtersForm}
          </Grid>
        )}
        <Grid item container sm={10} alignContent="flex-start">
          <EnhancedTableToolbar
            numSelected={selectedProductLength}
            clear={done}
            toggleFilterDrawer={toggleFilterDrawer}
            done={() => {
              done();
              setSelected(selectedProducts);
            }}
            disabled={validProducts()}
          />

          {loading
            ? progress
            : products && products?.length !== 0
              ? tableBody
              : !loading && (
                <EmptyTableMessage loading={false} message={t("noProducts")} />
              )}
        </Grid>
      </Root>
    </ManifestProductsProvider>
  );
};

export default ProductList;
