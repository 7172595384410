import React, { useState } from 'react'
import { Globals } from '../HOC/Classes/Globals'
import * as gqlb from "gql-query-builder";
import { gql, useQuery } from '@apollo/client';
import ManifestTransferView from '../ManifestsTransfer/ManifestTransferView';
import ManifestView from '../Manifests/ManifestView';
import FullScreenLoading from '../HOC/FunctionComponents/LoadingPages/FullScreenLoading';
import { MANIFEST_BY_ID_AND_PRINT } from '../ManifestsTransfer/Graphql';
import NotFound from '../../Error/NotFound';

// const MANIFEST_BY_ID_AND_PRINT = gqlb.query([
//     {
//         operation: "manifest",
//         fields: [{
//             transferType: ["id", "code", "name"],
//         }],
//         variables: {
//             id: {
//                 type: "Int",
//                 required: true,
//             },
//         },
//     }
// ]);

export default function ViewRouteManifest(props) {
    const [disableForm, setDisableForm] = useState(false);
    const [disableView, setDisableView] = useState(false);
    const [manifestType, setManifestType] = useState(null);
    const [referredManifestsApprove, setReferredManifestsApprove] = useState(true)
    const [receivedShipment, setReceivedShipment] = useState({ shipments: [] });
    const [referredManifestData, setReferredManifestData] = useState(false);
    const manifestId = props.match.params.id
    const { data, loading: manifestByIdLoad } = useQuery(
        gql`
          ${MANIFEST_BY_ID_AND_PRINT.query}
        `,
        {
            fetchPolicy: "no-cache",
            nextFetchPolicy: "no-cache",
            notifyOnNetworkStatusChange: true,
            // skip: !props.match.params.id,
            variables: {
                id: parseInt(manifestId),
            },
            onCompleted: (data) => {
                if (data?.manifest === null) return;
                if (data.manifest.transferType) {
                    const manifest = data?.manifest;
                    setManifestType(data?.manifest?.referredManifests.length > 0 ? "BMR" : manifest?.transactionType?.type.code);
                    const shipments = []
                    if (data?.manifest?.referredManifests.length > 0) {
                        data?.manifest?.referredManifests.map((e) => {
                            shipments.push(...e.manifestShipments)
                            !e.approved && setReferredManifestsApprove(false)
                            return (e)
                        })
                        setReceivedShipment(shipments)
                        setReferredManifestData(true)
                    } else {
                        setReferredManifestsApprove(false)
                    }
                    setDisableForm(true);
                } else {
                    const manifest = data?.manifest;
                    setManifestType(manifest?.transactionType?.type.code);
                    setDisableForm(true);
                }
            },
            onError: ({ graphQLErrors }) => {
                const auth = graphQLErrors[0].extensions.category
                auth === 'authorization' && setDisableView(true)
            }
        }
    );

    return manifestByIdLoad ? (
        <FullScreenLoading minHeight="25%" />
    )
        : !data?.manifest ? <NotFound /> :
            data?.manifest.transferType ?
                (
                    <ManifestTransferView
                        pageProps={props}
                        data={data}
                        disableForm={disableForm}
                        disableView={disableView}
                        manifestType={manifestType}
                        manifestByIdLoad={manifestByIdLoad}
                        referredManifestsApprove={referredManifestsApprove}
                        receivedShipment={receivedShipment}
                        referredManifestData={referredManifestData}
                        manifestId={manifestId}
                        transferType={data.manifest.transferType.code}
                    />
                ) :
                (
                    <ManifestView
                        pageProps={props}
                        data={data}
                        disableForm={disableForm}
                        disableView={disableView}
                        manifestType={manifestType}
                        manifestByIdLoad={manifestByIdLoad}
                        manifestId={manifestId}
                    />
                )
}
